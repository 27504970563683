<townip-messages-header class="navigation-header">
  <button *ngIf="!isDashboard" class="btn-new-message btn btn-primary mb-3" type="button" (click)="create()">
    <i class="fa fa-envelope-o"></i>New Message
  </button>

  <ng-container *ngIf="!countrySelectItems; else projectCommunicationSearchOptions">
    <div class="row no-gutters">
      <div class="col-md-7 search-container">
        <div class="form-group">
          <input class="form-control form-control-sm message-search"
                 [(ngModel)]="searchQuery"
                 (ngModelChange)="filterMessages()"
                 placeholder="Search"/>
        </div>
      </div>
      <div class="col-md-5">
        <div class="form-group">
          <p-dropdown
            styleClass="message-search-option"
            class="message-search-by"
            [options]="searchFilters"
            [(ngModel)]="selectedSearchFilter"
            [style]="{'min-width':'80px'}"
            (change)="filterMessages()">
          </p-dropdown>
        </div>
      </div>
    </div>
  </ng-container>
</townip-messages-header>
<townip-messages-sub-header class="navigation-sub-header">
  <div class="d-flex conversation-summary header-height align-items-center">
    <span class="align-self-center text-muted text-bold-medium text-uppercase" style="color: #000000;" *ngIf="headerText; else defaultHeader">
        {{headerText}}
    </span>
    <ng-template #defaultHeader>
      <span class="font-weight-normal" style="font-size: 14px;">
        {{filteredMessages.length}} {{filteredMessages.length === 1 ? 'Conversation' : 'Conversations'}}
      </span>
    </ng-template>
    <span class="margin-left-10"><a href="#" routerLink="/messages" *ngIf="isDashboard">View All</a> </span>
    <p-checkbox binary="true" label="Show Unread Only" class="ml-auto unread-checkbox" *ngIf="!isDashboard"
                [(ngModel)]="showUnreadOnly" (onChange)="filterMessages()">
    </p-checkbox>
    <button *ngIf="isDashboard"
            class="btn-new-message btn btn-primary mb-3 mt-3 ml-auto"
            type="button"
            (click)="create()">
      <i class="fa fa-envelope-o"></i>New
    </button>
  </div>
</townip-messages-sub-header>
<div class="navigation-entries" [ngClass]="{'no-bg' : !hasBg}" *ngIf="messages && filteredMessages.length > 0; else noData">
  <cdk-virtual-scroll-viewport
    (scrolledIndexChange)="allRecipientTooltipClosed()"
    class="list-virtual-container vertical-fixed-max-width"
    itemSize="72"
    minBufferPx="360"
    maxBufferPx="720">
    <div
      class="message-item position-relative padding-10 border-bottom pb-2"
      [ngClass]="{ active: activeMessage === message.id }"
      [ngStyle]="{width: listWidth}"
      (mouseenter)="message.showEnvelopeIcon = true"
      (mouseleave)="message.showEnvelopeIcon = false"
      *cdkVirtualFor="let message of filteredMessages; templateCacheSize: 0; trackBy: trackBy">
      <div class="d-flex">
        <div class="text-primary d-flex align-items-center flex-shrink-0">
          <div>{{message.messageRecipients[0].user | generateFullname }}</div>
          <!-- Close the existing tooltips during (click) to prevent multiple tooltip display -->
          <div
            (click)="allRecipientTooltipClosed(recipientTooltip)"
            #recipientTooltip="bs-tooltip"
            [tooltip]="message.messageRecipients.length > 0 ? extraRecipientInfo : null"
            triggers="click"
            container="body"
            containerClass="client-info-tooltip-v2"
            placement="right"
            [hidden]="message.messageRecipients.length === 0"
            class="ml-2 text-white recipient-count">
            +{{message.messageRecipients.length}}
            <ng-template #extraRecipientInfo>
              <!-- Tooltip content of all recipients -->
              <div (click)="$event.stopPropagation()" class="pl-3 pr-4 pt-2 recipient-tooltip-container">
                <div class="mb-2" *ngFor="let user of message.messageRecipients">
                  <div class="text-white text-bold-500 recipient-name">{{user.user | generateFullname}}</div>
                  <div class="recipient-email w-100"><i>{{user.user.email}}</i></div>
                </div>
              </div>
            </ng-template>
          </div>
        </div>
        <div
          (click)="select(message)"
          class="latest-time text-right text-dark small w-100 text-black-50 align-self-start">
          <span *ngIf="!message.childMessages || (message.childMessages && message.childMessages.length === 0)">
            {{ message.creationTime | date:'d MMM yyyy' }}
          </span>
          <span *ngIf="message.childMessages && message.childMessages.length > 0">
            {{ message.childMessages[0].creationTime | date:'d MMM yyyy' }}
          </span>
        </div>
      </div>
      <div class="d-flex">
        <div (click)="select(message)" class="text-primary recipient-email text-truncate w-100">
          <i>{{message.messageRecipients[0].user.email}}</i>
        </div>
        <div class="d-flex justify-content-end flex-shrink-0">
          <div
            (click)="allRecipientTooltipClosed(); toggleRead(message)"
            *ngIf="message.showEnvelopeIcon && !isDashboard">
            <img
              class="envelope-icon"
              *ngIf="!message.isUserUnreadMessage"
              src="assets/images/svgAssets/mail-read-open.svg"/>
            <img
              class="envelope-icon"
              *ngIf="message.isUserUnreadMessage"
              src="assets/images/svgAssets/mail-unread-closed.svg"/>
          </div>
          <div class="scope-container">
            <button
              (click)="allRecipientTooltipClosed(); navigateScope(message)"
              *ngIf="message.scope && (showProjectScope || (!showProjectScope && message.scope !== 'PROJECT'))"
              title="{{message.scope + ' ' + message.scopeName}}"
              class="btn btn-reply-scope {{message.scope}} w-auto d-inline-block ml-2">
              {{message.scope + ' ' + message.scopeName}}
            </button>
          </div>
        </div>
      </div>
      <div (click)="select(message)">
        <div
          class="text-truncate subject-content"
          [ngClass]="{'font-weight-bold': message.isUserUnreadMessage}">
          {{message.messageSubject}}
        </div>
        <div class="message-content text-black-50">
          <!-- The body we are supposed to display is from the latest message body of the child -->
          <!-- If no child, should display the parent -->
          <townip-trimmed-text
            maxHeight="17"
            [expandable]="false"
            [fullText]="message.lastMessage?.messageBody">
          </townip-trimmed-text>
        </div>
        <div *ngIf="(message | messageAttachmentCount) > 0" class="attachment-count text-primary">
          <i class="fa fa-paperclip"></i>
          <span class="small ml-1">
            {{message | messageAttachmentCount}} {{(message | messageAttachmentCount) > 1 ? 'Attachments' : 'Attachment'}}
          </span>
        </div>
      </div>
    </div>
  </cdk-virtual-scroll-viewport>
</div>

<ng-template #noData>
  <div *ngIf="isDashboard" class="d-flex justify-content-center align-items-center flex-column" style="height: 100%">
    <div class="margin-bottom-10">
      <img class="img-fluid"
           style="max-width: 100%; height: 50px"
           src="/assets/images/svgAssets/email.svg">
    </div>
    <span>You do not have any unread messages.</span>
    <div class="margin-top-15">
      <button class="btn-new-message btn btn-primary mb-3" type="button" (click)="create()">
        <i class="fa fa-envelope-o"></i>New Message
      </button>
    </div>
  </div>
  <div *ngIf="!isDashboard && (!messages || (messages && messages.length === 0))"
       class="d-flex justify-content-center align-items-center flex-column"
       style="height: 350px">
    <div class="mb-4">
      <img class="img-fluid"
           style="max-width: 100%; height: 50px"
           src="/assets/images/svgAssets/email.svg">
    </div>
    <span class="font-weight-bold">No conversation has been created yet.</span>
    <div>
      <button class="btn btn-link" (click)="create()">Create First Conversation</button>
    </div>
  </div>
</ng-template>

<ng-template #projectCommunicationSearchOptions>
  <div class="form-group">
    <input class="form-control form-control-sm message-search"
           [(ngModel)]="searchQuery"
           (ngModelChange)="filterMessages()"
           placeholder="Search"/>
  </div>

  <div class="row mt-2">
    <div class="col-md-6">
      <div class="form-group">
        <p-dropdown
          styleClass="message-search-option"
          class="message-search-by"
          [options]="searchFilters"
          [(ngModel)]="selectedSearchFilter"
          [style]="{'min-width':'80px'}"
          (onChange)="filterMessages()">
        </p-dropdown>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <p-dropdown
          styleClass="message-search-option"
          class="message-search-by"
          [options]="countrySelectItems"
          [(ngModel)]="selectedCountry"
          [style]="{'min-width':'80px'}"
          [showClear]="true"
          (onChange)="filterMessages()"
          placeholder="Country">
        </p-dropdown>
      </div>
    </div>
  </div>
</ng-template>
