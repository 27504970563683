<!-- When replacing this with another editor - make sure we also have the correct default height -->
<!-- This is a temporary editor, we might replace this with something more appropriate to our needs-->
<editor
  [(ngModel)]="content"
  (ngModelChange)="updateModel($event)"
  (onFocus)="touch()"
  (onInit)="init.emit()"
  [ngClass]="styleClass"
  [disabled]="isDisabled"
  [init]="initParams">
</editor>


