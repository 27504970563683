import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InitialsComponent } from './initials.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [InitialsComponent],
  exports: [InitialsComponent]
})
export class InitialsModule { }
