
<ng-container>
  <ng-template #popTemplate>{{fullText}}</ng-template>
  <div [innerHtml]="isExpanded? fullText : summary"
       [ngClass]="isExpanded ? 'fullMessage' : 'trimmedMessage'"
       [ngStyle]="customStyle"
       [isDisabled]="!useTooltip || (fullText === summary)"
       [tooltip]="popTemplate"
       placement="{{placement}}">
  </div>
</ng-container>

<div class="text-expander" *ngIf="expandable && isTrimmed">
  <a href="#" (click)="$event.preventDefault(); expand()" *ngIf="!isExpanded">
    <i class="fa fa-caret-down"></i> {{expandLabel}}
  </a>
  <a href="#" (click)="$event.preventDefault(); collapse()" *ngIf="isExpanded">
    <i class="fa fa-caret-up"></i> {{collapseLabel}}
  </a>
</div>

