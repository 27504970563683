/**
 * This function will be used on Prime Editor (Messaging component) to remove image tag
 * User will not be able to paste image in the text editor
 *
 *
 * NOTE: IF WE ARE GOING TO INTEGRATE THIS TO ALL EDITOR,
 *       WE NEED TO WRAP THE P-EDITOR AND DO THIS FUNCTION INSIDE THE WRAPPER
 */
import { isNullOrUndefined } from './utils/is-null-or-undefined';

export function removeImageTag(value: string): string {
  if (isNullOrUndefined(value)) {
    return;
  }

  value = value.replace(/<img[^>]*>/g,'');
  return value;
}
