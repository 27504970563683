import { Injectable } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { LoggingService } from '../logging.service';

@Injectable()
export class WebFontService {

  // eslint-disable-next-line @typescript-eslint/no-require-imports
  private webFontLoader = require('webfontloader');
  private webFontFamilies = ['Arvo:400,700', 'Roboto:100,300,400,500,700'];

  public webFontLoadSub: Subscription;
  public webFontLoadCompleted: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private loggingService: LoggingService) {}

  public loadWebFont(): void {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;
    this.webFontLoader.load({
      // tslint:disable-next-line:only-arrow-functions
      loading: function(): void {
        that.loggingService.info('Web font is loading.');
      },
      // tslint:disable-next-line:only-arrow-functions
      active: function(): void {
        that.loggingService.info('Web font is active.');
        that.webFontLoadCompleted.next(true);
      },
      // tslint:disable-next-line:only-arrow-functions
      inactive: function(): void {
        that.loggingService.error('Web font is inactive.');
        that.webFontLoadCompleted.next(false);
      },
      google: {
        families: that.webFontFamilies
      }
    });
  }
}
