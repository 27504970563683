import { EventEmitter, Injectable } from '@angular/core';
import { AuthService } from '../security/auth.service';
import { PrintData } from './print-preview.models';
import { Router } from '@angular/router';
import { cloneDeep } from '../shared/clone-deep';
import * as _random from 'lodash/random';
import { firstValueFrom, of } from 'rxjs';

@Injectable()
export class PrintPreviewService {

  public printingEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  public printData: PrintData[] = [];

  constructor(
    private authService: AuthService,
    private router: Router) {}

  public saveToPrintServer(data: any): Promise<PrintData> {
    const reqData: PrintData = {
      data: data,
      token: this.authService.getIdToken()
    };

    // Just some pseudo-random id
    reqData.id = (new Date().valueOf() + _random(1, 65535)).toString();
    const refData = cloneDeep(reqData);

    // Will need to fetch this later.
    // For now only
    this.printData.push(refData);
    return firstValueFrom(of(refData));
  }

  public getFromPrintServer(id: string): Promise<PrintData> {
    const printData = this.printData.find(data => data.id === id);
    return new Promise((resolve, reject) => {
      if (printData) {
        resolve(printData);
        return;
      }

      reject('Print data not found');
    });
  }

  public downloadPdf(url: string, fileName: string): Promise<string> {

    // Will deprecate method after porting all print pages.
    return Promise.resolve(null);
  }

  public getCurrentState(): string {
    const currentRout = this.router.parseUrl(this.router.url);
    const urlWithoutParams = currentRout.root.children.primary.segments
      .map(it => it.path)
      .join('/');

    return urlWithoutParams;
  }

  public parseAssetDomain(): string {
    const defaultAssetDomain: string = window.location.origin;
    // this.assetDomain = 'https://sunip-test.legalsight.com'; // FOR test mocking this. Change it something else

    // If we are not localhost, the default asset domain is already set by default.
    if (defaultAssetDomain.search('localhost') < 0) {
      return defaultAssetDomain;
    }

    // If we are in LOCALHOST, we need a remote-accessible image.
    // This is because the backend uses a third-party HTML-to-PDF converter
    // The api needs to have access to the images otherwise it could not render images
    // because it will not have access to LOCALHOST deployment.

    // CAVEAT HERE - possibly in the future if you need an image in the render that is not yet deployed in prod,
    // change this to some other test instance that have it as long as it is publicly remote accessible
    return 'https://sunip.legalsight.com';
  }
}
