import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextHighlighterComponent } from './text-highlighter.component';

@NgModule({
  declarations: [
    TextHighlighterComponent
  ],
  exports: [
    TextHighlighterComponent
  ],
  imports: [
    CommonModule
  ]
})
export class TextHighlighterModule {}
