import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { UserService } from '../services/user.service';

@Injectable()
export class AnnuitiesGuard  {

  constructor(
    private router: Router,
    private userService: UserService,
  ) { }

  public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.userService.hasAnnuitiesAccess()) {
      return true;
    }
    this.router.navigate(['/error-403']);
    return false;
  }

}
