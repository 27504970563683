import { EventEmitter, Injectable } from '@angular/core';
import { LoggingService } from '../logging.service';
import { AuthService } from '../../security/auth.service';
import { UserService } from './user.service';
import { WebsocketNotification } from '../dto/messaging/websocket-notification';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { RequestService } from './request-service';
import { GeneralResponseMessage } from '../dto/general-response-message';
import { from, Observable, Subscription, throwError as observableThrowError, timer } from 'rxjs';
import { delay, mergeMap, retryWhen, scan, take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { isNullOrUndefined } from '../utils/is-null-or-undefined';
import * as pubnub from 'pubnub';

export const PB_GRANT_URL = '/api/messaging/channel/grant';
export const PB_AUTH_RETRY_LIMIT = 2;

@Injectable()
export class WebsocketService {

  public userId: number;

  public websocketUpdateEmitter = new EventEmitter<WebsocketNotification>();

  // Used when the service connects even before the consuming component initializes.
  // The component will not receive the connectedEmitter event when the service is able to connect
  // faster than the component's subscription.
  public isConnected: boolean;

  public isChannelSecured: boolean;

  public connectedEmitter: EventEmitter<boolean> = new EventEmitter();

  private authErrorRetryLimit = PB_AUTH_RETRY_LIMIT;

  private authErrorRetryPending: Subscription;

  private pubnub: any;

  constructor(private loggingService: LoggingService,
              private authService: AuthService,
              private userService: UserService,
              private requestService: RequestService,
              private router: Router,
              private httpClient: HttpClient) {
    this.isConnected = false;

    this.authService.connectedEmitter.subscribe(connected => {
      if (!connected) {
        this.loggingService.info('logout event detected -- disconnecting websockets');
        this.disconnect();

        // Unsubscribe if there is a pending retry
        if (this.authErrorRetryPending) {
          this.authErrorRetryPending.unsubscribe();
        }
      }
    });

    this.userService.userEmitter.subscribe(() => {
      if (!this.isConnected) {
        this.updateWebSocketConnection(true);
      }
    });
  }

  private updateWebSocketConnection(establishSubscription: boolean): void {
    this.loggingService.info('Attempting to update websocket connection -- subscribe: ', establishSubscription);
    this.setupWebSockets(establishSubscription);
  }

  private async subscribe(): Promise<void> {
    const user = this.userService.getUser();
    this.pubnub.removeAllListeners();

    // Make sure there is a user when we subscribe, otherwise do not proceed.
    // Happens sporadically for some reason
    if (!user) {
      return;
    }

    this.userId = user.id;
    // ensure we aren't already connected to prevent duplicate messages
    this.createChannelNames(this.userId)
      .then(async channelNames => {
        this.pubnub.unsubscribe({
          channels: channelNames
        });
        this.createChannelListener();
        this.loggingService.info('attempting to connect to ', channelNames);
        this.pubnub.subscribe({
          channels: channelNames,
        });
      });
  }

  private createChannelListener(): void {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;
    that.pubnub.addListener({
      // tslint:disable-next-line:only-arrow-functions
      status: function(st: any): void {
        that.processStatusUpdate(st, that);
      },
      // tslint:disable-next-line:only-arrow-functions
      message: function(message: any): void {
        that.processMessage(message);
      }
    });
  }

  private processMessage(message: any): void {
    if (!isNullOrUndefined(message)) {
      this.loggingService.info('PN Message Received - channel: ' + message.channel);
      this.websocketUpdateEmitter.emit(message);
    }
  }

  private processStatusUpdate(st: any, that: any): void {
    if (!isNullOrUndefined(st)) {
      // NOTE - Only enable this when debugging websockets.
      // that.loggingService.info('PN Status Update - category: ' + st.category + ' affected channel: ' +
      // st.affectedChannels + ' operation: ' + st.operation, st);
      if (st.category === 'PNUnknownCategory') {
        that.loggingService.info('Unknown status received');
      } else if (st.category === 'PNConnectedCategory') {
        that.handleConnectedStatus();
      } else if (st.category === 'PNReconnectedCategory') {
        that.handleReconnectedStatus();
      } else if (st.category === 'PNNetworkIssuesCategory') {
        that.handleNetworkIssuesStatus();
      } else if (st.category === 'PNTimeoutCategory') {
        that.handleTimeoutStatus();
      } else if (st.category === 'PNNetworkDownCategory') {
        that.handleNetworkDownStatus();
      } else if (st.category === 'PNNetworkUpCategory') {
        that.handleNetworkUpStatus();
      } else if (st.category === 'PNAccessDeniedCategory' &&
        (st.operation === 'PNSubscribeOperation' || st.operation === 'PNHeartbeatOperation')) {
        that.disconnectAndStageRetry();
      } else if (st.category === 'PNUnauthorizedCategory') {
        that.disconnectAndStageRetry();
      } else if (st.category === 'PNBadRequestCategory') {
        // Probably pub-nub is down.
        // Run the kill switch.
        this.disconnectAndStageRetry();
      }
    }
  }

  private handleNetworkUpStatus(): void {
    this.loggingService.info('Network up status received');
  }

  private handleNetworkDownStatus(): void {
    this.loggingService.info('Network down status received');
    this.setConnected(false);
    this.isChannelSecured = false;
  }

  private handleTimeoutStatus(): void {
    // PubNub SDK will handle reconnect
    this.loggingService.info('Timeout status received');
  }

  private handleNetworkIssuesStatus(): void {
    // PubNub SDK will handle reconnect if network connection is subsequently dropped
    this.loggingService.info('Network Issues status received');
  }

  private handleReconnectedStatus(): void {
    this.loggingService.info('Reconnected status received');
    this.setConnected(true);
  }

  private handleConnectedStatus(): void {
    this.loggingService.info('Connected status received');
    this.setConnected(true);
  }

  private disconnectAndStageRetry(): void {
    // NOTE: Don't covert this to pure observables.
    // We're keeping this simple on purpose so it can be debugged, and adjusted by everyone easily.

    // If you reached the try limit for access denied,
    // just kill the system, something has probably gone wrong
    // Or pubnub is down, or you are doing something YOU ARE NOT wink* wink* supposed to do.
    if (this.authErrorRetryLimit <= 0) {
      // Full disconnect
      this.fullDisconnect();

      // Reset retry count
      this.authErrorRetryLimit = PB_AUTH_RETRY_LIMIT;

      // console.log("Retry limit reached, retrying after 10 minutes")
      // Wait 10 minutes to reconnect
      // Making this observable so we can cancel it
      // when the user logs out and there is a pending request to retry, we cancel it by unsubscribing
      this.authErrorRetryPending = from(timer(600000))
        .subscribe(() => {
          this.setupWebSockets(true);
        });
      return;
    }

    // As soon as we get get any access denied status, drop it.
    // Regardless of operation type, disconnect if we get 403.
    // This prevents additional events from being received while retrying
    this.fullDisconnect();

    // Retry in 10 seconds.
    setTimeout(() => {
      this.authErrorRetryLimit--;
      this.setupWebSockets(true);
    }, 10000);
  }

  private setConnected(connected: boolean): void {
    this.isConnected = connected;
    this.connectedEmitter.emit(connected);

    if (connected) {
      this.authErrorRetryLimit = PB_AUTH_RETRY_LIMIT;
    }
  }

  private disconnect(): void {
    this.loggingService.info('Disconnecting websockets');
    this.isConnected = false;
    this.isChannelSecured = false;
    this.pubnub?.unsubscribeAll();
    this.pubnub = null;
    this.userId = null;
    this.connectedEmitter.next(false);
  }

  private setupWebSockets(establishSubscription: boolean): void {
    if (this.pubnub == null) {
      this.pubnub = new pubnub({
        userId: this.userService.getUser().id.toString(),
        subscribeKey: environment.subscribeKey,
        ssl: true,
        // NOTE: Only set to true when debugging websockets
        logVerbosity: false,
      });
    }

    this.grantChannelAccess(3000, 3)
      .subscribe(pubnubToken => {
        this.loggingService.info('channel access has been granted');
        this.secureChannel(pubnubToken);
        if (establishSubscription) {
          this.subscribe();
        }
      }, (error) => {
        this.loggingService.error('unable to grant channel access', error);
        this.handleGrantChannelAccessFailure();
      });
  }

  private secureChannel(idToken: string): void {
    this.pubnub.setToken(idToken);
    this.isChannelSecured = true;
  }

  private handleGrantChannelAccessFailure(): void {
    this.loggingService.error('Grant channel access attempts exceed max number of retries -- channel is not secured');
    this.isChannelSecured = false;
    this.disconnectAndStageRetry();
  }

  private async createChannelNames(userId: number): Promise<string[]> {
    const channelName = 'channel-' + userId;
    const broadcastName = 'broadcast';
    let channels = [];

    if (environment.local) {
      await this.getIpAddress(1000, 5)
        .then(responseMessage => {
          this.loggingService.info('successfully retrieved local channel name');
          const suffix = '-' + responseMessage.response;
          channels = [channelName + suffix, broadcastName + suffix];
        })
        .catch(() => this.loggingService.error('unable to obtain IP address'));
    } else {
      channels = [channelName, broadcastName];
    }

    return Promise.resolve(channels);
  }

  private getIpAddress(delayTime: number, maxRetries: number): Promise<GeneralResponseMessage> {
    this.loggingService.info('Attempting to obtain mac address for local channel');
    const headers = this.requestService.buildHttpHeaders();
    const endpoint = '/api/user/ip-address';
    return this.httpClient.get<GeneralResponseMessage>(endpoint, { headers: headers })
      .pipe(retryWhen(errors => errors.pipe(delay(delayTime))
        .pipe(take(maxRetries))
        .pipe(scan((counter, error) => {
          this.loggingService.error('request to endpoint ' + endpoint + ' failed - attempt #', counter);
          this.isChannelSecured = false;
          if (counter >= maxRetries) {
            observableThrowError(error);
          }
          return counter + 1;
        }, 1))))
      .toPromise();
  }

  private grantChannelAccess(delayTime: number, maxRetries: number): Observable<string> {
    this.loggingService.info('Attempting to grant channel access.  Retries: ' + maxRetries + ' Delay: ' + delayTime);
    const headers = this.requestService.buildHttpHeaders();

    // eslint-disable-next-line
    return this.httpClient.get(PB_GRANT_URL, { responseType: 'text' as 'text', headers: headers })
      .pipe(
        retryWhen((errors) =>
          errors.pipe(
            mergeMap((err, i) => {
              const retryAttempt = i + 1;
              if (retryAttempt >= maxRetries) {
                return observableThrowError(err);
              } else {
                return timer(delayTime);
              }
            })
          )
        )
      );
  }

  /**
   * Do not call this inside this component.
   * Redirect the user to the maintenance page if you want full disconnect
   */
  public fullDisconnect(): void {
    // Cleanup the active connections so they won't retry.
    // IMPORTANT: System is not expected to recover from this so call it from the maintenance page
    this.disconnect();
    this.loggingService.warn('Removing all pubnub listeners..');
    this.pubnub?.removeAllListeners();
  }
}
