<div class="townip-top-menu-wrapper">
  <ul class="top-menu fade-down">
    <li class="toggler">
      <i class="fa toggle-icon fa-chevron-right" [ngClass]="expanded ? 'rotated' : 'no-rotate'" (click)="toggleNavbar()"></i>
    </li>
    <li class="search" *ngIf="isAdminPM || isSalesAccountManager">
      <townip-search-system></townip-search-system>
    </li>
    <li class="server-banner" *ngIf="isTestServer">
      <townip-server-banner class="align-top"></townip-server-banner>
    </li>
    <li class="res-user-nav" *ngIf="user">
      <townip-initials data-toggle="dropdown" fontSize="1rem" [size]="40" [data]="user.firstName + ' ' + user.lastName"></townip-initials>
      <ng-container *ngTemplateOutlet="navDropdown"></ng-container>
    </li>
    <li class="user-nav" *ngIf="user">
      <button data-toggle="dropdown" class="btn btn-outline-secondary profile-toggle d-flex align-items-center" data-container="body">
        <townip-initials fontSize="1rem" [size]="40" [data]="user.firstName + ' ' + user.lastName"></townip-initials>
        <span class="margin-left-20">{{user | displayShortName:30}}</span>
      </button>
      <ng-container *ngTemplateOutlet="navDropdown"></ng-container>
    </li>

    <ng-container *ngIf="!isForecasterOnly">
      <li class="nav-divider" *ngIf="user"></li>
      <li class="right-nav margin-top-20 margin-right-10" *ngIf="user">
        <div (click)="alertquickview.toggleMenu();" tabindex="1"
           (blur)="alertquickview.hideMenu()" [tooltip]="unreadAlertCount + ' Alerts'" placement="bottom" containerClass="toolbar-tooltip">
          <i class="fa fa-bell alert-comment toolbar-icon">
            <span class="alert-nav" *ngIf="unreadAlertCount > 0">&nbsp;</span>
            <div class="alert-pop-circle" *ngIf="unreadAlertCount > 0"></div>
          </i>
        </div>
        <townip-alerts-quickview
          #alertquickview
          [user]="user"
          (unreadAlertsCountChange)="updateAlertsCount($event)">
        </townip-alerts-quickview>
      </li>
      <li class="right-nav margin-left-10 margin-top-20 margin-right-20" *ngIf="user">
        <div (click)="messagequickview.toggleMenu();" tabindex="1"
           (blur)="messagequickview.hideMenu()" [tooltip]="unreadMessageCount + ' Messages'" placement="bottom" containerClass="toolbar-tooltip">
          <i class="fa fa-envelope-o message-comment toolbar-icon">
            <span class="messages-nav" *ngIf="unreadMessageCount > 0">&nbsp;</span>
            <div class="message-pop-circle" *ngIf="unreadMessageCount > 0"></div>
          </i>
        </div>
        <townip-message-quickview
          #messagequickview
          [user]="user"
          (unreadMessageCountChange)="updateMessageCount($event)">
        </townip-message-quickview>
      </li>
      <li
        class="right-nav margin-left-10 margin-top-20 margin-right-15"
        *ngIf="user && user.userType === 'INTERNAL' && !isSalesAccountManager"
        [routerLink]="'/calendar'">
        <i class="fa fa-calendar toolbar-icon"></i>
      </li>
      <li class="nav-divider" *ngIf="user && (isCompanyUser || user?.userType === 'INTERNAL')"></li>
      <li *ngIf="user && isCompanyUser" class="new-actions-container">
        <ng-container *ngTemplateOutlet="newActions"></ng-container>
      </li>
      <li *ngIf="isEditableInternalUser" class="new-actions-container">
        <ng-container *ngTemplateOutlet="pmActions"></ng-container>
      </li>
    </ng-container>
  </ul>

  <ng-template #newActions>
    <!-- New Version Buttons -->
    <button *townipIfUserHasTransFilingAccess type="button" label="New Project" pButton
            class="raised-button header-cta ui-button-success btn-action-new margin-left-5"
            [routerLink]="['/redirect']" [queryParams]="{route: '/estimate/new-estimate-2', query: projectQuery}">
    </button>
    <ng-container *ngIf="!userPermissions.RESTRICTED_COMPANY_USER">
      <button *townipIfUserHasTransFilingAccess
              type="button" label="New Estimate" pButton
              class="raised-button header-cta ui-button-success btn-action-new margin-left-5"
              [routerLink]="['/redirect']" [queryParams]="{route: '/estimate/new-estimate-2'}">
      </button>
    </ng-container>
  </ng-template>

  <ng-template #pmActions>
    <button type="button" label="New Client Project" pButton
            class="raised-button header-cta ui-button-success btn-action-new margin-right-5"
            (click)="newClientProject()">
    </button>
    <button type="button" label="New Client Estimate" pButton
            class="raised-button header-cta ui-button-success btn-action-new margin-left-5"
            (click)="newClientEstimate()">
    </button>
  </ng-template>

  <ng-template #navDropdown>
    <ul class="dropdown-menu right-nav-menu">
      <li class="t-dropdown-caret">
        <i class="fa fa-caret-up"></i>
      </li>
      <li>
        <div class="bottom-margin margin-top-10" *ngIf="user">
          <div class="text-truncate"><span class="prof-name">{{user.firstName}} {{user.lastName}}</span></div>
          <div class="text-truncate"><span class="prof-email">{{user.email}}</span></div>
        </div>
      </li>
      <li class="separator"></li>
      <!-- TODO profile/alerts/security navigation -->
      <li class="nav-item">
        <a [routerLink]="['/settings-v2/user-profile']"><span class="nav-item-span">My Profile</span></a>
      </li>
      <ng-container *ngIf="!isForecasterOnly">
        <li class="nav-item collapsed-nav">
          <a [routerLink]="['/messages']"><span class="nav-item-span">Messages - {{unreadMessageCount}}</span></a>
        </li>
        <li class="nav-item collapsed-nav">
          <span class="nav-item-span">Alerts - {{unreadAlertCount}}</span>
        </li>
      </ng-container>
      <li class="separator"></li>
      <li class="nav-item">
        <a href="#" routerLink="/auth/logout" [ngClass]="{ disabled: noLogout }">
            <span class="nav-item-span">
              <ng-container *ngIf="noLogout">
                <i class="fa fa-spin fa-circle-o-notch"></i>&nbsp;
              </ng-container>Log out
            </span>
        </a>
      </li>
    </ul>
  </ng-template>
</div>
