<div class="float-left">
  <button *ngIf="!displayUploadContainer"
          class="btn btn-primary"
          (click)="displayUploadContainer = true">
    <i class="fa fa-plus"></i> Upload
  </button>
</div>
<div class="mt-2 mb-2">
  <div class="alert alert-warning margin-top-10 file-warning" *ngIf="uploadErrors && uploadErrors.length > 0">
    <div *ngFor="let error of uploadErrors">{{error}}</div>
  </div>
  <p-fileUpload
    #fileUploader
    *ngIf="displayUploadContainer"
    name="files"
    url="/api/file"
    chooseLabel="Upload"
    maxFileSize="1000000000"
    multiple="true"
    [disabled]="fileUploader.uploading"
    [showUploadButton]="false"
    [showCancelButton]="false"
    (onSelect)="onSelect($event)"
    (onBeforeUpload)="onBeforeUpload($event)"
    (onUpload)="onUpload($event)">
    <ng-template pTemplate="file">
      <!-- prevent displaying the default template of selected files-->
    </ng-template>
    <ng-template pTemplate="content">
      <div class="drag-drop-area" townipDragDropListener></div>
      <div class="my-2" townipDragDropListener>
        <div class="row" *ngIf="!uploadedFiles.length; else uploadFilesTemplate" style="text-align: center">
          <div class="col-lg-12">
            <img style="height: 40px;" src="/assets/images/placeholders/upload.png" />
          </div>
          <div class="col-lg-12">
            <span>Drag & drop file(s) or use the button above.</span>
          </div>
        </div>
        <ng-template #uploadFilesTemplate>
          <div *ngIf="uploadedFiles?.length > 0">
            <div class="margin-left-10">
              <div *ngFor="let file of uploadedFiles" class="position-relative">
                <i class="fa fa-close mouse-style" (click)="remove(file)"></i>
                <span class="file-style padding-left-5">{{file?.filename}}</span>
              </div>
            </div>
          </div>
        </ng-template>
      </div>
    </ng-template>
  </p-fileUpload>

</div>
