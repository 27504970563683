import { FilterGroup } from '../../data-filter/filter';

export enum GroupTabMode {
  Task = 'TASK',
  Project = 'PROJECT',
  Estimate = 'ESTIMATE',
  Forecast = 'FORECAST',
}

export interface GroupTab {
  id: any;
  label: any;
  filter?: FilterGroup[];
  visible?: boolean;
  type?: GroupTabMode;
  userType?: string;
  subGroups?: GroupTab[];
  autoSelected?: boolean;
}
