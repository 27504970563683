import * as _ from 'underscore';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { SelectItem } from 'primeng/api';
import { FieldType } from '../../data-filter/field-type';
import { Filter, FilterFormOptions, FilterOperator, InputType } from './filter-form';
import { FilterGroup, FilterParameters } from '../../data-filter/filter';
import { OPERATOR_COLLECTION } from '../../data-filter/filter-operators';
import { ModalComponent } from '../../../controls/modal/modal.component';

@Component({
  selector: 'townip-filter-form',
  templateUrl: './filter-form.component.html',
  styleUrls: ['./filter-form.component.scss']
})
export class FilterFormComponent implements OnInit, OnChanges {

  @Output()
  // NOTE: Only suppressing because legacy.
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  public onSaved = new EventEmitter<any>();

  @Output()
  // NOTE: Only suppressing because legacy.
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  public onHidden = new EventEmitter<any>();

  @Input()
  public fields: FilterFormOptions[] = [];

  @ViewChild(ModalComponent)
  public filterModal: ModalComponent;

  public filters: Filter[] = [];

  public options: SelectItem[];

  public operators: FilterOperator[];

  public values: SelectItem[];

  public selectedOption: any[];

  public selectedOperator: any[];

  public selectedValue: any[];

  public filterName: string;

  public prevFilterName: string;

  public error: string;

  public inputTypes = InputType;

  public fieldTypes = FieldType;

  public isEditing: boolean;

  constructor() {
    this.error = '';
    this.selectedOperator = [];
    this.selectedValue = [];
    this.operators = OPERATOR_COLLECTION;
    this.isEditing = false;
  }

  public ngOnInit(): void {
    // Init stuff here
  }

  public ngOnChanges(): void {
    this.rebuildFilters();
  }

  private rebuildFilters(): void {
    // Reset the fields
    this.filters = [];

    if (this.fields) {
      for (const field of this.fields) {
        const filter = new Filter(field);

        this.filters.push(filter);
      }
    }
    // Map the fields to the select options
    this.options = this.filters.map((field) => {
      return { label: field.name, value: field.field };
    });
  }

  public updateFields(): void {
    // Deactivate all first
    for (const filter of this.filters) {
      filter.active = false;
      const find = this.selectedOption.find((option) => option === filter.field);
      if (find) {
        filter.active = true;
      }
    }
  }

  public edit(filter: FilterGroup): void {
    this.isEditing = true;
    this.prevFilterName = filter.name;
    this.filterName = filter.name;
    this.showModal();

    for (const fil of this.filters) {
      const loadedFilter = _.findWhere(filter.filters, { field: fil.field });
      if (loadedFilter) {
        fil.active = true;
        fil.value = loadedFilter.value;
        fil.operator = loadedFilter.operator;

        this.selectedOption.push(fil.field);
      }
    }
  }

  public onSave(form: NgForm): void {
    if (this.filterName) {
      this.error = '';
      const body: FilterGroup = {
        name: this.filterName,
        custom: true,
        prevName: this.prevFilterName,
        filters: this.getActiveFilters()
      };
      this.onSaved.emit(body);
      this.hideModal();
    } else {
      this.error = 'Filter Name is required.';
    }
  }

  public onClose(): void {
    this.onHidden.emit();
    this.hideModal();
  }

  public showModal(): void {
    this.rebuildFilters();
    this.filterModal.show();
  }

  public hideModal(): void {
    this.filterModal.hide();

    // Reset every time the user closes the modal
    this.filters = [];

    // for (const filter of this.filters) {
    //   filter.active = false;
    //   filter.value = null;
    // }

    // Reset the selected filters
    this.selectedOption = [];
    this.filterName = '';
    this.prevFilterName = '';
    this.isEditing = false;
  }

  private getActiveFilters(): FilterParameters[] {
    const activeFilters: Filter[] = _.where(this.filters, { active: true });

    return _.map(activeFilters, (filter: Filter): FilterParameters => {
      return filter.toParams();
    });
  }
}
