import { Injectable } from '@angular/core';
import { ConfigDataStoreService } from './config-data-store.service';
import { ReceivingOffice } from '../../../shared/dto/system-config/receiving-office';
import { ConfigService } from '../../config.service';
import { UserService } from '../../../shared/services/user.service';
import { SystemConfigDTO } from '../../../shared/dto/system-config/system-config-dto';
import { SystemConfigResponseDTO } from '../../../shared/dto/system-config/system-config-response-dto';

@Injectable()
export class ReceivingOfficeDataStoreService extends ConfigDataStoreService<ReceivingOffice> {

  constructor(configService: ConfigService, userService: UserService) {
    super(configService, userService, 'RECEIVING_OFFICE');
  }

  public create(data: ReceivingOffice): Promise<SystemConfigResponseDTO> {
    const configObject: SystemConfigDTO = { userId: this.user.id, type: this.type, receivingOfficeDTO: data };
    const response = this.configService.addSystemConfig(configObject);
    response.then(res => {
      this.dataSetPromise.then(dataSet => {
        dataSet.push(res.configValueDTO as ReceivingOffice);
        this.dataSet.next(dataSet);
        this.dataSetPromise = Promise.resolve(dataSet);
      });
    });

    return response;
  }

  public update(data: ReceivingOffice): Promise<SystemConfigResponseDTO> {
    const configObject: SystemConfigDTO = { userId: this.user.id, type: this.type, receivingOfficeDTO: data };
    const response = this.configService.updateSystemConfig(configObject);
    response.then(res => {
      this.dataSetPromise.then(dataSet => {
        const index = dataSet.findIndex(dataPoint => dataPoint.id === res.configValueDTO.id);
        dataSet[index] = res.configValueDTO as ReceivingOffice;
        this.dataSet.next(dataSet);
        this.dataSetPromise = Promise.resolve(dataSet);
      });
    });

    return response;
  }

  public enable(data: ReceivingOffice): Promise<SystemConfigResponseDTO> {
    return this.configService.enableSystemConfig({ userId: this.user.id, type: this.type, receivingOfficeDTO: data });
  }

  public disable(data: ReceivingOffice): Promise<SystemConfigResponseDTO> {
    return this.configService.disableSystemConfig({ userId: this.user.id, type: this.type, receivingOfficeDTO: data });
  }
}
