import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Company } from '../../dto/company';
import { CustomerPreferences } from '../../dto/preferences/customer-preferences';
import { Country } from '../../dto/system-config/country';
import { isNullOrUndefined } from '../../utils/is-null-or-undefined';

enum FilerType {
  'SUN_IP',
  'CLIENT_EMAIL',
  'CLIENT_PLATFORM',
}

export enum AgentType {
  'VENDOR',
  'EMAIL'
}

@Component({
  selector: 'townip-foreign-agent-classification-tag',
  templateUrl: './foreign-agent-classification-tag.component.html',
  styleUrls: ['./foreign-agent-classification-tag.component.scss']
})
export class ForeignAgentClassificationTagComponent implements OnInit, OnChanges {

  @Input()
  public country: Country;

  // Type of foreign agent.
  // A Vendor can serve as a foreign agent, but so can a non system user,
  // who is usually identified by their email within a client.
  @Input()
  public foreignAgentType: AgentType;

  @Input()
  public foreignAgentId: number;

  @Input()
  public preferenceSet: CustomerPreferences;

  @Input()
  public forPrefSetDisplay = false;

  @Input()
  public showAsBadge = true;

  @Input()
  public company: Company;

  public filerTypes = FilerType;

  public filerType: FilerType;

  constructor() {
  }

  public ngOnInit(): void {}

  public ngOnChanges(): void {
    this.setForeignAgentType();
  }

  public setForeignAgentType(): void {
    switch (this.foreignAgentType) {
    case AgentType.EMAIL:
      this.filerType = FilerType.CLIENT_EMAIL;
      break;
    case AgentType.VENDOR:
      this.filerType = this.forPrefSetDisplay ? FilerType.CLIENT_PLATFORM : FilerType.SUN_IP;

      this.getVendorFilingType();
      break;
    }
  }

  public getVendorFilingType(): void {
    if (isNullOrUndefined(this.country)) {
      return;
    }

    if (this.filerType === FilerType.CLIENT_PLATFORM) {
      return;
    }

    if (!isNullOrUndefined(this.preferenceSet) &&
        !isNullOrUndefined(this.preferenceSet.patentProject.foreignAgentJurisdictions)) {
      const foundVendorFiler = this.preferenceSet.patentProject.foreignAgentJurisdictions.find(agent => {
        if (agent.jurisdictionId === this.country.id &&
          agent.vendorForeignAgent && agent.vendorForeignAgent.id === this.foreignAgentId) {
          return true;
        }
      });

      if (!isNullOrUndefined(foundVendorFiler)) {
        this.filerType = FilerType.CLIENT_PLATFORM;
        return;
      }
    }

    if (!isNullOrUndefined(this.company) && !isNullOrUndefined(this.company.preferredVendors)) {
      const foundPrefVendor = this.company.preferredVendors.find(pv => {
        if (isNullOrUndefined(pv.jurisdiction) || pv.jurisdiction.id !== this.country.id) {
          return false;
        }

        if (pv.vendorId === this.foreignAgentId) {
          return true;
        }

        if (pv.availableVendors &&
            pv.availableVendors
              .find(av => av.vendorForeignAgent && av.vendorForeignAgent.id === this.foreignAgentId)) {
          return true;
        }
      });

      if (!isNullOrUndefined(foundPrefVendor)) {
        this.filerType = FilerType.CLIENT_PLATFORM;
      }
    }
  }
}
