export function isNullOrUndefined(value: any): boolean {
  if (value === null) {
    return true;
  }

  if (value === undefined) {
    return true;
  }

  return false;
}
