import { Injectable } from '@angular/core';
import { ConfigService } from '../../config.service';
import { ConfigDataStoreService } from './config-data-store.service';
import { UserService } from '../../../shared/services/user.service';
import { SystemConfigDTO } from '../../../shared/dto/system-config/system-config-dto';
import { ProjectCategory } from '../../../shared/dto/system-config/project-category';
import { SystemConfigResponseDTO } from '../../../shared/dto/system-config/system-config-response-dto';

@Injectable()
export class ProjectCategoryDataStoreService extends ConfigDataStoreService<ProjectCategory> {

  constructor(configService: ConfigService, userService: UserService) {
    super(configService, userService, 'PROJECT_CATEGORY');
  }

  public create(data: ProjectCategory): Promise<SystemConfigResponseDTO> {
    return null;
  }

  public update(data: ProjectCategory): Promise<SystemConfigResponseDTO> {
    const configObject: SystemConfigDTO = { userId: this.user.id, type: this.type, projectCategoryDTO: data };
    const response = this.configService.updateSystemConfig(configObject);
    response.then(res => {
      this.dataSetPromise.then(dataSet => {
        const index = dataSet.findIndex(dataPoint => dataPoint.id === res.configValueDTO.id);
        dataSet[index] = res.configValueDTO as ProjectCategory;
        this.dataSet.next(dataSet);
        this.dataSetPromise = Promise.resolve(dataSet);
      });
    });

    return response;
  }

  public find(id: number): Promise<ProjectCategory> {
    return Promise.resolve(this.dataSetPromise.then(ds => {
      return ds.find(d => d.id === id);
    }));
  }
}
