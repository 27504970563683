import { CountryPatentService } from './country-patent-service';
import { Currency } from './currency';
import { ConfigValue } from './config-value';
import { Holiday } from './holiday';
import { CountryAnnuitiesConfig } from './country-annuities-config';
import { RegionalOrganization } from './regional-organization';

export interface Country extends ConfigValue {
  name?: string;
  countryCode?: string;
  countryGroup?: string;
  regionalOrganizations?: RegionalOrganization[];
  currency?: Currency;
  daysOff?: string;
  flagged?: boolean;
  flagImageLocation?: string;
  flagClass?: string;
  selected?: boolean;
  filingExtension?: boolean;
  projectType?: string;
  patentServices?: CountryPatentService[];
  countryAnnuitiesConfig?: CountryAnnuitiesConfig[];
  holidays?: Holiday[];
  enableMainSystem?: boolean;
  enableForecaster?: boolean;
}

export interface CountryLite {
  id?: number;
  countryCode?: string;
  name?: string;
}

export enum CountryCodes {
  ARIPO = 'AP',
  EPO = 'EP',
  Eurasia = 'EA',
  Mexico = 'MX',
  OAPI = 'OA',
  WIPO = 'WO', // Also labeled PCT in dropdowns (temporarily), change in system config
  UnitedStates = 'US',
}
