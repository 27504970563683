import { Observable } from 'rxjs';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { OrganizationManagementService } from '../../settings-v2/services/organization-management.service';
import { Injectable } from '@angular/core';

@Injectable()
export class ProjectDeactivateGuard  {
  constructor(private orgManagementService: OrganizationManagementService) {
  }

  public canDeactivate(component: any,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    this.orgManagementService.clear();
    return true;
  }
}
