import { AfterContentInit, Component, ContentChild, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { UserContentDirective, UserImageDirective, UserTypeDirective } from '../user-card.directives';

@Component({
  selector: 'townip-user-card',
  templateUrl: './user-card.component.html',
  styleUrls: ['./user-card.component.scss']
})
export class UserCardComponent implements OnInit, AfterContentInit {

  @Input() // Determine whether content is one-liner and will be trimmed
  public compactContent: boolean;

  @Input()
  public highlight = true;

  @ContentChild(UserImageDirective, /* TODO: add static flag */ {})
  public userImage: UserImageDirective;

  @ContentChild(UserContentDirective, /* TODO: add static flag */ {})
  public userContent: UserContentDirective;

  @ContentChild(UserTypeDirective, /* TODO: add static flag */ {})
  public userType: UserTypeDirective;

  constructor(private elementRef: ElementRef,
              private renderer: Renderer2) {
    this.compactContent = false;
    this.renderer.addClass(this.elementRef.nativeElement, 'disable-hover');
  }

  public ngOnInit(): void {
    if (this.highlight) {
      this.renderer.removeClass(this.elementRef.nativeElement, 'disable-hover');
    }
  }

  public ngAfterContentInit(): void {
    // Debugging for existing user content
  }
}
