<div class="layout-wrapper" [ngClass]="{'app-expanded': isExpanded}">
  <townip-menu [user]="user" [accountManager]="accountManager" [hidden]="printPreview"></townip-menu>
  <div class="layout-main">
    <townip-toolbar [connected]="connected" [user]="user" (clientEstimateEmitter)="handleClientProject($event)"
                    [hidden]="printPreview"></townip-toolbar>
    <div class="layout-content">
      <townip-error-screensize-monitor #screensizeMonitor></townip-error-screensize-monitor>
      <!-- Hide the main outlet if we are showing the screensize error -->
      <!-- Hide only. Do not use ngIf because it will break. -->
      <!-- This prevents the rest of the page from being visible (esp those with long content) -->
      <div [hidden]="screensizeMonitor.showError">
        <townip-maintenance-notice releaseNotesLink="#" #maintenanceNotice></townip-maintenance-notice>
        <townip-loading *ngIf="loadingInd" [text]="loadingText" [styleClass]="firstLoadOverlay ? 'startup' : ''"></townip-loading>
        <router-outlet></router-outlet>
      </div>
    </div>
    <div class="layout-main-mask" (click)="toggleExpanded()"></div>
  </div>
</div>

<!-- The global spacer so that contents are not maxed out to the bottom of the screen -->
<div class="pt-5"></div>

<townip-global-modal></townip-global-modal>

<townip-leave-page-warning-modal [setGlobalInstance]="true"></townip-leave-page-warning-modal>

<!-- The global growl component for the services -->
<p-growl [life]="5000" [sticky]="false"></p-growl>

<townip-new-client-estimate *ngIf="user" #newClientEstimate></townip-new-client-estimate>

<!-- Terms of Use acceptance -->
<ng-container *ngIf="userCompany || vendor">
  <townip-terms-of-service-modal
    #tosModal [term]="systemTerm" (onReject)="onRejectTerms($event)" (onAccept)="onAcceptTerms()">
  </townip-terms-of-service-modal>
</ng-container>

<townip-offline-screen></townip-offline-screen>

<!-- Global mini-loader on the bottom right -->
<townip-global-loader></townip-global-loader>

<!-- Style Index -->
<a *ngIf="canShowDevTools" routerLink="/style-index" class="style-index-bar">
  <i class="fa fa-wrench"></i> Developer Style Index
</a>
