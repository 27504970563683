<div class="alert-container">
  <!--<div class="head"><i class="fa fa-caret-up"></i></div>-->
  <div class="close"><button class="fa-button" (click)="remove()"><i class="fa fa-times"></i></button></div>
  <div class="alert d-flex">
    <div class="alert-icon align-self-center">
      <ng-container *ngIf="metadata?.color; else defaultColor">
        <div class="alert-icon-circle" [ngStyle]="{ borderColor: metadata.color, color: metadata.color }">
          <i class="fa" [ngClass]="iconClass"></i>
        </div>
      </ng-container>
      <ng-template #defaultColor>
        <div class="alert-icon-circle">
          <i class="fa" [ngClass]="iconClass"></i>
        </div>
      </ng-template>
    </div>
    <div class="alert-main flex-grow">
      <div class="alert-subject" *ngIf="alert?.taskDefinition === 'manualAlert'; else sentMessage">{{alert.scopeName}}</div>
      <ng-template #sentMessage>
        <div class="alert-subject">
        {{sender}}
        </div>
        <div class="alert-subject" [ngClass]="{'alert-sub-subject': sender}">
          {{title}}
          <span *ngIf="metadata && showFileAttachments(metadata)" class="fa fa-paperclip"></span>
        </div>
      </ng-template>
      <div class="alert-body margin-bottom-10" style="width: 300px;">
        <div *ngIf="message && options.enableHtml" class="{{options.messageClass}}">
          <townip-trimmed-text
            [fullText]="message"
            (actionEmitter)="onShowMore()"
            [isToast]="true"
            [characters]="275"
            [maxHeight]="160">
          </townip-trimmed-text>
        </div>
        <div *ngIf="message && !options.enableHtml" class="{{options.messageClass}}" [attr.aria-label]="message">
          <townip-trimmed-text
            [fullText]="message"
            (actionEmitter)="onShowMore()"
            [isToast]="true"
            [characters]="275"
            [maxHeight]="160">
          </townip-trimmed-text>
        </div>
      </div>
      <div class="d-flex align-items-center">
        <div class="alert-time">{{ creationTime | relativeTime }}</div>
        <div class="alert-actions text-right ml-auto" *ngIf="metadata?.scope === 'TASK' && metadata?.messageType === 'ALERT' && !metadata.hideScope">
          <a href="#" (click)="$event.preventDefault(); navigate()">Go to task</a>
          <button pButton type="button" label="Snooze" class="ui-button-info primary-action"
                  (click)="action($event)">
          </button>
        </div>
        <div class="alert-actions text-right ml-auto" *ngIf="metadata?.scope === 'PROJECT' && metadata?.messageType === 'ALERT' && !metadata.hideScope">
          <a href="#" (click)="$event.preventDefault(); navigate()">Go to Project</a>
          <button pButton type="button" label="Snooze" class="ui-button-info primary-action"
                  (click)="action($event)">
          </button>
        </div>
        <div class="alert-actions text-right ml-auto" *ngIf="metadata?.messageType === 'GENERAL_MESSAGE'">
          <button pButton type="button" label="Go to message" class="ui-button-info primary-action"
                  (click)="navigate()">
          </button>
        </div>
        <div class="alert-actions text-right ml-auto" *ngIf="metadata?.scope === 'ESTIMATE' && metadata?.messageType === 'ALERT' && !metadata.hideScope">
          <a href="#" (click)="$event.preventDefault(); navigate()">Go to Estimate</a>
          <button pButton type="button" label="Snooze" class="ui-button-info primary-action"
                  (click)="action($event)">
          </button>
        </div>
        <div
          class="alert-actions text-right ml-auto"
          *ngIf="(metadata?.scope === 'PROJECT_FILTER' || metadata?.scope === 'PROJECT_FILTER_V2') && metadata?.messageType === 'FILTER'">
          <button pButton type="button" label="Show Projects" class="ui-button-info primary-action"
                  [ngStyle]="{ backgroundColor: metadata?.color, borderColor: metadata?.color }"
                  (click)="navigate()">
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
