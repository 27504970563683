import { Directive, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { UserService } from '../services/user.service';

@Directive({
  selector: '[townipIfUserHasTransFilingAccess]'
})
export class IfUserHasTransFilingAccessDirective implements OnInit {

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private userService: UserService,
  ) { }

  public ngOnInit(): void {
    if (this.userService.hasTranslationFilingAccess()) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }

}
