import { Injectable } from '@angular/core';
import { Observable, zip } from 'rxjs';
import { PaginatedData, PaginateObject } from '../shared/dto/paginated-data';
import { AnnuityPatentPaginated, AnnuityPatentPaginatedFields } from '../shared/dto/annuities/annuity-patent-paginated';
import { map, tap } from 'rxjs/operators';
import { RequestService } from '../shared/services/request-service';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { AnnuityPatent } from '../shared/dto/annuities/annuity-patent';
import { FieldType } from '../shared/data-filter/field-type';
import { OPERATORS } from '../shared/data-filter/filter-operators';
import { RsqlEncoder } from '../shared/data-filter/rsql-encoder';
import { FilterScope, PAGINATE_NO_LIMIT } from '../shared/query/paginated-request.component';
import { RequestFilterNumberResponse } from '../shared/query/request-filter.component';
import { AnnuityPaymentType } from '../shared/dto/annuities/annuity-payment-type';
import { FileKeyPair } from '../shared/dto/file-key';
import { UserService } from '../shared/services/user.service';
import { AnnuityPatentSearchResponse } from '../shared/dto/annuities/annuity-patent-search-response';
import { AnnuityBulkResponse } from '../shared/dto/annuities/annuity-bulk-response';
import { AnnuityBulkRequest } from '../shared/dto/annuities/annuity-bulk-request';
import { PatentHistory } from '../shared/dto/annuities/patent-history';
import { AnnuityOption } from '../shared/dto/annuities/annuity-option';
import { AnnuityOptionUpdateRequest } from '../shared/dto/annuities/annuity-option-update-request';
import { HttpRequestErrorInterceptorMessages } from '../shared/interceptors/http-request-error-interceptor-messages';
import { AnnuityOptionUpdateResponse } from '../shared/dto/annuities/annuity-option-update-response';
import { cloneDeep } from '../shared/clone-deep';
import { StopProcessingAnnuityRequest } from '../shared/dto/annuities/stop-processing-annuity-request';
import { AnnuityStatusAction } from '../shared/dto/annuities/annuity-status-action';
import { AnnuityPatentBulkIpRightActionsResponse } from '../shared/dto/annuities/annuity-patent-bulk-Ip-right-actions-response';
import { AnnuityPatentBulkRemoveResponse } from '../shared/dto/annuities/annuity-patent-bulk-remove-response';
import { AnnuityCompletePatentVerificationRequest } from '../shared/dto/annuities/annuity-complete-patent-verification-request';
import { AnnuityCompleteClientVerificationRequest } from '../shared/dto/annuities/annuity-complete-client-verification-request';
import { AnnuityPatentChild } from '../shared/dto/annuities/annuity-patent-child';
import { sortRenewalOptionChild } from '../shared/sort/sort-renewal-option-child';
import _isEmpty from 'lodash/isEmpty';

@Injectable({
  providedIn: 'root'
})
export class AnnuityPatentService {

  constructor(
    private httpClient: HttpClient,
    private requestService: RequestService,
    private userService: UserService,
    private errorInterceptor: HttpRequestErrorInterceptorMessages,
  ) { }

  public static patentCleanup(patent: AnnuityPatent): AnnuityPatent {
    const data = cloneDeep(patent);

    // Data cleanups
    if (data.assignees) {
      data.assignees = data.assignees.filter((assign) => {
        try {
          return assign.name.trim().length > 0;
        } catch (e) {
          return false;
        }
      });
    }

    if (data.inventors) {
      data.inventors = data.inventors.filter((inv) => {
        try {
          return inv.trim().length > 0;
        } catch (e) {
          return false;
        }
      });
    }

    return data;
  }

  public getAllPaginated(request: PaginatedData<AnnuityPatentPaginated>): Observable<PaginatedData<AnnuityPatentPaginated>> {
    const url = '/api/annuity/patent/paginated';
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient
      .post<any>(url, request.toObject(), { headers: headers })
      .pipe(
        // Update the given pagination object with values from the response.
        map((response: PaginateObject<AnnuityPatentPaginated>) => request.update(response))
      );
  }

  public getAllPaginatedForSelection(scope: FilterScope = this.userService.paginatedScope, query?: string): Observable<AnnuityPatentPaginated[]> {

    const url = '/api/annuity/patent/paginated';
    const headers = this.requestService.buildHttpHeaders();

    const paginationRequest: PaginatedData<AnnuityPatentPaginated> = new PaginatedData<AnnuityPatentPaginated>();
    paginationRequest.size = PAGINATE_NO_LIMIT;
    paginationRequest.page = 0;
    paginationRequest.query = query ? query : '';
    paginationRequest.scope = scope;

    // Requested columns (probably add everything manually request in the future.
    // https://townip.visualstudio.com/TownIP/_wiki/wikis/TownIP.wiki/78/Filtering-and-Pagination?anchor=annuity-dto
    paginationRequest.addColumnValue(AnnuityPatentPaginatedFields.referenceNumbers);
    paginationRequest.addColumnValue(AnnuityPatentPaginatedFields.nextAnnuityDeadline);

    return this.httpClient
      .post<any>(url, paginationRequest.toObject(), { headers: headers })
      .pipe(
        // Update the given pagination object with values from the response.
        map((response: PaginateObject<AnnuityPatentPaginated>) => response.page.content ? response.page.content : [])
      );
  }

  public getAllWithNoFamily(companyId?: number): Observable<AnnuityPatentPaginated[]> {
    const paginationData = new PaginatedData<AnnuityPatentPaginated>();
    const rsqlEncoder = new RsqlEncoder();

    // We need to manually request the value
    paginationData.addColumnValue(AnnuityPatentPaginatedFields.referenceNumbers);

    paginationData.scope = this.userService.paginatedScope;
    paginationData.size = PAGINATE_NO_LIMIT;
    const familyQuery = rsqlEncoder.encode({
      field: 'familyId',
      type: FieldType.Numeric,
      operator: OPERATORS.common.$isnull,
      value: true,
    });

    let companyQuery = '';
    if (companyId) {
      companyQuery = rsqlEncoder.encode({
        field: 'companyId',
        type: FieldType.Numeric,
        operator: OPERATORS.common.$eq,
        value: companyId,
      });
    }

    paginationData.query = RsqlEncoder.combine([familyQuery, companyQuery]);

    return this.getAllPaginated(paginationData)
      .pipe(
        map(data => data.content ? data.content : [])
      );
  }

  public getAllPatentCount(request: PaginatedData<AnnuityPatentPaginated>): Observable<RequestFilterNumberResponse> {
    const url = '/api/annuity/patent/counts';
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient.post<RequestFilterNumberResponse>(url, request.toObject(), { headers });
  }

  public getByFamilyId(id: number, companyId: number, scope?: FilterScope): Observable<AnnuityPatentPaginated[]> {
    const paginationData = new PaginatedData<AnnuityPatentPaginated>();
    const rsqlEncoder = new RsqlEncoder();

    // We need to manually request the value
    paginationData.addColumnValue(AnnuityPatentPaginatedFields.referenceNumbers);
    paginationData.addColumnValue(AnnuityPatentPaginatedFields.referenceNumberString);

    paginationData.scope = scope ? scope : this.userService.paginatedScope;
    paginationData.size = PAGINATE_NO_LIMIT;

    paginationData.query = rsqlEncoder.encodeGroup(
      [
        {
          field: 'familyId',
          type: FieldType.Numeric,
          operator: OPERATORS.common.$eq,
          value: id,
        },
        {
          field: 'companyId',
          type: FieldType.Numeric,
          operator: OPERATORS.common.$eq,
          value: companyId,
        }
      ]
    );

    return this.getAllPaginated(paginationData)
      .pipe(
        map(data => data.content ? data.content : [])
      );
  }

  /**
   * Get by specific ids and family Ids.
   * If you want to ignore id's, pass a blank array.
   * @param ids
   * @param familyId
   */
  public getIdsAndByFamilyId(ids: number[], familyId: number): Observable<AnnuityPatentPaginated[]> {
    const paginationData = new PaginatedData<AnnuityPatentPaginated>();
    const rsqlEncoder = new RsqlEncoder();

    // We need to manually request the value
    paginationData.addColumnValue(AnnuityPatentPaginatedFields.referenceNumbers);
    paginationData.addColumnValue(AnnuityPatentPaginatedFields.referenceNumberString);

    paginationData.scope = this.userService.paginatedScope;
    paginationData.size = PAGINATE_NO_LIMIT;

    const familyQuery = {
      field: 'familyId',
      type: FieldType.Numeric,
      operator: OPERATORS.common.$eq,
      value: familyId,
    };

    const idsQuery = {
      field: 'id',
      type: FieldType.Numeric,
      operator: OPERATORS.common.$in,
      value: ids,
    };

    // Default query = family only.
    paginationData.query = rsqlEncoder.encode(familyQuery);

    // If we have both.
    if (ids && ids.length) {
      paginationData.query = rsqlEncoder.encodeGroup([familyQuery, idsQuery]);
    }

    return this.getAllPaginated(paginationData)
      .pipe(
        map(data => data.content ? data.content : [])
      );
  }

  public get(id: number): Observable<AnnuityPatent> {
    const url = `/api/annuity/patent/${id}`;
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient
      .get<AnnuityPatent>(url, { headers });
  }

  public getWithChildren(id: number): Observable<AnnuityPatent> {
    const url = `/api/annuity/patent/${id}`;
    const headers = this.requestService.buildHttpHeaders();

    const patent = this.httpClient
      .get<AnnuityPatent>(url, { headers });

    const children = this.getChildren(id);

    return zip(patent, children)
      .pipe(
        map((resp) => {
          resp[0].childAnnuityPatents = resp[1];
          return resp[0];
        })
      );
  }

  public save(
    annuityPatent: AnnuityPatent,
    emailCustomer: boolean = false,
    isVerification: boolean = false,
    bypassError: boolean = false,
    isQuickImport: boolean = false,
  ): Observable<AnnuityPatent> {
    let url: string;

    if (annuityPatent.id) {
      url = `/api/annuity/patent/${annuityPatent.id}?email=${emailCustomer}&isVerificationProcess=${isVerification}`;
    } else {
      url = `/api/annuity/patent?isQuickImport=${isQuickImport}`;
    }

    if (bypassError) {
      this.errorInterceptor.add({ url, bypass: true });
    }

    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.post<AnnuityPatent>(
      url,
      AnnuityPatentService.patentCleanup(annuityPatent),
      { headers }
    );
  }

  public updateClientInfo(annuityPatent: AnnuityPatent): Observable<AnnuityPatent> {
    const url = `/api/annuity/patent/${annuityPatent.id}/client/info`;
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.post<AnnuityPatent>(url, annuityPatent, { headers });
  }

  public updatePaymentPreference(id: number, paymentPreference: AnnuityPaymentType): Observable<AnnuityPaymentType> {
    const url = `/api/annuity/patent/${id}/payment/preference`;
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.post<AnnuityPaymentType>(url, `"${paymentPreference}"`, { headers });
  }

  public updateVerificationDeadline(id: number, verificationDeadline: string): Observable<AnnuityPatent> {
    const url = `/api/annuity/patent/${id}/verification-deadline`;
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.put<AnnuityPatent>(url, null, {
      headers,
      params: { verificationDeadline }
    });
  }

  public addRelatedFiles(id: number, files: FileKeyPair[]): Observable<FileKeyPair[]> {
    const url = `/api/annuity/patent/${id}/related/files`;
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.post<FileKeyPair[]>(url, files, { headers });
  }

  public getByAnnuityId(id: number): Observable<AnnuityPatent> {
    const url = `/api/annuity/${id}/patent`;
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient
      .get<AnnuityPatent>(url, { headers });
  }

  public search(patentNumber: string, companyId: number): Observable<AnnuityPatentSearchResponse> {
    const url = '/api/annuity/patent/search';

    return this.httpClient.post<AnnuityPatentSearchResponse>(url, null, {
      headers: this.requestService.buildHttpHeaders(),
      params: { patentNumber, companyId: `${companyId}` },
    });
  }

  public bulkImport(data: AnnuityBulkRequest): Observable<AnnuityBulkResponse> {
    const url = '/api/annuity/patent/bulk/import';
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.post<any>(url, data, { headers });
  }

  public getBulkImportTemplate(companyId: number): Observable<HttpResponse<Blob>> {
    const url = `/api/annuity/patent/bulk/template/customer/${companyId}`;
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient
      .get(url, { headers, observe: 'response', responseType: 'blob' });
  }

  public saveBulkImportPatents(data: AnnuityPatent[]): Observable<AnnuityPatent[]> {
    const url = '/api/annuity/patent/bulk';
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.post<AnnuityPatent[]>(url, data, { headers });
  }

  public getHistory(patentId: number): Observable<PatentHistory[]> {
    const url = `/api/annuity/patent/${patentId}/history`;
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient.get<PatentHistory[]>(url, { headers });
  }

  public generateAnnuityOptions(patentId: number, bypassError: boolean = false): Observable<AnnuityOptionUpdateResponse> {
    const url = `/api/annuity/patent/${patentId}/generate-annuity-options`;
    const headers = this.requestService.buildHttpHeaders();

    if (bypassError) {
      this.errorInterceptor.add({ url, bypass: true });
    }

    return this.httpClient
      .get<AnnuityOptionUpdateResponse>(url, { headers })
      .pipe(
        tap(resp => {
          sortRenewalOptionChild(resp.annuityPatentVerificationChildren);
        })
      );
  }

  public generateAnnuityOptionWithInstruction(patentId: number, request: AnnuityOption): Observable<AnnuityOption> {
    const url = `/api/annuity/patent/${patentId}/generate-annuity-options-with-additional-instruction`;
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient.post<AnnuityOption>(url, request, { headers });
  }

  public updateAnnuityOptions(request: AnnuityOptionUpdateRequest): Observable<AnnuityOption[]> {
    const url = '/api/annuity/patent/update-annuity-options';
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient.post<AnnuityOption[]>(url, request, { headers });
  }

  public completeAnnuityVerification(
    request: AnnuityCompletePatentVerificationRequest,
    bypassError: boolean = false
  ): Observable<AnnuityPatent> {
    const url = '/api/annuity/patent/complete-verification';
    const headers = this.requestService.buildHttpHeaders();

    if (bypassError) {
      this.errorInterceptor.add({ url, bypass: true });
    }

    for (const option of request.annuityPatentVerificationRequest.annuityOptions) {
      if (_isEmpty(option.additionalInstruction)) {
        option.additionalInstruction = null;
      }
    }

    return this.httpClient.post<AnnuityPatent>(url, request, { headers });
  }

  public completeAnnuityClientVerification(request: AnnuityCompleteClientVerificationRequest): Observable<AnnuityCompleteClientVerificationRequest> {
    const url = '/api/annuity/patent/client-complete-verification';
    const headers = this.requestService.buildHttpHeaders();
    const httpRequest = this.httpClient.post<AnnuityCompleteClientVerificationRequest>(url, request, { headers });

    if (!request.selectedAnnuityOption.autoInstructed) {
      // We must remove the partial annuity if the user decided to instruct later
      // This is to prevent error if we set partial annuity to true and there is no provided value
      delete request.selectedAnnuityOption.additionalInstruction;
      return httpRequest;
    }

    if (!request.selectedAnnuityOption.additionalInstruction) {
      return httpRequest;
    }

    // Set partialDesignatedState to false if there is no provided states
    if (request.selectedAnnuityOption.additionalInstruction.partialDesignatedState) {
      if (!request.selectedAnnuityOption.additionalInstruction.partialDesignatedStates
        || !request.selectedAnnuityOption.additionalInstruction.partialDesignatedStates.length) {
        request.selectedAnnuityOption.additionalInstruction.partialDesignatedState = false;
      }
    }

    // Set partialRenewal to false if there is no provided designs
    if (request.selectedAnnuityOption.additionalInstruction.partialRenewal) {
      if (!request.selectedAnnuityOption.additionalInstruction.partialSubDesigns
        || !request.selectedAnnuityOption.additionalInstruction.partialSubDesigns.length) {
        request.selectedAnnuityOption.additionalInstruction.partialRenewal = false;
      }
    }

    return httpRequest;
  }

  public removeFutureAnnuities(request: StopProcessingAnnuityRequest): Observable<AnnuityPatent> {
    const url = '/api/annuity/patent/stop-processing-annuities';
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient.post<AnnuityPatent>(url, request, { headers });
  }

  public removeBulkFutureAnnuities(
    request: StopProcessingAnnuityRequest[],
  ): Observable<AnnuityPatentBulkRemoveResponse> {
    const url = '/api/annuity/patent/bulk/stop-processing-annuities';
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient.post<AnnuityPatentBulkRemoveResponse>(url, request, { headers });
  }

  public getIpRightStatusActions(id: number): Observable<AnnuityStatusAction[]> {
    const url = `/api/annuity/patent/${id}/ip-right-status-actions`;
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient.get<AnnuityStatusAction[]>(url, { headers });
  }

  public getBulkIpRightStatusActions(ids: number[]): Observable<AnnuityPatentBulkIpRightActionsResponse> {
    const url = '/api/annuity/patent/bulk/ip-right-status-actions';
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient.post<AnnuityPatentBulkIpRightActionsResponse>(url, ids, { headers });
  }

  public resumeFutureAnnuities(id: number, request: AnnuityOptionUpdateRequest): Observable<AnnuityPatent> {
    const url = `/api/annuity/patent/${id}/resume-processing-annuities`;
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient.post<AnnuityPatent>(url, request, { headers });
  }

  public getChildren(id: number): Observable<AnnuityPatentChild[]> {
    const url = `/api/annuity/patent/${id}/children`;
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient.get<AnnuityPatentChild[]>(url, { headers });
  }
}
