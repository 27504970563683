import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Vendor } from '../shared/dto/vendor-management/vendor';
import { RequestService } from '../shared/services/request-service';
import { HttpClient } from '@angular/common/http';
import { MarginAnalysisRequest } from '../shared/dto/finances/margin-analysis-request';
import { MarginAnalysisResponse } from '../shared/dto/finances/margin-analysis-response';
import { TaskRate } from '../shared/dto/projects/task-rate';

@Injectable()
export class AssignmentService {

  constructor(private httpClient: HttpClient,
              private requestService: RequestService) {
  }

  public getVendorForLanguagePairServiceByTaskIds(taskIds: number[]): Promise<Vendor[]> {
    const url = '/api/assignment/vendor/bulk/assign/active/service';
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.post<Vendor[]>(url, taskIds, { headers: headers })
      .toPromise();
  }

  public getLanguagePairServiceByTaskIdsAndVendorId(taskIds: number[], vendorId: number): Promise<Vendor> {
    const url = `/api/assignment/vendor/${vendorId}/tasks/filter/service`;
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.post<Vendor>(url, taskIds, { headers: headers })
      .toPromise();
  }

  public getAssignableVendorMargins(request: MarginAnalysisRequest): Observable<MarginAnalysisResponse> {
    const url = '/api/task/assignment/margins';
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.post<MarginAnalysisResponse>(url, request, { headers: headers });
  }

  public getAssignableVendorMarginsBulk(request: MarginAnalysisRequest[]): Observable<MarginAnalysisResponse[]> {
    const url = '/api/task/assignment/margins/bulk';
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.post<MarginAnalysisResponse[]>(url, request, { headers: headers });
  }

  public getFilingRateTotal(taskRate: TaskRate): Observable<TaskRate> {
    const url = '/api/task/calculate-filing-rate';
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient.post<TaskRate>(url, taskRate, { headers: headers });
  }
}
