import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RequestService } from '../shared/services/request-service';
import { HttpClient } from '@angular/common/http';
import { PatentFamily } from '../shared/dto/annuities/patent-family';
import { map } from 'rxjs/operators';
import { PaginatedData, PaginateObject } from '../shared/dto/paginated-data';
import { PAGINATE_NO_LIMIT } from '../shared/query/paginated-request.component';
import { FieldType } from '../shared/data-filter/field-type';
import { OPERATORS } from '../shared/data-filter/filter-operators';
import { RsqlEncoder } from '../shared/data-filter/rsql-encoder';
import { PatentFamilyPaginated, PatentFamilyPaginatedFields } from '../shared/dto/annuities/patent-family-paginated';
import { RequestFilterNumberResponse } from '../shared/query/request-filter.component';
import { GeneralResponseMessage } from '../shared/dto/general-response-message';
import { UserService } from '../shared/services/user.service';

@Injectable()
export class PatentFamilyService {

  constructor(
    private requestService: RequestService,
    private httpClient: HttpClient,
    private userService: UserService,
  ) {
  }

  public getAllPaginated(request: PaginatedData<PatentFamilyPaginated>): Observable<PaginatedData<PatentFamilyPaginated>> {
    const url = '/api/family/paginated';
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient
      .post<any>(url, request.toObject(), { headers: headers })
      .pipe(
        // Update the given pagination object with values from the response.
        map((response: PaginateObject<PatentFamilyPaginated>) => request.update(response))
      );
  }

  public getAllPaginatedForSelection(): Observable<PatentFamilyPaginated[]> {
    const familyPaginated: PaginatedData<PatentFamilyPaginated> = new PaginatedData<PatentFamilyPaginated>();
    familyPaginated.size = PAGINATE_NO_LIMIT;
    familyPaginated.page = 0;
    familyPaginated.query = '';
    familyPaginated.scope = this.userService.paginatedScope;
    familyPaginated.sort(PatentFamilyPaginatedFields.familyName, 1);

    return this.getAllPaginated(familyPaginated)
      .pipe(
        map(data => data.content ? data.content : [])
      );
  }

  public getAllFamilyCount(request: PaginatedData<PatentFamilyPaginated>): Observable<RequestFilterNumberResponse> {
    const url = '/api/family/counts';
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient
      .post<RequestFilterNumberResponse>(url, request.toObject(), { headers: headers });
  }

  public save(patentFamily: PatentFamily): Observable<PatentFamily> {
    const headers = this.requestService.buildHttpHeaders();

    if (!patentFamily.number) {
      delete patentFamily.number;
    }

    if (patentFamily.id) {
      return this.httpClient
        .put<PatentFamily>(`/api/family/${patentFamily.id}`, patentFamily, { headers: headers });
    }

    return this.httpClient.post<PatentFamily>('/api/family', patentFamily, { headers: headers });
  }

  public get(id: number): Observable<PatentFamily> {
    const url = `/api/family/${id}`;
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.get<PatentFamily>(url, { headers: headers });
  }

  public remove(id: number): Observable<GeneralResponseMessage> {
    const url = `/api/family/${id}`;
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.delete<GeneralResponseMessage>(url, { headers: headers });
  }

  public getByName(name: string, companyId: number): Observable<PatentFamilyPaginated[]> {
    // Setup the pagination data
    const paginatedFamily = new PaginatedData<PatentFamilyPaginated>();
    paginatedFamily.scope = this.userService.paginatedScope;
    paginatedFamily.size = PAGINATE_NO_LIMIT;

    paginatedFamily.query = new RsqlEncoder().encodeGroup([
      {
        field: 'familyName',
        type: FieldType.String,
        operator: OPERATORS.common.$eq,
        value: name,
      },
      {
        field: 'companyId',
        type: FieldType.Numeric,
        operator: OPERATORS.common.$eq,
        value: companyId,
      },
    ]);

    return this.getAllPaginated(paginatedFamily)
      .pipe(
        map(data => data.content ? data.content : [])
      );
  }

  public searchByNameOrNumber(
    query: string,
    companyId: number,
    limit: number = 5,
  ): Observable<PatentFamilyPaginated[]> {
    // Setup the pagination data
    const paginatedFamily = new PaginatedData<PatentFamilyPaginated>();
    paginatedFamily.scope = this.userService.paginatedScope;
    paginatedFamily.size = limit;

    const rsqlEncoder = new RsqlEncoder();
    paginatedFamily.query = RsqlEncoder.combine([
      rsqlEncoder.encode({
        field: PatentFamilyPaginatedFields.companyId,
        type: FieldType.Numeric,
        operator: OPERATORS.common.$eq,
        value: companyId
      }),
      rsqlEncoder.encodeGroup([
        {
          field: PatentFamilyPaginatedFields.familyName,
          type: FieldType.String,
          operator: OPERATORS.common.$contains,
          value: query
        },
        {
          field: PatentFamilyPaginatedFields.familyNumber,
          type: FieldType.String,
          operator: OPERATORS.common.$contains,
          value: query
        }
      ], 'or'),
    ]);

    return this.getAllPaginated(paginatedFamily)
      .pipe(
        map(data => data.content ? data.content : [])
      );
  }

  public getByNumber(number: string, companyId: number): Observable<PatentFamilyPaginated[]> {
    // Setup the pagination data
    const paginatedFamily = new PaginatedData<PatentFamily>();
    paginatedFamily.scope = this.userService.paginatedScope;
    paginatedFamily.size = PAGINATE_NO_LIMIT;

    paginatedFamily.query = new RsqlEncoder().encodeGroup([
      {
        field: 'familyNumber',
        type: FieldType.String,
        operator: OPERATORS.common.$eq,
        value: number,
      },
      {
        field: 'companyId',
        type: FieldType.Numeric,
        operator: OPERATORS.common.$eq,
        value: companyId,
      }
    ]);

    return this.getAllPaginated(paginatedFamily)
      .pipe(
        map(data => data.content ? data.content : [])
      );
  }

  public getByCompany(companyId: number): Observable<PatentFamilyPaginated[]> {
    // Setup the pagination data
    const paginatedFamily = new PaginatedData<PatentFamily>();
    paginatedFamily.scope = this.userService.paginatedScope;
    paginatedFamily.size = PAGINATE_NO_LIMIT;
    paginatedFamily.sort(PatentFamilyPaginatedFields.familyName, 1);

    paginatedFamily.query = new RsqlEncoder().encode({
      field: 'companyId',
      type: FieldType.Numeric,
      operator: OPERATORS.common.$eq,
      value: companyId,
    });

    return this.getAllPaginated(paginatedFamily)
      .pipe(
        map(data => data.content ? data.content : [])
      );
  }

  public addPatent(id: number, patentIds: number[]): Observable<PatentFamily> {
    const url = `/api/family/${id}/patents`;
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.put<PatentFamily>(url, patentIds, { headers: headers });
  }

  public removePatent(id: number, patentIds: number[]): Observable<PatentFamily> {
    const url = `/api/family/${id}/patents/remove`;
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.put<PatentFamily>(url, patentIds, { headers: headers });
  }
}
