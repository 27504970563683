import { Component, OnInit } from '@angular/core';

/**
 * Use this temporary page to debug other components quickly
 */

@Component({
  selector: 'townip-debug',
  templateUrl: './debug.component.html',
  styleUrls: ['./debug.component.scss'],
})
export class DebugComponent implements OnInit {

  public editorText = `
    <p>Why hello <strong>there</strong></p>
    <table>
     <thead>
         <tr>
          <td>Col 1</td>
          <td>Col 2</td>
         </tr>
     </thead>
     <tbody>
         <tr>
          <td>Col 1</td>
          <td>Col 2</td>
         </tr>
     </tbody>
    </table>
    `;

  private testData: any[];

  constructor() {
    // Copied over from prime example
    const t = `{
      "data": [
        {"brand": "VW", "year": 2012, "color": "Orange", "vin": "dsad231ff"},
        {"brand": "Audi", "year": 2011, "color": "Black", "vin": "gwregre345"},
        {"brand": "Renault", "year": 2005, "color": "Gray", "vin": "h354htr"},
        {"brand": "BMW", "year": 2003, "color": "Blue", "vin": "j6w54qgh"},
        {"brand": "Mercedes", "year": 1995, "color": "Orange", "vin": "hrtwy34"},
        {"brand": "Volvo", "year": 2005, "color": "Black", "vin": "jejtyj"},
        {"brand": "Honda", "year": 2012, "color": "Yellow", "vin": "g43gr"},
        {"brand": "Jaguar", "year": 2013, "color": "Orange", "vin": "greg34"},
        {"brand": "Ford", "year": 2000, "color": "Black", "vin": "h54hw5"},
        {"brand": "Fiat", "year": 2013, "color": "Red", "vin": "245t2s"}
      ]
    }`;

    this.testData = JSON.parse(t).data;

  }

  public ngOnInit(): void {
  }
}
