import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextHighlighterModule } from '../text-highlighter/text-highlighter.module';
import { SearchSystemComponent } from './search-system.component';
import { FormsModule } from '@angular/forms';
import { KeyFilterModule } from 'primeng/keyfilter';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TableModule } from 'primeng/table';
import { ScopeFilterPipe } from './pipes/scope-filter.pipe';
import { VirtualScrollerModule } from 'primeng/virtualscroller';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TableModule,
    OverlayPanelModule,
    KeyFilterModule,
    VirtualScrollerModule,
    TextHighlighterModule,
  ],
  exports: [SearchSystemComponent],
  declarations: [SearchSystemComponent, ScopeFilterPipe]
})
export class SearchSystemModule { }
