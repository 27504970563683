import { ReferenceNumber } from '../../../estimate/create-estimate-v2/shared/reference-number';
import { AnnuityVerificationStatus } from './annuity-verification-status';

// FIELDS REFERENCE:
// https://townip.visualstudio.com/TownIP/_wiki/wikis/TownIP.wiki/78/Filtering-and-Pagination?anchor=annuity-patent-dto

export enum AnnuityPatentPaginatedFields {
  id = 'id',
  endClientId = 'endClientId',
  endClient = 'endClient',
  countryId = 'countryId',
  country = 'country',
  patentIdentifier = 'patentIdentifier',
  ipRightType = 'ipRightType',
  familyId = 'familyId',
  familyName = 'familyName',
  familyNumber = 'familyNumber',
  referenceNumbers = 'referenceNumbers',
  referenceNumberString = 'referenceNumberString',
  companyId = 'companyId',
  companyName = 'companyName',
  clientContactName = 'clientContactName',
  lastModifiedDate = 'lastModifiedDate',
  verificationDeadline = 'verificationDeadline',
  ipRightStatus = 'ipRightStatus',
  annuityPatentIssuesString = 'annuityPatentIssuesString',
  nextAnnuityDeadline = 'nextAnnuityDeadline',
  removeFutureAnnuityReason = 'removeFutureAnnuityReason',
  realCompanyId = 'realCompanyId',
  // Anything you add here you must add below in the Paginated Model
}

export interface AnnuityPatentPaginated {
  id: number;
  endClientId: number;
  endClient: string;
  countryId: number;
  country: string;
  patentNumber: string;
  patentIdentifier: string;
  ipRightType?: string;
  familyId: number;
  familyName: string;
  familyNumber: string;
  referenceNumbers: ReferenceNumber[];
  referenceNumberString: string[];
  companyId: number;
  companyName?: string;
  clientContactName: string;
  lastModifiedDate: number;
  verificationDeadline?: string;
  ipRightStatus?: AnnuityVerificationStatus;
  annuityPatentIssuesString?: string;
  nextAnnuityDeadline?: string;
  removeFutureAnnuityReason?: string;
  realCompanyId?: number;
  // Anything you add here you must add above in the ENUM
}
