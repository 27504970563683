import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'townip-error-screensize',
  templateUrl: './error-screensize.component.html',
  styleUrls: ['./error-screensize.component.scss']
})
export class ErrorScreensizeComponent implements OnInit {

  constructor() { }

  public ngOnInit(): void {
  }

}
