import { Component, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { NavService } from '../nav.service';
import { PortalUser } from '../../../shared/domain/user';
import { from, Subscription, timer } from 'rxjs';
import { Router } from '@angular/router';
import { UserPermCache, UserService } from '../../../shared/services/user.service';
import { WebsocketService } from '../../../shared/services/websocket.service';

@Component({
  selector: 'townip-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit, OnChanges, OnDestroy {

  @Input()
  public connected: boolean;

  @Input()
  public user: PortalUser;

  @Output()
  public clientEstimateEmitter: EventEmitter<string>;

  public expanded = false;

  public expandedSub: Subscription;

  public isAdminPM: boolean;

  public isCompanyUser: boolean;

  public isForecaster: boolean;

  public isForecasterOnly: boolean;

  public isTestServer: boolean;

  public projectQuery = JSON.stringify({ project: true });

  public isSalesAccountManager: boolean;

  public isEditableInternalUser: boolean;

  public noLogout = true;

  private waitTilEnablingLogout: Subscription;

  /**
   * Message/Alerts params.
   */
  public unreadMessageCount: number;
  public unreadAlertCount: number;

  public userPermissions: UserPermCache;

  constructor(public navService: NavService,
              private userService: UserService,
              private websockets: WebsocketService,
              private router: Router) {
    this.clientEstimateEmitter = new EventEmitter();
  }

  @HostListener('window:resize', ['$event'])
  public onResize(): void {
    if (window.innerWidth <= 1600) {
      // Close the sidebar and hide the side bar toggle if the screen width size hit less than 1600px
      this.navService.collapseEmitter.emit(false);
    }
  }

  public ngOnInit(): void {
    this.checkIsTestServer();
    this.unreadMessageCount = 0;
    this.unreadAlertCount = 0;
    this.expandedSub = this.navService.collapseEmitter.subscribe(isExpanded => this.expanded = isExpanded);

    this.handleConnectionState(true);
    this.websockets
      .connectedEmitter
      .subscribe((state) => this.handleConnectionState(state));
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (!this.user) {
      this.isCompanyUser = false;
      return;
    }

    if (changes.user && !changes.user.firstChange) {
      this.isAdminPM = (this.userService.authorizations.ROLE_INTERNAL_ADMIN ||
        this.userService.authorizations.ROLE_INTERNAL_PROJECT_MGR);
      this.isEditableInternalUser = this.userService.authorizations.ROLE_INTERNAL_USER &&
        !this.userService.authorizations.ROLE_VIEW_ONLY;
      this.isCompanyUser = this.user.userType === 'COMPANY';
      this.isForecaster = this.userService.isForecaster();
      this.isForecasterOnly = this.userService.isForecasterOnly();
      this.isSalesAccountManager =
        this.userService.authorizations.ROLE_INTERNAL_SALES_MGR ||
        this.userService.authorizations.ROLE_INTERNAL_ACCOUNT_MGR;
      this.userPermissions = this.userService.getPermissions();
    }
  }

  public ngOnDestroy(): void {
    if (this.expandedSub) {
      this.expandedSub.unsubscribe();
    }
  }

  public toggleNavbar(): void {
    this.expanded = this.navService.toggle();
  }


  /**
   * This method updates the message count on the header.
   * @param {number} count
   */
  public updateMessageCount(count: number): void {
    setTimeout(() => this.unreadMessageCount = count);
  }

  /**
   * This method updates the alert count on the header.
   * @param {number} count
   */
  public updateAlertsCount(count: number): void {
    setTimeout(() => this.unreadAlertCount = count);
  }

  public newClientEstimate(): void {
    if (this.userService.authorizations.ROLE_INTERNAL_SALES_MGR ||
      this.userService.authorizations.ROLE_INTERNAL_ACCOUNT_MGR) {
      this.router
        .navigate(['/dashboard/sales-account-manager-v2'])
        .then((routed) => {
          if (routed) {
            this.clientEstimateEmitter.emit('Estimate');
          }
        });
    } else {
      this.router
        .navigate(['/dashboard/project-manager'])
        .then((routed) => {
          if (routed) {
            this.clientEstimateEmitter.emit('Estimate');
          }
        });
    }
  }

  public newClientProject(): void {
    if (this.userService.authorizations.ROLE_INTERNAL_SALES_MGR ||
      this.userService.authorizations.ROLE_INTERNAL_ACCOUNT_MGR) {
      this.router
        .navigate(['/dashboard/sales-account-manager-v2'])
        .then((routed) => {
          if (routed) {
            this.clientEstimateEmitter.emit('Project');
          }
        });
    } else {
      this.router
        .navigate(['/dashboard/project-manager'])
        .then((routed) => {
          if (routed) {
            this.clientEstimateEmitter.emit('Project');
          }
        });
    }
  }

  private handleConnectionState(state: boolean): void {
    this.noLogout = true;

    if (this.waitTilEnablingLogout) {
      this.waitTilEnablingLogout.unsubscribe();
      this.waitTilEnablingLogout = null;
    }

    if (state) {
      this.noLogout = false;
      return;
    }

    // When NOT connected, always wait 7 seconds before enabling the logout
    // This makes sure all other processes are completed before enabling it
    // Prevents errors when logging out.
    // We need a smarter way to do this on 3.5
    this.waitTilEnablingLogout = from(timer(7000))
      .subscribe(() => {
        this.noLogout = false;
      });
  }

  public checkIsTestServer(): void {
    const hostLocation: string = window.location.host;

    if (hostLocation !== 'chiefip.legalsight.com' &&
      hostLocation !== 'sunip.legalsight.com' &&
      hostLocation !== 'account.legalsight.com') {
      this.isTestServer = true;
    }
  }
}
