import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Company } from '../shared/dto/company';
import { DeliveryContact } from '../shared/dto/preferences/delivery-contact';
import { CompanyUser } from '../shared/dto/customer-management/company-user';
import { CustomerPreferences } from '../shared/dto/preferences/customer-preferences';
import { BillingInfo } from '../shared/dto/billing-info';
import { CompanyGroup } from '../shared/dto/company-group';
import { PreferredVendor } from '../shared/dto/customer-management/preferred-vendor';
import { RequestService } from '../shared/services/request-service';
import { PortalUser } from '../shared/domain/user';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SystemPricing } from '../shared/dto/system-pricing';
import { CustomerLite } from '../shared/dto/customer-lite';
import { CustomerManagementResponse } from '../shared/dto/customer-management-response';
import { ForeignAgent } from '../shared/dto/preferences/foreign-agent';
import { ForeignAgentJurisdiction } from '../shared/dto/preferences/foreign-agent-jurisdiction';
import { SelectItem } from 'primeng/api';
import { InternalUserDTO } from '../shared/dto/internal-management';
import { CompanyGroupLite } from '../shared/dto/company-group-lite';
import { clone } from '../settings-v2/shared/utils/clone';
import { SystemTerm } from '../shared/dto/system-term';
import { Tag } from '../shared/dto/tag';
import { ForecastPreferences } from '../shared/dto/forecast/forecast-preferences';
import { SaveCostFees } from '../shared/dto/preferences/save-cost-fees';
import { ForecastProfessionalPreferences, ForecastProfessionalPreferencesAddRequest } from '../settings-v2/forecaster-preferences/forecaster-preferences.model';
import { ForecastModelSettings } from '../shared/dto/preferences/forecast-model-settings';
import { ExternalContactGroup } from '../shared/dto/preferences/external-contact-group';
import { CompanyInvoiceContact } from '../shared/dto/company-invoice-contact';
import { CompanyLiteSearch } from '../shared/dto/company-lite-search';
import { CompanyInvoicePreference } from '../shared/dto/company-invoice-preference';
import { ApiQueryOptions, ApiQueryParams } from '../shared/api-query-params';
import { CompanyLite } from '../shared/dto/company-lite';
import DeliveryContactsUtil from '../shared/delivery-contacts-util';
import { UserService } from '../shared/services/user.service';
import { GeneralResponseMessage } from '../shared/dto/general-response-message';
import { BaseCurrencyIsoCode } from '../shared/enums/base-currency-iso-code';
import { CompanyCurrencyInfo } from '../shared/dto/company-currency-info';
import { CompanyAnnuityPrefs } from '../shared/dto/annuities/company-annuity-prefs';
import { CompanyCustomerAccess } from '../shared/dto/customer-management/company-customer-access';
import { HttpRequestErrorInterceptorMessages, } from '../shared/interceptors/http-request-error-interceptor-messages';
import { map } from 'rxjs/operators';
import { sortBy } from '../shared/utils/sort-by';
import { AnnuityPaymentType } from '../shared/dto/annuities/annuity-payment-type';
import { CompanyAnnuityContact } from '../shared/dto/annuities/company-annuity-contact';
import { AnnuityPatentApplicant } from '../shared/dto/annuities/annuity-patent';
import { ApplicantCountry } from '../shared/dto/customer-management/applicant-country';
import { generateFullname } from '../shared/overview-utilities/generate-fullname';
import { Applicant } from '../shared/dto/customer-management/applicant';
import { isNullOrUndefined } from '../shared/utils/is-null-or-undefined';
import { UserType } from '../main/navigation/menu/menu.component';
import { Params } from '@angular/router';
import { FileKeyPair } from '../shared/dto/file-key';
import { PaginatedData, PaginateObject } from '../shared/dto/paginated-data';
import { CompanyPaginated } from '../shared/dto/vendor-management/company-paginated';
import { CompanyTmWeights } from '../shared/dto/company-tm-weights';
import { InvoicePaginated, InvoicePaginatedFields } from '../shared/dto/invoice-paginated';
import { FilterScope, PAGINATE_NO_LIMIT } from '../shared/query/paginated-request.component';
import { RsqlEncoder } from '../shared/data-filter/rsql-encoder';
import { FieldType } from '../shared/data-filter/field-type';
import { OPERATORS } from '../shared/data-filter/filter-operators';
import { EmailAvailabilityResponse } from '../shared/dto/email-availability-response';
import { EndClient } from '../shared/dto/end-client';
import { sortByString } from '../shared/pipes/sort-by-string.pipe';
import { CompanyRenewalReminder } from '../shared/dto/annuities/company-renewal-reminder';
import { AnnuityCompanyDetails } from '../shared/dto/annuity-company-details';
import { ProjectTypeEnum } from '../shared/enums/project-type-enum';
import { InvoicePreferenceType } from '../shared/dto/invoice';
import { AnnuityInstructionConfirmationType } from '../shared/dto/annuities/annuity-instruction-confirmation';

@Injectable()
export class CustomerManagementService {
  private company: Company;
  private team: CompanyGroupLite;
  private companyUser: CompanyUser;
  private companyGroups: CompanyGroup[];
  public companyObservable: Observable<Company>;
  public companiesObservable: Observable<Company[]>;
  public companyLitesObservable: Observable<CompanyLite[]>;
  public companyUserObservable: Observable<CompanyUser>;
  public companyGroupObservable: Observable<CompanyGroup>;

  constructor(private http: HttpClient,
              private requestService: RequestService,
              private errorInterceptorMsgs: HttpRequestErrorInterceptorMessages,
              private userService: UserService) {

    this.userService.logoutEmitter.subscribe((() => {
      this.clear();
    }));
  }

  public getAllPaginated(request: PaginatedData<CompanyPaginated>): Observable<PaginatedData<CompanyPaginated>> {
    const url = '/api/company/paginated';
    const headers = this.requestService.buildHttpHeaders();

    return this.http
      .post<any>(url, request.toObject(), { headers: headers })
      .pipe(
        // Update the given pagination object with values from the response.
        map((response: PaginateObject<CompanyPaginated>) => request.update(response))
      );
  }

  public getCompanyById(companyId: number, opts: { bypassErrorMessages?: boolean } = {}): Promise<Company> {
    const url = `/api/company/${companyId}`;

    if (opts.bypassErrorMessages) {
      this.errorInterceptorMsgs.add({ url, bypass: true });
    }

    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.http
      .get<Company>(url, { headers: headers })
      .pipe(
        map(company => {
          const sortedCompanyUsers = sortBy(company.companyUsers,
            (user: CompanyUser) => {
              return generateFullname(user.user)
                .toUpperCase();
            });

          return {
            ...company,
            companyUsers: sortedCompanyUsers,
          };
        })
      )
      .toPromise();
  }

  public getAnnuityCompanyDetailsById(companyId: number, opts: {
    bypassErrorMessages?: boolean
  } = {}): Observable<AnnuityCompanyDetails> {
    const url = `/api/company/${companyId}/renewal-data`;

    if (opts.bypassErrorMessages) {
      this.errorInterceptorMsgs.add({ url, bypass: true });
    }

    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.http
      .get<AnnuityCompanyDetails>(url, { headers: headers });
  }

  public getCompanyLites(): Observable<CompanyLite[]> {
    const url = '/api/company-lite';
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.companyLitesObservable = this.http
      .get<CompanyLite[]>(url, { headers: headers })
      .pipe(
        map(val => sortBy(val, 'companyName'))
      );
  }

  public getCompanyLitesByUserIdAndCompanyService(userId: number, companyService: string): Observable<CompanyLite[]> {
    const url = `api/company-lite/service/${companyService}/internal/user/${userId}`;
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.companyLitesObservable = this.http
      .get<CompanyLite[]>(url, { headers: headers })
      .pipe(
        map(companies => {
          return sortBy(companies,
            (company: CompanyLite) => {
              return company
                .companyName
                .toUpperCase();
            });
        })
      );
  }

  public getCompanyUsersByUserIdAndCompanyService(companyId: number, userId: number): Observable<CompanyUser[]> {
    const url = `/api/company-user/company/${companyId}/internal/user/${userId}`;
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.companyLitesObservable = this.http
      .get<CompanyUser[]>(url, { headers: headers })
      .pipe(
        map(users => {
          return sortBy(users,
            (user: CompanyUser) => {
              return generateFullname(user.user);
            });
        })
      );
  }

  public retrieveCompany(companyId: number): Promise<Company> {
    if (!!this.company && this.company.idfr === companyId) {
      return new Promise(resolve => resolve(this.company));
    }
    return new Promise((resolve, reject) => {
      const url = `/api/company/${companyId}`;
      const headers: HttpHeaders = this.requestService.buildHttpHeaders();
      this.companyObservable = this.http.get<Company>(url, { headers: headers });
      this.companyObservable.subscribe(c => {
        this.company = c;
        resolve(c);
      });
    });
  }

  public retrieveCompanies(): Observable<CompanyLiteSearch[]> {
    const url = '/api/company';
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.companiesObservable = this.http.get<CompanyLiteSearch[]>(url, { headers: headers });
  }

  public setCompany(company: Company): void {
    this.company = company;
  }

  public setTeam(team: CompanyGroupLite): void {
    this.team = team;
  }

  public getCompanyUserById(companyId: number, companyUserId: number): Promise<CompanyUser> {
    const url = `/api/company/${companyId}/user/${companyUserId}`;
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.http.get<CompanyUser>(url, { headers: headers })
      .toPromise();
  }

  public retrieveCompanyUser(companyId: number, companyUserId: number): Promise<CompanyUser> {
    return new Promise((resolve, reject) => {
      const url = `/api/company/${companyId}/user/${companyUserId}`;
      const headers: HttpHeaders = this.requestService.buildHttpHeaders();
      this.companyUserObservable = this.http.get<CompanyUser>(url, { headers: headers });
      this.companyUserObservable.subscribe(cu => {
        this.companyUser = cu;
        resolve(cu);
      });
    });
  }

  public retrieveCompanyGroup(companyId: number, companyGroupId: number): Observable<CompanyGroup> {
    const url = `/api/company/${companyId}/group/${companyGroupId}`;
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    this.companyGroupObservable = this.http.get<CompanyGroup>(url, { headers: headers });
    this.companyGroupObservable.subscribe(cg => {
      this.setCompanyGroup(cg);
    });
    return this.companyGroupObservable;
  }

  public retrieveGroupPricing(companyId: number, companyGroupId: number): Observable<SystemPricing> {
    const url = `/api/company/${companyId}/group/${companyGroupId}/pricing`;
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.http.get<SystemPricing>(url, { headers: headers });
  }

  public updateCompany(company: Company): Promise<Company> {
    // Removing any of the non needed company values to avoid transferring extra data.
    const clonedCompany: Company = clone(company);
    clonedCompany.companyUsers = [];
    clonedCompany.customerPreferences = [];
    clonedCompany.companyGroups = [];
    clonedCompany.preferredPricing = undefined;
    clonedCompany.preferredVendors = [];
    clonedCompany.deliveryContacts = [];
    clonedCompany.preferredJurisdictions = undefined;
    const url = `/api/company/${company.idfr}`;
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.http.put<Company>(url, clonedCompany, { headers: headers })
      .toPromise();
  }

  public updateCompanyInvoiceContact(companyInvoiceContact: CompanyInvoiceContact, companyId: number): Promise<any> {
    const clonedContact: CompanyInvoiceContact = clone(companyInvoiceContact);
    const url = `/api/company/${companyId}/invoice/contact`;
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.http.put<CompanyInvoiceContact>(url, clonedContact, { headers: headers })
      .toPromise();
  }

  public updateCompanyInstruction(companyId: number, scopeType: string, instruction: string): Promise<Company> {
    const url = `/api/company/${companyId}/scope/${scopeType}/instruction`;
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.http.put<Company>(url, instruction, { headers: headers })
      .toPromise();
  }

  public getCompanyInstruction(companyId: number, scopeType: string): Promise<Company> {
    // note since return only string does not work, backend will return a company dto with only instruction attached.
    const url = `/api/company/${companyId}/scope/${scopeType}/instruction`;
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.http.get<Company>(url, { headers: headers })
      .toPromise();
  }

  public updateCompanyForecastMessaging(companyId: number): Promise<any> {
    const url = `/api/company/${companyId}/update/forecast/messaging`;
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.http.put<Company>(url, { headers: headers })
      .toPromise();
  }

  public toggleCompanyStatus(companyId: number): Promise<Company> {
    const url = `/api/company/${companyId}/status`;
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.http.put<Company>(url, null, { headers: headers })
      .toPromise();
  }

  public createCompany(company: Company): Observable<Company> {
    const url = '/api/company';
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.http.post<Company>(url, company, { headers: headers });
  }

  public addCompanyUser(companyId: number, companyUser: CompanyUser): Promise<CompanyUser> {
    const url = `/api/company/${companyId}/user`;
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.http.post<CompanyUser>(url, companyUser, { headers: headers })
      .toPromise();
  }

  public updateCompanyGroup(companyId: number, team: CompanyGroup): Promise<CompanyGroup> {
    const url = `/api/company/${companyId}/group/${team.groupIdfr}`;
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.http.put<CompanyGroup>(url, team, { headers: headers })
      .toPromise();
  }

  public addCompanyUsersToGroup(companyId: number, groupId: number, companyUserIds: number[]): Promise<CustomerLite[]> {
    const url = `/api/company/${companyId}/group/${groupId}/users`;
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.http.post<CustomerLite[]>(url, companyUserIds, { headers: headers })
      .toPromise();
  }

  public addCompanyUserToGroups(companyId: number, userId: number, groupIds: number[]): Promise<CustomerLite> {
    const url = `/api/company/${companyId}/user/${userId}/groups`;
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.http.post<CustomerLite>(url, groupIds, { headers: headers })
      .toPromise();
  }

  public deactivateCompanyUser(companyId: number, companyUserId: number): Promise<CompanyUser> {
    const url = `/api/company/${companyId}/user/${companyUserId}`;
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.http.delete<CompanyUser>(url, { headers: headers })
      .toPromise();
  }

  public activateCompanyUser(companyId: number, companyUserId: number): Promise<CompanyUser> {
    const url = `/api/company/${companyId}/user/${companyUserId}`;
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.http.put<CompanyUser>(url, null, { headers: headers })
      .toPromise();
  }

  public removeUsersFromGroup(companyId: number,
    groupId: number,
    companyUserIds: number[]): Promise<CustomerManagementResponse> {
    const url = `/api/company/${companyId}/group/${groupId}/removeUsers`;
    const headers = this.requestService.buildHttpHeaders();
    // TODO: Response type dto for removeUsersFromGroup
    // Note: Checked the usage and it returns a string from the api. Our header indicates that api always returns json.
    // Might need to convert the actual api response into a json.
    return this.http.put<CustomerManagementResponse>(url, companyUserIds, { headers: headers })
      .toPromise();
  }

  public addTeam(companyId: number, team: CompanyGroup): Promise<CompanyGroup> {
    const url = `/api/company/${companyId}/group`;
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.http.post<CompanyGroup>(url, team, { headers: headers })
      .toPromise();
  }

  public toggleGroupStatus(companyId: number, groupId: number): Promise<CompanyGroup> {
    const url = `/api/company/${companyId}/group/${groupId}`;
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.http.delete<CompanyGroup>(url, { headers: headers })
      .toPromise();
  }

  /**
   * This method will return the prefernces for the given preference number
   * @param {number} preferenceId
   * @returns {Observable<CustomerPreferences>}
   */
  public getPreferences(preferenceId: number): Observable<CustomerPreferences> {
    const url = `/api/company/preferences/${preferenceId}`;
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.http.get<CustomerPreferences>(url, { headers: headers });
  }

  /**
   * Method up add a customer preference object at the Company, Group, or Customer level.
   * @param {number} companyId
   * @param {number} groupId
   * @param {CompanyUser} companyUser
   * @param {CustomerPreferences} preferences
   * @returns {Observable<CustomerPreferences>}
   */
  public addCustomerPreferences(companyId: number,
    groupId: number,
    companyUser: CompanyUser,
    preferences: CustomerPreferences): Observable<CustomerPreferences> {
    let url: string;
    if (preferences.preferenceType === 'COMPANY_PREFERENCE') {
      url = `/api/company/${companyId}/preferences`;
    } else if (preferences.preferenceType === 'GROUP_PREFERENCE') {
      url = `/api/company/${companyId}/group/${groupId}/preferences`;
    } else if (preferences.preferenceType === 'USER_PREFERENCE') {
      url = `/api/company/${companyId}/user/${companyUser.uniqueIdentifier}/preferences`;
    } else {
      // TODO
      url = `/api/company/${companyId}/preferences`;
    }
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.http.post<CustomerPreferences>(url, preferences, { headers: headers });
  }

  /**
   * Method to update a customer preference object at the company, group, or customer level.
   * @param {CustomerPreferences} preferences
   * @returns {Observable<CustomerPreferences>}
   */
  public updateCustomerPreferences(preferences: CustomerPreferences): Observable<CustomerPreferences> {
    const url = `/api/company/preferences/${preferences.preferencesId}`;
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.http.put<CustomerPreferences>(url, preferences, { headers: headers });
  }

  public deletePreferenceSet(preferenceId: number): Promise<CustomerManagementResponse> {
    const url = `/api/company/preferences/${preferenceId}`;
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.http.delete<CustomerManagementResponse>(url, { headers: headers })
      .toPromise();
  }

  public markPreferenceDefault(preferencesId: number): Promise<CustomerPreferences> {
    const url = `/api/company/preferences/${preferencesId}/makeDefault`;
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.http.put<CustomerPreferences>(url, null, { headers: headers })
      .toPromise();
  }

  public addCompanyBilling(companyId: number, billing: BillingInfo): Promise<BillingInfo> {
    const url = `/api/company/${companyId}/billing`;
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.http.post<BillingInfo>(url, billing, { headers: headers })
      .toPromise();
  }

  public updateCompanyBilling(companyId: number, billing: BillingInfo): Promise<BillingInfo> {
    const url = `/api/company/${companyId}/billing/${billing.id}`;
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.http.put<BillingInfo>(url, billing, { headers: headers })
      .toPromise();
  }

  public toggleCompanyBillingDefault(companyId: number, billingId: number): Promise<BillingInfo> {
    const url = `/api/company/${companyId}/billing/${billingId}/toggleDefault`;
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.http.put<BillingInfo>(url, null, { headers: headers })
      .toPromise();
  }

  public setTeamBillingDefault(teamId: number, billingId: number): Promise<BillingInfo> {
    const url = `/api/team/${teamId}/billing/${billingId}/default`;
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.http
      .put<BillingInfo>(url, null, { headers: headers })
      .toPromise();
  }

  public toggleBillingStatus(billingInfoId: number): Promise<BillingInfo> {
    const url = `/api/company/billing/${billingInfoId}/status`;
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.http.put<BillingInfo>(url, null, { headers: headers })
      .toPromise();
  }

  /**
   * Start PreferredVendor methods
   */

  /**
   * This endpoint will add/remove/update any preferred vendors sent in for the given scope/scopeId.
   * @param {string} scope - COMPANY or GROUP
   * @param {number} scopeId - companyId or groupId
   * @param {PreferredVendor[]} preferredVendors
   * @returns {Observable<PreferredVendor[]>}
   */
  public setPreferredVendors(scope: string, scopeId: number,
    preferredVendors: PreferredVendor[]): Observable<PreferredVendor[]> {
    const url = `/api/company/preferred-vendors?scope=${scope}&scopeId=${scopeId}`;
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.http.post<PreferredVendor[]>(url, preferredVendors, { headers: headers });
  }

  /**
   * End PreferredVendor methods
   */

  /**
   * Method to add a new delivery contact. This includes end clients.
   * @param {number} companyId
   * @param {DeliveryContact} deliveryContact
   * @returns {Observable<DeliveryContact>}
   */
  public addCompanyContact(companyId: number, deliveryContact: DeliveryContact): Observable<DeliveryContact> {
    const url = `/api/company/${companyId}/delivery-contact`;
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.http.post<DeliveryContact>(url, deliveryContact, { headers: headers });
  }

  /**
   * Method to Update a delivery contact. This includes end clients.
   * @param {number} companyId
   * @param {DeliveryContact} deliveryContact
   * @returns {Observable<DeliveryContact>}
   */
  public updateCompanyContact(companyId: number, deliveryContact: DeliveryContact): Observable<DeliveryContact> {
    const url = `/api/company/${companyId}/delivery-contact/${deliveryContact.id}`;
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.http.put<DeliveryContact>(url, deliveryContact, { headers: headers });
  }

  public checkForAssociatedOrders(companyId: number,
    contactId: number): Promise<GeneralResponseMessage> {
    const url = `/api/company/${companyId}/delivery-contact/${contactId}/associated-active-orders`;
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.http.get<GeneralResponseMessage>(url, { headers: headers })
      .toPromise();
  }

  public deactivateCompanyContact(companyId: number, contactId: number): Promise<ExternalContactGroup> {
    const url = `/api/company/${companyId}/delivery-contact/${contactId}`;
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.http.delete<ExternalContactGroup>(url, { headers: headers })
      .toPromise();
  }

  public toggleDeliveryContactDefault(companyId: number, deliveryContactId: number): Promise<DeliveryContact> {
    const url = `/api/company/${companyId}/delivery-contact/${deliveryContactId}/toggle-default`;
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.http.put<DeliveryContact>(url, null, { headers: headers })
      .toPromise();
  }

  public makeCompanyUserDefaultContact(companyId: number, companyUserId: number): Promise<DeliveryContact> {
    const url = `/api/company/${companyId}/company-user/${companyUserId}/toggle-default`;
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.http.put<DeliveryContact>(url, { headers: headers })
      .toPromise();
  }

  public makeDeliveryContactsDefault(companyId: number, deliveryContactIds: number[]): Promise<DeliveryContact[]> {
    const url = `/api/company/${companyId}/delivery-contacts/make-default`;
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.http.put<DeliveryContact[]>(url, deliveryContactIds, { headers: headers })
      .toPromise();
  }

  /**
   * Method to get a company's delivery contacts (inactive contacts will not be included).
   * @param {number} companyId
   * @returns {Promise<DeliveryContact[]>}
   */
  public getCompanyDeliveryContacts(companyId: number): Promise<DeliveryContact[]> {
    const url = `/api/company/${companyId}/delivery-contacts`;
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.http.get<DeliveryContact[]>(url, { headers: headers })
      .toPromise();
  }

  /**
   * Method to get a company's delivery contacts with inactive contacts included.
   * @param {number} companyId
   * @returns {Promise<DeliveryContact[]>}
   */
  public getDeliveryContactsIncludeInactive(companyId: number): Promise<DeliveryContact[]> {
    const url = `/api/company/${companyId}/delivery-contacts/all`;
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.http.get<DeliveryContact[]>(url, { headers: headers })
      .toPromise();
  }

  public async getUserDeliveryContacts(orderId: number,
    customerId: number,
    team?: CompanyGroupLite): Promise<SelectItem[]> {
    const teamId = isNullOrUndefined(team) ? undefined : team.id;
    const filteredDeliveryContacts =
      await this.getCompanyDeliveryContactsForOrder(orderId, teamId);
    return DeliveryContactsUtil.buildDeliveryContactOptions(customerId, filteredDeliveryContacts, false);
  }

  public getCompanyDeliveryContactsForOrder(orderId: number, teamId?: number): Promise<DeliveryContact[]> {
    let url = `/api/project/${orderId}/delivery-contacts`;
    if (!isNullOrUndefined(teamId)) {
      const queryOptions: ApiQueryOptions = {
        queryParams: {
          teamId: teamId,
        }
      };
      url += new ApiQueryParams(queryOptions).generateQueryParams();
    }
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.http.get<DeliveryContact[]>(url, { headers: headers })
      .toPromise();
  }

  public getInvoicesByCustomerId(customerId: number): Observable<InvoicePaginated[]> {
    const url = '/api/invoice/paginated';
    const headers = this.requestService.buildHttpHeaders();

    const rsqlEncoder = new RsqlEncoder();
    const request = new PaginatedData<InvoicePaginated>();
    request.size = PAGINATE_NO_LIMIT;
    request.scope = this.userService.isPM() ? FilterScope.MINE : this.userService.paginatedScope;
    request.query = rsqlEncoder.encode({
      field: InvoicePaginatedFields.companyId,
      type: FieldType.Numeric,
      operator: OPERATORS.common.$eq,
      value: customerId,
    });

    return this.http
      .post<PaginateObject<InvoicePaginated>>(url, request.toObject(), { headers })
      .pipe(
        // Update the given pagination object with values from the response.
        map(response => response.page.content ? response.page.content : []),
      );
  }

  public getCompany(): Company {
    return this.company;
  }

  public getTeam(): CompanyGroupLite {
    return this.team;
  }

  public getCompanyUser(): CompanyUser {
    return this.companyUser;
  }

  public getGroupPreferences(groupId: number, companyId: number): Observable<CustomerPreferences[]> {
    const url = `/api/company/${companyId}/group/${groupId}/preferences`;
    const headers = this.requestService.buildHttpHeaders();
    return this.http.get<CustomerPreferences[]>(url, { headers: headers });
  }

  public saveCompanyUser(user: CompanyUser): Promise<CompanyUser> {
    const url = `/api/company/user/${user.uniqueIdentifier}`;
    const headers = this.requestService.buildHttpHeaders();
    return this.http.put<CompanyUser>(url, user, { headers: headers })
      .toPromise();
  }

  public removeCompanyAdmin(companyUserId: number): Observable<CompanyUser> {
    const url = `/api/company/removeAdmin/${companyUserId}`;
    const headers = this.requestService.buildHttpHeaders();
    return this.http.put<CompanyUser>(url, null, { headers: headers });
  }

  public makeCompanyAdmin(companyUserId: number): Observable<CompanyUser> {
    const url = `/api/company/makeAdmin/${companyUserId}`;
    const headers = this.requestService.buildHttpHeaders();
    return this.http.put<CompanyUser>(url, null, { headers: headers });
  }

  public makeCompanyAdmins(companyUserIds: number[]): Observable<CompanyUser[]> {
    const url = '/api/company/makeAdmins/ids';
    const headers = this.requestService.buildHttpHeaders();
    return this.http.put<CompanyUser[]>(url, companyUserIds, { headers: headers });
  }

  public removeGroupAdmin(user: CustomerLite, groupId: number): Observable<CompanyUser> {
    const url = `/api/company/${user.companyId}/group/${groupId}/removeAdmin/${user.id}`;
    const headers = this.requestService.buildHttpHeaders();
    return this.http.put<CompanyUser>(url, null, { headers: headers });
  }

  public makeGroupAdmin(user: CustomerLite, groupId: number): Observable<CompanyUser> {
    const url = `/api/company/${user.companyId}/group/${groupId}/makeAdmin/${user.id}`;
    const headers = this.requestService.buildHttpHeaders();
    return this.http.put<CompanyUser>(url, null, { headers: headers });
  }

  public setCompanyUser(companyUser: CompanyUser): void {
    this.companyUser = companyUser;
  }

  public getCompanyGroup(groupId: number): CompanyGroup {
    let group: CompanyGroup;
    if (!!this.companyGroups) {
      group = this.companyGroups.find(g => g.groupIdfr === groupId);
    }
    return group;
  }

  public setCompanyGroup(group: CompanyGroup): void {
    if (isNullOrUndefined(this.companyGroups)) {
      this.companyGroups = [];
    }
    const groupIndex = this.companyGroups.findIndex(g => g.groupIdfr === group.groupIdfr);
    if (groupIndex >= 0) {
      this.companyGroups[groupIndex] = group;
    } else {
      this.companyGroups.push(group);
    }
  }

  public getAssignableUsers(companyId: number): Observable<CompanyUser[]> {
    const url = `/api/company/${companyId}/assignable-users`;
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.http.get<CompanyUser[]>(url, { headers: headers });
  }

  public addInternalUserToCompany(companyId: number,
    internalUserId: number,
    userType: string): Observable<InternalUserDTO> {
    const url = `/api/company/${companyId}/internal-user/${internalUserId}?userType=${userType}`;
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.http.post<InternalUserDTO>(url, null, { headers: headers });
  }

  /**
   *
   * @param companyUserId
   * @param internalUserId, can be set to -1 in order to remove the internal user form the company user
   * @param userType
   */
  public addInternalUserToCompanyUser(companyUserId: number,
    internalUserId: number,
    userType: string): Observable<InternalUserDTO> {
    const url = `/api/company/user/${companyUserId}/internal/user/${internalUserId}?userType=${userType}`;
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.http.post<InternalUserDTO>(url, null, { headers: headers });
  }

  public getInternalCompanyUser(company: Company, userType: string): PortalUser {
    let internalManagerUser: PortalUser;
    if (company) {
      if (userType === 'Account Manager') {
        internalManagerUser = company.accountManager.user;
      }
      if (userType === 'Sales Manager') {
        internalManagerUser = company.salesManager.user;
      }

      if (userType === 'Project Manager') {
        internalManagerUser = company.projectManager.user;
      }
    }
    return internalManagerUser;
  }

  public addCompanyPricing(companyId: number, pricing: SystemPricing): Observable<SystemPricing> {
    const url = `api/company/${companyId}/pricing`;
    const headers = this.requestService.buildHttpHeaders();
    return this.http.post<SystemPricing>(url, pricing, { headers: headers });
  }

  public addGroupPricing(companyId: number, groupId: number, pricing: SystemPricing): Observable<SystemPricing> {
    const url = `api/company/${companyId}/group/${groupId}/pricing`;
    const headers = this.requestService.buildHttpHeaders();
    return this.http.post<SystemPricing>(url, pricing, { headers: headers });
  }

  public getCompanyTags(companyId: number): Observable<Tag[]> {
    const url = `api/company/${companyId}/tags`;
    const headers = this.requestService.buildHttpHeaders();
    return this.http.get<Tag[]>(url, { headers: headers });
  }

  /**
   * Start of Forecast Preferences Methods
   */

  public getForecastPreferences(userId: number): Promise<ForecastPreferences> {
    const url = `api/company/user/${userId}/forecast-preferences`;
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.http.get<ForecastPreferences>(url, { headers: headers })
      .toPromise();
  }

  public updateForecastGeneralInformation(userId: number,
    forecastPreferences: ForecastPreferences): Promise<ForecastPreferences> {
    const url = `api/company/user/${userId}/forecast-preferences/general-info`;
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.http.put<ForecastPreferences>(url, forecastPreferences, { headers: headers })
      .toPromise();
  }

  public updateForecastInHoseCost(userId: number, inHouseFees: SaveCostFees[]): Promise<ForecastPreferences> {
    const url = `api/company/user/${userId}/forecast-fee-preferences`;
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.http.put<ForecastPreferences>(url, inHouseFees, { headers: headers })
      .toPromise();
  }

  public updateForecastPctPreferences(userId: number,
    forecastPreferences: ForecastPreferences): Promise<ForecastPreferences> {
    const url = `api/company/user/${userId}/forecast-preferences/patent-preferences`;
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.http.put<ForecastPreferences>(url, forecastPreferences, { headers: headers })
      .toPromise();
  }

  public addForecastModelSettings(userId: number, model: ForecastModelSettings): Promise<ForecastModelSettings> {
    const url = `api/company/user/${userId}/model-settings`;
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.http.post<ForecastModelSettings>(url, model, { headers: headers })
      .toPromise();
  }

  public updateForecastModelSettings(userId: number, model: ForecastModelSettings): Promise<ForecastModelSettings> {
    const url = `api/company/user/${userId}/model-settings/model-jurisdiction-route`;
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.http.put<ForecastModelSettings>(url, model, { headers: headers })
      .toPromise();
  }

  public deleteForecastModelSettings(userId: number, modelId: number): Promise<ForecastModelSettings> {
    const url = `api/company/user/${userId}/model-settings/${modelId}`;
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.http.delete<ForecastModelSettings>(url, { headers: headers })
      .toPromise();
  }

  /**
   * End of Forecast Preferences Methods
   */


  /**
   * Start Foreign Agent methods
   */

  /**
   * Method to add a new foreign agent.
   * @param {number} companyId
   * @param {ForeignAgent} foreignAgent
   * @returns {Observable<ForeignAgent>}
   */
  public addCompanyForeignAgent(companyId: number, foreignAgent: ForeignAgent): Observable<ForeignAgent> {
    const url = `api/company/${companyId}/foreign-agent`;
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.http.post<ForeignAgent>(url, foreignAgent, { headers: headers });
  }

  /**
   * Method to update an already existing foreign agent.
   * @param {number} companyId
   * @param {ForeignAgent} foreignAgent
   * @returns {Observable<ForeignAgent>}
   */
  public updateCompanyForeignAgent(companyId: number, foreignAgent: ForeignAgent): Observable<ForeignAgent> {
    const url = `api/company/${companyId}/foreign-agent`;
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.http.put<ForeignAgent>(url, foreignAgent, { headers: headers });
  }

  /**
   * This method will deactivate a country for a given foreign agent.
   * @param {number} companyId
   * @param {number} foreignAgentJurisdictionId
   * @returns {Observable<ForeignAgent>}
   */
  public deactivateForeignAgentJurisdiction(companyId: number,
    foreignAgentJurisdictionId: number): Observable<ForeignAgent> {
    const url = `api/company/${companyId}/foreign-agent-jurisdiction/${foreignAgentJurisdictionId}`;
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.http.put<ForeignAgent>(url, null, { headers: headers });
  }

  /**
   * Helper method to build a map of jurisdictionId to List of Foreign agents.
   * @param {ForeignAgent[]} agents
   * @param {boolean} activeOnly
   * @param {number[]} includeInactiveFaId - provided IDs will always be included in the list
   * @returns {Map<number, ForeignAgentJurisdiction[]>}
   */
  public buildForeignAgentMap(
    agents: ForeignAgent[],
    activeOnly: boolean = false,
    includeInactiveFaId: number[] = [],
  ): Map<number, ForeignAgent[]> {
    const agentMap = new Map();
    if (isNullOrUndefined(agents)) {
      return null;
    }
    const agentJurisdictions: ForeignAgentJurisdiction[] = [];
    agents.forEach(agent => {
      agent.jurisdictions.forEach((aj) => {
        if (activeOnly) {
          if (aj.active || includeInactiveFaId.includes(aj.foreignAgentId)) {
            agentJurisdictions.push(aj);
          }
        } else {
          agentJurisdictions.push(aj);
        }
      });
    });

    agentJurisdictions.forEach(agentJurisdiction => {
      const countryAgentList: ForeignAgentJurisdiction[] = agentMap.get(agentJurisdiction.jurisdictionId);

      const agent = agents.find(foreignAgent => foreignAgent.id === agentJurisdiction.foreignAgentId);
      if (isNullOrUndefined(countryAgentList)) {
        agentMap.set(agentJurisdiction.jurisdictionId, [agent]);
      } else {
        countryAgentList.push(agent);
      }
    });

    return agentMap;
  }

  /**
   * Helper method to get the country select items for a country in the foreign agent map.
   * @param {Map<number, ForeignAgentJurisdiction[]>} agentMap
   * @param {number} countryId
   * @returns {SelectItem[]}
   */
  public getAgentSelectItemsForCountry(agentMap: Map<number, ForeignAgent[]>, countryId: number): SelectItem[] {
    const agents = agentMap.get(countryId);
    let agentOptions: SelectItem[] = null;

    if (!isNullOrUndefined(agents)) {
      const foreignAgentCompanyMap = new Map();
      agents.forEach((agent) => {
        if (!(foreignAgentCompanyMap.has(agent.companyName))) {
          foreignAgentCompanyMap.set(agent.companyName, 1);
        } else {
          foreignAgentCompanyMap.set(agent.companyName, 2);
        }
      });

      agentOptions = [];
      agents.forEach(agent => {
        let label = agent.companyName;
        if (foreignAgentCompanyMap.get(agent.companyName) > 1) {
          label += ' (' + agent.firstName + ')';
        }
        agentOptions.push({
          label: label,
          value: agent
        });
      });
    }
    return agentOptions;
  }

  /** End Foreign Agent methods */

  public acceptTermsOfService(termType: string, companyId: number): Observable<SystemTerm> {
    const url = `api/term/${termType}/accept/company/${companyId}`;
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.http.post<SystemTerm>(url, null, { headers: headers });
  }

  public getTermsOfServiceStatus(companyId: number): Observable<SystemTerm> {
    const url = `api/term/company/${companyId}`;
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.http.get<SystemTerm>(url, { headers: headers });

    // Caching mechanism. Needs more work.
    // if (isNullOrUndefined(this.termsOfServiceStatus)) {
    //   const url = `api/term/company/${companyId}`;
    //   const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    //   return this.http.get<SystemTerm>(url,{headers: headers});
    // }

    // return new Observable<SystemTerm>((observer) => {
    //   observer.next(this.termsOfServiceStatus);
    //   observer.complete();
    // })
  }

  public getTermsofServiceHistory(companyId: number): Observable<SystemTerm[]> {
    const url = `api/term/company/${companyId}/history`;
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.http.get<SystemTerm[]>(url, { headers: headers });
  }

  public updateCustomerOffline(companyId: number, userId: number, isOffline: boolean): Promise<CompanyUser> {
    const url = `api/company/${companyId}/user/${userId}/offline/${isOffline}`;
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.http.post<CompanyUser>(url, null, { headers: headers })
      .toPromise();
  }

  public toggleCompanyOffline(companyId: number, isOffline: boolean): Promise<Company> {
    const url = `api/company/${companyId}/offline/${isOffline}`;
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.http.post<Company>(url, null, { headers: headers })
      .toPromise();
  }

  public addForecastProfessionalPreferences(companyUserId: number,
    addRequest: ForecastProfessionalPreferencesAddRequest):
    Promise<ForecastProfessionalPreferences[]> {
    const url = `api/company/user/${companyUserId}/forecast-professional-preferences`;
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    // return all the professional preferences for the user, not just the added ones.
    return this.http.put<ForecastProfessionalPreferences[]>(url, addRequest, { headers: headers })
      .toPromise();
  }

  public updateForecastProfessionalPreferences(companyUserId: number,
    forecastProfessionalPreferences: ForecastProfessionalPreferences):
    Promise<ForecastProfessionalPreferences> {
    const url = `api/company/user/${companyUserId}/forecast-professional-preferences`;
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.http.post<ForecastProfessionalPreferences>(url, forecastProfessionalPreferences, { headers: headers })
      .toPromise();
  }

  public deleteForecastProfessionalPreferences(companyUserId: number,
    preferencesId: number): Promise<CustomerManagementResponse> {
    const url = `api/company/user/${companyUserId}/forecast-professional-preferences/${preferencesId}`;
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    // returns 'Success' string
    return this.http.delete<CustomerManagementResponse>(url, { headers: headers })
      .toPromise();
  }

  public getCompanyInvoicePreferencesById(companyId: number): Observable<CompanyInvoicePreference[]> {
    const url = `/api/company/${companyId}/invoice/preferences`;
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.http.get<CompanyInvoicePreference[]>(url, { headers: headers });
  }

  public updateCompanyInvoicePreferences(companyId: number, companyInvoicePreferences: CompanyInvoicePreference[]):
    Observable<CompanyInvoicePreference[]> {
    const url = `/api/company/${companyId}/invoice/preferences`;
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.http.put<CompanyInvoicePreference[]>(url, companyInvoicePreferences, { headers: headers });
  }

  public getAvailableForeignAgentJurisdictions(companyId: number,
    patentType: string,
    groupId?: number,
    preferencesId?: number): Observable<ForeignAgentJurisdiction[]> {

    let url = `/api/company/${companyId}/available-foreign-agent-jurisdictions`;
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();

    const queryOptions: ApiQueryOptions = {
      queryParams: {
        patentType: patentType,
        groupId: groupId,
        preferencesId: preferencesId
      }
    };

    url += new ApiQueryParams(queryOptions).generateQueryParams();

    return this.http.get<ForeignAgentJurisdiction[]>(url, { headers: headers });
  }

  public togglePreferredExternalBilling(companyId: number, billPreferredExternally: boolean): Observable<Company> {
    const url = `/api/company/${companyId}/preferred-external-billing/${billPreferredExternally}`;
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.http.put<Company>(url, null, { headers: headers });
  }

  public getPreferredExternalBilling(companyId: number): Observable<boolean> {
    const url = `/api/company/${companyId}/preferred-external-billing`;
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.http.get<boolean>(url, { headers: headers });
  }

  public toggleInvoiceTranslationWord(companyId: number, showWord: boolean): Observable<Company> {
    const url = `/api/company/${companyId}/show-invoice-translation-word/${showWord}`;
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.http.put<Company>(url, null, { headers: headers });
  }

  public toggleInvoiceIndividualInvoice(companyId: number, individualCountryInvoice: boolean): Observable<Company> {
    const url = `/api/company/${companyId}/individual-country-invoice/${individualCountryInvoice}`;
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.http.put<Company>(url, null, { headers: headers });
  }

  public updateCountryDefaultCurrency(companyId: number, baseIsoCode: BaseCurrencyIsoCode): Observable<Company> {
    const url = `api/company/${companyId}/currency-info/${baseIsoCode}`;
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.http.put<Company>(url, null, { headers: headers });
  }

  public getCompanyCurrencyInfos(companyId: number): Observable<CompanyCurrencyInfo[]> {
    const url = `api/company/${companyId}/currency-info`;
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.http.get<CompanyCurrencyInfo[]>(url, { headers: headers });
  }

  public getAnnuityPreferences(companyId: number): Observable<CompanyAnnuityPrefs> {
    const url = `api/customer/${companyId}/annuity/preferences`;
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.http.get<CompanyAnnuityPrefs>(url, { headers: headers });
  }

  public canPriceFreeze(): Observable<boolean> {
    return of(this.company.clientAnnuityPreferences.priceFreezeEnabled);
  }

  public getUsersSystemAccess(companyId: number): Observable<CompanyCustomerAccess> {
    const url = `api/company/${companyId}/users/system-access`;
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.http.get<CompanyCustomerAccess>(url, { headers: headers });
  }

  public updateUsersSystemAccess(companyId: number, companyAccess: CompanyCustomerAccess): Observable<CompanyCustomerAccess> {
    const url = `api/company/${companyId}/users/system-access`;
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.http.put<CompanyCustomerAccess>(url, companyAccess, { headers: headers });
  }

  public getApplicants(companyId: number): Observable<AnnuityPatentApplicant[]> {
    const url = `api/customer/${companyId}/assignees`;
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.http.get<AnnuityPatentApplicant[]>(url, { headers });
  }

  public updatePaymentPreference(companyId: number, paymentPreference: AnnuityPaymentType): Observable<string> {
    const url = `/api/customer/${companyId}/annuity/preferences/payment/preference`;
    const headers = this.requestService.buildHttpHeaders();

    return this.http.post<string>(url, `"${paymentPreference}"`, { headers });
  }

  public saveAnnuityContact(companyId: number, contact: CompanyAnnuityContact): Observable<CompanyAnnuityContact> {
    const url = `/api/customer/${companyId}/annuity/preferences/contact`;
    const headers = this.requestService.buildHttpHeaders();

    return this.http.post<CompanyAnnuityContact>(url, contact, { headers });
  }

  public removeAnnuityContact(companyId: number, contactId: number): Observable<CompanyAnnuityContact> {
    const url = `/api/customer/${companyId}/annuity/preferences/contact/${contactId}`;
    const headers = this.requestService.buildHttpHeaders();

    return this.http.delete<CompanyAnnuityContact>(url, { headers });
  }

  public updatePaymentEmailNotification(companyId: number, companyRenewalReminders: CompanyRenewalReminder[]): Observable<string> {
    const url = `/api/customer/${companyId}/annuity/preferences/email/reminders`;
    const headers = this.requestService.buildHttpHeaders();

    return this.http.post<string>(url, companyRenewalReminders, { headers });
  }

  public updatePriceFreeze(companyId: number): Observable<CompanyAnnuityPrefs> {
    const url = `/api/customer/${companyId}/annuity/preferences/price-freeze`;
    const headers = this.requestService.buildHttpHeaders();
    return this.http.put<CompanyAnnuityPrefs>(url, null, { headers });
  }

  public updateEndClientInvoicePreference(companyId: number, isSeparate: boolean): Observable<CompanyAnnuityPrefs> {
    const url = `/api/customer/${companyId}/annuity/preferences/end-client-invoice/${isSeparate}`;
    const headers = this.requestService.buildHttpHeaders();
    return this.http.put<CompanyAnnuityPrefs>(url, null, { headers });
  }

  public getAssignees(companyId: number): Observable<Applicant[]> {
    const url = `/api/customer/${companyId}/assignees`;
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.http.get<Applicant[]>(url, { headers });
  }

  public updateApplicantVendorVendor(applicantVendorId: number, newVendorId: number): Observable<ApplicantCountry> {
    const url = `api/assignee/vendor/${applicantVendorId}/vendor/${newVendorId}`;
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.http.post<ApplicantCountry>(url, null, { headers });
  }

  public updateApplicantVendorPOAFile(applicantVendorId: number, fileKey: string): Observable<ApplicantCountry> {
    const url = `api/assignee/vendor/${applicantVendorId}/file/${fileKey}`;
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.http.post<ApplicantCountry>(url, null, { headers });
  }

  public deleteApplicantVendorPOAFile(applicantVendorId: number, fileKey: string): Observable<ApplicantCountry> {
    const url = `api/assignee/vendor/${applicantVendorId}/file/${fileKey}`;
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.http.delete<ApplicantCountry>(url, { headers });
  }

  public getEndClients(companyId: number, linked: boolean = null): Observable<EndClient[]> {
    return this.getEndClientsByLinkStatus(companyId, linked);
  }

  public getEndClientsByLinkStatus(companyId: number, isLinked: boolean = false): Observable<EndClient[]> {
    const url = `api/company/${companyId}/end-clients`;
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    let params: Params = { isLinked: isLinked };

    if (isLinked === null) {
      params = {};
    }

    return this.http
      .get<EndClient[]>(url, { headers, params })
      .pipe(
        map((endClients: EndClient[]) => {
          // Sort Alphabetically
          return sortByString(endClients, 'companyName');
        })
      );
  }

  public getUserManager(userId: number, userType: UserType): Observable<InternalUserDTO> {
    const url = `api/company/user/${userId}/internal-user`;
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    const params: Params = { internalUserType: userType };

    return this.http.get(url, { headers, params });
  }

  public getFiles(companyId: number): Observable<FileKeyPair[]> {
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();

    return this.http
      .get<FileKeyPair[]>(`api/company/${companyId}/files`, { headers });
  }

  public addFiles(companyId: number, files: FileKeyPair[]): Observable<FileKeyPair[]> {
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();

    return this.http
      .post<FileKeyPair[]>(`api/company/${companyId}/files/add`, files, { headers });
  }

  public deleteFile(companyId: number, fileKey: string): Observable<FileKeyPair> {
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();

    return this.http
      .delete<FileKeyPair>(`api/company/${companyId}/files`, { headers, params: { fileKey } });
  }

  public getTmWeights(companyId: number): Observable<CompanyTmWeights> {
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();

    return this.http.get<CompanyTmWeights>(`api/company/${companyId}/tm-weights`, { headers });
  }

  public updateTmWeights(companyId: number, tmWeights: CompanyTmWeights): Observable<CompanyTmWeights> {
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();

    return this.http.put<CompanyTmWeights>(`api/company/${companyId}/tm-weights`, tmWeights, { headers });
  }

  public validateExternalContact(
    companyId: number,
    groupName: string,
    email: string,
  ): Observable<EmailAvailabilityResponse> {
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();

    return this.http
      .get<EmailAvailabilityResponse>(
        `api/validate/company/${companyId}/delivery-group/${groupName}/email/${email}`,
        { headers });
  }

  public saveEndClient(companyId: number, endClient: EndClient, bypassError?: boolean): Observable<EndClient> {
    const url = `/api/company/${companyId}/end-client`;
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();

    if (bypassError === true) {
      this.errorInterceptorMsgs.add({ url, bypass: true });
    }

    if (endClient.id) {
      return this.http
        .put<EndClient>(url, endClient, { headers: headers });
    }

    return this.http.post<EndClient>(url, endClient, { headers: headers });
  }

  public removeEndClient(endClientId: number): Observable<EndClient[]> {
    const url = `/api/company/end-client/${endClientId}`;
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.http
      .delete<EndClient[]>(url, { headers: headers })
      .pipe(
        map(endClients => sortBy(endClients, (endClient: EndClient) => endClient.companyName.toLowerCase()))
      );
  }

  public getLinkableCompanies(companyId: number): Observable<CompanyLite[]> {
    const url = `/api/company/${companyId}/linkable-clients`;
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.companyLitesObservable = this.http
      .get<CompanyLite[]>(url, { headers: headers })
      .pipe(
        map(val => sortByString(val, 'companyName'))
      );
  }

  public linkEndClient(companyId: number, endClientId: number): Observable<EndClient> {
    const url = `/api/company/end-client/${endClientId}/update-link`;
    let params: Params;

    if (companyId !== null) {
      params = { linkedCompanyId: companyId };
    }
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.http
      .put<EndClient>(url, null, { headers, params });
  }

  public getEndClientById(endClientId: number): Observable<EndClient> {
    const url = `api/company/end-client/${endClientId}`;
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();

    return this.http
      .get<EndClient>(url, { headers });
  }

  public getInvoicePreferenceTypeByCompanyIdAndProjectType(companyId: number, projectType: ProjectTypeEnum): Observable<InvoicePreferenceType> {
    const url = `api/company/${companyId}/project-type/${projectType}/invoice-preference-type`;
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();

    return this.http
      .get<InvoicePreferenceType>(url, { headers });
  }

  public updateInstructionConfirmationType(companyId: number,
    instructionConfirmationType: AnnuityInstructionConfirmationType): Observable<CompanyAnnuityPrefs> {
    const url =
      `/api/customer/${companyId}/annuity/preferences/instruction-confirmation-type/${instructionConfirmationType}`;
    const headers = this.requestService.buildHttpHeaders();
    return this.http.put<CompanyAnnuityPrefs>(url, null, { headers });
  }

  private clear(): void {
    this.company = null;
    this.team = null;
    this.companyUser = null;
    this.companyGroups = null;
    this.companyObservable = null;
    this.companyUserObservable = null;
    this.companyGroupObservable = null;
  }
}
