import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { GlobalErrorHandlerService } from '../../main/error-handlers/global-error-handler.service';
import { LoggingService } from '../logging.service';

@Injectable()
export class ErrorGuardService  {

  constructor(private errorService: GlobalErrorHandlerService,
              private router: Router,
              private loggingService: LoggingService) {
  }

  /**
   * Guard to prevent navigation away from the error page
   * @returns {Promise<T>}
   */
  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (this.errorService.isAppInErrorState) {
        this.loggingService.info('We previously had an error. Hard refreshing into the page ' + state.url);

        // We could resolve to true, but angular will also do a hard refresh by itself.
        reject(false);

        // Hard navigate to the URL that we are supposed to go to.
        // We need to do a hard refresh because angular won't be able to recover in template and component errors.
        // These errors needs to be handled in their own context properly.
        // This will make angular reload the whole app in the current url (e.g like refresh)
        // Caveat: All items must on the side bar must be "refresh-safe" or else we'll end up to the error page still.
        window.location.href = state.url;
        return;
      }
      resolve(true);
    });
  }
}
