import { SelectItem } from 'primeng/api';

export const PAGINATE_NO_LIMIT = 1000000;

export enum SortOrder {
  ASC = 'ASC',
  DESC = 'DESC'
}

export class SortOptions {
  public propertyName: string;
  public sortOrder: SortOrder;
}

export class Page {
  public number: number;
  public size: number;
  public sort: SortOrder[];
}

export enum FilterScope {
  ALL = 'ALL',
  MINE = 'MINE',
  MINE_AND_FOLLOWED = 'MINE_AND_FOLLOWED',
  MY_GROUPS = 'MY_GROUPS',
  MY_COMPANY = 'MY_COMPANY',
  LINKED_END_CLIENT = 'LINKED_END_CLIENT',
}

export const VIEWING_PREFERENCE_TYPE_OPTIONS: SelectItem[] = [
  { label: 'All Items', value: FilterScope.ALL },
  { label: 'My Items', value: FilterScope.MINE },
  { label: 'Company Items (All)', value: FilterScope.MY_COMPANY },
  { label: 'Linked End Client', value: FilterScope.LINKED_END_CLIENT },
];

export class ColumnValues {
  public field: string;
  public values: string[];
}

export class PaginatedRequest {
  public columnValues: ColumnValues[];
  public filter: string;
  public page: Page;
  public groupId: number;
  public scope: FilterScope;
}
