import { Platform } from '@angular/cdk/platform';
import * as _ from 'underscore';
import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, NavigationError, Router } from '@angular/router';
import { interval, Subscription } from 'rxjs';
import { filter, skipWhile, take } from 'rxjs/operators';
import { UserService } from './shared/services/user.service';
import { PortalUser } from './shared/domain/user';
import { CustomerManagementService } from './data-services/customer-management.service';
import { VendorManagementService } from './data-services/vendor-management.service';
import { ConfigService } from './data-services/config.service';
import { CustomToast } from './shared/components/messaging/custom-toast';
import { ActiveToast, ToastrService } from 'ngx-toastr';
import * as cloneDeep from 'lodash/cloneDeep';
import { MessagingService } from './shared/services/messaging.service';
import { AlertMessage } from './shared/dto/messaging/alert-message';
import { WebsocketService } from './shared/services/websocket.service';
import { ToastGroupingService } from './shared/services/toast-grouping.service';
import { Message } from './shared/dto/messaging/message';
import { MessageRecipient } from './shared/dto/messaging/message-recipient';
import { ContainerMarginsService } from './shared/services/container-margins.service';
import { environment } from '../environments/environment';
import * as rg4js from 'raygun4js';
import { Company } from './shared/dto/company';
import { NavService } from './main/navigation/nav.service';
import { LoggingService } from './shared/logging.service';
import { PrincipalUserDTO } from './shared/dto/principal-user';
import { InternalManagementService } from './data-services/internal-management.service';
import { LoadingService } from './shared/components/loading/loading.service';
import { AuthService } from './security/auth.service';
import { NewClientEstimateComponent } from './settings-v2/internal-management/components/new-client-estimate/new-client-estimate.component';
import { TermsOfServiceModalComponent } from './shared/components/terms-of-service-modal/terms-of-service-modal.component';
import { Vendor } from './shared/dto/vendor-management/vendor';
import { BroadcastService } from './shared/services/broadcast.service';
import { PrintPreviewService } from './print-preview/print-preview.service';
import { WebFontService } from './shared/services/web-font.service';
import { SystemTerm } from './shared/dto/system-term';
import { ConnectionDaemonService } from './shared/services/connection-daemon.service';
import { isNullOrUndefined } from './shared/utils/is-null-or-undefined';

interface ToastData extends ActiveToast<any> {
  metadata?: Message | AlertMessage;
}

@Component({
  selector: 'townip-root',
  templateUrl: './app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  private routerSubscription: Subscription;
  public user: PortalUser;
  public accountManager: PrincipalUserDTO;
  public userCompany: Company;
  private currentRoute: string;
  private alertSubscription: Subscription;
  private toastGroupingSub: Subscription;
  public connected: boolean;
  public vendor: Vendor;
  public systemTerm: SystemTerm;

  // Enables/disables the margin for ".div-container"
  // We usually do it by adding a negative margin in pages, but it could conflict
  // with bootstrap's grid/row utilities creating unnecessary margins/spaces
  public globalMargin = true;

  // Enables/disables the min height for ".div-container"
  // For tight-layout pages that does not need the min-height (dashboard/messaging)
  public globalMinHeight = true;

  public isExpanded = false;

  public loadingInd: boolean;
  public loadingText: string;
  public firstLoadOverlay: boolean;

  private tosCheckDisabled = true;
  private tosAccepted = false;

  public printPreview: boolean;

  @ViewChild('newClientEstimate')
  public newClientEstimate: NewClientEstimateComponent;

  @ViewChild('tosModal')
  public termsOfService: TermsOfServiceModalComponent;

  public companyAndUserDataLoaded = interval(200)
    .pipe(
      skipWhile(() => {
        return isNullOrUndefined(this.customerManagementService.getCompany()) ||
          isNullOrUndefined(this.customerManagementService.getCompanyUser());
      })
    );

  constructor(private router: Router,
              private configService: ConfigService,
              private authService: AuthService,
              private userService: UserService,
              private customerManagementService: CustomerManagementService,
              private vendorService: VendorManagementService,
              private toastrService: ToastrService,
              private messagingService: MessagingService,
              private websocketService: WebsocketService,
              private toastGroupingService: ToastGroupingService,
              private changeDetector: ChangeDetectorRef,
              private navService: NavService,
              private containerMarginsService: ContainerMarginsService,
              private loggingService: LoggingService,
              private loadingService: LoadingService,
              private internalManagementService: InternalManagementService,
              private broadcastService: BroadcastService,
              private printService: PrintPreviewService,
              private webFontService: WebFontService,
              private connectionDaemonService: ConnectionDaemonService,
              private platform: Platform) {
    this.setupDragDropHandlers();
  }

  public ngOnInit(): void {
    // Load web font via Web Font Loader
    this.webFontService.loadWebFont();

    this.loadingText = 'Loading Data...';
    this.loadingInd = true;
    this.firstLoadOverlay = true;
    this.navService.collapseEmitter.subscribe(expanded => this.isExpanded = expanded);
    // Only show loading page when emitting a text string.
    this.loadingService.loadingEmitter.subscribe(loadingText => {
      setTimeout(() => {
        // Show loading screen when text is given.
        this.loadingInd = !isNullOrUndefined(loadingText);
        this.loadingText = loadingText;

        // When the app first loads, we use the overlay loader to give a consistent login load status.
        // After the first load, we set it back to the default one.
        if (this.firstLoadOverlay) {
          this.firstLoadOverlay = false;
        }
      });
    });
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // If we are changing the route, end loading.
        this.loadingService.endLoading();
        this.currentRoute = (event as NavigationEnd).url;

        // Do not show TOS when we are at password update.
        // Not the best way to do at the moment, but we need it asap.
        // Refactor soon.
        if (this.currentRoute === '/' ||
          this.currentRoute === '/login' ||
          this.currentRoute.startsWith('/error-') ||
          this.currentRoute.startsWith('/auth/password-update') ||
          this.currentRoute.startsWith('/auth/login') ||
          this.currentRoute.startsWith('/auth/logout') ||
          this.currentRoute.startsWith('/auth/mfa-setup') ||
          this.currentRoute.startsWith('/auth/password-reset')) {

          // Disable TOS check here.
          this.tosCheckDisabled = true;
        } else {
          this.tosCheckDisabled = false;
        }

        // Since there are pages where we don't show the ToS, we check for it every route change.
        this.checkForTos();
      }

      if (environment.production) {
        if (event instanceof NavigationEnd) {
          // Track navigation end
          rg4js('trackEvent', {
            type: 'pageView',
            path: event.url
          });
        } else if (event instanceof NavigationError) {
          // Track navigation error
          rg4js('send', {
            error: event.error
          });
        }
      }
    });

    this.printService.printingEmitter.subscribe((printPreview) => {
      this.printPreview = printPreview;
    });

    this.userService.logoutEmitter.subscribe(() => {
      this.logout();
    });

    this.configService.init();
    this.connectionDaemonService.start();

    this.routerSubscription = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        window.scrollTo(0, 0);
      });

    this.authService.connectedEmitter.subscribe(connected => {
      this.handleConnection(connected);
      if (!connected) {
        this.clearTosCheck();
      }
    });

    // Handle websocket connection
    this.setupConnectionStatus();

    // New Message Alerts
    this.setupMessageAlerts();
  }

  public ngAfterViewInit(): void {
    // Global margins adjustments
    this.setupGlobalMargins();
  }

  private handleConnection(connected: boolean): void {
    // Note: DO NOT REDIRECT to login page from here. The authentication service handles that.
    if (!connected) {
      this.clear();
      return;
    }

    this.broadcastService.initialize();
    const user = this.userService.getUser();
    this.user = user;
    if (isNullOrUndefined(this.user)) {
      return;
    }

    this.enableToastGrouping();

    if (environment.production) {
      rg4js('setUser', {
        identifier: this.user.id,
        isAnonymous: false,
        email: this.user.email,
        firstName: this.user.firstName,
        fullName: this.user.firstName + ' ' + this.user.lastName
      });
    }

    if (this.alertSubscription) {
      this.alertSubscription.unsubscribe();
    }
    this.alertSubscription = this.messagingService.alertEmitter.subscribe(alert => this.displayCustomToast(alert));
    if (user.userType === 'COMPANY') {
      // Get Company Details
      this.customerManagementService.retrieveCompany(user.organizationId)
        .then(
          (data: Company) => {
            this.userCompany = data;
            this.loggingService.info('USER COMPANY', this.userCompany);
            this.checkForTos();
          }
        );

      this.customerManagementService.retrieveCompanyUser(user.organizationId, user.userId);
      this.companyAndUserDataLoaded
        .pipe(take(1))
        .subscribe(() => {
          this.setupAccountManager();
        });

    } else if (user.userType === 'VENDOR') {
      this.vendorService.retrieveVendor(user.organizationId)
        .then((v) => {
          this.vendor = v;
          this.checkForTos();
        });
      this.vendorService.retrieveVendorUser(user.organizationId, user.userId);
    } else if (user.userType === 'INTERNAL') {
      this.internalManagementService.retrieveInternalUser(user.userId);
    }
  }

  /**
   * Handles connection status in case the websocket service is able to connect earlier or later than expected
   */
  private setupConnectionStatus(): void {
    if (this.websocketService.isConnected) {
      this.connected = true;
    } else {
      this.websocketService.connectedEmitter.subscribe(connected => this.connected = connected);
    }
  }

  private setupMessageAlerts(): void {
    this.messagingService.messageEmitter.subscribe((message) => {
      const allMessage = this.messagingService.messages.getValue();

      // If don't have any user yet, do not process.
      if (!this.user) {
        return;
      }

      // Do not send notification if the sender is the current user
      if (message.creator && message.creator.id === this.user.id) {
        return;
      }

      // This is a reply message
      if (message.parentMessageId) {
        const parentMessage = _.findWhere(allMessage, { id: message.parentMessageId });
        if (this.isMessageReadByCurrentUser(parentMessage)) {
          this.displayCustomToast(message);
        }

        return;
      }

      // New parent message with children, show the latest child message
      if (message.childMessages && message.childMessages.length > 0) {
        if (this.isMessageReadByCurrentUser(message)) {
          message.childMessages = _.sortBy(message.childMessages, 'id')
            .reverse();
          if (!message.hidePopup) {
            this.displayCustomToast(message.childMessages[0]);
          }
        }

        return;
      }

      // A new message, not a reply
      // Send notification only if the current user is one of the recipient and it is not read
      if (this.isMessageReadByCurrentUser(message)) {
        this.displayCustomToast(message);
      }
    });
  }

  private isMessageReadByCurrentUser(message: Message): boolean {
    const currentUser = _.find(message.messageRecipients, (rec: MessageRecipient) => {
      if (rec.user.id === this.user.id && rec.read === false) {
        return true;
      }
    });
    return !!currentUser;
  }

  private displayCustomToast(alert: AlertMessage | Message): void {
    if (alert.hidePopup) {
      return;
    }
    const opt = cloneDeep(this.toastrService.toastrConfig);
    opt.tapToDismiss = false;
    opt.timeOut = 10000;
    opt.closeButton = true;
    opt.extendedTimeOut = 3000;
    opt.toastComponent = CustomToast;
    opt.toastClass = 'customtoast';
    // currently not an attribute of toast opt.alert = alert;

    // Add some metadata, we'll fetch it from custom toast later
    const toast: ToastData = this.toastrService.show(alert.messageBody, alert.messageSubject, opt);
    toast.metadata = alert;

    // Snoozing requires the toast id...
    const matchedAlert = this.messagingService.alerts.getValue()
      .find(alrt => alrt.id === alert.id);
    if (matchedAlert) {
      matchedAlert.toastId = toast.toastId;
    }
  }

  public logout(): void {
    this.user = null;
    this.userCompany = null;
    this.vendor = null;
    this.tosAccepted = false;
    this.clearToasts();
    this.clearTosCheck();

    this.authService.logout();
    // NOTE: No need to redirect to logout. Auth service handles it.
  }

  public ngOnDestroy(): void {
    this.routerSubscription.unsubscribe();
  }

  public clear(): void {
    this.user = null;
    this.tosAccepted = false;
    this.tosCheckDisabled = true;
    this.accountManager = null;
    this.userCompany = null;
    if (!!this.toastGroupingSub) {
      this.toastGroupingSub.unsubscribe();
    }

    this.broadcastService.cleanup();
  }

  private enableToastGrouping(): void {
    if (!!this.toastGroupingSub) {
      this.toastGroupingSub.unsubscribe();
    }

    this.toastGroupingService.enable();
    this.toastGroupingSub = this.toastGroupingService.onGrouped.subscribe((count) => {
      const alert: AlertMessage = {
        id: Math.ceil(Math.random() * 65535), // Generate a random ID.
        toastId: 0,
        service: null,
        serviceSubtype: null,
        taskDefinition: null,
        action: 'group',
        status: null,
        messageSubject: 'Notification',
        messageBody: `You have ${count} alerts`,
        messageRecipients: [],
        messageType: 'GROUPING',
        fireTime: new Date().getTime(),
        alertRoute: null,
        creator: { firstName: 'System', lastName: '' },
        creationTime: new Date().getTime(),
        hideScope: false,
      };

      this.displayCustomToast(alert);
    });
    // this.toastGroupingService.onGroupSelect.subscribe((count) => {
    //   this.alertQuickview.toggleMenu();
    // });
  }

  private clearToasts(): void {
    for (const toast of this.toastrService.toasts) {
      this.toastrService.clear(toast.toastId);
    }
  }

  private setupGlobalMargins(): void {
    this.containerMarginsService.onUpdate.subscribe((values) => {
      this.globalMargin = values[0];
      this.globalMinHeight = values[1];
      this.changeDetector.detectChanges();
    });
  }

  public toggleExpanded(): void {
    this.navService.toggle();
  }

  public handleClientProject(type: string): void {
    this.newClientEstimate.show(type);
  }

  /**
   * Checks for acceptance of Terms of Use
   * This is called every route change since there are certain pages we do not show this.
   */
  public checkForTos(): void {
    // Init guards so we can bypass when we don't need to check any more;
    if (this.tosCheckDisabled) {
      return;
    }

    if (this.tosAccepted) {
      return;
    }

    // Need to check for this since this is called every route change, and things might have not been init yet.
    if (this.termsOfService && this.termsOfService.modal.modal.isShown) {
      return;
    }

    if (isNullOrUndefined(this.user)) {
      return;
    }

    if (this.user.userType === 'COMPANY') {
      // we have not yet read back the company, once read back we will check again
      if (isNullOrUndefined(this.userCompany)) {
        return;
      }

      this.customerManagementService.getTermsOfServiceStatus(this.userCompany.idfr)
        .subscribe((status) => {
          this.systemTerm = status;
          if (status.acknowledge || status.bypass) {
            this.tosAccepted = true;
            return;
          }
          this.tosAccepted = false;
          setTimeout(() => this.termsOfService.show());
        }, (error) => {
          // If we couldn't fetch the terms of service, don't show it, but this block needs to be here.
          // this.termsOfService.show();
        });
    }

    if (this.user.userType === 'VENDOR') {
      // we have not yet read back the vendor, once read back we will check again
      if (isNullOrUndefined(this.vendor)) {
        return;
      }

      this.vendorService.getTermsOfServiceStatus(this.vendor.id)
        .subscribe((status) => {
          this.systemTerm = status;
          if (status.acknowledge || status.bypass) {
            this.tosAccepted = true;
            return;
          }
          this.tosAccepted = false;
          setTimeout(() => this.termsOfService.show());
        }, (error) => {
          // IF we couldn't fetch the terms of service, do not show it, but this block needs to be here.
          // this.termsOfService.show();
        });
    }
  }

  public clearTosCheck(): void {
    this.tosAccepted = false;
    this.tosCheckDisabled = true;
    this.systemTerm = null;

    if (this.termsOfService) {
      this.termsOfService.hide();
    }
  }

  public onRejectTerms(): void {
    this.tosAccepted = false;
    this.logout();
  }

  public onAcceptTerms(): void {
    this.tosAccepted = true;
  }

  /**
   * Prevent dragging and dropping files into the page
   * NOTE: This does not override p-fileUpload drag-n-drop
   */
  private setupDragDropHandlers(): void {
    // This is a Chrome-specific issue so we will disregard every other browser
    if (this.platform.BLINK) {
      window.addEventListener('dragenter', this.dragDropHandler, false);
      window.addEventListener('dragover', this.dragDropHandler);
      window.addEventListener('drop', this.dragDropHandler);
    }
  }

  public dragDropHandler(e: DragEvent): void {
    // Add CSS class names of exempted elements to allow drag and drop
    const allowedClassNames = ['ui-sortable-column'];
    // @ts-expect-error e.path might be unknown
    const hasAllowedClassName = (e.path || e.composedPath())
      .filter((element: HTMLElement) => element && element.classList)
      .map((element: HTMLElement) => element.classList)
      .some((classList: DOMTokenList) => allowedClassNames.some(className => classList.contains(className)));

    // Add attribute names of exempted elements to allow drag and drop
    const allowedAttributes = ['preorderablecolumn'];
    // @ts-expect-error e.path might be unknown
    const hasAllowedAttribute = (e.path || e.composedPath())
      .filter((element: HTMLElement) => element && element.hasAttribute)
      .some((element: HTMLElement) => allowedAttributes.some(attribute => element.hasAttribute(attribute)));

    if (!hasAllowedClassName && !hasAllowedAttribute) {
      e.preventDefault();
      e.dataTransfer.effectAllowed = 'none';
      e.dataTransfer.dropEffect = 'none';
    }
  }

  public get canShowDevTools(): boolean {
    return window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';
  }

  /**
   * Wrapper call for the auth's poison token.
   * Do not REMOVE. This is the method called by the debug button in the template.
   */
  public poisonToken(): void {
    this.authService.poisonToken();
  }

  /**
   * Prioritize the account manager for the current user.
   * If not existing, use the COMPANY account manager
   */
  private setupAccountManager(): void {
    // Check the AM of the current user
    const userAM = this.customerManagementService
      .getCompanyUser()
      .accountManager;

    if (userAM) {
      this.accountManager = userAM.user;
      return;
    }

    // Use company account manager if no specific AM for the current user
    this.accountManager = this.userCompany.accountManager.user;
  }
}
