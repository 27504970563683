import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LoggingService } from '../logging.service';
import { UserService } from '../services/user.service';
import { MessageService } from 'primeng/api';
import { isNullOrUndefined } from '../utils/is-null-or-undefined';

@Injectable()
export class VendorManagerGuardService  {

  constructor(private loggingService: LoggingService,
              private router: Router,
              private growlService: MessageService,
              private userService: UserService) {
  }

  /**
   * Guard to prevent a PM from opening the create-estimate page in a bad state.
   * @returns {Promise<T>}
   */
  public canActivate(): Promise<boolean> {
    return new Promise((resolve) => {
      const user = this.userService.getUser();
      if (isNullOrUndefined(user)) {
        this.router.navigate(['/auth/login']);
        resolve(false);
        return;
      }

      if (user.userType === 'VENDOR' && user.authorities && user.authorities.indexOf('ROLE_VENDOR_ADMIN') >= 0) {
        resolve(true);
      } else {
        this.growlService.add({
          severity: 'warn',
          summary: 'Oops, you do not have the permissions to access that page',
          detail: ' '
        });
        this.router.navigate(['/error-403']);
        resolve(false);
      }
    });
  }
}
