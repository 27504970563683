import { OnInit, Component, Input, ViewChild, AfterViewInit } from '@angular/core';
import { ConnectionDaemonService } from '../../shared/services/connection-daemon.service';
import { environment } from '../../../environments/environment';
import { filter, take } from 'rxjs/operators';
import { ModalComponent } from '../../controls/modal/modal.component';

@Component({
  selector: 'townip-maintenance-notice',
  templateUrl: './maintenance-notice.component.html',
  styleUrls: ['./maintenance-notice.component.scss']
})
export class MaintenanceNoticeComponent implements OnInit, AfterViewInit {

  @ViewChild('maintenanceNoticeModal', { static: true })
  public maintenanceNoticeModal: ModalComponent;

  @Input()
  public releaseNotesLink: string;

  public countdown: number;

  public timer: number;

  constructor(private connectionD: ConnectionDaemonService) {
    this.connectionD
      .versionStatus
      .pipe(
        // Make sure we only emit when the version is different,
        filter(version => version.trim() !== environment.version),
        // We only need to take one instance.
        // We don't want to double count and double modal trigger
        take(1)
      )
      .subscribe((version) => {
        // Version is different if we reached here - show modal.
        this.showModal();
      });
  }

  public ngOnInit(): void {
    this.countdown = 60;
  }

  public ngAfterViewInit(): void {
    // Temporary Maintenance Notice Trigger
    // this.showModal();
  }

  public showModal(): void {
    this.maintenanceNoticeModal.show();
    this.startCountdown();
  }

  public clearTimer(): void {
    clearInterval(this.timer);
  }

  public startCountdown(): void {
    this.timer = window.setInterval(() => {
      if (this.countdown === 0) {
        this.refreshPage();
      } else {
        this.countdown--;
      }
    }, 1000);
  }

  public refreshPage(): void {
    window.location.reload();
  }
}
