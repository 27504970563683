import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'underscore';
import { isNullOrUndefined } from '../../utils/is-null-or-undefined';

@Pipe({
  name: 'checkListSubgroupFilter'
})
export class CheckListSubgroupFilterPipe implements PipeTransform {

  public transform(value: any[], group: string): any {
    if (isNullOrUndefined(group)) {
      return _.filter(value, (item) => {
        return isNullOrUndefined(item.subGroup);
      });
    }

    if (group === '') {
      return _.filter(value, (item) => {
        return item.subGroup === '';
      });
    }

    return _.filter(value, (item) => {
      return item.subGroup === group;
    });
  }

}
