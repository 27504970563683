import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { FilterFormComponent } from './filter-form.component';
import { FilterOperatorPipe } from './pipes/filter-operator.pipe';
import { ActiveOnlyPipe } from './pipes/active-only.pipe';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { MultiSelectModule } from 'primeng/multiselect';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { SliderModule } from 'primeng/slider';
import { ModalModule } from '../../../controls/modal/modal.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MultiSelectModule,
    DropdownModule,
    ModalModule,
    CalendarModule,
    AutoCompleteModule,
    TypeaheadModule,
    SliderModule,
  ],
  declarations: [FilterFormComponent, FilterOperatorPipe, ActiveOnlyPipe],
  exports: [FilterFormComponent]
})
export class FilterFormModule { }
