import * as _ from 'underscore';
import { DeliveryContact } from '../../shared/dto/preferences/delivery-contact';
import { DeliveryContactCardModel } from '../components/pref-editor/delivery-contact-card/delivery-contact-card.component';
import { CompanyUser } from '../../shared/dto/customer-management/company-user';
import { PreferredJurisdictions } from '../../shared/dto/preferences/preferred-jurisdictions';
import { JurisdictionCardModel } from '../components/pref-editor/jurisdiction-card/jurisdiction-card.component';
import { CountryDTO } from '../../shared/dto/system-config/country-dto';
import { AgentCardCountry, AgentCardModel } from '../foreign-agents/components/agent-card/agent-card.component';
import { ForeignAgentJurisdiction } from '../../shared/dto/preferences/foreign-agent-jurisdiction';
import { Country } from '../../shared/dto/system-config/country';
import { Company } from '../../shared/dto/company';
import { PhoneNumber } from '../../shared/dto/phone-number';
import { ForeignAgent } from '../../shared/dto/preferences/foreign-agent';
import { CustomerPreferences } from '../../shared/dto/preferences/customer-preferences';
import { PrefEditorModel } from '../components/pref-editor/pref-editor.component';
import { PrefCardModel } from '../components/pref-card/pref-card.component';
import { UserCardModel } from '../user-and-teams/components/user-card/user-card.component';
import { ExternalContactCardModel } from '../external-contacts/components/external-contact-card/external-contact-card.component';
import { ExternalContactGroupModel } from '../external-contacts/external-contacts.component';
import { TeamDataViewModel, TeamMembersViewModel } from '../user-and-teams/teams/teams.component';
import { CheckListItem } from '../../shared/data-filter/check-list/check-list.component';
import { BillingInfo } from '../../shared/dto/billing-info';
import { PatentProjectPreferences } from '../../shared/dto/preferences/patent-project-preferences';
import { ExtensionJurisdictions } from '../../shared/dto/preferences/extension-jurisdictions';
import { CustomerLite } from '../../shared/dto/customer-lite';
import { VendorUser } from '../../shared/dto/vendor-management/vendor-user';
import { Vendor } from '../../shared/dto/vendor-management/vendor';
import { InternalOrganizationDTO, InternalUserDTO } from '../../shared/dto/internal-management';
import { SelectItem } from 'primeng/api';
import { CompanyGroupLite } from '../../shared/dto/company-group-lite';
import { clone } from '../shared/utils/clone';
import { CompanyGroup } from '../../shared/dto/company-group';
import { AgentType } from '../../shared/components/foreign-agent-classification-tag/foreign-agent-classification-tag.component';
import { isNullOrUndefined } from '../../shared/utils/is-null-or-undefined';

export default class PreferencesUtil {

  public static determineDefaultPreferences(preferenceSetItems: SelectItem[], teamId?: number): CustomerPreferences {
    if (isNullOrUndefined(preferenceSetItems) || preferenceSetItems.length === 0) {
      return null;
    }
    let defaultPrefs: CustomerPreferences = null;

    preferenceSetItems.forEach((preferenceSetItem: SelectItem) => {
      const pref: CustomerPreferences = preferenceSetItem.value;
      // Skip the {'None', null} select Item.
      if (isNullOrUndefined(pref)) {
        return;
      }

      // This is a potential default pref.
      if (pref.defaultIndicator) {
        if (isNullOrUndefined(defaultPrefs) && pref.preferenceType !== 'GROUP_PREFERENCE') {
          defaultPrefs = pref;
        } else {
          // Take the group pref #1 if a group was selected
          if (!!pref.group && pref.group.id === teamId) {
            defaultPrefs = pref;
          } else if (pref.preferenceType === 'USER_PREFERENCE' && defaultPrefs.preferenceType !== 'GROUP_PREFERENCE') {
            // Take a customer pref if we do not already have a pref for the selected group.
            defaultPrefs = pref;
          }
          // This pref is lower precedence.
        }
      }
    });

    return defaultPrefs;
  }

  public static filterByTeam(customerPreferences: CustomerPreferences[],
    team: CompanyGroupLite): CustomerPreferences[] {
    let preferenceSet = clone(customerPreferences);

    if (!isNullOrUndefined(preferenceSet)) {
      if (!isNullOrUndefined(team)) {
        preferenceSet = preferenceSet.filter(pref =>
          (pref.preferenceType === 'GROUP_PREFERENCE' && pref.group.id === team.id) ||
          pref.preferenceType === 'COMPANY_PREFERENCE' ||
          pref.preferenceType === 'USER_PREFERENCE');
      } else {
        preferenceSet = preferenceSet.filter(pref =>
          pref.preferenceType !== 'GROUP_PREFERENCE');
      }
    }

    return preferenceSet;
  }

  public static copyCompanyPreferencesToUser(company: Company, companyUser: CompanyUser): void {
    if (isNullOrUndefined(companyUser.customerPreferences)) {
      companyUser.customerPreferences = [];
    }

    // Copy all customerPreferences from company to companyUser
    if (company.customerPreferences && company.customerPreferences.length > 0) {
      // When the user already have preference
      companyUser.customerPreferences = companyUser.customerPreferences.concat(company.customerPreferences);
    }

    // Remove duplicate preference
    companyUser.customerPreferences = _.uniq(companyUser.customerPreferences, (data) => {
      return data.preferencesId;
    });
  }

  public static setupPreferenceSelectItemsForCustomer(customer: CompanyUser,
    patentType: string,
    team: CompanyGroupLite): SelectItem[] {
    const prefItems: SelectItem[] = [];

    let preferenceSet = clone(customer.customerPreferences);
    preferenceSet = PreferencesUtil.filterByTeam(preferenceSet, team);
    if (!!preferenceSet) {
      preferenceSet.forEach(customerPref => {
        if (customerPref.patentProject.patentType !== patentType) {
          return;
        }

        let type: string;
        // We only have customer or company preferences in the user pref list.
        if (customerPref.preferenceType === 'USER_PREFERENCE') {
          type = '(Customer';
        } else if (customerPref.preferenceType === 'GROUP_PREFERENCE') {
          type = '(Group';
        } else if (customerPref.preferenceType === 'COMPANY_PREFERENCE') {
          type = '(Company';
        }
        if (customerPref.defaultIndicator) {
          type = type + ' - Default';
        }
        type = type + ')';
        prefItems.push(
          {
            label: customerPref.preferenceName + ' ' + type,
            value: customerPref
          });
      });
    }

    if (prefItems.length === 0) {
      return null;
    } else {
      const sortedPrefs = prefItems.sort((a, b) => {
        const labelA = a.label.toLowerCase();
        const labelB = b.label.toLowerCase();
        if (labelA > labelB) {
          return 1;
        }
        if (labelA < labelB) {
          return -1;
        }
        return 0;
      });
      sortedPrefs.unshift({ label: 'None', value: {} });
      return sortedPrefs;
    }
  }

  public static mapPreferenceSetToModels(preferenceSet: CustomerPreferences[]): PrefCardModel[] {
    return _.map(preferenceSet, (preference: CustomerPreferences): PrefCardModel => {
      const viewLink =
        preference.preferenceType === 'GROUP_PREFERENCE' ? `../preference-set/${preference.preferencesId}` :
          `./preference-set/${preference.preferencesId}`;
      return {
        id: preference.preferencesId,
        name: preference.preferenceName,
        default: preference.defaultIndicator,
        viewLink: viewLink,
        patentType: preference.patentProject.patentType,
        metadata: preference
      };
    });
  }

  public static copyPreferencesToModel(preferences: CustomerPreferences): PrefEditorModel {
    if (isNullOrUndefined(preferences)) {
      return;
    }

    return {
      preferencesId: preferences.preferencesId,
      defaultInd: preferences.defaultIndicator,
      name: preferences.preferenceName,
      type: preferences.preferenceType,
      patentType: preferences.patentProject.patentType,
      deliveryContacts: PreferencesUtil.copyDeliveryContactsModel(preferences.patentProject.deliveryContacts),
      preferredJurisdictions:
        PreferencesUtil.copyPreferredJurisdictions(preferences.patentProject.preferredJurisdictions),
      excludedJurisdictions:
        PreferencesUtil.copyExtensionJurisdictions(preferences.patentProject.extensionJurisdictions),
      preferredAgents:
        PreferencesUtil
          .mapForeignAgentJurisdictionsToPreferredAgents(preferences.patentProject.foreignAgentJurisdictions),
      billing: preferences.billingInfoDTO,
      useFilingExtension: preferences.patentProject.useFilingExtensions,
      metadata: preferences,
      team: preferences.group
    };
  }

  public static copyModelToPreferences(prefModel: PrefEditorModel): CustomerPreferences {
    if (isNullOrUndefined(prefModel)) {
      return;
    }

    return {
      preferencesId: prefModel.preferencesId,
      defaultIndicator: prefModel.defaultInd,
      preferenceName: prefModel.name,
      billingInfoDTO: prefModel.billing,
      preferenceType: prefModel.type,
      patentProject: PreferencesUtil.copyPatentProjectFromModel(prefModel),
      linguisticProject: null,
    };
  }

  public static copyPatentProjectFromModel(prefModel: PrefEditorModel): PatentProjectPreferences {
    const existingPreferences: CustomerPreferences = prefModel.metadata;
    let id: number = null;
    if (!!existingPreferences) {
      id = existingPreferences.patentProject.id;
    }
    return {
      id: id,
      patentType: prefModel.patentType,
      useFilingExtensions: prefModel.useFilingExtension,
      deliveryContacts: PreferencesUtil.copyModelDeliveryContacts(prefModel),
      foreignAgentJurisdictions: PreferencesUtil.copyModelForeignAgentJurisdictions(prefModel),
      preferredJurisdictions: PreferencesUtil.copyModelPreferredJurisdictions(prefModel.preferredJurisdictions),
      extensionJurisdictions: PreferencesUtil.copyModelExtensionJurisdictions(prefModel.excludedJurisdictions),
    };
  }

  public static copyModelDeliveryContacts(prefModel: PrefEditorModel): DeliveryContact[] {
    const contacts: DeliveryContact[] = [];
    prefModel.deliveryContacts.forEach(modelContact => {
      let deliveryContact: DeliveryContact = null;
      if (modelContact.external) {
        deliveryContact = modelContact.metadata;
      } else {
        const user = modelContact.metadata;
        let mappedUser: CustomerLite = null;
        // May need to work back from here. In some cases the metadata is being set to the
        // company user, in others it is the existing delivery contact.
        if (!isNullOrUndefined(user.uniqueIdentifier)) {
          mappedUser = {
            id: user.uniqueIdentifier,
            firstName: user.user.firstName,
            lastName: user.user.lastName
          };
        } else {
          mappedUser = user.companyUser;
        }
        deliveryContact = {
          contactUserType: 'INTERNAL',
          companyUser: mappedUser
        };
      }
      contacts.push(deliveryContact);
    });
    return contacts;
  }

  public static copyModelForeignAgentJurisdictions(prefModel: PrefEditorModel): ForeignAgentJurisdiction[] {
    const agentJurisdictions: ForeignAgentJurisdiction[] = [];
    prefModel.preferredAgents.forEach(agent => {
      const countries: AgentCardCountry[] = agent.countries;
      countries.forEach(agentCountry => {
        const agentJurisdiction: ForeignAgentJurisdiction = {
          id: agentCountry.id,
          jurisdictionId: agentCountry.metaData.jurisdictionId,
          jurisdictionName: agentCountry.name,
          countryCode: agentCountry.countryCode,
          foreignAgentId: agent.id
        };
        agentJurisdictions.push(agentJurisdiction);
      });
    });
    return agentJurisdictions;
  }

  public static copyModelPreferredJurisdictions(prefJurisdictions: JurisdictionCardModel[]): PreferredJurisdictions {
    let jurisdictions: CountryDTO[] = [];

    if (!isNullOrUndefined(prefJurisdictions) && prefJurisdictions.length > 0) {
      jurisdictions = _.map(prefJurisdictions, (prefJurisdiction: JurisdictionCardModel): CountryDTO => {
        return {
          id: prefJurisdiction.id,
          name: prefJurisdiction.name,
          countryCode: prefJurisdiction.countryCode
        };
      });
    }

    return {
      preferredJurisdictions: jurisdictions
    };
  }

  public static copyModelExtensionJurisdictions(excJurisdictions: JurisdictionCardModel[]): ExtensionJurisdictions {
    let jurisdictions: CountryDTO[] = [];

    if (!isNullOrUndefined(excJurisdictions) && excJurisdictions.length > 0) {
      jurisdictions = _.map(excJurisdictions, (excludedJurisdiction: JurisdictionCardModel): CountryDTO => {
        return {
          id: excludedJurisdiction.id,
          name: excludedJurisdiction.name,
          countryCode: excludedJurisdiction.countryCode
        };
      });
    }

    return {
      excludedJurisdictions: jurisdictions
    };
  }

  public static copyPreferredJurisdictions(preferredJurisdictions: PreferredJurisdictions): JurisdictionCardModel[] {
    if (isNullOrUndefined(preferredJurisdictions) || isNullOrUndefined(preferredJurisdictions.preferredJurisdictions)) {
      return [];
    }

    return _.map(preferredJurisdictions.preferredJurisdictions, (jurisdiction: CountryDTO): JurisdictionCardModel => {
      return {
        id: jurisdiction.id,
        countryCode: jurisdiction.countryCode,
        name: jurisdiction.name,
        metaData: jurisdiction
      };
    });
  }

  public static copyExtensionJurisdictions(extensionJurisdictions: ExtensionJurisdictions): JurisdictionCardModel[] {
    if (isNullOrUndefined(extensionJurisdictions) || isNullOrUndefined(extensionJurisdictions.excludedJurisdictions)) {
      return [];
    }

    return _.map(extensionJurisdictions.excludedJurisdictions, (jurisdiction: CountryDTO): JurisdictionCardModel => {
      return {
        id: jurisdiction.id,
        countryCode: jurisdiction.countryCode,
        name: jurisdiction.name,
        metaData: jurisdiction
      };
    });
  }

  public static copyDeliveryContactFormToDeliveryContact(data: any): DeliveryContact {
    let phoneNumber: PhoneNumber = null;
    if (data.phone) {
      phoneNumber = data.phone[0];
    }

    return {
      contactUserType: 'EXTERNAL',
      externalContact: {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        phoneNumber: phoneNumber,
        type: data.contactType,
        companyName: data.company,
        active: true
      }
    };
  }

  public static copyCompanyUserToContactModel(companyUser: CustomerLite,
    isDefault?: boolean): DeliveryContactCardModel {
    return {
      companyUserId: companyUser.id,
      company: companyUser.companyName,
      companyId: companyUser.companyId,
      name: `${companyUser.firstName} ${companyUser.lastName}`,
      email: companyUser.emailAddress,
      supplementalEmails: companyUser.supplementalEmails ?
        _.sortBy(companyUser.supplementalEmails, (email) => email && email.toLowerCase()) : null,
      alternateLogin: companyUser.alternateLogin,
      external: false,
      type: 'INTERNAL', // Used for external types
      metadata: {
        contactUserType: 'INTERNAL',
        companyUser: companyUser
      },
      active: companyUser.active,
      isDefault
    };
  }

  public static copyCompanyUsersToContactModel(companyUsers: CompanyUser[]): DeliveryContactCardModel[] {
    if (isNullOrUndefined(companyUsers)) {
      return [];
    }

    return _.map(companyUsers, (companyUser: CompanyUser): DeliveryContactCardModel => {
      const customerLite: CustomerLite = PreferencesUtil.copyCompanyUserToCompanyLite(companyUser);
      return PreferencesUtil.copyCompanyUserToContactModel(customerLite);
    });
  }

  public static getDefaultBilling(company: Company): BillingInfo {
    const billing = _.findWhere(company.billingInfoList, { defaultInd: true });
    return billing ? billing : null;
  }

  public static copyDeliveryContactsModel(deliveryContacts: DeliveryContact[]): DeliveryContactCardModel[] {
    if (isNullOrUndefined(deliveryContacts)) {
      return [];
    }

    return _.map(deliveryContacts, (deliveryContact: DeliveryContact): DeliveryContactCardModel => {
      if (!isNullOrUndefined(deliveryContact.externalContactGroup)) {
        return PreferencesUtil.copyExternalContactGroupToContactModel(deliveryContact);
      }
      if (!isNullOrUndefined(deliveryContact.companyUser)) {
        return PreferencesUtil.copyCompanyUserToContactModel(deliveryContact.companyUser);
      }
      if (!isNullOrUndefined(deliveryContact.externalContact)) {
        return PreferencesUtil.copyExternalContactToContactModel(deliveryContact);
      }
    });
  }

  public static copyExternalContactToContactModel(deliveryContact: DeliveryContact): DeliveryContactCardModel {
    return {
      id: deliveryContact.id,
      company: deliveryContact.externalContact.companyName,
      companyId: deliveryContact.externalContact.companyId,
      name: `${deliveryContact.externalContact.firstName} ${deliveryContact.externalContact.lastName}`,
      email: deliveryContact.externalContact.email,
      external: true,
      type: deliveryContact.contactUserType, // Used for external types
      metadata: deliveryContact,
      isDefault: deliveryContact.defaultInd,
      active: deliveryContact.active
    };
  }

  public static copyExternalContactGroupToContactModel(deliveryContact: DeliveryContact): DeliveryContactCardModel {
    return {
      id: deliveryContact.id,
      name: deliveryContact.externalContactGroup.groupName,
      company: deliveryContact.externalContactGroup.groupName,
      external: true,
      type: deliveryContact.contactUserType, // Used for external types
      metadata: deliveryContact,
      isDefault: deliveryContact.defaultInd,
    };
  }

  public static copyUserDataToCompanyUser(data: any, company: Company, countries: Country[]): CompanyUser {
    const country = countries.find(c => c.countryCode === data.country);
    return {
      uniqueIdentifier: data.orgUserId,
      companyName: company.companyName,
      companyIdfr: company.idfr,
      groups: [],
      customerPreferences: [],
      offline: data.offline,
      projectManager: this.mapInternalManager(data, company, 'projectManager'),
      salesManager: this.mapInternalManager(data, company, 'salesManager'),
      accountManager: this.mapInternalManager(data, company, 'accountManager'),
      user: {
        organizationId: company.idfr,
        firstName: data.firstName.trim(),
        lastName: data.lastName.trim(),
        email: data.email,
        alternateLogin: data.alternateLogin,
        supplementalEmails: data.supplementalEmails,
        phoneNumbers: this.mapPhoneNumber(data.phone),
        active: true,
        address: {
          address1: data.address,
          city: data.city,
          district: data.state,
          postalCode: data.zipCode,
          country: {
            id: country.id,
            countryCode: country.countryCode
          }
        },
        salesforceId: data.salesforceId
      }
    };
  }

  public static copyUserDataToVendorUser(data: any, vendor: Vendor, countries: Country[]): VendorUser {
    const country = countries.find(c => c.countryCode === data.country);
    return {
      id: data.orgUserId,
      groups: [],
      onProbation: data.onProbation,
      user: {
        organizationId: vendor.id,
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        alternateLogin: data.alternateLogin,
        supplementalEmails: data.supplementalEmails,
        phoneNumbers: this.mapPhoneNumber(data.phone),
        active: true,
        address: {
          address1: data.address,
          city: data.city,
          district: data.state,
          postalCode: data.zipCode,
          country: {
            id: country.id,
            countryCode: country.countryCode
          }
        }
      }
    };
  }

  public static copyUserDataToInternalUser(data: any,
    internalOrg: InternalOrganizationDTO,
    countries: Country[]): InternalUserDTO {
    const country = countries.find(c => c.countryCode === data.country);
    return {
      id: data.orgUserId,
      internalUserType: data.userType,
      user: {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        supplementalEmails: data.supplementalEmails,
        phoneNumbers: this.mapPhoneNumber(data.phone),
        active: true,
        address: {
          address1: data.address,
          city: data.city,
          district: data.state,
          postalCode: data.zipCode,
          country: {
            id: country.id,
            countryCode: country.countryCode
          }
        },
        salesforceId: data.salesforceId,
      }
    };
  }

  private static mapPhoneNumber(phoneArray: any[]): PhoneNumber[] {
    const results: PhoneNumber[] = [];

    if (isNullOrUndefined(phoneArray)) {
      return results;
    }

    phoneArray.forEach(phone => {
      results.push(phone);
    });
    return results;
  }

  private static mapInternalManager(data: any,
    company: Company,
    managerType: 'projectManager' | 'salesManager' | 'accountManager'): InternalUserDTO {
    if (!data.overrideManagers) {
      return;
    }

    let manager = data[managerType];

    if (manager == null) {
      manager = company[managerType];
    }

    return manager;
  }

  public static mapForeignAgents(agents: ForeignAgent[]): AgentCardModel[] {
    const returnAgents: AgentCardModel[] = [];

    if (isNullOrUndefined(agents)) {
      return returnAgents;
    }

    agents.forEach(agent => returnAgents.push(this.mapForeignAgent(agent)));
    return returnAgents;
  }

  public static mapForeignAgentJurisdictionsToPreferredAgents(
    agentJurisdictions: ForeignAgentJurisdiction[]): AgentCardModel[] {
    const returnAgents: AgentCardModel[] = [];
    if (isNullOrUndefined(agentJurisdictions) || agentJurisdictions.length === 0) {
      return returnAgents;
    }

    agentJurisdictions.forEach(aj => {
      const index = returnAgents
        .findIndex(ra =>
          (aj.foreignAgentId && ra.id === aj.foreignAgentId) ||
          (aj.vendorForeignAgent && ra.countries &&
            !isNullOrUndefined(ra.countries.find(acc => acc.metaData.vendorForeignAgent &&
              acc.metaData.vendorForeignAgent.id === aj.vendorForeignAgent.id))));
      // add jurisdiction to existing agent
      if (index >= 0) {
        returnAgents[index].countries.push(this.mapForeignAgentJurisdiction(aj));
      } else {
        // New agent
        returnAgents.push({
          id: aj.foreignAgentId,
          company: (aj.companyName ? aj.companyName : aj.vendorForeignAgent.vendorName),
          name: (aj.firstName ? `${aj.firstName} ${aj.lastName}` : null),
          countries: [PreferencesUtil.mapForeignAgentJurisdiction(aj)],
          active: aj.active,
          foreignAgentId: (aj.vendorForeignAgent ? aj.vendorForeignAgent.id : aj.foreignAgentId),
          foreignAgentType: (aj.vendorForeignAgent ? AgentType.VENDOR : AgentType.EMAIL),
        });
      }
    });

    return returnAgents;
  }

  public static mapForeignAgent(agent: ForeignAgent): AgentCardModel {
    return {
      id: agent.id,
      company: agent.companyName,
      name: `${agent.firstName} ${agent.lastName}`,
      phoneNumber: agent.phoneNumber,
      emails: agent.emails ? agent.emails : [],
      countries: PreferencesUtil.mapForeignAgentJurisdictions(agent.jurisdictions),
      active: agent.active,
      metadata: agent
    };
  }

  public static mapForeignAgentJurisdictions(agentJurisdictions: ForeignAgentJurisdiction[]): AgentCardCountry[] {
    return _.map(agentJurisdictions, (agentJurisdiction: ForeignAgentJurisdiction): AgentCardCountry => {
      return PreferencesUtil.mapForeignAgentJurisdiction(agentJurisdiction);
    });
  }

  public static mapForeignAgentJurisdiction(agentJurisdiction: ForeignAgentJurisdiction): AgentCardCountry {
    return {
      id: agentJurisdiction.id,
      countryId: agentJurisdiction.jurisdictionId,
      countryCode: agentJurisdiction.countryCode,
      name: agentJurisdiction.jurisdictionName,
      active: agentJurisdiction.active,
      metaData: agentJurisdiction
    };
  }


  // Start For to DTO functions
  public static mapForiegnAgentFormToAgent(data: any, configCountries: Country[]): ForeignAgent {
    const countries: any[] = [];

    if (!isNullOrUndefined(data.filingCountry)) {
      data.filingCountry.forEach(fc => {
        const country = configCountries.find(c => c.countryCode === fc);
        countries.push({ jurisdictionId: country.id, active: true });
      });
    }

    let phoneNumber: PhoneNumber = null;
    if (data.phone) {
      phoneNumber = data.phone[0];
    }

    const agent: ForeignAgent = {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      emails: data.emails,
      address: {
        address1: data.address,
        city: data.city,
        district: data.state,
        postalCode: data.zipCode,
        country: _.findWhere(configCountries, { countryCode: data.country }),
      },
      companyName: data.company,
      phoneNumber: phoneNumber,
      jurisdictions: countries,
      active: true
    };

    // Optional fields
    if (data.id) {
      agent.id = data.id;
    }

    // Read active data. Use it if its there.
    if (isNullOrUndefined(data.active)) {
      agent.active = true;
    } else {
      agent.active = data.active;
    }

    return agent;
  }


  // Start sort functions
  public static sortUserCards(users: UserCardModel[], isAscending: boolean): UserCardModel[] {
    if (isAscending) {
      return users.sort((a, b) => {
        const fullNameA = a.name.toLocaleLowerCase();
        const fullNameB = b.name.toLocaleLowerCase();
        if (fullNameA > fullNameB) {
          return 1;
        }
        if (fullNameA < fullNameB) {
          return -1;
        }
        return 0;
      });
    }
    if (!isAscending) {
      return users.sort((a, b) => {
        const fullNameA = a.name.toLocaleLowerCase();
        const fullNameB = b.name.toLocaleLowerCase();
        if (fullNameA < fullNameB) {
          return 1;
        }
        if (fullNameA > fullNameB) {
          return -1;
        }
        return 0;
      });
    }
  }

  public static filterExternalContactCards(users: ExternalContactCardModel[],
    viewOption: any): ExternalContactCardModel[] {
    return viewOption ? users.filter(user => user.type.toLocaleLowerCase() === viewOption.toLocaleLowerCase()) : users;
  }

  public static sortExternalContactCards(users: ExternalContactCardModel[],
    isAscending: boolean): ExternalContactCardModel[] {
    if (isAscending) {
      return users.sort((a, b) => {
        const fullNameA = a.name.toLocaleLowerCase();
        const fullNameB = b.name.toLocaleLowerCase();
        if (fullNameA > fullNameB) {
          return 1;
        }
        if (fullNameA < fullNameB) {
          return -1;
        }
        return 0;
      });
    }
    if (!isAscending) {
      return users.sort((a, b) => {
        const fullNameA = a.name.toLocaleLowerCase();
        const fullNameB = b.name.toLocaleLowerCase();
        if (fullNameA < fullNameB) {
          return 1;
        }
        if (fullNameA > fullNameB) {
          return -1;
        }
        return 0;
      });
    }
  }

  public static sortExternalCompanyCards(companies: ExternalContactGroupModel[],
    isAscending: boolean): ExternalContactGroupModel[] {
    if (isAscending) {
      return companies.sort((a, b) => {
        a.name = a.name || '';
        b.name = b.name || '';
        const fullNameA = a.name.toLocaleLowerCase();
        const fullNameB = b.name.toLocaleLowerCase();
        if (fullNameA > fullNameB) {
          return 1;
        }
        if (fullNameA < fullNameB) {
          return -1;
        }
        return 0;
      });
    }
    if (!isAscending) {
      return companies.sort((a, b) => {
        const fullNameA = a.name.toLocaleLowerCase();
        const fullNameB = b.name.toLocaleLowerCase();
        if (fullNameA < fullNameB) {
          return 1;
        }
        if (fullNameA > fullNameB) {
          return -1;
        }
        return 0;
      });
    }
  }

  public static sortTeamDataView(teams: TeamDataViewModel[], isAscending: boolean): TeamDataViewModel[] {
    if (isAscending) {
      return teams.sort((a, b) => {
        const teamNameA = a.name.toLocaleLowerCase();
        const teamNameB = b.name.toLocaleLowerCase();
        if (teamNameA > teamNameB) {
          return 1;
        }
        if (teamNameA < teamNameB) {
          return -1;
        }
        return 0;
      });
    }
    if (!isAscending) {
      return teams.sort((a, b) => {
        const teamNameA = a.name.toLocaleLowerCase();
        const teamNameB = b.name.toLocaleLowerCase();
        if (teamNameA < teamNameB) {
          return 1;
        }
        if (teamNameA > teamNameB) {
          return -1;
        }
        return 0;
      });
    }
  }

  public static sortTeamMembers(teamMembers: TeamMembersViewModel[]): TeamMembersViewModel[] {
    return teamMembers.sort((a, b) => {
      const teamNameA = a.name.toLocaleLowerCase();
      const teamNameB = b.name.toLocaleLowerCase();
      if (teamNameA > teamNameB) {
        return 1;
      }
      if (teamNameA < teamNameB) {
        return -1;
      }
      return 0;
    });
  }

  public static sortCheckListItems(checkListItems: CheckListItem[]): CheckListItem[] {
    return checkListItems.sort((a, b) => {
      const itemA = a.label.toLocaleLowerCase();
      const itemB = b.label.toLocaleLowerCase();
      if (itemA > itemB) {
        return 1;
      }
      if (itemA < itemB) {
        return -1;
      }
      return 0;
    });
  }

  public static sortCompanyUsers(users: CompanyUser[]): CompanyUser[] {
    return users.sort((a, b) => {
      const fullNameA = (a.user.firstName + ' ' + a.user.lastName).toLocaleLowerCase();
      const fullNameB = (b.user.firstName + ' ' + b.user.lastName).toLocaleLowerCase();
      if (fullNameA > fullNameB) {
        return 1;
      }
      if (fullNameA < fullNameB) {
        return -1;
      }
      return 0;
    });
  }

  public static sortVendorUsers(users: VendorUser[]): VendorUser[] {
    return users.sort((a, b) => {
      const fullNameA = (a.user.firstName + ' ' + a.user.lastName).toLocaleLowerCase();
      const fullNameB = (b.user.firstName + ' ' + b.user.lastName).toLocaleLowerCase();
      if (fullNameA > fullNameB) {
        return 1;
      }
      if (fullNameA < fullNameB) {
        return -1;
      }
      return 0;
    });
  }

  public static sortBilling(billings: BillingInfo[]): BillingInfo[] {
    return billings.sort((a, b) => {
      const nameA = a.companyName.toLocaleLowerCase();
      const nameB = b.companyName.toLocaleLowerCase();
      if (a.defaultInd) {
        return -1;
      }
      if (b.defaultInd) {
        return 1;
      }
      if (nameA > nameB) {
        return 1;
      }
      if (nameA < nameB) {
        return -1;
      }
      return 0;
    });
  }

  public static copyCompanyUserToCompanyLite(companyUser: CompanyUser): CustomerLite {
    return {
      id: companyUser.uniqueIdentifier,
      firstName: companyUser.user.firstName,
      lastName: companyUser.user.lastName,
      emailAddress: companyUser.user.email,
      companyId: companyUser.companyIdfr,
      groups: companyUser.groups,
      companyName: companyUser.companyName,
      active: companyUser.user.active
    };
  }

  public static updateCompanyPreferences(company: Company, updatedPreferences: CustomerPreferences): void {
    const prefIndex =
      company.customerPreferences.findIndex(cp => cp.preferencesId === updatedPreferences.preferencesId);
    company.customerPreferences[prefIndex] = updatedPreferences;
  }

  public static updateCompanyUserPreferences(companyUser: CompanyUser,
    updatedPreferences: CustomerPreferences): void {
    const prefIndex =
      companyUser.customerPreferences.findIndex(cp => cp.preferencesId === updatedPreferences.preferencesId);
    companyUser.customerPreferences[prefIndex] = updatedPreferences;
  }

  public static updateCompanyGroupPreferences(companyGroup: CompanyGroup,
    updatedPreferences: CustomerPreferences): void {
    const prefIndex =
      companyGroup.groupPreferences.findIndex(gp => gp.preferencesId === updatedPreferences.preferencesId);
    companyGroup.groupPreferences[prefIndex] = updatedPreferences;
  }
}
