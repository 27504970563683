import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TermsOfUseContentComponent } from './terms-of-use-content.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [TermsOfUseContentComponent],
  exports: [TermsOfUseContentComponent]
})
export class TermsOfUseContentModule { }
