import { Injectable } from '@angular/core';
import { ConfigService } from '../../config.service';
import { ConfigDataStoreService } from './config-data-store.service';
import { UserService } from '../../../shared/services/user.service';
import { SystemConfigDTO } from '../../../shared/dto/system-config/system-config-dto';
import { ProjectType } from '../../../shared/dto/system-config/project-type';
import { SystemConfigResponseDTO } from '../../../shared/dto/system-config/system-config-response-dto';

@Injectable()
export class ProjectTypeDataStoreService extends ConfigDataStoreService<ProjectType> {

  constructor(configService: ConfigService, userService: UserService) {
    super(configService, userService, 'PROJECT_TYPE');
  }

  public create(data: ProjectType): Promise<SystemConfigResponseDTO> {
    return null;
  }

  public update(data: ProjectType): Promise<SystemConfigResponseDTO> {
    const configObject: SystemConfigDTO = { userId: this.user.id, type: this.type, projectTypeDTO: data };
    const response = this.configService.updateSystemConfig(configObject);
    response.then(res => {
      this.dataSetPromise.then(dataSet => {
        const index = dataSet.findIndex(dataPoint => dataPoint.id === res.configValueDTO.id);
        dataSet[index] = res.configValueDTO as ProjectType;
        this.dataSet.next(dataSet);
        this.dataSetPromise = Promise.resolve(dataSet);
      });
    });

    return response;
  }

  public findItem(id: number): Promise<ProjectType> {
    return Promise.resolve(this.dataSetPromise.then(ds => {
      return ds.find(d => d.id === id);
    }));
  }
}
