import * as _ from 'underscore';
import { Injectable } from '@angular/core';
// Use this service to maintain consitent color of items across the system.
// It can generate and save color information into the local storage
// (e.g project id 24 will always have the same color after being randomly generated)

// Uses the library:
// https://github.com/davidmerfield/randomColor
declare interface RandomColorOptions {
  count?: number;
  hue?: string; // blue, green
  luminosity?: string; // dark, light
  format?: 'string'; // rgb, rgba, hsl, hsla
}

interface ColorData {
  id: string;
  value: string;
}

declare function randomColor(options?: RandomColorOptions): string;

@Injectable()
export class RandomColorService {

  private colorCache: ColorData[];

  private storageKey: string;

  constructor() {
    this.storageKey = 'townip_color_cache';
    this.load();
  }

  public getRandom(options?: RandomColorOptions): string {
    return randomColor(options);
  }

  public getFromCache(id: string): string {
    const data = _.findWhere(this.colorCache, { id: id });
    if (data) {
      return data.value;
    }

    // If the id does not exist in the cache
    const newValue = this.getRandom();
    this.saveToCache(id, newValue);
    return newValue;
  }

  public saveToCache(id: string, value: string): void {
    const data = _.findWhere(this.colorCache, { id: id });
    if (data) {
      data.value = value;
    } else {
      this.colorCache.push({ id: id, value: value });
    }

    this.commit();
  }

  /**
   * Saves the data into the local storage
   */
  private commit(): void {
    window.localStorage.setItem(this.storageKey, JSON.stringify(this.colorCache));
  }

  /**
   * Loads data from local storage
   */
  private load(): void {
    const rawData = window.localStorage.getItem(this.storageKey);
    if (rawData === null) {
      this.colorCache = [];
      return;
    }

    try {
      this.colorCache = JSON.parse(rawData);
    } catch (e) {
      this.colorCache = [];
    }
  }
}
