<!--<button (click)="test()">Test</button>-->
<div class="form-group" *ngIf="filter">
  <input type="text" placeholder="Search..." [(ngModel)]="queryFilter" class="form-control checklist-search">
</div>

<ng-container *ngIf="!group">
  <p-scrollPanel [style]="{ width: '100%', height: maxHeight }">
    <div class="m-3">
      <div class="row no-gutters" *ngFor="let item of internalItems | checkListFilter:queryFilter">
        <div [ngClass]="{'col-md-4': showAssociatedGroups, 'col-md-12': !showAssociatedGroups}">
          <p-checkbox class="d-flex align-items-center"
                      [(ngModel)]="selectedInternal[item.id].selected"
                      (ngModelChange)="updateSelectedValues(item)"
                      binary="true"
                      [label]="item.label">
          </p-checkbox>
        </div>
        <div *ngIf="showAssociatedGroups" class="col-md-8">
        <span class="badge badge-light group-indicator margin-5" *ngFor="let group of item.associatedGroups">
          {{group.name}}
        </span>
        </div>
      </div>
    </div>
  </p-scrollPanel>
</ng-container>

<ng-container *ngIf="group">
  <!-- Note: Groupings get disabled when searching -->
  <ng-container *ngIf="queryFilter && queryFilter.length > 0; else groupedDefault">
    <section [ngStyle]="{ maxHeight: maxHeight }" class="custom-scroller">
      <p-checkbox class="d-flex align-items-center"
                  *ngFor="let item of internalItems | checkListFilter:queryFilter"
                  [(ngModel)]="selectedInternal[item.id].selected"
                  (ngModelChange)="updateSelectedValues(item)"
                  binary="true"
                  [label]="item.label + ' (' + item.groupLabel.toLowerCase() + ')'">
      </p-checkbox>
    </section>
  </ng-container>

  <!-- Groupings shown when not searching -->
  <ng-template #groupedDefault>
    <section class="checklist-group custom-scroller"
             [ngStyle]="{ maxHeight: maxHeight }">
      <!-- Source document always on top -->
      <p-checkbox
        class="d-flex align-items-center margin-top-5"
        *ngFor="let item of internalItems | checkListGroupFilter:null"
        [(ngModel)]="selectedInternal[item.id].selected"
        (ngModelChange)="updateSelectedValues(item)"
        [label]="item.label"
        binary="true">
      </p-checkbox>

      <!-- Grouped Stuff -->
      <accordion [closeOthers]="true">
        <accordion-group [(isOpen)]="accordionGroupStatus[group.id]" *ngFor="let group of accordionGroups">
          <div accordion-heading class="clearfix">
            <div class="d-flex">
              <p-triStateCheckbox
                  class="mb-0 mr-1"
                  *ngIf="groupSelect"
                  [(ngModel)]="group.selectAll"
                  (onChange)="updateGroupValues(group)"
                  styleClass="townip-tristate-checkbox-{{'' + group.selectAll}}">
              </p-triStateCheckbox>
              <span>{{group.label}}</span>
              <span class="ml-auto mr-2">
                <i class="fa fa-chevron-{{accordionGroupStatus[group.id] ? 'down' : 'right'}}"></i>
              </span>
            </div>
          </div>

          <!-- Checkbox without subgroups -->
          <p-checkbox
            class="d-flex align-items-center"
            *ngFor="let item of internalItems | checkListGroupFilter:group.group | checkListSubgroupFilter:null"
            [(ngModel)]="selectedInternal[item.id].selected"
            (ngModelChange)="updateSelectedValues(item)"
            [label]="item.label"
            binary="true">
          </p-checkbox>

          <!-- Sub Group Accordions -->
          <accordion [closeOthers]="true">
            <accordion-group
              [(isOpen)]="accordionGroupStatus[subgoup.id]"
              *ngFor="let subgoup of accordionSubGroups | checklistGroupParentFilter:group.group">
              <div accordion-heading class="clearfix">
                <div class="d-flex">
                  <span>{{subgoup.label}}</span>
                  <span class="ml-auto mr-2">
                    <i class="fa fa-chevron-{{accordionGroupStatus[subgoup.id] ? 'down' : 'right'}}"></i>
                  </span>
                </div>
              </div>
              <p-checkbox
                class="d-flex align-items-center"
                *ngFor="let item of internalItems | checkListSubgroupFilter:subgoup.group"
                [(ngModel)]="selectedInternal[item.id].selected"
                (ngModelChange)="updateSelectedValues(item)"
                [label]="item.label"
                binary="true">
              </p-checkbox>
            </accordion-group>
          </accordion>
        </accordion-group>
      </accordion>
    </section>
  </ng-template>
</ng-container>
