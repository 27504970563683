import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../services/user.service';
import { AuthService } from '../../security/auth.service';
import { FirebaseService } from '../../firebase/firebase.service';

@Injectable()
export class LoginGuard  {

  constructor(private router: Router,
              private userService: UserService,
              private authService: AuthService,
              private firebaseService: FirebaseService) {
  }

  public canActivate(): Promise<boolean> {
    return new Promise((resolve) => {
      if (!!this.firebaseService.auth.currentUser) {
        this.firebaseService.auth.currentUser.getIdToken()
          .then(token => {
            this.authService.setIsConnected(true);
            resolve(true);
          });
      } else {
        resolve(true);
      }
    });
  }
}
