<townip-modal heading="New Client {{estimateType}}">
  <townip-modal-body>
    <div class="select-area">
      <div class="row">
        <div class="col-12">
          <label>Enter a client company</label>
          <p-autoComplete [(ngModel)]="selectedCompany"
                          [suggestions]="filteredCompanies"
                          (ngModelChange)="companyChange()"
                          (onSelect)="selectCompany($event)"
                          (completeMethod)="filterClientCompanies($event)"
                          field="companyName" [size]="30"
                          placeholder="" [minLength]="0"></p-autoComplete>
          <div class="margin-top-20 alert alert-danger text-center" *ngIf="showCompanyAlert">No Company Selected
          </div>
        </div>
      </div>
      <div class="row margin-top-30">
        <div class="col-12">
          <label>Select a company user</label>
          <p-dropdown class="nce-dropdown d-block" [options]="companyUsers"
                      [style]="{'width': '100%'}"
                      [(ngModel)]="selectedCompanyUser"
                      placeholder="    "
                      (onChange)="selectCompanyUser()"></p-dropdown>
          <div class="margin-top-20 alert alert-danger text-center" *ngIf="showCompanyUserAlert">No User Selected
          </div>
        </div>
      </div>
    </div>
  </townip-modal-body>
  <townip-modal-footer>
    <button class="btn btn-secondary text-white" (click)="hide()">Cancel
    </button>
    <button class="btn btn-primary" (click)="submit()">Confirm</button>
  </townip-modal-footer>
</townip-modal>
