import { arrayLen } from '../utils/array-len';
import { firstBy } from 'thenby';
import { AnnuityOptionUpdateResponse } from '../dto/annuities/annuity-option-update-response';

export function sortRenewalOptionChild(data: AnnuityOptionUpdateResponse[]): void {
  if (!arrayLen(data)) {
    return;
  }

  // NOTE: this mutates the original array.
  // Make sure to keep in mind when hooked up into change detection

  // Renewal Children should be sorted by Country Name first
  // then class number after.

  data.sort(
    firstBy<AnnuityOptionUpdateResponse>((a, b) => {
      const countryA = a.country.name.toUpperCase();
      const countryB = b.country.name.toUpperCase();

      if (countryA < countryB) {
        return -1;
      }

      if (countryA > countryB) {
        return 1;
      }

      return 0;
    })
      .thenBy<AnnuityOptionUpdateResponse>((a, b) => {
        const classA = a.singleTrademarkClass ? a.singleTrademarkClass.classNumber : 0;
        const classB = b.singleTrademarkClass ? b.singleTrademarkClass.classNumber : 0;

        return classA - classB;
      })
  );
}
