import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'townip-standard-alert',
  templateUrl: './standard-alert.component.html',
  styleUrls: ['./standard-alert.component.scss']
})
export class StandardAlertComponent implements OnInit {

  @Input()
  public type: 'warning' | 'success' | 'danger' | 'info' = 'success';

  @Input()
  public mode: 'default' | 'lite' = 'default';

  @Input()
  public dangerIcon = 'fa-exclamation-triangle';

  @Input()
  public infoIcon = 'fa-info-circle';

  @Input()
  public warningIcon = 'fa-exclamation-triangle';

  @Input()
  public customIcon = false;

  @Input()
  public iconAlignment: 'center' | 'top' = 'center';

  @Input()
  public imgSrc: string;

  constructor() { }

  public ngOnInit(): void {
  }

}
