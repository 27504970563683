<div [fillHeight]="{minHeight: true}" class="d-flex align-items-center justify-content-center full-screen">
  <div class="error-container text-center">
    <div class="super-warn">
      <svg xmlns="http://www.w3.org/2000/svg" width="99" height="100" viewBox="0 0 30 31">
        <path fill="#F5BD17" fill-rule="nonzero" d="M15 0C6.715 0 0 6.715 0 15s6.715 15.04 15 15.04 15-6.755 15-15C30 6.753 23.285 0 15 0zm0 28.586c-7.46 0-13.547-6.086-13.547-13.547C1.453 7.58 7.539 1.453 15 1.453c7.46 0 13.547 6.086 13.547 13.586S22.461 28.586 15 28.586zm.746-7.421a.788.788 0 0 1-.785.785.788.788 0 0 1-.786-.785c0-.432.354-.785.786-.785.432 0 .785.353.785.785zm-1.531-3.848V8.482c0-.393.314-.707.706-.707.393 0 .707.314.707.707v8.835a.704.704 0 0 1-.707.707c-.392.039-.706-.315-.706-.707z"/>
      </svg>
    </div>
    <h1>Sorry, we need to fix what's going wrong here</h1>
    <p class="mt-3">Please contact our Customer Support team or close out this window and try again.
      <br /><button class="btn btn-link" (click)="toggleError()">View Error Information</button>
    </p>
    <textarea class="form-control mt-3" [readonly]="true" *ngIf="showError">
      {{lastError.timestamp}}
      {{lastError.error}}
      {{lastError.location}}
    </textarea>
    <div class="mt-5">
      <a href="mailto: {{supportContact}}" class="btn btn-primary btn-large mr-3">Contact Customer Support</a>
      <button class="btn btn-primary btn-large" (click)="close()">Close</button>
    </div>
  </div>
</div>
