import { EventEmitter, Injectable } from '@angular/core';
import { Currency } from '../shared/dto/system-config/currency';
import { PatentType } from '../shared/dto/system-config/patent-type';
import { LanguagePair } from '../shared/dto/system-config/language-pair';
import { Language } from '../shared/dto/system-config/language';
import { Service } from '../shared/dto/system-config/service';
import { UnitCostType } from '../shared/dto/system-config/unit-cost-type';
import { ProficiencyType } from '../shared/dto/system-config/proficiency-type';
import { TranslationTools } from '../shared/dto/system-config/translation-tools';
import { ConfigValue } from '../shared/dto/system-config/config-value';
import { ProjectCategory } from '../shared/dto/system-config/project-category';
import { Country, CountryCodes, CountryLite } from '../shared/dto/system-config/country';
import { Holiday } from '../shared/dto/system-config/holiday';
import { SelectItem } from 'primeng/api';
import { TimeZone } from '../shared/dto/system-config/time-zone';
import { ProjectType } from '../shared/dto/system-config/project-type';
import { ProjectTypeEnum } from '../shared/enums/project-type-enum';
import { FileCategory, FileCategoryType } from '../shared/dto/system-config/file-category-type';
import { RequestService } from '../shared/services/request-service';
import { SystemConfigDTO } from '../shared/dto/system-config/system-config-dto';
import { SystemPricing } from '../shared/dto/system-pricing';
import { SystemConfigResponseDTO } from '../shared/dto/system-config/system-config-response-dto';
import { HttpClient, HttpParams } from '@angular/common/http';
import * as _ from 'underscore';
import { Searchable } from '../shared/Searchable';
import { ServiceComponent } from '../shared/dto/system-config/service-component';
import { AdditionalService } from '../shared/dto/system-config/additional-service';
import { ParamConfig } from '../shared/dto/system-config/param-config';
import { map, switchMap, tap } from 'rxjs/operators';
import { cloneDeep } from '../shared/clone-deep';
import { from, Observable, of, throwError } from 'rxjs';
import { NamedConfig } from '../shared/dto/system-config/named-config';
import { BaseCurrencyIsoCode } from '../shared/enums/base-currency-iso-code';
import * as moment from 'moment-timezone';
import { AnnuityAlertInterval } from '../shared/dto/annuities/annuity-alert-interval';
import { AnnuityAlertType } from '../shared/dto/annuities/annuity-alert-type';
import { sortBy } from '../shared/utils/sort-by';
import { ProjectDisclaimer } from '../shared/dto/system-config/project-disclaimer';
import { CountryRegion } from '../shared/dto/country-region';
import { uniqBy } from '../shared/uniq-by';
import * as flatten from 'lodash/flatten';
import { CountryPatentService } from '../shared/dto/system-config/country-patent-service';
import { GrowlService } from '../shared/services/growl.service';
import { TmWeights } from '../shared/dto/system-config/tm-weights';
import { TmWeightsType } from '../shared/dto/system-config/tm-weights-type';
import { ANNUITY_FULFILLMENT_FILETYPES } from '../shared/system-globals';
import { includes } from '../settings-v2/shared/utils/includes';
import { IpRightType } from '../shared/dto/annuities/ip-right-type';
import { LoggingV2Service } from '../shared/logging-v2.service';
import { DocumentService } from './document.service';
import { ForecasterTranslationFactor } from '../shared/dto/forecaster-translation-factor';

@Injectable()
export class ConfigService {
  public currenciesPromise: Promise<Currency[]>;
  public currencies: Currency[];
  public patentTypesPromise: Promise<PatentType[]>;
  public patentTypes: PatentType[];
  public projectCategoryPromise: Promise<ProjectCategory[]>;
  public projectCategory: ProjectCategory[];
  public projectType: ProjectType[];
  public additionalServicesPromise: Promise<AdditionalService[]>;
  public additionalServices: AdditionalService[];
  public languagesPromise: Promise<Language[]>;
  public languages: Language[];
  public languagePairsPromise: Promise<LanguagePair[]>;
  public languagePairs: LanguagePair[];
  public servicesPromise: Promise<Service[]>;
  public services: Service[];
  public serviceComponentsPromise: Promise<ServiceComponent[]>;
  public serviceComponents: ServiceComponent[];
  public unitCostTypesPromise: Promise<UnitCostType[]>;
  public unitCostTypes: UnitCostType[];
  public proficiencyTypes: ProficiencyType[];
  public translationToolsPromise: Promise<TranslationTools[]>;
  public translationTools: TranslationTools[];
  public websiteTypesPromise: Promise<ConfigValue[]>;
  public websiteTypes: ConfigValue[];
  public countries: Country[];
  public countriesPromise: Promise<Country[]>;
  public holidays: Holiday[];
  public holidaysPromise: Promise<Holiday[]>;
  public timeZones: TimeZone[];
  public timeZonesPromise: Promise<TimeZone[]>;

  public translationProjectType: ProjectType;
  public translationAndFilingProjectType: ProjectType;
  public filingProjectType: ProjectType;

  public fileCategories: FileCategoryType[];
  public fileCategoriesPromise: Promise<FileCategoryType[]>;
  public rejectReasonsPromise: Promise<ConfigValue[]>;
  public rejectReasons: ConfigValue[];

  public paramConfigs: ParamConfig[];
  public paramConfigPromise: Promise<ParamConfig[]>;

  // Select Items for drop downs
  public countrySelectItems: SelectItem[];
  public languageSelectItems: SelectItem[];
  public languagePairSelectItems: SelectItem[];
  public currencySelectItems: SelectItem[];
  public unitCostTypeSelectItems: SelectItem[];
  public servicesSelectItems: SelectItem[];
  public translationToolsSelectItems: SelectItem[];
  public patentTypeSelectItems: SelectItem[];
  public projectTypeSelectItems: SelectItem[];
  public timeZoneSelectItems: SelectItem[];
  public fileCategorySelectItems: SelectItem[];
  public rejectReasonsSelectItems: SelectItem[];
  public vendorTypes: SelectItem[];
  public vendorTypesSelectItemForVendor: SelectItem[];
  public additionalServicesSelectItems: SelectItem[];
  public proficiencyTypesSelectItems: SelectItem[];
  public translationTypeSelectItems: SelectItem[];
  public technicalSpecialtyItems: SelectItem[];

  public systemConfigUpdatedEmitter = new EventEmitter<string>();

  constructor(
    private httpClient: HttpClient,
    private requestService: RequestService,
    private growlService: GrowlService,
    private logger: LoggingV2Service,
    private docService: DocumentService
  ) {
    this.countrySelectItems = [];
    this.languageSelectItems = [];
    this.languagePairSelectItems = [];
    this.currencySelectItems = [];
    this.unitCostTypeSelectItems = [];
    this.servicesSelectItems = [];
    this.translationToolsSelectItems = [];
    this.patentTypeSelectItems = [];
    this.timeZoneSelectItems = [];
    this.fileCategorySelectItems = [];
    this.rejectReasonsSelectItems = [];
    this.projectType = [];
    this.projectTypeSelectItems = [];
    this.additionalServicesSelectItems = [];
    this.proficiencyTypesSelectItems = [];
  }

  public init(): void {
    this.retrieveLanguages();
    this.retrieveCountries();
    this.retrieveTimeZones();
    this.retrieveParamConfig();
    this.setupVendorTypes();
  }

  public retrieveCurrencyByBaseIso(isoCode: BaseCurrencyIsoCode): Observable<Currency[]> {
    const url = `/api/system_config?type=CURRENCY&subtype=${isoCode}`;
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient.get<Currency[]>(url, { headers: headers });
  }

  public retrieveCurrencies(): Promise<Currency[]> {
    if (!!this.currenciesPromise) {
      return this.currenciesPromise;
    }

    const url = '/api/system_config?type=CURRENCY';
    const headers = this.requestService.buildHttpHeaders();
    this.currenciesPromise = this.httpClient.get<Currency[]>(url, { headers: headers })
      .toPromise();
    this.currenciesPromise.then(currencies => {
      currencies.forEach(c => c.text = c.isoCode);
      this.currencies = currencies;
      this.currencies.forEach(cur => this.currencySelectItems.push({ label: cur.text, value: cur }));

      this.currencySelectItems.sort((a: SelectItem, b: SelectItem) => {
        if (a.label < b.label) {
          return -1;
        } else {
          return 1;
        }
      });
      const usdIndex = this.currencySelectItems.findIndex(csi => csi.label === 'USD');
      const usdItem = this.currencySelectItems[usdIndex];
      this.currencySelectItems.splice(usdIndex, 1);
      this.currencySelectItems.unshift(usdItem);
    });
    return this.currenciesPromise;
  }

  public retrieveLanguages(): Promise<Language[]> {
    if (!!this.languagesPromise) {
      return this.languagesPromise;
    }

    const url = '/api/system_config/public?type=LANGUAGES';
    const headers = this.requestService.buildHttpHeaders();
    this.languagesPromise = this.httpClient.get<Language[]>(url, { headers: headers })
      .toPromise();
    this.languagesPromise.then(languages => {
      languages.forEach(l => l.text = l.language);
      this.languages = languages;
      this.languages = this.languages.sort((a, b) => {
        if (a.text < b.text) {
          return -1;
        }
        if (a.text > b.text) {
          return 1;
        }
        return 0;
      });
      this.languages.forEach(language => {
        this.languageSelectItems.push({ label: language.text, value: language });
      });
    });
  }

  public getLanguages(): Observable<Language[]> {
    return from(this.retrieveLanguages())
      .pipe(
        map((selectItems) => {
          return cloneDeep(this.languages);
        })
      );
  }

  public getLanguage(name: string): Observable<Language> {
    return this
      .getLanguages()
      .pipe(
        map(languages => {
          return languages.find(lang => lang.language === name);
        })
      );
  }

  public retrieveLanguagePairs(): Promise<LanguagePair[]> {
    if (!!this.languagePairsPromise) {
      return this.languagePairsPromise;
    }
    const url = '/api/system_config?type=LANGUAGE_PAIRS';
    const headers = this.requestService.buildHttpHeaders();
    this.languagePairsPromise = this.httpClient.get<LanguagePair[]>(url, { headers: headers })
      .toPromise();
    this.languagePairsPromise.then(languagePairs => {
      this.languagePairSelectItems = [];
      languagePairs.forEach(lp => {
        lp.toLanguage.text = lp.toLanguage.language;
        lp.fromLanguage.text = lp.fromLanguage.language;
        this.languagePairs = languagePairs;
        // const title = lp.fromLanguage.language + ' to ' + lp.toLanguage.language;
        const title = lp.toLanguage.language;
        this.languagePairSelectItems.push({ label: title, value: lp });
      });
      this.languagePairSelectItems = _.sortBy(this.languagePairSelectItems, 'label');
    });
    return this.languagePairsPromise;
  }

  public retrievePatentTypes(): Promise<PatentType[]> {
    if (!!this.patentTypesPromise) {
      return this.patentTypesPromise;
    }

    const url = '/api/system_config?type=PATENT_TYPE';
    const headers = this.requestService.buildHttpHeaders();
    this.patentTypesPromise = this.httpClient.get<PatentType[]>(url, { headers: headers })
      .toPromise();
    this.patentTypesPromise.then(pt => {
      this.patentTypes = pt;
      this.patentTypes.forEach(type => {
        this.patentTypeSelectItems.push({ label: type.text, value: type });
      });
    });
    return this.patentTypesPromise;
  }

  public retrieveProjectCategories(): Promise<ProjectCategory[]> {
    if (!!this.projectCategoryPromise) {
      return this.projectCategoryPromise;
    }

    const url = '/api/system_config?type=PROJECT_CATEGORY';
    const headers = this.requestService.buildHttpHeaders();
    this.projectCategoryPromise = this.httpClient.get<ProjectCategory[]>(url, { headers: headers })
      .toPromise();
    this.projectCategoryPromise.then(pc => {
      this.projectCategory = pc;
      this.projectCategory.forEach(projectCategory => {
        this.projectType = this.projectType.concat(projectCategory.projectTypes);
        const tfIndex = projectCategory.projectTypes
          .findIndex(pt => pt.projectType.valueOf() === ProjectTypeEnum.TRANSLATION_AND_FILING);
        const transIndex = projectCategory.projectTypes
          .findIndex(pt => pt.projectType.valueOf() === ProjectTypeEnum.TRANSLATION_ONLY);
        const filingIndex = projectCategory.projectTypes
          .findIndex(pt => pt.projectType.valueOf() === ProjectTypeEnum.FILING_ONLY);
        if (tfIndex >= 0) {
          this.translationAndFilingProjectType = projectCategory.projectTypes[tfIndex];
        }
        if (transIndex >= 0) {
          this.translationProjectType = projectCategory.projectTypes[transIndex];
        }
        if (filingIndex >= 0) {
          this.filingProjectType = projectCategory.projectTypes[filingIndex];
        }
      });
      this.projectType.forEach(type => {
        this.projectTypeSelectItems.push({ label: type.text, value: type });
      });
    });
    return this.projectCategoryPromise;
  }

  public retrieveServices(): Promise<Service[]> {
    if (!!this.servicesPromise) {
      return this.servicesPromise;
    }

    const url = '/api/system_config?type=SERVICES';
    const headers = this.requestService.buildHttpHeaders();
    this.servicesPromise = this.httpClient.get<Service[]>(url, { headers: headers })
      .toPromise();
    this.servicesPromise.then(s => {
      this.services = s;
      this.services.forEach(service => this.servicesSelectItems.push({
        label: service.readableServiceSubtype,
        value: service
      }));
    });
    return this.servicesPromise;
  }

  public retrieveAdditionalServices(): Promise<AdditionalService[]> {
    if (!!this.additionalServicesPromise) {
      return this.additionalServicesPromise;
    }

    const url = '/api/system_config?type=ADDITIONAL_SERVICE';
    const headers = this.requestService.buildHttpHeaders();
    this.additionalServicesPromise = this.httpClient.get<AdditionalService[]>(url, { headers: headers })
      .toPromise();
    this.additionalServicesPromise.then(as => {
      this.additionalServices = as;
      this.additionalServices.forEach(additionalService => this.additionalServicesSelectItems.push({
        label: additionalService.name,
        value: additionalService
      }));
    });
    return this.additionalServicesPromise;
  }

  public retrieveServiceComponents(): Promise<ServiceComponent[]> {
    if (!!this.serviceComponentsPromise) {
      return this.serviceComponentsPromise;
    }

    const url = '/api/system_config?type=SERVICE_COMPONENTS';
    const headers = this.requestService.buildHttpHeaders();
    this.serviceComponentsPromise = this.httpClient.get<Service[]>(url, { headers: headers })
      .toPromise();
    this.serviceComponentsPromise.then(s => {
      this.serviceComponents = s;
    });
    return this.serviceComponentsPromise;
  }

  public retrieveTranslationTools(): Promise<TranslationTools[]> {
    if (!!this.translationToolsPromise) {
      return this.translationToolsPromise;
    }

    const url = '/api/system_config?type=TRANSLATION_TOOLS';
    const headers = this.requestService.buildHttpHeaders();
    this.translationToolsPromise = this.httpClient.get<TranslationTools[]>(url, { headers: headers })
      .toPromise();
    this.translationToolsPromise.then(tools => {
      tools.forEach(t => {
        t.text = t.value;
        this.translationToolsSelectItems.push({ label: t.value, value: t });
      });
      this.translationTools = tools;
    });
    return this.translationToolsPromise;
  }

  public retrieveUnitCostTypes(): Promise<UnitCostType[]> {
    if (!!this.unitCostTypesPromise) {
      return this.unitCostTypesPromise;
    }

    const url = '/api/system_config?type=UNIT_COST_TYPES';
    const headers = this.requestService.buildHttpHeaders();
    this.unitCostTypesPromise = this.httpClient.get<UnitCostType[]>(url, { headers: headers })
      .toPromise();
    this.unitCostTypesPromise.then(uct => {
      this.unitCostTypes = uct;
      this.unitCostTypes.forEach(unitCost => this.unitCostTypeSelectItems.push({
        label: unitCost.text,
        value: unitCost
      }));
    });
    return this.unitCostTypesPromise;
  }

  public retrieveProficiencyTypes(): Promise<ProficiencyType[]> {
    if (this.proficiencyTypes) {
      return Promise.resolve(cloneDeep(this.proficiencyTypes));
    }

    const url = '/api/system_config?type=PROFICIENCY_TYPES';
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient
      .get<ProficiencyType[]>(url, { headers: headers })
      .pipe(
        tap((response) => {
          this.proficiencyTypes = response;
          this.proficiencyTypesSelectItems = response.map((type) => {
            return { label: type.text, value: type };
          });
        })
      )
      .toPromise();
  }

  public retrieveWebsiteTypes(): Promise<ConfigValue[]> {
    if (!!this.websiteTypesPromise) {
      return this.websiteTypesPromise;
    }

    const url = '/api/system_config?type=WEBSITE_TYPE';
    const headers = this.requestService.buildHttpHeaders();
    this.websiteTypesPromise = this.httpClient.get<ConfigValue[]>(url, { headers: headers })
      .toPromise();
    this.websiteTypesPromise.then(wt => this.websiteTypes = wt);
    return this.websiteTypesPromise;
  }

  public retrieveRejectReasons(): Promise<ConfigValue[]> {
    if (!!this.rejectReasonsPromise) {
      return this.rejectReasonsPromise;
    }

    const url = '/api/system_config?type=REJECT_REASON';
    const headers = this.requestService.buildHttpHeaders();
    this.rejectReasonsPromise = this.httpClient.get<ConfigValue[]>(url, { headers: headers })
      .toPromise();
    this.rejectReasonsPromise.then(rr => {
      this.rejectReasons = rr;
      this.rejectReasonsSelectItems = [];
      this.rejectReasons.forEach(rrs => this.rejectReasonsSelectItems.push({ label: rrs.value, value: rrs }));
    });
    return this.rejectReasonsPromise;
  }

  public retrieveFileCategoryTypes(): Promise<FileCategoryType[]> {
    if (!!this.fileCategoriesPromise) {
      return this.fileCategoriesPromise;
    }

    const url = '/api/system_config?type=FILE_CATEGORIES';
    const headers = this.requestService.buildHttpHeaders();
    this.fileCategoriesPromise = this.httpClient.get<FileCategoryType[]>(url, { headers: headers })
      .toPromise();
    this.fileCategoriesPromise.then(fc => {
      this.fileCategories = fc;
      this.fileCategories.forEach(fileCat => this.fileCategorySelectItems.push({ label: fileCat.text, value: fileCat }));
    });
    return this.fileCategoriesPromise;
  }

  /**
   * Modern and simple version of retrieveFileCategoryTypes and used in newer pages.
   * This is used on new pages moving forward as caching is handled automatically at interceptor
   */
  public getFileCategoryTypes(): Observable<FileCategoryType[]> {
    if (this.fileCategories && this.fileCategories.length) {
      return of(this.fileCategories);
    }

    const url = '/api/system_config?type=FILE_CATEGORIES';
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient
      .get<FileCategoryType[]>(url, { headers: headers })
      .pipe(
        tap(cats => {
          this.fileCategories = cats;
        })
      );
  }

  /**
   * Gets the file category types for annuity fulfillment that is sorted based on AnnuityFulfillmentFileTypes enum
   */
  public getFileCategoryTypesForAnnuityFulfillment(): Observable<FileCategoryType[]> {
    return this.getFileCategoryTypes()
      .pipe(
        map(cats => {
          // Filter and get categories for Annuity Fulfillment
          const filteredCategories = cats.filter(cat => includes(ANNUITY_FULFILLMENT_FILETYPES, cat.fileCategory));

          // Sort categories based AnnuityFulfillmentFileTypes enum
          return sortBy(filteredCategories, (fileType: FileCategoryType) => {
            return ANNUITY_FULFILLMENT_FILETYPES.indexOf(fileType.fileCategory);
          });
        })
      );
  }

  public getFileCategoryTypesForMarkImages(): Observable<{ mark: FileCategoryType, additional: FileCategoryType }> {
    return this.getFileCategoryTypes()
      .pipe(
        map(cats => {
          // // Mark Image
          const mark = cats.find(cat => cat.fileCategory === FileCategory.MARK_IMAGE);
          const additional = cats.find(cat => cat.fileCategory === FileCategory.ADDITIONAL_IMAGE);

          return { mark, additional };
        })
      );
  }

  public retrieveTechnicalSpecialtyTypes(): Promise<NamedConfig[]> {
    if (this.technicalSpecialtyItems) {
      return Promise.resolve(this.technicalSpecialtyItems.map(item => item.value));
    }

    const url = '/api/system_config?type=TECHNICAL_SPECIALTY';
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient
      .get<NamedConfig[]>(url, { headers: headers })
      .pipe(
        tap(response => {
          this.technicalSpecialtyItems = response.map(type => {
            return { label: type.name, value: type };
          });
        })
      )
      .toPromise();
  }

  public retrieveTranslationTypes(): Promise<NamedConfig[]> {
    if (this.translationTypeSelectItems) {
      return Promise.resolve(this.translationTypeSelectItems.map(item => item.value));
    }

    const url = '/api/system_config?type=TRANSLATION_TYPE';
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient
      .get<NamedConfig[]>(url, { headers: headers })
      .pipe(
        tap(response => {
          this.translationTypeSelectItems = response.map(type => {
            return { label: type.name, value: type };
          });
        })
      )
      .toPromise();
  }

  public retrieveParamConfig(): Promise<ParamConfig[]> {
    if (!!this.paramConfigPromise) {
      return this.paramConfigPromise;
    }

    const url = '/api/system_config/public?type=PARAMETER_CONFIG';
    const headers = this.requestService.buildHttpHeaders();

    this.paramConfigPromise = this.httpClient.get<any>(url, { headers: headers })
      .toPromise();

    this.paramConfigPromise.then(pcp => {
      this.paramConfigs = pcp;
    });

    return this.paramConfigPromise;
  }

  public retrieveParamConfigValueByType(type: string): string {
    if (this.paramConfigs) {
      const findParamConfig = this.paramConfigs.find(paramConfig => paramConfig.paramType === type);
      if (!!findParamConfig) {
        return findParamConfig.value;
      }
    }
  }

  public retrieveHolidaysForCountry(countryId: number): Promise<Holiday[]> {
    const url = `/api/system_config/holidays/${countryId}`;
    const headers = this.requestService.buildHttpHeaders();
    this.holidaysPromise = this.httpClient.get<Holiday[]>(url, { headers: headers })
      .toPromise();
    this.holidaysPromise.then(holidays => {
      this.holidays = holidays;
      this.holidays.forEach(h => h.text = h.name);
    });
    return this.holidaysPromise;
  }

  public retrieveTimeZones(): Promise<TimeZone[]> {
    if (!!this.timeZonesPromise) {
      return this.timeZonesPromise;
    }

    // use timezones.json instead of endpoint.
    this.timeZonesPromise = this.httpClient.get<TimeZone[]>('/assets/timezones.json')
      .toPromise();
    this.timeZonesPromise.then(timeZones => {
      this.timeZones = timeZones;
      this.timeZones.forEach(t => {
        this.timeZoneSelectItems.push({ label: t.label, value: t });
      });
    });

    // const url = `/api/system_config/timezones`;
    // const headers = this.requestService.buildHttpHeaders();
    // this.timeZonesPromise = this.httpClient.get<TimeZone[]>(url, {headers: headers})
    //   .toPromise();
    // this.timeZonesPromise.then(timeZones => {
    //   this.timeZones = timeZones;
    //   this.timeZones.forEach(t => {
    //     this.timeZoneSelectItems.push({label: t.label, value: t});
    //   });
    // });

    return this.timeZonesPromise;
  }

  public retrieveSystemConfig(configType: string): Promise<any> {
    const url = `/api/system_config?type=${configType}`;
    const headers = this.requestService.buildHttpHeaders();
    // TODO: Response type dto for retrieveSystemConfig
    return this.httpClient.get<any>(url, { headers: headers })
      .toPromise();
  }

  public retrieveConfigItem(type: string, id: number): Promise<ConfigValue> {
    const url = `/api/system_config/${id}`;
    const params = new HttpParams().set('type', type);

    return this.httpClient.get<ConfigValue>(url, {
      headers: this.requestService.buildHttpHeaders(),
      params: params
    })
      .toPromise();
  }

  public addSystemConfig(configValue: SystemConfigDTO): Promise<SystemConfigResponseDTO> {
    const url = '/api/system_config';
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.post<SystemConfigResponseDTO>(url, configValue, { headers: headers })
      .toPromise();
  }

  public updateSystemConfig(configValue: SystemConfigDTO): Promise<SystemConfigResponseDTO> {
    const url = '/api/system_config';
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.put<SystemConfigResponseDTO>(url, configValue, { headers: headers })
      .toPromise();
  }

  public getHighRepetitionFlagValue(): Observable<ParamConfig> {
    const url = '/api/system_config?type=PARAMETER_CONFIG';
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient
      .get<ParamConfig[]>(url, { headers })
      .pipe(
        map(configs => configs.find(config => config.paramType === 'HIGH_TM_REPETITION_PERCENTAGE_THRESHOLD'))
      );
  }

  public updateHighRepetitionFlagValue(paramConfigDTO: ParamConfig, userId: number): Promise<SystemConfigResponseDTO> {
    return this.updateSystemConfig({ type: 'PARAMETER_CONFIG', paramConfigDTO, userId });
  }

  public getTranslationMemoryWeights(subtype?: TmWeightsType): Observable<TmWeights[]> {
    const url = '/api/system_config';
    const headers = this.requestService.buildHttpHeaders();
    let params = new HttpParams()
      .set('type', 'TM_WEIGHTS');

    if (subtype) {
      params = params.set('subtype', subtype);
    }

    return this.httpClient.get<any>(url, { headers, params });
  }

  public findAndReplace(systemConfigList: any[],
    selectItemList: SelectItem[],
    label: string,
    updatedConfigValue: Searchable): void {
    if (systemConfigList) {
      const index = systemConfigList.findIndex(lp => lp.id === updatedConfigValue.id);
      if (index >= 0) {
        systemConfigList[index] = updatedConfigValue;
      } else {
        systemConfigList.push(updatedConfigValue);
      }
    }

    if (selectItemList) {
      const selectItemIndex = selectItemList.findIndex(si => si.value.id === updatedConfigValue.id);
      if (selectItemIndex >= 0) {
        selectItemList[selectItemIndex].value = updatedConfigValue;
      } else {
        selectItemList.push({ label: label, value: updatedConfigValue });
      }
    }
  }

  public enableSystemConfig(configValue: SystemConfigDTO): Promise<SystemConfigResponseDTO> {
    const url = '/api/system_config/enable';
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.put<SystemConfigResponseDTO>(url, configValue, { headers: headers })
      .toPromise();
  }

  public disableSystemConfig(configValue: SystemConfigDTO): Promise<SystemConfigResponseDTO> {
    const url = '/api/system_config/disable';
    const headers = this.requestService.buildHttpHeaders();
    // TODO: Response type dto for disableSystemConfig
    return this.httpClient.put<SystemConfigResponseDTO>(url, configValue, { headers: headers })
      .toPromise();
  }

  public retrieveCountries(): Promise<Country[]> {
    if (!!this.countriesPromise) {
      return this.countriesPromise;
    }

    const url = '/api/system_config/public?type=COUNTRY';
    const headers = this.requestService.buildHttpHeaders();
    this.countriesPromise = this.httpClient.get<Country[]>(url, { headers: headers })
      .toPromise();
    this.countriesPromise.then(countries => {
      countries.forEach(c => {
        this.setCountryData(c);
      });
      this.countries = sortBy(countries, country => country.name.toUpperCase());
      this.countrySelectItems = [];
      this.countries.forEach(country => {
        this.countrySelectItems.push({ label: country.text, value: country });
      });
    });

    return this.countriesPromise;
  }

  /**
   * New get countries endpoint.
   * The old one should be deprecated and this should be used for any new code
   */
  public getCountries(): Observable<Country[]> {
    if (this.countries) {
      return of(this.countries);
    }

    const url = '/api/system_config/public?type=COUNTRY';
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient
      .get<Country[]>(url, { headers: headers })
      .pipe(
        map(countries => sortBy(countries, country => country.name.toUpperCase())),
        tap((countries) => this.countries = countries)
      );
  }

  public getCountry(name: string): Observable<Country> {
    return this
      .getCountries()
      .pipe(
        switchMap((countries) => {
          const found = countries.find(country => country.name === name);
          if (found) {
            return of(found);
          }

          return throwError('Country not found');
        })
      );
  }

  /**
   * New get currencies endpoint.
   * The old one should be deprecated and this should be used for any new code
   */
  public getCurrencies(): Observable<Currency[]> {
    if (this.currencies) {
      return of(this.currencies);
    }

    const url = '/api/system_config?type=CURRENCY';
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.get<Currency[]>(url, { headers: headers });
  }

  private setCountryData(country: Country): void {
    country.flagImageLocation =
      '/assets/flags/4x3/' + country.countryCode.toLowerCase() + '.svg';
    country.text = country.name;
  }

  public updateTranslationFactors(forecasterTranslationFactor: ForecasterTranslationFactor): Promise<ForecasterTranslationFactor> {
    const url = '/api/system_config/pricing/translation-factor';
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.put<SystemPricing>(url, forecasterTranslationFactor, { headers: headers })
      .toPromise();
  }

  public getCountryForCountryIsoCode(countryIsoCode: string): Country {
    return this.countries.find(c => c.countryCode === countryIsoCode);
  }

  public updateSystemConfigValue(type: string, updatedValue: ConfigValue): void {

    switch (type) {
    case 'COUNTRY':
      this.setCountryData((updatedValue as Country));
      this.findAndReplace(this.countries, this.countrySelectItems, updatedValue.text, updatedValue);
      break;
    case 'LANGUAGES':
      updatedValue.text = (updatedValue as Language).language;
      this.findAndReplace(this.languages, this.languageSelectItems, updatedValue.text, updatedValue);
      break;
    case 'LANGUAGE_PAIRS':
      (updatedValue as LanguagePair).fromLanguage.text = (updatedValue as LanguagePair).fromLanguage.language;
      (updatedValue as LanguagePair).toLanguage.text = (updatedValue as LanguagePair).toLanguage.language;
      this.findAndReplace(this.languagePairs, this.languagePairSelectItems,
        (updatedValue as LanguagePair).toLanguage.language, updatedValue);
      break;
    case 'HOLIDAYS':
      updatedValue.text = (updatedValue as Holiday).name;
      this.findAndReplace(this.holidays, null, null, updatedValue);
      break;
    case 'SERVICES':
      this.findAndReplace(this.services, this.servicesSelectItems,
        (updatedValue as Service).readableServiceSubtype, updatedValue);
      break;
    case 'CURRENCY':
      updatedValue.text = (updatedValue as Currency).isoCode;
      this.findAndReplace(this.currencies, this.currencySelectItems, updatedValue.text, updatedValue);
      break;
    case 'PROJECT_CATEGORY':
      this.findAndReplace(this.projectCategory, null, null, updatedValue);
      break;
    case 'PROJECT_TYPE':
      updatedValue.text = (updatedValue as TranslationTools).value;
      this.findAndReplace(this.projectType, this.projectTypeSelectItems, updatedValue.text, updatedValue);
      break;
    case 'TRANSLATION_TOOLS':
      this.findAndReplace(this.translationTools, this.translationToolsSelectItems, updatedValue.value, updatedValue);
      break;
    case 'REJECT_REASON':
      this.findAndReplace(this.rejectReasons, this.rejectReasonsSelectItems, updatedValue.value, updatedValue);
      break;
    case 'FILE_CATEGORIES':
      this.findAndReplace(this.fileCategories, this.fileCategorySelectItems, updatedValue.text, updatedValue);
      break;
    case 'PATENT_TYPE':
      this.findAndReplace(this.patentTypes, this.patentTypeSelectItems, updatedValue.text, updatedValue);
      break;
    case 'WEBSITE_TYPE':
      this.findAndReplace(this.websiteTypes, null, null, updatedValue);
      break;
    case 'UNIT_COST_TYPES':
      this.findAndReplace(this.unitCostTypes, this.unitCostTypeSelectItems, updatedValue.text, updatedValue);
      break;
    case 'PARAMETER_CONFIG':
      this.findAndReplace(this.paramConfigs, null, null, updatedValue);
      break;
    }

    this.systemConfigUpdatedEmitter.emit(type);
  }

  public getLocalTimeByTimezone(timezone: string): string {
    if (!timezone) {
      return '';
    }

    const vendorTz = this.timeZones.find(tz => tz.tzName === timezone);

    return moment()
      .tz(vendorTz.tzName)
      .format('hh:mm A');
  }

  public getAnnuityAlertsInterval(): Observable<AnnuityAlertInterval> {
    const url = '/api/system_config/public?type=PARAMETER_CONFIG';
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient.get<ParamConfig[]>(url, { headers: headers })
      .pipe(
        map(paramConfig => {
          return {
            clientCriticalLapse: +paramConfig
              .find(config => config.paramType === AnnuityAlertType.ClientAnnuityLapseCriticalInterval)
              .value,
            clientUrgentAction: +paramConfig
              .find(config => config.paramType === AnnuityAlertType.ClientAnnuityPoaUrgencyInterval)
              .value,
            clientUrgentSurcharge: +paramConfig
              .find(config => config.paramType === AnnuityAlertType.ClientAnnuitySurchargeUrgencyInterval)
              .value,
            pmUrgentAction: +paramConfig
              .find(config => config.paramType === AnnuityAlertType.InternalAnnuityUrgencyInterval)
              .value,
            vendorUrgentAction: +paramConfig
              .find(config => config.paramType === AnnuityAlertType.VendorAnnuityUrgencyInterval)
              .value,
          } as AnnuityAlertInterval;
        })
      );
  }

  public getDisclaimers(): Observable<ProjectDisclaimer[]> {
    const url = '/api/system_config?type=PROJECT_DISCLAIMER';
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.get<ProjectDisclaimer[]>(url, { headers: headers });
  }

  public getCountriesByRegion(region: CountryRegion): Observable<Country[]> {
    return this.getCountries()
      .pipe(
        map((countries) => {
          return countries
            .filter((country) => {
              return country
                .regionalOrganizations
                .some(org => org.regionalOrganizationType === region);
            });
        })
      );
  }

  public getCountriesWithAnnuity(): Observable<Country[]> {
    return this.getCountries()
      .pipe(
        map(countries => sortBy(countries, country => country.name.toUpperCase())),
        switchMap(countries => {
          const countriesWithAnnuity = countries.filter(country => {
            return country.countryAnnuitiesConfig && country.countryAnnuitiesConfig.length;
          });
          return of(countriesWithAnnuity);
        })
      );
  }

  public async getAvailableUnitarySourceLanguages(): Promise<Language[]> {
    let languages: Language[];

    try {
      const unitaryConfig = await this.getUnitaryConfig();

      // IF NOT coming from english-locked languages, it will be based on the config settings
      const sourceLanguages = unitaryConfig
        .sectionLanguages
        .map((lang) => lang.fromLanguages);

      // Flatten 1 level since fromLanguage is an array, sourceLanguages will then be a multidimension array
      languages = uniqBy(flatten(sourceLanguages) as Language[], (lang) => lang.id);

      if (!languages.length) {
        this.growlService.warning(
          'Could not find a configuration for unitary source languages',
          'Please check system configuration');

        languages = await this.getLanguages()
          .toPromise();
      }
    } catch (e) {
      languages = await this.getLanguages()
        .toPromise();

      this.growlService.warning(
        'Could not find a configuration for unitary source languages',
        'Please check system configuration');
    }

    return languages;
  }

  public async getAvailableUnitaryTargetLanguages(sourceLanguage: string): Promise<Language[]> {
    let languages: Language[];

    try {
      const sourceLang = await this
        .getLanguage(sourceLanguage)
        .toPromise();

      const unitaryConfig = await this.getUnitaryConfig();

      // IF NOT coming from english-locked languages, it will be based on the config settings
      const section = unitaryConfig
        .sectionLanguages
        .find(patentSection => {
          return patentSection
            .fromLanguages
            .some((lang) => lang.language === sourceLang.language);
        });

      if (section) {
        languages = section.languages;
      } else {
        this.growlService.warning(
          `Could not find a configuration for language ${sourceLanguage}`,
          'Please check system configuration'
        );
        languages = await this.getLanguages()
          .toPromise();
      }

    } catch (e) {
      // This block happens when you do not have the document source language configured
      // in the language condition preferences in system config
      languages = await this.getLanguages()
        .toPromise();

      this.growlService.warning(
        `Could not find a configuration for language ${sourceLanguage}`,
        'Please check system configuration'
      );
    }

    return languages;
  }

  public async getUnitaryConfig(countryName: string = 'EPO'): Promise<CountryPatentService> {
    const epoCountryData = await this.getCountry(countryName)
      .toPromise();

    return epoCountryData
      .patentServices
      .find((service) => service.patentTypeConfig.name === 'UNITARY');
  }

  public isLocalDev(): boolean {
    return window.location.hostname.startsWith('localhost');
  }

  public getWipoChildren(): Observable<CountryLite[]> {
    return this.getCountries()
      .pipe(
        map((countries): CountryLite[] => {
          return countries
            .find(theCountry => theCountry.countryCode === CountryCodes.WIPO)
            .countryAnnuitiesConfig
            .find(config => config.ipRightType === IpRightType.Trademark)
            .childrenCountries;
        })
      );
  }

  public getWIpoChildrenFromCountryList(countries: Country[]): CountryLite[] {
    return countries
      .find(theCountry => theCountry.countryCode === CountryCodes.WIPO)
      .countryAnnuitiesConfig
      .find(config => config.ipRightType === IpRightType.Trademark)
      .childrenCountries;
  }

  /**
   *
   * @param countries - The countries source to filter from, usually comes from this.getCountries
   * @param regionalOrganization - WIPO, ARIPO, etc...
   * @param ipRightType - Need to this because they are type-based
   */
  public getDesignatedStatesByRegionAndIpRightType(
    countries: Country[],
    regionalOrganization: CountryCodes,
    ipRightType: IpRightType,
  ): Country[] {
    // Ported originally from import / designated states
    try {
      return countries
        .filter(country => country.regionalOrganizations &&
          country.regionalOrganizations.some((orgName) =>
            orgName.regionalOrganizationType === regionalOrganization &&
            orgName.ipRightType === ipRightType));
    } catch (e) {
      this.logger.logError('Failed to get regional data list', e);
      return [];
    }
  }

  private setupVendorTypes(): void {
    this.vendorTypesSelectItemForVendor = [
      { label: 'Translation Agency', value: 'TRANSLATION_AGENCY' },
      { label: 'Law Firm', value: 'LAW_FIRM' },
      { label: 'General Agency', value: 'GENERAL_AGENCY' },
      { label: 'Freelancer', value: 'FREELANCER' },
      { label: 'Other', value: 'OTHER' },
    ];

    this.vendorTypes = [...this.vendorTypesSelectItemForVendor, { label: 'Client Vendor', value: 'CLIENT_VENDOR' }];
  }

  public getCountryFile(countryCode: string): void {
    const url = `/api/country/rules/download?countryCode=${countryCode}`;
    const headers = this.requestService.buildHttpHeaders();

    this.httpClient.get(url, {
      headers: headers,
      observe: 'response',
      responseType: 'blob'
    })
      .toPromise()
      .then(resp => {
        this.docService.saveFile(resp);
      });
  }

  public getCountryFileAvailable(countryCode: string): Observable<boolean> {
    const url = `/api/country/rules/available?countryCode=${countryCode}`;
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient.get<boolean>(url, { headers: headers });
  }
}
