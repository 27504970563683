import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { cloneDeep } from '../clone-deep';

@Injectable()
export class GlobalLoaderService {
  public loaderStatus = new BehaviorSubject<number>(0);

  private ignorePermanentlyList: string[] = [];

  private ignoreOnceList = [];

  private isDisabled: boolean;

  public get permanentlyIgnoredList(): string[] {
    return cloneDeep(this.ignorePermanentlyList);
  }

  public triggerAuto(): void {
    if (this.isDisabled) {
      // Prevent to show the loader when disabled
      return;
    }
    const current = this.loaderStatus.getValue();
    const next = current + 1;
    this.loaderStatus.next(next);
  }

  public completeAuto(): void {
    const current = this.loaderStatus.getValue();
    let next = current - 1;

    // Making sure it never goes below zero.
    if (next < 0) {
      next = 0;
    }

    this.loaderStatus.next(next);
  }

  public disable(): void {
    this.isDisabled = true;
  }

  public enable(): void {
    this.isDisabled = false;
  }

  public ignoreOnce(url: string): void {
    this.ignoreOnceList.push(url);
  }

  public ignorePermanently(url: string): void {
    if (this.ignorePermanentlyList.some(existing => existing === url)) {
      // Terminate if the the url is already existing
      return;
    }
    this.ignorePermanentlyList.push(url);
  }

  public cleanupIgnoreOnce(url: string): void {
    this.ignoreOnceList = this.ignoreOnceList.filter(eIgnoreUrl => eIgnoreUrl !== url);
  }

  public hasIgnoreOnce(url: string): boolean {
    return !!this.ignoreOnceList.find(ignore => ignore === url);
  }
}
