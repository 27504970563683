import { Injectable } from '@angular/core';
import { DocumentSearchRequest } from '../shared/request/document-search-request';
import { Observable } from 'rxjs';
import { RequestService } from '../shared/services/request-service';
import { PatentDocument } from '../shared/dto/patent/patent-document';
import { HttpClient, HttpResponse } from '@angular/common/http';
import * as FileSaver from 'file-saver';
import { tap, timeout } from 'rxjs/operators';
import { ApiQueryOptions, ApiQueryParams } from '../shared/api-query-params';
import { ProjectDocumentMetaData } from '../shared/response/project-document-meta-data';
import { PatentDocumentDetails } from '../shared/response/patent-document-details';
import { GeneralResponseMessage } from '../shared/dto/general-response-message';
import { LoggingService } from '../shared/logging.service';
import { isNullOrUndefined } from '../shared/utils/is-null-or-undefined';
import { FileCodeType } from '../shared/enums/file-codes';
import { FileUpload } from 'primeng/fileupload';
import { FileMetaData } from '../shared/dto/file-meta-data';
import { FileCategoryType } from '../shared/dto/system-config/file-category-type';

export interface FileUploadBeforeUploadData {
  event: { formData: FormData };
  instance: FileUpload;
}

@Injectable()
export class DocumentService {

  constructor(private httpClient: HttpClient,
              private requestService: RequestService,
              private loggingService: LoggingService) {
  }

  public getDocument(docId: number): Promise<PatentDocument> {
    const url = `/api/document/patent/${docId}`;
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.get<PatentDocument>(url, { headers: headers })
      .toPromise();
  }

  public getProjectDocument(docId: number): Promise<ProjectDocumentMetaData> {
    const url = `/api/project/document/${docId}`;
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.get<ProjectDocumentMetaData>(url, { headers: headers })
      .toPromise();
  }

  public downloadPatentData(searchRequest: DocumentSearchRequest): Observable<PatentDocument> {
    const url = '/api/document/download';
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient
      .post<PatentDocument>(url, searchRequest, { headers: headers })
      .pipe(
        timeout(60000) // 60 seconds
      );
  }

  public getCoverImage(docId: string): Observable<string> {
    const url = `/api/file/patentCoverPageImage/${docId}`;
    // We're getting base64 encoded text for the image
    const headers = this.requestService.buildHttpHeaders('image/png');
    // eslint-disable-next-line
    const options = {responseType: 'text' as 'text', headers: headers};
    return this.httpClient.get(url, options);
  }

  public getFile(fileKey: string): Promise<GeneralResponseMessage> {
    const url = '/api/file/download';
    const headers = this.requestService.buildHttpHeaders();

    const keys = [fileKey];

    return this.httpClient.post<GeneralResponseMessage>(url, keys, { headers: headers })
      .pipe(
        tap((resp => {
          this.loggingService.info('received response ', resp.response);
          window.open('/api/file/' + resp.response, '_self');
        })
        )
      )
      .toPromise();
  }

  public getFiles(fileKeys: string[], opts?: ApiQueryOptions): void {
    const tokenUrl = '/api/file/download';
    const headers = this.requestService.buildHttpHeaders();
    this.httpClient.post<GeneralResponseMessage>(tokenUrl, fileKeys, { headers: headers })
      .toPromise()
      .then(resp => {
        this.loggingService.info('received response ', resp.response);
        let downloadUrl = '/api/file/download-zip-file/' + resp.response;
        if (opts) {
          // Append the generated query params
          downloadUrl += new ApiQueryParams(opts).generateQueryParams();
        }
        window.open(downloadUrl, '_self');
      }
      );
  }

  public getFileByFileCode(fileCode: FileCodeType): void {
    const url = '/api/file/download/file-code';
    const headers = this.requestService.buildHttpHeaders();
    this.httpClient
      .post<GeneralResponseMessage>(url, JSON.stringify(fileCode),{ headers: headers })
      .pipe(
        tap((resp => {
          this.loggingService.info('received response ', resp.response);
          window.open('/api/file/' + resp.response, '_self');
        })
        )
      )
      .toPromise();
  }

  public deleteFiles(fileKeys: string[]): Promise<GeneralResponseMessage> {
    const url = '/api/file/delete';
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.post<GeneralResponseMessage>(url, fileKeys, { headers: headers })
      .toPromise();
  }

  public saveFile(resp: HttpResponse<Blob>): void {
    const contentDispositionHeader: string = resp.headers.get('Content-Disposition');
    const parts: string[] = contentDispositionHeader.split(';');
    const filename = parts[1].split('=')[1];
    FileSaver.saveAs(resp.body, filename);
  }

  public getPatentDocumentDetailsByPatentId(patentApplicationId: number, patentType?: string):
    Observable<PatentDocumentDetails> {
    let url = `/api/project/estimate/patent/application/${patentApplicationId}/details`;
    const headers = this.requestService.buildHttpHeaders();

    if (!isNullOrUndefined(patentType)) {
      const queryOptions: ApiQueryOptions = {
        queryParams: {
          patentType: patentType,
        }
      };
      url += new ApiQueryParams(queryOptions).generateQueryParams();
    }

    return this.httpClient.get<PatentDocumentDetails>(url, { headers: headers });
  }

  public htmlToPdf(html: string, filename: string): Observable<HttpResponse<Blob>> {
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient
      .post(
        '/api/file/html-string-to-pdf?fileName=' + filename,
        html,
        { headers: headers, observe: 'response', responseType: 'blob' })
      .pipe(
        tap(resp => this.saveFile(resp))
      );
  }

  public getAsset(path: string): Observable<HttpResponse<Blob>> {
    return this.httpClient.get(path, { observe: 'response', responseType: 'blob' });
  }

  public getTextAsset(path: string): Observable<string> {
    return this.httpClient.get(path, { observe: 'body', responseType: 'text' });
  }

  public getImageFile(fileKey: string): Observable<string> {
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.get(
      '/api/file/image/' + fileKey,
      // eslint-disable-next-line
      {responseType: 'text' as 'text', headers: headers}
    );
  }

  public setCategoryMetadataBeforeUpload(event: FileUploadBeforeUploadData, category: FileCategoryType): void {
    const metadata: FileMetaData[] = [];

    event
      .instance
      .files
      .forEach(file => {
        const metaData: FileMetaData = {
          fileName: file.name,
          fileCategory: category,
        };
        metadata.push(metaData);
      });

    event.event.formData.append('filemetadata', JSON.stringify(metadata));
  }
}
