/**
 * A services that manages the margins and min-height of the
 * router base container '.div-container' from app.component.
 *
 * Useful for certain pages that needs TIGHT layout and does not need the
 * default margins and min-height.
 *
 * Use in PAGE components only, not reusable components or controls
 *
 * Make sure to call enable() on the page component's onDestroy lifecycle
 * or margins and min-height will be disabled on all other apges
 */
import { Injectable, EventEmitter } from '@angular/core';

@Injectable()
export class ContainerMarginsService {

  public minHeightActive: boolean;

  public marginsActive: boolean;

  public onUpdate = new EventEmitter<boolean[]>();

  constructor() {
    // this.enable();
    // this.disable();
  }

  public enable(): void {
    this.minHeightActive = true;
    this.marginsActive = true;
    this.onUpdate.emit([this.marginsActive, this.minHeightActive]);
  }

  public disable(): void {
    this.minHeightActive = false;
    this.marginsActive = false;
    this.onUpdate.emit([this.marginsActive, this.minHeightActive]);
  }

}
