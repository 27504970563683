import { Directive, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { UserService } from '../services/user.service';

@Directive({
  selector: '[townipIfUserHasAnnuitiesAccess]',
})
export class IfUserHasAnnuitiesAccessDirective implements OnInit {

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private userService: UserService,
  ) { }

  public ngOnInit(): void {
    if (this.userService.hasAnnuitiesAccess()) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }

}
