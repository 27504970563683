import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserDisabledStatus } from '../../security/user-disabled-status';

@Component({
  selector: 'townip-error-403',
  templateUrl: './error-403.component.html',
  styleUrls: ['./error-403.component.scss']
})
export class Error403Component implements OnInit {

  public disabledStatus: UserDisabledStatus;

  public disabledStatuses  = UserDisabledStatus;

  constructor(private route: ActivatedRoute) { }

  public ngOnInit(): void {
    this.route.queryParams.subscribe(() => {
      this.disabledStatus = this.route.snapshot.queryParamMap.get('disabled') as UserDisabledStatus;
    });
  }
}
