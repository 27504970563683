import { AnnuityClientStatus } from './annuity-client-status';
import { AnnuityInternalStatus } from './annuity-internal-status';
import { ReferenceNumber } from '../../../estimate/create-estimate-v2/shared/reference-number';
import { AnnuityVendorStatus } from './annuity-vendor-status';
import { AnnuitySeverityLevel } from './annuity-severity-level';
import { AnnuitySeverityReason, AnnuityUrgencyReason } from './annuity-urgency-reason';
import { AnnuityIssueType } from './annuity-issue';
import { OptNotToPayReason } from './opt-not-to-pay-reason';
import { AnnuityPatentGrantStatus } from './annuity-patent-grant-status';
import { QualityControlAction } from './quality-control-action';
import { IprServiceType } from './ipr-service-type';

export interface AnnuityPaginated {
  id?: number;
  clientStatus?: AnnuityClientStatus;
  internalStatus?: AnnuityInternalStatus;
  vendorStatus?: AnnuityVendorStatus;
  iprServiceType?: IprServiceType;
  company?: string;
  companyId?: number;
  vendor?: string;
  endClient?: string;
  countryId: number;
  country?: string;
  patentId?: number;
  patentNumber?: string;
  patentIdentifier?: string;
  ipRightType?: string;
  annuityDeadline?: string;
  submissionDeadline?: string;
  fulfillmentDeadline?: number;
  fulfillmentDate?: string;
  annuitySchedule?: string;
  annuityScheduleSort?: string;
  annuityFee?: number;
  annuityFeeUsd?: number;
  annuityFeeCurrencyIso?: string;
  paymentId?: string;
  paymentIdSort?: number;
  referenceNumbers?: ReferenceNumber[];
  referenceNumberString?: string;
  vendorReferenceNumber: string;
  familyId?: number;
  clientContactName: string;
  assignedVendorId: number;
  vendorUrgencySeverity: AnnuitySeverityLevel;
  internalUrgencySeverity: AnnuitySeverityLevel;
  customerUrgencySeverity: AnnuitySeverityLevel;
  prioritizedUrgencySeverity: AnnuitySeverityLevel;
  customerUrgencyReason: AnnuityUrgencyReason;
  vendorUrgencyReason: AnnuityUrgencyReason;
  cancellationDate?: number;
  deliveryDate?: number;
  optNotToPayDate?: number;
  optNotToPayReason: OptNotToPayReason;
  optNotToPayOtherReason: string;
  responseDeadline?: string;
  issueExists?: boolean;
  issueReason?: AnnuityIssueType;
  latestAnnuityDeadline?: string;
  poaFileRequired?: boolean;
  qualityControlDeadline?: string;
  earliestAnnuityDeadline?: string;
  gracePeriodDeadline?: string;
  reworkDeadline?: string;
  receiptFilesReleasedToClient?: boolean;
  inCriticalPeriod?: boolean;
  prioritizedUrgencySeverityReason: AnnuitySeverityReason;
  vendorUrgencySeverityReason: AnnuitySeverityReason;
  customerUrgencySeverityReason: AnnuitySeverityReason;
  partialAnnuity?: boolean;
  partible?: boolean;
  grantStatus?: AnnuityPatentGrantStatus;
  officialFeeUsd?: number;
  officialFeeClient?: number;
  clientCurrencyIso?: string;
  submissionDate?: number;
  currentQualityControlAssignedPm?: string;
  qualityControlAction?: QualityControlAction;
  annuityFulfillmentUpdateExpectedDate?: string;
  declarationOfUseProofProvided?: boolean;
  hasMarkImage?: boolean;
  autoPayInstructionDate?: number;
  declarationOfIncontestability?: boolean;
  applicationNumber?: string;
  publicationNumber?: string;
  registrationNumber?: string;
  effectiveFilingDate?: string;
  publicationDate?: string;
  patentGrantDate?: string;
  entitySize?: string;
  filingRoute?: string;
  numberOfClaims?: number;
  title?: string;
  unfreezeDate?: string;
  priceFreeze?: boolean;
  // NOTE: These items should always be the same as the ones below in AnnuityPaginatedFields
}

export enum AnnuityPaginatedFields {
  id = 'id',
  clientStatus = 'clientStatus',
  internalStatus = 'internalStatus',
  vendorStatus = 'vendorStatus',
  iprServiceType = 'iprServiceType',
  company = 'company',
  companyId = 'companyId',
  vendor = 'vendor',
  endClient = 'endClient',
  countryId = 'countryId',
  country = 'country',
  patentId = 'patentId',
  patentNumber = 'patentNumber',
  patentIdentifier = 'patentIdentifier',
  ipRightType = 'ipRightType',
  annuityDeadline = 'annuityDeadline',
  submissionDeadline = 'submissionDeadline',
  fulfillmentDeadline = 'fulfillmentDeadline',
  fulfillmentDate = 'fulfillmentDate',
  annuitySchedule = 'annuitySchedule',
  annuityScheduleSort = 'annuityScheduleSort',
  annuityFee = 'annuityFee',
  annuityFeeUsd = 'annuityFeeUsd',
  annuityFeeCurrencyIso = 'annuityFeeCurrencyIso',
  paymentId = 'paymentId',
  paymentIdSort = 'paymentIdSort',
  referenceNumbers = 'referenceNumbers',
  referenceNumberString = 'referenceNumberString',
  vendorReferenceNumber = 'vendorReferenceNumber',
  requestedBy = 'requestedBy',
  familyId = 'familyId',
  clientContactName = 'clientContactName',
  assignedVendorId = 'assignedVendorId',
  vendorUrgencySeverity = 'vendorUrgencySeverity',
  internalUrgencySeverity = 'internalUrgencySeverity',
  customerUrgencySeverity = 'customerUrgencySeverity',
  prioritizedUrgencySeverity = 'prioritizedUrgencySeverity',
  prioritizedUrgencyReason = 'prioritizedUrgencyReason',
  prioritizedUrgencySeverityReason = 'prioritizedUrgencySeverityReason',
  customerUrgencyReason = 'customerUrgencyReason',
  vendorUrgencyReason = 'vendorUrgencyReason',
  cancellationDate = 'cancellationDate',
  deliveryDate = 'deliveryDate',
  optNotToPayDate = 'optNotToPayDate',
  optNotToPayReason = 'optNotToPayReason',
  optNotToPayOtherReason = 'optNotToPayOtherReason',
  responseDeadline = 'responseDeadline',
  issueExists = 'issueExists',
  latestAnnuityDeadline = 'latestAnnuityDeadline',
  poaFileRequired = 'poaFileRequired',
  qualityControlDeadline = 'qualityControlDeadline',
  earliestAnnuityDeadline = 'earliestAnnuityDeadline',
  gracePeriodDeadline = 'gracePeriodDeadline',
  reworkDeadline = 'reworkDeadline',
  receiptFilesReleasedToClient = 'receiptFilesReleasedToClient',
  inCriticalPeriod = 'inCriticalPeriod',
  vendorUrgencySeverityReason = 'vendorUrgencySeverityReason',
  customerUrgencySeverityReason = 'customerUrgencySeverityReason',
  partialAnnuity = 'partialAnnuity',
  partible = 'partible',
  grantStatus =  'grantStatus',
  officialFeeUsd =  'officialFeeUsd',
  officialFeeClient =  'officialFeeClient',
  clientCurrencyIso =  'clientCurrencyIso',
  submissionDate = 'submissionDate',
  autoPayInstructionDate = 'autoPayInstructionDate',
  currentQualityControlAssignedPm = 'currentQualityControlAssignedPm',
  qualityControlAction = 'qualityControlAction',
  annuityFulfillmentUpdateExpectedDate = 'annuityFulfillmentUpdateExpectedDate',
  declarationOfUseProofProvided = 'declarationOfUseProofProvided',
  hasMarkImage = 'hasMarkImage',
  familyName = 'familyName',
  declarationOfIncontestability = 'declarationOfIncontestability',
  applicationNumber = 'applicationNumber',
  publicationNumber = 'publicationNumber',
  registrationNumber = 'registrationNumber',
  effectiveFilingDate = 'effectiveFilingDate',
  publicationDate = 'publicationDate',
  patentGrantDate = 'patentGrantDate',
  entitySize = 'entitySize',
  filingRoute = 'filingRoute',
  numberOfClaims = 'numberOfClaims',
  title = 'title',
  unfreezeDate = 'unfreezeDate',
  priceFreeze = 'priceFreeze',
  // NOTE: These items should always be the same as the ones above in AnnuityPaginated
}
