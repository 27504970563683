import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { RequestService } from '../shared/services/request-service';
import { PaginatedColumnValueIndex, PaginatedData, PaginateObject } from '../shared/dto/paginated-data';
import { from, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AnnuityPaginated, AnnuityPaginatedFields } from '../shared/dto/annuities/annuity-paginated';
import { AnnuityPatentService } from './annuity-patent.service';
import { FieldType } from '../shared/data-filter/field-type';
import { OPERATORS } from '../shared/data-filter/filter-operators';
import { RsqlEncoder } from '../shared/data-filter/rsql-encoder';
import { FilterScope, PAGINATE_NO_LIMIT, PaginatedRequest } from '../shared/query/paginated-request.component';
import { PatentFamilyPaginated } from '../shared/dto/annuities/patent-family-paginated';
import { Annuity } from '../shared/dto/annuities/annuity';
import { AnnuityInvoiceLineItem } from '../shared/dto/annuities/annuity-invoice-line-item';
import { RequestFilterNumberResponse } from '../shared/query/request-filter.component';
import { AnnuityPatent } from '../shared/dto/annuities/annuity-patent';
import { Vendor } from '../shared/dto/vendor-management/vendor';
import { CustomerManagementService } from './customer-management.service';
import { VendorManagementService } from './vendor-management.service';
import { CategorizedWorkflowActions } from '../shared/dto/annuities/annuity-workflow-action';
import { AnnuityWorkflowService } from './annuity-workflow.service';
import { UserService } from '../shared/services/user.service';
import { AnnuityInvoicePaginated } from '../shared/dto/annuities/annuity-invoice-paginated';
import { AnnuityBulkSendPaymentInstructionRequest } from '../shared/dto/annuities/annuity-bulk-send-payment-instruction-request';
import { AnnuityBulkSendPaymentInstructionResponse } from '../shared/dto/annuities/annuity-bulk-send-payment-instruction-response';
import { sortBy } from '../shared/utils/sort-by';
import { AnnuityHistory } from '../shared/dto/annuities/annuity-history';
import { AnnuityRequiredField, AnnuityRequiredFieldPartialType, AnnuityRequiredFieldsConfig, AnnuitySpecificRequiredField } from '../shared/dto/annuities/annuity-required-fields';
import { AnnuityInvoiceSummary } from '../shared/dto/annuities/annuity-invoice-summary';
import { DocumentService } from './document.service';
import { TableColumnV2 } from '../shared/table-column-v2';
import { ApiQueryParams } from '../shared/api-query-params';
import { CustomerLite } from '../shared/dto/customer-lite';
import { IpRightType } from '../shared/dto/annuities/ip-right-type';
import { AnnuityAdditionalInstruction } from '../shared/dto/annuities/annuity-additional-instruction';
import { AnnuityExtensionStatus } from '../shared/dto/annuities/annuity-extension-status';
import { AnnuityFulfillmentQcRecords } from '../shared/dto/annuities/annuity-fulfillment-qc-records';
import { AnnuityQualityControlRequest } from '../shared/dto/annuities/annuity-quality-control-request';
import { AnnuityFulfillmentRecord } from '../shared/dto/annuities/annuity-fulfillment-record';
import { Params } from '@angular/router';
import { TrademarkClass } from '../shared/dto/annuities/trademark-class';
import { MarkType } from '../shared/dto/annuities/mark-type';
import { EndClient } from '../shared/dto/end-client';
import { GeneralResponseWithStatus } from '../shared/dto/general-response-with-status';
import { AnnuityCompanyDetails } from '../shared/dto/annuity-company-details';
import { CountryCodes } from '../shared/dto/system-config/country';
import { AnnuityClientStatus } from '../shared/dto/annuities/annuity-client-status';
import { IprServiceType } from '../shared/dto/annuities/ipr-service-type';
import { includes } from '../settings-v2/shared/utils/includes';
import { AnnuityFeeDetail } from '../shared/dto/annuities/annuity-fee-detail';

export interface AnnuityFullDetails {
  annuity?: Annuity;
  patent?: AnnuityPatent;
  company?: AnnuityCompanyDetails;
  vendor?: Vendor;
  invoiceLineItem?: AnnuityInvoiceLineItem;
  actions?: CategorizedWorkflowActions;
}

export interface AnnuityFullDetailsOpts {
  skipCompany?: boolean;
  skipVendor?: boolean;
  skipInvoiceLine?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class AnnuityService {

  constructor(
    private httpClient: HttpClient,
    private requestService: RequestService,
    private annuityPatentService: AnnuityPatentService,
    private customerManagementService: CustomerManagementService,
    private vendorManagementService: VendorManagementService,
    private annuityWorkflowService: AnnuityWorkflowService,
    private userService: UserService,
    private docService: DocumentService,
  ) {
  }

  public getAllPaginated(request: PaginatedData<AnnuityPaginated>): Observable<PaginatedData<AnnuityPaginated>> {
    const url = '/api/annuity/paginated';
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient
      .post<any>(url, request.toObject(), { headers: headers })
      .pipe(
        // Update the given pagination object with values from the response.
        map((response: PaginateObject<AnnuityPaginated>) => request.update(response))
      );
  }

  public getAllPaginatedForExport(request: PaginatedData<AnnuityPaginated>, columns?: TableColumnV2[]): Observable<any> {
    const url = '/api/annuity/paginated/export';
    const headers = this.requestService.buildHttpHeaders();

    // Copy the request so we don't mutate the original
    const data = request.toObject();
    data.page.number = 0; // Prevents issue where incorrect import items when on page 2.
    data.page.size = data.page.totalElements;

    data.columnValues = columns.map((col) => {
      return { field: col.field, readableField: col.header } as PaginatedColumnValueIndex;
    });

    return this.httpClient
      .post(url, data, { headers: headers, observe: 'response', responseType: 'blob' })
      .pipe(
        // Update the given pagination object with values from the response.
        tap((resp) => {
          this.docService.saveFile(resp);
        })
      );
  }

  /**
   *
   * @param scope - The scope to use when doing the query
   * @param query - RSQL encoded string filter (create it at consumer level)
   */
  public getAllPaginatedForSelection(scope: FilterScope = this.userService.paginatedScope, query?: string): Observable<AnnuityPaginated[]> {

    const url = '/api/annuity/paginated';
    const headers = this.requestService.buildHttpHeaders();

    const paginationRequest: PaginatedData<PatentFamilyPaginated> = new PaginatedData<AnnuityPaginated>();
    paginationRequest.size = PAGINATE_NO_LIMIT;
    paginationRequest.page = 0;
    paginationRequest.query = query ? query : '';
    paginationRequest.scope = scope;

    // Requested columns (probably add everything manually request in the future.
    // https://townip.visualstudio.com/TownIP/_wiki/wikis/TownIP.wiki/78/Filtering-and-Pagination?anchor=annuity-dto
    paginationRequest.addColumnValue('endClientId');
    paginationRequest.addColumnValue('referenceNumbers');
    paginationRequest.addColumnValue('assignedVendor');

    return this.httpClient
      .post<any>(url, paginationRequest.toObject(), { headers: headers })
      .pipe(
        // Update the given pagination object with values from the response.
        map((response: PaginateObject<AnnuityPaginated>) => response.page.content ? response.page.content : [])
      );
  }

  public getAllByFamily(familyId: number, companyId: number, scope?: FilterScope): Observable<AnnuityPaginated[]> {
    const paginatedData = new PaginatedData<AnnuityPaginated>();
    const rsqlEncoder = new RsqlEncoder();

    paginatedData.scope = scope ? scope : this.userService.paginatedScope;
    paginatedData.size = PAGINATE_NO_LIMIT;
    paginatedData.addColumnValue(AnnuityPaginatedFields.referenceNumbers);
    paginatedData.sort(AnnuityPaginatedFields.annuityDeadline, 1);

    paginatedData.query = rsqlEncoder
      .encodeGroup([
        {
          field: AnnuityPaginatedFields.familyId,
          type: FieldType.Numeric,
          value: familyId,
          operator: OPERATORS.common.$eq,
        },
        {
          field: AnnuityPaginatedFields.companyId,
          type: FieldType.Numeric,
          value: companyId,
          operator: OPERATORS.common.$eq,
        }
      ]);

    return this.getAllPaginated(paginatedData)
      .pipe(
        map(data => data.content ? data.content : [])
      );
  }

  public getOnePaginated(id: number): Observable<AnnuityPaginated> {
    const paginatedData = new PaginatedData<AnnuityPaginated>();
    const rsqlEncoder = new RsqlEncoder();

    paginatedData.scope = this.userService.paginatedScope;
    paginatedData.size = PAGINATE_NO_LIMIT;

    paginatedData.query = rsqlEncoder.encode({
      field: AnnuityPaginatedFields.id,
      type: FieldType.Numeric,
      value: id,
      operator: OPERATORS.common.$eq,
    });

    return this.getAllPaginated(paginatedData)
      .pipe(
        map(data => {
          if (data.content.length > 0) {
            return data.content[0];
          }

          return null;
        })
      );
  }

  public getAnnuitiesCount(query: string, scope: FilterScope = this.userService.paginatedScope):
    Observable<RequestFilterNumberResponse> {
    const url = '/api/annuity/counts';

    const request: PaginatedRequest = new PaginatedRequest();
    request.filter = query;
    request.scope = scope;

    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient.post<RequestFilterNumberResponse>(url, request, { headers });
  }

  public get(id: number): Observable<Annuity> {
    const url = `/api/annuity/${id}`;
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient
      .get<Annuity>(url, { headers })
      .pipe(
        tap(annuity => {
          annuity.annuitySurchargeTiers = sortBy(annuity.annuitySurchargeTiers, 'tierLevel');
        })
      );
  }

  /**
   * Get the annuity together with all the related data items.
   * Usually used if you need full annuity data (ex: annuity detail page)
   * If you only need the base annuity, call this.get();
   *
   * @param id - Annuity Id
   * @param opts - Additional options for skipping requests
   */
  public async getWithAllDetails(id: number, opts: AnnuityFullDetailsOpts = {}): Promise<AnnuityFullDetails> {
    // All requests that are not dependent on each other and only needs the ID.
    // This is to optimize performance.
    const [annuity, patent, actions, invoiceLineItem] = await Promise.all([
      this.get(id)
        .toPromise(),
      this.annuityPatentService
        .getByAnnuityId(id)
        .toPromise(),
      this.annuityWorkflowService
        .getCategorizedActions(id)
        .toPromise(),
      !opts.skipInvoiceLine
        ? this.getInvoiceLineItem(id)
          .toPromise()
        : null
    ]);

    let companyRequest = null;
    let vendorRequest = null;

    if (!opts.skipCompany) {
      companyRequest = from(this.customerManagementService.getAnnuityCompanyDetailsById(patent.associatedCompany.id));
    }

    if (!opts.skipVendor && annuity.assignedVendor) {
      vendorRequest = this.vendorManagementService
        .getVendorById(annuity.assignedVendor.id);
    }

    let vendor: Vendor;
    let company: AnnuityCompanyDetails;

    // We only fetch the vendor if we have one.
    // When an annuity is recently created, it may or may not have a vendor yet.
    if (annuity.assignedVendor) {
      [vendor, company] = await Promise.all([
        vendorRequest ? vendorRequest.toPromise() : null,
        companyRequest ? companyRequest.toPromise() : null,
      ]);

    } else {
      company = companyRequest ? await companyRequest.toPromise() : null;
    }

    return {
      annuity,
      patent,
      company,
      vendor,
      invoiceLineItem,
      actions,
    } as AnnuityFullDetails;
  }

  public getAllInvoicesPaginated(request: PaginatedData<AnnuityInvoicePaginated>): Observable<PaginatedData<AnnuityInvoicePaginated>> {
    const url = '/api/annuity/invoice/paginated';
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient
      .post<any>(url, request.toObject(), { headers })
      .pipe(
        // Update the given pagination object with values from the response.
        map((response: PaginateObject<AnnuityInvoicePaginated>) => request.update(response)),
      );
  }

  public getInvoiceLineItem(id: number): Observable<AnnuityInvoiceLineItem> {
    const url = `/api/annuity/${id}/invoice-line-item`;
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient
      .get<AnnuityInvoiceLineItem>(url, { headers });
  }

  public importBulkSendPaymentInstruction(data: AnnuityBulkSendPaymentInstructionRequest): Observable<AnnuityBulkSendPaymentInstructionResponse> {
    const url = '/api/annuity/bulk/payment/instruction/import';
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient
      .post<AnnuityBulkSendPaymentInstructionResponse>(url, data, { headers });
  }

  public getBulkSendPaymentTemplate(companyId: number, companyUserId?: number, endClientId?: number): Observable<HttpResponse<Blob>> {

    let url = `/api/annuity/bulk/payment/instruction/template/customer/${companyId}`;
    const headers = this.requestService.buildHttpHeaders();
    const queryParams = new ApiQueryParams({
      queryParams: { companyUserId, endClientId }
    })
      .generateQueryParams();

    url += queryParams;

    return this.httpClient
      .get(url, { headers, observe: 'response', responseType: 'blob' });
  }

  public getHistory(annuityId: number): Observable<AnnuityHistory[]> {
    const url = `/api/annuity/${annuityId}/history`;
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient.get<AnnuityHistory[]>(url, { headers });
  }

  public getRequiredFields(
    countryCodes: CountryCodes[],
    ipRightType: IpRightType,
    parentCountryCode: CountryCodes,
  ): Observable<AnnuityRequiredFieldsConfig[]> {
    const url = '/api/annuity/required-fields';
    const headers = this.requestService.buildHttpHeaders();

    let params = new HttpParams();
    params = params.append('countryCodes', countryCodes.toString());
    params = params.append('ipRightType', ipRightType);
    params = params.append('parentCountryCode', parentCountryCode);

    return this.httpClient.get<AnnuityRequiredFieldsConfig[]>(url, { headers, params });
  }

  public getAllRequiredFields(configuredOnly: boolean = false): Observable<AnnuityRequiredFieldsConfig[]> {
    // let url = `/api/annuity/required-fields/all?configuredOnly=${configuredOnly}`;
    const url = '/api/annuity/required-fields/all';
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient.get<AnnuityRequiredFieldsConfig[]>(url, { headers });
  }

  public getCountryRequiredFields(
    countryId: number,
    countryCode: string,
    ipRightType: IpRightType = IpRightType.Patent,
    annuityId?: number,
    annuityPatentId?: number,
    parentCountryId?: number,
    parentCountryCode?: string,
  ): Observable<AnnuityRequiredFieldsConfig> {

    let url = '/api/annuity/required-fields/country?';
    let params = new HttpParams();
    params = params.append('countryId', countryId.toString());
    params = params.append('countryCode', countryCode);
    params = params.append('ipRightType', ipRightType);

    if (annuityId) {
      params = params.append('annuityId', annuityId.toString());
    }

    if (annuityPatentId) {
      params = params.append('annuityPatentId', annuityPatentId.toString());
    }

    if (parentCountryId) {
      params =params.append('parentCountryId', parentCountryId.toString());
    }

    if (parentCountryCode) {
      params = params.append('parentCountryCode', parentCountryCode);
    }

    // Need to do this to trigger caching properly.
    url += params.toString();

    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient
      .get<AnnuityRequiredFieldsConfig>(url, { headers });
  }

  public getaBatchCountryRequiredFields(
    ipRightType: IpRightType,
    parentCountryCode: string,
    countryCodes: string[]
  ): Observable<AnnuityRequiredFieldsConfig[]> {
    let url = '/api/annuity/required-fields';
    url += `?ipRightType=${ipRightType}&parentCountryCode=${parentCountryCode}&countryCodes=${countryCodes}`;

    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.get<AnnuityRequiredFieldsConfig[]>(url, { headers });
  }

  public getCountryRequiredFieldsForPartialRenewal(
    countryId: number,
    countryCode: string,
    ipRightType: IpRightType = IpRightType.Patent,
    numberOfSubDesign: number = null,
    trademarkClassesCount: number = null,
  ): Observable<{ design: boolean, states: boolean, trademark: boolean }> {

    return this.getCountryRequiredFields(countryId, countryCode, ipRightType)
      .pipe(
        map(fields => {
          const canRenewStates = this.canPartialRenewType(
            fields,
            AnnuitySpecificRequiredField.StatesPartialRenewalAvailable
          );
          const canRenewDesigns = this.canPartialRenewType(
            fields,
            AnnuitySpecificRequiredField.DesignsPartialRenewalAvailable
          );
          const canRenewTrademark = this.canPartialRenewType(
            fields,
            AnnuitySpecificRequiredField.TrademarksPartialRenewalAvailable
          );

          let design: boolean;
          try {
            design = canRenewDesigns && fields
              .annuityPatentRequiredFields
              .some((field) => field.requiredFieldType === AnnuityRequiredField.NumberOfSubDesigns);

            if (numberOfSubDesign === 1) {
              // Bug-18542: Patent is not allowed for partial renewal if the number of sub design is only one.
              design = false;
            }
          } catch (e) {
            design = false;
          }

          let states: boolean;
          try {
            states = canRenewStates && fields
              .annuityPatentRequiredFields
              .some((field) => field.requiredFieldType === AnnuityRequiredField.DesignatedStates);
          } catch (e) {
            states = false;
          }

          // Already handled by the top level handler
          let trademark = canRenewTrademark;
          if (trademark && trademarkClassesCount <= 1) {
            trademark = false;
          }

          return { design, states, trademark };
        })
      );
  }

  public getCountryRequiredFieldsForPartialRenewalV2(
    countryId: number,
    countryCode: string,
    ipRightType: IpRightType = IpRightType.Patent,
    annuityPatentId?: number,
    annuityId?: number,
    parentCountryId?: number,
    parentCountryCode?: string,
  ): Observable<{ design: boolean, states: boolean, trademark: boolean }> {
    return this.getCountryRequiredFields(
      countryId,
      countryCode,
      ipRightType,
      annuityId,
      annuityPatentId,
      parentCountryId,
      parentCountryCode,
    )
      .pipe(
        map(fields => {
          return fields.partialRenewalType;
        }),
        map(partialType => {
          return {
            design: partialType === AnnuityRequiredFieldPartialType.DesignsPartialRenewalAvailable,
            states: partialType === AnnuityRequiredFieldPartialType.StatesPartialRenewalAvailable,
            trademark: partialType === AnnuityRequiredFieldPartialType.TrademarksPartialRenewalAvailable
          };
        })
      );
  }

  public isDoiRequired(resp: AnnuityRequiredFieldsConfig): boolean {
    return resp
      .annuitySpecificRequiredFields
      .some((field) => {
        return field.specificRequiredFields
          .some(subfield => subfield === AnnuitySpecificRequiredField.DeclarationOfIncontestability);
      });
  }

  public getInvoiceSummary(annuityId: number): Observable<AnnuityInvoiceSummary> {
    const url = `/api/annuity/${annuityId}/invoice-summary`;
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient.get<AnnuityInvoiceSummary>(url, { headers });
  }

  public getFeeDetails(annuityId: number): Observable<AnnuityFeeDetail> {
    const url = `/api/annuity/${annuityId}/fee-detail`;
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient.get<AnnuityFeeDetail>(url, { headers });
  }

  public getAnnuityIdByPaymentId(paymentId: string): Observable<number> {
    const url = `/api/annuity/payment-id/${paymentId}`;
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient.get<number>(url, { headers });
  }

  public getInstructAnnuityCompanyUserLiteByCompanyId(companyId: number): Observable<CustomerLite[]> {
    const url = `/api/annuity/company/${companyId}/send-payment-instruction/company-users`;
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.httpClient.get<CustomerLite[]>(url, { headers: headers });
  }

  public getEndClientsByCompanyAndUser(companyId: number, companyUserId?: number): Observable<EndClient[]> {
    let url = `/api/annuity/company/${companyId}/send-payment-instruction/end-client`;
    if (companyUserId) {
      url += `?companyUserId=${companyUserId}`;
    }

    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.httpClient.get<EndClient[]>(url, { headers: headers });
  }

  public calculatePartialRenewal(data: AnnuityAdditionalInstruction): Observable<AnnuityInvoiceSummary> {
    const url = '/api/annuity/calculate/additional-instruction';
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.httpClient.post<AnnuityInvoiceSummary>(url, data, { headers: headers });
  }

  public saveExtensionStatus(annuityId: number, extensionStatus: AnnuityExtensionStatus): Observable<Annuity> {
    const url = `/api/annuity/${annuityId}/extension/status`;
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    const params = { extensionStatus };

    return this.httpClient.post<Annuity>(url, null, { headers, params });
  }

  public getQualityControlPaginated(request: PaginatedData<AnnuityPaginated>): Observable<PaginatedData<AnnuityPaginated>> {
    const url = '/api/annuity/quality-control/paginated';
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient
      .post<any>(url, request.toObject(), { headers: headers })
      .pipe(
        // Update the given pagination object with values from the response.
        map((response: PaginateObject<AnnuityPaginated>) => request.update(response))
      );
  }

  public completeQualityControl(annuityId: number, request: AnnuityQualityControlRequest): Observable<AnnuityFulfillmentRecord> {
    const url = `/api/annuity/${annuityId}/complete-quality-control`;
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();

    return this.httpClient.put<AnnuityFulfillmentRecord>(url, request, { headers });
  }

  public assignQualityControl(annuityId: number, userId: number): Observable<AnnuityFulfillmentQcRecords> {
    const url = `/api/annuity/${annuityId}/assign-quality-control`;
    const params: Params = { userId };
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient.put(url, null, { headers, params });
  }

  public releaseAssignment(annuityId: number): Observable<AnnuityFulfillmentQcRecords> {
    const url = `/api/annuity/${annuityId}/release-assignment`;
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient.put(url, null, { headers });
  }

  public getTrademarkClasses(countryCode: string, countryId: number): Observable<TrademarkClass[]> {
    const url = '/api/annuity/trademark-classes';
    const headers = this.requestService.buildHttpHeaders();

    const params = new HttpParams()
      .set('countryCode', countryCode)
      .set('countryId', countryId.toString());

    return this.httpClient.get<TrademarkClass[]>(url, { headers, params });
  }

  public getMarkTypes(countryCode: string, countryId: number): Observable<MarkType[]> {
    const url = '/api/annuity/type-of-mark';
    const headers = this.requestService.buildHttpHeaders();

    const params = new HttpParams()
      .set('countryCode', countryCode)
      .set('countryId', countryId.toString());

    return this.httpClient.get<MarkType[]>(url, { headers, params });
  }

  public canAutoInstructImmediately(annuityId: number): Observable<GeneralResponseWithStatus> {
    const url = `/api/annuity/${annuityId}/is-auto-instruct-edge-case`;
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient.get<GeneralResponseWithStatus>(url, { headers });
  }

  public updateDeclarationOfIncontestability(annuityId: number, isDeclarationOfIncontestability: boolean = false): Observable<Annuity> {
    let url = `/api/annuity/${annuityId}/edit-declaration-of-incontestability`;
    url += `?isDeclarationOfIncontestability=${isDeclarationOfIncontestability}`;

    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.put<Annuity>(url, null, { headers });
  }

  public canShowDoU(iprServiceType: IprServiceType, status: AnnuityClientStatus, autoInstructed: boolean): boolean {
    return includes([
      IprServiceType.DeclarationOfUse,
      IprServiceType.RenewalAndDeclarationOfUse,
    ], iprServiceType) && autoInstructed &&
      status === AnnuityClientStatus.WaitingForInstruction;
  }

  private canPartialRenewType(fields: AnnuityRequiredFieldsConfig, type: AnnuitySpecificRequiredField): boolean {
    return fields.annuitySpecificRequiredFields
      .some((field) => {
        return field.specificRequiredFields
          .some((specificField) => {
            return specificField === type;
          });
      });
  }
}
