import { Directive, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { UserService } from '../services/user.service';

@Directive({
  selector: '[townipIfInternalUser]',
})
export class IfInternalUserDirective implements OnInit {

  constructor(private templateRef: TemplateRef<any>,
              private viewContainer: ViewContainerRef,
              private userService: UserService) {
  }

  public ngOnInit(): void {
    const currentUser = this.userService.getUser();
    if (currentUser.userType === 'INTERNAL') {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }

}
