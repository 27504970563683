<div class="tip-loading {{styleClass}}">
</div>
<div class="tip-loading-text-box {{styleClass}}">
  <div class="tip-loading-content">
    <div class="row justify-content-center">
      <div class="tip-loading-box">
        <span class="tip-loading-text">{{text}}</span>
        <div class="tip-loading-spin"></div>
      </div>
    </div>
  </div>
</div>
