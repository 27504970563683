import { EventEmitter, Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

/**
 * This service is a companion service for FileUploadIntereceptorService
 * We are getting a different instance for Interceptor (HTTP_INTERCEPTOR), in this case we fail to emit out the Files.
 * NOTE: `FileUploadServiceSingletonInstance` class will only be used in `FileUploadInterceptorService` class
 */
@Injectable({
  providedIn: 'root'
})
export class FileUploadServiceSingletonInstance {
  public onFileUpload: EventEmitter<any> = new EventEmitter<any>();
}

@Injectable({
  providedIn: 'root'
})
export class FileUploadInterceptorService implements HttpInterceptor {

  /**
   * This event will get triggered once the prime file upload is completed and success.
   */
  public onFileUpload: EventEmitter<any>;

  constructor(private service: FileUploadServiceSingletonInstance) {
    this.onFileUpload = this.service.onFileUpload;
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next
      .handle(req)
      .pipe(
        tap((event) => {
          if (event instanceof HttpResponse) {
            if (event.url.includes('/api/file')) {
              // NOTE: onUpload function in prime upload and this(interceptor) are being called exactly the same time.
              // We need to put a 500ms delay,
              // so that the components will have enough time to subscribe before emitting in here.
              setTimeout(() => {
                this.service.onFileUpload.emit(event.body);
              }, 500);
            }
          }
        })
      );
  }
}
