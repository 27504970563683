import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LeavePageWarningModalComponent } from './leave-page-warning-modal.component';
import { ModalModule } from '../../../controls/modal/modal.module';
import { StandardAlertModule } from '../standard-alert/standard-alert.module';

@NgModule({
  declarations: [LeavePageWarningModalComponent],
  exports: [LeavePageWarningModalComponent],
  imports: [
    CommonModule,
    ModalModule,
    StandardAlertModule,
  ],
})
export class LeavePageWarningModalModule {
}
