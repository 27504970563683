/* tslint:disable:max-line-length */

export interface EditorV2Icon {
  name: string;
  src: string;
}

export const EDITOR_ICONS: EditorV2Icon[] = [
  {
    name: 'paperclip',
    src: '<svg viewBox="0 0 1792 1792" width="24" height="24"><path fill="#00B1A8" d="M1596 1385q0 117-79 196t-196 79q-135 0-235-100l-777-776q-113-115-113-271 0-159 110-270t269-111q158 0 273 113l605 606q10 10 10 22 0 16-30.5 46.5t-46.5 30.5q-13 0-23-10l-606-607q-79-77-181-77-106 0-179 75t-73 181q0 105 76 181l776 777q63 63 145 63 64 0 106-42t42-106q0-82-63-145l-581-581q-26-24-60-24-29 0-48 19t-19 48q0 32 25 59l410 410q10 10 10 22 0 16-31 47t-47 31q-12 0-22-10l-410-410q-63-61-63-149 0-82 57-139t139-57q88 0 149 63l581 581q100 98 100 235z"/></svg>'
  }
];
