import firebase from 'firebase/app';
import 'firebase/auth';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class FirebaseService {

  public app: firebase.app.App;

  public auth: firebase.auth.Auth;

  public recaptchaSolved = new BehaviorSubject<boolean>(false);

  constructor() {
    this.app = firebase.initializeApp(environment.firebase);
    this.auth = firebase.auth();
  }

  public initInvisibleCaptchaVerifier(buttonId: string): firebase.auth.RecaptchaVerifier {
    //  This makes sure we always have to wait for true in the implementations.
    this.recaptchaSolved.next(false);

    return new firebase.auth.RecaptchaVerifier(buttonId, {
      'size': 'invisible',
      'callback': (response: string) => {
        // For debugging.
        this.recaptchaSolved.next(true);
      },
      'expired-callback': () => {
        this.recaptchaSolved.next(false);
      }
    });
  }

  public async sendMfaCode(
    resolver: firebase.auth.MultiFactorResolver,
    recaptchaVerifier: firebase.auth.RecaptchaVerifier
  ): Promise<string> {

    const phoneInfoOptions = {
      multiFactorHint: resolver.hints[0], // We only have 1 activated in the system, (SMS)
      session: resolver.session
    };

    const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
    // Send SMS verification code
    return phoneAuthProvider
      .verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier);
  }

  public async sendMfaCodeForEnrollment(appVerifier: any, phone: string): Promise<string> {
    const mfaSession = await this.auth
      .currentUser
      .multiFactor
      .getSession();

    const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
    const phoneInfoOptions = {
      phoneNumber: phone,
      session: mfaSession
    };

    return phoneAuthProvider
      .verifyPhoneNumber(phoneInfoOptions, appVerifier);
  }

  public async verifyMfaEnroll(verificationId: string, verificationCode: string): Promise<void> {
    const cred = firebase
      .auth.PhoneAuthProvider
      .credential(verificationId, verificationCode);

    const multiFactorAssertion = firebase
      .auth
      .PhoneMultiFactorGenerator
      .assertion(cred);

    return this.auth
      .currentUser
      .multiFactor
      .enroll(multiFactorAssertion);
  }

  public async verifyMfaSignIn(
    resolver: firebase.auth.MultiFactorResolver,
    verificationId: string,
    verificationCode: string
  ): Promise<firebase.auth.UserCredential> {
    const cred = firebase.auth.PhoneAuthProvider.credential(verificationId, verificationCode);
    const multiFactorAssertion = firebase.auth.PhoneMultiFactorGenerator.assertion(cred);

    return resolver.resolveSignIn(multiFactorAssertion);
  }

  public getCurrentUser(): firebase.User {
    return this.auth.currentUser;
  }

  public removePhoneMFA(): Promise<void> {
    // Need to re-login first before calling this command.
    const factor = this.getActiveMFAs().find((mfa) => mfa.factorId === 'phone');
    return this.auth
      .currentUser
      .multiFactor
      .unenroll(factor);
  }

  public getActiveMFAs(): firebase.auth.MultiFactorInfo[] {
    return this.auth
      .currentUser
      .multiFactor
      .enrolledFactors;
  }

  public getActiveMfaNumber(): string {
    const mfas = this.auth
      .currentUser
      .multiFactor
      .enrolledFactors as firebase.auth.PhoneMultiFactorInfo[];

    // We only have 1 mfa enabled.
    if (mfas.length > 0) {
      return mfas[0].phoneNumber;
    }

    return '';
  }

  public translateErrorCode(errorCode: string, custom?: string): string {
    let msg: string = '';
    let noMatch = false;

    switch (errorCode) {
    case 'auth/invalid-verification-code':
      msg = 'That code is not valid. Resend Code if needed.';
      break;
    case 'auth/missing-verification-code':
      msg = 'Verification code is required.';
      break;
    case 'auth/code-expired':
      msg = 'Code Expired. Resend code if needed.';
      break;
    case 'auth/too-many-requests':
      msg = 'Action blocked. Too many requests.';
      break;
    case 'auth/invalid-phone-number':
      msg = 'Invalid phone number format. Make sure you start with + and your country code. (ex: +1 123 456 7890)';
      break;
    case 'auth/unverified-email':
      msg = 'Unverified email.';
      break;
    case 'auth/invalid-multi-factor-session':
      msg = 'MFA session expired. Please CANCEL and restart the process again.';
      break;
    case 'auth/missing-verification-id':
      msg = 'You have not verified the phone number.';
      break;
    case 'auth/requires-recent-login':
      msg = 'This action requires a recent login. Please Cancel and restart the process again.';
      break;
    default:
      msg = errorCode;
      noMatch = true;
    }

    if (custom && noMatch) {
      msg = custom;
    }

    return msg;
  }
}
