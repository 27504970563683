import { Pipe, PipeTransform } from '@angular/core';
import { Filter } from '../../../data-filter/filter';

@Pipe({
  name: 'activeOnly',
  pure: false
})
export class ActiveOnlyPipe implements PipeTransform {

  public transform(value: Filter[], args?: any): any {
    const filtered = value.filter((f) => {
      if (f.active === true) {
        return f;
      }
    });

    return filtered;
  }

}
