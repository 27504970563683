<!-- Old implementation, no checking if content exist -->
<!--<div class="d-flex">-->
  <!--<div class="user-image"><ng-content select="townip-user-image"></ng-content></div>-->
  <!--<div class="flex-grow" style="overflow: hidden">-->
    <!--<div class="user-name">-->
      <!--<ng-content select="townip-user-name"></ng-content>-->
    <!--</div>-->
    <!--<div class="user-content">-->
      <!--<ng-content select="townip-user-content"></ng-content>-->
    <!--</div>-->
  <!--</div>-->
<!--</div>-->

<!--
 Basically, this one checks if there is a townip-user-content TRANSCLUDED
 If there is none, will center the name to the user image.

 If there is, will display in two lines
 -->
<div class="d-flex no-gutters">
  <div class="user-image" *ngIf="userImage">
    <ng-content select="townip-user-image"></ng-content>
  </div>
  <div class="flex-grow" [ngClass]="{ 'd-flex': !userContent }" style="overflow: hidden">
    <div class="user-type" *ngIf="userType">
      <ng-content select="townip-user-type"></ng-content>
    </div>
    <div class="user-name text-truncate" [ngClass]="{ 'align-self-center': !userContent }">
      <ng-content select="townip-user-name"></ng-content>
    </div>
    <div class="user-content" *ngIf="userContent">
      <ng-content select="townip-user-content"></ng-content>
    </div>
  </div>
</div>
