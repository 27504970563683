import { Pipe, PipeTransform } from '@angular/core';
import { IndexedSelectItem } from '../message-find-recipient/message-find-recipient.component';
import { sortBy } from '../../../utils/sort-by';
import { isNullOrUndefined } from '../../../utils/is-null-or-undefined';

@Pipe({
  name: 'companyUsers'
})
export class CompanyUsersPipe implements PipeTransform {

  public transform(filteredUsers: IndexedSelectItem[], organization: string, userType: string): IndexedSelectItem[] {
    if (!filteredUsers) {
      return null;
    }

    const organizationUsers = filteredUsers
      .filter(user => !isNullOrUndefined(user.value.organization))
      .filter(user => user.value.organization === organization && user.value.recipientType === userType);

    return sortBy(organizationUsers, (user: IndexedSelectItem) => {
      return user.label.toLowerCase();
    });
  }

}
