import { Component, OnInit, Input, OnChanges, EventEmitter, Output, ViewChildren, QueryList, HostListener } from '@angular/core';
import { Message } from '../../../dto/messaging/message';
import { SelectItem } from 'primeng/api';
import { UserService } from '../../../services/user.service';
import { PortalUser } from '../../../domain/user';
import MessageUtil from '../../../../shared/message-util';
import { MessageInfo } from '../messages/messages.component';
import { MessagingService } from '../../../services/messaging.service';
import { Country } from '../../../dto/system-config/country';
import { TooltipDirective } from 'ngx-bootstrap/tooltip';
import { isNullOrUndefined } from '../../../utils/is-null-or-undefined';

@Component({
  selector: 'townip-message-list',
  templateUrl: './message-list.component.html',
  styleUrls: ['./message-list.component.scss']
})
export class MessageListComponent implements OnInit, OnChanges {

  @ViewChildren('recipientTooltip')
  public recipientTooltips: QueryList<TooltipDirective>;

  @Input()
  public messages: MessageInfo[];

  @Input()
  public activeMessage: number; // The ID of the actively selected message, (used to show active borders)

  @Input()
  public hasBg: boolean; // Wether or not the message list has a background color

  @Input()
  public isDashboard: boolean;

  @Input()
  public listWidth = '365px'; // NOTE: Must always have a value for layouts.

  @Input()
  public countrySelectItems: SelectItem[];

  @Output()
  // NOTE: Only suppressing because legacy.
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  public onSelect: EventEmitter<MessageInfo> = new EventEmitter();

  @Output()
  // NOTE: Only suppressing because legacy.
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  public onNavigateScope: EventEmitter<MessageInfo> = new EventEmitter();

  @Output()
  // NOTE: Only suppressing because legacy.
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  public onCreate: EventEmitter<boolean> = new EventEmitter();

  @Input()
  public headerText: string;

  @Input()
  public showProjectScope = true;

  public showUnreadOnly = false;

  public filteredByUnread: MessageInfo[];

  public filteredMessages: MessageInfo[];

  public searchQuery: string;

  public selectedCountry: Country;

  public selectedSearchFilter: string;

  public searchFilters: SelectItem[];

  public currentUser: PortalUser;

  public timeout: any;

  public specialCharacters: RegExp = /^[^\\/[\]{}|()]+$/;

  @HostListener('document:click', ['$event'])
  public clickedAnywhere(event: any): void {
    // we get many events here, want to make sure we are dealing with a string first...
    if (typeof event.target.className === 'string' && event.target.className.includes('recipient-count')) {
      // Terminate when clicked the +X label element
      return;
    }

    // We must close the tooltips when clicked anywhere
    this.allRecipientTooltipClosed();
  }

  constructor(private userService: UserService,
              private messagingService: MessagingService) {
    this.filteredMessages = [];
    this.hasBg = true;
    this.buildSearchFilters();
    this.currentUser = this.userService.getUser();
  }

  public ngOnInit(): void {
    if (this.countrySelectItems) {
      this.countrySelectItems.push({ label: 'No Country', value: { id: null } });
    }
  }

  public ngOnChanges(): void {
    if (this.messages) {
      this.setupUserMessageState();
      this.filterMessages();
    }
  }

  private buildSearchFilters(): void {
    this.searchQuery = '';
    this.searchFilters = [
      { value: 'subject', label: 'Subject' },
      { value: 'sender', label: 'Sender' },
      { value: 'all', label: 'All' }
    ];

    // Set the default
    this.selectedSearchFilter = 'all';
  }

  public filterMessages(): void {
    if (this.isDashboard) {
      this.showUnreadOnly = true;
    }

    this.filteredByUnread = this.messages.filter( message => {
      if (isNullOrUndefined(message.messageRecipients)) {
        if (this.currentUser.userType === 'INTERNAL') {
          return true;
        } else {
          return false;
        }
      } else {
        const recipient = message.messageRecipients.find( tempRecipient => {
          return tempRecipient.user.id === this.currentUser.id;
        } );
        return (recipient ? !recipient.read : false) || !this.showUnreadOnly;
      }
    } );

    // If no search query, do no filter
    if (this.searchQuery === '' && !this.selectedCountry) {
      this.clearFilter();
      return;
    }

    // If country selected to filter by, filter messages by that country, then do other filter.
    // Otherwise filter whole list.
    const messagesToFilter =
      this.selectedCountry ? this.filterByCountry(this.filteredByUnread) : this.filteredByUnread;

    switch (this.selectedSearchFilter) {
    case 'subject':
      this.filterBySubject(messagesToFilter);
      break;
    case 'sender':
      this.filterByAuthor(messagesToFilter);
      break;
    case 'all':
      this.filterByAll(messagesToFilter);
      break;
    }
  }

  public showFileAttachments(message: Message): boolean {
    return MessageUtil.showFileAttachments(message);
  }

  private clearFilter(): void {
    this.filteredMessages = [...this.filteredByUnread];
  }

  private filterBySubject(messagesToFilter: Message[]): void {
    this.filteredMessages = MessageUtil.filterMessagesBySubject(messagesToFilter, this.searchQuery);
  }

  private filterByAuthor(messagesToFilter: Message[]): void {
    this.filteredMessages = MessageUtil.filterMessagesByAuthor(messagesToFilter, this.searchQuery);
  }

  private filterByAll(messagesToFilter: Message[]): void {
    this.filteredMessages = MessageUtil.filterMessagesByAll(messagesToFilter, this.searchQuery);
  }

  private filterByCountry(messagesToFilter: Message[]): Message[] {
    return MessageUtil.filterMessagesByCountry(messagesToFilter, this.selectedCountry.id);
  }

  public select(message: Message): void {
    this.onSelect.emit(message);
  }

  public navigateScope(message: Message): void {
    this.onNavigateScope.emit(message);
  }

  public create(): void {
    this.onCreate.emit(true);
  }

  public toggleRead(message: MessageInfo): void {
    message.isUserUnreadMessage = !message.isUserUnreadMessage;

    message.messageRecipients.forEach(recipient => {
      if (recipient.user.id === this.currentUser.id) {
        recipient.read = !recipient.read;
      }
    });

    this.messagingService
      .toggleRead(this.currentUser.id, message.baseMessageId)
      .toPromise();
  }

  public allRecipientTooltipClosed(selectedTooltip: TooltipDirective = null): void {
    for (const recipientTooltip of this.recipientTooltips.toArray()) {
      if (recipientTooltip === selectedTooltip) {
        // Do not close current tooltip
        continue;
      }

      recipientTooltip.hide();
    }
  }

  /**
   * This will identify if the message is read/unread by the user
   */
  private setupUserMessageState(): void {
    if (this.messages) {
      this.messages.forEach(message => {
        let isUnread = false;
        message.messageRecipients.forEach(recipient => {
          if (recipient.user.id === this.currentUser.id && recipient.read === false) {
            isUnread = true;
          }
        });

        message.isUserUnreadMessage = isUnread;
      });
    }
  }

  public trackBy(index: any, item: MessageInfo): number {
    return undefined;
  }
}
