<townip-modal
  size="lg"
  (onHide)="onHidden.emit()"
  heading="{{ isEditing ? 'Edit Filter ' + filterName : 'Build new filter' }}">
  <townip-modal-body>
    <div *ngIf="error" class="alert alert-danger" role="alert">
      <strong>FAILED: </strong> {{ error }}
    </div>
    <form #form="ngForm">
      <div class="form-group">
        <label for="filtername">Name</label>
        <input type="text" id="filtername" class="form-control" aria-describedby="filterName"
               [(ngModel)]="filterName" name="filterName" required>
      </div>
      <div class="form-group">
        <p-multiSelect
          [options]="options"
          [ngModel]="selectedOption"
          (ngModelChange)="selectedOption = $event; updateFields();"
          [defaultLabel]="'Select a filter'"
          [displaySelectedLabel]="true"
          name="selectedOption">
        </p-multiSelect>
      </div>
      <table class="table">
        <thead>
        <tr>
          <th><h6>Field</h6></th>
          <th><h6>Operator</h6></th>
          <th><h6>Value</h6></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let filter of (filters | activeOnly); let i = index">
          <td>{{ filter.name }}</td>
          <td>
            <!--<div>OP: {{filter.operator}}</div>-->
            <ng-container *ngIf="filter.operatorsOverride.length > 0; else defaultOperators"><!-- If we are overriding  the operators -->
              <select class="form-control"
                      [ngModel]="filter.operator"
                      (ngModelChange)="filter.operator = $event; filter.resetValue()"
                      [ngModelOptions]="{ standalone: true }" required>
                <option *ngFor="let op of filter.operatorsOverride" [value]="op.value">{{op.label}}</option>
              </select>
            </ng-container>
            <ng-template #defaultOperators>
              <select class="form-control"
                      [ngModel]="filter.operator"
                      (ngModelChange)="filter.operator = $event; filter.resetValue()"
                      [ngModelOptions]="{ standalone: true }" required>
                <option *ngFor="let op of (operators | filterOperator:filter.type)" [value]="op.value">{{op.label}}</option>
              </select>
            </ng-template>
          </td>
          <td [ngSwitch]="filter.type">
            <ng-container *ngSwitchCase="fieldTypes.Boolean">
              <select class="form-control" [(ngModel)]="filter.value" [ngModelOptions]="{ standalone: true }">
                <option value="true">Yes</option>
                <option value="false">No</option>
              </select>
            </ng-container>
            <ng-container *ngSwitchCase="fieldTypes.Numeric">
              <ng-container [ngSwitch]="filter.operator">
                <ng-container *ngSwitchCase="'between'">
                  <div>
                    <p-slider
                      [(ngModel)]="filter.value"
                      [min]="filter.minValue"
                      [max]="filter.maxValue"
                      [ngModelOptions]="{ standalone: true }"
                      [range]="true">
                    </p-slider>
                  </div>
                  <div class="d-flex margin-top-10">
                    <input type="number" class="form-control" [(ngModel)]="filter.value[0]" placeholder="Minimum" [ngModelOptions]="{standalone: true}" />
                    <span class="align-self-center">&nbsp;-&nbsp;</span>
                    <input type="number" class="form-control" [(ngModel)]="filter.value[1]" placeholder="Maximum" [ngModelOptions]="{standalone: true}" />
                  </div>
                </ng-container>
                <ng-container *ngSwitchDefault>
                  <input type="number"
                         class="form-control"
                         [(ngModel)]="filter.value"
                         [ngModelOptions]="{ standalone: true }"
                         placeholder="Enter a number">
                </ng-container>
              </ng-container>
              <!--<input type="number" class="form-control" [(ngModel)]="filter.value" name="filterVal[i]">-->
            </ng-container>
            <ng-container *ngSwitchCase="fieldTypes.Date">
              <div class="d-flex">
                <p-calendar [selectOtherMonths]="true" [(ngModel)]="filter.value[0]" name="filterVal{{i}}">
                </p-calendar>
                <span class="align-self-center">&nbsp;-&nbsp;</span>
                <p-calendar [selectOtherMonths]="true" [(ngModel)]="filter.value[1]" name="filterVal2{{i}}">
                </p-calendar>
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="fieldTypes.Array">
              <!--Array-->
              <p-multiSelect
                [options]="filter.selectionValues"
                [(ngModel)]="filter.value"
                [defaultLabel]="'Select Values'"
                [displaySelectedLabel]="true"
                [ngModelOptions]="{ standalone: true }">
              </p-multiSelect>
            </ng-container>
            <ng-container *ngSwitchCase="fieldTypes.Collection">
              <!--Collection-->
              <p-multiSelect
                [options]="filter.selectionValues"
                [(ngModel)]="filter.value"
                [defaultLabel]="'Select Values'"
                [displaySelectedLabel]="true"
                [ngModelOptions]="{ standalone: true }">
              </p-multiSelect>
            </ng-container>

            <!-- String Types: -->
            <!-- Gonna need to refactor this area for maintainability -->
            <ng-container *ngSwitchDefault>
              <!-- For contains, they use special fields -->
              <ng-container *ngIf="filter.operator === 'contains' || filter.operator === 'does-not-contain'; else other">
                <ng-container [ngSwitch]="filter.input">
                  <ng-container *ngSwitchCase="inputTypes.Selection">
                    <p-multiSelect
                      [options]="filter.selectionValues"
                      [(ngModel)]="filter.value"
                      [defaultLabel]="'Select Items'"
                      [displaySelectedLabel]="true"
                      [ngModelOptions]="{ standalone: true }">
                    </p-multiSelect>
                  </ng-container>
                  <ng-container *ngSwitchDefault>
                    <ng-container *ngIf="filter.selectionValues; else noSelectionValueString">
                      <p-multiSelect
                        [options]="filter.selectionValues"
                        [(ngModel)]="filter.value"
                        [defaultLabel]="'Select Items'"
                        [displaySelectedLabel]="true"
                        [ngModelOptions]="{ standalone: true }">
                      </p-multiSelect>
                    </ng-container>
                    <ng-template #noSelectionValueString>
                      <input class="form-control"
                             [(ngModel)]="filter.value" name="filterVal{{i}}"
                             placeholder="Enter comma-separated values (apples, oranges)">
                    </ng-template>
                  </ng-container>
                </ng-container>
              </ng-container>

              <!-- For all other string operators -->
              <ng-template #other>
                <ng-container [ngSwitch]="filter.input">
                  <ng-container *ngSwitchCase="inputTypes.Selection">
                    <!--<select class="form-control" [(ngModel)]="filter.value" [ngModelOptions]="{ standalone: true }">-->
                    <!--<option *ngFor="let opt of filter.selectionValues" [value]="opt.value">{{opt.label}}</option>-->
                    <!--</select>-->
                    <p-dropdown [options]="filter.selectionValues"
                                [(ngModel)]="filter.value"
                                [ngModelOptions]="{ standalone: true }"
                                [filter]="true">
                    </p-dropdown>
                  </ng-container>
                  <ng-container *ngSwitchCase="inputTypes.Autocomplete">
                    <input [(ngModel)]="filter.value"
                           name="filterVal{{i}}"
                           [typeahead]="filter.selectionValues"
                           [typeaheadOptionField]="'value'"
                           class="form-control">
                  </ng-container>
                  <ng-container *ngSwitchDefault>
                    <input type="text" class="form-control" [(ngModel)]="filter.value" name="filterVal{{i}}">
                  </ng-container>
                </ng-container>
              </ng-template>
            </ng-container>
          </td>
        </tr>
        </tbody>
      </table>
    </form>
  </townip-modal-body>
  <townip-modal-footer>
    <button type="button" class="btn btn-cta-primary" aria-label="Add filter" (click)="onSave(form)">
      Save
    </button>
    <button type="button" class="btn btn-cta-secondary" aria-label="Cancel" (click)="onClose()">
      Cancel
    </button>
  </townip-modal-footer>
</townip-modal>
