import { NgModule } from '@angular/core';
import { CurrencyWrapperPipe } from './currency-wrapper-pipe';
import { IncludesPipe } from './includes.pipe';
import { LanguagePipe } from './language.pipe';
import { CountryPipe } from './country.pipe';
import { NumberWrapperPipe } from './number-wrapper.pipe';
import { DiscountWrapperPipe } from './discount-wrapper.pipe';
import { RelativeTimePipe } from './relative-time.pipe';
import { KeyMatchPipe } from './key-match.pipe';
import { TaskAssigneePipe } from './task-assignee.pipe';
import { DateTimeFormatPipe, DateTimePipe } from './date-time.pipe';
import { QuickFilterPipe } from './quick-filter.pipe';
import { ColumnOptionsFilterPipe } from './column-options-filter.pipe';
import { QuickCollectionFilterPipe } from './quick-collection-filter.pipe';
import { QuickFilterStringMatchPipe } from './quick-filter-string-match.pipe';
import { TimezonePipe } from './timezone.pipe';
import { DatePipe } from '@angular/common';
import { MessageBodyPreviewPipe } from './message-body-preview.pipe';
import { PluckAndStringifyPipe } from './pluck-and-stringify.pipe';
import { DeliveryContactsInitialsPipe } from './delivery-contact-initials.pipe';
import { DeliveryContactsEmailPipe } from './delivery-contact-email.pipe';
import { DeliveryContactsNamePipe } from './delivery-contact-name.pipe';
import { ApplicantsPipe } from './applicants.pipe';
import { DynamicSortArrayPipe } from './dynamic-sort-array.pipe';
import { SummarizeLineItemsPipe } from '../../projects/details/project-finances/components/invoice/pipes/summarize-line-items.pipe';
import { ToDatePipe } from './to-date.pipe';
import { ContainsPipe } from './contains.pipe';
import { RouteCheckPipe } from './route-check.pipe';
import { CurrencySymbolPipe } from './currency-symbol.pipe';
import { FileFilterPipe } from './file-filter.pipe';
import { BooleanWrapperPipe } from './boolean-wrapper.pipe';
import { DelimitArray } from './delimitArray';
import { TranslationTaskFilterPipe } from './translation-task-filter.pipe';
import { GroupFileTargetLanguagesPipe } from './group-file-target-languages.pipe';
import { FilterOutSelectedTargetLanguagesPipe } from './filter-out-selected-target-languages.pipe';
import { FilterOutSelectedSourceLanguagePipe } from './filter-out-selected-source-language.pipe';
import { FilterOutSelectedSourceLanguagesPipe } from './filter-out-selected-source-languages.pipe';
import { FileSizePipe } from './file-size.pipe';
import { ReferenceNumberDisplayPipe } from './reference-number-display.pipe';
import { ReferenceNumberSortPipe } from './reference-number-sort.pipe';
import { ReadableTaskStagePipe } from './readable-task-stage.pipe';
import { ReadableTaskStatusPipe } from './readable-task-status.pipe';
import { SpaceToDashPipe } from './space-to-dash.pipe';
import { FilterPipe } from './filter.pipe';
import { IsNullOrUndefinedOrBlankPipe } from './is-null-or-undefined-or-blank.pipe';
import { UniqueIdPipe } from './unique-id.pipe';
import { SortByPipe } from './sort-by.pipe';
import { ReadableInvoiceStatusPipe } from './readable-invoice-status.pipe';
import { ReadableReferenceNumberTypePipe } from './readable-reference-number-type.pipe';
import { ExtensionTypePipe } from './extension-type.pipe';
import { ReadableInvoiceLineItemTypePipe } from '../../projects/details/project-finances/components/invoice/pipes/readable-invoice-line-item-type.pipe';
import { NoteCreatorPipe } from './note-creator.pipe';
import { DeliveryCompletePipe } from './delivery-complete.pipe';
import { HasActionRequestPipe } from './has-action-request.pipe';
import { PlusSignPipe } from './plus-sign.pipe';
import { ReadableQuickbookServiceTypePipe } from './readable-quickbook-service-type.pipe';
import { CountEstimatesPipe } from './count-estimates.pipe';
import { CountProjectsPipe } from './count-projects.pipe';
import { CountTasksPipe } from './count-tasks.pipe';
import { ReadableOrderMethodPipe } from './readable-order-method.pipe';
import { ReadableBillFrequencyPipe } from './readable-bill-frequency.pipe';
import { ReadableFeeTypePipe } from './readable-fee-type.pipe';
import { FilterUniqueOptionsPipe } from './filter-unique-options.pipe';
import { ReadableFilerTypePipe } from './readable-filer-type.pipe';
import { ReadableAnnuityPaymentTypePipe } from './readable-annuity-payment-type.pipe';
import { StandardDatePipe } from './standard-date.pipe';
import { StandardDateTimePipe } from './standard-date-time.pipe';
import { ReadableClientAnnuityStatusPipe } from './readable-client-annuity-status.pipe';
import { SumByPipe } from './sum-by.pipe';
import { ReadableNotToPayReasonPipe } from './readable-not-to-pay-reason.pipe';
import { CountAnnuitiesPipe } from './count-annuitites.pipe';
import { ReadableNotificationFrequencyPipe } from './readable-notification-frequency.pipe';
import { ReadableNotificationTimeRangePipe } from './readable-notification-time-range.pipe';
import { MapPipe } from './map.pipe';
import { TruncateTextPipe } from './truncate-text.pipe';
import { CountAnnuityBillsPipe } from './count-annuity-bills.pipe';
import { ActiveOnlyPipe } from './active-only.pipe';
import { CompanyUserToLitePipe } from './company-user-to-lite.pipe';
import { VendorToLitePipe } from './vendor-to-lite.pipe';
import { ServiceManagerSortPipe } from './service-manager-sort.pipe';
import { ReadableBillingStatusPipe } from './readable-billing-status.pipe';
import { CountPatentsPipe } from './count-patents.pipe';
import { ReadableDashboardPreferencePipe } from './readable-dashboard-preference.pipe';
import { ReadableCompanyTypePipe } from './readable-company-type.pipe';
import { ReadableProjectFeeTypePipe } from './readable-project-fee-type.pipe';
import { ReadableAnnuityPatentIssuePipe } from './readable-annuity-patent-issue.pipe';
import { GetLocalTimeByTimezonePipe } from './get-local-time-by-timezone.pipe';
import { ReadableIpRightTypePipe } from './readable-ip-right-type.pipe';
import { DateFromTodayPipe } from './date-from-today.pipe';
import { ReadableFilterScopePreferencePipe } from './readable-viewing-preference-type.pipe';
import { ReadableGrantStatusPipe } from './readable-grant-status.pipe';
import { HtmlToTextPipe } from './html-to-text.pipe';
import { NatsortPipe } from './natsort.pipe';
import { ReadableRemoveFutureAnnuityReasonPipe } from './readable-remove-future-annuity-reason.pipe';
import { ReadableClientAnnuityVerificationStatusPipe } from './readable-client-annuity-verification-status.pipe';
import { SortByStringPipe } from './sort-by-string.pipe';
import { UniqPriorityCountrySelectionPipe } from './uniq-priority-country-selection.pipe';
import { SortByPriorityDetailsPipe } from './sort-by-priority-details.pipe';
import { PatentIssuesSortingPipe } from './patent-issues-sorting.pipe';
import { ReadableQualityControlActionPipe } from './readable-quality-control-action.pipe';
import { ReadableReworkSummaryTypePipe } from './readable-rework-summary-type.pipe';
import { ReadableIprFullServiceTypePipe } from './readable-ipr-full-service-type.pipe';
import { ReadableIprServiceTypePipe } from './readable-ipr-service-type.pipe';
import { ReadableMarkTypePipe } from './readable-mark-type.pipe';
import { ConcatPipe } from './concat.pipe';
import { MapByKeyPipe } from './map-by-key.pipe';
import { ReadableColorTypePipe } from './readable-color-type.pipe';
import { PluckValueByKeyPipe } from './pluck-value-by-key.pipe';
import { CountryIdToFullPipe } from './country-id-to-full.pipe';
import { CanExportTablePipe } from './can-export-table.pipe';
import { ReadableCreditMemoStatusPipe } from './readable-credit-memo-status.pipe';
import { ReadableInvoiceNetTermTypePipe } from './readable-net-type.pipe';
import { ReadableInvoiceFrequencyTypePipe } from './readable-invoice-frequency-type.pipe';
import { ReadableInvoiceBreakdownTypePipe } from './readable-invoice-breakdown-type.pipe';
import { PhoneMaskPipe } from './phone-mask.pipe';

@NgModule({
  imports: [],
  exports: [
    CountryPipe,
    CurrencyWrapperPipe,
    DiscountWrapperPipe,
    KeyMatchPipe,
    LanguagePipe,
    NumberWrapperPipe,
    RelativeTimePipe,
    TaskAssigneePipe,
    DateTimePipe,
    DateTimeFormatPipe,
    QuickFilterPipe,
    ColumnOptionsFilterPipe,
    QuickCollectionFilterPipe,
    QuickFilterStringMatchPipe,
    TimezonePipe,
    MessageBodyPreviewPipe,
    PluckAndStringifyPipe,
    DeliveryContactsInitialsPipe,
    DeliveryContactsEmailPipe,
    DeliveryContactsNamePipe,
    ApplicantsPipe,
    DynamicSortArrayPipe,
    SummarizeLineItemsPipe,
    ToDatePipe,
    ContainsPipe,
    RouteCheckPipe,
    CurrencySymbolPipe,
    FileFilterPipe,
    BooleanWrapperPipe,
    DelimitArray,
    TranslationTaskFilterPipe,
    GroupFileTargetLanguagesPipe,
    FilterOutSelectedTargetLanguagesPipe,
    FilterOutSelectedSourceLanguagePipe,
    FilterOutSelectedSourceLanguagesPipe,
    FileSizePipe,
    ReferenceNumberDisplayPipe,
    ReferenceNumberSortPipe,
    ReadableTaskStagePipe,
    ReadableTaskStatusPipe,
    SpaceToDashPipe,
    FilterPipe,
    IsNullOrUndefinedOrBlankPipe,
    UniqueIdPipe,
    SortByPipe,
    IncludesPipe,
    ReadableInvoiceStatusPipe,
    ReadableReferenceNumberTypePipe,
    ReadableOrderMethodPipe,
    ExtensionTypePipe,
    ReadableInvoiceLineItemTypePipe,
    NoteCreatorPipe,
    DeliveryCompletePipe,
    HasActionRequestPipe,
    PlusSignPipe,
    ReadableQuickbookServiceTypePipe,
    CountEstimatesPipe,
    CountProjectsPipe,
    CountTasksPipe,
    ReadableFeeTypePipe,
    FilterUniqueOptionsPipe,
    ReadableFilerTypePipe,
    ReadableAnnuityPaymentTypePipe,
    StandardDatePipe,
    StandardDateTimePipe,
    ReadableClientAnnuityStatusPipe,
    ReadableBillFrequencyPipe,
    SumByPipe,
    ReadableNotToPayReasonPipe,
    CountAnnuitiesPipe,
    ReadableNotificationFrequencyPipe,
    ReadableNotificationTimeRangePipe,
    MapPipe,
    TruncateTextPipe,
    CountAnnuityBillsPipe,
    ActiveOnlyPipe,
    CompanyUserToLitePipe,
    VendorToLitePipe,
    ServiceManagerSortPipe,
    ReadableBillingStatusPipe,
    CountPatentsPipe,
    ReadableDashboardPreferencePipe,
    ReadableCompanyTypePipe,
    ReadableProjectFeeTypePipe,
    ReadableAnnuityPatentIssuePipe,
    GetLocalTimeByTimezonePipe,
    ReadableIpRightTypePipe,
    DateFromTodayPipe,
    ReadableFilterScopePreferencePipe,
    ReadableGrantStatusPipe,
    HtmlToTextPipe,
    NatsortPipe,
    ReadableRemoveFutureAnnuityReasonPipe,
    ReadableClientAnnuityVerificationStatusPipe,
    SortByStringPipe,
    UniqPriorityCountrySelectionPipe,
    SortByPriorityDetailsPipe,
    PatentIssuesSortingPipe,
    ReadableQualityControlActionPipe,
    ReadableReworkSummaryTypePipe,
    ReadableIprFullServiceTypePipe,
    ReadableIprServiceTypePipe,
    ReadableMarkTypePipe,
    ConcatPipe,
    MapByKeyPipe,
    ReadableColorTypePipe,
    CountryIdToFullPipe,
    CanExportTablePipe,
    ReadableCreditMemoStatusPipe,
    ReadableInvoiceNetTermTypePipe,
    ReadableInvoiceFrequencyTypePipe,
    ReadableInvoiceBreakdownTypePipe,
    PhoneMaskPipe,
  ],
  declarations: [
    CurrencyWrapperPipe,
    LanguagePipe,
    CountryPipe,
    NumberWrapperPipe,
    DiscountWrapperPipe,
    RelativeTimePipe,
    TaskAssigneePipe,
    KeyMatchPipe,
    DateTimePipe,
    DateTimeFormatPipe,
    QuickFilterPipe,
    ColumnOptionsFilterPipe,
    QuickCollectionFilterPipe,
    QuickFilterStringMatchPipe,
    TimezonePipe,
    MessageBodyPreviewPipe,
    PluckAndStringifyPipe,
    DeliveryContactsInitialsPipe,
    DeliveryContactsEmailPipe,
    DeliveryContactsNamePipe,
    ApplicantsPipe,
    DynamicSortArrayPipe,
    SummarizeLineItemsPipe,
    ToDatePipe,
    ContainsPipe,
    RouteCheckPipe,
    CurrencySymbolPipe,
    FileFilterPipe,
    BooleanWrapperPipe,
    DelimitArray,
    TranslationTaskFilterPipe,
    GroupFileTargetLanguagesPipe,
    FilterOutSelectedTargetLanguagesPipe,
    FilterOutSelectedSourceLanguagePipe,
    FilterOutSelectedSourceLanguagesPipe,
    FileSizePipe,
    ReferenceNumberDisplayPipe,
    ReferenceNumberSortPipe,
    ReadableTaskStagePipe,
    ReadableTaskStatusPipe,
    SpaceToDashPipe,
    FilterPipe,
    IsNullOrUndefinedOrBlankPipe,
    UniqueIdPipe,
    SortByPipe,
    IncludesPipe,
    ReadableInvoiceStatusPipe,
    ReadableReferenceNumberTypePipe,
    ReadableOrderMethodPipe,
    ExtensionTypePipe,
    ReadableInvoiceLineItemTypePipe,
    NoteCreatorPipe,
    DeliveryCompletePipe,
    HasActionRequestPipe,
    PlusSignPipe,
    ReadableQuickbookServiceTypePipe,
    CountEstimatesPipe,
    CountProjectsPipe,
    CountTasksPipe,
    ReadableFeeTypePipe,
    FilterUniqueOptionsPipe,
    ReadableFilerTypePipe,
    ReadableAnnuityPaymentTypePipe,
    StandardDatePipe,
    StandardDateTimePipe,
    ReadableClientAnnuityStatusPipe,
    ReadableBillFrequencyPipe,
    SumByPipe,
    ReadableNotToPayReasonPipe,
    CountAnnuitiesPipe,
    ReadableNotificationFrequencyPipe,
    ReadableNotificationTimeRangePipe,
    MapPipe,
    TruncateTextPipe,
    CountAnnuityBillsPipe,
    ActiveOnlyPipe,
    CompanyUserToLitePipe,
    VendorToLitePipe,
    ServiceManagerSortPipe,
    ReadableBillingStatusPipe,
    CountPatentsPipe,
    ReadableDashboardPreferencePipe,
    ReadableCompanyTypePipe,
    ReadableProjectFeeTypePipe,
    ReadableAnnuityPatentIssuePipe,
    GetLocalTimeByTimezonePipe,
    ReadableIpRightTypePipe,
    ReadableIprFullServiceTypePipe,
    ReadableIprServiceTypePipe,
    DateFromTodayPipe,
    ReadableFilterScopePreferencePipe,
    ReadableGrantStatusPipe,
    HtmlToTextPipe,
    NatsortPipe,
    ReadableRemoveFutureAnnuityReasonPipe,
    ReadableClientAnnuityVerificationStatusPipe,
    SortByStringPipe,
    UniqPriorityCountrySelectionPipe,
    SortByPriorityDetailsPipe,
    PatentIssuesSortingPipe,
    ReadableQualityControlActionPipe,
    ReadableReworkSummaryTypePipe,
    ReadableIprServiceTypePipe,
    ReadableMarkTypePipe,
    ConcatPipe,
    MapByKeyPipe,
    ReadableColorTypePipe,
    PluckValueByKeyPipe,
    CountryIdToFullPipe,
    CanExportTablePipe,
    ReadableCreditMemoStatusPipe,
    ReadableInvoiceNetTermTypePipe,
    ReadableInvoiceFrequencyTypePipe,
    ReadableInvoiceBreakdownTypePipe,
    PhoneMaskPipe,
  ],
  providers: [
    DateTimePipe,
    DateTimeFormatPipe,
    TimezonePipe,
    DatePipe
  ],
})
export class PipesModule {
}
