import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertMessage } from '../dto/messaging/alert-message';
import { NavService } from '../../main/navigation/nav.service';
import { AuthService } from '../../security/auth.service';
import { startWith, switchMap, take } from 'rxjs/operators';
import { UserService } from '../services/user.service';
import { UserType } from '../domain/user-type';
import { of } from 'rxjs';
import { isNullOrUndefined } from '../utils/is-null-or-undefined';

/*
NOTES:

This component handles 'hard' redirections in case you want to navigate into the same route/component
and want it to re-initialize.

Usually, when navigating into the same route with Angular, it will just skip the navigation and do nothing.
Sadly, { onSameUrlNavigation: 'reload' } would not re-init or (run ngOnInit) on the component.

This component is the LAST resort if you really need it.
 */

@Component({
  selector: 'townip-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.scss']
})
export class RedirectComponent implements OnInit {

  constructor(private router: Router,
              private activatedRoute: ActivatedRoute,
              private navService: NavService,
              private authService: AuthService,
              private userService: UserService,
  ) { }

  public ngOnInit(): void {
    this.activatedRoute.queryParams
      // .map(param => param.route)
      .subscribe((params) => {
        // ANNUITY REDIRECTS top level check
        if (params.scope && params.scope === 'ANNUITY') {
          this.handleAnnuityRedirect();
          return;
        }

        if (params.scope && params.scope === 'ANNUITY_PATENT') {
          this.handleAnnuityPatentRedirect();
          return;
        }

        // Params.query is a json string. We need to parse it.
        let query = null;
        if (params.query) {
          query = JSON.parse(params.query);
        }

        if (!params.scope) {
          this.handleRedirect(params.route, query);
          return;
        }

        const alertMessage: AlertMessage = {
          action: '',
          alertRoute: '',
          creationTime: 0,
          creator: undefined,
          fireTime: 0,
          messageBody: '',
          messageRecipients: [],
          messageSubject: '',
          service: '',
          serviceSubtype: '',
          status: '',
          taskDefinition: '',
          scope: params.scope,
          scopeId: params.scopeId,
          parentScopeId: params.parentScopeId,
          projectCategory: params.projectCategory,
          scopeRouteType: params.scopeRouteType
        };

        this.navService.navigateAlert(alertMessage);
      });
  }

  /**
   * Handles the redirection for the specified route
   *
   * @param {string} route
   * @param {any} query JSON object
   *
   */
  public handleRedirect(route: string, query?: any): void {
    if (isNullOrUndefined(route)) {
      return;
    }
    this.router.navigate([route], { queryParams: query });
  }

  private handleAnnuityRedirect(): void {
    const annuityId = +this.activatedRoute
      .snapshot
      .queryParamMap
      .get('scopeId');

    // This redirect does not have any session guard.
    // We then have to wait for the signal that the sessions has initialised
    // before we process the redirect.
    // If the user NOT logged in - the browser will automatically redirect to login.
    this.authService
      .connectedEmitter
      .pipe(
        startWith(false),
        switchMap(() => {
          if (this.authService.isConnected()) {
            return of(true);
          }

          return this.authService.connectedEmitter;
        }),
        take(1),
      )
      .subscribe((connected) => {
        if (!connected) {
          return;
        }

        if (!annuityId) {
          this.router.navigate(['/annuities']);
          return;
        }

        const user = this.userService.getUser();

        if (user.userType === UserType.Internal && this.userService.isSalesOrAccountManager()) {
          this.router.navigate(['annuities', 'sm-am', 'annuity-details', annuityId]);
          return;
        }

        if (user.userType === UserType.Internal) {
          this.router.navigate(['annuities', 'internal', 'project-manager', 'annuity-details', annuityId]);
          return;
        }

        if (user.userType === UserType.Company) {
          this.router.navigate(['annuities', 'company', 'annuity-details', annuityId]);
          return;
        }

        if (user.userType === UserType.Vendor) {
          this.router.navigate(['annuities', 'vendor', 'annuity-details', annuityId]);
          return;
        }
      });
  }

  private handleAnnuityPatentRedirect(): void {
    const annuityPatentId = +this.activatedRoute
      .snapshot
      .queryParamMap
      .get('scopeId');

    const patentCompanyId = +this.activatedRoute
      .snapshot
      .queryParamMap
      .get('parentScopeId');

    // We then have to wait for the signal that the sessions has initialised
    // before we process the redirect.
    // If the user NOT logged in - the browser will automatically redirect to login.
    this.authService
      .connectedEmitter
      .pipe(
        startWith(false),
        switchMap(() => {
          if (this.authService.isConnected()) {
            return of(true);
          }

          return this.authService.connectedEmitter;
        }),
        take(1),
      )
      .subscribe((connected) => {
        if (!connected) {
          return;
        }

        if (!annuityPatentId || !patentCompanyId) {
          this.router.navigate(['/patent-portfolio']);
          return;
        }

        this.router.navigate(['patent-portfolio', 'patent-details', annuityPatentId],
          { queryParams: { companyId: patentCompanyId } });

      });
  }

}
