import { ReferenceNumber } from '../../estimate/create-estimate-v2/shared/reference-number';
import { BaseCurrencyIsoCode } from '../enums/base-currency-iso-code';

export interface InvoicePaginated {
  id?: number; // Primary key of the annuity invoice
  invoiceNumber?: string; // Unique id for the annuity invoice
  referenceNumbers?: ReferenceNumber[]; // Array of reference number data
  invoiceDate?: string; // Date that the invoice should be available to Client
  paymentDate?: Date; // Date that the invoice is paid
  totalCost?: number; // Invoice total cost
  foreignTotalCost?: number; // Invoice foreign total cost
  defaultBaseCurrency?: BaseCurrencyIsoCode; // Invoice foreign currency ISO code
  companyId?: number; // The Company Id
  companyUserId?: number; // The Company User Id
  endClientId?: number; // The End Client Id
  endClient?: string; // The End Client Name
  salesManagerId?: number; // The Sales Manager Id
  accountManagerId?: number; // The Account Manager Id
  status?: string;
  dueDate?: string;
  country?: string;
  countryId?: number;
  invoiceType?: string;
  projectId?: string;
  projectNumber?: string;
  amount?: number;

  // NOTE: These items should always be the same as the ones below in InvoicePaginatedFields
}

export enum InvoicePaginatedFields {
  id = 'id',
  invoiceNumber = 'invoiceNumber',
  status = 'status',
  referenceNumbers = 'referenceNumbers',
  invoiceDate = 'invoiceDate',
  paymentDate = 'paymentDate',
  dueDate = 'dueDate',
  totalCost = 'totalCost',
  foreignTotalCost = 'foreignTotalCost',
  defaultBaseCurrency = 'defaultBaseCurrency',
  companyId = 'companyId',
  companyUserId = 'companyUserId',
  endClientId = 'endClientId',
  endClient = 'endClient',
  salesManagerId = 'salesManagerId',
  accountManagerId = 'accountManagerId',
  country = 'country',
  countryId = 'countryId',
  invoiceType = 'invoiceType',
  projectId = 'projectId',
  projectNumber = 'projectNumber',
  amount = 'amount',

  // NOTE: These items should always be the same as the ones above in InvoicePaginated
}


