import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class LoadingService {

  public loadingEmitter = new BehaviorSubject<string>(null);

  constructor() { }


  public setLoading(loadingText: string = 'Loading'): void {
    this.loadingEmitter.next(loadingText);
  }

  public endLoading(): void {
    this.loadingEmitter.next(null);
  }
}
