import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StandardAlertComponent } from './standard-alert.component';

@NgModule({
  declarations: [StandardAlertComponent],
  exports: [StandardAlertComponent],
  imports: [
    CommonModule,
  ]
})
export class StandardAlertModule { }
