import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as _ from 'underscore';
import { FileKeyPair } from '../../../dto/file-key';
import { DocumentService } from '../../../../data-services/document.service';
import { UserService } from '../../../services/user.service';
import { PortalUser } from '../../../domain/user';

@Component({
  selector: 'townip-message-attachment',
  templateUrl: './message-attachment.component.html',
  styleUrls: ['./message-attachment.component.scss']
})
export class MessageAttachmentComponent implements OnInit {

  @Input()
  public file: FileKeyPair;

  @Input()
  public isOwn: boolean;

  @Input()
  public readOnly: boolean;

  @Input()
  public isGeneralMessage = true;

  @Output()
  public removeFile: EventEmitter<FileKeyPair> = new EventEmitter<FileKeyPair>();

  @Output()
  // NOTE: Only suppressing because legacy.
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  public onAssociate: EventEmitter<any> = new EventEmitter();

  public attachmentIcon: string;

  public currentUser: PortalUser;

  constructor(private documentService: DocumentService, private userService: UserService) {

  }

  public ngOnInit(): void {
    this.currentUser = this.userService.getUser();

    this.attachmentIcon = getAttachmentIcon(this.file.filename);
  }

  public downloadAttachment(): void {
    this.documentService.getFile(this.file.filekey);
  }

  public removeAttachment(): void {
    this.removeFile.emit(this.file);
  }

  get isPM(): boolean {
    const isSalesOrAccountManager = this.userService.authorizations.ROLE_INTERNAL_SALES_MGR ||
      this.userService.authorizations.ROLE_INTERNAL_ACCOUNT_MGR;

    return this.currentUser && this.currentUser.userType === 'INTERNAL' && !isSalesOrAccountManager;
  }
}

export function getAttachmentIcon(filename: string): string {
  try {
    const split = filename.split('.');
    const extension = split[split.length - 1].toLowerCase();

    if (_.contains(['xls', 'xlsx', 'csv'], extension)) {
      return 'file-excel-o';
    } else if (_.contains(['doc', 'docx'], extension)) {
      return 'file-word-o';
    } else if (_.contains(['txt', 'json'], extension)) {
      return 'file-text-o';
    } else if (_.contains(['pdf'], extension)) {
      return 'file-pdf-o';
    } else if (_.contains(['png', 'jpg', 'jpeg', 'bmp', 'gif'], extension)) {
      return 'file-image-o';
    } else if (_.contains(['zip', 'rar'], extension)) {
      return 'file-archive-o';
    } else {
      return 'file-o';
    }
  } catch (e) {
    return 'file-o';
  }

}
