import { Injectable } from '@angular/core';
import { ConfigService } from '../../config.service';
import { ConfigDataStoreService } from './config-data-store.service';
import { UserService } from '../../../shared/services/user.service';
import { SystemPricing } from '../../../shared/dto/system-pricing';
import { SystemConfigDTO } from '../../../shared/dto/system-config/system-config-dto';
import { LinguisticPricing } from '../../../shared/dto/linguistic-pricing';
import { LanguagePair } from '../../../shared/dto/system-config/language-pair';
import { SystemConfigResponseDTO } from '../../../shared/dto/system-config/system-config-response-dto';

@Injectable()
export class SystemPricingDataStoreService extends ConfigDataStoreService<SystemPricing> {
  private linguisticPricingMap: Map<number, LinguisticPricing[]> = new Map<number, LinguisticPricing[]>();
  public pricingType: string;
  public pricingId: number;
  public linguisticPriceSheetId: number;

  constructor(configService: ConfigService, userService: UserService) {
    super(configService, userService, 'SYSTEM_PRICING');
  }

  public clear(): void {
    this.linguisticPricingMap = null;
    this.pricingType = null;
    this.pricingId = null;
    this.linguisticPriceSheetId = null;
  }

  public create(data: SystemPricing): Promise<SystemConfigResponseDTO> {
    const configObject: SystemConfigDTO = { userId: this.user.id, type: this.type, systemPricingDTO: data };
    const response = this.configService.addSystemConfig(configObject);
    response.then(res => {
      this.dataSetPromise.then(dataSet => {
        dataSet.push(res.configValueDTO as SystemPricing);
        this.dataSet.next(dataSet);
        this.dataSetPromise = Promise.resolve(dataSet);
      });
    });
    return response;
  }

  public update(data: SystemPricing): Promise<SystemConfigResponseDTO> {
    const configObject: SystemConfigDTO = { userId: this.user.id, type: this.type, systemPricingDTO: data };
    const response = this.configService.updateSystemConfig(configObject);
    response.then(res => {
      this.dataSetPromise.then(dataSet => {
        const index = dataSet.findIndex(dataPoint => dataPoint.id === res.configValueDTO.id);
        dataSet[index] = res.configValueDTO as SystemPricing;
        this.dataSet.next(dataSet);
        this.dataSetPromise = Promise.resolve(dataSet);
      });
    });
    return response;
  }

  public addLinguisticPricing(languagePair: LanguagePair, data: LinguisticPricing[]): Promise<SystemConfigResponseDTO> {
    const configObject: SystemConfigDTO = {
      userId: this.user.id,
      type: 'LINGUISTIC_PRICING',
      linguisticPricingDTOs: data
    };
    const response = this.configService.addSystemConfig(configObject);
    response.then(res => {
      const pricingList: LinguisticPricing[] = this.linguisticPricingMap.get(languagePair.id);
      if (pricingList && res.configValueDTOs) {
        res.configValueDTOs.forEach(configValueDTO => {
          const index = pricingList.findIndex(p => configValueDTO.id === p.id);
          pricingList[index] = res.configValueDTO as LinguisticPricing;
        });
      }
    });
    return response;
  }

  public updateLinguisticPricing(languagePair: LanguagePair,
    data: LinguisticPricing[]): Promise<SystemConfigResponseDTO> {
    const configObject: SystemConfigDTO = {
      userId: this.user.id,
      type: 'LINGUISTIC_PRICING',
      linguisticPricingDTOs: data
    };
    const response = this.configService.updateSystemConfig(configObject);
    response.then(res => {
      const pricingList: LinguisticPricing[] = this.linguisticPricingMap.get(languagePair.id);
      if (pricingList && res.configValueDTOs) {
        res.configValueDTOs.forEach(configValueDTO => {
          const index = pricingList.findIndex(p => configValueDTO.id === p.id);
          if (index >= 0) {
            pricingList[index] = configValueDTO as LinguisticPricing;
          } else {
            pricingList.push(configValueDTO as LinguisticPricing);
          }
        });
      }
    });
    return response;
  }

  public deleteLinguisticPricing(linguisticPricingId: number,
    languagePairId: number): Promise<SystemConfigResponseDTO> {
    const configObject: SystemConfigDTO = {
      userId: this.user.id,
      type: 'LINGUISTIC_PRICING',
      valueDTO: { id: linguisticPricingId }
    };
    const response = this.configService.disableSystemConfig(configObject);
    response.then(res => {
      if (!res.error) {
        const pricingList: LinguisticPricing[] = this.linguisticPricingMap.get(languagePairId);
        const index = pricingList.findIndex(p => linguisticPricingId === p.id);
        if (index >= 0) {
          pricingList.splice(index, 1);
        } else {
          console.error('Error removing the pricing');
        }
      }
    });
    return response;
  }

  public setLinguisticPricingMap(cpMap: Map<number, LinguisticPricing[]>): void {
    this.linguisticPricingMap = cpMap;
  }

  public getLanguagePairPricingMap(languagePairId: number): LinguisticPricing[] {
    return this.linguisticPricingMap.get(languagePairId);
  }
}
