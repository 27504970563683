import * as _ from 'underscore';
import { Pipe, PipeTransform } from '@angular/core';
import { FilterGroup } from '../filter';

@Pipe({
  name: 'filterCategory'
})
export class FilterCategoryPipe implements PipeTransform {

  public transform(value: FilterGroup[], category?: string): FilterGroup[] {
    return _.where(value, { category: category });
  }

}
