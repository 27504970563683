import { Injectable } from '@angular/core';
import { omitCategoriesSubType } from '../shared/components/file-upload/file-upload-category';
import { ConfigService } from './config.service';
import { FileCategoryType } from '../shared/dto/system-config/file-category-type';
import { ProjectCategoryFileType } from '../shared/dto/system-config/project-category-file-type';
import { ServiceComponent } from '../shared/dto/system-config/service-component';
import { RequestTitle, WorkflowActionRequest } from '../shared/dto/projects/workflow-action-request';
import { WorkflowActions } from '../shared/dto/projects/workflow-actions';
import { Task } from '../shared/dto/projects/task';
import { isNullOrUndefined } from '../shared/utils/is-null-or-undefined';

@Injectable()
export class TasksActionService {

  constructor(private configService: ConfigService) {

  }

  public getFileCategoriesByFileRequestTitle(fileTask: Task,
    fileRequestTitle: string,
    notInProgress?: boolean): FileCategoryType[] {
    if (fileRequestTitle === 'sourceErrorFile') {
      // only need to get the SOURCE ERROR COMMENT category for this title sourceErrorFile
      const sourceError =
        this.configService.fileCategories.find(fileCategory => fileCategory.fileCategory === 'SOURCE_ERROR');
      if (sourceError) {
        return [sourceError];
      }
    } else {
      return this.getAvailableFileCategories(fileTask, notInProgress,
        fileRequestTitle !== RequestTitle.UploadAndAssociateFile);
    }
    return [];
  }

  public getAvailableFileCategories(fileTask: Task,
    notInProgress?: boolean,
    filter: boolean = true): FileCategoryType[] {
    let fileCategories: FileCategoryType[];
    let projectCategoryFileCategories: ProjectCategoryFileType[];

    const assignee = fileTask.assignee;

    let configServiceComponent: ServiceComponent = null;
    let parentServiceId: number = null;
    if (assignee.serviceComponent) {
      if (assignee.serviceComponent.parentServiceId) {
        parentServiceId = assignee.serviceComponent.parentServiceId;
      } else {
        configServiceComponent = this.configService.serviceComponents.find(s => s.id === assignee.serviceComponent.id);
      }
    }

    if (configServiceComponent && configServiceComponent.availableFileCategories) {
      projectCategoryFileCategories = configServiceComponent.availableFileCategories;
    } else if (!isNullOrUndefined(parentServiceId)) {
      projectCategoryFileCategories = this.getFileCategoriesByServiceId(parentServiceId);
    } else if (assignee.service) {
      projectCategoryFileCategories = this.getFileCategoriesByServiceId(assignee.service.id);
    } else {
      projectCategoryFileCategories = null;
    }

    if (!isNullOrUndefined(projectCategoryFileCategories)) {
      fileCategories =
        this.filterFileCategoriesByProjectCategory(projectCategoryFileCategories,
          fileTask.projectOverview.projectCategory.id);
      // Remove the subType of each categories
      fileCategories = omitCategoriesSubType(fileCategories);

      // If not a rework task, remove the rework category
      if (filter && fileCategories && !assignee.reworkTask) {
        fileCategories = fileCategories.filter(fc => fc.text !== 'Rework');
      }
    }

    if (filter && (assignee.service.serviceType === 'LINGUISTIC' || assignee.service.serviceType === 'GENERAL')) {
      // If is an in progress task remove all categories except in progress, if not only remove in progress
      if (!notInProgress && fileCategories &&
        (assignee.taskStage.taskStage === 'IN_PROGRESS' || assignee.taskStage.taskStage === 'READY_TO_START')) {
        fileCategories = fileCategories.filter(fc => fc.text === 'In Progress');
      } else {
        fileCategories = fileCategories.filter(fc => fc.text !== 'In Progress');
      }
    }

    return fileCategories;
  }

  public getFileCategoriesByServiceComponentId(fileTask: Task): FileCategoryType[] {

    let fileCategories: FileCategoryType[];

    const serviceComponentConfig = this.configService.serviceComponents
      .find(serviceComponent => serviceComponent.id === fileTask.assignee.serviceComponent.id);

    if (serviceComponentConfig) {
      fileCategories =
        this.filterFileCategoriesByProjectCategory(serviceComponentConfig.availableFileCategories,
          fileTask.projectOverview.projectCategory.id);
    }

    return fileCategories;
  }

  private getFileCategoriesByServiceId(serviceId: number): ProjectCategoryFileType[] {
    let fileCategories: ProjectCategoryFileType[] = null;

    const serviceConfig = this.configService.services.find(s => s.id === serviceId);
    if (serviceConfig) {
      fileCategories = serviceConfig.availableFileCategories;
    } else {
      fileCategories = null;
    }

    return fileCategories;
  }

  private filterFileCategoriesByProjectCategory(allFileCategories: ProjectCategoryFileType[],
    projectCategoryId: number): FileCategoryType[] {
    return allFileCategories.filter(afc => afc.projectCategoryConfig.id === projectCategoryId)
      .map(ffc => ffc.fileCategoryConfig);
  }

  public invalidWorkFlowResponses(action: WorkflowActions, task: Task): boolean {
    if (action === undefined || action === null) {
      return true;
    }

    let invalid: boolean;

    for (const request of action.workflowActionRequests) {
      invalid = this.isInvalidAction(request, task);
      if (invalid) {
        break;
      }
    }

    return invalid;
  }

  private isInvalidAction(action: WorkflowActionRequest, task: Task): boolean {
    let invalid = false;
    if (action.required && isNullOrUndefined(action.requestResponse) && !this.shouldHideRequest(action)) {
      if (isNullOrUndefined(action.dependentRequest)) {
        invalid = true;
      } else if (isNullOrUndefined(action.dependentRequest.requestResponse)) {
        invalid = this.isInvalidAction(action.dependentRequest, task);
      } else if (action.dependentRequest.requestResponse.toString() === action.dependentValue) {
        invalid = true;
      }
    }

    if (action.title === 'updateVendorWord') {
      if (action.requestResponse > task.assignee.vendorWordCount) {
        invalid = true;
      }
    }

    if (action.required && action.type === 'acknowledgement' &&
        (isNullOrUndefined(action.requestResponse) || action.requestResponse === false)) {
      invalid = true;
    }

    return invalid;
  }

  public shouldHideRequest(request: WorkflowActionRequest): boolean {
    if (isNullOrUndefined(request.dependentRequest)) {
      return false;
    } else if (isNullOrUndefined(request.dependentValue)) {
      return true;
    } else if (isNullOrUndefined(request.dependentRequest.requestResponse)) {
      return true;
    } else if (request.dependentRequest.requestResponse.toString() !== request.dependentValue) {
      return true;
    } else { // they are equal, make sure its parent is good too.
      return this.shouldHideRequest(request.dependentRequest);
    }
  }
}
