import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { WebsocketService } from '../shared/services/websocket.service';
import { LoggingService } from '../shared/logging.service';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, delay, switchMap } from 'rxjs/operators';
import { isNullOrUndefined } from '../shared/utils/is-null-or-undefined';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService,
              private websocketService: WebsocketService,
              private loggingService: LoggingService,
              private router: Router) {
  }

  public addAuthHeader(request: HttpRequest<any>): HttpRequest<any> {
    const authHeader = this.authService.getAuthorizationHeader();
    if (!isNullOrUndefined(authHeader)) {
      const updatedReq = request.clone({
        headers: request.headers.set('Authorization', this.authService.getAuthorizationHeader())
      }) as HttpRequest<any>;

      // For debugging -
      // console.log(updatedReq);
      return updatedReq;
    }

    // No headers
    return request.clone();
  }

  public logout(): void {
    this.router.navigate(['auth/logout']);
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    // Handle request
    request = this.addAuthHeader(request);

    // Handle response
    return next
      .handle(request)
      .pipe(
        catchError(error => {
          // If we are not logged in, do not attempt to refresh tokens.
          if (!this.authService.isConnected()) {
            return observableThrowError(error);
          }

          // Other statuses
          if (error.status !== 401) {
            return observableThrowError(error);
          }

          // 401 error means the token is expired.
          return this.authService.refreshToken()
            .pipe(
              catchError((refreshErr) => {
                this.logout();
                return observableThrowError(refreshErr);
              }),
              switchMap(() => {
                const retryRequest = this.addAuthHeader(request);
                // Delay is necessary so we don't request new token too many times on simult requests
                return next.handle(retryRequest)
                  .pipe(delay(1000));
              })
            );
        }), // -- End catch handler
      ); // -- End Pipe
  }
}
