import * as _ from 'underscore';
import { Component, DoCheck, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { PortalUser } from '../../../domain/user';
import { Message } from '../../../dto/messaging/message';
import { MessagingService } from '../../../services/messaging.service';
import { UserService } from '../../../services/user.service';
import { MessageRecipient } from '../../../dto/messaging/message-recipient';
import { SelectItem } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { ThreadContainerDirective } from '../messaging.directives';
import { Subscription } from 'rxjs';
import { TaskNavigationService } from '../../../../tasks/services/task-navigation.service';
import { ConfirmModalService } from '../../../services/confirm-modal.service';
import { NavService } from '../../../../main/navigation/nav.service';
import { removeImageTag } from '../../../prime-editor.util';
import { FileKeyPair } from '../../../dto/file-key';
import { DocumentService } from '../../../../data-services/document.service';
import { MessageFileUploadComponent } from '../message-file-upload/message-file-upload.component';
import { switchMap } from 'rxjs/operators';
import { UserChipsComponent } from '../user-chips/user-chips.component';
import { ScopeNavigation } from '../utils/scope-navigation';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { sortBy } from '../../../utils/sort-by';
import { isNullOrUndefined } from '../../../utils/is-null-or-undefined';

export interface MessageInfo extends Message {
  isUserUnreadMessage?: boolean;
  showEnvelopeIcon?: boolean;
  lastMessage?: Message;
}

@Component({
  selector: 'townip-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['messages.component.scss'],
})
export class MessagesComponent implements OnInit, OnDestroy, DoCheck {

  @ViewChild(MessageFileUploadComponent)
  public msgFileUpload: MessageFileUploadComponent;

  @ViewChild(UserChipsComponent)
  public simpleSearchComponent: UserChipsComponent;

  @ViewChild('popoverFindRecipient')
  public popoverFindRecipient: PopoverDirective;

  @Input()
  public deeplink: boolean;

  @Input()
  public scope: string; // Which scope to filter

  @Input()
  public scopeId: number; // Which scope id to filter from the scope

  // The parent scope id when one exists. An example would be the project ID when working with a task.
  @Input()
  public parentScopeId: number;

  @Input()
  public scopeName: string; // The scope name that will be appended to the message subject

  @Input()
  public projectCategory: string; // The project category (patent prosecution, legal translation, etc.)

  @Input()
  public newMessageSubject: string;

  // NOTE: Enabling sendCommunication will hide the messages list and make the
  // messages emit the authored message instead of handling it here.
  // Message data must be handled outside this component via output
  // TODO: Possible Optimization for maintainability
  // We probably need to merge this function with hideMessageList and newMessage and
  // make the functionality generic instead of domain-specific
  @Input()
  public sendCommunication = false;

  // NOTE: Enabling hideMessageList will only hide the messages list
  // Message data will be sent using the internal function we have here
  // If you want to show only the form, set newMessage = true and hideMessageList = true
  // You can also show to form by calling this.showNewMessage()
  @Input()
  public hideMessageList = false;

  // Sets a pre-selected user from the message recipient list (from backend)
  @Input()
  public selectedUserId = 0;

  // Sets pre-selected users from the given recipients array. This will always override selectedUserId
  @Input()
  public selectedUsers: MessageRecipient[];

  @Input()
  public newMessage = false;

  @Input()
  public disableContacts = false;

  @Input()
  public preSelectedRecipients: number[];

  @Input()
  public countrySelectItems: SelectItem[];

  // If the component will be used in modal
  @Input()
  public popup = false;

  /**
   * If set to TRUE, this components will match the height of the parent container
   * @type {boolean}
   */
  @Input()
  public containerMatching = false;

  @Input()
  public findRecipientHeight = 300; // This will be used on message-find-recipient.component

  @Input()
  public showProjectScope = true;

  @Output()
  public messageSent: EventEmitter<Message> = new EventEmitter<Message>();

  @Output()
  public messageCancelled: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  // NOTE: Only suppressing because legacy.
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  public onFileAssociated = new EventEmitter<void>();

  private user: PortalUser;

  public messages: MessageInfo[];

  public showNewMessageInd: boolean;

  public selectedMessage: Message;

  public newMessageText: string;

  public messageCount = 0;

  public contacts: SelectItem[];

  public selectedContacts: SelectItem[] = [];

  public queryMessageId: number;

  public threadType: string;

  public showReplyAll = false;

  public jElement: JQuery; // The jquery version of our component

  @ViewChild(ThreadContainerDirective)
  private threadContainer: ThreadContainerDirective;

  private isLoadingContacts = false;

  private messagesSubscription: Subscription;

  public hasRecipients: boolean;

  public removeImageTag = removeImageTag;

  public uploadedFiles: FileKeyPair[];

  public selectedMessageRecipients: SelectItem[];

  public replySubject: string;

  public newMessageAttachments: FileKeyPair[];

  // This is a list of filekeys that should be removed from the backend.
  // This is used so that we don't have files on the backend that are created but
  // are not referenced to anything.
  public uploadedFilesToDelete: string[];

  public initialExternalRecipients: string[];

  public excludedRecipients: string[];

  private navigateInProgress = false;

  private midSubscription: Subscription;

  constructor(private messagingService: MessagingService,
              private modalService: ConfirmModalService,
              private taskNavService: TaskNavigationService,
              private userService: UserService,
              private route: ActivatedRoute,
              private router: Router,
              private element: ElementRef,
              private navService: NavService,
              private growlService: MessageService,
              private documentService: DocumentService) {
    this.contacts = [];
    this.deeplink = true;
    this.newMessageSubject = '';
    this.uploadedFiles = [];
    this.uploadedFilesToDelete = [];
  }

  public ngOnInit(): void {
    this.jElement = jQuery(this.element.nativeElement);
    this.user = this.userService.getUser();

    if (this.user) {
      if (isNullOrUndefined(this.selectedUsers)) {
        this.isLoadingContacts = true;
        this.messagingService.retrieveMessageRecipients(this.scope, this.scopeId)
          .toPromise()
          .then(recipients => {
            if (recipients) {
              this.contacts = [];
              recipients.forEach(recipient => this.contacts.push({
                label: recipient.firstName + ' ' + recipient.lastName,
                value: recipient
              }));

              if (this.selectedUserId > 0) {
                const contacts = this.contacts.find(c => c.value.id === this.selectedUserId);
                if (contacts) {
                  this.selectedContacts.push(contacts);
                }
              }

              if (!isNullOrUndefined(this.preSelectedRecipients) && this.preSelectedRecipients.length > 0) {
                this.preSelectedRecipients.forEach(recipientId => {
                  const contact = this.contacts.find(c => c.value.id === recipientId);
                  if (!isNullOrUndefined(contact)) {
                    this.selectedContacts.push(contact);
                  }
                });
              }
            }

            this.isLoadingContacts = false;
            this.enableRecipients();
          });
      } else {
        // IF we have overridden the contacts
        this.selectedContacts = _.map(this.selectedUsers, (user) => {
          return {
            label: user.user.firstName + ' ' + user.user.lastName,
            value: user.user,
          };
        });
      }

      this.enableRecipients();

      if (this.newMessage) {
        this.showNewMessage();
      }

      if (this.scope === 'PROJECT') {
        this.messagesSubscription = this.messagingService.projectMessages.subscribe(projectMessages => {
          this.messages = projectMessages;
          this.mapMessages();
          this.messages.sort((a, b) => {
            return b.lastMessageReceived as any - a.lastMessageReceived as any;
          });

          this.preSelectMessage();
        });
        this.threadType = 'project';
      } else if (this.scope === 'TASK') {
        this.threadType = 'task';
      } else {
        this.threadType = 'user';
        this.messagesSubscription = this.messagingService.messages.subscribe(userMessages => {
          this.messages = this.filterByScope(userMessages);
          this.mapMessages();
          this.messages.sort((a, b) => {
            return b.lastMessageReceived as any - a.lastMessageReceived as any;
          });
          this.preSelectMessage();
        });
      }

      this.selectMessage(this.queryMessageId);
    }

    this.preSelectMessage();
  }

  public showFindRecipientPanel(): void {
    if (this.simpleSearchComponent && this.simpleSearchComponent.searchOverlay) {
      // Hide the panel of simple search
      this.simpleSearchComponent.tempSelection = '';
      this.simpleSearchComponent.searchOverlay.hide();
    }
  }

  public onReplyAll(): void {
    this.selectedMessageRecipients = [];
    this.newMessageAttachments = [];
    this.replySubject = this.selectedMessage.messageSubject;

    this.selectedMessage.messageRecipients.forEach(recipient => {
      const userInfo = this.contacts.find(contact => contact.value.id === recipient.user.id);

      if (userInfo) {
        this.selectedMessageRecipients.push(userInfo);
      }
    });

    this.showReplyAll = true;

    this.scrollToTop();
  }

  public removeAttachment(file: FileKeyPair): void {
    if (!this.newMessageAttachments) {
      return;
    }

    this.newMessageAttachments = this.newMessageAttachments.filter(attachment => attachment.id !== file.id);
  }

  public onAddAttachments(files: FileKeyPair[]): void {
    if (!this.newMessageAttachments) {
      this.newMessageAttachments = [];
    }

    if (!files) {
      return;
    }

    this.newMessageAttachments.push(...files);
  }

  // map last message received and last message bodyContent
  private mapMessages(): void {
    _.map(this.messages, (message) => {
      message.childMessages = sortBy(message.childMessages, 'creationTime')
        .reverse();

      let lastMessage: Message;

      if (!message.childMessages || (message.childMessages && message.childMessages.length === 0)) {
        lastMessage = message;
      } else {
        lastMessage = message.childMessages[0];
      }

      // Used for sorting
      message.lastMessageReceived = lastMessage.creationTime;

      // Used for displaying the latest text.
      message.lastMessage = lastMessage;

      return message;
    });
  }

  private enableRecipients(): void {
    this.hasRecipients =
      (this.contacts && this.contacts.length > 0) ||
      (this.selectedContacts && this.selectedContacts.length > 0 && this.disableContacts);
  }

  private cleanupSubscriptions(): void {
    if (this.messagesSubscription) {
      this.messagesSubscription.unsubscribe();
    }
  }

  public ngDoCheck(): void {
    if (this.containerMatching) {
      this.matchContainerHeight();
    }
  }

  private matchContainerHeight(): void {
    const containerHeight = this.jElement.parent()
      .height();
    this.jElement.css('height', containerHeight + 'px');
  }

  private filterByScope(messages: Message[]): Message[] {
    if (this.scope && this.scopeId) {
      const response = _.where(messages, { scope: this.scope, scopeId: this.scopeId });
      return response;
    }

    if (this.scope && isNullOrUndefined(this.scopeId)) {
      return _.where(messages, { scope: this.scope });
    }

    if (isNullOrUndefined(this.scope) && this.scopeId) {
      return _.where(messages, { scopeId: this.scopeId });
    }

    // If none satisfies
    return messages;
  }

  public ngOnDestroy(): void {
    this.cleanupSubscriptions();
  }

  public async selectMessage(id: number): Promise<void> {
    const message = _.findWhere(this.messages, { id: id });
    if (message === undefined) {
      return;
    }

    this.markAsRead(message);
    this.selectedMessage = message;

    const userEmails = [];
    this.excludedRecipients = [];

    this.initialExternalRecipients = await this.messagingService
      .getExternalRecipientsForMessage(this.selectedMessage.baseMessageId);

    message.messageRecipients.forEach(mr => {
      if (mr.user.id === this.user.id) {
        return;
      }
      // build a list of all e-mails associated with message recipients
      userEmails.push(mr.user.email);
      if (mr.user.supplementalEmails && mr.user.supplementalEmails.length > 0) {
        userEmails.concat(mr.user.supplementalEmails);
      }
    });

    if (this.initialExternalRecipients) {
      // compare list of external e-mails who received the first message of this thread against the list of
      // e-mails associated with message recipients.  Any external e-mail not found in the list
      // of user e-mails will not receive a reply message.
      // The following list will be used for the "Reply-All" warning message
      this.excludedRecipients = this.initialExternalRecipients.filter(item => userEmails.indexOf(item) < 0);
    }

    this.showReplyAll = false;
  }

  private markAsRead(message: MessageInfo): void {
    let isRecipient = false;
    const isRead = _.find(message.messageRecipients, (recipient: MessageRecipient) => {
      if (recipient.user.id === this.user.id) {
        isRecipient = true;
        if (recipient.read) {
          return true;
        }
      }
    });

    // If message is already read, don't send any request at all
    if (isRead || !isRecipient) {
      return;
    }

    this.messagingService.markMessageRead(this.user.id, message.baseMessageId, 'GENERAL_MESSAGE');
  }

  public async navigateToMessage(message: Message): Promise<void> {
    let discard = true;
    if (this.newMessageText || this.uploadedFiles.length > 0) {
      discard = await this.modalService.confirmDiscard();
    }

    if (discard) {
      this.resetMsgFileUpload();
      this.cancelNewMessage();

      if (this.deeplink) {
        this.router.navigate(['./', { mid: message.id }], { relativeTo: this.route });
      } else {
        this.selectMessage(message.id);
      }
    }
  }

  public navigate(message: Message): void {
    if (!ScopeNavigation.canNavigateToScope(message) || this.navigateInProgress) {
      return;
    }

    this.navigateInProgress = true;

    const scopeNavigation = new ScopeNavigation(this.taskNavService, this.userService, this.navService, this.router);
    scopeNavigation.navigateToScope(message);
  }

  public clearMessage(): void {
    this.selectedMessage = null;
  }

  public showNewMessage(): void {
    this.showNewMessageInd = true;
    this.newMessageAttachments = [];
  }

  public cancelNewMessage(): void {
    this.showNewMessageInd = false;
    this.updateFiles(true);
    this.uploadedFiles = [];
    this.newMessageSubject = '';
    this.newMessageText = '';

    // Do not clear the selected contacts if it has been populated from the INPUTS.
    if (this.selectedUserId === 0) {
      this.selectedContacts = [];
    }

    this.messageCancelled.emit(true);

    // If newMessage is true, always show the message form.
    if (this.newMessage) {
      this.showNewMessage();
    }
  }

  public sendNewMessage(newMessage: Message): void {
    if (this.popoverFindRecipient.isOpen) {
      return;
    }

    const messageRecipients: MessageRecipient[] = [];
    this.selectedContacts.forEach(item => {
      messageRecipients.push(
        {
          user: {
            id: item.value.id,
            firstName: item.value.firstName,
            lastName: item.value.lastName
          }
        });
    });
    messageRecipients.push({
      user: {
        id: this.user.id,
        firstName: this.user.firstName,
        lastName: this.user.lastName
      }
    });

    const message: Message = newMessage as Message;
    message.scopeName = this.scopeName ? this.scopeName : '';
    message.scopeId = this.scopeId ? this.scopeId : null;
    message.parentScopeId = this.parentScopeId ? this.parentScopeId : null;
    message.scope = this.scope ? this.scope : null;
    message.messageRecipients = messageRecipients;
    message.fileAttachments = this.newMessageAttachments;
    message.projectCategory = isNullOrUndefined(this.projectCategory) ? null : this.projectCategory;

    if (this.sendCommunication) {
      this.messageSent.emit(message);
      return;
    }

    this.messagingService.postMessage(this.user.id, message)
      .toPromise()
      .then(r => {
        this.selectedMessage = r;

        this.showNewMessageInd = false;
        this.newMessageSubject = '';

        // Do not clear the selected contacts if it has been populated from the INPUTS.
        if (this.selectedUserId === 0) {
          this.selectedContacts = [];
        }

        // If new message is set to true, always show the form by calling the create() method on message list
        if (this.newMessage) {
          // Wrap in timeout to give chance for the newMessage component to recreate itself.
          setTimeout(() => {
            this.showNewMessage();
          });
        }

        // We are not sending the message for outside handling,
        // Just emit a blank response to notify it has been sent (for closing modals, etc)
        this.messageSent.emit(null);
        this.growlService.add({ severity: 'success', summary: 'Message Sent', detail: ' ' });
      });
  }

  public replyToThread(parentMessage: Message, newMessageText: string): void {
    const today: Date = new Date();
    const message: Message = {
      creationTime: today.getTime(),
      creator: {
        id: this.user.id,
        firstName: this.user.firstName,
        lastName: this.user.lastName,
        organization: this.user.organizationName
      },
      messageBody: newMessageText,
      messageSubject: parentMessage.messageSubject,
      isReply: true,
      messageType: 'GENERAL_MESSAGE',
      parentMessageId: parentMessage.id,
      fileAttachments: this.newMessageAttachments,
      projectCategory: isNullOrUndefined(this.projectCategory) ? null : this.projectCategory
    };
    const response = this.messagingService.postMessage(this.user.id, message);
    response.toPromise()
      .then(r => {
        this.updateFiles(false);
        this.resetMsgFileUpload();
        this.growlService.add({ severity: 'success', summary: 'Message Sent', detail: ' ' });
        this.showReplyAll = false;
      });
    this.uploadedFiles = [];
    this.newMessageText = null;

    /*
    if (this.pFileUpload.files.length > 0) {
      this.pFileUpload.upload();
      this.pFileUpload.onUpload.take(1).subscribe(response => {
        this.sendMessage(parentMessage, keys);
      });
    } else {
      this.sendMessage(parentMessage, null);
    }*/
  }

  public discardMessage(): void {
    this.newMessageText = null;
    this.updateFiles(true);
    this.uploadedFiles = [];
    this.messageCancelled.emit(true);
    this.resetMsgFileUpload();
  }

  public updateFiles(allFiles: boolean): void {
    if (allFiles) {
      this.uploadedFiles.forEach(file => this.uploadedFilesToDelete.push(file.filekey));
    }
    if (this.uploadedFilesToDelete.length > 0) {
      this.documentService.deleteFiles(this.uploadedFilesToDelete)
        .then();
    }
    this.uploadedFilesToDelete = [];
  }

  private scrollToTop(): void {
    setTimeout(() => {
      try {
        this.threadContainer.scrollToTop();
      } catch (e) {
        // Probably no selected message yet or it's the first message in the thread
      }
    });
  }

  private resetMsgFileUpload(): void {
    if (this.msgFileUpload) {
      this.msgFileUpload.reset();
    }
  }

  /**
   * Subscribe to the route parameter changes. Only when deeplink is enabled
   * This allows us to pre-select messages based on the given url parameter 'mid'
   */
  private preSelectMessage(): void {
    if (this.deeplink) {
      if (this.midSubscription) {
        this.midSubscription.unsubscribe();
      }

      this.midSubscription = this.route.paramMap
        .pipe(
          switchMap((params) => {
            return Promise.resolve(+params.get('mid'));
          })
        )
        .subscribe((messageId: number) => {
          this.queryMessageId = messageId;
          this.selectMessage(messageId);
        });
    }
  }
}
