<div class="d-flex" [ngClass]="{'flex-row-reverse' : isOwn}">
  <div class="message-attachment-card d-flex">
    <div class="attachment-icon d-flex align-items-center">
      <i class="fa fa-{{attachmentIcon}} text-center"></i>
    </div>
    <div class="attachment-filename d-flex align-items-center px-3 py-2"
         [ngClass]="{'min-attachment-width' : readOnly && !isGeneralMessage && isPM}">
      <span>{{file?.filename}}</span>
    </div>
    <div class="download-label">
      <div class="d-flex align-items-center h-100 px-3">
        <a *ngIf="isPM && readOnly && !isGeneralMessage" (click)="onAssociate.emit($event)" class="mr-3"><i class="fa fa-link mr-1"></i>Associate</a>
        <a (click)="$event.stopPropagation(); downloadAttachment()" ><i class="fa fa-download mr-1"></i>Download</a>
        <button *ngIf="!readOnly"
                (click)="$event.stopPropagation(); removeAttachment()"
                class="btn btn-icon ml-2 btn-remove">
          <i class="fa fa-times"></i>
        </button>
      </div>
    </div>
  </div>
</div>
