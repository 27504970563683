import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OfflineScreenComponent } from './offline-screen.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [OfflineScreenComponent],
  exports: [OfflineScreenComponent]
})
export class OfflineScreenModule { }
