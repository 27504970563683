<div fillHeight class="d-flex align-items-center justify-content-center">
  <div class="error-container">
    <span class="error-code">503</span>
    <div class="error-content text-center">
      <div class="error-title">Our system is currently offline</div>
      <div class="error-text">
        <p>Please check back again in a few minutes.</p>
        <a href="#" (click)="$event.preventDefault(); retry()" *ngIf="!disabledStatus"><i class="fa fa-refresh"></i>&nbsp;Retry</a>
      </div>
    </div>
  </div>
</div>
