import * as clone from 'lodash/clone';

export enum FilterPageType {
  Estimate = 'ESTIMATES',
  Projects = 'PROJECTS',
  Tasks = 'TASKS',
  VendorSearch = 'VENDOR_SEARCH',
}

export enum FilterCreatedByType {
  Company = 'COMPANY',
  Internal = 'INTERNAL',
  Vendor = 'VENDOR'
}

export interface FilterObject {
  id?: number;
  version?: number;
  filter?: string;
  description?: string;
  createdBy?: number;
  lastModified?: number;
  lastAccessed?: number;
  systemFilter?: boolean;
  pageType?: FilterPageType;
  createdByUserType?: FilterCreatedByType;
}

export interface FilterSetCollection {
  id: string;
  filters: any[];
}

export class FilterEntity {

  private filterObject: FilterObject;

  constructor(object?: FilterObject) {
    if (object) {
      this.filterObject = object;
    } else {
      this.setupDefaults();
    }
  }

  get id(): number {
    return this.filterObject.id;
  }

  set id(id: number) {
    this.filterObject.id = id;
  }

  get pageType(): FilterPageType {
    return this.filterObject.pageType;
  }

  set pageType(type: FilterPageType) {
    this.filterObject.pageType = type;
  }

  public toObject(request: boolean = true): FilterObject {
    if (request) {
      return this.toRequestObject();
    }

    return this.toResponseObject();
  }

  public update(object: FilterObject): FilterEntity {
    this.filterObject = object;
    return this;
  }

  /**
   * You can save any type of filter in the collection filter array.
   */
  public commit(collection: FilterSetCollection[]): void {
    // Weed out blank filters
    const finalizedCollection = collection.filter((item) => {
      if (!item.filters) {
        return false;
      }

      return item.filters.length > 0;
    });

    // Check if there are filters
    if (finalizedCollection.length === 0) {
      this.filterObject.filter = '';
      return;
    }

    // When we actual have filters.
    const stringify = JSON.stringify(collection);
    this.filterObject.filter = stringify;
  }

  public extract(id: string): FilterSetCollection {
    try {
      const obj = JSON.parse(this.filterObject.filter) as FilterSetCollection[];
      if (obj && Array.isArray(obj)) {
        return obj.find(collection => collection.id === id);
      }
    } catch (e) {
      return null;
    }

    return null;
  }

  public hasFilters(): boolean {
    return this.filterObject.filter.length > 0;
  }

  public isSameAuthor(userId: number): boolean {
    return userId === this.filterObject.createdBy;
  }

  private setupDefaults(): void {
    this.filterObject = {
      filter: '',
      description: '',
      systemFilter: false,
      pageType: null,
    };
  }

  private toRequestObject(): FilterObject {
    const obj = {
      filter: this.filterObject.filter,
      description: this.filterObject.description,
      systemFilter: this.filterObject.systemFilter,
      pageType: this.filterObject.pageType,
    } as FilterObject;

    if (this.filterObject.id) {
      obj.id = this.filterObject.id;
    }

    return obj;
  }

  private toResponseObject(): FilterObject {
    return clone(this.filterObject);
  }
}

