import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InitialsPipe } from './pipes/initials.pipe';
import { CountryNamePipe } from './pipes/country-name.pipe';
import { NoWhiteSpaceDirective } from './directives/no-whitespace.directive';
import { MessagingModule } from './components/messaging/messaging.module';
import { DisplayShortNamePipe } from './pipes/display-short-name.pipe';
import { PreferredPricingIconModule } from './components/preferred-pricing-icon/preferred-pricing-icon.module';

@NgModule({
  declarations: [
    InitialsPipe,
    CountryNamePipe,
    DisplayShortNamePipe,
    NoWhiteSpaceDirective,
  ],
  exports: [
    InitialsPipe,
    CountryNamePipe,
    DisplayShortNamePipe,
    MessagingModule,
  ],
  imports: [
    CommonModule,
    MessagingModule,
    PreferredPricingIconModule,
  ]
})
export class SharedModule {
}
