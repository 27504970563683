import { AfterViewInit, Directive, DoCheck, ElementRef, HostListener, Input, OnDestroy } from '@angular/core';
import { interval, Observable, Subscription } from 'rxjs';

/**
 * From the position of the attached element on the screen(top),
 * compute the necessary height to fill the space up to the end of the window height
 */

export interface FillHeightOptions {
  maxHeight: boolean; // Use the fill as max height css property
  minHeight: boolean; // Use the fill as min height css property
}

@Directive({ selector: '[fillHeight]' })
export class FillHeightDirective implements AfterViewInit, DoCheck, OnDestroy {

  private offsetHeight: number; // The offset height we'll be subtracting from the main window height

  private timer: Observable<number>;

  private timerSub: Subscription;

  @Input()
  public fillHeight: FillHeightOptions; // Options

  constructor(private el: ElementRef) {
    this.calculateAndSetElementHeight();
    this.getOffsetHeight();

    this.timer = interval(200);
    this.timerSub = this.timer
      .subscribe(() => {
        this.calculateAndSetElementHeight();
        this.getOffsetHeight();
      });
  }

  public ngAfterViewInit(): void {
    this.calculateAndSetElementHeight();
  }

  public ngOnDestroy(): void {
    this.timerSub.unsubscribe();
  }

  @HostListener('window:resize', ['$event'])
  public onResize(event: any): void {
    this.calculateAndSetElementHeight();
    this.getOffsetHeight();
  }

  // Dirty checking for the height, to make sure it's always the right size
  public ngDoCheck(): void {
    // This could performance intensive - skipping this for now.
    // this.calculateAndSetElementHeight();
    // this.getOffsetHeight();
  }

  private getOffsetHeight(): void {
    // The fastest way to get it is with a little classic magic
    this.offsetHeight = jQuery(this.el.nativeElement)
      .offset().top;
  }

  private calculateAndSetElementHeight(): void {
    const windowHeight = window.innerHeight;

    if (this.fillHeight && this.fillHeight.minHeight) {
      this.el.nativeElement.style.minHeight = windowHeight - this.offsetHeight + 'px';
      return;
    }

    // When no options specified
    this.el.nativeElement.style.height = windowHeight - this.offsetHeight + 'px';
  }
}
