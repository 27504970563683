import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { OrderBill } from '../../shared/dto/billing/order-bill';
import { RequestService } from '../../shared/services/request-service';
import { HttpClient } from '@angular/common/http';
import { BillPayment } from '../../shared/dto/billing/bill-payment';
import { DocumentService } from '../../data-services/document.service';
import { VendorLite } from '../../shared/dto/vendor-management/vendor-lite';
import { AdditionalBillOption } from '../../shared/dto/billing/additional-bill-option';
import { map } from 'rxjs/operators';
import { sortBy } from '../../shared/utils/sort-by';
import { FilingBill } from '../../shared/dto/billing/filing-bill';
import { PaginatedData, PaginateObject } from '../../shared/dto/paginated-data';
import { VendorBillPaginated } from '../../shared/dto/annuities/vendor-bill-paginated';
import { RequestFilterNumberResponse } from '../../shared/query/request-filter.component';

@Injectable()
export class BillingService {

  constructor(private httpClient: HttpClient,
              private requestService: RequestService,
              private docService: DocumentService) {
  }

  public getAllPaginated(request: PaginatedData<VendorBillPaginated>): Observable<PaginatedData<VendorBillPaginated>> {
    const url = '/api/bill/paginated';
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient
      .post<any>(url, request.toObject(), { headers: headers })
      .pipe(
        // Update the given pagination object with values from the response.
        map((response: PaginateObject<VendorBillPaginated>) => request.update(response))
      );
  }

  public getCount(request: PaginatedData<VendorBillPaginated>): Observable<RequestFilterNumberResponse> {
    const url = '/api/bill/counts';
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.post<any>(url, request.toObject(), { headers: headers });
  }

  public submitBill(billId: number, invoiceNumber: string): Observable<OrderBill> {
    const url = `/api/bill/${billId}/submit`;
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.put<OrderBill>(url, invoiceNumber, { headers: headers });
  }

  public rejectBill(billId: number): Observable<OrderBill> {
    const url = `/api/bill/${billId}/reject`;
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.put<OrderBill>(url, null, { headers: headers });
  }

  public updateBill(billId: number, bill: OrderBill): Observable<OrderBill> {
    const url = `/api/bill/${billId}`;
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.put<OrderBill>(url, bill, { headers: headers });
  }

  public retrieveProjectBills(projectId: number): Observable<OrderBill[]> {
    const url = `/api/bill/project/${projectId}`;
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.get<OrderBill[]>(url, { headers: headers });
  }

  public retrieveBill(billId: number): Observable<OrderBill> {
    const url = `/api/bill/${billId}`;
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.get<OrderBill>(url, { headers: headers });
  }

  public retrieveBillPayment(billId: number): Observable<BillPayment[]> {
    const url = `/api/bill/${billId}/payment`;
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.get<BillPayment[]>(url, { headers: headers });
  }

  public downloadPaymentExcel(billId: number, billPayments: BillPayment[]): void {
    const url = `/api/bill/${billId}/payment/excel`;
    const headers = this.requestService.buildHttpHeaders();

    this.httpClient.post(url, billPayments, {
      headers: headers,
      observe: 'response',
      responseType: 'blob'
    })
      .toPromise()
      .then(resp => {
        this.docService.saveFile(resp);
      }
      );
  }

  public retrieveBillableVendors(orderId: number): Observable<VendorLite[]> {
    const url = `/api/bill/additional/billable/vendors/order/${orderId}`;
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient
      .get<VendorLite[]>(url, { headers: headers })
      .pipe(
        map((vendors: VendorLite[]) => {
          // Sort the services by name
          return sortBy(vendors, (vendor: VendorLite) => {
            return vendor.vendorName.toUpperCase();
          });
        })
      );
  }

  public retrieveAdditionalBillOptions(orderId: number, vendorId: number): Observable<AdditionalBillOption[]> {
    const url = `/api/bill/additional/vendor/${vendorId}/order/${orderId}`;
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient.get<AdditionalBillOption[]>(url, { headers: headers });
  }

  public createBill(orderBill: OrderBill): Observable<OrderBill> {
    const url = '/api/bill';
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient.post<OrderBill>(url, orderBill, { headers: headers });
  }

  public getFilingBillTotal(filingBill: FilingBill): Observable<FilingBill> {
    const url = '/api/bill/calculate-filing-bill';
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient.post<FilingBill>(url, filingBill, { headers: headers });
  }
}
