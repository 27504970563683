export enum ProjectTypeEnum {
  FILING_ONLY = 'FILING_ONLY',
  GENERAL_LEGAL = 'GENERAL_LEGAL',
  LITIGATION = 'LITIGATION',
  PROOFREADING = 'PROOFREADING',
  TRANSLATION_ONLY = 'TRANSLATION_ONLY',
  TRANSLATION_AND_FILING = 'TRANSLATION_AND_FILING',
  PATENT_REFERENCE = 'PATENT_REFERENCE',
  CONTRACTS = 'CONTRACTS',
  OTHER = 'OTHER',
  OFFICE_ACTION = 'OFFICE_ACTION',
  ANNUITIES = 'ANNUITIES'
}
