<townip-modal #associateFiles heading="Associate Files" size="lg" (onHide)="hide()">
  <townip-modal-body>
    <div [hidden]="!loaded">
      <div class="row">
        <div class="col-6">
          <div>Filename</div>
          <div class="mt-1 filename">
            {{file?.filename}}
          </div>
        </div>
        <div class="col-6">
          <div>
            File Category
            <span class="ml-1" style="cursor: pointer">
              <span *ngIf="!updateCategory">
                <i class="fa fa-edit" (click)="updateCategory = true"></i>
              </span>
              <span *ngIf="updateCategory">
                <i class="fa fa-times" (click)="updateCategory = false"></i>
              </span>
            </span>
          </div>
          <div class="mt-1">
            <p-dropdown
              *ngIf="updateCategory"
              placeholder="Select File Type"
              [filter]="true"
              [options]="fileCategoryItems"
              (onChange)="updateFileCategory($event)">
            </p-dropdown>
            <span class="file-header" *ngIf="!updateCategory">
              {{file?.fileCategory.text}}
            </span>
          </div>
        </div>
      </div>
      <div class="margin-top-40">
        I would like to associate this file with:
      </div>
      <div class="ml-3 mt-3">
        <div>
          <p-checkbox [disabled]="associateWithTasks || alreadyAssociated" binary="true"
                      [(ngModel)]="associateWithOrder"></p-checkbox>
          <span class="ml-2 file-header">{{label}}</span>
        </div>
        <div class="margin-top-15" [hidden]="!showTaskAssociation">
          <div class="ml-2">
            <p-checkbox [(ngModel)]="associateWithTasks" binary="true"
                        (onChange)="setAssociateWithTasks()" [disabled]="alreadyAssociated"></p-checkbox>
            <span class="ml-2 file-header">Tasks:</span>
          </div>
          <div class="mt-2 mb-2" [hidden]="!associateWithTasks">
            <townip-files-associate-tasks
              #filesAssociateTasks
              [(filters)]="fileAssociateTaskFilters"
              [isLegalTranslation]="isLegalTranslation"
              (onAssociatedTasksChange)="this.associatedTasksChanged = true;">
            </townip-files-associate-tasks>
          </div>
        </div>
      </div>
    </div>
    <div class="text-center" *ngIf="!loaded">
      <i class="fa fa-refresh fa-spin fa-fw"></i>
    </div>
  </townip-modal-body>
  <townip-modal-footer>
    <button pButton type="button" label="Cancel" icon="fa fa-close" (click)="hide()"
            class="raised-button ui-button-secondary ui-button-cancel"></button>
    <button pButton type="button" label="Associate" icon="fa fa-check" (click)="associateFile()"
            class="raised-button ui-button-success margin-left-5"
            [disabled]="!associateWithOrder ||
            (associateWithTasks && !associatedTasksChanged) || (alreadyAssociated && !associateWithTasks)"></button>
  </townip-modal-footer>
</townip-modal>
