<div class="associate-file-container">
  <div [hidden]="true">
    <townip-filter-selection #taskTableFilter (onUpdate)="saveFilters()" (onClear)="onClearFilters()">
    </townip-filter-selection>
  </div>
  <button class="btn btn-link btn-reset" (click)="taskFilterSelection.clear()">
    <i class="fa fa-circle-o-notch mr-1"></i>Reset Filters
  </button>
  <p-table
    styleClass="standard-table"
    dataKey="taskAssignmentId"
    selectionMode="multiple"
    #tasksTable
    [(selection)]="updatedTasks"
    [value]="tableTasks | dataFilter:taskTableFilter.activeFilters"
    [rows]="10"
    [paginator]="true"
    [reorderableColumns]="true"
    [style]="{'max-width' : '800px'}"
    (onFilter)="saveFilters()"
    (onRowSelect)="onTaskSelectChange($event)"
    (onRowUnselect)="onTaskSelectChange($event)"
    (onHeaderCheckboxToggle)="onTaskHeaderSelect($event)">
    <ng-template pTemplate="header">
      <tr>
        <th style="width: 75px;">
          <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
        </th>
        <th *ngFor="let col of taskCols" [ngClass]="col.class" [ngStyle]="col.style" [hidden]="col.hidden">
          <div><span class="column-title">{{col.header}}</span></div>
          <div *ngIf="col.filter">
            <townip-header-filter
              *ngIf="col.customHeaderFilter"
              [options]="col.customHeaderFilter"
              [filterSelection]="taskTableFilter"
              [metadata]="taskDataHeaderFilterMetaData">
            </townip-header-filter>
            <input
              type="text"
              class="ui-column-filter ui-inputtext"
              *ngIf="!col.customHeaderFilter"
              [(ngModel)]="col.filterValue"
              (input)="tasksTable.filter($event.target.value, col.field, col.filterMatchMode)">
          </div>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-task>
      <tr>
        <td>
          <p-tableCheckbox [value]="task"></p-tableCheckbox>
        </td>
        <td
          *ngFor="let col of taskCols"
          [ngSwitch]="col.templateType"
          [ngClass]="col.class"
          [ngStyle]="col.style"
          [hidden]="col.hidden">
          <ng-container *ngSwitchCase="'assignee'">
            <span class="client-name">
              {{task?.task | taskAssignee}}
            </span>
          </ng-container>
          <ng-container *ngSwitchCase="'sourceFile'">
            <span *ngIf="task.sourceFile">
              <span>
                {{task.sourceFile}}
              </span>
            </span>
          </ng-container>
          <ng-container *ngSwitchDefault>{{task[col.field]}}</ng-container>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td [attr.colspan]="taskCols?.length + 1">
          No records found
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
