import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Error404Component } from './error-404/error-404.component';
import { Error403Component } from './error-403/error-403.component';
import { ErrorScreensizeComponent } from './error-screensize/error-screensize.component';
import { DirectivesModule } from '../shared/directives/directives.module';
import { RouterModule } from '@angular/router';
import { ErrorsRoutingModule } from './errors-routing.module';
import { ErrorScreensizeMonitorComponent } from './error-screensize/error-screensize-monitor/error-screensize-monitor.component';
import { ErrorDarkComponent } from './error-dark/error-dark.component';
import { ErrorLogService } from './error-log.service';
import { ErrorSystemOfflineComponent } from './error-system-offline/error-system-offline.component';
import { ErrorAuthModuleOnlyComponent } from './error-auth-module-only/error-auth-module-only.component';
import { Error503MaintenanceComponent } from './error-503/error-503-maintenance.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    DirectivesModule,
    ErrorsRoutingModule,
  ],
  declarations: [
    Error404Component,
    Error403Component,
    ErrorScreensizeComponent,
    ErrorScreensizeMonitorComponent,
    ErrorDarkComponent,
    ErrorSystemOfflineComponent,
    ErrorAuthModuleOnlyComponent,
    Error503MaintenanceComponent
  ],
  exports: [
    Error404Component,
    Error403Component,
    ErrorScreensizeComponent,
    ErrorScreensizeMonitorComponent
  ],
  providers: [
    ErrorLogService,
  ]
})
export class ErrorsModule { }
