import { Injectable } from '@angular/core';
import { FilterEntity, FilterObject, FilterPageType, FilterSetCollection } from '../shared/dto/filter-entity';
import { Observable, of, throwError as observableThrowError } from 'rxjs';
import { RequestService } from '../shared/services/request-service';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import * as clone from 'lodash/cloneDeep';
import * as moment from 'moment';
import { isNullOrUndefined } from '../shared/utils/is-null-or-undefined';

export const STATIC_FILTERS = {
  PMDashboardProjectActive: -1,
  PMDashboardEstimateNeedsReview: -2,
  PMDashboardTasksWorkflowDue: -3,
  PMDashboardTasksPOAsReady: -4,
  PMDashboardTasksQAReady: -9,
  PMDashboardTasksQAReviewReady: -8,
  PMDashboardTasksNeedsAction: -5,
  PMDashboardTasksUnassigned: -6,
  PMDashboardTasksRequested: -7,
  PMDashboardTasksTranslationDeliveryReady: -12,
  PMDashboardTasksTranslationDeliveryDue: -13,
  PMDashboardTasksFilingDeliveryReady: -14,
  PMDashboardTasksSendInvoiceReady: -16,
  PMDashboardTasksFilingConfirmationDue: -17,
  PMDashboardTasksReadyForCompletion: -10,
  PMDashboardTasksProjectLevelAssigned: -11,
  PMDashboardProjectOverdue: -18,
  CustomerDashboardProjectCompleted: -34,
  CustomerDashboardActiveProjects: -35,
  CustomerDashboardAllProjects: -36,
  CustomerDashboardPendingSunIpAction: -37,
  AccountSalesDashboardExpiredEstimate: -101,
  AccountSalesDashboardApprovedEstimate: -102,
  AccountSalesDashboardProjectActive: -108,
  VendorUserDashboardProjectActive: -103,
  VendorUserDashboardTaskReadyToStart: -104,
  VendorUserDashboardTaskNeedsAction: -105,
  VendorUserDashboardTaskOverdue: -106,
  VendorUserDashboardProjectCompleted: -107,
  VendorManagerDashboardTasksUnassigned: -51,
  VendorManagerDashboardTasksNeedAction: -52,
  VendorManagerDashboardTasksReadyToStart: -53,
  VendorManagerDashboardTasksOverdue: -54,
  VendorManagerDashboardProjectsActive: -55,
  VendorManagerDashboardProjectsNeedAction: -56,
  VendorManagerDashboardNeedsBilling: -57,

  CustomerDashboardV2EstimatesActive: -3101,
  CustomerDashboardV2EstimatesRecentlyExpired: -3102,
  CustomerDashboardV2PendingApproval: -31,
  SalesAccountManagerDashboardV2EstimatesNeedSunIPAction: -3201,
  SalesAccountManagerDashboardV2EstimatesReadyForApproval: -3202,
  SalesAccountManagerDashboardV2EstimatesUpcomingExpiration: -3203,
};

interface FilterObjectSource extends FilterObject {
  filterSource: FilterSetCollection[];
}

@Injectable()
export class FilterService {

  private staticFilters = [];

  constructor(private httpClient: HttpClient,
              private requestService: RequestService) {
    this.setupStaticFilters();
  }

  public get(id: number): Observable<FilterEntity> {
    // Check for static filters first
    if (this.staticFilterExists(id)) {
      return this.getStatic(id);
    }

    // If specified id does not exist from static, fetch from backend
    const url = `/api/v2/filter/${id}`;
    return this.httpClient
      .get<FilterEntity>(url, { headers: this.requestService.buildHttpHeaders() })
      .pipe(
        map(res => {
          return new FilterEntity(res);
        })
      );
  }

  public update(filter: FilterEntity): Observable<FilterEntity> {
    const url = '/api/v2/filter';
    return this.httpClient
      .put<FilterObject>(url, filter.toObject(), { headers: this.requestService.buildHttpHeaders() })
      .pipe(
        map(res => {
          return new FilterEntity(res);
        })
      );
  }

  public create(filter: FilterEntity): Observable<FilterEntity> {
    const url = '/api/v2/filter';

    // Make sure the id is always stripped
    filter.id = null;

    // Systems filters can't be created.
    const filterRequest = filter.toObject();
    filterRequest.systemFilter = false;

    return this.httpClient
      .post<FilterEntity>(url, filterRequest, { headers: this.requestService.buildHttpHeaders() })
      .pipe(
        map(res => {
          return filter.update(res);
        })
      );
  }

  public getStatic(id: number): Observable<FilterEntity> {
    // filterSource is the object version of the filters - used to quickly modify these filters.
    // These filterSource will then be converted to filter string afterwards before being returned to the service.
    // IMPORTANT NOTE: filterSource should always be the same format that we generate in filter-entity.ts.


    // Handle pre-processing of the filters
    let foundFilter = this.staticFilters.find((filter) => filter.id === id);
    if (foundFilter) {
      foundFilter = clone(foundFilter);
      foundFilter.filter = JSON.stringify(foundFilter.filterSource);
      delete foundFilter.filterSource;

      return of(new FilterEntity(foundFilter));
    }

    return observableThrowError('Static filter not found.');
  }

  private setupStaticFilters(): void {
    const filterDefault: FilterObjectSource = {
      id: 0,
      version: 1,
      filter: '',
      filterSource: [],
      createdBy: 0,
      lastModified: new Date().valueOf(),
      lastAccessed: new Date().valueOf(),
      systemFilter: true,
      createdByUserType: null,
    };

    this.staticFilters = [
      {
        ...filterDefault,
        id: STATIC_FILTERS.PMDashboardProjectActive,
        description: 'PM Dashboard [ACTIVE Projects] KPI Filter',
        pageType: FilterPageType.Projects,
        filterSource: [
          {
            id: 'tableHeader',
            filters: []
          },
          {
            id: 'filterButtons',
            filters: [
              {
                name: 'On Track',
                filters: [
                  {
                    name: 'On Track',
                    field: 'projectOverviewStatus',
                    type: 2,
                    operator: '==',
                    value: 'ON_TRACK'
                  }
                ],
                category: 'status'
              },
              {
                name: 'Overdue',
                filters: [
                  {
                    name: 'Overdue',
                    field: 'projectOverviewStatus',
                    type: 2,
                    operator: '==',
                    value: 'OVERDUE'
                  }
                ],
                category: 'status'
              },
              {
                name: 'Delayed',
                filters: [
                  {
                    name: 'Delayed',
                    field: 'projectOverviewStatus',
                    type: 2,
                    operator: '==',
                    value: 'LATE'
                  }
                ],
                category: 'status'
              },
              {
                name: 'On Hold',
                filters: [
                  {
                    name: 'On Hold',
                    field: 'projectOverviewStatus',
                    type: 2,
                    operator: '==',
                    value: 'ON_HOLD'
                  }
                ],
                category: 'status'
              },
              {
                name: 'Active Projects',
                filters: [
                  {
                    name: 'Not Ready To Complete',
                    field: 'readyToComplete',
                    type: 3,
                    operator: '==',
                    value: false
                  },
                  {
                    name: 'Internal Work Not Complete',
                    field: 'internalWorkComplete',
                    type: 3,
                    operator: '==',
                    value: false
                  }
                ],
                category: 'additional',
                custom: true
              },
            ]
          },
          {
            id: 'filterGroupSet',
            filters: ['Active']
          }
        ],
      },
      {
        ...filterDefault,
        id: STATIC_FILTERS.PMDashboardProjectOverdue,
        description: 'PM Dashboard [Overdue Projects] KPI Filter',
        pageType: FilterPageType.Projects,
        filterSource: [
          {
            id: 'tableHeader',
            filters: []
          },
          {
            id: 'filterButtons',
            filters: [
              {
                name: 'Overdue',
                filters: [
                  {
                    name: 'Overdue',
                    field: 'projectOverviewStatus',
                    type: 2,
                    operator: '==',
                    value: 'OVERDUE'
                  }
                ],
                category: 'status'
              }
            ]
          },
          {
            id: 'filterGroupSet',
            filters: ['Active']
          }
        ],
      }, // -- End filter item.
      {
        ...filterDefault,
        id: STATIC_FILTERS.PMDashboardEstimateNeedsReview,
        description: 'PM Dashboard [Estimates Need Review] KPI Filter',
        pageType: FilterPageType.Estimate,
        filterSource: [
          {
            id: 'tableHeader',
            filters: []
          },
          {
            id: 'filterButtons',
            filters: [
              {
                name: 'In Progress',
                filters: [
                  {
                    name: 'In Progress',
                    field: 'projectEstimateStatus',
                    type: 2,
                    operator: '==',
                    value: 'IN_PROGRESS'
                  }
                ],
                category: 'status'
              }
            ]
          }
        ],
      }, // -- End filter item.
      {
        ...filterDefault,
        id: STATIC_FILTERS.PMDashboardTasksWorkflowDue,
        description: 'PM Dashboard [TASK Workflow Due] KPI Filter',
        pageType: FilterPageType.Tasks,
        filterSource: [
          {
            id: 'tableHeader',
            filters: []
          },
          {
            id: 'filterButtons',
            filters: [
              {
                name: 'Workflow Due Task Type',
                filters: [
                  {
                    name: 'Task Due Filters',
                    field: 'taskType',
                    type: 2,
                    operator: 'in',
                    value: [
                      'Translation',
                      'Proofreading',
                      'Translation Approval',
                      'Filing Receipt',
                      'Ad-Hoc Review',
                      'Certified Translation',
                      'Translation Certification',
                      'Other',
                      'Notarization',
                      'Apostille',
                      'Desktop Publishing',
                      'Verify Official Fees',
                    ]
                  }
                ],
                category: 'status',
                custom: true
              },
              {
                name: 'Workflow Due',
                filters: [
                  {
                    name: 'Active Status',
                    field: 'status',
                    type: 2,
                    operator: 'in',
                    value: ['ON_TRACK', 'LATE', 'ON_HOLD', 'OVERDUE']
                  },
                  {
                    name: 'Not NEW',
                    field: 'taskStageString',
                    type: 2,
                    operator: '!=',
                    value: 'NEW'
                  }
                ],
                category: 'additional',
                custom: true
              },
              {
                name: 'Workflow Cancellation Request Due',
                filters: [
                  {
                    name: 'Cancelled Status',
                    field: 'status',
                    type: 2,
                    operator: '==',
                    value: 'CANCELLED'
                  },
                  {
                    name: 'Cancellation Request Stage',
                    field: 'taskStageString',
                    type: 2,
                    operator: '==',
                    value: 'CANCELLATION_REQUEST'
                  }
                ],
                category: 'additional',
                custom: true
              }
            ]
          },
          {
            id: 'filterGroupSet',
            filters: ['Active']
          }
        ]
      }, // -- End filter item.
      {
        ...filterDefault,
        id: STATIC_FILTERS.PMDashboardTasksPOAsReady,
        description: 'PM Dashboard [TASK POAs Due] KPI Filter',
        pageType: FilterPageType.Tasks,
        filterSource: [
          {
            id: 'tableHeader',
            filters: []
          },
          {
            id: 'filterButtons',
            filters: [
              {
                name: 'POAs Ready',
                filters: [
                  {
                    name: 'Ready',
                    field: 'taskStageString',
                    type: 2,
                    operator: 'in',
                    value: ['READY_TO_START', 'IN_PROGRESS']
                  },
                  {
                    name: 'POAs Filters',
                    field: 'taskType',
                    type: 2,
                    operator: 'in',
                    value: [
                      'Document Execution',
                      'Send Document To Client'
                    ]
                  }
                ],
                category: 'additional',
                custom: true
              }
            ]
          },
          {
            id: 'filterGroupSet',
            filters: ['Active']
          }
        ]
      }, // -- End filter item.
      {
        ...filterDefault,
        id: STATIC_FILTERS.PMDashboardTasksQAReady,
        description: 'PM Dashboard [TASK QA Ready] KPI Filter',
        pageType: FilterPageType.Tasks,
        filterSource: [
          {
            id: 'tableHeader',
            filters: []
          },
          {
            id: 'filterButtons',
            filters: [
              {
                name: 'QA Ready',
                filters: [
                  {
                    name: 'Ready',
                    field: 'taskStageString',
                    type: 2,
                    operator: 'in',
                    value: ['READY_TO_START', 'IN_PROGRESS']
                  },
                  {
                    name: 'QA',
                    field: 'taskType',
                    type: 2,
                    operator: 'in',
                    value: [
                      'Quality Assurance',
                    ]
                  },
                  {
                    name: 'Not Internal Assigned',
                    field: 'assignedUserIsInternal',
                    type: 3,
                    operator: '==',
                    value: false
                  },
                ],
                category: 'additional',
                custom: true
              }
            ]
          },
          {
            id: 'filterGroupSet',
            filters: ['Active']
          }
        ]
      }, // -- End filter item.
      {
        ...filterDefault,
        id: STATIC_FILTERS.PMDashboardTasksQAReviewReady,
        description: 'PM Dashboard [TASK QA Review Ready] KPI Filter',
        pageType: FilterPageType.Tasks,
        filterSource: [
          {
            id: 'tableHeader',
            filters: []
          },
          {
            id: 'filterButtons',
            filters: [
              {
                name: 'QA Review Ready',
                filters: [
                  {
                    name: 'Ready',
                    field: 'taskStageString',
                    type: 2,
                    operator: 'in',
                    value: ['READY_TO_START', 'IN_PROGRESS']
                  },
                  {
                    name: 'QA Review',
                    field: 'taskType',
                    type: 2,
                    operator: 'in',
                    value: [
                      'QA Review',
                    ]
                  }
                ],
                category: 'additional',
                custom: true
              },
              {
                name: 'QA Ready',
                filters: [
                  {
                    name: 'Ready',
                    field: 'taskStageString',
                    type: 2,
                    operator: 'in',
                    value: ['READY_TO_START', 'IN_PROGRESS']
                  },
                  {
                    name: 'QA',
                    field: 'taskType',
                    type: 2,
                    operator: 'in',
                    value: [
                      'Quality Assurance',
                    ]
                  },
                  {
                    name: 'Internal Assigned',
                    field: 'assignedUserIsInternal',
                    type: 3,
                    operator: '==',
                    value: true
                  },
                ],
                category: 'additional',
                custom: true
              }
            ]
          },
          {
            id: 'filterGroupSet',
            filters: ['Active']
          }
        ]
      }, // -- End filter item.
      {
        ...filterDefault,
        id: STATIC_FILTERS.PMDashboardTasksNeedsAction,
        description: 'PM Dashboard [TASK Needs Action] KPI Filter',
        pageType: FilterPageType.Tasks,
        filterSource: [
          {
            id: 'tableHeader',
            filters: []
          },
          {
            id: 'filterButtons',
            filters: [
              {
                name: 'Needs Action',
                filters: [
                  {
                    name: 'Needs Action',
                    field: 'needsAction',
                    type: 3,
                    operator: '==',
                    value: true
                  }
                ],
                category: 'additional'
              }
            ],
          },
          {
            id: 'filterGroupSet',
            filters: ['All Tasks']
          }
        ],
      }, // -- End filter item.
      {
        ...filterDefault,
        id: STATIC_FILTERS.PMDashboardTasksUnassigned,
        description: 'PM Dashboard [TASK Unassigned] KPI Filter',
        pageType: FilterPageType.Tasks,
        filterSource: [
          {
            id: 'tableHeader',
            filters: [
              {
                id: 'header_filter_19',
                field: 'taskStageString',
                operator: 'in',
                value: [
                  'NEW',
                  'REJECTED'
                ],
                type: 2
              }
            ]
          },
          {
            id: 'filterButtons',
            filters: []
          },
          {
            id: 'filterGroupSet',
            filters: ['Active']
          }
        ]
      }, // -- End filter item.
      {
        ...filterDefault,
        id: STATIC_FILTERS.PMDashboardTasksRequested,
        description: 'PM Dashboard [TASK Requested] KPI Filter',
        pageType: FilterPageType.Tasks,
        filterSource: [
          {
            id: 'tableHeader',
            filters: [
              {
                id: 'header_filter_19',
                field: 'taskStageString',
                operator: 'in',
                value: [
                  'REQUESTED'
                ],
                type: 2
              }
            ]
          },
          {
            id: 'filterButtons',
            filters: []
          },
          {
            id: 'filterGroupSet',
            filters: ['Active']
          }
        ]
      }, // -- End filter item.
      {
        ...filterDefault,
        id: STATIC_FILTERS.PMDashboardTasksTranslationDeliveryReady,
        description: 'PM Dashboard [TASK Translation Delivery Ready] KPI Filter',
        pageType: FilterPageType.Tasks,
        filterSource: [
          {
            id: 'tableHeader',
            filters: []
          },
          {
            id: 'filterButtons',
            filters: [
              {
                name: 'Translation Delivery Ready',
                filters: [
                  {
                    name: 'Ready',
                    field: 'taskStageString',
                    type: 2,
                    operator: 'in',
                    value: ['READY_TO_START', 'IN_PROGRESS']
                  },
                  {
                    name: 'Translation Delivery Tasks',
                    field: 'taskType',
                    type: 2,
                    operator: 'in',
                    value: ['Translation Delivery']
                  }
                ],
                category: 'additional',
                custom: true
              }
            ]
          },
          {
            id: 'filterGroupSet',
            filters: ['Active']
          }
        ]
      }, // -- End filter item.
      {
        ...filterDefault,
        id: STATIC_FILTERS.PMDashboardTasksTranslationDeliveryDue,
        description: 'PM Dashboard [TASK Translation Delivery Due] KPI Filter',
        pageType: FilterPageType.Tasks,
        filterSource: [
          {
            id: 'tableHeader',
            filters: []
          },
          {
            id: 'filterButtons',
            filters: [
              {
                name: 'Translation Delivery Due',
                filters: [
                  {
                    name: 'Active Stage',
                    field: 'status',
                    type: 2,
                    operator: 'in',
                    value: [
                      'OVERDUE',
                      'ON_TRACK',
                      'ON_HOLD',
                      'LATE'
                    ]
                  },
                  {
                    name: 'Translation Delivery Tasks',
                    field: 'taskType',
                    type: 2,
                    operator: 'in',
                    value: ['Translation Delivery']
                  },
                ],
                category: 'additional',
                custom: true
              },
              {
                name: 'Translation Delivery No Due',
                filters: [
                  {
                    name: 'Active Stage',
                    field: 'status',
                    type: 2,
                    operator: 'in',
                    value: [
                      'OVERDUE',
                      'ON_TRACK',
                      'ON_HOLD',
                      'LATE'
                    ]
                  },
                  {
                    name: 'Translation Delivery Tasks',
                    field: 'taskType',
                    type: 2,
                    operator: 'in',
                    value: ['Translation Delivery']
                  },
                  {
                    name: 'No Due Date',
                    field: 'dueDate',
                    type: 3,
                    operator: 'isnull',
                    value: true
                  },
                ],
                category: 'additional',
                custom: true
              }
            ]
          },
          {
            id: 'filterGroupSet',
            filters: ['Active']
          }
        ]
      }, // -- End filter item.
      {
        ...filterDefault,
        id: STATIC_FILTERS.PMDashboardTasksFilingDeliveryReady,
        description: 'PM Dashboard [TASK Filing Delivery Ready] KPI Filter',
        pageType: FilterPageType.Tasks,
        filterSource: [
          {
            id: 'tableHeader',
            filters: []
          },
          {
            id: 'filterButtons',
            filters: [
              {
                name: 'Filing Delivery Ready',
                filters: [
                  {
                    name: 'Ready',
                    field: 'taskStageString',
                    type: 2,
                    operator: 'in',
                    value: ['READY_TO_START', 'IN_PROGRESS']
                  },
                  {
                    name: 'Filing Delivery Tasks',
                    field: 'taskType',
                    type: 2,
                    operator: 'in',
                    value: ['Filing Delivery', 'Review Filing Report'],
                  }
                ],
                category: 'additional',
                custom: true
              }
            ]
          },
          {
            id: 'filterGroupSet',
            filters: ['Active']
          }
        ]
      }, // -- End filter item.
      {
        ...filterDefault,
        id: STATIC_FILTERS.PMDashboardTasksFilingConfirmationDue,
        description: 'PM Dashboard [TASK Filing Confirmation Due] KPI Filter',
        pageType: FilterPageType.Tasks,
        filterSource: [
          {
            id: 'tableHeader',
            filters: []
          },
          {
            id: 'filterButtons',
            filters: [
              {
                name: 'Filing Confirmation Due',
                filters: [
                  {
                    name: 'Active Stage',
                    field: 'status',
                    type: 2,
                    operator: 'in',
                    value: ['ON_TRACK', 'LATE', 'ON_HOLD', 'OVERDUE']
                  },
                  {
                    name: 'Filing Confirmation Tasks',
                    field: 'taskType',
                    type: 2,
                    operator: 'in',
                    value: ['Filing Confirmation', 'Translations Filed', 'PCT Filing', 'Direct Filing', 'EP Filing']
                  },
                ],
                category: 'additional',
                custom: true
              },
              {
                name: 'Filing Confirmation No Due Date',
                filters: [
                  {
                    name: 'Active Stage',
                    field: 'taskStageString',
                    type: 2,
                    operator: 'out',
                    value: ['COMPLETE', 'SKIPPED', 'CANCELLED']
                  },
                  {
                    name: 'Filing Confirmation Tasks',
                    field: 'taskType',
                    type: 2,
                    operator: 'in',
                    value: ['Filing Confirmation', 'PCT Filing', 'Direct Filing', 'EP Filing']
                  },
                  {
                    name: 'No Due Date',
                    field: 'dueDate',
                    type: 3,
                    operator: 'isnull',
                    value: true
                  },
                ],
                category: 'additional',
                custom: true
              },
            ]
          },
          {
            id: 'filterGroupSet',
            filters: ['Active']
          }
        ]
      }, // -- End filter item.
      {
        ...filterDefault,
        id: STATIC_FILTERS.PMDashboardTasksReadyForCompletion,
        description: 'PM Dashboard [TASK Ready for Completion] KPI Filter',
        pageType: FilterPageType.Tasks,
        filterSource: [
          {
            id: 'tableHeader',
            filters: []
          },
          {
            id: 'filterButtons',
            filters: [
              {
                name: 'Ready for Completion',
                filters: [
                  {
                    name: 'Ready',
                    field: 'taskStageString',
                    type: 2,
                    operator: 'in',
                    value: ['READY_TO_START', 'IN_PROGRESS']
                  },
                  {
                    name: 'Complete',
                    field: 'serviceSubtype',
                    type: 2,
                    operator: '==',
                    value: 'COMPLETE_PROJECT'
                  }
                ],
                category: 'additional',
                custom: true
              }
            ]
          },
          {
            id: 'filterGroupSet',
            filters: ['Active']
          }
        ]
      }, // -- End filter item.
      {
        ...filterDefault,
        id: STATIC_FILTERS.PMDashboardTasksSendInvoiceReady,
        description: 'PM Dashboard [TASK Send Invoice Ready] KPI Filter',
        pageType: FilterPageType.Tasks,
        filterSource: [
          {
            id: 'tableHeader',
            filters: []
          },
          {
            id: 'filterButtons',
            filters: [
              {
                name: 'Complete Project Ready',
                filters: [
                  {
                    name: 'Ready',
                    field: 'taskStageString',
                    type: 2,
                    operator: 'in',
                    value: ['READY_TO_START', 'IN_PROGRESS']
                  },
                  {
                    name: 'Complete Project Tasks',
                    field: 'taskType',
                    type: 2,
                    operator: 'in',
                    value: ['Complete Project']
                  },
                  {
                    name: 'Complete',
                    field: 'draftInvoiceExist',
                    type: 2,
                    operator: '==',
                    value: true
                  }
                ],
                category: 'additional',
                custom: true
              },
              {
                name: 'Send Invoice Ready',
                filters: [
                  {
                    name: 'Ready',
                    field: 'taskStageString',
                    type: 2,
                    operator: 'in',
                    value: ['READY_TO_START', 'IN_PROGRESS', 'ON_HOLD']
                  },
                  {
                    name: 'Send Invoice Tasks',
                    field: 'taskType',
                    type: 2,
                    operator: 'in',
                    value: ['Send Invoice']
                  }
                ],
                category: 'additional',
                custom: true
              },
              {
                name: 'Approve Proposed Fees',
                filters: [
                  {
                    name: 'Ready',
                    field: 'taskStageString',
                    type: 2,
                    operator: 'in',
                    value: ['READY_TO_START', 'IN_PROGRESS', 'ON_HOLD'],
                  },
                  {
                    name: 'Approve Proposed Fees Tasks',
                    field: 'taskType',
                    type: 2,
                    operator: 'in',
                    value: ['Approve Proposed Fees'],
                  },
                ],
                category: 'additional',
                custom: true,
              },
            ]
          },
          {
            id: 'filterGroupSet',
            filters: ['Active']
          }
        ]
      }, // -- End filter item.
      {
        ...filterDefault,
        id: STATIC_FILTERS.PMDashboardTasksProjectLevelAssigned,
        description: 'PM Dashboard [TASK Project Level Assigned] KPI Filter',
        pageType: FilterPageType.Tasks,
        filterSource: [
          {
            id: 'tableHeader',
            filters: []
          },
          {
            id: 'filterButtons',
            filters: [
              {
                name: 'Assigned Project Level Tasks',
                filters: [
                  {
                    name: 'Ready',
                    field: 'taskStageString',
                    type: 2,
                    operator: 'in',
                    value: ['READY_TO_START', 'IN_PROGRESS']
                  },
                  {
                    name: 'Internal Tasks',
                    field: 'serviceString',
                    type: 2,
                    operator: '==',
                    value: [
                      'INTERNAL'
                    ]
                  },
                  {
                    name: 'Already Tracked Internal Tasks',
                    field: 'taskType',
                    type: 2,
                    operator: 'out',
                    value: [
                      'Send Invoice',
                      'Translation Delivery',
                      'Complete Project',
                      'Filing Delivery'
                    ]
                  },
                ],
                category: 'additional',
                custom: true
              },
              {
                name: 'PM Assigned Vendor Tasks',
                filters: [
                  {
                    name: 'Ready',
                    field: 'taskStageString',
                    type: 2,
                    operator: 'in',
                    value: ['READY_TO_START', 'IN_PROGRESS']
                  },
                  {
                    name: 'PM Assigned Vendor Tasks',
                    field: 'taskType',
                    type: 2,
                    operator: 'in',
                    value: [
                      'Quality Assurance',
                      'Combine Document'
                    ]
                  },
                  {
                    name: 'Internal Assigned',
                    field: 'assignedUserIsInternal',
                    type: 3,
                    operator: '==',
                    value: true
                  },
                ],
                category: 'additional',
                custom: true
              }
            ]
          },
          {
            id: 'filterGroupSet',
            filters: ['Active']
          }
        ]
      }, // -- End filter item.
      {
        ...filterDefault,
        id: STATIC_FILTERS.CustomerDashboardProjectCompleted,
        description: 'Customer Dashboard [PROJECT Completed] KPI Filter',
        pageType: FilterPageType.Projects,
        filterSource: [
          {
            id: 'tableHeader',
            filters: []
          },
          {
            id: 'filterButtons',
            filters: [
              {
                name: 'Completed (Last 30 Days)',
                filters: [
                  {
                    name: 'Completed',
                    field: 'orderStatus',
                    type: 2,
                    operator: '==',
                    value: 'COMPLETED'
                  },
                  {
                    name: 'Last 30 Days',
                    field: 'completionDate',
                    type: 4,
                    operator: 'between',
                    value: [
                      moment()
                        .add(-30, 'days')
                        .startOf('day')
                        .valueOf(),
                      moment()
                        .endOf('day')
                        .valueOf(),
                      true, // include null values
                    ],
                  }
                ],
                category: 'additional',
                custom: true,
              },
            ]
          },
          {
            id: 'filterGroupSet',
            filters: ['All Projects']
          }
        ],
      }, // -- End filter item.
      {
        ...filterDefault,
        id: STATIC_FILTERS.CustomerDashboardActiveProjects,
        description: 'Customer Dashboard [PROJECT Active] KPI Filter',
        pageType: FilterPageType.Projects,
        filterSource: [
          {
            id: 'tableHeader',
            filters: []
          },
          {
            id: 'filterButtons',
            filters: [
              {
                name: 'Active (Last 90 Days)',
                filters: [
                  {
                    name: 'Active and On Hold',
                    field: 'orderStatus',
                    type: 2,
                    operator: 'in',
                    value: ['ACTIVE', 'ON_HOLD'],
                  },
                  {
                    name: 'Last 90 Days',
                    field: 'completionDate',
                    type: 4,
                    operator: 'between',
                    value: [
                      moment()
                        .add(-90, 'days')
                        .startOf('day')
                        .valueOf(),
                      moment()
                        .endOf('day')
                        .valueOf(),
                      true, // include null values
                    ],
                  }
                ],
                category: 'additional',
                custom: true,
              },
            ]
          },
          {
            id: 'filterGroupSet',
            filters: ['All Projects']
          }
        ],
      }, // -- End filter item.
      {
        ...filterDefault,
        id: STATIC_FILTERS.CustomerDashboardAllProjects,
        description: 'Customer Dashboard [PROJECT All] KPI Filter',
        pageType: FilterPageType.Projects,
        filterSource: [
          {
            id: 'tableHeader',
            filters: []
          },
          {
            id: 'filterButtons',
            filters: []
          },
          {
            id: 'filterGroupSet',
            filters: ['All Projects']
          }
        ],
      }, // -- End filter item.
      {
        ...filterDefault,
        id: STATIC_FILTERS.VendorUserDashboardProjectActive,
        description: 'Vendor User Dashboard [PROJECT Active] KPI Filter',
        pageType: FilterPageType.Projects,
        filterSource: [
          {
            id: 'tableHeader',
            filters: []
          },
          {
            id: 'filterButtons',
            filters: [
              {
                name: 'Active (Last 90 Days)',
                filters: [
                  {
                    name: 'Active',
                    field: 'projectOverviewStatus',
                    type: 2,
                    operator: 'in',
                    value: ['ON_TRACK', 'OVERDUE', 'LATE', 'ON_HOLD'],
                  },
                  {
                    name: 'Last 90 Days',
                    field: 'completionDate',
                    type: 4,
                    operator: 'between',
                    value: [
                      moment()
                        .add(-90, 'days')
                        .startOf('day')
                        .valueOf(),
                      moment()
                        .endOf('day')
                        .valueOf(),
                      true, // include null values
                    ],
                  }
                ],
                category: 'additional',
                custom: true,
              },
            ]
          },
          {
            id: 'filterGroupSet',
            filters: ['Active']
          }
        ],
      }, // -- End filter item.
      {
        ...filterDefault,
        id: STATIC_FILTERS.VendorUserDashboardTaskReadyToStart,
        description: 'Vendor User Dashboard [TASK Ready to Start] KPI Filter',
        pageType: FilterPageType.Tasks,
        filterSource: [
          {
            id: 'tableHeader',
            filters: []
          },
          {
            id: 'filterButtons',
            filters: [
              {
                name: 'Ready to Start (Last 90 Days)',
                filters: [
                  {
                    name: 'Ready to Start',
                    field: 'taskStageString',
                    type: 2,
                    operator: 'in',
                    value: [
                      'READY_TO_START'
                    ]
                  },
                  {
                    name: 'Last 90 Days',
                    field: 'orderCompletionDate',
                    type: 4,
                    operator: 'between',
                    value: [
                      moment()
                        .add(-90, 'days')
                        .startOf('day')
                        .valueOf(),
                      moment()
                        .endOf('day')
                        .valueOf(),
                      true, // include null values
                    ],
                  }
                ],
                category: 'additional',
                custom: true
              }
            ]
          },
          {
            id: 'filterGroupSet',
            filters: ['Active']
          }
        ],
      }, // -- End filter item.
      {
        ...filterDefault,
        id: STATIC_FILTERS.VendorUserDashboardTaskNeedsAction,
        description: 'Vendor User Dashboard [TASK Needs Action] KPI Filter',
        pageType: FilterPageType.Tasks,
        filterSource: [
          {
            id: 'tableHeader',
            filters: []
          },
          {
            id: 'filterButtons',
            filters: [
              {
                name: 'Needs Action (Last 90 Days)',
                filters: [
                  {
                    name: 'Needs Action',
                    field: 'needsAction',
                    type: 3,
                    operator: '==',
                    value: true
                  },
                  {
                    name: 'Last 90 Days',
                    field: 'orderCompletionDate',
                    type: 4,
                    operator: 'between',
                    value: [
                      moment()
                        .add(-90, 'days')
                        .startOf('day')
                        .valueOf(),
                      moment()
                        .endOf('day')
                        .valueOf(),
                      true, // include null values
                    ],
                  }
                ],
                category: 'additional',
                custom: true,
              }
            ]
          },
          {
            id: 'filterGroupSet',
            filters: ['All Tasks']
          }
        ],
      }, // -- End filter item.
      {
        ...filterDefault,
        id: STATIC_FILTERS.VendorUserDashboardTaskOverdue,
        description: 'Vendor User Dashboard [TASK Overdue] KPI Filter',
        pageType: FilterPageType.Tasks,
        filterSource: [
          {
            id: 'tableHeader',
            filters: []
          },
          {
            id: 'filterButtons',
            filters: [
              {
                name: 'Overdue (Last 90 Days)',
                filters: [
                  {
                    name: 'Overdue',
                    field: 'status',
                    type: 2,
                    operator: '==',
                    value: 'OVERDUE'
                  },
                  {
                    name: 'Last 90 Days',
                    field: 'orderCompletionDate',
                    type: 4,
                    operator: 'between',
                    value: [
                      moment()
                        .add(-90, 'days')
                        .startOf('day')
                        .valueOf(),
                      moment()
                        .endOf('day')
                        .valueOf(),
                      true, // include null values
                    ],
                  }
                ],
                category: 'additional',
                custom: true,
              }
            ]
          },
          {
            id: 'filterGroupSet',
            filters: ['Active']
          }
        ],
      }, // -- End filter item.
      {
        ...filterDefault,
        id: STATIC_FILTERS.VendorUserDashboardProjectCompleted,
        description: 'Vendor User Dashboard [PROJECT Completed] KPI Filter',
        pageType: FilterPageType.Projects,
        filterSource: [
          {
            id: 'tableHeader',
            filters: []
          },
          {
            id: 'filterButtons',
            filters: [
              {
                name: 'Completed (Last 90 Days)',
                filters: [
                  {
                    name: 'Completed',
                    field: 'projectOverviewStatus',
                    type: 2,
                    operator: '==',
                    value: 'COMPLETED'
                  },
                  {
                    name: 'Last 90 Days',
                    field: 'completionDate',
                    type: 4,
                    operator: 'between',
                    value: [
                      moment()
                        .add(-90, 'days')
                        .startOf('day')
                        .valueOf(),
                      moment()
                        .endOf('day')
                        .valueOf(),
                      true, // include null values
                    ],
                  },
                ],
                category: 'additional',
                custom: true,
              }
            ]
          },
          {
            id: 'filterGroupSet',
            filters: ['All Tasks']
          }
        ],
      }, // -- End filter item.
      {
        ...filterDefault,
        id: STATIC_FILTERS.AccountSalesDashboardExpiredEstimate,
        description: 'AM Dashboard [ESTIMATE Expired] KPI Filter',
        pageType: FilterPageType.Estimate,
        filterSource: [
          {
            id: 'tableHeader',
            filters: [],
          },
          {
            id: 'filterButtons',
            filters: [
              {
                name: 'Expired',
                filters: [
                  {
                    name: 'Expired',
                    field: 'projectEstimateStatus',
                    type: 2,
                    operator: '==',
                    value: 'EXPIRED'
                  }
                ],
                category: 'status'
              },
              {
                name: 'No Expiry',
                filters: [
                  {
                    name: 'No Expiry',
                    field: 'expirationDate',
                    type: 3,
                    operator: 'isnull',
                    value: true
                  }
                ],
                category: 'additional'
              },
              {
                name: 'Expired 30 Days ago',
                filters: [
                  {
                    name: 'Expired 30 Days ago',
                    field: 'expirationDate',
                    type: 4,
                    operator: 'between',
                    value: [
                      moment()
                        .add(-30, 'days')
                        .startOf('day')
                        .valueOf(),
                      moment()
                        .endOf('day')
                        .valueOf(),
                    ],
                  }
                ],
                category: 'additional'
              },
            ]
          }
        ],
      }, // -- End filter item.
      {
        ...filterDefault,
        id: STATIC_FILTERS.AccountSalesDashboardApprovedEstimate,
        description: 'AM Dashboard [PROJECT Approved] KPI Filter',
        pageType: FilterPageType.Projects,
        filterSource: [
          {
            id: 'tableHeader',
            filters: []
          },
          {
            id: 'filterButtons',
            filters: [
              {
                name: 'Approved 30 Days ago',
                filters: [
                  {
                    name: 'Approved 30 Days ago',
                    field: 'projectApproved',
                    type: 4,
                    operator: 'between',
                    value: [
                      moment()
                        .add(-30, 'days')
                        .startOf('day')
                        .valueOf(),
                      moment()
                        .endOf('day')
                        .valueOf(),
                    ],
                  }
                ],
                category: 'additional'
              },
            ]
          },
          {
            id: 'filterGroupSet',
            filters: ['All Projects']
          }
        ],
      }, // -- End filter item.
      {
        ...filterDefault,
        id: STATIC_FILTERS.AccountSalesDashboardProjectActive,
        description: 'AM Dashboard [PROJECT ACTIVE] KPI Filter',
        pageType: FilterPageType.Projects,
        filterSource: [
          {
            id: 'tableHeader',
            filters: []
          },
          {
            id: 'filterButtons',
            filters: [
              {
                name: 'Active',
                filters: [
                  {
                    name: 'Active',
                    field: 'orderStatus',
                    type: 2,
                    operator: '==',
                    value: 'ACTIVE'
                  }
                ],
                category: 'status'
              },
              {
                name: 'On Hold',
                filters: [
                  {
                    name: 'On Hold',
                    field: 'orderStatus',
                    type: 2,
                    operator: '==',
                    value: 'ON_HOLD'
                  }
                ],
                category: 'status'
              }
            ]
          },
          {
            id: 'filterGroupSet',
            filters: ['Active']
          }
        ],
      }, // -- End filter item.
      {
        ...filterDefault,
        id: STATIC_FILTERS.VendorManagerDashboardTasksUnassigned,
        description: 'Vendor Manager Dashboard [TASK Unassigned] KPI Filter',
        pageType: FilterPageType.Tasks,
        filterSource: [
          {
            id: 'tableHeader',
            filters: []
          },
          {
            id: 'filterButtons',
            filters: [
              {
                name: 'Unassigned (Last 90 Days)',
                filters: [
                  {
                    name: 'Unassigned',
                    field: 'assigneeUserName',
                    type: 3,
                    operator: 'isnull',
                    value: true
                  },
                  {
                    name: 'Not COMPLETED',
                    field: 'taskStageString',
                    type: 2,
                    operator: '!=',
                    value: 'COMPLETE'
                  },
                  {
                    name: 'CANCELLATION REQUEST',
                    field: 'taskStageString',
                    type: 2,
                    operator: '!=',
                    value: 'CANCELLATION_REQUEST'
                  },
                  {
                    name: 'CANCELLED',
                    field: 'taskStageString',
                    type: 2,
                    operator: '!=',
                    value: 'CANCELLED'
                  },
                  {
                    name: 'SKIPPED',
                    field: 'taskStageString',
                    type: 2,
                    operator: '!=',
                    value: 'SKIPPED'
                  },
                  {
                    name: 'ON_HOLD',
                    field: 'taskStageString',
                    type: 2,
                    operator: '!=',
                    value: 'ON_HOLD'
                  },
                  {
                    name: 'Not REASSIGNED',
                    field: 'taskStageString',
                    type: 2,
                    operator: '!=',
                    value: 'REASSIGNED'
                  },
                  {
                    name: 'Last 90 Days',
                    field: 'orderCompletionDate',
                    type: 4,
                    operator: 'between',
                    value: [
                      moment()
                        .add(-90, 'days')
                        .startOf('day')
                        .valueOf(),
                      moment()
                        .endOf('day')
                        .valueOf(),
                      true, // include null values
                    ],
                  },
                ],
                category: 'additional',
                custom: true
              }
            ]
          },
          {
            id: 'filterGroupSet',
            filters: ['Active']
          }
        ]
      }, // -- End filter item.
      {
        ...filterDefault,
        id: STATIC_FILTERS.VendorManagerDashboardTasksNeedAction,
        description: 'Vendor Manager Dashboard [TASK Need Action] KPI Filter',
        pageType: FilterPageType.Tasks,
        filterSource: [
          {
            id: 'tableHeader',
            filters: []
          },
          {
            id: 'filterButtons',
            filters: [
              {
                name: 'Needs Action (Last 90 Days)',
                filters: [
                  {
                    name: 'Needs Action',
                    field: 'needsAction',
                    type: 3,
                    operator: '==',
                    value: true
                  },
                  {
                    name: 'Last 90 Days',
                    field: 'orderCompletionDate',
                    type: 4,
                    operator: 'between',
                    value: [
                      moment()
                        .add(-90, 'days')
                        .startOf('day')
                        .valueOf(),
                      moment()
                        .endOf('day')
                        .valueOf(),
                      true, // include null values
                    ],
                  },
                ],
                category: 'additional',
                custom: true,
              }
            ]
          },
          {
            id: 'filterGroupSet',
            filters: ['All Tasks']
          }
        ]
      }, // -- End filter item.
      {
        ...filterDefault,
        id: STATIC_FILTERS.VendorManagerDashboardTasksReadyToStart,
        description: 'Vendor Manager Dashboard [TASK Ready to Start] KPI Filter',
        pageType: FilterPageType.Tasks,
        filterSource: [
          {
            id: 'tableHeader',
            filters: []
          },
          {
            id: 'filterButtons',
            filters: [
              {
                name: 'Ready to Start (Last 90 Days)',
                filters: [
                  {
                    name: 'Ready to Start',
                    field: 'taskStageString',
                    type: 2,
                    operator: 'in',
                    value: [
                      'READY_TO_START'
                    ]
                  },
                  {
                    name: 'Last 90 Days',
                    field: 'orderCompletionDate',
                    type: 4,
                    operator: 'between',
                    value: [
                      moment()
                        .add(-90, 'days')
                        .startOf('day')
                        .valueOf(),
                      moment()
                        .endOf('day')
                        .valueOf(),
                      true, // include null values
                    ],
                  },
                ],
                category: 'additional',
                custom: true
              }
            ]
          },
          {
            id: 'filterGroupSet',
            filters: ['Active']
          }
        ]
      }, // -- End filter item.
      {
        ...filterDefault,
        id: STATIC_FILTERS.VendorManagerDashboardTasksOverdue,
        description: 'Vendor Manager Dashboard [TASK Overdue] KPI Filter',
        pageType: FilterPageType.Tasks,
        filterSource: [
          {
            id: 'tableHeader',
            filters: []
          },
          {
            id: 'filterButtons',
            filters: [
              {
                name: 'Overdue (Last 90 Days)',
                filters: [
                  {
                    name: 'Overdue',
                    field: 'status',
                    type: 2,
                    operator: '==',
                    value: 'OVERDUE'
                  },
                  {
                    name: 'Last 90 Days',
                    field: 'orderCompletionDate',
                    type: 4,
                    operator: 'between',
                    value: [
                      moment()
                        .add(-90, 'days')
                        .startOf('day')
                        .valueOf(),
                      moment()
                        .endOf('day')
                        .valueOf(),
                      true, // include null values
                    ],
                  },
                ],
                category: 'additional',
                custom: true,
              }
            ]
          },
          {
            id: 'filterGroupSet',
            filters: ['Active']
          }
        ]
      }, // -- End filter item.
      {
        ...filterDefault,
        id: STATIC_FILTERS.VendorManagerDashboardProjectsActive,
        description: 'Vendor Manager Dashboard [PROJECT Active] KPI Filter',
        pageType: FilterPageType.Projects,
        filterSource: [
          {
            id: 'tableHeader',
            filters: []
          },
          {
            id: 'filterButtons',
            filters: [
              {
                name: 'Active (Last 90 Days)',
                filters: [
                  {
                    name: 'On Track',
                    field: 'projectOverviewStatus',
                    type: 2,
                    operator: 'in',
                    value: ['ON_TRACK', 'OVERDUE', 'LATE', 'ON_HOLD']
                  },
                  {
                    name: 'Last 90 Days',
                    field: 'completionDate',
                    type: 4,
                    operator: 'between',
                    value: [
                      moment()
                        .add(-90, 'days')
                        .startOf('day')
                        .valueOf(),
                      moment()
                        .endOf('day')
                        .valueOf(),
                      true, // include null values
                    ],
                  },
                ],
                category: 'additional',
                custom: true,
              },
            ]
          },
          {
            id: 'filterGroupSet',
            filters: ['Active']
          }
        ]
      }, // -- End filter item.
      {
        ...filterDefault,
        id: STATIC_FILTERS.VendorManagerDashboardProjectsNeedAction,
        description: 'Vendor Manager Dashboard [PROJECT Need Action] KPI Filter',
        pageType: FilterPageType.Projects,
        filterSource: [
          {
            id: 'tableHeader',
            filters: []
          },
          {
            id: 'filterButtons',
            filters: [
              {
                name: 'Needs Action (Last 90 Days)',
                filters: [
                  {
                    name: 'Needs Action',
                    field: 'needsAction',
                    type: 3,
                    operator: '==',
                    value: true
                  },
                  {
                    name: 'Last 90 Days',
                    field: 'completionDate',
                    type: 4,
                    operator: 'between',
                    value: [
                      moment()
                        .add(-90, 'days')
                        .startOf('day')
                        .valueOf(),
                      moment()
                        .endOf('day')
                        .valueOf(),
                      true, // include null values
                    ],
                  },
                ],
                category: 'additional',
                custom: true,
              }
            ]
          },
          {
            id: 'filterGroupSet',
            filters: ['All Projects']
          }
        ]
      }, // -- End filter item.
      {
        ...filterDefault,
        id: STATIC_FILTERS.CustomerDashboardV2EstimatesActive,
        description: 'Customer Dashboard v2 [Estimates Active] KPI Filter',
        pageType: FilterPageType.Estimate,
        filterSource: [
          {
            id: 'tableHeader',
            filters: [],
          },
          {
            id: 'filterButtons',
            filters: [
              {
                name: 'Active (Last 90 Days)',
                filters: [
                  {
                    name: 'Ready',
                    field: 'projectEstimateStatus',
                    type: 2,
                    operator: 'in',
                    value: ['READY_FOR_APPROVAL', 'IN_PROGRESS'],
                  },
                  {
                    name: 'Last 90 Days',
                    field: 'submittedDate',
                    type: 4,
                    operator: 'between',
                    value: [
                      moment()
                        .add(-90, 'days')
                        .startOf('day')
                        .valueOf(),
                      moment()
                        .endOf('day')
                        .valueOf(),
                      true, // include null values
                    ],
                  },
                ],
                category: 'additional',
                custom: true,
              },
            ],
          },
        ],
      }, // -- End filter item.
      {
        ...filterDefault,
        id: STATIC_FILTERS.CustomerDashboardV2EstimatesRecentlyExpired,
        description: 'Customer Dashboard v2 [Estimates Recently Expired] KPI Filter',
        pageType: FilterPageType.Estimate,
        filterSource: [
          {
            id: 'tableHeader',
            filters: [
              {
                field: 'expirationDate',
                operator: 'between',
                value: [
                  moment()
                    .subtract(90, 'days')
                    .startOf('day')
                    .valueOf(),
                  moment()
                    .endOf('day')
                    .valueOf(),
                ],
                type: 4,
              },
            ],
          },
          {
            id: 'filterButtons',
            filters: [
              {
                name: 'Expired',
                filters: [
                  {
                    name: 'Expired',
                    field: 'projectEstimateStatus',
                    type: 2,
                    operator: '==',
                    value: 'EXPIRED',
                  },
                ],
                category: 'status',
              },
            ],
          },
        ],
      }, // -- End filter item.
      {
        ...filterDefault,
        id: STATIC_FILTERS.SalesAccountManagerDashboardV2EstimatesNeedSunIPAction,
        description: 'Sales/Account Manager Dashboard [ESTIMATES Need Sun IP Action] KPI Filter',
        pageType: FilterPageType.Estimate,
        filterSource: [
          {
            id: 'tableHeader',
            filters: []
          },
          {
            id: 'filterButtons',
            filters: [
              {
                name: 'In Progress',
                filters: [
                  {
                    name: 'In Progress',
                    field: 'projectEstimateStatus',
                    type: 2,
                    operator: '==',
                    value: 'IN_PROGRESS'
                  }
                ],
                category: 'status'
              }
            ]
          }
        ]
      }, // -- End filter item.
      {
        ...filterDefault,
        id: STATIC_FILTERS.SalesAccountManagerDashboardV2EstimatesReadyForApproval,
        description: 'Sales/Account Manager Dashboard [ESTIMATES Ready for Approval] KPI Filter',
        pageType: FilterPageType.Estimate,
        filterSource: [
          {
            id: 'tableHeader',
            filters: []
          },
          {
            id: 'filterButtons',
            filters: [
              {
                name: 'Ready',
                filters: [
                  {
                    name: 'Ready',
                    field: 'projectEstimateStatus',
                    type: 2,
                    operator: '==',
                    value: 'READY_FOR_APPROVAL'
                  }
                ],
                category: 'status',
              }
            ]
          }
        ]
      }, // -- End filter item.
      {
        ...filterDefault,
        id: STATIC_FILTERS.SalesAccountManagerDashboardV2EstimatesUpcomingExpiration,
        description: 'Sales/Account Manager Dashboard v2 [ESTIMATES Upcoming Expiration] KPI Filter',
        pageType: FilterPageType.Estimate,
        filterSource: [
          {
            id: 'tableHeader',
            filters: [
              {
                field: 'expirationDate',
                operator: 'between',
                value: [
                  moment()
                    .add(1, 'days')
                    .startOf('day')
                    .valueOf(),
                  moment()
                    .add(15, 'days')
                    .endOf('day')
                    .valueOf(),
                ],
                type: 4,
              },
            ],
          },
          {
            id: 'filterButtons',
          },
        ],
      }, // -- End filter item.
      {
        ...filterDefault,
        id: STATIC_FILTERS.CustomerDashboardPendingSunIpAction,
        description: 'Customer Dashboard v2 [Estimates Pending SunIP Action]',
        pageType: FilterPageType.Estimate,
        filterSource: [
          {
            id: 'tableHeader',
            filters: [],
          },
          {
            id: 'filterButtons',
            filters: [
              {
                name: 'In Progress',
                filters: [
                  {
                    name: 'In Progress',
                    field: 'projectEstimateStatus',
                    type: 2,
                    operator: '==',
                    value: 'IN_PROGRESS',
                  },
                ],
                category: 'status',
              },
            ],
          },
        ],
      }, // -- End filter item.
      {
        ...filterDefault,
        id: STATIC_FILTERS.CustomerDashboardV2PendingApproval,
        description: 'Customer Dashboard v2 [Estimates Pending Approval]',
        pageType: FilterPageType.Estimate,
        filterSource: [
          {
            id: 'tableHeader',
            filters: [],
          },
          {
            id: 'filterButtons',
            filters: [
              {
                name: 'Ready',
                filters: [
                  {
                    name: 'Ready',
                    field: 'projectEstimateStatus',
                    type: 2,
                    operator: '==',
                    value: 'READY_FOR_APPROVAL',
                  },
                ],
                category: 'status',
              },
            ],
          },
        ],
      }, // -- End filter item.
    ];
    // // -- End of filters array.
  }

  private staticFilterExists(id: number): boolean {
    return !isNullOrUndefined(this.staticFilters.find(filter => filter.id === id));
  }
}
