import { Pipe, PipeTransform } from '@angular/core';
import { FilterGroup } from '../filter';
import { DataFilter } from '../data-filter';

@Pipe({
  name: 'dataFilter',
  pure: true
})
export class DataFilterPipe implements PipeTransform {

  public transform(value: any[], filters?: FilterGroup[]): any[] {
    return new DataFilter(value, filters).getValues();
  }
}
