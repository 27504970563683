import { Injectable } from '@angular/core';
import { ConfigService } from '../../config.service';
import { ConfigDataStoreService } from './config-data-store.service';
import { LanguageSpecializationConfig } from '../../../shared/dto/system-config/language-specialization-config';
import { UserService } from '../../../shared/services/user.service';
import { SystemConfigDTO } from '../../../shared/dto/system-config/system-config-dto';
import { SubConfigValue } from '../../../shared/dto/system-config/sub-config-value';
import { SystemConfigResponseDTO } from '../../../shared/dto/system-config/system-config-response-dto';

@Injectable()
export class LanguageSpecializationSubtypeDataStoreService extends ConfigDataStoreService<LanguageSpecializationConfig> {

  constructor(configService: ConfigService, userService: UserService) {
    super(configService, userService, 'LANGUAGE_SPECIALIZATION_SUBTYPE');
  }

  public create(data: SubConfigValue): Promise<SystemConfigResponseDTO> {
    const configObject: SystemConfigDTO = { userId: this.user.id, type: this.type, subValueDTO: data };
    const response = this.configService.addSystemConfig(configObject);
    response.then(res => {
      this.dataSetPromise.then(dataSet => {
        dataSet.push(res.configValueDTO as LanguageSpecializationConfig);
        this.dataSet.next(dataSet);
        this.dataSetPromise = Promise.resolve(dataSet);
      });
    });

    return response;
  }

  public update(data: SubConfigValue): Promise<SystemConfigResponseDTO> {
    const configObject: SystemConfigDTO = { userId: this.user.id, type: this.type, subValueDTO: data };
    const response = this.configService.updateSystemConfig(configObject);
    response.then(res => {
      this.dataSetPromise.then(dataSet => {
        const index = dataSet.findIndex(dataPoint => dataPoint.id === res.configValueDTO.id);
        dataSet[index] = res.configValueDTO as LanguageSpecializationConfig;
        this.dataSet.next(dataSet);
        this.dataSetPromise = Promise.resolve(dataSet);
      });
    });

    return response;
  }
}
