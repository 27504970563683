import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import * as includes from 'lodash/includes';

// APP CONTEXT SERVICE
// ANYTHING REGARDING STYLE and BRANDING is controlled by AppContextService

const STORE_CONTEXT = {
  Forecaster: 'forecaster',
  Sunip: 'sunip',
  Legalsight: 'legalsight'
};

@Injectable()
export class AppContextService {

  public isForecaster: boolean;

  public isSunip: boolean;

  public isLegalsight: boolean;

  public supportContact: string;

  public brandLogo: string;

  public fullLogoColor: string;

  private currentIcon: string;

  private readonly forecasterContext: string[];

  private readonly hostname: string;

  private readonly storeName = 'townip_context';

  private readonly iconCacheId = Math.round(Math.random() * 65535);

  constructor(private titleService: Title) {
    this.hostname = window.location.hostname;
    this.isForecaster = false;
    this.isSunip = false;
    this.isLegalsight = false;
    this.brandLogo = '';
    this.fullLogoColor = '';
    this.updateFavicon('assets/blank-icon.ico');

    // For debugging forecaster context on localhost, uncomment localhost
    // Forecaster host domains (including port) needs to be added here or forecaster will see SUNIP until they login
    this.forecasterContext = [
      // 'localhost',
      'chiefip.legalsight.com', // Forecaster prod
      'chiefip.townip.com', // Forecaster dev
    ];

    this.checkContext();
  }

  /**
   * Force context to CheifIP.
   */
  public setForecastContext(): void {
    if (this.isLegalsightContext()) {
      return;
    }

    this.isForecaster = true;
    this.isSunip = false;
    this.setForecastOptions();
  }

  public setForecastOptions(): void {
    this.titleService.setTitle('ChiefIP');
    this.brandLogo = 'assets/images/logos/chiefip/chiefip-logo-white.svg';
    this.fullLogoColor = 'assets/images/logos/chiefip/chiefip-full-logo.png';
    this.updateFavicon('assets/chiefip.ico');
    window.localStorage.setItem(this.storeName, STORE_CONTEXT.Forecaster);
    this.supportContact = 'support@chiefip.com';
  }

  /**
   * Force context to SunIP
   */
  public setSunipContext(): void {
    if (this.isLegalsightContext()) {
      return;
    }

    this.isSunip = true;
    this.isForecaster = false;
    this.setSunipOptions();
  }

  public setSunipOptions(): void {
    this.titleService.setTitle('SunIP');
    this.brandLogo = 'assets/images/logos/logo-white.svg';
    this.fullLogoColor = 'assets/images/logos/full-logo.png';
    this.updateFavicon('assets/sunip-icon.ico');
    window.localStorage.setItem(this.storeName, STORE_CONTEXT.Sunip);
    this.supportContact = 'support@sunip.com';
  }

  public setLegalsightContext(): void {
    this.isSunip = false;
    this.isForecaster = false;
    this.isLegalsight = true;

    this.setLegalsightOptions();
  }

  public setLegalsightOptions(): void {
    this.titleService.setTitle('LegalSight');
    this.brandLogo = 'assets/images/logos/legalsight/legalsight-logo-white.svg';
    this.fullLogoColor = 'assets/images/logos/legalsight/legalsight-full-logo.svg';
    this.updateFavicon('assets/legalsight-icon.ico');
    window.localStorage.setItem(this.storeName, STORE_CONTEXT.Legalsight);
  }

  public resetContext(): void {
    window.localStorage.removeItem(this.storeName);
    this.checkContext();
  }

  public isAccountPortal(): boolean {
    const portalDomains = [
      'account.legalsight.com',
      'account.townip.com'
    ];

    return includes(portalDomains, window.location.hostname);
  }

  private checkContext(): void {
    this.isForecaster = false;
    this.isSunip = true;
    this.isLegalsight = false;

    const lastContext = this.loadlastContext();
    if (lastContext === STORE_CONTEXT.Forecaster) {
      this.setForecastContext();
      return;
    }

    if (lastContext === STORE_CONTEXT.Sunip) {
      this.setSunipContext();
      return;
    }

    // Account.legalsight.com overrides all context. When visited in this domain,
    // Always display legalsight
    if (this.isLegalsightContext()) {
      this.isLegalsight = true;
      this.setLegalsightContext();
      return;
    }

    for (const context of this.forecasterContext) {
      if (new RegExp(context, 'ig').test(this.hostname)) {
        this.isForecaster = true;
        this.isSunip = false;
        break;
      }
    }

    if (this.isForecaster) {
      this.setForecastOptions();
    }

    if (this.isSunip) {
      this.setSunipOptions();
    }
  }

  private updateFavicon(path: string): void {
    // If they are the same, no need to switch.
    if (this.currentIcon === path) {
      return;
    }

    try {
      const shortcutIcon = document.querySelector('link[rel*="shortcut icon"]') as HTMLElement;
      shortcutIcon.setAttribute('href', path + '?ver=' + this.iconCacheId);
      this.currentIcon = path;
    } catch (e) {
      // In case it fails
    }
  }

  private loadlastContext(): string {
    const context = window.localStorage.getItem(this.storeName);
    if (context === STORE_CONTEXT.Sunip || context === STORE_CONTEXT.Forecaster) {
      return context;
    }

    return null;
  }

  private isLegalsightContext(): boolean {
    return new RegExp('account.legalsight.com', 'ig').test(this.hostname) ||
      new RegExp('account.townip.com', 'ig').test(this.hostname);
    // return new RegExp('localhost', 'ig').test(this.hostname);
  }
}
