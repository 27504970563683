import * as _ from 'underscore';
import { Component, forwardRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CompanyGroupLite } from '../../dto/company-group-lite';
import { isNullOrUndefined } from '../../utils/is-null-or-undefined';

export interface CheckListItem {
  label: string;
  value: any;
  group?: string;
  associatedGroups?: CompanyGroupLite[];
  groupLabel?: string;
  subGroup?: string; // Optional sub-groups. We only support up to 1 level deep
  subGroupLabel?: string;
}

interface InternalItem extends CheckListItem {
  id: number;
}

interface ItemGroup {
  id: number;
  group: string;
  label: string;
  parent: string;
  selectAll: boolean;
}

interface AccordionGroupStatus {
  [id: string]: boolean;
}

@Component({
  selector: 'townip-check-list',
  templateUrl: './check-list.component.html',
  styleUrls: ['./check-list.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CheckListComponent),
    multi: true,
  }]
})
export class CheckListComponent implements OnInit, OnChanges, ControlValueAccessor {

  @Input()
  public items: any[];

  @Input()
  public filter = false; // Use the internal-built in filter

  @Input()
  public showAssociatedGroups = false;

  @Input()
  public group = false;

  @Input()
  public disabled = false; // Not implemented at the moment

  @Input()
  public maxHeight = null;

  @Input()
  public multi = false;

  @Input()
  public externalFilter: string; // Use a string to filter values externally

  @Input()
  public groupSelect = false;

  public selectedInternal: any = {};

  public internalItems: InternalItem[];

  public selected: any[];

  public onChange;

  public onTouched;

  public queryFilter: string;

  public accordionGroupStatus: AccordionGroupStatus;

  public accordionGroups: ItemGroup[] = [];

  public accordionSubGroups: ItemGroup[] = [];

  constructor() { }

  public ngOnInit(): void {
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.items || changes.group) {
      this.updateItems();
    }

    if (changes.externalFilter) {
      this.queryFilter = this.externalFilter;
    }
  }

  private updateItems(): void {
    this.selectedInternal = {};
    this.internalItems = [];
    for (const item of this.items) {
      const id = this.genId();
      this.internalItems.push({
        id: id,
        label: item.label,
        value: item.value,
        group: item.group,
        associatedGroups: item.associatedGroups,
        groupLabel: item.groupLabel,
        subGroup: item.subGroup,
        subGroupLabel: item.subGroupLabel,
      });

      // Un-check
      this.selectedInternal[id] = { selected: false, value: item.value, group: item.group };
    }

    if (this.group) {
      this.buildGroups();
    }
  }

  private buildGroups(): void {
    const groups = [] as ItemGroup[];
    for (const item of this.items) {
      const groupExists = _.findWhere(groups, { group: item.group });
      if (!isNullOrUndefined(item.group) || item.group === '') {
        if (!groupExists) {
          groups.push({ group: item.group, label: item.groupLabel, id: this.genId(), parent: null, selectAll: false });
        }
      }
    }

    this.accordionGroups = groups;
    this.accordionGroupStatus = {};
    for (const group of groups) {
      this.accordionGroupStatus[group.id] = false;
    }

    this.buildSubGroups();
  }

  private buildSubGroups(): void {
    const groups = [] as ItemGroup[];
    for (const item of this.items) {
      const groupExists = _.findWhere(groups, { group: item.subGroup });
      if (!isNullOrUndefined(item.subGroup) || item.subGroup === '') {
        if (!groupExists) {
          groups.push({
            group: item.subGroup,
            label: item.subGroupLabel,
            id: this.genId(),
            parent: item.group,
            selectAll: false
          });
        }
      }
    }

    this.accordionSubGroups = groups;
    for (const group of groups) {
      this.accordionGroupStatus[group.id] = false;
    }
  }

  private genId(): number {
    return Math.ceil(Math.random() * 100000000);
  }

  public writeValue(selected: any[]): void {
    this.selected = selected;
    this.propagateSelectionValues();
  }

  public registerOnChange(func: any): void {
    this.onChange = func;
  }

  public registerOnTouched(func: any): void {
    this.onTouched = func;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public updateGroupValues(group: ItemGroup): void {

    // fix to prevent closing of group accordion
    this.accordionGroupStatus[group.id] = !this.accordionGroupStatus[group.id];
    if (isNullOrUndefined(group.selectAll)) {
      group.selectAll = true;
    }

    this.selected = Object.keys(this.selectedInternal)
      .filter(prop => {
        const internal = this.selectedInternal[prop];
        if (internal.group === group.group) {
          internal.selected = group.selectAll;
        }
        return internal.selected;
      })
      .map(prop => this.selectedInternal[prop].value);

    this.onChange(this.selected);
  }

  public getGroupSelectValue(group: ItemGroup): void {
    if (isNullOrUndefined(group) || isNullOrUndefined(this.selectedInternal)) {
      return;
    }
    const groupItems = Object
      .keys(this.selectedInternal)
      .map(key => this.selectedInternal[key])
      .filter(item => item.group === group.group);
    const selected = groupItems.filter(item => item.selected).length;
    group.selectAll = selected < groupItems.length ? selected ? null : false : true;
  }

  public updateSelectedValues(item?: InternalItem): void {
    // If multi-select is disabled, only select the latest one we checked.
    // Uncheck everything else.
    if (this.multi === false) {
      Object.keys(this.selectedInternal)
        .forEach(prop => {
          if (prop !== item.id.toString()) {
            this.selectedInternal[prop].selected = false;
          }
        });
    }

    this.getGroupSelectValue(this.accordionGroups.find(group => group.group === item.group));

    this.selected = Object
      .keys(this.selectedInternal)
      .map(key => this.selectedInternal[key])
      .filter(internalItem => internalItem.selected)
      .map(internalItem => internalItem.value);

    this.onChange(this.selected);
  }

  /**
   * Updates the selected values when the modal has been changed externally
   */
  public propagateSelectionValues(): void {
    Object.keys(this.selectedInternal)
      .forEach(prop => {
        if (this.selectedInternal[prop].value) {
          const val = this.selectedInternal[prop].value;
          this.selectedInternal[prop].selected = _.contains(this.selected, val);
        }
      });
    this.accordionGroups.forEach(group => this.getGroupSelectValue(group));
  }
}
