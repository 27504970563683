import { Component, OnInit } from '@angular/core';
import { UserDisabledStatus } from '../../security/user-disabled-status';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'townip-error-system-offline',
  templateUrl: './error-system-offline.component.html',
  styleUrls: ['./error-system-offline.component.scss']
})
export class ErrorSystemOfflineComponent implements OnInit {

  public disabledStatus: UserDisabledStatus;

  constructor(private route: ActivatedRoute) { }

  public ngOnInit(): void {
    this.route.queryParams.subscribe(() => {
      this.disabledStatus = this.route.snapshot.queryParamMap.get('disabled') as UserDisabledStatus;
    });
  }

  public retry(): void {
    // We need to do a hard redirect (refresh whole app)
    window.location.href = '/';
  }

}
