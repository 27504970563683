import { Pipe, PipeTransform } from '@angular/core';
import { isNullOrUndefinedOrBlank } from '../utils/is-null-or-undefined-or-blank';
import * as striptags from 'striptags';

/**
 * This pipe will convert html string to a plain text
 */
@Pipe({
  name: 'htmlToText'
})
export class HtmlToTextPipe implements PipeTransform {

  public transform(text: string): string {
    const replacement = ' ';

    // Replace tags with spaces. We trim and filter later
    // Use striptags utility to replace html tags
    // We will not strip the style tag from here; we also need to remove its content
    let parsedText = striptags(text, ['style'], replacement);

    // Replace any new line (in case this comes from a normal multiline textarea box)
    parsedText = parsedText.replace(/(?:\r\n|\r|\n)/g, replacement);

    // Replace the style tag and the content of it to prevent from being displayed
    parsedText = parsedText.replace(/(<style[\w\W]+style>)/g, replacement);

    // Convert the text to array of words
    // We will remove words that are invalid
    // Combine the words into string
    parsedText = parsedText
      .split(replacement)
      .filter((word: string) => !isNullOrUndefinedOrBlank(word.trim()))
      // Added an initial value '' on reduce
      // This is to prevent the error just in case the length of the array is zero after filtering
      // Ex. <p>                  </p> That example can occur when sending a white space only message
      .reduce((combined: string, word: string) => combined += ` ${word}`, '')
      .trim();

    return parsedText;
  }

}
