import * as _ from 'underscore';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, } from '@angular/core';
import { FilterGroup } from '../filter';
import { ActivatedRoute } from '@angular/router';
import { FilterFormOptions } from '../../components/filter-form/filter-form';
import { FilterSelectionMemoryService } from './filter-selection-memory.service';
import { isNullOrUndefined } from '../../utils/is-null-or-undefined';

@Component({
  selector: 'townip-filter-selection',
  templateUrl: './filter-selection.component.html',
  styleUrls: ['./filter-selection.component.scss']
})
export class FilterSelectionComponent implements OnInit, OnChanges {

  @Input()
  public filters: FilterGroup[]; // Available filters for selection

  @Input()
  public customFilterOptions: FilterFormOptions[];

  @Input()
  public disabledFilters = {}; // A dictionary of filter names that are disabled

  @Input()
  public datastoreId: string; // The index name of the filters that we saved and can be restored

  @Input()
  public disabled = false;

  @Input()
  public filterHeaderLabel = 'Select Status';

  @Output()
  // NOTE: Only suppressing because legacy.
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  public onSelect: EventEmitter<FilterGroup> = new EventEmitter();

  @Output()
  // NOTE: Only suppressing because legacy.
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  public onRemove: EventEmitter<FilterGroup> = new EventEmitter();

  @Output()
  // NOTE: Only suppressing because legacy.
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  public onEdit: EventEmitter<FilterGroup> = new EventEmitter();

  @Output()
  // NOTE: Only suppressing because legacy.
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  public onClear: EventEmitter<boolean> = new EventEmitter();

  @Output()
  // NOTE: Only suppressing because legacy.
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  public onUpdate: EventEmitter<FilterGroup[]> = new EventEmitter();

  public activeFilters: FilterGroup[];

  @Input()
  public activeFilterOverride: FilterGroup[];

  constructor(private route: ActivatedRoute,
              private filterMemory: FilterSelectionMemoryService) {
    this.activeFilters = [];
  }

  public ngOnInit(): void {
    this.handleFilterFromUrl();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.activeFilterOverride) {
      if (changes.activeFilterOverride.currentValue) {
        this.handleFilterOverride();
      }
    }
  }

  public handleFilterOverride(): void {
    const filterMemory = this.filterMemory.load(this.datastoreId);
    // Remembered filter always takes priority until it is cleared.
    if (filterMemory.length > 0) {
      return;
    }

    if (this.activeFilterOverride && this.activeFilterOverride.length > 0) {
      this.activeFilters = [];
      this.activeFilterOverride.forEach(activeFilter => {
        const existingFilter = _.findWhere(this.filters, { name: activeFilter.name });
        if (existingFilter) {
          // this.filters.push(existingFilter);
          this.select(existingFilter);
        }
      });
    } else {
      this.activeFilters = [];
    }
  }

  public select(filter: FilterGroup, toggle: boolean = true): void {
    const activeFilter = _.findWhere(this.activeFilters, { name: filter.name });
    // If the filter already exists, remove it
    if (activeFilter) {
      if (toggle) {
        this.activeFilters = _.filter(this.activeFilters, f => f.name !== filter.name);
      }
      this.activeFilters = [...this.activeFilters];
      this.onSelect.emit(filter);
      this.sendUpdateSignal();

      return;
    } else {
      // If the filter does not exist, add it
      this.activeFilters.push(filter);
    }

    // Used for triggering the 'changed' event on the filterData pipe.
    this.activeFilters = [...this.activeFilters];
    this.onSelect.emit(filter);
    this.sendUpdateSignal();
  }

  public selectByName(name: string, toggle: boolean = true): void {
    const activeFilter = _.findWhere(this.activeFilters, { name: name });
    // If the filter already exists, remove it
    if (activeFilter) {
      if (toggle) {
        this.activeFilters = _.filter(this.activeFilters, f => f.name !== name);
      }
    } else {
      // If the filter does not exist, add it
      const filter = _.findWhere(this.filters, { name: name });
      this.activeFilters.push(filter);
      // Used for triggering the 'changed' event on the filterData pipe.
      this.activeFilters = [...this.activeFilters];
    }

    this.onSelect.emit(activeFilter);
    this.sendUpdateSignal();
  }

  public removeFromActive(filter: FilterGroup): void {
    const activeFilter = _.findWhere(this.activeFilters, { name: filter.name });
    if (activeFilter) {
      this.activeFilters = _.filter(this.activeFilters, f => f.name !== filter.name);
    }
    this.sendUpdateSignal();
  }

  public removeFromActiveByName(name: string): void {
    const activeFilter = _.findWhere(this.activeFilters, { name: name });
    if (activeFilter) {
      this.activeFilters = _.filter(this.activeFilters, f => f.name !== name);
    }
    this.sendUpdateSignal();
  }

  public remove(filter: FilterGroup): void {
    this.removeFromActive(filter);
    // Create a new set so we can lose the reference
    this.filters = _.filter(this.filters, (f) => {
      if (f.name === filter.name) {
        return false;
      }
      return true;
    });

    // Emit the removed filter
    this.onRemove.emit(filter);
    this.sendUpdateSignal();
  }

  public edit(filter: FilterGroup): void {
    this.onEdit.emit(filter);
    this.sendUpdateSignal();
  }

  public isActive(filter: FilterGroup): boolean {
    const activeFilter = _.findWhere(this.activeFilters, { name: filter.name });
    if (activeFilter) {
      return true;
    }
    return false;
  }

  public clear(): void {
    // Clears all the active filters, except the internal ones
    this.activeFilters = _.where(this.activeFilters, { category: 'internal' });
    this.onClear.emit(true);
    this.sendUpdateSignal();
  }

  public addCustomFilter(filter: FilterGroup): void {
    // We use the previous name as a handle since we don't keep unique ids
    if (filter.prevName && filter.prevName !== '') {
      const currentFilter = _.findWhere(this.filters, { name: filter.prevName });
      if (currentFilter) {
        // Make sure first to deactivate the filter
        this.removeFromActive(currentFilter);
        currentFilter.name = filter.name;
        currentFilter.filters = filter.filters;
      }
    } else {
      this.filters.push(filter);
    }

    this.sendUpdateSignal();
  }

  /**
   * Loads filtering information in the url.
   * Filters from the URL ALWAYS TAKES PRIORITY over the ones saved in memory
   */
  public handleFilterFromUrl(): void {
    const param = this.route.snapshot.queryParams.filter;
    // Load from url
    if (param && param !== '') {
      const filter = JSON.parse(param) as FilterGroup;

      // Clear the filters saved in memory first
      this.clearActiveFiltersFromMemory();

      if (filter.override) {
        return;
      }
      // Search for an existing filter with the same name.
      // If such exist, use that regardless if the filter from url has different options
      const existingFilter = _.findWhere(this.filters, { name: filter.name });
      if (existingFilter) {
        // this.filters.push(existingFilter);
        this.select(existingFilter);
      } else {
        // Url filters always go to the 'additional' filter slot
        filter.category = 'additional';
        filter.desc = 'url'; // We'll use this later to exclude saving url filters in memory

        // Now if this is a different filter, use the parsed one from the url
        // Make sure there is actual loaded filter from the url before adding.
        if (this.filters) {
          this.filters.push(filter);
          this.select(filter);
        }
      }
    } else {
      // Load from memory
      this.loadActiveFiltersFromMemory();
    }
  }

  private loadActiveFiltersFromMemory(): void {
    if (!isNullOrUndefined(this.datastoreId) && this.datastoreId !== '') {
      const savedFilters = this.filterMemory.load(this.datastoreId);
      if (savedFilters.length > 0) {
        // Only update if there are actual filters saved
        this.activeFilters = savedFilters; // test
      }
    }
  }

  private saveActiveFiltersToMemory(): void {
    if (!isNullOrUndefined(this.datastoreId) && this.datastoreId !== '') {
      // NOTE: We exclude filters that came from the URL as these are transient
      const filtersToSave = _.filter(this.activeFilters, (filter) => filter.desc !== 'url');
      this.filterMemory.save(this.datastoreId, filtersToSave);
    }
  }

  private clearActiveFiltersFromMemory(): void {
    this.filterMemory.clear(this.datastoreId);
  }

  private sendUpdateSignal(): void {
    this.saveActiveFiltersToMemory();
    this.onUpdate.emit(this.activeFilters);
  }
}
