import { BaseMessage } from './base-message';
import { MessageRecipient } from './message-recipient';
import { FilterGroup } from '../../data-filter/filter';

export enum ScopeRouteType {
  CompleteOrder = 'COMPLETE_ORDER',
  FilingDelivery = 'FILING_DELIVERY',
  TranslationDelivery = 'TRANSLATION_DELIVERY',
  Files = 'FILES',
}

export interface AlertMessage extends BaseMessage {
  service: string;
  serviceSubtype: string;
  taskDefinition: string;
  action: string;
  status: string;
  baseMessageId?: number;
  messageRecipients: MessageRecipient[];
  toastId?: number;
  sentTime?: number;
  fireTime: number;
  alertRoute: string;
  filterId?: number;
  filters?: FilterGroup;
  color?: string;
  taskNotes?: Map<string, string>; // bulk release alert will have language pair string (English to Chinese) and note.
  scopeRouteType?: ScopeRouteType;
  filterScope?: string;
  type?: string;
}
