<div class="messaging-container">
  <div class="message-thread" *ngIf="showForm">
    <townip-message-form (onCancel)="toggleForm()" (onSendMessage)="saveMessage($event)" [user]="user"></townip-message-form>
  </div>

  <div class="row no-gutters">
    <div class="col-md-12">
      <div class="message-thread-container" *ngIf="threadMessages">
        <div class="message-thread" *ngFor="let message of threadMessages">
          <div class="d-flex message-details-container"
               [ngClass]="{expanded: message.showFullDetails}">
            <ng-container *ngIf="!message.showFullDetails">
              <div class="d-flex collapse-details w-100" (click)="message.showFullDetails = !message.showFullDetails">
                <townip-initials
                  class="d-block mr-2"
                  fontSize="1rem"
                  [size]="35"
                  [data]="message.creator | generateFullname">
                </townip-initials>
                <div class="panel-recipients message-recipients w-50">
                  <div class="from">
                    <span class="text-muted">From: </span>
                    <span class="font-weight-normal">{{message.creator | generateFullname}}</span>
                  </div>
                  <div class="sent-to text-muted d-flex flex-wrap">
                    <div class="mr-1">To: </div>
                    <ng-container *ngFor="let recipient of parentMessage.messageRecipients; let i = index; let last = last">
                      <!-- Show the first 6 when collapsed to prevent multi line recipient-->
                      <div *ngIf="i <= 5" [tooltip]="recipient.user.organizationName" placement="bottom">
                        <span>{{recipient.user | generateFullname}}</span>
                        <span *ngIf="i < 5 && !last">, </span>
                        <span *ngIf="i === 5 && parentMessage.messageRecipients.length > 6">...</span>
                      </div>
                    </ng-container>
                  </div>
                  <div *ngIf="externalRecipients?.length && !message.isReply"
                       class="sent-to text-muted d-flex flex-wrap">
                    <div class="mr-1">External: </div>
                    <ng-container *ngFor="let recipient of externalRecipients; let i = index; let last = last">
                      <div *ngIf="i <= 2">
                        <span>{{recipient}}</span>
                        <span *ngIf="i < 2 && !last">, </span>
                        <span *ngIf="i === 2 && externalRecipients.length > 3">...</span>
                      </div>
                    </ng-container>
                  </div>
                </div>
                <div class="w-50 text-right panel-sent text-muted">
                  Sent: {{message.creationTime | date:'EEEE, LLLL d, yyyy h:mm aaa'}}
                </div>
              </div>
            </ng-container>

            <!--  All Details  -->
            <div class="w-100" *ngIf="message.showFullDetails">
              <div class="d-flex recipient-container">
                <townip-initials
                  class="d-block mr-2"
                  fontSize="1rem"
                  [size]="35"
                  [data]="message.creator.firstName + ' ' + message.creator.lastName">
                </townip-initials>
                <div class="message-recipients w-75" (click)="message.showFullDetails = !message.showFullDetails">
                  <div class="from">
                    <span class="text-muted">From: </span>
                    <span class="font-weight-normal">{{message.creator.firstName}} {{message.creator.lastName}}</span>
                  </div>
                  <div class="sent-date text-muted">
                    Sent: {{message.creationTime | date:'EEEE, LLLL d, yyyy h:mm aaa'}}
                  </div>
                  <div class="sent-to text-muted d-flex flex-wrap">
                    <div class="mr-1">To: </div>
                    <ng-container *ngFor="let recipient of parentMessage.messageRecipients; let last = last">
                      <div [tooltip]="recipient.user.organizationName" placement="bottom" class="mr-1">
                        {{recipient.user.firstName}} {{recipient.user.lastName}}<span *ngIf="!last">, </span>
                      </div>
                    </ng-container>
                  </div>
                  <div *ngIf="externalRecipients?.length && !message.isReply"
                       class="sent-to text-muted d-flex flex-wrap">
                    <div class="mr-1">External:</div>
                    <ng-container *ngFor="let recipient of externalRecipients; let last = last">
                      <div class="mr-1">
                        {{recipient}}<span *ngIf="!last">, </span>
                      </div>
                    </ng-container>
                  </div>
                  <div
                    *ngIf="parentMessage.scope && (showProjectScope || (!showProjectScope && parentMessage.scope !== 'PROJECT'))"
                    class="mt-1">
                    <button
                      class="btn btn-reply-scope {{parentMessage.scope}} mt-1"
                      (click)="$event.stopPropagation(); navigateToScope.emit()">
                      {{parentMessage.scope}} {{parentMessage.scopeName}}
                    </button>
                  </div>
                </div>

                <div class="w-25 text-right p-1">
                  <button (click)="triggerReplyAll.emit()" class="btn btn-link">
                    <i class="fa fa-reply-all"></i>Reply All
                  </button>
                </div>
              </div>
              <div *ngIf="message.fileAttachments" class="d-flex flex-wrap padding-left-15 mb-2">
                <townip-message-attachment
                  class="d-block d-flex pr-2 mt-1"
                  *ngFor="let attachment of message.fileAttachments"
                  [readOnly]="true"
                  [isGeneralMessage]="isGeneralMessage"
                  [file]="attachment"
                  (onAssociate)="showAssociateFile(attachment, message)">
                </townip-message-attachment>
              </div>

              <hr style="width: 97.5%" class="my-2">

              <div class="message-content">
                <!-- ql-editor will render the formats of the message body -->
                <div class="ql-editor message-content" [innerHTML]="message.messageBody"></div>
              </div>
            </div>
          </div>
        </div><!-- end: message thread -->
      </div><!-- end: message thread container -->
    </div>
  </div>
</div>

<townip-message-associate-file (onAssociated)="onFileAssociated.emit()"></townip-message-associate-file>
