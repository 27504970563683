import * as _ from 'underscore';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange } from '@angular/core';
import { Router } from '@angular/router';
import { MessagingService } from '../../../services/messaging.service';
import { PortalUser } from '../../../domain/user';
import { AlertMessage } from '../../../dto/messaging/alert-message';
import { TaskNavigationService } from '../../../../tasks/services/task-navigation.service';
import { NavService } from '../../../../main/navigation/nav.service';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'townip-alerts-quickview',
  templateUrl: './alerts-quickview.component.html',
  styleUrls: ['./alerts-quickview.component.scss']
})
export class AlertsQuickviewComponent implements OnInit, OnChanges {
  @Input() public user: PortalUser;
  @Output() public unreadAlertsCountChange: EventEmitter<number>;
  public unreadAlertsCount = 0;
  public alerts: AlertMessage[];
  public renderMenu: boolean;

  constructor(private messageService: MessagingService,
              private taskNavService: TaskNavigationService,
              private router: Router,
              private navService: NavService,
              private userService: UserService) {
    this.renderMenu = false;
    this.unreadAlertsCountChange = new EventEmitter();
  }

  public ngOnInit(): void {
  }

  public ngOnChanges(changes: { [propertyName: string]: SimpleChange }): void {
    if (this.user && changes.user) {
      this.messageService.alerts.subscribe(alerts => {
        this.alerts = alerts.filter(alert => {
          const recipient = alert.messageRecipients.find(mr => mr.user.id === this.user.id);
          if (!recipient) {
            return false;
          }
          return !recipient.read;
        });
        this.unreadAlertsCount = this.alerts.length;
        this.unreadAlertsCountChange.emit(this.unreadAlertsCount);
      });
    }
  }

  public hideMenu(): void {
    if (!this.renderMenu) {
      return;
    }
    // Set a very minimal delay so that angular has time to process
    // the click event before hiding the rendered menu
    setTimeout(() => {
      this.renderMenu = false;
    }, 200);
  }

  public showMenu(): void {
    this.renderMenu = true;
  }

  public toggleMenu(): void {
    if (this.renderMenu) {
      this.hideMenu();
    }

    this.renderMenu = true;
  }

  public navigate(alert: AlertMessage): void {
    this.messageService.markMessageRead(this.user.id, alert.baseMessageId, 'ALERT');
    if (alert.alertRoute) {
      this.decrementAlertCount(alert);
      if (!!alert.hideScope) {
        // do nothing when hideScope is true
        return;
      }
      if (alert.scope === 'TASK') {
        this.taskNavService.navigateToTaskByIds(alert.scopeId, alert.parentScopeId, alert.projectCategory);
      } else if (alert.scope === 'PROJECT') {
        this.navService.goToProject(this.user, alert.scopeId);
      } else {
        // Handling for ESTIMATES
        this.navService.navigateToEstimateMessage(alert);
      }
    }
  }

  public markAsRead(alert: AlertMessage): void {
    this.messageService.markMessageRead(this.user.id, alert.baseMessageId, 'ALERT');
  }

  public dismissAll(): void {
    this.messageService
      .markAllMessagesRead(this.user.id, 'ALERT')
      .toPromise();
    this.messageService.alerts.next([]);
  }

  private decrementAlertCount(alert: AlertMessage): void {
    if (this.unreadAlertsCount === 0) {
      return;
    }

    this.unreadAlertsCount--;
    this.unreadAlertsCountChange.emit(this.unreadAlertsCount);
    this.removeAlert(alert);
  }

  private removeAlert(alert: AlertMessage): void {
    this.alerts = _.filter(this.alerts, (item) => item !== alert);
  }

}
