import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DirectivesModule } from '../../../shared/directives/directives.module';
import { FileUploaderComponent } from './file-uploader.component';
import { FileUploadModule as PrimeFileUploadModule } from 'primeng/fileupload';

@NgModule({
  declarations: [FileUploaderComponent],
  exports: [FileUploaderComponent],
  imports: [
    CommonModule,
    PrimeFileUploadModule,
    DirectivesModule
  ]
})
export class FileUploaderModule {
}
