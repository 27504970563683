import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AnnuitySendPaymentResponse } from '../shared/dto/annuities/annuity-send-payment-response';
import { AnnuitySendPaymentRequest } from '../shared/dto/annuities/annuity-send-payment-request';
import { RequestService } from '../shared/services/request-service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AssignmentCategory, CategorizedWorkflowActions, WorkflowAction } from '../shared/dto/annuities/annuity-workflow-action';
import { cloneDeep } from '../shared/clone-deep';
import { map, switchMap, tap } from 'rxjs/operators';
import { includes } from '../settings-v2/shared/utils/includes';
import { FulfillAnnuityRequest } from '../shared/dto/annuities/fulfill-annuity-request';
import { AnnuityInformationRequestResponse } from '../shared/dto/annuities/annuity-information-request';
import { sortBy } from '../shared/utils/sort-by';
import { Annuity } from '../shared/dto/annuities/annuity';
import { AnnuityActionHelper } from '../annuities/lib/annuity-action-helper';
import { AnnuityInvoice } from '../shared/dto/annuities/annuity-invoice';
import { Params } from '@angular/router';
import { AnnuityAdditionalInstruction } from '../shared/dto/annuities/annuity-additional-instruction';
import { AnnuityReworkHistory } from '../shared/dto/annuities/annuity-rework-history';
import { AnnuityFulfillmentQcRecords } from '../shared/dto/annuities/annuity-fulfillment-qc-records';
import { AnnuityFulfillmentRecord } from '../shared/dto/annuities/annuity-fulfillment-record';
import { FulfillmentDateType } from '../shared/enums/fulfillment-date-type';
import { FileKeyPair } from '../shared/dto/file-key';
import { DeclarationOfProofFile } from '../shared/dto/annuities/declaration-of-proof-file';
import { PriceFreezeDetails, PriceFreezeRequest, PriceFreezeResponse } from '../shared/dto/annuities/price-freeze';
import { CompanyLite } from '../shared/dto/company-lite';
import { sortByString } from '../shared/pipes/sort-by-string.pipe';

@Injectable({
  providedIn: 'root'
})
export class AnnuityWorkflowService {

  private allActions: WorkflowAction[] = [];

  constructor(
    private requestService: RequestService,
    private httpClient: HttpClient,
  ) { }

  public sendPaymentInstructions(request: AnnuitySendPaymentRequest[]): Observable<AnnuitySendPaymentResponse> {
    const url = '/api/annuity/send-payment-instructions';
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient.post<AnnuitySendPaymentResponse>(url, request, { headers });
  }

  public getAllActions(): Observable<WorkflowAction[]> {
    // All the actions barely change so we cache them manually.
    // If we have values, return that.
    if (this.allActions && this.allActions.length) {
      return of(cloneDeep(this.allActions));
    }

    const url = '/api/annuities/actions';
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient
      .get<WorkflowAction[]>(url, { headers })
      .pipe(
        tap((actions) => {
          this.allActions = sortBy(actions, 'readableAction');
        })
      );
  }

  /**
   * Gets all the related actions based on the given annuity id
   * This returns the actual actions instead of just a set of ids.
   * @param id - Annuity Id
   *
   */
  public getActions(id: number): Observable<WorkflowAction[]> {
    const url = `/api/annuities/actions/${id}`;
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient
      .get<number[]>(url, { headers })
      .pipe(
        switchMap((availableActions) => {
          // In here, we basically map all the FULL actions already so there's no need to do them on the consumers.
          return this.getAllActions()
            .pipe(
              map(actions => actions.filter(action => includes(availableActions, action.id)))
            );
        })
      );
  }

  public getCategorizedActions(id: number): Observable<CategorizedWorkflowActions> {
    return this.getActions(id)
      .pipe(
        map((actions) => {
          const categorized: CategorizedWorkflowActions = {
            internal: actions.filter(action => action.assignmentCategory === AssignmentCategory.Internal),
            company: actions.filter(action => action.assignmentCategory === AssignmentCategory.Client),
            vendor: actions.filter(action => action.assignmentCategory === AssignmentCategory.Vendor),
            all: this.allActions,
          };

          // Proper sorting, because they get returned in various order
          categorized.internal = sortBy(categorized.internal, 'readableAction');
          categorized.company = sortBy(categorized.company, 'readableAction');
          categorized.vendor = sortBy(categorized.vendor, 'readableAction');
          categorized.all = sortBy(categorized.all, 'readableAction');

          // Also set the helper
          // For use in quickly figuring out if actions exist.
          // Read class definition for details
          categorized.helper = new AnnuityActionHelper(categorized);

          return categorized;
        })
      );
  }

  public vendorAcknowledge(id: number): Observable<void> {
    const url = `/api/annuity/${id}/acknowledge-request`;
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient
      .put<void>(url, null, { headers });
  }

  public bulkVendorAcknowledge(ids: number[]): Observable<void> {
    const url = '/api/annuity/acknowledge-request/bulk';
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient
      .post<void>(url, ids, { headers });
  }

  public vendorFulfill(request: FulfillAnnuityRequest): Observable<void> {
    const url = `/api/annuity/${request.annuityId}/fulfill-annuity`;
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient
      .put<void>(url, request, { headers });
  }

  public verifyReceipt(id: number, isReceiptReleasedToClient: boolean = false): Observable<void> {
    const url = `/api/annuity/${id}/verify-receipt-request?isReceiptFilesReleasedToClient=${isReceiptReleasedToClient}`;
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient
      .put<void>(url, null, { headers });
  }

  public releaseReceipt(id: number, isReceiptReleasedToClient: boolean = false, receiptId: number): Observable<void> {
    // tslint:disable-next-line:max-line-length
    const url = `/api/annuity/${id}/${receiptId}/release-receipt?isReceiptFilesReleasedToClient=${isReceiptReleasedToClient}`;
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient
      .put<void>(url, null, { headers });
  }

  public requestMoreInformation(id: number, request: AnnuityInformationRequestResponse): Observable<void> {
    const url = `/api/annuity/${id}/request-more-information`;
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient
      .put<void>(url, request, { headers });
  }

  public respondToInformationRequest(id: number, request: AnnuityInformationRequestResponse): Observable<void> {
    const url = `/api/annuity/${id}/request-more-information-response`;
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient
      .put<void>(url, request, { headers });
  }

  public createClientTask(id: number, messageRequest: string, responseDeadline: string): Observable<void> {
    const url = `/api/annuity/${id}/create-client-task`;
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient
      .put<void>(url, { messageRequest, responseDeadline }, { headers });
  }

  public acceptResponse(id: number): Observable<void> {
    const url = `/api/annuity/${id}/accept-response`;
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient
      .put<void>(url, null, { headers });
  }

  public addVendorReferenceNumber(id: number, ref: string): Observable<string> {
    const url = `/api/annuity/${id}/vendor-reference-number`;
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient.put(url, null, {
      headers: headers,
      params: {
        referenceNumber: ref.trim(),
      },
      responseType: 'text',
    });
  }

  public reassignVendor(annuityId: number, newVendorId: number): Observable<void> {
    const url = `api/annuity/${annuityId}/vendor/${newVendorId}`;
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient
      .put<void>(url, null,  { headers });
  }

  public cancel(annuityId: number, reason: string): Observable<Annuity> {
    const url = `/api/annuity/${annuityId}/cancel`;
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient.put<Annuity>(url, reason, { headers });
  }

  public hold(annuityId: number, reason: string, internalOnlyNote: boolean = false): Observable<Annuity> {
    const url = `/api/annuity/${annuityId}/hold`;
    const queryParams: Params = { internalOnlyNote };
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient.put<Annuity>(url, reason, { headers, params: queryParams });
  }

  public sendBackToVendor(annuityId: number, reworkMessage: string, reworkDeadline: string): Observable<Annuity> {
    const url = `/api/annuity/${annuityId}/send-back-to-vendor-request`;
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient.put<Annuity>(url, { reworkMessage, reworkDeadline }, { headers });
  }

  public resume(annuityId: number, reason: string, internalOnlyNote: boolean = false): Observable<Annuity> {
    const url = `/api/annuity/${annuityId}/resume`;
    const queryParams: Params = { internalOnlyNote };
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient.put<Annuity>(url, reason, { headers, params: queryParams });
  }

  public bulkFulfill(request: FulfillAnnuityRequest[]): Observable<FulfillAnnuityRequest[]> {
    const url = '/api/annuity/bulk/fulfill-annuity';
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient.put<FulfillAnnuityRequest[]>(url, request, { headers });
  }

  public addAdditionalInvoice(invoice: AnnuityInvoice): Observable<AnnuityInvoice> {
    const url = `/api/annuity/${invoice.annuityId}/additional-invoice`;
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient.post<AnnuityInvoice>(url, invoice, { headers });
  }

  public bypassPaymentRequired(annuityId: number, messageRequest: string): Observable<Annuity> {
    const url = `/api/annuity/${annuityId}/bypass-payment-required`;
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient.put<Annuity>(url, { messageRequest }, { headers });
  }

  public updatePartialInstruction(request: AnnuityAdditionalInstruction): Observable<Annuity> {
    const url = '/api/annuity/edit-partial-instruction';
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient.post<Annuity>(url, request, { headers });
  }

  public startAndClaim(annuityId: number): Observable<any> {
    const url = `/api/annuity/${annuityId}/claim-or-start-qc`;
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient.put<Annuity>(url, null, { headers });
  }

  public getFulfillmentReworkHistory(recordId: number): Observable<AnnuityReworkHistory[]> {
    const url = `/api/annuity/annuity-fulfillment-rework-history/${recordId}`;
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient
      .get<AnnuityReworkHistory[]>(url, { headers })
      .pipe(
        map(data => {
          return sortBy(data, 'creationDate');
        })
      );
  }

  public getFulfillmentQualityControlHistory(fulfillmentRecordId: number): Observable<AnnuityFulfillmentQcRecords[]> {
    const url = `/api/annuity/annuity-fulfillment-quality-control/${fulfillmentRecordId}`;

    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.httpClient
      .get<AnnuityFulfillmentQcRecords[]>(url, { headers: headers })
      .pipe(
        // map((fulRec))
      );
  }

  public getFulfillmentRecords(fulfillmentRecordId: number): Observable<AnnuityFulfillmentRecord> {
    const url = `/api/annuity/annuity-fulfillment-record/${fulfillmentRecordId}`;

    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.httpClient.get<AnnuityFulfillmentRecord>(url, { headers: headers });
  }

  public updateFileUploadExpectedDate(
    fulfillmentRecordId: number,
    updatedDate: string
  ): Observable<AnnuityFulfillmentRecord> {
    const url = `api/annuity/annuity-fulfillment-record/${fulfillmentRecordId}/dates`;
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    const body = {
      fulfillmentDateType: FulfillmentDateType.FulfillmentDateType,
      updatedDate: updatedDate
    };

    return this.httpClient.put<AnnuityFulfillmentRecord>(url, body, { headers });
  }

  public updateOfficialFulfillmentDate(
    fulfillmentRecordId: number,
    updatedDate: string
  ): Observable<AnnuityFulfillmentRecord> {
    const url = `api/annuity/annuity-fulfillment-record/${fulfillmentRecordId}/dates`;
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    const body = {
      fulfillmentDateType: FulfillmentDateType.OfficialFulfillmentDate,
      updatedDate: updatedDate,
    };

    return this.httpClient.put<AnnuityFulfillmentRecord>(url, body, { headers });
  }

  public addDeclarationOfUseProof(annuityId: number, file: FileKeyPair): Observable<DeclarationOfProofFile> {
    const url = `/api/annuity/${annuityId}/declaration-of-use-proof`;
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.httpClient.post<DeclarationOfProofFile>(url, file, { headers });
  }

  public updateDeclarationOfUseProof(annuityId: number, dous: DeclarationOfProofFile[]): Observable<DeclarationOfProofFile[]> {
    const url = `/api/annuity/${annuityId}/declaration-of-use-proof`;
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.httpClient.put<DeclarationOfProofFile[]>(url, dous, { headers });
  }

  public removeDeclarationOfUseProof(id: number, fileId: number): Observable<void> {
    const url = `/api/annuity/${id}/declaration-of-use-proof/file/${fileId}`;
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.httpClient.delete<void>(url, { headers });
  }

  public getFreezeDetail(annuityIds: number[]): Observable<PriceFreezeDetails[]> {
    const url = '/api/annuity/details/freeze';
    const request: PriceFreezeRequest = { annuityIds: annuityIds };
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.httpClient.post<PriceFreezeDetails[]>(url, request, { headers });
  }

  public priceFreeze(annuityIds: number[]): Observable<PriceFreezeResponse> {
    const url = '/api/annuity/freeze';
    const request: PriceFreezeRequest = { annuityIds: annuityIds };
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.httpClient.post<PriceFreezeResponse>(url, request, { headers });
  }

  public prizeUnfreeze(annuityId: number): Observable<Annuity> {
    const url = `/api/annuity/${annuityId}/unfreeze`;

    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.post<Annuity>(url, null, { headers });
  }

  public getFreezableClients(): Observable<CompanyLite[]> {
    const url = '/api/annuity/price-freeze/assigned-internal';

    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.get<CompanyLite[]>(url, { headers })
      .pipe(
        map(clients => sortByString(clients, 'companyName'))
      );
  }
}
