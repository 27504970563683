import { Injectable } from '@angular/core';
import { ConfigService } from '../../config.service';
import { ConfigDataStoreService } from './config-data-store.service';
import { UserService } from '../../../shared/services/user.service';
import { SystemConfigDTO } from '../../../shared/dto/system-config/system-config-dto';
import { Country } from '../../../shared/dto/system-config/country';
import { SystemConfigResponseDTO } from '../../../shared/dto/system-config/system-config-response-dto';

@Injectable()
export class CountryDataStoreService extends ConfigDataStoreService<Country> {

  constructor(configService: ConfigService, userService: UserService) {
    super(configService, userService, 'COUNTRY');
  }

  public create(data: Country): Promise<SystemConfigResponseDTO> {
    const configObject: SystemConfigDTO = { userId: this.user.id, type: this.type, countryDTO: data };
    const response = this.configService.addSystemConfig(configObject);
    response.then(res => {
      this.dataSetPromise.then(dataSet => {
        dataSet.push(res.configValueDTO as Country);
        this.dataSet.next(dataSet);
        this.dataSetPromise = Promise.resolve(dataSet);
      });
    });

    return response;
  }

  public update(data: Country): Promise<SystemConfigResponseDTO> {
    const configObject: SystemConfigDTO = { userId: this.user.id, type: this.type, countryDTO: data };
    const response = this.configService.updateSystemConfig(configObject);
    response.then(res => {
      this.dataSetPromise.then(dataSet => {
        const index = dataSet.findIndex(dataPoint => dataPoint.id === res.configValueDTO.id);
        dataSet[index] = res.configValueDTO as Country;
        this.dataSet.next(dataSet);
        this.dataSetPromise = Promise.resolve(dataSet);
      });
    });

    return response;
  }

  public enable(data: Country): Promise<SystemConfigResponseDTO> {
    return this.configService.updateSystemConfig({ userId: this.user.id, type: this.type, countryDTO: data });
  }

  public disable(data: Country): Promise<SystemConfigResponseDTO> {
    return this.configService.updateSystemConfig({ userId: this.user.id, type: this.type, countryDTO: data });
  }

  public find(id: number): Promise<Country> {
    return Promise.resolve(this.dataSetPromise.then(ds => {
      return ds.find(d => d.id === id);
    }));
  }
}
