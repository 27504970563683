import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PreferredPricingIconComponent } from './preferred-pricing-icon.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

@NgModule({
  declarations: [PreferredPricingIconComponent],
  exports: [PreferredPricingIconComponent],
  imports: [
    CommonModule,
    TooltipModule,
  ]
})
export class PreferredPricingIconModule { }
