import { Component, Input, OnChanges, OnInit, SimpleChange, ViewChild } from '@angular/core';
import { PortalUser } from '../../../shared/domain/user';
import { MenuItem } from 'primeng/api';
import { NavService } from '../nav.service';
import { Event as RouterEvent, NavigationEnd, Router } from '@angular/router';
import { UserAuthCache, UserPermCache, UserService } from '../../../shared/services/user.service';
import { LoggingService } from '../../../shared/logging.service';
import { AppContextService } from '../../../shared/services/app-context.service';
import { PrincipalUserDTO } from '../../../shared/dto/principal-user';
import { ModalComponent } from '../../../controls/modal/modal.component';

export enum UserType {
  CUSTOMER = 'CUSTOMER',
  VENDOR = 'VENDOR',
  VENDOR_MANAGER = 'VENDOR_MANAGER',
  PM = 'PM',
  SALES_MANAGER = 'SALES_MANAGER',
  ACCOUNT_MANAGER = 'ACCOUNT_MANAGER',
  INTERNAL_VIEW_ONLY = 'INTERNAL_VIEW_ONLY'
}

@Component({
  selector: 'townip-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit, OnChanges {
  @Input()
  public user: PortalUser;

  @Input()
  public accountManager: PrincipalUserDTO;

  @ViewChild(ModalComponent)
  public messageModal: ModalComponent;

  public userType: UserType;

  public userAuthorities: UserAuthCache;

  public userPermissions: UserPermCache;

  public items: MenuItem[];

  public messageModalVisible = false;

  public hasViewReportsPermission: boolean;

  public isExpanded = false;

  public userTypes = UserType;

  // This value will be set once we have roles for forecast users.
  public isForecaster: boolean;

  public currentModule: string;

  constructor(public appContext: AppContextService,
              private navService: NavService,
              private router: Router,
              private loggingService: LoggingService,
              private userService: UserService) {
  }

  public get hasAnnuitiesAccess(): boolean {
    return this.userService.hasAnnuitiesAccess();
  }

  public get hasTranslationFilingAccess(): boolean {
    return this.userService.hasTranslationFilingAccess();
  }

  public ngOnInit(): void {
    this.navService.collapseEmitter.subscribe(expanded => this.isExpanded = expanded);
    this.setUserType();

    this.router
      .events
      .subscribe((event) => {
        this.parseOverviewRoutes(event);
      });
  }

  public ngOnChanges(changes: { [propertyName: string]: SimpleChange }): void {
    if (changes.user && !changes.user.firstChange && !!this.user) {
      this.userAuthorities = this.userService.getAuthorizations();
      this.userPermissions = this.userService.getPermissions();
      this.setUserType();
    } else if (!this.user) {
      this.userAuthorities = null;
      this.userPermissions = null;
      this.userType = null;
      this.hasViewReportsPermission = false;
    }
  }

  private setUserType(): void {
    this.userType = null;

    if (this.user) {
      if (this.user.userType === 'COMPANY') {
        this.userType = UserType.CUSTOMER;
      } else if (this.user.userType === 'VENDOR') {
        if (this.userService.authorizations.ROLE_VENDOR_ADMIN) {
          this.userType = UserType.VENDOR_MANAGER;
        } else {
          this.userType = UserType.VENDOR;
        }
      } else if (this.user.userType === 'INTERNAL') {
        if (this.userService.authorizations.ROLE_INTERNAL_SALES_MGR) {
          this.userType = UserType.SALES_MANAGER;
        } else if (this.userService.authorizations.ROLE_INTERNAL_ACCOUNT_MGR) {
          this.userType = UserType.ACCOUNT_MANAGER;
        } else if (this.userService.authorizations.ROLE_INTERNAL_PROJECT_MGR ||
            this.userService.authorizations.ROLE_INTERNAL_ADMIN) {
          this.userType = UserType.PM;
        } else {
          this.userType = UserType.INTERNAL_VIEW_ONLY;
        }
      }

      this.setRunReportsPermission();
    }

    this.isForecaster = this.userService.isForecaster();
  }

  public navigateToDashboard(): void {
    this.navService.goToTranslationFilingDashboard(this.user);
  }

  public showMessageModal(): void {
    this.messageModalVisible = true;
    this.messageModal.show();
  }

  public hideMessageModal(): void {
    this.messageModalVisible = false;
    this.messageModal.hide();
  }

  public messageSent(): void {
    this.hideMessageModal();
  }

  private setRunReportsPermission(): void {
    this.hasViewReportsPermission = this.userService.getPermissions().REPORTS_USER;
  }

  public get isSalesOrAccountMgr(): boolean {
    return this.userService.isSalesOrAccountManager();
  }

  /**
   * Handles the navigation active highlight
   * This was done because we need to redirect the overview tables to the redirector first.
   * By doing that, we can't use [routerLinkActive] any more since the url points to the redirector.
   * It was done this way so that the whole page and route will reset to their fresh states.
   * Although it could have been handled in the table overview themselves, it requires significant
   * restructuring of the overview tables
   *
   * @param event
   */
  private parseOverviewRoutes(event: RouterEvent): void {
    if (!(event instanceof NavigationEnd)) {
      return;
    }

    this.hideMessageModal();

    if (event.url.startsWith('/estimate/estimates')) {
      this.currentModule = 'estimates';
      return;
    }

    if (event.url.startsWith('/projects')) {
      this.currentModule = 'projects';
      return;
    }

    if (event.url.startsWith('/tasks')) {
      this.currentModule = 'tasks';
      return;
    }

    if (event.url.startsWith('/billing') || event.url.startsWith('/annuities/vendor/view-bill')) {
      this.currentModule = 'billing';
      return;
    }

    const annuitiesTableRoute = [
      '/annuities/company/all',
      '/annuities/internal/project-manager/all',
      '/annuities/vendor/annuities-table',
      '/annuities/sm-am/all',
    ];

    for (const route of annuitiesTableRoute) {
      if (event.url.startsWith(route)) {
        this.currentModule = 'annuities-table';
        return;
      }
    }

    if (event.url.startsWith('/annuities')) {
      this.currentModule = 'annuities-overview';
      return;
    }

    // Non of the special handling satisfies.
    this.currentModule = '';
  }
}
