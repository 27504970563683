import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Error404Component } from './error-404/error-404.component';
import { Error403Component } from './error-403/error-403.component';
import { ErrorScreensizeComponent } from './error-screensize/error-screensize.component';
import { ErrorSystemOfflineComponent } from './error-system-offline/error-system-offline.component';
import { ErrorDarkComponent } from './error-dark/error-dark.component';
import { ErrorAuthModuleOnlyComponent } from './error-auth-module-only/error-auth-module-only.component';
import { Error503MaintenanceComponent } from './error-503/error-503-maintenance.component';

// These error components will fill screen height
// Two ways you can use these error components
// 1 - use them as normal components (for special use cases (e.g per page))
// 2 - use them as routed (below)
const routes: Routes = [
  {
    path: 'error-404',
    component: Error404Component,
    canActivate: [],
  },
  {
    path: 'error-403',
    component: Error403Component,
    canActivate: [],
  },
  {
    path: 'error-503-maintenance',
    component: Error503MaintenanceComponent,
    canActivate: [],
  },
  {
    path: 'error-system-offline',
    component: ErrorSystemOfflineComponent,
    canActivate: [],
  },
  {
    path: 'error-screen',
    component: ErrorScreensizeComponent,
    canActivate: [],
  },
  {
    path: 'error-forecast',
    component: ErrorDarkComponent,
    canActivate: [],
  },
  {
    path: 'error-auth-only',
    component: ErrorAuthModuleOnlyComponent,
    canActivate: [],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class ErrorsRoutingModule {
}
