import { Component, OnInit, Input } from '@angular/core';
import { WebsocketService } from '../../../services/websocket.service';

@Component({
  selector: 'townip-message-connection-status',
  templateUrl: './message-connection-status.component.html',
  styleUrls: ['./message-connection-status.component.scss']
})
export class MessageConnectionStatusComponent implements OnInit {

  @Input()
  public warning: string;

  @Input()
  public show: boolean;

  constructor(private websocketService: WebsocketService) { }

  public ngOnInit(): void {
    this.show = true;

    // If it's not connected yet, wait for it to connect
    if (this.websocketService.isConnected) {
      this.show = false;
    } else {
      this.websocketService.connectedEmitter.subscribe(connected => {
        this.show = !connected;
      });
    }
  }

}
