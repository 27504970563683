export enum DeliveryContactTypeEnum {
  Internal = 'INTERNAL',
  External = 'EXTERNAL',
  ForeignAgent = 'FOREIGN_AGENT',
  Client = 'CLIENT',
  SupplementalEmail = 'SUPPLEMENTAL_EMAIL',
  Vendor = 'VENDOR',
  AdHoc = 'AD_HOC',
  System = 'SYSTEM',
}
