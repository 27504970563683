import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserCardComponent } from './user-card/user-card.component';
import { UserContentDirective, UserImageDirective, UserMetaDirective, UserNameDirective, UserTypeDirective } from './user-card.directives';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    UserCardComponent,
    UserNameDirective,
    UserTypeDirective,
    UserImageDirective,
    UserContentDirective,
    UserMetaDirective
  ],
  exports: [
    UserCardComponent,
    UserNameDirective,
    UserTypeDirective,
    UserImageDirective,
    UserContentDirective,
    UserMetaDirective
  ]
})
export class UserCardModule { }
