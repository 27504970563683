import { Injectable } from '@angular/core';
import { UserService } from '../shared/services/user.service';
import { Router } from '@angular/router';
import { LoggingService } from '../shared/logging.service';
import { WorkflowService } from './workflow-service';

@Injectable()
export class ProjectsGuardService  {

  constructor(private userService: UserService,
              private workflowService: WorkflowService,
              private loggingService: LoggingService,
              private _router: Router) {
  }

  /**
   * Temporary fix so that a page refresh does not break the whole system
   * @returns {Promise<T>}
   */
  public canActivate(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      // Check if session is active
      setTimeout(() => {
        const user = this.userService.getUser();
        if (user) {
          // Making sure we are also loading the required data together with the user
          const dataFetch: Promise<any>[] = [];

          dataFetch.push(this.workflowService.retrieveWorkflowActions());

          // When all the data finishes loading async
          Promise.all(dataFetch)
            .then(() => {
              resolve(true);
            })
            .catch(() => {
              console.error('Failed to load session instance for projects.');
              resolve(false);
              this._router.navigate(['error-404']);
            });

          return;
        }

        // If session is not active, redirect to login
        // this._router.navigate(['/auth/login']);
        this.loggingService.warn('Could not load project session, sending rejection');
        reject(false);
      });
    });
  }
}
