import * as _ from 'underscore';
import { Component } from '@angular/core';
import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';
import { MessagingService } from '../../services/messaging.service';
import { Router } from '@angular/router';
import { AlertMessage } from '../../dto/messaging/alert-message';
import { ToastGroupingService } from '../../services/toast-grouping.service';
import { Message } from '../../dto/messaging/message';
import { TaskNavigationService } from '../../../tasks/services/task-navigation.service';
import { NavService } from '../../../main/navigation/nav.service';
import MessageUtil from '../../message-util';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[custom-toast-component]',
  styleUrls: ['custom-toast.scss'],
  templateUrl: 'custom-toast.html',
  animations: [
    trigger('flyInOut', [
      state('inactive', style({
        opacity: 0
      })),
      transition('inactive => active', animate('400ms ease-out', keyframes([
        style({
          transform: 'translate3d(100%, 0, 0) skewX(-30deg)',
          opacity: 0,
        }),
        style({
          transform: 'skewX(20deg)',
          opacity: 1,
        }),
        style({
          transform: 'skewX(-5deg)',
          opacity: 1,
        }),
        style({
          transform: 'none',
          opacity: 1,
        }),
      ]))),
      transition('active => removed', animate('400ms ease-out', keyframes([
        style({
          opacity: 1,
        }),
        style({
          transform: 'translate3d(100%, 0, 0) skewX(30deg)',
          opacity: 0,
        }),
      ]))),
    ]),
  ],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class CustomToast extends Toast {
  public snoozeString = 'Remind me later';
  public alertId: number;
  public alert: AlertMessage;
  public metadata: Message | AlertMessage;
  public creationTime: number; // The timestamp when this toast has been created
  public iconClass = 'fa-exclamation';
  public toastId: number;
  public sender: string;

  // constructor is only necessary when not using AoT
  constructor(protected toastrService: ToastrService,
              protected toastRef: ToastPackage,
              private messagingService: MessagingService,
              private taskNavService: TaskNavigationService,
              private toastGroupingService: ToastGroupingService,
              private router: Router,
              private navService: NavService) {


    super(toastrService, toastRef);

    this.toastId = toastRef.toastId;

    this.creationTime = new Date().getTime();

    // Run 1 angular tick so the values are updated.
    // This is because the component has been already created even before the toastID
    // and metadata has been set for the alert
    setTimeout(() => {
      this.alert = this.messagingService.getAlertForToast(this.toastId);
      // We'll fetch the metada we saved earlier
      const toastData = _.findWhere(this.toastrService.toasts, { toastId: this.toastId }) as any;

      if (toastData) {
        this.metadata = toastData.metadata;
        if (this.metadata.creator) {
          this.sender = `${this.metadata.creator.firstName} ${this.metadata.creator.lastName}`;
          const recipientType = this.metadata.creator.recipientType;
          if (recipientType === 'Client' || recipientType === 'Vendor') {
            this.sender = `${this.sender} - ${this.metadata.creator.organization}`;
          }
        }
        if (this.metadata && this.metadata.messageType === 'GENERAL_MESSAGE') {
          this.iconClass = 'fa-envelope';
        } else {
          this.iconClass = 'fa-exclamation';
        }
      }
    });
  }

  public action(event: Event): boolean {
    event.stopPropagation();
    const response = this.messagingService.snoozeAlert(this.toastId);
    response
      .toPromise()
      .then(res => {

      });
    // this.toastRef.onAction.next(this.snoozeString);
    // this.toastRef.onAction.complete();
    this.remove();
    return false;
  }

  public navigate(): void {
    if (this.metadata.messageType === 'GROUPING') {
      this.toastGroupingService.selectGroup(this.toastPackage.toastId);
    }

    if (this.metadata.messageType === 'ALERT') {
      this.navService.navigateAlert(this.alert, this.metadata as AlertMessage);
    }

    if (this.metadata.messageType === 'GENERAL_MESSAGE') {
      this.navigateMessage(this.metadata as Message);
    }

    if (this.metadata.messageType === 'FILTER') {
      this.navigateFilter();
    }

    this.remove();
  }

  /**
   * This method is being used when 'Show More' was clicked at the toast message
   */
  public onShowMore(): void {
    if (this.metadata.messageType === 'GENERAL_MESSAGE') {
      this.navigateMessage(this.metadata as Message);
    }

    this.remove();
  }

  public showFileAttachments(message: Message): boolean {
    return MessageUtil.showFileAttachments(message);
  }

  private navigateMessage(message: Message): void {
    // If we are navigating a message reply
    if (message.parentMessageId) {
      this.router.navigate(['/messages', { mid: message.parentMessageId }]);
      return;
    }

    // If we are navigating a new message
    this.router.navigate(['/messages', { mid: message.id }]);
  }

  private navigateFilter(): void {
    const alert = this.metadata as AlertMessage;
    this.router.navigate(['/projects'], { queryParams: { filterId: alert.filterId, tab: 'my' } });

    if (alert.scope === 'TASK') {
      // Do task here...
    }
  }
}
