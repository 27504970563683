import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';
import { isNullOrUndefined } from '../utils/is-null-or-undefined';

export const OUTPUT_DATE_FORMAT: Map<string, string> = new Map<string, string>()
  .set('date', 'MMM DD, YYYY')
  .set('dategmt', 'MMM DD, YYYY')
  .set('monthday', 'MMM DD')
  .set('time', 'hh:mmA')
  .set('datetime', 'MMM DD, YYYY hh:mmA');

// Moment-timezone method:
// For future reference when we need to actually manipulate the timezones

// We need to use the global version of moment, not the imported one
// The imported creates a different instance and thus not loading the one from global scope which includes the tz lib
// declare function moment(date?: any): any;

export function handleZoneNaming(): void {
  // Manual override because we'll be displaying UTC +offset on
  // users where timezones are not in the Americas
  const abbrs = {
    EST: 'EST', // 'Eastern Standard Time'
    EDT: 'EDT', // 'Eastern Daylight Time'
    CST: 'CST', // 'Central Standard Time'
    CDT: 'CDT', // 'Central Daylight Time'
    MST: 'MST', // 'Mountain Standard Time'
    MDT: 'MDT', // 'Mountain Daylight Time'
    PST: 'PST', // 'Pacific Standard Time'
    PDT: 'PDT', // 'Pacific Daylight Time'
  };

  // Quick hack to make TZ work as expected, without having to add external typings
  const m: any = moment;

  m.fn.zoneName = function(): any {
    const abbr = this.zoneAbbr();

    // If user's timezone is somewhere outside the Americas,
    // return the UTC +00 offset format, if in Americas, return the abbrev format above
    return abbrs[abbr] || 'GMT ' + abbr;
  };
}

export function getDateTimeString(value: any, params?: any): string {
  // Making sure our changes are applied to the moment object
  handleZoneNaming();

  // Quick hack to make TZ work as expected, without having to add external typings
  const m: any = moment;
  let browserTimezone = m.tz.guess();
  let format = '';

  if (isNullOrUndefined(params)) {
    return;
  }

  // Set defaults
  if (isNullOrUndefined(params.showTimezone)) {
    params.showTimezone = true;
  }
  if (isNullOrUndefined(params.noTimeZone)) {
    params.noTimeZone = false;
  }

  if (!isNullOrUndefined(params.output)) {
    format = OUTPUT_DATE_FORMAT.get(params.output);
    if (params.output === 'dategmt') {
      browserTimezone = 'GMT';
    }
  } else {
    // Should always have OUTPUT in params
    return;
  }

  if (params.showTimezone) {
    format += ' (zz)';
  }

  if (params.noTimeZone) {
    return m.utc(value)
      .format(format);
  }

  if (params.timezone) {
    return m(value)
      .tz(params.timezone)
      .format(format);
  }

  return m(value)
    .tz(browserTimezone)
    .format(format);
}

export function isDateToday(date: any): boolean {
  const d = moment(date);
  const today = moment();
  return d.date() === today.date() && d.month() === today.month() && d.year() === today.year();
}

/**
 * This will become the version 2 of 'dateTime' pipe.
 * We need to handle all the functionality/complexity from the 'dateTime' pipe to this pipe
 *
 * This pipe should only have single parameter which is (params?: any)
 */
@Pipe({
  name: 'dateTimeFormat'
})
export class DateTimeFormatPipe implements PipeTransform {
  public transform(value: any, params?: any): any {
    if (value === null || value === undefined) {
      return '-';
    }

    // Handle null params
    if (isNullOrUndefined(params)) {
      params = {};
    }

    return getDateTimeString(value, params);
  }
}

@Pipe({
  name: 'dateTime'
})
export class DateTimePipe implements PipeTransform {
  // TODO: Convert parameters into single options? object for FUTURE-proofing
  // EX: transform(value: any, options?: any)
  // Also add support for bypassing timezone conversions

  // transform(value: any, dateOnly = false, timeOnly = false, checkIfDateIsToday = false): any {
  public transform(value: any, options?: any): any {
    if (value === null || value === undefined) {
      return '-';
    }

    if (!options) {
      return getDateTimeString(value, { output: 'datetime' });
    }

    if (options.dateOnly) {
      return getDateTimeString(value,
        { output: 'date', showTimezone: false, noTimeZone: options.noTimezone, timezone: options.timezone });
    }

    if (options.timeOnly) {
      return getDateTimeString(value,
        {
          output: 'time',
          showTimezone: !options ? true : options.showTimezone,
          noTimeZone: options.noTimezone,
          timezone: options.timezone
        });
    }

    if (options.checkIfDateIsToday) {
      return (isDateToday(value) ? 'Today' :
        getDateTimeString(value, {
          output: 'monthday',
          showTimezone: false,
          noTimeZone: options.noTimezone,
          timezone: options.timezone
        })) +
        `, ${getDateTimeString(value, {
          output: 'time',
          showTimezone: false,
          noTimeZone: options.noTimezone,
          timezone: options.timezone
        })}`;
    }

    return getDateTimeString(value, { output: 'datetime', noTimeZone: options.noTimezone, timezone: options.timezone });
  }
}
