<form [formGroup]="messageForm" class="form-group message-form-container" *ngIf="isInit">
  <div *ngIf="isInit && showMessageSubject">
    <input type="text" class="form-control"
           formControlName="messageSubject"
           placeholder="Enter a message Title"/>
  </div>

  <p-editor class="editor" formControlName="messageBody" [style]="{'height':'295px'}" styleClass="messaging">
    <p-header>
        <span class="ql-formats">
            <button class="ql-bold"></button>
            <button class="ql-italic"></button>
            <button class="ql-underline"></button>
        </span>
      <span class="ql-formats" style="line-height: 0;">
          <button class="ql-list" value="ordered"></button>
          <button class="ql-list" value="bullet"></button>
          <select class="ql-align">
            <option selected></option>
            <option value="center"></option>
            <option value="right"></option>
            <option value="justify"></option>
          </select>
          <button class="btn btn-icon ml-4 upload-file" (click)="showAttachmentModal()">
            <i class="fa fa-paperclip"></i>
          </button>
        </span>
    </p-header>
  </p-editor>

  <div *ngIf="!popup; else modal" class="main-actions">
    <button type="button"
            class="btn btn-primary btn-wide mr-3"
            (click)="sendReply()"
            [disabled]="!contacts || contacts.length === 0 || !messageForm.valid">
      Send
    </button>
    <button type="button" class="btn btn-gray btn-wide" (click)="cancel()">Discard</button>
  </div>

  <ng-template #modal>
    <div class="main-actions text-right">
      <button type="button"
              data-dismiss="modal"
              (click)="cancel()"
              class="btn-dashboard-message-override btn-gray"><i class="fa fa-times"></i> Cancel
      </button>
      <button type="button" class="btn-dashboard-message-override btn-primary"
              [disabled]="!contacts || contacts.length === 0 || !messageForm.valid"
              (click)="sendReply()"><i class="fa fa-check"></i> Send
      </button>
    </div>
  </ng-template>
</form>

<townip-modal heading="Add Attachment to Message" size="lg" (onHide)="discardAttachments()" #attachmentModal>
  <townip-modal-body>
    <townip-message-file-upload
      [displayUploadContainer]="true"
      (onUpdateFiles)="uploadedFiles = $event"
      (onRemoveFile)="uploadedFilesToDelete = $event"></townip-message-file-upload>
  </townip-modal-body>
  <townip-modal-footer>
    <button class="btn btn-secondary btn-large padding-10 margin-right-5 text-uppercase" (click)="discardAttachments()">
      Cancel
    </button>
    <button class="btn btn-primary btn-large padding-10 text-uppercase"
            (click)="onConfirmAttachments()"
            [disabled]="!uploadedFiles || uploadedFiles.length === 0">
      Confirm
    </button>
  </townip-modal-footer>
</townip-modal>

