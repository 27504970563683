<div fillHeight class="d-flex align-items-center justify-content-center">
  <div class="error-container">
    <span class="error-code">403</span>
    <div class="error-content text-center">
      <div class="error-title">You don't have access</div>
      <div class="error-text">
        <a href="#" routerLink="/dashboard" *ngIf="!disabledStatus"><i class="fa fa-arrow-left"></i> Back to dashboard</a>
        <a href="#" routerLink="/auth/login" *ngIf="disabledStatus"><i class="fa fa-arrow-left"></i> Back to login</a>
      </div>

      <ng-container>
        <div class="alert alert-warning user-disabled" *ngIf="disabledStatus === disabledStatuses.CompanyOffline">
          You are accessing an offline account <br />
          Please contact <a href="mailto: support@legalsight.com">customer support.</a>
        </div>
      </ng-container>
      <ng-container  *ngIf="disabledStatus === disabledStatuses.CompanyDisabled">
        <div class="alert alert-warning user-disabled">
          Your company is currently disabled <br />
          Please contact <a href="mailto: support@legalsight.com">customer support.</a>
        </div>
      </ng-container>
      <ng-container *ngIf="disabledStatus === disabledStatuses.UserDisabled">
        <div class="alert alert-warning user-disabled">
          Your user account is disabled <br />
          Please contact your company administrator.
        </div>
      </ng-container>
      <ng-container *ngIf="disabledStatus === disabledStatuses.Other">
        <div class="alert alert-warning user-disabled">
          Login failed.<br />
          Our service may temporarily be offline.
        </div>
      </ng-container>
    </div>
  </div>
</div>
