import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AppContextService } from '../services/app-context.service';

@Injectable()
export class DisallowLegalsightGuard  {

  constructor(private appContext: AppContextService, private router: Router) {

  }

  public canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    // Check for legalsight context. If legalsight context is active,
    // this guard prevents the user from accessing the rest of the system
    if (this.appContext.isLegalsight) {
      this.router.navigate(['/error-auth-only']);
      return false;
    }

    return true;
  }
}
