<div class="input-chip-container d-flex flex-wrap message-recipients-container mb-2 p-1 margin-10"
     (click)="setFocus()">
  <div class="chip-container" *ngFor="let user of newlySelected">
    <div class="chip">{{user.label}}
      <button type="button" class="fa-button close-button" (click)="$event.stopPropagation(); remove(user)">
        <i class="fa fa-times"></i>
      </button>
    </div>
  </div>
  <div class="w-100">
    <ng-container>
      <input #inputSelection [(ngModel)]="tempSelection"
             (ngModelChange)="onSearchKeyChange($event)"
             class="message-recipients form-control form-control-sm no-border">
    </ng-container>
  </div>
</div>
<div class="find-description mb-2 margin-10" *ngIf="isInternal">{{description}}</div>
<div class="margin-left-10 mb-2">
  <button class="btn btn-primary btn-sm mr-3 btn-recipient"
          type="button"
          [disabled]="!newlySelected || newlySelected.length === 0"
          (click)="onAddNewRecipients()">
    Add Recipients
  </button>
  <button type="button" class="btn btn-link btn-sm" (click)="onClearAll()">Clear All</button>
</div>
<div *ngIf="filteredUsers" class="overflow-auto" [ngStyle]="{'height.px': userListHeight}">
  <div class="mb-4">
    <ng-container *ngFor="let groupedUser of filteredUsers | groupByRecipientType; let i = index">
      <!-- Display of User Type  -->
      <div class="ml-2">
        <hr *ngIf="i !== 0" class="separator">
        <span class="user-type font-weight-bold pl-2">
            {{ groupedUser.recipientType | uppercase }}
          </span>
      </div>

      <ng-container *ngFor="let userOrganization of groupedUser | generateCompany: filteredUsers">
        <ng-container *ngIf="userOrganization; else displayInternalUsers">
          <!-- Display of User Organization -->
          <div class="pl-3">
              <span (click)="$event.stopPropagation(); selectOrganization(userOrganization)" class="org-name">
                {{ userOrganization | uppercase }}
              </span>
          </div>
          <!-- Display of User -->
          <ng-container
            *ngFor="let companyUser of filteredUsers | companyUsers:userOrganization:groupedUser.recipientType">
            <ng-container *ngTemplateOutlet="userOption; context: {user:companyUser}"></ng-container>
          </ng-container>
        </ng-container>

        <ng-template #displayInternalUsers>
          <ng-container *ngFor="let companyUser of groupedUser.users | sortBy: indexedUserSort">
            <ng-container *ngTemplateOutlet="userOption; context: {user:companyUser}"></ng-container>
          </ng-container>
        </ng-template>
      </ng-container>

      <!-- Display of User Details -->
      <ng-template #userOption let-user="user">
        <townip-user-card
          (click)="$event.stopPropagation(); select(user)"
          class="user-details mb-1 pl-3"
          *ngIf="!user.value.isOrganization">
          <townip-user-image class="text-center d-block">
            <townip-initials [size]="30" [data]="user.label"></townip-initials>
          </townip-user-image>
          <townip-user-name>
            <townip-text-highlighter [word]="tempSelection">
              <span class="username">
                {{ user.label }}
              </span>
              <span class="pull-right" *ngIf="isUserSelected(user)">
                <i class="fa fa-check"></i>
              </span>
            </townip-text-highlighter>
          </townip-user-name>
        </townip-user-card>
      </ng-template>
    </ng-container>
  </div>
</div>
