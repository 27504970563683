import { Injectable } from '@angular/core';
import { WebsocketService } from './websocket.service';
import { Subscription } from 'rxjs';
import { ConfirmModalService } from './confirm-modal.service';
import { BroadcastMessage } from '../dto/status/broadcast-message';
import { UserService } from './user.service';
import { ConfigService } from '../../data-services/config.service';
import { HttpRequestErrorInterceptorMessages } from '../interceptors/http-request-error-interceptor-messages';

@Injectable()
export class BroadcastService {

  private broadcastSubscription: Subscription;

  constructor(private websocketService: WebsocketService,
              private confirmModalService: ConfirmModalService,
              private userService: UserService,
              private configService: ConfigService,
              private httpRequestErrorMessages: HttpRequestErrorInterceptorMessages) {
  }

  public initialize(): void {
    this.broadcastSubscription = this.websocketService.websocketUpdateEmitter.subscribe(websocketMessage => {
      if (websocketMessage.message.type === 'BROADCAST') {
        this.processBroadcast(websocketMessage.message);
      }
    });
  }

  public cleanup(): void {
    if (this.broadcastSubscription) {
      this.broadcastSubscription.unsubscribe();
    }
  }

  private processBroadcast(broadcastMessage: BroadcastMessage): void {
    if (broadcastMessage.updateType === 'CONFIG') {
      this.httpRequestErrorMessages.add({
        url: `/api/system_config/${broadcastMessage.id}?type=${broadcastMessage.configurationType}`,
        bypass: true, permanent: false
      });
      this.configService.retrieveConfigItem(broadcastMessage.configurationType, broadcastMessage.id)
        .then((configUpdate) => {
          this.configService.updateSystemConfigValue(broadcastMessage.configurationType, configUpdate);
        });
    } else {
      this.confirmModalService.confirmDialog(broadcastMessage.message as string, 'Broadcast', 'Ok', 'No', false)
        .then(accepted => {
          if (accepted) {
            if (broadcastMessage.updateType === 'RELOAD') {
              window.location.reload();
            } else if (broadcastMessage.updateType === 'LOGOUT') {
              this.userService.logout();
            }
          }
        });
    }
  }
}
