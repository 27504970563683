import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'townip-startup-page',
  templateUrl: './startup-page.component.html',
  styleUrls: ['./startup-page.component.scss']
})
export class StartupPageComponent implements OnInit {

  constructor() { }

  public ngOnInit(): void {
  }

}
