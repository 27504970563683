import { Component, OnInit } from '@angular/core';
import { ConnectionDaemonService, ConnectionDaemonStatusCode } from '../../shared/services/connection-daemon.service';

@Component({
  selector: 'townip-offline-screen',
  templateUrl: './offline-screen.component.html',
  styleUrls: ['./offline-screen.component.scss']
})
export class OfflineScreenComponent implements OnInit {

  public statusCodes = ConnectionDaemonStatusCode;

  public currentStatus: ConnectionDaemonStatusCode;

  constructor(private connectionDaemon: ConnectionDaemonService) { }

  public ngOnInit(): void {
    this.connectionDaemon
      .status
      .subscribe((val) => {
        this.currentStatus = val;
      });
  }

}
