<townip-modal
	zIndex="1000000"
	class="tosModal"
	size="lg"
	heading="Terms of Use"
	[showCloseButton]="false">
  <townip-modal-body>
    <townip-standard-alert *ngIf="termsOfUse" class="mt-4" type="info" mode="lite">
      Please agree to our new <span class="font-weight-bold">Terms of Use</span>
      updated on {{termsOfUse.releaseDate | standardDate}} to continue.
    </townip-standard-alert>
    <div class="modal-body" style="padding-top: 30px;">
      <p-scrollPanel [style]="{width: '100%', height: '350px', 'border-radius': '4px'}">
        <div class="padding-20">
          <townip-terms-of-use-content [tosId]="term?.termsOfUseId"></townip-terms-of-use-content>
        </div>
      </p-scrollPanel>

      <div class="margin-top-20 font-weight-bold tos-border px-4 py-3">
        <div class="d-flex full-width">
          <p-checkbox [(ngModel)]="accepted" [binary]="true" labelStyleClass=""></p-checkbox>
          <div *ngIf="term?.type === 'COMPANY_FORECASTER'; else mainSystem" class="margin-left-5 font-weight-bold">
            I accept the Chief IP Terms of Use and
            <a href="https://chiefip.com/privacy-policy/ " target="_blank">Privacy Policy</a>
          </div>
          <ng-template #mainSystem>
            <div class="margin-left-5 font-weight-bold">I accept the Sun IP Terms of Use and
              <a href="https://sunip.com/cookies-and-privacy-policy/" target="_blank">Privacy Policy</a>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </townip-modal-body>
  <townip-modal-footer>
    <button class="btn btn-secondary" (click)="decline()">
      <i class="fa fa-close mr-2"></i>Cancel
    </button>
    <button class="btn btn-primary" *ngIf="accepted" (click)="save()">
      <i class="fa fa-check mr-2"></i>Accept
    </button>
  </townip-modal-footer>
</townip-modal>
