<span [ngClass]="showAsBadge ? 'badge badge-secondary status-padding' : 'delivery-contact-badge'">
  <ng-container [ngSwitch]="filerType">
    <ng-container *ngSwitchCase="filerTypes.SUN_IP">
      <span>
        Sun IP FA
      </span>
    </ng-container>
    <ng-container *ngSwitchCase="filerTypes.CLIENT_PLATFORM">
      <span>
        Client FA via Platform
      </span>
    </ng-container>
    <ng-container *ngSwitchCase="filerTypes.CLIENT_EMAIL">
      <span>
        Client FA via Email
      </span>
    </ng-container>
  </ng-container>
</span>
