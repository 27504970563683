import { EventEmitter, Injectable, Injector } from '@angular/core';
import { LoggingService } from '../../shared/logging.service';
import { environment } from '../../../environments/environment';
import { MessageService } from 'primeng/api';
import * as rg4js from 'raygun4js';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorLogService } from '../../errors/error-log.service';
import { TimeoutError } from 'rxjs';

if (environment.production) {
  rg4js('apiKey', environment.raygunKey);
  rg4js('setVersion', environment.version);
  rg4js('enableCrashReporting', true);
  rg4js('enablePulse', true);
}

export function sendToRaygun(error: any, customData?: any): void {
  // Raygun only on production environments
  if (!environment.production) {
    return;
  }

  rg4js('send', {
    error: error,
    customData: customData
  });
}

@Injectable()
export class GlobalErrorHandlerService {

  public errorEmitter: EventEmitter<boolean>;

  public isAppInErrorState = false;


  constructor(private injector: Injector) {
    this.errorEmitter = new EventEmitter();
  }

  public handleError(error: Error): void {
    let timeout = 0;
    const loggingService = this.injector.get(LoggingService);
    const errorLogService = this.injector.get(ErrorLogService);
    const growlService = this.injector.get(MessageService);
    const message = error.message ? error.message : error.toString();

    // Wrap in try catch to make sure we don't cause error on the error handler
    try {
      errorLogService.setError(`${error.name} - ${error.message}`);
    } catch (e) {
      errorLogService.setError('An unknown error occurred.');
    }

    if (error instanceof HttpErrorResponse ||
      this.errorStringify(error)
        .indexOf('HttpErrorResponse') > -1) {
      // HTTP-based errors has it's own handler.
      // They must be handled in HttpRequestErrorInterceptor.
      this.sendToRaygun(error);
      return;
    }

    if (error instanceof TimeoutError) {
      // Timeout errors are thrown in rxjs observables when we put a timeout limit explicitly
      // It's not really fatal so we don't need to redirect to 404 pages;
      // This notification will only show if the error has not been handled in subscribe();
      growlService.add({
        severity: 'error', summary: 'Error',
        detail: 'System Timeout'
      });

      this.sendToRaygun(error);
      return;
    }

    if (typeof error === 'string' && error === 'Timeout') {
      // Similar to above, this one is a timeout we get from ReCaptcha when
      // it can't find the instance of the button anymore.
      // No need to growl any errors as this is third party
      return;
    }

    if (loggingService && environment.production) {
      loggingService.error(message);
    }
    if (message.indexOf('The connection has not been established yet') > 0) {
      /* empty */
    } else if (message.indexOf('ExpressionChangedAfterItHasBeenCheckedError') < 0) {
      if (!environment.production) {
        timeout = 600000;
        growlService.add({
          severity: 'error', summary: 'Error',
          detail: 'An exception occurred, please capture this exception and refresh the page.'
        });
      } else {
        this.sendToRaygun(error);
      }
      if (!this.isAppInErrorState) {
        this.isAppInErrorState = true;
        this.errorEmitter.emit(this.isAppInErrorState);
      }
      if (loggingService) {
        loggingService.info('Redirecting to error page');
      }
      setTimeout(() => {
        // Uncaught promises are not fatal errors, no need to redirect them to 404.
        // This will conflict with the rest of our online/offline handling and redirection
        if (message.indexOf('Uncaught (in promise)') > -1) {
          return;
        }

        const router = this.injector.get(Router);

        // Check if we can get the router instance.
        if (router) {
          router.navigate(['/error-404'], { skipLocationChange: true });
        } else {
          // If we don't have the router instance, hard-navigate to refresh the app and display the error page.
          window.location.href = '/error-404';
        }

      }, timeout);
    }
  }

  private sendToRaygun(error: any, customData?: any): void {
    // Raygun only on production environments
    if (!environment.production) {
      return;
    }

    sendToRaygun(error, customData);
  }

  /**
   * Stringifies the error.
   * Used if the instanceOf does not work as expected
   *
   * @param error
   */
  private errorStringify(error: Error): string {
    // Needs to wrapped in try-catch to handle unexpected behavior in all types of error
    try {
      return error.toString();
    } catch (e) {
      return '';
    }
  }
}
