/**
 * Data Filter Class.
 * Used for filtering a set of complex array data, mainly used on DataFilter.pipe that is implemented on
 * ProjectOverview and Task Overview.
 *
 * Used originally as a Pipe but was converted to a class so it can be used elsewhere when filtering is needed
 * for projects or tasks
 *
 * Simplest usage: (just pass an array and an array of FilterGroup)
 *
 * // Will return a filtered array of the items
 * let filteredArray = new DataFilter(items, filters).getValues()
 *
 */

/* Other Usage Example

let filteredItems = [];
let dataset = new DataFilter(items, filterGroups);

dataset.changed.subscribe((filtered) => {
  filteredItems = filtered;
});

// Will emit the changed event
dataset.setFilters(filterGroups);

// Will apply the current filters and emit changed event
dataset.setData(newItems);
*/

import * as _ from 'underscore';
import * as moment from 'moment';
import { FilterGroup, FilterParameters } from './filter';
import { FieldType } from './field-type';
import { Subject } from 'rxjs';
import { isNullOrUndefined } from '../utils/is-null-or-undefined';

interface MergedFilters {
  and: FilterParameters[];
  or: FilterParameters[];
}

export class DataFilter<T> {

  public changed: Subject<T[]>;

  private values: T[];

  private filteredValues: T[];

  private filters: FilterGroup[];

  constructor(data?: T[], filters?: FilterGroup[]) {
    this.changed = new Subject();
    this.setData(data);
    this.setFilters(filters);
  }

  public getValues(): T[] {
    return this.filteredValues;
  }

  public setData(data: T[]): void {
    this.values = data ? data : [];
    this.applyFilters();
  }

  public setFilters(filters: FilterGroup[]): void {
    this.filters = filters ? filters : [];
    this.applyFilters();
  }

  public applyFilters(): void {
    this.filteredValues = this.transform(this.values, this.filters);
    this.changed.next(this.filteredValues);
  }

  private transform(value: any[], filters?: FilterGroup[]): any[] {
    // NOTE: We are using any here as this filter can accept any type of models.
    if (value === undefined || filters === undefined) {
      return value;
    }

    // If no filters are present, return the given value (unfiltered)
    if (filters && filters.length === 0) {
      return value;
    }

    const mergedFilters: MergedFilters = this.mergeFilters(filters);

    // Contains all the filtered items
    const filtered: any[] = [];

    for (const item of value) {
      // Basically here, if the item matches either and/or, match will have a value
      // If null value, means it didn't match any of the or filters
      let match = null;

      if (mergedFilters.and.length > 0 && mergedFilters.or.length > 0) {
        if (this.matchesOr(item, mergedFilters.or) && this.matches(item, mergedFilters.and)) {
          match = item;
        }
      } else {

        const matchAnd  = this.matches(item, mergedFilters.and);
        if (matchAnd) {
          match = item;
        }

        const matchOr = this.matchesOr(item, mergedFilters.or);
        if (matchOr) {
          match = item;
        }
      }

      // Actually push item
      if (match) {
        filtered.push(match);
      }
    }

    return filtered;
  }

  private mergeFilters(filters: FilterGroup[]): MergedFilters {
    const andFilters = _.filter(filters, (curFilter) => {
      if (curFilter.isOr === false || curFilter.isOr === undefined) {
        return true;
      }
    });

    const orFilters = _.filter(filters, (curFilter) => {
      if (curFilter.isOr === true) {
        return true;
      }
    });

    // All the filter params in AND group
    const andParams = [];

    // All the filter params in OR group
    const orParams = [];

    for (const fg of andFilters) {
      for (const fp of fg.filters) {
        andParams.push(fp);
      }
    }

    for (const fg of orFilters) {
      for (const fp of fg.filters) {
        orParams.push(fp);
      }
    }

    return {
      and: andParams,
      or: orParams,
    };
  }

  /**
   * We are processing the filters using AND which means all of the filters must pass
   * If any of the filters in the array fails, it will break the loop and return as false/fail
   * @param item
   * @param filters
   * @returns {boolean}
   */
  private matches(item: any, filters: FilterParameters[]): boolean {
    // Guard - if filters are empty, it must automatically fail
    if (filters.length === 0) {
      return false;
    }

    let isMatch = false;
    const results = [];

    for (const filter of filters) {
      if (filter.type === FieldType.String && this.matchString(item, filter) === false) {
        // isMatch = true;
        // break;
        results.push(false);
      }

      if (filter.type === FieldType.Numeric && this.matchNumbers(item, filter) === false) {
        // isMatch = true;
        // break;
        results.push(false);
      }

      if (filter.type === FieldType.Boolean && this.matchBool(item, filter) === false) {
        // isMatch = true;
        // break;
        results.push(false);
      }

      if (filter.type === FieldType.Date && this.matchDate(item, filter) === false) {
        // isMatch = true;
        // break;
        results.push(false);
      }

      if (filter.type === FieldType.Collection && this.matchCollection(item, filter) === false) {
        // isMatch = true;
        // break;
        results.push(false);
      }

      if (filter.type === FieldType.Array && this.matchArray(item, filter) === false) {
        // isMatch = true;
        // break;
        results.push(false);
      }
    }

    if (results.length > 0) {
      isMatch = false;
    } else {
      isMatch = true;
    }

    return isMatch;
  }

  /**
   * We are processing the filters using OR means any of the filters must pass
   * @param item
   * @param filters
   * @returns {boolean}
   */
  private matchesOr(item: any, filters: FilterParameters[]): boolean {
    let isMatch = false;

    for (const filter of filters) {

      if (filter.type === FieldType.String && this.matchString(item, filter)) {
        isMatch = true;
      }

      if (filter.type === FieldType.Numeric && this.matchNumbers(item, filter)) {
        isMatch = true;
      }

      if (filter.type === FieldType.Boolean && this.matchBool(item, filter)) {
        isMatch = true;
      }

      if (filter.type === FieldType.Date && this.matchDate(item, filter)) {
        isMatch = true;
      }

      if (filter.type === FieldType.Collection && this.matchCollection(item, filter)) {
        isMatch = true;
      }

      if (filter.type === FieldType.Array && this.matchArray(item, filter)) {
        isMatch = true;
      }
    }

    return isMatch;
  }

  private matchNumbers(item: any, filter: FilterParameters): boolean {
    let passed = true;
    let itemValue = this.resolveFieldData(item, filter.field);

    // If the item we are comparing has a value of null or undefined, treat it as 0
    // So  that these values will still trigger nicely when it's compared against an actual 0 number
    if (isNullOrUndefined(itemValue)) {
      itemValue = 0;
    }

    if (filter.operator === '=') {
      if (itemValue !== filter.value) {
        passed = false;
        return passed;
      }
    }

    if (filter.operator === '!=') {
      if (itemValue === filter.value) {
        passed = false;
        return passed;
      }
    }

    if (filter.operator === '>') {
      if (itemValue < filter.value) {
        passed = false;
        return passed;
      }
    }

    if (filter.operator === '>=') {
      if (itemValue <= filter.value) {
        passed = false;
        return passed;
      }
    }

    if (filter.operator === '<') {
      if (itemValue > filter.value) {
        passed = false;
        return passed;
      }
    }

    if (filter.operator === '<=') {
      if (itemValue >= filter.value) {
        passed = false;
        return passed;
      }
    }

    if (filter.operator === 'contains') {
      // If the given value is null or undefined, will automatically fail the filter.
      if (isNullOrUndefined(filter.value)) {
        passed = false;
        return passed;
      }

      // Parsing an actual array
      if (_.isArray(filter.value) && _.contains(filter.value, itemValue) === false) {
        // console.log('the input is an array!');
        passed = false;
        return passed;
      }

      // Parsing a comma-separated numeric value
      if (!_.isArray(filter.value)) {
        if (filter.value) {
          const values = filter.value.split(',');
          for (let value of values) {
            // Trim whitespaces and convert to numeric
            value = +value.trim();
          }

          // If it does not contain the value, it fails
          if (!_.contains(values, itemValue)) {
            passed = false;
            return passed;
          }
        }
      }
    }

    if (filter.operator === 'does-not-contain') {
      // Parsing an actual array
      if (_.isArray(filter.value) && _.contains(filter.value, itemValue) === true) {
        passed = false;
        return passed;
      }

      // Parsing a comma-separated numeric value
      if (!_.isArray(filter.value)) {
        const values = filter.value.split(',');
        for (let value of values) {
          // Trim whitespaces and convert to numeric
          value = +value.trim();
        }

        // If it contains the value, it fails
        if (_.contains(values, itemValue)) {
          passed = false;
          return passed;
        }
      }
    }

    // For range types, between
    if (filter.operator === 'between') {
      if (itemValue >= filter.value[0] && itemValue <= filter.value[1]) {
        passed = true;
        return passed;
      } else {
        passed = false;
        return passed;
      }
    }

    // Return the default
    return passed;
  }

  public getItemValue(item: any, filter: FilterParameters): any {
    if (filter.fieldValueOverride) {
      return filter.fieldValueOverride(item, filter);
    }
    return this.resolveFieldData(item, filter.field);
  }

  private matchString(item: any, filter: FilterParameters): boolean {
    let passed = true;
    const itemValue = this.getItemValue(item, filter);

    // This will be used to get Unassigned Tasks
    if (filter.name === 'Unassigned' && isNullOrUndefined(itemValue)) {
      passed = true;
      return passed;
    }

    if (itemValue === null && filter.value === null || filter.value === 'null') {
      passed = true;
      return passed;
    }

    if (itemValue === null || itemValue === undefined) {
      passed = false;
      return passed;
    }

    if (filter.operator === '=') {
      if (itemValue === undefined || itemValue === null) {
        passed = false;
        return passed;
      }

      if (itemValue !== filter.value) {
        passed = false;
        return passed;
      }
      return passed;
    }

    if (filter.operator === '!=') {
      if (itemValue === filter.value) {
        passed = false;
        return passed;
      }

      return passed;
    }

    if (filter.operator === 'contains') {
      // Parsing an actual array
      if (_.isArray(filter.value) && _.contains(filter.value, itemValue) === false) {
        // console.log('the input is an array!');
        passed = false;
        return passed;
      }

      // Parsing a comma-separated string
      if (! _.isArray(filter.value)) {
        const values = filter.value.split(',');
        const valuesTrimmed = [];
        for (let value of values) {
          // Trim whitespaces
          value = value.trim();
          valuesTrimmed.push(value.trim());
        }

        // If it does not contain the value, it fails
        if (_.contains(valuesTrimmed, itemValue) === false) {
          passed = false;
          return passed;
        }
      }
    }


    if (filter.operator === 'does-not-contain') {
      if (_.isArray(filter.value) && _.contains(filter.value, itemValue) === true) {
        passed = false;
        return passed;
      }

      // Parsing a comma-separated string
      if (! _.isArray(filter.value)) {
        const values = filter.value.split(',');
        for (let value of values) {
          // Trim whitespaces
          value = value.trim();
        }

        // If it contains the value, it fails
        if (_.contains(values, itemValue)) {
          passed = false;
          return passed;
        }
      }
    }


    // Return the default value
    return passed;
  }

  private matchBool(item: any, filter: FilterParameters): boolean {
    let passed = true;
    // const itemValue = this.resolveFieldData(item, filter.field);
    const itemValue = this.getItemValue(item, filter);

    if (this.isNullOrUndefined(itemValue, filter.value)) {
      return passed;
    }

    if (filter.operator === '=') {
      if (itemValue !== filter.value) {
        passed = false;
        return passed;
      }
    }

    if (filter.operator === '!=') {
      if (itemValue === filter.value) {
        passed = false;
        return passed;
      }
    }

    return passed;
  }

  private matchDate(item: any, filter: FilterParameters): boolean {
    // const itemValue = this.resolveFieldData(item, filter.field);
    const itemValue = this.getItemValue(item, filter);
    const start = moment(filter.value[0])
      .startOf('day')
      .valueOf();
    const end = moment(filter.value[1])
      .endOf('day')
      .valueOf();

    if (this.isNullOrUndefined(itemValue, filter.value)) {
      return true;
    }

    if (itemValue >= start && itemValue <= end) {
      return true;
    }

    return false;
  }

  private matchCollection(item: any, filter: FilterParameters): boolean {
    let passed = false;
    // const itemValues: any[] = this.resolveFieldData(item, filter.field);
    const itemValues: any[] = this.getItemValue(item, filter);

    if (filter.operator === 'contains') {
      for (const value of filter.value) {
        const queryParam = {};
        queryParam[filter.collectionField] = value;

        // If it did not find the value
        if (_.findWhere(itemValues, queryParam)) {
          passed = true;
          break;
        }
      }
    }

    if (filter.operator === 'does-not-contain') {
      for (const value of filter.value) {
        const queryParam = {};
        queryParam[filter.collectionField] = value;

        // If it did not find the value
        if (_.findWhere(itemValues, queryParam) === undefined) {
          passed = true;
          break;
        }
      }
    }

    return passed;
  }

  private matchArray(item: any, filter: FilterParameters): boolean {
    let passed = false;
    const itemValues: any[] = this.getItemValue(item, filter);
    // debugger;

    if (filter.operator === 'contains') {
      for (const value of filter.value) {
        // If it did not find the value
        if (_.contains(itemValues, value)) {
          passed = true;
          // debugger;
          break;
        }
      }
    }

    if (filter.operator === 'does-not-contain') {
      for (const value of filter.value) {
        // If it did not find the value
        if (_.contains(itemValues, value) === false) {
          passed = true;
          break;
        }
      }
    }

    return passed;
  }

  /**
   * Checks wether the compared values are null or undefined
   * Used in function guards to that we don't need to do processing on these values
   * NOTE: Probably need to convert the lines using this to use the built-in native typescript one
   * @param value1
   * @param value2
   * @returns {boolean}
   */
  private isNullOrUndefined(value1: any, value2: any): boolean {
    if (value1 === undefined && value2 === undefined) {
      return true;
    }

    if (value1 === null && value2 === null) {
      return true;
    }

    if (value1 === undefined && value2 === null) {
      return true;
    }

    if (value1 === null && value2 === undefined) {
      return true;
    }

    return false;
  }

  private resolveFieldData(obj: any, prop: string): any {

    let resolve;

    try {
      const arr = prop.split('.');
      while (arr.length) {
        obj = obj[arr.shift()];
      }
      resolve = obj;
    } catch (e) {
      // When the operation error-ed out
      // Mostly when the property you are trying to reach is undefined
      resolve = null;
    }
    return resolve;
  }
}
