import { Observable, throwError as observableThrowError } from 'rxjs';
import { EventEmitter, Injectable } from '@angular/core';
import { FilingInformation } from '../shared/dto/projects/filing-information';
import { ProjectResponse } from '../shared/response/project-response';
import { OrderResponse } from '../shared/response/order-response';
import { OrderUpdateRequest } from '../shared/dto/order/order-update-request';
import { OrderDetailsResponse } from '../shared/response/order-details-response';
import { FileKeyPair } from '../shared/dto/file-key';
import { RequestService } from '../shared/services/request-service';
import { OrderSummaryResponse } from '../shared/response/order-summary-response';
import { FileKeySupplementalData } from '../shared/dto/file-key-supplemental-data';
import { ReleaseDocumentsRequest } from '../shared/dto/projects/release-documents-request';
import { JurisdictionOrderInfo } from '../shared/dto/jurisdiction-order-info';
import { HttpClient } from '@angular/common/http';
import { UpdateOrderFileRequest } from '../shared/request/update-order-file-request';
import { UpdateOrderFileTaskRequest } from '../shared/request/update-order-file-task-request';
import { OrderOrganizationReference } from '../shared/dto/projects/order-organization-reference';
import { WebsocketService } from '../shared/services/websocket.service';
import { LoggingService } from '../shared/logging.service';
import { ProjectComponent } from '../shared/components/task-action/project-component';
import { VendorLite } from '../shared/dto/vendor-management/vendor-lite';
import { VendorFilingContact } from '../shared/dto/vendor-management/VendorFilingContact';
import { CustomerPreferences } from '../shared/dto/preferences/customer-preferences';
import { ApiQueryOptions, ApiQueryParams } from '../shared/api-query-params';
import { ServiceType } from '../shared/dto/system-config/service';
import { GeneralResponseMessage } from '../shared/dto/general-response-message';
import { OrderPatentProjectDocument } from '../shared/dto/projects/order-patent-project-document';
import { VendorOrderReferences } from '../shared/dto/projects/vendor-order-references';
import { tap } from 'rxjs/operators';
import { sortBy } from '../shared/utils/sort-by';

// Use this pattern if sharing URLS from other services.
export const ORDER_SERVICE_URLS = {
  orderFileCategories: '/api/orders/{orderId}/available/file/categories'
};

@Injectable()
export class OrderService {

  public estimateResponse: ProjectResponse[];

  public orderDetailsResponse: OrderDetailsResponse;

  public orderSummaryResponseEmitter: EventEmitter<OrderSummaryResponse>;

  public orderEventEmitter: EventEmitter<OrderDetailsResponse>;

  constructor(private httpClient: HttpClient,
              private requestService: RequestService,
              private websocketService: WebsocketService,
              private loggingService: LoggingService) {
    this.orderSummaryResponseEmitter = new EventEmitter();

    this.orderEventEmitter = new EventEmitter();

    this.websocketService.websocketUpdateEmitter.subscribe(data => {
      if (data.message.type === 'ORDER') {
        this.loggingService.info('Received order websocket message');
        this.getOrderDetails(data.message.id)
          .toPromise()
          .then(orderDetails => {
            this.orderEventEmitter.emit(orderDetails);
          });
      }
    });
  }

  public setEstimateResponse(theEstimates: ProjectResponse[]): void {
    this.estimateResponse = theEstimates;
  }

  public getEstimateResponse(): ProjectResponse {
    if (!!this.estimateResponse && this.estimateResponse.length === 1) {
      return this.estimateResponse[0];
    }
    return null;
  }

  public getEstimateResponses(): ProjectResponse[] {
    return this.estimateResponse;
  }

  public setOrderDetailsResponse(orderDetailsResponse: OrderDetailsResponse): void {
    this.orderDetailsResponse = orderDetailsResponse;
  }

  public getOrderDetailsResponse(): OrderDetailsResponse {
    return this.orderDetailsResponse;
  }

  public sendUpdateOrderOrganizationReference(orderId: number,
    updateRequest: OrderOrganizationReference):
    Promise<OrderOrganizationReference> {
    const url = `/api/orders/reference/${orderId}`;
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.put<OrderOrganizationReference>(url, updateRequest, { headers: headers })
      .toPromise();
  }

  public saveVendorReferenceNumber(
    orderId: number,
    vendorId: number,
    updateRequest: VendorOrderReferences,
  ): Observable<VendorOrderReferences> {
    const url = `/api/orders/reference/${orderId}/vendor/${vendorId}`;
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient
      .put<VendorOrderReferences>(url, updateRequest, { headers: headers })
      .pipe(
        tap((response: VendorOrderReferences) => {
          response.countryReferenceNumbers = sortBy(response.countryReferenceNumbers, 'country.name');
        })
      );
  }

  public sendUpdateOrderRequest(updateRequest: OrderUpdateRequest): Promise<OrderResponse> {
    const url = `/api/orders/${updateRequest.orderId}`;
    const headers = this.requestService.buildHttpHeaders();
    // TODO: Response type dto for sendUpdateOrderRequest
    return this.httpClient.put<OrderResponse>(url, updateRequest, { headers: headers })
      .toPromise();
  }

  public sendUpdateJurisdictionRequest(orderId: number,
    updateRequest: JurisdictionOrderInfo): Promise<GeneralResponseMessage> {
    const url = `/api/orders/${orderId}/jurisdiction/${updateRequest.jurisdictionId}`;
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.put<any>(url, updateRequest, { headers: headers })
      .toPromise();
  }

  public sendUpdateJurisdictionRequests(orderId: number,
    updateRequests: JurisdictionOrderInfo[]): Promise<GeneralResponseMessage> {
    const url = `/api/orders/${orderId}/jurisdictions`;
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.put<GeneralResponseMessage>(url, updateRequests, { headers: headers })
      .toPromise();
  }

  public getOrderDetails(orderId: number): Observable<OrderDetailsResponse> {
    const url = `/api/orders/details/${orderId}`;
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.get<OrderDetailsResponse>(url, { headers: headers });
  }

  public getVendorsForProject(projectId: number): Observable<VendorLite[]> {
    const url = `api/vendors/project/${projectId}`;
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.get<VendorLite[]>(url, { headers: headers });
  }

  public getOrderSummary(orderId: number): Observable<OrderSummaryResponse> {
    const url = `/api/orders/summary/${orderId}`;
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.get<any>(url, { headers: headers });
  }

  public emitOrderSummary(summary: OrderSummaryResponse): void {
    this.orderSummaryResponseEmitter.emit(summary);
  }

  public getOrderDocuments(orderId: number,
    releaseRequest: ReleaseDocumentsRequest): Observable<FileKeySupplementalData[]> {
    const url = `/api/orders/${orderId}/documents`;
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.post<any>(url, releaseRequest, { headers: headers });
  }

  public getOrderComponentDeliveredDocuments(projectComponent: ProjectComponent):
    Observable<FileKeySupplementalData[]> {
    const orderId = projectComponent.projectId;
    const componentId = projectComponent.componentId;
    const documentId = projectComponent.documentId;
    const url = `/api/orders/${orderId}/component/${componentId}/documentId/${documentId}/delivered/documents`;
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.get<any>(url, { headers: headers });
  }

  public updateOrderFileStatus(orderId: number,
    suppressEmailAlert: boolean,
    updatedOrderFiles: UpdateOrderFileRequest[]): Observable<FileKeySupplementalData[]> {
    const url = `/api/orders/${orderId}/file-status/${suppressEmailAlert}`;
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.put<any>(url, updatedOrderFiles, { headers: headers });
  }

  public updateOrderFileTaskAssociation(orderId: number, updateOrderTaskAssociations: UpdateOrderFileTaskRequest): Observable<FileKeySupplementalData[]> {
    const url = `/api/orders/${orderId}/file-tasks`;
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.put<any>(url, updateOrderTaskAssociations, { headers: headers });
  }

  public addNewOrderFiles(orderId: number, files: FileKeyPair[]): Promise<FileKeySupplementalData[]> {
    const url = `/api/orders/${orderId}/new-files`;
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.put<any>(url, files, { headers: headers })
      .toPromise();
  }

  public toggleFileOrderTaskLink(orderId: number, taskId: number, file: FileKeySupplementalData): Promise<void> {
    const url = `/api/orders/${orderId}/task/${taskId}/toggle-link`;
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.post<void>(url, file, { headers: headers })
      .toPromise();
  }

  public updatePriorityFiles(orderId: number, files: FileKeySupplementalData[]): Observable<FileKeySupplementalData[]> {
    const url = `/api/orders/${orderId}/priority/files`;
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.put<FileKeySupplementalData[]>(url, files, { headers: headers });
  }

  public getOrderAvailableFileCategoryId(orderId: number): Promise<number[]> {
    const url = ORDER_SERVICE_URLS.orderFileCategories
      .replace('{orderId}', orderId.toString());

    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient
      .get<any>(url, { headers: headers })
      .toPromise();
  }

  public updateProjectName(orderId: number, projectName: string): Observable<OrderDetailsResponse> {
    let url = `/api/order/${orderId}/project/name`;
    const headers = this.requestService.buildHttpHeaders();

    const queryOptions: ApiQueryOptions = {
      queryParams: { name: projectName }
    };

    url += new ApiQueryParams(queryOptions).generateQueryParams();
    return this.httpClient.put<OrderDetailsResponse>(url, { headers: headers });
  }

  public getVendorFilingContact(orderId: number, countryId: number): Observable<VendorFilingContact> {
    const url = `/api/orders/${orderId}/country/${countryId}/vendor/filing/contact`;
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.get<VendorFilingContact>(url, { headers: headers });
  }

  public getCountryFilingInfo(orderId: number, countryId: number): Observable<FilingInformation> {
    const url = `/api/order/${orderId}/country/${countryId}`;
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.get<FilingInformation>(url, { headers: headers });
  }

  public putCountryFilingInfo(filingInfo: FilingInformation): Observable<FilingInformation> {
    const url = '/api/order/filing-delivery-contact';
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.put<FilingInformation>(url, filingInfo, { headers: headers });
  }

  public getOrderPreferenceSet(orderId: number): Observable<CustomerPreferences> {
    const url = `api/orders/${orderId}/preferences`;
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.get<CustomerPreferences>(url, { headers: headers });
  }

  public setNewProjectManagers(orderId: number, managerId: number): Observable<OrderResponse> {
    const url = `api/project/${orderId}/save/project/manager/${managerId}`;
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.put<OrderResponse>(url, { headers: headers });
  }

  public getOrderVendors(orderId: number, serviceTypes: ServiceType[]): Observable<VendorLite[]> {
    const url = `api/order/${orderId}/vendors?serviceTypes=` + serviceTypes.toString();
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.get<VendorLite[]>(url, { headers: headers });
  }

  public updatePatentDetails(
    orderId: number,
    orderPatentProjectDocument: OrderPatentProjectDocument
  ): Observable<ProjectResponse> {
    const url = `api/orders/${orderId}/update-patent-details`;
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.put<ProjectResponse>(url, orderPatentProjectDocument, { headers: headers });
  }
}
