import { Pipe, PipeTransform } from '@angular/core';
import { TypeUsers } from './group-by-recipient-type.pipe';
import * as _ from 'underscore';
import { IndexedSelectItem } from '../message-find-recipient/message-find-recipient.component';
import { isNullOrUndefined } from '../../../utils/is-null-or-undefined';

@Pipe({
  name: 'generateCompany'
})
export class GenerateCompanyPipe implements PipeTransform {

  public transform(recipient: TypeUsers, filteredUsers: IndexedSelectItem[]): string[] {
    if (!filteredUsers) {
      return null;
    }

    const typeUsers = filteredUsers.filter(user => user.value.recipientType === recipient.recipientType);

    if (typeUsers.length === 0 || isNullOrUndefined(typeUsers[0].value.organization)) {
      // Check the first user if it is Internal
      return [null];
    }

    const usersWithOrganization = filteredUsers.filter(user => !isNullOrUndefined(user.value.organization));

    // List of User Per Organization
    let userPerOrganization = _.uniq(usersWithOrganization, (user => {
      return user.value.organization;
    }));

    userPerOrganization = userPerOrganization.filter(user => user.value.recipientType === recipient.recipientType);

    // Sort By Organization
    userPerOrganization = _.sortBy(userPerOrganization, (user) => {
      return user.value.organization;
    });

    // Map each organization
    const organizations = userPerOrganization.map(user => {
      return user.value.organization;
    });

    return organizations;
  }

}
