import * as cloneDeep from 'lodash/cloneDeep';
import * as moment from 'moment';
import { includes } from '../../settings-v2/shared/utils/includes';
import { isNullOrUndefinedOrBlank } from '../utils/is-null-or-undefined-or-blank';
import { FieldType } from './field-type';
import { OPERATORS } from './filter-operators';
import { FilterSet } from './filter-set';
import { uniq } from '../uniq';
import { isNullOrUndefined } from '../utils/is-null-or-undefined';

/**
 * Handles encoding of FilterSet objects to RSQL string
 */
export class RsqlEncoder {

  /**
   * Utility function that combines all the queries by the specified operator.
   * @param queries
   * @param operator
   */
  public static combine(queries: string[], operator: 'and' | 'or' = 'and'): string {
    // const combined = queries.join(` ${operator} `);
    let combined = '';

    for (const query of queries) {
      if (isNullOrUndefined(query) || query === '') {
        continue;
      }

      combined += `(${ query }) ${ operator } `;
    }

    // Trim extra whitespace from generation
    combined = combined.trim();

    // The last operator gets trimmed;
    return combined
      .substring(0, combined.length - operator.length)
      .trim();
  }

  constructor() {

  }

  public encode(filter: FilterSet): string {
    return this.encodeGroup([filter]);
  }

  public encodeGroup(filters: FilterSet[], mergeOperator: 'and' | 'or' = 'and'): string {
    if (!filters || filters.length === 0) {
      return '';
    }

    let rsql = '';

    for (const filter of filters) {
      const operator = this.getOperator(filter);
      if (!operator) {
        continue;
      }

      rsql += `${operator} ${mergeOperator} `;
    }

    // Trim extra whitespace from generation
    rsql = rsql.trim();

    // The last operator gets trimmed;
    return rsql
      .substring(0, rsql.length - mergeOperator.length)
      .trim();
  }

  /**
   * Gets the equivalent RSQL operator for the give filter sets...
   * @param sourceFilter
   */
  private getOperator(sourceFilter: FilterSet): string {
    // Run transformations first for certain data types
    const filter = this.runTransformations(sourceFilter);

    // ---- COMMON OPERATORS ----
    const commonOperators = [
      OPERATORS.common.$eq,   // equal
      OPERATORS.common.$neq,  // not equal
      OPERATORS.common.$gt,   // greater than
      OPERATORS.common.$gte,  // greater than or equal
      OPERATORS.common.$lt,   // less than
      OPERATORS.common.$lte   // less than or equal
    ];
    if (includes(commonOperators, filter.operator)) {
      const value = filter.type === FieldType.String ? `'${ this.escapeString(filter.value) }'` : filter.value;
      return `${ filter.field }${ filter.operator }${ value }`;
    }

    // ISNULL operator
    if (filter.operator === OPERATORS.common.$isnull) {
      return `${ filter.field }=${ filter.operator }=${ filter.value }`;
    }

    // Contains-statements is used as string search
    if (filter.operator === OPERATORS.common.$contains) {
      if (isNullOrUndefinedOrBlank(filter.value)) {
        return null;
      }
      return `${ filter.field }=='*${ this.escapeString(filter.value) }*'`;
    }

    // Between requires an array value
    if (filter.operator === OPERATORS.common.$between && Array.isArray(filter.value) && filter.value.length > 1) {
      // If the operator is between and one of the values is missing - return null immediately Or backend will error out
      if (filter.value.some((val) => isNullOrUndefinedOrBlank(val))) {
        return null;
      }

      const first = `${filter.field}>=${this.parseValue(filter.value[0])}`;
      const last = `${filter.field}<=${this.parseValue(filter.value[1])}`;
      const query = `${first} and ${last}`;

      // Third item indicates if null values are included
      return filter.value[2] ? `(${query} or ${filter.field}=isnull=true)` : query;
    }

    // IN- and OUT-statements need to be an array.
    if (includes([OPERATORS.common.$in, OPERATORS.common.$out], filter.operator) && Array.isArray(filter.value)) {
      let values: any[] = filter.value;

      // Make sure to return null if there is no items
      if (!values || values.length === 0) {
        return null;
      }

      // Special condition for null values
      const nullQuery = values.indexOf(null) > -1 ? `${filter.field}=isnull=true` : '';

      // Escape the values if they are string. This prevents the query from breaking on values with spaces
      values = values
        .filter(val => val != null)
        .map(val => `'${ this.escapeString(val) }'`);

      if (values.length) {
        const mainQuery = `${ filter.field }=${ filter.operator }=(${ values.join(',') })`;
        return RsqlEncoder.combine([nullQuery, mainQuery], 'or');
      }
      return nullQuery;
    }

    // OR-statements
    if (filter.operator === OPERATORS.common.$or && Array.isArray(filter.value)) {
      const values: any[] = filter.value;

      // Make sure to return null if there is no items
      if (!values || values.length === 0) {
        return null;
      }

      // Special condition for null values
      const nullQuery = values.indexOf(null) > -1 ? `${filter.field}=isnull=true` : '';

      const filters = values
        .filter(val => val != null)
        .map(val => `${ filter.field }=='*${ this.escapeString(val) }*'`);

      return '(' + RsqlEncoder.combine([nullQuery, ...filters], 'or') + ')';
    }

    //  Return unsupported operators or invalid values
    return null;
  }

  private parseValue(value: any): any {
    if (value instanceof Date) {
      return value.valueOf();
    }
    return value;
  }

  private escapeString(value: string): string {
    return `${ value }`.replace(/'/g, '\\\'');
  }

  private runTransformations(sourceFilter: FilterSet): FilterSet {
    // Create a copy of the original filter so we can mutate it safely.
    const filter = cloneDeep(sourceFilter) as FilterSet;

    // Date type handling - they are converted to unix timestamps
    if (filter.type === FieldType.Date && Array.isArray(filter.value)) {
      const values: any[] = filter.value;

      // Special handling for string dates (YYYY-MM-DD).
      // If only one is given, use the same date
      if (typeof values[0] === 'string' && !values[1]) {
        values[1] = values[0];

        return filter;
      }

      // If more than one given = do not do any transformations
      const valueTypes = values.map(val => typeof val);
      const valueTypeSummary = uniq(valueTypes)
        .shift();

      // If they are all string, we don't do any other transformation
      // and then return early
      if (valueTypeSummary === 'string') {
        return filter;
      }

      values.forEach((val, index) => {
        // Set the TO or the end of the range to the End of the Date (ex. Sep 06 2019 23:59:59)
        if (index === 1) {
          val = moment(!isNullOrUndefined(val) ? val : values[0])
            .endOf('day')
            .toDate();
        }
        // Convert to their numeric unix timestamp when it is a date object.
        // Otherwise don't touch it.
        if (val instanceof Date) {
          filter.value[index] = val.valueOf();
        }
      });
    }

    // Add any other transformations here -
    // ...


    // Return the transformed filters
    return filter;
  }

}
