import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FilterMetadata } from 'primeng/api';
import { DataTable } from 'primeng/datatable';
import { clone } from '../../../settings-v2/shared/utils/clone';
import { FilterGroup } from '../../data-filter/filter';
import { HeaderFilterComponent } from '../../data-filter/header-filters/header-filter/header-filter.component';
import { FileKeySupplementalData } from '../../dto/file-key-supplemental-data';
import { FilterSelectionComponent } from '../../data-filter/filter-selection/filter-selection.component';
import { HeaderFilterOptions } from '../../data-filter/header-filters/header-filters.models';
import { FieldType } from '../../data-filter/field-type';
import { OPERATORS } from '../../data-filter/filter-operators';
import { TaskAssigneePipe } from '../../pipes/task-assignee.pipe';
import { Task } from '../../dto/projects/task';
import { TaskFiles } from '../../dto/projects/task-files';
import { TasksOverviewService } from '../../../data-services/tasks-overview.service';
import * as _ from 'underscore';
import { UpdateOrderFileTaskRequest } from '../../request/update-order-file-task-request';
import { OrderService } from '../../../data-services/order.service';
import _isEqual from 'lodash/isEqual';
import { isNullOrUndefined } from '../../utils/is-null-or-undefined';

export interface FilesAssociateTasksFilters {
  filterSelection: FilterGroup[];
  tableFilters: {
    [s: string]: FilterMetadata
  };
  show: boolean;
}

@Component({
  selector: 'townip-files-associate-tasks',
  templateUrl: './files-associate-tasks.component.html',
  styleUrls: ['./files-associate-tasks.component.scss']
})
export class FilesAssociateTasksComponent implements OnInit {
  @ViewChild('taskTableFilter', { static: true })
  public taskFilterSelection: FilterSelectionComponent;

  @ViewChild('tasksTable', { static: true })
  public tasksTable: DataTable;

  @ViewChildren(HeaderFilterComponent)
  public headerFilters: QueryList<HeaderFilterComponent>;

  @Input()
  public isLegalTranslation = false;

  @Input()
  public filters: FilesAssociateTasksFilters;

  @Output()
  // NOTE: Only suppressing because legacy.
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  public onHide: EventEmitter<any> = new EventEmitter();

  @Output()
  // NOTE: Only suppressing because legacy.
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  public onFilesAssociated: EventEmitter<any> = new EventEmitter();

  @Output()
  // NOTE: Only suppressing because legacy.
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  public onAssociatedTasksChange: EventEmitter<any[]> = new EventEmitter();

  @Output()
  public filtersChange = new EventEmitter<FilesAssociateTasksFilters>();

  public workingFile: FileKeySupplementalData;

  public orderId: number;

  public projectTasks: Task[];

  public taskFiles: TaskFiles[];

  public tableTasks: any[];

  public updatedTasks: any[];

  public taskCols: any[];

  public taskDataHeaderFilterMetaData: any;

  private associatedTasks: any[];

  constructor(private taskService: TasksOverviewService,
              private orderService: OrderService) { }

  public ngOnInit(): void {
    // need to add timeout here to prevent ExpressionChangedAfterItHasBeenCheckedError error
    setTimeout(() => this.saveFilters());
  }

  public buildTaskData(workingFile: FileKeySupplementalData, projectTasks: Task[], orderId: number): Promise<boolean> {
    if (isNullOrUndefined(projectTasks)) {
      return Promise.resolve(false);
    }

    this.workingFile = workingFile;
    this.projectTasks = projectTasks;
    this.orderId = orderId;

    return new Promise( (resolve) => {
      this.taskService.getTaskFilesForTasks(this.projectTasks)
        .then(tf => {
          this.taskFiles = tf;
          this.buildTaskCols();
          this.extractTaskData();
          setTimeout(() => this.applySavedFilters());
          resolve(true);
        });
    });
  }

  public hideAssociateFileDialog(): void {
    this.workingFile = null;
    this.tableTasks = [];
    this.updatedTasks = [];
  }

  private extractTaskData(): void {
    this.tableTasks = [];
    this.associatedTasks = [];
    this.projectTasks.forEach(t => {
      if (isNullOrUndefined(t.projectOverview) || isNullOrUndefined(t.projectOverview.orderStatus)) {
        return;
      }

      const tableTask = {
        stage: t.assignee.taskStage.text,
        stageValue: t.assignee.taskStage.taskStage,
        country: (t.assignee.countries && t.assignee.countries.length > 0 &&
          t.assignee.countries[0].name || 'All'),
        countryId: (t.assignee.countries && t.assignee.countries.length > 0 && t.assignee.countries[0].id),
        taskType: t.assignee.taskType,
        // eslint-disable-next-line no-constant-binary-expression
        username: t.assignee.assignedUser &&
          t.assignee.assignedUser.firstName ||
          ' ' ||
          t.assignee.assignedUser.lastName,
        taskId: t.assignee.taskId,
        taskAssignmentId: t.taskAssignmentId,
        parentTaskAssignmentId: t.parentTaskAssignmentId,
        task: t,
        fileAssociated: false,
        sourceFile: t.assignee.documentName,
        assignee: t ? new TaskAssigneePipe().transform(t) : null
      };

      this.tableTasks.push(tableTask);

      if (this.taskFiles) {
        const taskFile = this.taskFiles.find(taskFileSearch => taskFileSearch.taskId === t.taskAssignmentId);

        if (taskFile) {
          const index = taskFile.taskFiles
            .findIndex(file => file.clientDocumentId === this.workingFile.clientDocumentId);
          if (index >= 0) {
            tableTask.fileAssociated = true;
            this.associatedTasks.push(tableTask);
          }
        }
      }
    });

    this.updatedTasks = [...this.associatedTasks];

    this.buildTaskDataFilterIndexes();
  }

  public buildTaskCols(): void {
    this.taskCols = [
      {
        field: 'taskId',
        header: 'Task Number',
        sortable: true,
        filterToggle: true,
        filter: true,
        filterMatchMode: 'contains'
      },
      {
        field: 'stage',
        header: 'Stage',
        sortable: true,
        filterToggle: true,
        filter: true,
        customHeaderFilter: {
          field: 'stage',
          input: 'multiselect',
          type: FieldType.String,
          operator: OPERATORS.collection.$contains.value
        } as HeaderFilterOptions
      },
      {
        field: 'taskType',
        header: 'Task Type',
        sortable: true,
        filter: true,
        filterToggle: true,
        customHeaderFilter: {
          field: 'taskType',
          input: 'multiselect',
          type: FieldType.String,
          operator: OPERATORS.collection.$contains.value
        } as HeaderFilterOptions
      }
    ];

    if (this.isLegalTranslation) {
      this.taskCols.unshift({
        field: 'sourceFile',
        header: 'Source File',
        sortable: false,
        filterToggle: true,
        filter: true,
        templateType: 'sourceFile',
        filterMatchMode: 'contains'
      });
    }

    if (!this.isLegalTranslation) {
      this.taskCols.push({
        field: 'country',
        header: 'Target Country',
        sortable: true,
        filterToggle: true,
        filter: true,
        customHeaderFilter: {
          field: 'country',
          input: 'multiselect',
          type: FieldType.String,
          operator: OPERATORS.collection.$contains.value
        } as HeaderFilterOptions
      });
    }

    this.taskCols.push({
      field: 'assignee',
      header: 'Assignee',
      sortable: true,
      filterToggle: true,
      filter: true,
      templateType: 'assignee',
      customHeaderFilter: {
        field: 'assignee',
        input: 'multiselect',
        type: FieldType.String,
        operator: OPERATORS.collection.$contains.value
      } as HeaderFilterOptions
    });
  }

  private buildTaskDataFilterIndexes(): void {
    // Index User Name
    const stages = _.map(this.tableTasks, (task) => {
      return { label: task.stage, value: task.stage };
    });

    // Index Task Types
    const taskTypes = _.map(this.tableTasks, (task) => {
      return { label: task.taskType, value: task.taskType };
    });

    // Index Countries
    const countries = _.map(this.tableTasks, (task) => {
      return { label: task.country, value: task.country };
    });

    // Index Username
    const assignees = _.map(this.tableTasks, (task) => {
      const assignee = new TaskAssigneePipe().transform(task.task);
      return { label: assignee, value: assignee };
    });

    this.taskDataHeaderFilterMetaData = {};
    this.taskDataHeaderFilterMetaData.stage = stages;
    this.taskDataHeaderFilterMetaData.country = countries;
    this.taskDataHeaderFilterMetaData.assignee = assignees;
    this.taskDataHeaderFilterMetaData.taskType = taskTypes;
  }

  public onTaskSelectChange(event: any): void {
    const selectedTask = this.tableTasks.find(t => t.taskAssignmentId === event.data.taskAssignmentId);
    selectedTask.fileAssociated = !!this.updatedTasks.find(t => t.taskAssignmentId === event.data.taskAssignmentId);
    this.onAssociatedTasksChange.emit(this.updatedTasks);
  }

  public onTaskHeaderSelect(event: any): void {
    this.tableTasks.forEach(task => task.fileAssociated = !!event.checked);
    this.onAssociatedTasksChange.emit(this.updatedTasks);
  }

  public onClearFilters(): void {
    this.taskCols.forEach(col => {
      if (col.filter && !col.customHeaderFilter) {
        col.filterValue = '';
      }
    });

    this.tasksTable.reset();
  }

  public async associateFilesAndTasks(clientDocumentId?: number): Promise<void> {
    const associateFiles: number[] = [];

    const docId = clientDocumentId ? clientDocumentId : this.workingFile.clientDocumentId;
    associateFiles.push(docId);

    const updateOrderTaskAssociations: UpdateOrderFileTaskRequest = {
      clientDocumentId: associateFiles,
      taskFileAssociations: []
    };

    const updatedTasks = [...this.updatedTasks];

    // get the initially associatedTasks that were unchecked
    this.associatedTasks
      .filter(task => !this.updatedTasks.find(updatedTask => updatedTask.taskAssignmentId === task.taskAssignmentId))
      .forEach(task => updatedTasks.push(task));

    updatedTasks.forEach(ut => {
      updateOrderTaskAssociations.taskFileAssociations.push({
        taskAssignmentId: ut.taskAssignmentId,
        fileAssociated: ut.fileAssociated
      });
    });

    await this.orderService
      .updateOrderFileTaskAssociation(this.orderId, updateOrderTaskAssociations)
      .toPromise();

    this.taskFiles = await this.taskService.getTaskFilesForTasks(this.projectTasks);
    this.onFilesAssociated.emit();

    this.onHide.emit();
  }

  private applySavedFilters(): void {
    if (!this.filters) {
      return;
    }

    // header filters
    if (Array.isArray(this.filters.filterSelection)) {
      const headerFilters = this.headerFilters.toArray();
      this.filters.filterSelection.forEach(filter => {
        const headerFilter = headerFilters.find(header => header.options.field === filter.filters[0].field);
        headerFilter.currentValue = filter.filters[0].value;
        headerFilter.signalUpdate();
      });
    }
    const tableFilters = this.filters.tableFilters;

    // table filters
    if (this.filters.tableFilters) {
      Object
        .keys(tableFilters)
        .forEach(key => this.tasksTable.filter(tableFilters[key].value, key, tableFilters[key].matchMode));
    }
  }

  public saveFilters(): void {
    const newFilters = {
      show: true,
      filterSelection: this.taskFilterSelection.activeFilters,
      tableFilters: this.tasksTable.filters
    };
    if (!_isEqual(newFilters, this.filters)) {
      this.filters = clone(newFilters);
      this.filtersChange.emit(this.filters);
    }
  }
}
