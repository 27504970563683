import { SelectItem } from 'primeng/api';

export enum IprServiceType {
  Renewal = 'RENEWAL',
  DeclarationOfUse = 'DOU',
  RenewalAndDeclarationOfUse = 'RENEWAL_DOU',
}

export const IPR_SERVICES_TYPES: SelectItem[] = [
  { label: 'Renewal', value: IprServiceType.Renewal },
  { label: 'Declaration of Use', value: IprServiceType.DeclarationOfUse },
  { label: 'Renewal and Declaration of Use', value: IprServiceType.RenewalAndDeclarationOfUse },
];
