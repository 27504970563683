import { Observable, Subscription } from 'rxjs';
import * as _ from 'underscore';
import { EventEmitter, Injectable } from '@angular/core';
import { PortalUser } from '../domain/user';
import { EmailAvailabilityResponse } from '../dto/email-availability-response';
import { HttpClient } from '@angular/common/http';
import { NotificationSettings } from '../../settings-v2/notification/notification.models';
import { Router } from '@angular/router';
import { LoggingService } from '../logging.service';
import { RequestService } from './request-service';
import { HttpRequestErrorInterceptorMessages } from '../interceptors/http-request-error-interceptor-messages';
import { environment } from '../../../environments/environment';
import { GeneralRequestMessage } from '../dto/general-request-message';
import { GeneralResponseMessage } from '../dto/general-response-message';
import { AppContextService } from './app-context.service';
import { PrincipalUserLite } from '../dto/principal-user-lite';
import { FirebaseService } from '../../firebase/firebase.service';
import { UserType } from '../domain/user-type';
import { FilterScope } from '../query/paginated-request.component';
import * as some from 'lodash/some';
import { isNullOrUndefined } from '../utils/is-null-or-undefined';
import { DashboardPreference } from '../enums/dashboard-preference';
import { PrincipalUserDTO } from '../dto/principal-user';
import { PasswordResetResponse } from '../dto/password-reset-response';
import { UserDashboardPreference } from '../dto/user-dashboard-preference';
import { ViewPreferenceType } from '../dto/preferences/view-preference-type';
import { map } from 'rxjs/operators';

// TODO: Add other authorization types
// An object of the current's users role so we don't have to compute/parse every time
export interface UserAuthCache {
  ROLE_VENDOR_USER?: boolean;
  ROLE_VENDOR_ADMIN?: boolean;
  ROLE_COMPANY_ADMIN?: boolean;
  ROLE_COMPANY_USER?: boolean;
  ROLE_INTERNAL_PROJECT_MGR?: boolean;
  ROLE_INTERNAL_USER?: boolean;
  ROLE_INTERNAL_ADMIN?: boolean;
  ROLE_INTERNAL_ACCOUNT_MGR?: boolean;
  ROLE_INTERNAL_SALES_MGR?: boolean;
  ROLE_INTERNAL_ACCOUNT_ADMIN?: boolean;
  ROLE_INTERNAL_SALES_ADMIN?: boolean;
  ROLE_FORECASTER_USER?: boolean;
  RESTRICTED_COMPANY_USER?: boolean;
  ROLE_VIEW_ONLY?: boolean;
}


// An object of the current's users permissions so we don't have to compute/parse every time
export interface UserPermCache {
  VIEW_VENDOR_USERS?: boolean;
  VIEW_VENDOR_PRICING?: boolean;
  Edit_All?: boolean;
  ADD_VENDOR_GROUP?: boolean;
  REMOVE_VENDOR_GROUP?: boolean;
  VIEW_VENDOR_GROUP?: boolean;
  VIEW_ESTIMATE?: boolean;
  VIEW_PROJECTS?: boolean;
  VIEW_COMPANY_ALL?: boolean;
  VIEW_COMPANY_GROUPS?: boolean;
  VIEW_COMPANY_USERS?: boolean;
  EDIT_COMPANY_USER?: boolean;
  EDIT_COMPANY_GROUP?: boolean;
  EDIT_CONFIG?: boolean;
  FORECASTER_USER?: boolean;
  PORTAL_USER?: boolean;
  ACTUATOR?: boolean;
  AUTO_ASSIGN_PROJECT_MGR?: boolean;
  AUTO_ASSIGN_SALES_MGR?: boolean;
  AUTO_ASSIGN_ACCOUNT_MGR?: boolean;
  AUTO_FOLLOW_PROJECT?: boolean;
  ASSIGN_PROJECT_MGR?: boolean;
  ASSIGN_SALES_MGR?: boolean;
  ASSIGN_ACCOUNT_MGR?: boolean;
  EDIT_PERMISSIONS?: boolean;
  VIEW_INTERNAL_QUALITY_ISSUES?: boolean;
  RUN_ESTIMATE_REPORTS?: boolean;
  RUN_PROJECT_REPORTS?: boolean;
  RUN_CLIENT_REPORTS?: boolean;
  REPORTS_USER?: boolean;
  RUN_FINANCIALS_REPORTS?: boolean;
  RUN_ANNUITY_REPORTS?: boolean;
  WEBSOCKET_ACCESS?: boolean;
  RUN_PRICING_REPORTS?: boolean;
  RUN_PROJECT_MANAGER_REPORTS?: boolean;
  RUN_QUALITY_ASSURANCE_REPORTS?: boolean;
  RUN_HISTORICAL_REPORTS?: boolean;
  RUN_OUTSTANDING_WORK_REPORTS?: boolean;
  RUN_DELIVERY_AUDIT_REPORTS?: boolean;
  RUN_VENDOR_MANAGEMENT_REPORTS?: boolean;
  RESTRICTED_COMPANY_USER?: boolean;
  EDIT_END_CLIENT_LINKING?: boolean;
  RUN_FAILED_SALESFORCE_REPORTS?: boolean;
  ENABLE_PRICE_FREEZE?: boolean;
}

@Injectable()
export class UserService {
  private userSub: Subscription;

  public userEmitter: EventEmitter<PortalUser>;

  public logoutEmitter: EventEmitter<any>;

  public userObservable: Observable<PortalUser>;

  public user: PortalUser;

  public authorizations: UserAuthCache;

  public permissions: UserPermCache;

  constructor(private httpClient: HttpClient,
              private firebaseService: FirebaseService,
              private router: Router,
              private requestService: RequestService,
              private loggingService: LoggingService,
              private httpRequestErrorMessages: HttpRequestErrorInterceptorMessages,
              private appContext: AppContextService) {
    this.userEmitter = new EventEmitter<PortalUser>();
    this.logoutEmitter = new EventEmitter<any>();
    this.user = null;
    this.authorizations = {};
    this.permissions = {};

    // Turn off error growls on the /api/user/ path.
    // Calls on this endpoint is used for getting the current session user.
    // User will be redirected to 403 or system error pages in AuthService if they don't have access or server is down.
    this.httpRequestErrorMessages.add({ url: '/api/user/', bypass: true, permanent: true });
    this.httpRequestErrorMessages.add({ url: '/api/messaging/channel/grant', bypass: true, permanent: true });
  }

  public get paginatedScope(): FilterScope {
    const adminScopes: boolean[] = [
      this.authorizations.ROLE_INTERNAL_SALES_ADMIN,
      this.authorizations.ROLE_INTERNAL_ACCOUNT_ADMIN,
      this.authorizations.ROLE_INTERNAL_ADMIN,
      this.authorizations.ROLE_COMPANY_ADMIN,
      this.authorizations.ROLE_INTERNAL_PROJECT_MGR
    ];

    // IMPORTANT NOTE:
    // The order of the if statements are important.

    if (some(adminScopes, scope => scope === true)) {
      return FilterScope.MY_COMPANY;
    }

    // For everyone else.
    return FilterScope.MINE;
  }

  public getPreferredScope(pref: DashboardPreference): FilterScope {
    const matchingPref = this.user
      .userDashboardPreferences
      .find(userPref => userPref.dashboardPreference === pref);

    if (!matchingPref) {
      return this.paginatedScope;
    }

    if (matchingPref.tableFilterScopePreference === FilterScope.MINE) {
      return FilterScope.MINE;
    } else {
      return FilterScope.MY_COMPANY;
    }
  }

  public setUser(user: PortalUser): void {
    this.user = user;
    if (isNullOrUndefined(user)) {
      this.userObservable = null;
      if (!!this.userSub) {
        this.userSub.unsubscribe();
      }
    }
  }

  public retrieveUsersById(userId: number[]): Observable<PortalUser[]> {
    const url = '/api/users';
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.post<PortalUser[]>(url, userId, { headers: headers });
  }

  public retrieveUserById(userId: number): Observable<PortalUser> {
    const url = `/api/user/${userId}`;
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.get<PortalUser>(url, { headers: headers });
  }

  public retrieveUser(): Promise<PortalUser> {
    return new Promise((resolve, reject) => {
      const url = '/api/user';
      const headers = this.requestService.buildHttpHeaders();
      this.userObservable = this.httpClient.get<PortalUser>(url, { headers: headers });
      this.userObservable.toPromise()
        .then(user => {
          this.user = user;

          // Internal users will always have sunip context
          if (user.userType === 'INTERNAL') {
            this.appContext.setSunipContext();
          } else {
            // All other user types will need to check permissions
            // Override the Application Context based on permissions

            // Forecaster only user
            if (this.isForecaster() && this.isForecasterOnly()) {
              this.appContext.setForecastContext();
            }
            // Forecaster and Main System User
            if (!this.isForecasterOnly() && this.isForecaster()) {
              this.appContext.setSunipContext();
            }
            // Main System only user
            if (!this.isForecaster()) {
              this.appContext.setSunipContext();
            }
          }

          this.buildAuthorizationCache();
          this.buildPermissionsCache();
          this.userEmitter.emit(this.user);
          resolve(user);
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  public getUser(): PortalUser {
    return this.user;
  }

  public getUserNotificationSettings(userId: number): Observable<NotificationSettings> {
    const url = `/api/messaging/preferences/user/${userId}`;
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.get<NotificationSettings>(url, { headers: headers });
  }

  public updateUserNotificationSettings(userId: number,
    notificationSettings: NotificationSettings): Observable<NotificationSettings> {
    const url = `/api/messaging/preferences/user/${userId}`;
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.put<NotificationSettings>(url, notificationSettings, { headers: headers });
  }

  public updateUser(user: PortalUser): Observable<any> {
    const url = `/api/user/${user.id}`;
    const headers = this.requestService.buildHttpHeaders();
    this.userObservable = this.httpClient.put<any>(url, user, { headers: headers });
    this.userObservable.toPromise()
      .then(u => {
        this.user = u;
      });
    return this.userObservable;
  }

  public resetUserPassword(email: string): Observable<PasswordResetResponse> {
    const url = `/api/request-password-reset/${email}`;
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.get<PasswordResetResponse>(url, { headers: headers });
  }

  public isEmailAvailable(emailAddress: string): Observable<EmailAvailabilityResponse> {
    const url = `/api/validateEmail/${emailAddress}`;
    const headers = this.requestService.buildHttpHeaders();
    const emailResponse =
      this.httpClient.get<EmailAvailabilityResponse>(url, { headers: headers });
    return emailResponse;
  }

  public isEmailForecastOnly(emailAddress: string): Promise<GeneralResponseMessage> {
    const request: GeneralRequestMessage = {
      request: emailAddress,
      uuid: environment.uuid
    };
    const url = '/api/validateEmail/isForecastOnly';
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient.post<GeneralResponseMessage>(url, request, { headers: headers })
      .toPromise();
  }

  public parseAuthorities(user: PortalUser): UserAuthCache {
    const authorities: UserAuthCache = {};
    const auths = user.authorities.split(',');
    for (const auth of auths) {
      authorities[auth] = true;
    }
    return authorities;
  }

  public getDashboardPreferenceOptions(id: number): Observable<DashboardPreference[]> {
    const url = `/api/user/${id}/dashboard-preference/options`;
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient.get<DashboardPreference[]>(url, { headers: headers });
  }

  public saveDashboardPreference(id: number, preference: DashboardPreference): Observable<PrincipalUserDTO> {
    const url = `/api/user/${id}/update/dashboard-preference?defaultDashboardPreference=${preference}`;
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient.post<PrincipalUserDTO>(url, { headers: headers });
  }

  public saveUserDashboardPreferences(id: number, prefs: UserDashboardPreference[]): Observable<PrincipalUserDTO> {
    const url = `/api/user/${id}/dashboard-preference`;
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient.put<PrincipalUserDTO>(url, prefs, { headers: headers });
  }

  private buildAuthorizationCache(): void {
    this.authorizations = {};
    const auths = _.pluck(this.user.roles, 'value');
    for (const auth of auths) {
      this.authorizations[auth] = true;
    }
  }

  public getAuthorizations(): UserAuthCache {
    return this.authorizations;
  }

  private buildPermissionsCache(): void {
    this.permissions = {};
    const perms = _.pluck(this.user.permissions, 'name');
    for (const perm of perms) {
      this.permissions[perm] = true;
    }
  }

  public getPermissions(): UserPermCache {
    return this.permissions;
  }

  public logout(): void {
    this.logoutEmitter.emit(true);
  }

  /**
   * Used to check if the current user has forecaster permissions.
   * This only checks if the user has FORECASTER permissions regardless if it has other ones.
   * Adjust the checking logic as necessary.
   */
  public isForecaster(user?: PortalUser): boolean {
    // return true;
    if (!(isNullOrUndefined(user))) {
      return user.authorities.includes('FORECASTER_USER');
    }

    if (!this.user) {
      return false;
    }

    const forecasterPerms = _.find(this.user.permissions, { name: 'FORECASTER_USER' });

    // If no perms, return immediately.
    if (!forecasterPerms) {
      return false;
    }

    // We don't really have to check if  the user has other permissions.
    return true;
  }

  /**
   * Basically, the forecaster permission can be added to a normal system user.
   * This checks if the current user is a 'FORECASTER' only user.
   */
  public isForecasterOnly(): boolean {
    // return true;
    if (!this.user) {
      return false;
    }

    const forecasterPerms = _.find(this.user.permissions, { name: 'FORECASTER_USER' });

    // If no perms, return immediately.
    if (!forecasterPerms) {
      return false;
    }

    if (this.hasAnnuitiesAccess()) {
      return false;
    }

    // User got forecaster, but does the user have the main system perms?
    // If the user has the main system perms, then it's a normal system user that has forecaster enabled
    const mainSystemPerms = _.find(this.user.permissions, { name: 'PORTAL_USER' });
    return !mainSystemPerms;
  }

  public isAnnuitiesOnly(): boolean {
    if (!this.user) {
      return false;
    }

    if (!this.hasAnnuitiesAccess()) {
      return false;
    }

    if (this.hasTranslationFilingAccess() || this.isForecaster()) {
      return false;
    }

    return true;
  }

  public retrieveLiteUsersById(userId: number[]): Observable<PrincipalUserLite[]> {
    const url = '/api/users/lite';
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.post<PrincipalUserLite[]>(url, userId, { headers: headers });
  }

  public isInternal(): boolean {
    return this.user.userType === UserType.Internal;
  }

  public isVendor(): boolean {
    return this.user.userType === UserType.Vendor;
  }

  public isVendorAdmin(): boolean {
    return this.authorizations.ROLE_VENDOR_ADMIN;
  }

  public isCompany(): boolean {
    return this.user.userType === UserType.Company;
  }

  public isCompanyAdmin(): boolean {
    return this.authorizations.ROLE_COMPANY_ADMIN;
  }

  public isSalesManager(): boolean {
    return this.authorizations.ROLE_INTERNAL_SALES_MGR || this.authorizations.ROLE_INTERNAL_SALES_ADMIN;
  }

  public isAccountManager(): boolean {
    return this.authorizations.ROLE_INTERNAL_ACCOUNT_MGR || this.authorizations.ROLE_INTERNAL_ACCOUNT_ADMIN;
  }

  public isSalesOrAccountManager(): boolean {
    return this.isSalesManager() || this.isAccountManager();
  }

  public isSalesAdmin(): boolean {
    return this.authorizations.ROLE_INTERNAL_SALES_ADMIN;
  }

  public isSalesOrAccountAdmin(): boolean {
    return this.authorizations.ROLE_INTERNAL_ACCOUNT_ADMIN || this.authorizations.ROLE_INTERNAL_SALES_ADMIN;
  }

  public isPM(): boolean {
    return this.authorizations.ROLE_INTERNAL_PROJECT_MGR || this.authorizations.ROLE_INTERNAL_ADMIN;
  }

  public isInternalAdmin(): boolean {
    return this.authorizations.ROLE_INTERNAL_ADMIN;
  }

  public hasAnnuitiesAccess(user?: PortalUser): boolean {
    const tempUser = user ? user : this.getUser();

    return tempUser
      .authorities
      .includes('ANNUITIES');
  }

  public hasTranslationFilingAccess(user?: PortalUser): boolean {
    const tempUser = user ? user : this.getUser();

    return tempUser
      .authorities
      .includes('PORTAL_USER');
  }

  public retrieveCurrentUser(): Observable<PortalUser> {
    const url = '/api/user';
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.get<PortalUser>(url, { headers: headers });
  }

  public getDashboardPreferenceTypes(userId: number): Observable<ViewPreferenceType[]> {
    const url = `/api/user/${userId}/dashboard-preference/types`;
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.get<ViewPreferenceType[]>(url, { headers: headers });
  }

  public getDashboardPreferenceTypesWithScopeMapping(userId: number, type: DashboardPreference): Observable<FilterScope[]> {
    return this.getDashboardPreferenceTypes(userId)
      .pipe(
        map(types => {
          const thePref = types
            .find(fType => fType.dashboardPreference === type);

          return thePref.tableFilterScopePreferences;
        })
      );
  }

  public getUserDashboardPreference(user: PortalUser, type: DashboardPreference): FilterScope {
    try {
      return user.userDashboardPreferences
        .find((pref) => pref.dashboardPreference === type)
        .tableFilterScopePreference;
    } catch (e) {
      // IDEALLY, it would never reach here because the backend returns default values
      // But in the event that it fails there is some general handling here.
      console.warn('Failed to get user preference for scope.');
      // COMPANY defaults to MY - this is based on the original panel-table-display-preferences.component
      // that was removed in 25515
      // INTERNAL defaults to COMPANY based on 25663
      return user.userType === UserType.Internal ? FilterScope.MY_COMPANY : FilterScope.MINE;
    }
  }

  public getUserDashboardPreferenceByScope(user: PortalUser, type: DashboardPreference): FilterScope {
    return this.getUserDashboardPreference(user, type);
  }

  public getDefaultInternalRenewalOverviewScope(): FilterScope {
    const user = this.getUser();
    const scope = this.getUserDashboardPreferenceByScope(user, DashboardPreference.Annuities);

    // This is how it's supposed to work according to 25663
    // ALL and LINKED_END_CLIENT filter scopes are not applicable to renewals(annuities) table
    if (
      (this.isPM() || this.isSalesOrAccountAdmin()) &&
      (scope === FilterScope.ALL || scope === FilterScope.LINKED_END_CLIENT)) {
      return FilterScope.MY_COMPANY;
    }

    if (this.isSalesOrAccountManager() && (scope === FilterScope.ALL || scope === FilterScope.LINKED_END_CLIENT)) {
      return FilterScope.MINE;
    }

    return scope;
  }
}
