import { Injectable } from '@angular/core';

export abstract class Logger {

  public info: any;
  public warn: any;
  public error: any;
}

@Injectable()
export class LoggingService implements Logger {

  public info: any;
  public warn: any;
  public error: any;

  public invokeConsoleMethod(type: string, args?: any): void {}
}
