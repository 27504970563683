import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RequestService } from '../shared/services/request-service';
import { FileKeyPair } from '../shared/dto/file-key';

@Injectable()
export class FileService {

  constructor(private httpClient: HttpClient,
              private requestService: RequestService) { }

  public updateFileCategory(fileKey: string, categoryId: number): Promise<FileKeyPair> {
    const url = `/api/file/${fileKey}/category/${categoryId}`;
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient.put(url, { headers: headers })
      .toPromise();
  }

  public updateFileName(fileKey: string, fileName: string): Promise<any> {
    const url = `/api/file/${fileKey}/update-filename/${fileName}`;
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient.put(url, { headers: headers })
      .toPromise();
  }

  public getAvailableFileCategoryByUser(projectCategoryId: number): Promise<number[]> {
    const url = `api/file/user/available/categories/${projectCategoryId}`;
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.get<any>(url, { headers: headers })
      .toPromise();
  }

  public getAvailableFileCategoryForCompany(projectCategoryId: number): Promise<number[]> {
    const url = `api/file/company/available/categories/${projectCategoryId}`;
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.get<any>(url, { headers: headers })
      .toPromise();
  }
}
