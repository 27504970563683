import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderFilterComponent } from './header-filter/header-filter.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { FormsModule } from '@angular/forms';
import { PipesModule } from '../../pipes/pipes.module';
import { MultiSelectModule } from 'primeng/multiselect';
import { SliderModule } from 'primeng/slider';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from '../../../controls/calendar/calendar.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MultiSelectModule,
    CalendarModule,
    SliderModule,
    TooltipModule,
    PipesModule,
    DropdownModule,
    CalendarModule
  ],
  declarations: [HeaderFilterComponent],
  exports: [HeaderFilterComponent],
})
export class HeaderFiltersModule { }
