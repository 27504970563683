import { Injectable } from '@angular/core';
import { GlobalLoaderService } from '../shared/services/global-loader.service';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Injectable()
export class GlobalLoaderInterceptor implements HttpInterceptor {
  private ignoreUrls: string[] = [
    '/assets/alive.txt',
    '/api/v2/tasks/counts',
    '/api/v2/tasks/sum',
    '/api/v2/projects/counts',
    '/api/v2/projects/sum',
    '/api/validateEmail',
    '/api/annuity/bill/calculate',
    '/api/messaging/channel/grant',
  ];

  constructor(private globalLoader: GlobalLoaderService) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const ignored = this.ignoreUrls
      .concat(this.globalLoader.permanentlyIgnoredList)
      .some(url => request.url.startsWith(url));

    if (!ignored && !this.globalLoader.hasIgnoreOnce(request.url)) {
      this.globalLoader.triggerAuto();
    }

    return next.handle(request)
      .pipe(
        finalize(() => {
          if (!ignored && !this.globalLoader.hasIgnoreOnce(request.url)) {
            this.globalLoader.completeAuto();
            this.globalLoader.cleanupIgnoreOnce(request.url);
          }
        })
      );
  }
}
