import * as _ from 'underscore';
import { Injectable } from '@angular/core';

export interface TablePreference {
  id: string;
  user: number;
  columns?: any[];
  rows?: number;
}

@Injectable()
export class TablePreferencesService {

  public datastore: TablePreference[] = [];

  private _storageName = 'townip_table_prefs';

  constructor() {
    this._loadFromStorage();
  }

  private _loadFromStorage(): void {
    const storageData = window.localStorage.getItem(this._storageName);
    if (storageData) {
      try {
        this.datastore = JSON.parse(storageData);
      } catch (e) {
        console.warn('Invalid table pref data.');
        this.datastore = [];
      }
    }
  }

  private _commitToStorage(): void {
    window.localStorage.setItem(this._storageName, JSON.stringify(this.datastore));
  }

  public save(pref: TablePreference): void {
    const existingPref = _.findWhere(this.datastore, { id: pref.id, user: pref.user });

    if (existingPref) {
      // Update data without losing reference
      existingPref.columns = pref.columns;
      existingPref.user = pref.user;
      existingPref.rows = pref.rows;
    } else {
      this.datastore.push(pref);
    }

    this.datastore = [...this.datastore];
    this._commitToStorage();

    // NOTE: Save to api from here:
  }

  public load(id: string, userId: number): TablePreference {
    return _.findWhere(this.datastore, { id: id, user: userId });
  }

  public clear(id: string): void {
    this.datastore = _.filter(this.datastore, (data) => {
      return data.id !== id;
    });

    this._commitToStorage();
  }
}
