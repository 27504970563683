import { Injectable } from '@angular/core';
import * as Papa from 'papaparse';
import { ParseResult } from 'papaparse';
import { isNullOrUndefined } from '../utils/is-null-or-undefined';

@Injectable()
export class CsvParserService {

  constructor() {
    const papaConfig = Papa as any;
    // Required for papa to function as a service worker. (we need to this for big files)
    // The aside from node_modules/package.json, the parse needs to exist as a static file on server.
    papaConfig.SCRIPT_PATH = '/assets/js/vendor/papaparse.min.js';
  }

  public parse(file: File, opts: Papa.ParseConfig): ParseResult {
    if (isNullOrUndefined(opts)) {
      console.error('Csv Parser must have options.');
      return null;
    }

    // Always in service worker mode
    if (opts) {
      opts.worker = true;
    }

    return Papa.parse(file, opts);
  }
}
