import { Injectable } from '@angular/core';
import { ConfigService } from '../../data-services/config.service';

import { LoggingService } from '../logging.service';

@Injectable()
export class OnboardingGuardService  {

  constructor(private loggingService: LoggingService,
              private configService: ConfigService) {
  }

  /**
   * Ensure all data needed for onboarding is loaded.
   * @returns {Promise<boolean>}
   */
  public canActivate(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      // Making sure we are also loading the required data together with the user
      const dataFetch: Promise<any>[] = [];
      dataFetch.push(this.configService.retrieveCountries());
      dataFetch.push(this.configService.retrieveTimeZones());

      // When all the data finishes loading async
      Promise.all(dataFetch)
        .then(() => {
          resolve(true);
        })
        .catch(() => {
          console.error('Failed to load onboarding data.');
          reject(false);
        });
    });
  }
}

