<!-- inject options list template -->
<ng-template [ngTemplateOutlet]="optionsListTemplate || (isBs4 ? bs4Template : bs3Template)"
             [ngTemplateOutletContext]="{matches:matches, itemTemplate:itemTemplate, query:query}"></ng-template>

<!-- default options item template -->
<ng-template #bsItemTemplate let-match="match" let-query="query"><span [innerHtml]="highlight(match, query)"></span>
</ng-template>

<!-- Bootstrap 3 options list template -->
<ng-template #bs3Template>
  <ul class="dropdown-menu"
      #ulElement
      [style.overflow-y]="needScrollbar ? 'scroll': 'auto'"
      [style.height]="needScrollbar ? guiHeight: 'auto'">
    <ng-template ngFor let-match let-i="index" [ngForOf]="matches">
      <li #liElements *ngIf="match.isHeader()" class="dropdown-header">{{ match }}</li>
      <li #liElements *ngIf="!match.isHeader()" [class.active]="isActive(match)" (mouseenter)="selectActive(match)">
        <a href="#" (click)="selectMatch(match, $event)" tabindex="-1">
          <ng-template [ngTemplateOutlet]="itemTemplate || bsItemTemplate"
                       [ngTemplateOutletContext]="{item:match.item, index:i, match:match, query:query}"></ng-template>
        </a>
      </li>
    </ng-template>
  </ul>
</ng-template>

<!-- Bootstrap 4 options list template -->
<ng-template #bs4Template>
  <ng-template ngFor let-match let-i="index" [ngForOf]="matches">
    <h6 *ngIf="match.isHeader()" class="dropdown-header">{{ match }}</h6>
    <ng-template [ngIf]="!match.isHeader()">
      <button #liElements
              class="dropdown-item"
              (click)="selectMatch(match, $event)"
              (mouseenter)="selectActive(match)"
              [class.active]="isActive(match)">
        <ng-template [ngTemplateOutlet]="itemTemplate || bsItemTemplate"
                     [ngTemplateOutletContext]="{item:match.item, index:i, match:match, query:query}"></ng-template>
      </button>
    </ng-template>
  </ng-template>
</ng-template>
