import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidatorFn } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { UserService } from '../shared/services/user.service';
import { debounceTime, map } from 'rxjs/operators';
import { isNullOrUndefined } from '../shared/utils/is-null-or-undefined';

@Injectable()
export class EmailValidatorsService {

  constructor(private userService: UserService) { }


  // TODO this would be where we hook into a uniqueness call for delivery contact uniqueness per company.
  // validateExternalInternalUser(): AsyncValidatorFn {
  //   return (control: AbstractControl):
  //    Promise<{ [key: string]: any } | null> | Observable<{ [key: string]: any } | null> => {
  //
  //     if (isNullOrUndefined(control.value)) {
  //       return Observable.of(null);
  //     } else {
  //       return this.customerManagementService.isExternalEmailAvailable(control.value)
  //       .debounceTime(5000).map(availabilityResponse => {
  //         return availabilityResponse.availability ? null : { emailTaken: true };
  //       });
  //     }
  //   };
  // }

  public validateInternalUser(currentEmail?: string): AsyncValidatorFn {
    return (control: AbstractControl):
      Promise<{ [key: string]: any } | null> | Observable<{ [key: string]: any } | null> => {

      if ((currentEmail && control.value) && currentEmail.toLowerCase() === control.value.toLowerCase()) {
        // Provided current email is valid
        return of(null);
      }

      if (isNullOrUndefined(control.value)) {
        return of(null);
      } else {
        return this.userService
          .isEmailAvailable(control.value)
          .pipe(
            debounceTime(500),
            map((availabilityResponse) => {
              return availabilityResponse.availability ? null : { emailTaken: true };
            })
          );
      }
    };
  }
}
