export const environment = {
  production: true,
  firebase: {
    apiKey: 'AIzaSyBwkIzlhtksXk5oyE8qoY9KYhfCqXVDE4Y',
    authDomain: 'production-6d1f0.firebaseapp.com',
    databaseURL: 'https://production-6d1f0.firebaseio.com',
    projectId: 'production-6d1f0',
    storageBucket: 'production-6d1f0.appspot.com',
    messagingSenderId: '569641428565'
  },
  minPasswordLength: 8,
  maxPasswordLength: 25,
  mainSystemUrl: 'https://sunip.legalsight.com',
  forecasterUrl: 'https://chiefip.legalsight.com',
  uuid: '9A8b7C6d5E4f3G2h1i0',
  subscribeKey: 'sub-c-9c56807a-c81e-11e8-9ca5-92bdce849b25',
  local: false,
  raygunKey: '7JrLS3I83ysgCCFMMTyrIg',
  version: '6.3.18',
};
