import { Injectable } from '@angular/core';
import { ConfigService } from '../../data-services/config.service';
import { Router } from '@angular/router';
import { LoggingService } from '../logging.service';

@Injectable()
export class ProjectGuardService  {

  constructor(private loggingService: LoggingService,
              private configService: ConfigService,
              private router: Router) {
  }

  /**
   * Temporary fix so that a page refresh does not break the whole system
   * @returns {Promise<T>}
   */
  public canActivate(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      // Making sure we are also loading the required data together with the user
      const dataFetch: Promise<any>[] = [];
      dataFetch.push(this.configService.retrievePatentTypes());
      dataFetch.push(this.configService.retrieveServices());
      dataFetch.push(this.configService.retrieveProjectCategories());
      dataFetch.push(this.configService.retrieveFileCategoryTypes());
      dataFetch.push(this.configService.retrieveAdditionalServices());

      // When all the data finishes loading async
      Promise.all(dataFetch)
        .then(() => {
          resolve(true);
        })
        .catch(() => {
          console.error('Failed to load session instance (project)');
          resolve(false);
          // Navigate to the error page to give chance for recovery
          this.router.navigate(['error-404']);
        });
    });
  }
}

