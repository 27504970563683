<ul class="pager">
  <li [class.disabled]="noPrevious()" [class.previous]="align"
      [ngClass]="{'pull-right': align, 'float-right': align}"
      class="{{ pageBtnClass }}">
    <a href (click)="selectPage(page - 1, $event)">{{ getText('previous') }}</a>
  </li>
  <li [class.disabled]="noNext()" [class.next]="align"
      [ngClass]="{'pull-right': align, 'float-right': align}"
      class="{{ pageBtnClass }}">
    <a href (click)="selectPage(page + 1, $event)">{{ getText('next') }}</a>
  </li>
</ul>
