import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TrimmedTextComponent } from './trimmed-text.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

@NgModule({
  imports: [
    CommonModule,
    TooltipModule
  ],
  declarations: [TrimmedTextComponent],
  exports: [TrimmedTextComponent]
})
export class TrimmedTextModule { }
