import { EventEmitter, Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { MessagingService } from './messaging.service';
import * as _ from 'underscore';
import { UserService } from './user.service';
import { PortalUser } from '../domain/user';
import { AuthService } from '../../security/auth.service';
import { isNullOrUndefined } from '../utils/is-null-or-undefined';

@Injectable()
export class ToastGroupingService {

  public onGrouped: EventEmitter<number>;

  public onGroupSelect: EventEmitter<boolean>;

  private threshold: number;

  private groupCount: number;

  private interval: any;

  private enabled: boolean;

  private user: PortalUser;

  constructor(private toastService: ToastrService,
              private userService: UserService,
              private authService: AuthService,
              private messagingService: MessagingService) {
    this.threshold = 5;
    this.groupCount = 0;
    this.enabled = false;
    this.onGrouped = new EventEmitter();
    this.onGroupSelect = new EventEmitter();
    this.monitorUser();
  }

  /**
   * Enables the grouping of alert messages by the given threshold.
   * It checks the number of alerts using an interval
   */
  public enable(): void {
    // Making sure we don't create another listener
    if (this.enabled) {
      return;
    }
    // We'll be getting the alerts from the ToastService as this will always have the
    // the most accurate number of alerts currently visible
    // Also, this was set to interval so that if a user gets a new alert,
    // it will appear at least once before getting grouped
    this.interval = setInterval(() => {
      if (this.toastService.toasts.length > this.threshold) {
        const userAlerts = _.filter(this.messagingService.alerts.getValue(), (alert) => {
          if (!isNullOrUndefined(this.user)) {
            const recipient = alert.messageRecipients.find(mr => mr.user.id === this.user.id);
            if (!recipient) {
              return false;
            }
            return !recipient.read;
          } else {
            return false;
          }
        });

        if (userAlerts.length === 0) {
          // Try to filter again on the next tick
          // this.messagingService.alerts.getValue does not have yet any value.
          return;
        }

        this.groupCount = userAlerts.length;
        this.clearAll();
        this.onGrouped.emit(this.groupCount);
      }
    }, 2000);

    this.enabled = true;
  }

  /**
   * Clears the interval so it doesn't keep on running.
   * Usually called when the user logs out
   */
  public disable(): void {
    clearInterval(this.interval);
    this.enabled = false;
  }

  /**
   * Resets the groupings count.
   * Usually called when the user clears the alert for the grouping
   */
  public reset(): void {
    // console.log('Resetting alert group count');
    this.groupCount = 0;
  }

  /**
   * Clears all the current content of the toaster service
   */
  public clearAll(): void {
    for (const toast of this.toastService.toasts) {
      this.toastService.clear(toast.toastId);
    }
  }

  public selectGroup(toastId: number): void {
    this.toastService.clear(toastId);
    this.onGroupSelect.emit(true);
  }

  private monitorUser(): void {
    this.userService.userEmitter.subscribe(user => {
      this.user = user;
    });

    this.authService.connectedEmitter.subscribe(connected => {
      if (!connected) {
        this.user = undefined;
      }
    });
  }
}
