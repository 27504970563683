import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverviewDateDisplayComponent } from './overview-date-display.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [OverviewDateDisplayComponent],
  exports: [OverviewDateDisplayComponent]
})
export class OverviewDateDisplayModule { }
