import { Injectable } from '@angular/core';
import { GlobalModalService } from '../../controls/global-modal/global-modal.service';

@Injectable()
export class ConfirmModalService {

  constructor(
    private globalModalService: GlobalModalService,
  ) {
  }

  public confirmDiscard(): Promise<boolean> {
    return this.globalModalService.confirmDiscard();
  }

  public confirmDelete(): Promise<boolean> {
    const message = 'Are you sure you want to delete?';
    return this.globalModalService.confirm({ message, heading: 'Warning' });
  }

  public confirmCustom(message: string, header: string): Promise<boolean> {
    return this.globalModalService.confirm({ message, heading: header });
  }

  public confirmDialog(
    message: string,
    header: string = 'Confirmation',
    acceptLabel: string = 'Yes',
    rejectLabel: string = 'No',
    showReject: boolean = true, // Not used anymore
  ): Promise<boolean> {
    return this.globalModalService.confirm({
      message,
      heading: header,
      yesLabel: acceptLabel,
      noLabel: rejectLabel,
      noVisible: showReject,
    });
  }

  // NOTE: Old implementation = only keeping this for future debugging reference
  // public confirmDialog(
  //   message: string,
  //   header: string,
  //   acceptLabel: string = 'Yes',
  //   rejectLabel: string = 'No',
  //   showReject: boolean = true,
  //   icon: string = null
  // ): Promise<boolean> {
  //
  //   return new Promise<boolean>((resolve) => {
  //     this.confirmationSvc.confirm({
  //       header: header,
  //       icon: icon,
  //       message: message,
  //       acceptLabel: acceptLabel,
  //       acceptVisible: true,
  //       rejectLabel: rejectLabel,
  //       rejectVisible: showReject,
  //       accept: () => {
  //         resolve(true);
  //       },
  //       reject: () => {
  //         resolve(false);
  //       }
  //     });
  //   });
  // }
}
