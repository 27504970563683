import { Component, ContentChild, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ModalFooterComponent } from './modal-footer/modal-footer.component';

@Component({
  selector: 'townip-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {

  @ContentChild(ModalFooterComponent, /* TODO: add static flag */ {})
  public modalFooter: ModalFooterComponent;

  @ViewChild(ModalDirective, { static: true })
  public modal: ModalDirective;

  @Input()
  public size: 'sm' | 'lg' | '' = '';

  @Input()
  public heading: string;

  @Input()
  public zIndex: number;

  @Input()
  public showCloseButton = true;

  /**
   * Notify the component if they clicked the 'x' button
   */
  @Output()
  // NOTE: Only suppressing because legacy.
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  public onHide: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() { }

  public ngOnInit(): void {
  }

  public show(): void {
    this.modal.show();
  }

  public hide(): void {
    this.modal.hide();
  }

  public onCloseButton(): void {
    this.onHide.emit(true);
  }

}
