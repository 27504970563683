import * as _ from 'underscore';
import { Pipe, PipeTransform } from '@angular/core';
import { CheckListItem } from './check-list.component';
import { isNullOrUndefined } from '../../utils/is-null-or-undefined';

@Pipe({
  name: 'checkListFilter'
})
export class CheckListFilterPipe implements PipeTransform {

  public transform(value: CheckListItem[], args?: string): CheckListItem[] {
    if (isNullOrUndefined(args) || args === '') {
      return value;
    }

    return _.filter(value, (item) => {
      return new RegExp(args, 'ig').test(item.label);
    });
  }
}
