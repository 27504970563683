/**
 * Service for saving any filter-related settings that needs to persist across pages
 */

import * as _ from 'underscore';
import { Injectable } from '@angular/core';
import { FilterGroup } from '../filter';

interface FilterSelectionData {
  id: string;
  activeFilters: FilterGroup[];
}

@Injectable()
export class FilterSelectionMemoryService {

  private datastore: FilterSelectionData[] = [];

  constructor() { }

  public load(id: string): FilterGroup[] {
    const data = _.findWhere(this.datastore, { id: id });
    if (data) {
      return data.activeFilters;
    }

    return [];
  }

  /**
   * Saves the data in memory only. Will not persist on page reload.
   * @param {string} id
   * @param {FilterGroup[]} filters
   */
  public save(id: string, filters: FilterGroup[]): void {
    const data = _.findWhere(this.datastore, { id: id });
    if (data) {
      data.activeFilters = filters;
      return;
    }

    const newData: FilterSelectionData = {
      id: id,
      activeFilters: filters,
    };

    this.datastore.push(newData);
  }

  public clear(id: string): void {
    this.datastore = _.filter(this.datastore, data => data.id !== id);
  }

}
