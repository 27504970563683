import { ReadableConfigValue } from './readable-config-value';

export enum FileCategory {
  // TODO: Add others here in the future
  MARK_IMAGE = 'MARK_IMAGE',
  ADDITIONAL_IMAGE = 'ADDITIONAL_IMAGE',
}

/**
 * Created by aeleneski on 6/15/2017.
 */
export interface FileCategoryType extends ReadableConfigValue {
  fileCategory?: FileCategory | string;
  fileCategoryType?: string;
  fileStatus?: string;
}
