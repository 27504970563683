import { Component, OnInit, Input, forwardRef, EventEmitter, Output, OnChanges, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, UntypedFormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as moment from 'moment';
import { isNullOrUndefined } from '../../shared/utils/is-null-or-undefined';

interface ConvertDateOptions {
  toUTC: boolean;
}

/**
 * This wrapper was created to properly handle UTC dates usage,
 * because there's a problem with prime calendar handling of [utc]=true.
 *
 * Prime calendar doesn't correctly display/highlight the date specified in the calendar selector.
 *
 * This also handles date in timestamp format to help us avoid manually creating date object.
 *
 */
@Component({
  selector: 'townip-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CalendarComponent),
    multi: true,
  }]
})
export class CalendarComponent implements OnInit, ControlValueAccessor, OnChanges {

  /**
   * If set to true, the date coming IN and OUT will be converted to handle the UTC dates properly
   * If set to false, it will just use the prime calendar normally
   */
  @Input()
  public utc: boolean;    // We should handle utc conversion on this wrapper, not on the Prime Calendar component

  /**
   * This wrapper can handle a Timestamp type date, and a Date type
   */
  @Input()
  public dateType: 'timestamp' | 'date' = 'timestamp';

  /**
   * Any additional class that will be used to change the prime calendar should go here
   */
  @Input()
  public class: string;

  @Input()
  public inputStyleClass: string;

  @Input()
  public disabled = false;

  @Input()
  public showIcon: boolean;

  @Input()
  public showTime = false;

  @Input()
  public maxDate: Date = null;

  @Input()
  public minDate: Date = null;

  @Input()
  public readonlyInput: boolean = null;

  @Input()
  public defaultDate: Date = null;

  @Input()
  public dateFormat = 'mm/dd/yy';

  @Input()
  public placeholder: string;

  @Input()
  public style: any;

  @Input()
  public inline: boolean;

  @Input()
  public monthNavigator = true;

  @Input()
  public yearNavigator = true;

  @Input()
  public yearRange: string;

  @Input()
  public selectionMode = 'single'; // 'single' or 'multiple' or 'range'

  @Input()
  public showButtonBar = false;

  @Input()
  public appendTo: string;

  /**
   * This is not required, but all the changes will be emitted to this output field
   * @type {EventEmitter<Date | Date[] | number | number[]>}
   */
  @Output()
  public valueChanged = new EventEmitter<Date | Date[] | number | number[]>();

  public onChange: any;

  public onTouched: any;

  public form: UntypedFormGroup;

  constructor() {
    this.form = new UntypedFormGroup({
      value: new UntypedFormControl(null),
    });

    this.form.valueChanges.subscribe((data) => {
      this.updateValue(data.value);
    });
  }

  public ngOnInit(): void {
    this.generateYearRange();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.disabled && this.form) {
      if (this.disabled) {
        this.form.get('value')
          .disable();
      } else {
        this.form.get('value')
          .enable();
      }
    }
  }

  public writeValue(value: any): void {
    const writeValue = this.selectionMode === 'single'
      ? this.writeSingleDate(value)
      : this.writeMultipleDates(value);

    // emiEvent = false will prevent from calling the valueChanges subscription
    // At this stage we are assigning value to the control not changing to a new value
    this.form
      .get('value')
      .setValue(writeValue, { emitEvent: false });
  }

  private writeSingleDate(value: any): Date {
    let date: Date;
    if (this.dateType === 'timestamp' && !isNullOrUndefined(value)) {
      // If the date is timestamp, we need to convert it to Date as it is needed in the prime calendar
      date = new Date(value as number);
    }

    if (this.utc && !isNullOrUndefined(value)) {
      // If UTC is true, we should convert the date to utc and ignore timezone before passing it to prime calendar
      date = this.convertDate(date, { toUTC: true });
    }
    return date;
  }

  private writeMultipleDates(values: any): Date[] {
    if (!Array.isArray(values)) {
      return null;
    }
    return values.map(value => this.writeSingleDate(value));
  }

  public updateValue(value: any): void {
    const updateValue = this.selectionMode === 'single'
      ? this.updateSingleDate(value as Date)
      : this.updateMultipleDates(value as Date[]);

    if (!isNullOrUndefined(this.onChange)) {
      this.onChange(updateValue);
    }

    this.valueChanged.emit(updateValue);
  }

  public updateSingleDate(value: Date, endOfDay: boolean = false): Date | number {
    let date: any = moment(value);

    if (!date.isValid() || isNullOrUndefined(value)) {
      return null;
    }

    if (endOfDay) {
      date = date.endOf('day');
    }

    if (this.utc) {
      // If UTC is true, we should convert the date to normal
      date = moment(this.convertDate(date, { toUTC: false }));
    }

    if (this.dateType === 'timestamp') {
      // If timestamp is set to true, convert it back to timestamp value before emitting out.
      return date.valueOf();
    }
    return date.toDate();
  }

  public updateMultipleDates(values: Date[]): any[] {
    if (!Array.isArray(values)) {
      return null;
    }
    if (this.selectionMode === 'range') {
      return values.map((value, index) => this.updateSingleDate(value, index > 0));
    }
    return values.map(value => this.updateSingleDate(value));
  }

  public registerOnChange(func: any): void {
    this.onChange = func;
  }

  public registerOnTouched(func: any): void {
    this.onTouched = func;
  }

  /**
   * This method will convert the date to UTC (options.toUTC = true) or from UTC to normal date (options.toUTC = false)
   * @param {Date} value
   * @param options
   *    options.toUTC: boolean
   * @returns {Date}
   */
  private convertDate(value: Date, options: ConvertDateOptions): Date {
    let momentValue = moment(value);
    if (!momentValue.isValid()) {
      return null;
    }

    const timestamp = momentValue.valueOf();
    let givenTimezoneOffset = moment
      .parseZone(value)
      .utcOffset();

    // For UTC conversion, we need to get the Timezone offset
    // And it will be used later to subtract from the current date value, to get the UTC date value
    if (options.toUTC === true) {
      givenTimezoneOffset = givenTimezoneOffset * -1;
    }

    if (givenTimezoneOffset !== 0) {
      // Apply timezone offset
      momentValue = moment(timestamp)
        .utc()
        .add(givenTimezoneOffset, 'minutes');
    } else {
      // IF the given timezone offset is zero, we will just set it to UTC.
      momentValue = moment(value)
        .utc();
    }

    return momentValue.toDate();
  }

  public generateYearRange(): void {
    if (this.yearRange) {
      return;
    }
    const today = new Date();
    const minYear = today.getFullYear() - 25;
    const maxYear = today.getFullYear() + 5;
    this.yearRange = minYear + ':' + maxYear;
  }
}
