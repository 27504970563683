import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckListComponent } from './check-list.component';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { FormsModule } from '@angular/forms';
import { CheckListFilterPipe } from './check-list-filter.pipe';
import { CheckListGroupFilterPipe } from './check-list-group-filter.pipe';
import { DirectivesModule } from '../../directives/directives.module';
import { CheckListSubgroupFilterPipe } from './check-list-subgroup-filter.pipe';
import { ChecklistGroupParentFilterPipe } from './checklist-group-parent-filter.pipe';
import { CheckboxModule } from 'primeng/checkbox';
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';
import { ScrollPanelModule } from 'primeng/scrollpanel';

@NgModule({
  imports: [
    CommonModule,
    CheckboxModule,
    FormsModule,
    AccordionModule,
    DirectivesModule,
    TriStateCheckboxModule,
    ScrollPanelModule,
  ],
  declarations: [
    CheckListComponent,
    CheckListFilterPipe,
    CheckListGroupFilterPipe,
    CheckListSubgroupFilterPipe,
    ChecklistGroupParentFilterPipe
  ],
  exports: [CheckListComponent]
})
export class CheckListModule {
}
