import { Injectable } from '@angular/core';

export interface ErrorLog {
  error?: any;
  timestamp?: string;
  location?: string;
}

/**
 * Stores an error so we can display them.
 * Use this to store the error before navigating to the error pages or other pages
 */

@Injectable()
export class ErrorLogService {

  private currentError: string;
  private timestamp: string;
  private location: string;

  constructor() {

  }

  public setError(error: string): void {
    this.currentError = error;
    this.timestamp = new Date().toISOString();
    this.location = window.location.href;
  }

  public getError(): ErrorLog {
    return { error: this.currentError, timestamp: this.timestamp, location: this.location };
  }

}
