import { IndexedSelectItem } from '../message-find-recipient/message-find-recipient.component';

export class RecipientFilter {
  public static isUserExisting(user: IndexedSelectItem, key: string): boolean {
    let filterByOrganization = false;
    const filterByName = user.label
      .toLowerCase()
      .includes(key.toLowerCase());

    if (user.value.organization) {
      filterByOrganization = user.value.organization
        .toLowerCase()
        .includes(key.toLowerCase());
    }

    return filterByName || filterByOrganization;
  }
}
