import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RequestService } from '../shared/services/request-service';
import { DocumentService } from './document.service';
import { ReportRequest } from '../shared/data-filter/report-filter/report-filter.model';

@Injectable()
export class ReportService {

  constructor(private httpClient: HttpClient,
              private requestService: RequestService,
              private docService: DocumentService) {
  }

  public generateReport(reportRequest: ReportRequest): void  {
    const url = '/api/report/generate';
    const headers = this.requestService.buildHttpHeaders();

    this.httpClient.post(url, reportRequest, {
      headers: headers,
      observe: 'response',
      responseType: 'blob'
    })
      .toPromise()
      .then(resp => {
        this.docService.saveFile(resp);
      }
      );
  }
}
