import { Injectable } from '@angular/core';
import { LoggingService } from './logging.service';
import { environment } from '../../environments/environment';

export const isDebugMode = !environment.production;

const noLog = (): any => undefined;

@Injectable()
export class ConsoleLoggingService implements LoggingService {

  get info(): any {
    if (isDebugMode) {
      // eslint-disable-next-line no-console
      return console.info.bind(console);
    } else {
      return noLog;
    }
  }

  get warn(): any {
    if (isDebugMode) {
      return console.warn.bind(console);
    } else {
      return noLog;
    }
  }

  get error(): any {
    if (isDebugMode) {
      return console.error.bind(console);
    } else {
      return noLog;
    }
  }

  public invokeConsoleMethod(type: string, args?: any): void {
    // eslint-disable-next-line no-console
    const logFn = (console)[type] || console.log || noLog;
    logFn.apply(console, [args]);
  }
}
