import { ProjectRequest } from './project-request';
import { BillingInfo } from '../dto/billing-info';

export class OrderRequest {
  public customerId: number;
  public submitterId: number;
  public originalEstimateId: number;
  public orderEstimate: ProjectRequest;
  public billingInfo: BillingInfo;
  public directOrder: boolean;

  constructor(customerId: number,
    submitterId: number,
    originalEstimateId: number,
    orderEstimate?: ProjectRequest,
    billingInfo?: BillingInfo,
    directOrder?: boolean) {
    this.customerId = customerId;
    this.submitterId = submitterId;
    this.originalEstimateId = originalEstimateId;
    this.orderEstimate = orderEstimate;
    this.billingInfo = billingInfo;
    this.directOrder = directOrder;
  }
}
