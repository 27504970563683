import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LoggingService } from '../logging.service';
import { UserService } from '../services/user.service';
import { MessageService } from 'primeng/api';
import { sendToRaygun } from '../../main/error-handlers/global-error-handler.service';
import { isNullOrUndefined } from '../utils/is-null-or-undefined';


@Injectable()
export class NonVendorGuardService  {

  constructor(private loggingService: LoggingService,
              private router: Router,
              private growlService: MessageService,
              private userService: UserService) {
  }

  /**
   * Guard to prevent a PM from opening the create-estimate page in a bad state.
   * @returns {Promise<T>}
   */
  public canActivate(): Promise<boolean> {
    return new Promise((resolve) => {
      const user = this.userService.getUser();
      if (isNullOrUndefined(user)) {
        this.router.navigate(['/auth/login']);
        resolve(false);
        return;
      }
      if (user.userType !== 'VENDOR') {
        resolve(true);
      } else {

        // Manually send the error; (For figuring out what's wrong with Bug 5562
        try {
          throw new Error('403 Error from NonVendorGuard');
        } catch (e) {
          console.error(e);
          sendToRaygun(e, {
            desc: 'Current userService.getUser() instance has been attached',
            user: this.userService.getUser()
          });
        }

        this.growlService.add({
          severity: 'warn',
          summary: 'Oops, you do not have the permissions to access that page',
          detail: ' '
        });
        this.router.navigate(['/error-403']);
        resolve(false);
      }
    });
  }
}
