import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ValidateMinNumberDirective } from './validate-min-number.directive';
import { ValidateMaxNumberDirective } from './validate-max-number.directive';
import { ValidatePhoneNumberDirective } from './validate-phone-number';
import { ValidateMinDateDirective } from './validate-min-date.directive';
import { ValidateMaxDateDirective } from './validate-max-date.directive';
import { ValidateBlankOrValidEmailDirective } from './validate-blank-or-valid-email';
import { ValidateRequiredPhoneDirective } from './validate-required-phone.directive';
import { ValidateEmailPatternDirective } from './validate-email-pattern.directive';
import { ValidateEmailPatternListDirective } from './validate-email-pattern-list.directive';
import { ValidateOptionalPhoneDirective } from './validate-optional-phone.directive';
import { ValidatePatentNumberDirective } from './validate-patent-number.directive';
import { ValidateFileNamePatternDirective } from './validate-file-name-pattern.directive';
import { ValidateFamilyNameDirective } from './validate-family-name.directive';
import { ValidateFamilyNumberDirective } from './validate-family-number.directive';
import { ValidateDiscountTypeDirective } from './validate-discount-type.directive';
import { ValidateEqualToDirective } from './validate-equal-to.directive';
import { ValidateExistingArrayValueDirective } from './validate-existing-array-value.directive';
import { ValidateDuplicateArrayValuesDirective } from './validate-duplicate-array-values.directive';
import { ValidateUniqPriorityNumberDirective } from './validate-uniq-priority-number.directive';
import { ValidateExistingEmailDirective } from './validate-existing-email.directive';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    ValidateMinNumberDirective,
    ValidateMaxNumberDirective,
    ValidatePhoneNumberDirective,
    ValidateMinDateDirective,
    ValidateMaxDateDirective,
    ValidateBlankOrValidEmailDirective,
    ValidateRequiredPhoneDirective,
    ValidateEmailPatternDirective,
    ValidateEmailPatternListDirective,
    ValidateOptionalPhoneDirective,
    ValidatePatentNumberDirective,
    ValidateFileNamePatternDirective,
    ValidateFamilyNameDirective,
    ValidateFamilyNumberDirective,
    ValidateDiscountTypeDirective,
    ValidateEqualToDirective,
    ValidateExistingArrayValueDirective,
    ValidateDuplicateArrayValuesDirective,
    ValidateUniqPriorityNumberDirective,
    ValidateExistingEmailDirective,
  ],
  exports: [
    ValidateMinNumberDirective,
    ValidateMaxNumberDirective,
    ValidatePhoneNumberDirective,
    ValidateMinDateDirective,
    ValidateMaxDateDirective,
    ValidateBlankOrValidEmailDirective,
    ValidateRequiredPhoneDirective,
    ValidateEmailPatternDirective,
    ValidateEmailPatternListDirective,
    ValidateOptionalPhoneDirective,
    ValidatePatentNumberDirective,
    ValidateFileNamePatternDirective,
    ValidateFamilyNameDirective,
    ValidateFamilyNumberDirective,
    ValidateDiscountTypeDirective,
    ValidateEqualToDirective,
    ValidateExistingArrayValueDirective,
    ValidateDuplicateArrayValuesDirective,
    ValidateUniqPriorityNumberDirective,
    ValidateExistingEmailDirective,
  ]
})
export class ValidatorsModule { }
