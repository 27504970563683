<ng-container *ngIf="mode === 'lite'">
  <ng-container [ngSwitch]="type">
    <div *ngSwitchCase="'success'" class="alert alert-success {{iconAlignment}} text-dark d-flex align-items-center mb-0">
      <i class="fa mr-3 text-success fa-check-circle" *ngIf="!customIcon; else customIconImg"></i>
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </div>
    <div *ngSwitchCase="'warning'" class="alert alert-warning {{iconAlignment}} text-dark d-flex align-items-center mb-0">
      <i class="fa mr-3 text-warning {{warningIcon}}"></i>
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </div>
    <div *ngSwitchCase="'danger'" class="alert alert-danger {{iconAlignment}} text-dark d-flex align-items-center mb-0">
      <i class="fa mr-3 text-danger {{dangerIcon}}" *ngIf="!customIcon; else customIconImg"></i>
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </div>
    <div *ngSwitchCase="'info'" class="alert alert-info {{iconAlignment}} text-dark d-flex align-items-center mb-0">
      <i class="fa {{infoIcon}} mr-3 text-info"></i>
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </div>
    <div *ngSwitchCase="'secondary'" class="alert alert-secondary {{iconAlignment}} text-dark d-flex align-items-center mb-0">
      <i class="fa mr-3 text-info fa-info-circle"></i>
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </div>
  </ng-container>
</ng-container>

<ng-container *ngIf="mode === 'default'">
  <ng-container [ngSwitch]="type">
    <div *ngSwitchCase="'success'" class="d-flex border rounded overflow-hidden border-primary">
      <div class="d-flex align-items-center justify-content-center py-2 px-4" style="background-color: #D9F2EC;">
        <i class="fa fa-check-circle fa-lg text-primary"></i>
      </div>
      <div class="d-flex align-items-center border-left w-100 border-primary py-2 px-3">
        <ng-container *ngTemplateOutlet="content"></ng-container>
      </div>
    </div>
    <div *ngSwitchCase="'warning'" class="d-flex border rounded overflow-hidden border-warning">
      <div class="d-flex align-items-center justify-content-center py-2 px-4" style="background-color: #FFF3CD;">
        <i class="fa {{warningIcon}} fa-lg text-warning"></i>
      </div>
      <div class="d-flex align-items-center border-left w-100 border-warning py-2 px-3">
        <ng-container *ngTemplateOutlet="content"></ng-container>
      </div>
    </div>
    <div *ngSwitchCase="'danger'" class="d-flex border rounded overflow-hidden border-danger">
      <div class="d-flex align-items-center justify-content-center py-2 px-4" style="background-color: #E5B6B6;">
        <i class="fa {{dangerIcon}} fa-lg text-danger"></i>
      </div>
      <div class="d-flex align-items-center border-left w-100 border-danger py-2 px-3">
        <ng-container *ngTemplateOutlet="content"></ng-container>
      </div>
    </div>
    <div *ngSwitchCase="'info'" class="d-flex border rounded overflow-hidden border-info">
      <div class="d-flex align-items-center justify-content-center py-2 px-4" style="background-color: #cce5ff;">
        <i class="fa {{infoIcon}} fa-lg text-info"></i>
      </div>
      <div class="d-flex align-items-center border-left w-100 border-info py-2 px-3">
        <ng-container *ngTemplateOutlet="content"></ng-container>
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-template #content>
  <div class="w-100">
    <ng-content></ng-content>
  </div>
</ng-template>

<ng-template #customIconImg>
  <img class="mr-3 align-self-baseline" src="{{imgSrc}}">
</ng-template>
