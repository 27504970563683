import * as _ from 'underscore';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FilterSelectionComponent } from '../../filter-selection/filter-selection.component';
import { FilterGroup, FilterParameters, TableHeaderFilter } from '../../filter';
import { HeaderFilterOptions } from '../header-filters.models';
import { SelectItem } from 'primeng/api';
import { UserService } from '../../../services/user.service';
import { UserType } from '../../../../main/navigation/menu/menu.component';
import { GroupTabMode } from '../../../overview-utilities/overview-tabs/overview-tabs.models';
import { ActivatedRoute } from '@angular/router';
import { debounceTime } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { isNullOrUndefined } from '../../../utils/is-null-or-undefined';

@Component({
  selector: 'townip-header-filter',
  templateUrl: './header-filter.component.html',
  styleUrls: ['./header-filter.component.scss']
})
export class HeaderFilterComponent implements OnInit, OnChanges, OnDestroy {

  @Input()
  public tableHeaderFiltersParam: string;

  @Input()
  public options: HeaderFilterOptions;

  @Input()
  public mode: GroupTabMode;

  // This is linked to the filter selection component as it is the one managing the active filters
  @Input()
  public filterSelection: FilterSelectionComponent;

  // Used for updating the selectvalues when they come from async values
  @Input()
  public metadata: any;

  @Input()
  public fieldValueOverride: any;

  public currentValue: any[];

  private name: string; // The name of this filter. Used for disabling/removing/adding

  private filterUpdate = new EventEmitter<any>();

  public booleanOptions: SelectItem[];

  public rangeOptions: SelectItem[];

  private filterSelectionSubscription: Subscription;

  private filterUpdateSubscription: Subscription;

  constructor(
    private route: ActivatedRoute,
    private userSvc: UserService
  ) {
    this.name = 'header_' + Math.ceil(Math.random() * 100000);
  }

  public ngOnInit(): void {
    this.booleanOptions = [
      { label: 'All', value: null },
      { label: 'Yes', value: 'true' },
      { label: 'No', value: 'false' },
    ];

    if (isNullOrUndefined(this.options)) {
      return;
    }

    if (!isNullOrUndefined(this.filterSelection)) {
      this.filterSelectionSubscription = this.filterSelection.onClear.subscribe(() => {
        this.clear();
      });
    }

    // Update filters only after 0.7 seconds
    this.filterUpdateSubscription = this.filterUpdate
      .pipe(debounceTime(700))
      .subscribe(() => {
        this.activateFilter();
      });

  }

  // ngDoCheck() {
  //   this.processMetadataValues();
  // }

  public ngOnChanges(changes: SimpleChanges): void {
    // Handles the async update of metadata,
    // Update the metadata on our reference object
    this.resetValues();
    this.processMetadataValues();
    this.processQueryParamsFilters();

    // if options have been changed, and the the current filter selection has an active filter
    // with the same name as this filter, set the currentValue based on the active filter
    if (changes.metadata) {
      this.resetCurrentValue();
    }
  }

  public ngOnDestroy(): void {
    if (this.filterSelectionSubscription) {
      this.filterSelectionSubscription.unsubscribe();
    }

    if (this.filterUpdateSubscription) {
      this.filterUpdateSubscription.unsubscribe();
    }
  }

  public signalUpdate(): void {
    this.filterUpdate.emit(null);
  }

  public activateFilter(): void {
    if (this.options.input === 'date') {
      this.processDate();
      return;
    }

    if (this.options.input === 'boolean') {
      this.processBool();
      return;
    }

    if (this.options.input === 'multiselect') {
      this.processMulti();
      return;
    }

    if (this.options.input === 'range') {
      this.process();
      return;
    }
  }

  public processBool(): void {
    if (this.currentValue[0] === null || this.currentValue[0] === 'null') {
      this.clear();
      return;
    }

    const filterGroup: FilterGroup = {
      name: this.name,
      filters: [
        {
          name: this.name,
          field: this.options.field,
          type: this.options.type,
          operator: this.options.operator,
          value: this.currentValue[0] === 'true',
          fieldValueOverride: this.fieldValueOverride ? this.fieldValueOverride : null
        }
      ],
      category: 'custom_header'
    };

    this.filterSelection.removeFromActiveByName(this.name);
    this.filterSelection.select(filterGroup);
  }

  public processDate(): void {
    if (!Array.isArray(this.currentValue)) {
      this.filterSelection.removeFromActiveByName(this.name);
      return;
    }

    if (isNullOrUndefined(this.currentValue[0]) || isNullOrUndefined(this.currentValue[1])) {
      this.filterSelection.removeFromActiveByName(this.name);
      return;
    }

    const filterGroup: FilterGroup = {
      name: this.name,
      filters: [
        {
          name: this.name,
          field: this.options.field,
          type: this.options.type,
          operator: this.options.operator,
          value: this.currentValue,
          fieldValueOverride: this.fieldValueOverride ? this.fieldValueOverride : null
        }
      ],
      category: 'custom_header'
    };

    this.filterSelection.removeFromActiveByName(this.name);
    this.filterSelection.select(filterGroup);
  }

  public processMulti(): void {
    if (this.currentValue.length === 0) {
      this.clear();
      return;
    }

    this.process();
  }

  public process(): void {
    const filter: FilterParameters = {
      name: this.name,
      field: this.options.field,
      type: this.options.type,
      operator: this.options.operator,
      value: this.currentValue,
      fieldValueOverride: this.fieldValueOverride ? this.fieldValueOverride : null
    };

    if (this.options.collectionField) {
      filter.collectionField = this.options.collectionField;
    }

    const filterGroup: FilterGroup = {
      name: this.name,
      filters: [filter],
      category: 'custom_header'
    };

    this.filterSelection.removeFromActiveByName(this.name);
    this.filterSelection.select(filterGroup);
  }

  public clear(): void {
    this.resetValues();
    this.filterSelection.removeFromActiveByName(this.name);
  }

  public resetValues(): void {
    switch (this.options.input) {
    case 'multiselect':
      this.currentValue = [];
      break;
    case 'date':
      this.currentValue = null;
      break;
    case 'range':
      this.currentValue = [0, this.options.selectValues[1]]; // Select the max by default
      break;
    case 'boolean':
      this.currentValue = [];
      this.currentValue[0] = null;
    }
  }

  private resetCurrentValue(): void {
    if (!this.filterSelection || !Array.isArray(this.filterSelection.activeFilters)) {
      return;
    }

    this.filterSelection.activeFilters.forEach(activeFilter => {
      // if not same name, skip
      if (activeFilter.name !== this.name) {
        return;
      }

      // if no filters, skip
      if (!Array.isArray(activeFilter.filters) || activeFilter.filters.length < 1) {
        return;
      }

      // assign value of activeFilter to currentValue
      this.currentValue = activeFilter.filters[0].value;
    });
  }

  private processQueryParamsFilters(): void {
    if (isNullOrUndefined(this.tableHeaderFiltersParam)) {
      return;
    }

    const tableHeaderFilters = JSON.parse(this.tableHeaderFiltersParam) as TableHeaderFilter[];

    switch (this.options.header) {
    case 'Stage':
      const stageTableFilter = tableHeaderFilters.find(p => p.colName === 'Stage');
      const stageFilters = stageTableFilter ? stageTableFilter.filters : null;
      this.currentValue = _.map(stageFilters, (filter: FilterGroup) => filter.name);
      break;
    case 'Assignee':
      const assigneeTableFilter = tableHeaderFilters.find(p => p.colName === 'Assignee');
      const assigneeFilters = assigneeTableFilter ? assigneeTableFilter.filters : null;
      this.currentValue = _.map(assigneeFilters, (filter: FilterGroup) => filter.name);
      break;
    default:
      break;
    }

    this.signalUpdate();
  }

  /**
   * Sets the metadata values as the select items/values
   * Also makes sure there are no duplicates
   */
  private processMetadataValues(): void {
    try {
      if (this.metadata[this.options.field]) {
        const filteredMetadataValues = [];
        const metadataValue = this.metadata[this.options.field];
        const user = this.userSvc.getUser();
        // Assigned User Filter Options
        if (this.options.field === 'assignee.assignedUser') {
          // Push first the Unassigned option
          if (user.userType === UserType.VENDOR || user.userType === UserType.VENDOR_MANAGER) {
            // Check if there is an unassigned task
            if (metadataValue.find(v => v.label === '' && v.value !== null)) {
              const vendor = metadataValue.find(v => v.label === '' && v.value !== null).value;
              filteredMetadataValues.push({ label: 'Unassigned', value: vendor });
            }
          } else {
            // Check if there is an unassigned task
            if (metadataValue.find(v => v.label === '' && v.value === '')) {
              filteredMetadataValues.push({ label: 'Unassigned', value: '' });
            }
          }

          for (const item of metadataValue) {
            // Do not include null label and string
            if (item.label === '' && item.value === '') {
              continue;
            }

            if (item.label === '' && item.value !== null) {
              continue;
            }

            // To Avoid duplicates
            if (!_.findWhere(filteredMetadataValues, item)) {
              filteredMetadataValues.push(item);
            }
          }
        } else {
          // Making sure there is no duplicate
          for (const item of metadataValue) {
            if (!_.findWhere(filteredMetadataValues, item)) {
              filteredMetadataValues.push(item);
            }
          }
        }

        this.options.selectValues = filteredMetadataValues;

        // Sort dropdown items
        if (this.options.input === 'multiselect') {
          this.options.selectValues = _.sortBy(this.options.selectValues, (item) => {
            return item.label.toLowerCase();
          });
        }

        // Update the highest value if this is a range type
        if (this.options.input === 'range') {
          this.currentValue[1] = metadataValue[1];
          this.buildRangeOptions(metadataValue[1]);
        }
      }
    } catch (e) {
      // Metadata does not exist for this field
    }
  }

  private buildRangeOptions(maxCount: number): void {
    if (this.mode === GroupTabMode.Forecast) {
      // This will be the range options for the Forecast Table
      this.rangeOptions = [
        { label: 'All', value: [0, maxCount] },
        { label: '0 - 50K', value: [0, 50000] },
        { label: '50K - 100K', value: [50000, 100000] },
        { label: 'Greater than 100K', value: [100000, maxCount] }
      ];
    } else {
      // This will be the default range options for now.
      this.rangeOptions = [
        { label: 'All', value: [0, maxCount] },
        { label: '0 - 10K', value: [0, 10000] },
        { label: '10K - 25K', value: [10000, 25000] },
        { label: '25K - 50K', value: [25000, 50000] },
        { label: 'Greater than 50K', value: [50000, maxCount] }
      ];
    }
  }
}
