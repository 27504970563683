import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ModalComponent } from '../../../controls/modal/modal.component';
import { GlobalModalService } from '../../../controls/global-modal/global-modal.service';

@Component({
  selector: 'townip-leave-page-warning-modal',
  templateUrl: './leave-page-warning-modal.component.html',
  styleUrls: ['./leave-page-warning-modal.component.scss'],
})
export class LeavePageWarningModalComponent implements OnInit {

  @ViewChild('modal', { static: true })
  public modal: ModalComponent;

  @Input()
  public setGlobalInstance = false;

  public resolve: (value: boolean) => void;

  constructor(
    private globalModalService: GlobalModalService,
  ) { }

  public ngOnInit(): void {
    if (this.setGlobalInstance) {
      // This is used for global hook implementations.
      // Do not remove.
      this.globalModalService.setDiscardInstance(this);
    }
  }

  public show(): Promise<boolean> {
    this.modal.show();
    return new Promise((resolve) => this.resolve = resolve);
  }

  public confirmActivate(): void {
    this.resolve(true);
    this.modal.hide();
  }

  public clear(): void {
    this.resolve(false);
  }

}
