import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import * as moment from 'moment-timezone';

@Injectable()
export class RequestService {

  constructor() {

  }

  public buildHttpHeaders(type?: string): HttpHeaders {
    const doctype = !!type ? type : 'application/json';
    return new HttpHeaders()
      .set('Content-Type', doctype)
      .set('Accept', doctype)
      .set('User-Timezone', moment.tz.guess());
  }
}
