<div class="quickview-container" [hidden]="renderMenu === false">
  <div class="quickview">
    <div class="quickview-caret"><i class="fa fa-caret-up"></i></div>
    <div class="messages-header d-flex align-items-center">
      <div>{{alerts?.length}} new alerts</div>
      <div class="ml-auto text-bold-500">
        <a href="#" (click)="$event.preventDefault(); dismissAll()">Dismiss All</a> |
        <a href="#" routerLink="/settings-v2/internal/notifications" *ngIf="user.userType === 'INTERNAL'">Settings</a>
        <a href="#" routerLink="/settings-v2/vendor/notifications" *ngIf="user.userType === 'VENDOR'">Settings</a>
        <a href="#" routerLink="/settings-v2/notifications" *ngIf="user.userType === 'COMPANY'">Settings</a>
      </div>
    </div>
    <!-- Do not render the the list when dropdown not shown. -->
    <!-- it will incur major performance loss if there are more than 100+ alerts/messages -->
    <div class="messages-container" *ngIf="renderMenu">
      <div *ngIf="unreadAlertsCount===0" class="p-2 text-center">Currently no new alerts</div>
      <cdk-virtual-scroll-viewport itemSize="100" class="scroll-container">
        <townip-user-card [compactContent]="true" *cdkVirtualFor="let alert of alerts" (click)="navigate(alert)">
          <townip-user-content>
            <div class="d-flex">
              <div class="margin-right-10" [innerHTML]="alert.messageBody"></div>
              <div class="ml-auto">
                <a
                  href="#"
                  (click)="$event.preventDefault(); $event.stopPropagation(); markAsRead(alert)"><i class="fa fa-times"></i></a>
              </div>
            </div>
            <div *ngIf="alert.taskNotes">
              <div *ngFor="let note of alert.taskNotes | keyvalue">
                *{{note.value}}
              </div>
            </div>
            <div class="d-flex align-items-center content-footer">
              <div class="timestamp">{{alert.sentTime|dateTime}}</div>
              <div class="ml-auto scope-name">{{alert.scopeName}}</div>
            </div>
          </townip-user-content>
        </townip-user-card>
      </cdk-virtual-scroll-viewport>
    </div>
  </div>
</div>
