import * as _ from 'underscore';
import { Pipe, PipeTransform } from '@angular/core';
import { isNullOrUndefined } from '../../utils/is-null-or-undefined';

@Pipe({
  name: 'checkListGroupFilter'
})
export class CheckListGroupFilterPipe implements PipeTransform {

  public transform(value: any[], group: string): any {
    if (isNullOrUndefined(group)) {
      return _.filter(value, (item) => {
        return isNullOrUndefined(item.group);
      });
    }

    if (group === '') {
      return _.filter(value, (item) => {
        return item.group === '';
      });
    }

    return _.filter(value, (item) => {
      return item.group === group;
    });
  }
}
