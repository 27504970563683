import { Pipe, PipeTransform } from '@angular/core';
import * as _sortBy from 'lodash/sortBy';

@Pipe({
  name: 'sortBy'
})
export class SortByPipe implements PipeTransform {

  public transform<T>(value: T[], args?: any, asc: boolean = true): T[] {
    if (!value) {
      return [];
    }

    if (!asc) {
      return _sortBy(value, args)
        .reverse();
    }

    return _sortBy(value, args);
  }

}
