import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../shared/services/user.service';
import { PrintPreviewService } from './print-preview.service';
import { FirebaseService } from '../firebase/firebase.service';

@Injectable()
export class PrintSessionGuard {

  constructor(private firebaseService: FirebaseService,
              private router: Router,
              private userService: UserService,
              private printPreviewService: PrintPreviewService) {
  }

  public canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    return new Promise((resolve, reject) => {
      this.handleAuthState(next)
        .then(() => {
          resolve(true);
        })
        .catch(e => {
          this.router.navigate(['error-403']);
          reject();
        });
    });
  }

  private handleAuthState(route: ActivatedRouteSnapshot): Promise<boolean> {

    return new Promise((resolve, reject) => {
      // If existing session
      if (this.userService.getUser()) {
        resolve(true);
        return;
      }
      // If existing session, but page was refreshed
      this.userService.userEmitter.subscribe(() => {
        resolve(true);
      });
    });
  }
}
