import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CalendarTaskRequest } from '../shared/dto/calendar/calendar-task-request';
import { RequestService } from '../shared/services/request-service';
import { CalendarTask } from '../shared/dto/calendar/calendar-task';
import { PrincipalUserLite } from '../shared/dto/principal-user-lite';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CalendarTasksService {

  constructor(private httpClient: HttpClient, private requestService: RequestService) { }

  public get(request: CalendarTaskRequest): Observable<CalendarTask[]> {
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.post<CalendarTask[]>('/api/tasks/calendar', request, { headers });
  }

  public getUsersWithCalendarTasks(): Observable<PrincipalUserLite[]> {
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.get<PrincipalUserLite[]>('/api/tasks/internal/users', { headers });
  }
}
