import { DisplayQuestion } from '../../domain/display-question';

/**
 * Created by aeleneski on 4/26/2017.
 */
export interface WorkflowActionRequest {
  id: number;
  type: string;
  title: string;
  description: string;
  required: boolean;
  usedInDecision: boolean;
  requestResponse: any;
  dependentRequestId: number;
  dependentRequest: WorkflowActionRequest;
  dependentValue: string;
  displayOptions?: DisplayQuestion[];
  defaultResponseValue?: any;
  carryOnValue?: boolean;
  carryOnLocation?: number;
  sequenceNumber?: number;
}

export enum RequestType {
  Boolean = 'boolean',
  Rate = 'rate',
  InlineMessage = 'inlineMessage',
  Note = 'note',
  File = 'file',
  FilingDeliveryContact = 'filingDeliveryContact',
  PassAlongFile = 'passAlongFile',
  AssociateFile = 'associateFile'
}

export enum RequestTitle {
  QaReworkNeeded = 'qaReworkNeeded',
  TranslationReworkNeeded = 'translationReworkNeeded',
  ReworkNotes = 'reworkNotes',
  FilingDocsChoice = 'filingDocsChoice',
  JobComplete = 'jobComplete',
  UploadAndAssociateFile = 'uploadAndAssociateFile',
  ReworkRequestedDeadline = 'reworkRequestedDeadline',
  SignificantIssuesMissed = 'significantIssuesMissed',
  MinorIssuesMissed = 'minorIssuesMissed',
  IssuesIdentifiedNonIssues = 'issuesIdentifiedNonIssues',
  FeedbackMessage = 'feedbackMessage',
  FeedbackFile = 'feedbackFile',
  CompletionFileChoice = 'completionFileChoice',
  InProgress = 'inProgress',
}
