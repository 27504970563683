import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditorV2Component } from './editor-v2.component';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    EditorV2Component
  ],
  exports: [
    EditorV2Component
  ],
  imports: [
    CommonModule,
    EditorModule,
    FormsModule
  ],
  providers: [
    { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' }
  ]
})
export class EditorV2Module { }
