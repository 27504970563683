import { Pipe, PipeTransform } from '@angular/core';

export const FILE_SIZE_TYPE: string[] = ['Byte', 'KB', 'MB', 'GB', 'TB'];

export function getFileSizeString(value: any, params?: any): string {
  if (!value) {
    return 'Unknown';
  }

  let sizeType = 0;

  while (value >= 1024 && sizeType < FILE_SIZE_TYPE.length) {
    value = value / 1024;
    ++sizeType;
  }

  return Math.round(value * 100) / 100 + ' ' + FILE_SIZE_TYPE[sizeType];
}

@Pipe({
  name: 'fileSize'
})
export class FileSizePipe implements PipeTransform {

  public transform(value: number, args?: any): any {

    return getFileSizeString(value, args);
  }

}
