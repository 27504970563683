import { Pipe, PipeTransform } from '@angular/core';
import { PortalUser } from '../domain/user';

/**
 * Usage:
 *
 * let user = {
 *    firstName: 'Uvuvwevwe Onyetenyevwe',
 *    lastName: 'Ugwembubwem Ossas',
 *    ...
 *    ...
 * }
 *
 * {{ user | pluckAndStringify:30 }}
 *
 * Result:
 * Uvuvwevwe Onyetenyevwe U. O.
 *
 */
@Pipe({ name: 'displayShortName' })
export class DisplayShortNamePipe implements PipeTransform {
  public transform(user: PortalUser, maxChar: number): string {
    let result = '';
    const fullName = `${user.firstName} ${user.lastName}`;
    if (fullName.length > maxChar) {
      result = user.firstName;
      const lastNames = user.lastName.split(' ');
      for (const lastName of lastNames) {
        result = result.concat(` ${lastName[0]}.`);
      }
    } else {
      result = fullName;
    }

    return result;
  }
}
