import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'townip-server-banner',
  templateUrl: './server-banner.component.html',
  styleUrls: ['./server-banner.component.scss']
})
export class ServerBannerComponent implements OnInit {

  constructor() { }

  public ngOnInit(): void {
  }

}
