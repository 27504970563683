import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SessionGuardService } from './shared/guards/session-guard.service';
import { ErrorGuardService } from './shared/guards/error-guard.service';
import { Error404Component } from './errors/error-404/error-404.component';
import { RedirectComponent } from './shared/redirect/redirect.component';
import { ProjectDeactivateGuard } from './shared/services/project-deactivate-guard';
import { NoForecasterGuard } from './shared/guards/no-forecaster.guard';
import { StartupPageComponent } from './main/startup-page/startup-page.component';
import { DisallowLegalsightGuard } from './shared/guards/disallow-legalsight.guard';
import { ForecasterGuard } from './shared/guards/forecaster-guard';

export const routes: Routes = [
  // NOTE: Do not redirect to login as default.
  // The user might have an existing session. IF that is the case, we don't need to redirect them to login.
  // Auth service will handle redirection to login if no session exists
  { path: '', component: StartupPageComponent },
  {
    path: 'dashboard',
    loadChildren: () => import('app/dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [DisallowLegalsightGuard, SessionGuardService, ErrorGuardService]
  },
  {
    path: 'estimate',
    loadChildren: () => import('app/estimate/estimate.module').then(m => m.EstimateModule),
    canActivate: [DisallowLegalsightGuard, SessionGuardService, NoForecasterGuard, ErrorGuardService],
    canDeactivate: [ProjectDeactivateGuard]
  },
  {
    path: 'forecast',
    loadChildren: () => import('app/forecast/forecast.module').then(m => m.ForecastModule),
    canActivateChild: [ForecasterGuard],
    canActivate: [DisallowLegalsightGuard, SessionGuardService, ErrorGuardService],
  },
  {
    path: 'billing',
    loadChildren: () => import('app/billing/billing.module').then(m => m.BillingModule),
    canActivate: [DisallowLegalsightGuard, SessionGuardService, NoForecasterGuard, ErrorGuardService]
  },
  {
    path: 'invoices',
    loadChildren: () => import('app/invoices/invoices.module').then(m => m.InvoicesModule),
    canActivate: [DisallowLegalsightGuard, SessionGuardService, NoForecasterGuard, ErrorGuardService]
  },
  {
    path: 'login',
    redirectTo: 'auth/login',
    pathMatch: 'full'
  },
  {
    path: 'auth',
    loadChildren: () => import('app/security/security.module').then(m => m.SecurityModule)
  },
  {
    path: 'messages',
    loadChildren: () => import('app/messages/messages.module').then(m => m.MessagesModule),
    canActivate: [DisallowLegalsightGuard, SessionGuardService, NoForecasterGuard, ErrorGuardService]
  },
  {
    path: 'onboarding',
    loadChildren: () => import('app/onboarding/onboarding.module').then(m => m.OnboardingModule),
    canActivate: [DisallowLegalsightGuard, ErrorGuardService]
  },
  {
    path: 'projects',
    loadChildren: () => import('app/projects/projects.module').then(m => m.ProjectsModule),
    canActivate: [DisallowLegalsightGuard, SessionGuardService, NoForecasterGuard, ErrorGuardService],
    canDeactivate: [ProjectDeactivateGuard]
  },
  {
    path: 'reporting',
    loadChildren: () => import('app/reporting/reporting.module').then(m => m.ReportingModule),
    canActivate: [DisallowLegalsightGuard, SessionGuardService, ErrorGuardService]
  },
  {
    path: 'settings-v2',
    loadChildren: () => import('app/settings-v2/settings.module').then(m => m.SettingsModule),
    canActivate: [DisallowLegalsightGuard, SessionGuardService, ErrorGuardService]
  },
  {
    path: 'tasks',
    loadChildren: () => import('app/tasks/tasks.module').then(m => m.TasksModule),
    canActivate: [DisallowLegalsightGuard, SessionGuardService, NoForecasterGuard, ErrorGuardService]
  },
  {
    path: 'system-configuration',
    loadChildren: () => import('app/system-configuration/system-configuration.module').then(m => m.SystemConfigurationModule),
    canActivate: [DisallowLegalsightGuard, SessionGuardService, NoForecasterGuard, ErrorGuardService]
  },
  {
    path: 'redirect',
    component: RedirectComponent,
    canActivate: [ErrorGuardService]
  },
  {
    path: 'print-preview',
    canActivate: [DisallowLegalsightGuard],
    loadChildren: () => import('app/print-preview/print-preview.module').then(m => m.PrintPreviewModule),
  },
  {
    path: 'calendar',
    loadChildren: () => import('app/calendar/calendar.module').then(m => m.CalendarModule),
    canActivate: [
      DisallowLegalsightGuard,
      SessionGuardService,
      NoForecasterGuard,
      ErrorGuardService,
    ]
  },
  {
    path: 'vendor-select',
    loadChildren: () => import('app/vendor-select/vendor-select.module').then(m => m.VendorSelectModule),
    canActivate: [DisallowLegalsightGuard, SessionGuardService, NoForecasterGuard, ErrorGuardService],
  },
  {
    path: 'patent-portfolio',
    loadChildren: () => import('app/patent-portfolio/patent-portfolio.module').then(m => m.PatentPortfolioModule),
    canActivate: [DisallowLegalsightGuard, SessionGuardService, ErrorGuardService],
  },
  {
    path: 'annuities',
    loadChildren: () => import('app/annuities/annuities.module').then(m => m.AnnuitiesModule),
    canActivate: [DisallowLegalsightGuard, SessionGuardService, ErrorGuardService],
  },
  {
    path: 'bulk-vendor-message',
    loadChildren: () => import('app/bulk-vendor-message/bulk-vendor-message.module').then(m => m.BulkVendorMessageModule),
    canActivate: [DisallowLegalsightGuard, SessionGuardService, ErrorGuardService],
  },
  {
    path: 'style-index',
    loadChildren: () => import('app/style-index/style-index.module').then(m => m.StyleIndexModule),
    canActivate: [
      DisallowLegalsightGuard,
      SessionGuardService,
      NoForecasterGuard,
      ErrorGuardService
    ]
  },
  // Automatically handle page that does not exist
  {
    path: '**', component:
      Error404Component
  }
]
;

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false, onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
