import { EventEmitter, Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { EstimateCountry } from '../shared/dto/order/estimate-country';
import { clone } from '../settings-v2/shared/utils/clone';
import * as _ from 'underscore';
import { Subscription } from 'rxjs';
import { isNullOrUndefined } from '../shared/utils/is-null-or-undefined';

@Injectable()
export class CountryService {

  public countries: EstimateCountry[];
  private selectedCountries: EstimateCountry[];

  public selectedCountriesEmitter = new EventEmitter<EstimateCountry[]>();

  private systemConfigSubscription: Subscription;

  constructor(private configService: ConfigService) {
    this.getAllCountries();
    this.selectedCountries = [];

    if (isNullOrUndefined(this.systemConfigSubscription)) {
      this.systemConfigSubscription = this.configService.systemConfigUpdatedEmitter.subscribe(configUpdate => {
        if (configUpdate === 'COUNTRY') {
          this.getAllCountries();
        }
      });
    }
  }

  public updateSelectedCountries(selected: EstimateCountry[]): void {
    if (!selected || selected.length === 0) {
      if (this.selectedCountries) {
        this.selectedCountries.forEach(c => c.selected = false);
      }
      if (this.countries) {
        this.countries.forEach(c => c.selected = false);
      }
    }
    this.selectedCountries = selected;
    this.emitSelectedCountries();
  }

  private emitSelectedCountries(): void {
    this.selectedCountries.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      } else if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
    this.selectedCountriesEmitter.emit(this.selectedCountries);
  }

  private getAllCountries(): void {
    this.countries = clone(this.configService.countries);
  }

  public getCountryForCountryIsoCode(countryIsoCode: string): EstimateCountry {
    return this.countries.find(c => c.countryCode === countryIsoCode);
  }

  public getEPCountries(): EstimateCountry[] {
    return clone(_.filter(this.configService.countries, (country) => country.countryCode !== 'EP'));
  }
}
