import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MessageService as PrimeMessageService } from 'primeng/api';
import { HttpRequestErrorInterceptorMessages } from './http-request-error-interceptor-messages';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import * as includes from 'lodash/includes';
import { ConnectionDaemonService, ConnectionDaemonStatusCode } from '../services/connection-daemon.service';
import { PB_GRANT_URL } from '../services/websocket.service';
import { isNullOrUndefined } from '../utils/is-null-or-undefined';

@Injectable()
export class HttpRequestErrorInterceptor implements HttpInterceptor {

  constructor(private growlService: PrimeMessageService,
              private interceptorMessages: HttpRequestErrorInterceptorMessages,
              private router: Router,
              private connectionDaemon: ConnectionDaemonService) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Ops before the request made here. Before 'return'.
    return next
      .handle(req)
      .pipe(
        tap((event) => {
          if (event instanceof HttpResponse) {
            // Noop, proceed normally
            // For debugging ONLY, shows growl on successful request.
            // this.growlService.add({
            //   severity: 'info',
            //   summary: 'Request Successful',
            //   detail: event.url
            // })
          }
        }, (error: HttpErrorResponse) => {
          // Note: You can disable this per-url. Use HttpRequestErrorInterceptorMessages.add()

          // If the response was 200 OK, it should be fine in most cases
          if (error.status === 200) {
            return;
          }

          // Errors that come from connection check have its own handler.
          if (error.url === this.connectionDaemon.connectionCheckPath) {
            return;
          }

          // External Urls (different-origin)
          if (this.isExternal(error.url)) {
            this.handleExternalRequestError(error);
          }

          // Internal Urls (same-origin)
          this.handleInternalRequestError(error, req);
        })
      );
  }

  /**
   * For http request internally (same-origin)
   */
  private handleInternalRequestError(error: HttpErrorResponse, req: HttpRequest<any>): void {
    // Check if we have a message override
    const message = this.interceptorMessages.find(error.url);
    if (message || error.url === null) {
      // If we don't bypass
      if (message && message.bypass === false) {
        this.growlService.add({ severity: 'error', summary: message.title, detail: message.body });
      }

      // Remove the API url from the list after bypassing if message is not marked as permanent
      if (!message.permanent) {
        this.interceptorMessages.remove(message);
      }
      return;
    }

    const entity = this.generateEntityName(error);

    // Default action
    if (this.canGrowl(error)) {
      this.growlService.add({
        severity: 'error',
        summary: 'Request Failed',
        detail: `Unable to process ${entity} ` + `(${error.status} ${error.statusText})`
      });
    }

    // If we get an unauthorized, redirect them to unathorized page
    if (error.status ===  403) {
      this.router.navigate(['/error-403'], { skipLocationChange: true });
    }

    if (error.status === 502 || error.status === 503) {
      // PUBNUB grant channel has its own handling and retry. We'll leave it to that.
      if (req.url === PB_GRANT_URL) {
        return;
      }

      const maintUrl = '/error-503-maintenance';
      // Are we already in the maintenance page?
      if (this.router.url === maintUrl) {
        return;
      }

      this.router.navigate([maintUrl]);
    }

    // If we get 404
    // if (error.status ===  404) {
    //   this.router.navigate(['/error-404']);
    // }
  }

  /**
   * For http request externally (different origin)
   */
  private handleExternalRequestError(error: HttpErrorResponse): void {
    // Do not show the errors if we are offline - See 5114 for more info
    if (this.connectionDaemon.status.getValue() === ConnectionDaemonStatusCode.Offline) {
      return;
    }

    // Default action
    this.growlService.add({
      severity: 'error',
      summary: 'Request Failed',
      detail: 'Unable to process external request ' + `(${error.status} ${error.statusText})`
    });
  }

  /**
   * Generates an entity name based on the path.
   * Ex if path is /api/more/task - entity name will be task
   *
   * @param {string} path
   * @returns {string}
   */
  private generateEntityName(error: HttpErrorResponse): string {
    // Wrapping in try-catch to make sure our error handler does end up in an error as well.
    try {
      const entityPath: string[] = error.url.split('/');
      let entity = entityPath[entityPath.length - 1];

      // For paths with trailing slashes (ex /api/test/)
      if (entityPath[entityPath.length - 1] === '') {
        entity = entityPath[entityPath.length - 2];
      }

      return entity;
    } catch (e) {
      return 'Request';
    }
  }

  private isExternal(url: string): boolean {
    if (isNullOrUndefined(url)) {
      return false;
    }

    // IE 11 did not include the window location origin in the error url
    // Prevent external error when refreshing the auth token in IE 11
    if (url.startsWith('/')) {
      return false;
    }

    if (url.startsWith(window.location.origin)) {
      return false;
    } else {
      return true;
    }
  }

  private canGrowl(error: HttpErrorResponse): boolean {
    // Do not show the errors if we are offline - See 5114 for more info
    if (this.connectionDaemon.status.getValue() === ConnectionDaemonStatusCode.Offline) {
      return false;
    }

    const noGrowlStatus = [401]; // Specify error codes that will not growl.
    return includes(noGrowlStatus, error.status) === false;
  }
}
