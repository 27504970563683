import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

export enum LogType {
  Info,
  Warning,
  Error
}

export interface LogData {
  type?: LogType;
  message?: string;
  data?: any;
}

@Injectable({
  providedIn: 'root'
})
export class LoggingV2Service {

  private info: LogData[] = [];

  private warn: LogData[] = [];

  private error: LogData[] = [];

  public getAll(): { info: LogData[], warn: LogData[], error: LogData[] } {
    return {
      info: [],
      warn: [],
      error: [],
    };
  }

  public getAllMerged(): LogData[] {
    return [];
  }

  public logError(message: string, data: any): void {
    this.error.push({ message, data, type: LogType.Error });
    const timestamp = new Date().valueOf(); // Probably change the formatting later on.
    if (!environment.production) {
      if (data) {
        // eslint-disable-next-line no-console
        console.log(`[${timestamp}] ${message}`, data);
      } else {
        // eslint-disable-next-line no-console
        console.log(`[${timestamp}] ${message}`);
      }
    }
  }

  public logWarning(message: string, data: any): void {
    this.warn.push({ message, data, type: LogType.Warning });
    const timestamp = new Date().valueOf(); // Probably change the formatting later on.
    if (!environment.production) {
      if (data) {
        console.warn(`[${timestamp}] ${message}`, data);
      } else {
        console.warn(`[${timestamp}] ${message}`);
      }
    }
  }

  public logInfo(message: string, data: any): void {
    this.info.push({ message, data, type: LogType.Info });
    const timestamp = new Date().valueOf(); // Probably change the formatting later on.
    if (!environment.production) {
      if (data) {
        // eslint-disable-next-line no-console
        console.log(`[${timestamp}] ${message}`, data);
      } else {
        // eslint-disable-next-line no-console
        console.log(`[${timestamp}] ${message}`);
      }
    }
  }

}
