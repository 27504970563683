import { FieldType } from '../../data-filter/field-type';
import { Filter } from '../../data-filter/filter';
import { FilterOperator } from '../../data-filter/filter-operators';
import { OPERATORS } from '../../data-filter/filter-operators';

enum InputType {
  Text = 1,
  Number = 2,
  Selection = 3,
  Date = 4,
  Autocomplete = 5,
  Multiselect = 6,
  Range = 7,
}

interface FilterValueMap {
  value?: string; // This will be the value used when matching a filter
  label?: string;
  isCollection?: boolean;
}

interface FilterFormOptions {
  name: string;
  field: string;
  fieldType: FieldType;
  inputType?: InputType;
  collectionField?: string; // If the main field is a collection array, which field/property to search
  values?: any[]; // Values available in auto complete or dropdown,
  valueMapping?: FilterValueMap; // Used for complex selection that has different labels and values
  operators?: FilterOperator[]; // Override the operators selection
}

export { InputType, FieldType, Filter, FilterOperator, FilterFormOptions, FilterValueMap, OPERATORS };
