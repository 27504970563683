import * as _ from 'underscore';
import { Component, OnInit, ViewChild } from '@angular/core';
import { SearchService } from '../../../data-services/search.service';
import { SearchResult } from '../../response/search-result';
import { Params, Router } from '@angular/router';
import { OverlayPanel } from 'primeng/overlaypanel';
import natsort from 'natsort';
import { EstimatesOverviewService } from '../../../estimate/estimates-overview/estimates-overview.service';
import { AnnuityPatentService } from '../../../data-services/annuity-patent.service';
import { isNullOrUndefined } from '../../utils/is-null-or-undefined';
import { UserService } from '../../services/user.service';

interface ScopeTypeDisplay {
  label: string;
  scopeType: string;
  showMore: boolean;
}

@Component({
  selector: 'townip-search-system',
  templateUrl: './search-system.component.html',
  styleUrls: ['./search-system.component.scss']
})
export class SearchSystemComponent implements OnInit {

  @ViewChild('searchOverlay')
  public searchOverlay: OverlayPanel;

  public searchValue: string;

  public loaded = false;

  public searchResults: SearchResult[];

  public scopeTypeDisplays: ScopeTypeDisplay[];

  public displaySearchValue: string;

  public keyFilterRegEx = new RegExp('[a-zA-Z0-9/.,\\s\\-\\&]+');

  constructor(private searchService: SearchService,
              private estimatesOverviewService: EstimatesOverviewService,
              private patentService: AnnuityPatentService,
              private router: Router,
              private userService: UserService,) {
  }

  public ngOnInit(): void {
    this.loaded = false;
    this.searchValue = '';
  }

  public searchSystem(event: any): void {
    if (isNullOrUndefined(this.searchValue) || this.searchValue.length === 0) {
      return;
    }

    this.searchOverlay.show(event);

    this.loaded = false;

    this.searchService.searchSystem(this.searchValue)
      .toPromise()
      .then(result => {
        this.searchResults = result;

        // Sort results by name
        const sorter = natsort({ insensitive: true, desc: true });
        this.searchResults.sort((a, b) => sorter(a.name, b.name));

        this.buildScopeTypeDisplay();
        this.displaySearchValue = this.searchValue;
        this.loaded = true;
      });
  }

  public buildScopeTypeDisplay(): void {
    this.scopeTypeDisplays = _.map(
      _.uniq(this.searchResults, res => {
        return res.scopeType;
      }),
      res => {
        if (res.scopeType === 'PROJECT') {
          return { label: 'PROJECT NUMBER', scopeType: res.scopeType, showMore: false };
        }
        if (res.scopeType === 'ESTIMATE') {
          return { label: 'ESTIMATE NUMBER', scopeType: res.scopeType, showMore: false };
        }
        if (res.scopeType === 'ANNUITY') {
          return { label: 'RENEWAL SERVICE PAYMENT ID', scopeType: res.scopeType, showMore: false };
        }
        if (res.scopeType === 'VENDOR') {
          return { label: 'VENDORS COMPANY NAME', scopeType: res.scopeType, showMore: false };
        }
        if (res.scopeType === 'COMPANY') {
          return { label: 'CLIENT COMPANY NAME', scopeType: res.scopeType, showMore: false };
        }
        if (res.scopeType === 'REFERENCE_NUMBER') {
          return { label: 'REFERENCE NUMBER', scopeType: res.scopeType, showMore: false };
        }
        if (res.scopeType === 'COUNTRY_REFERENCE_NUMBER') {
          return { label: 'COUNTRY REFERENCE NUMBER', scopeType: res.scopeType, showMore: false };
        }
        if (res.scopeType === 'VENDOR_REFERENCE_NUMBER') {
          return { label: 'VENDOR REFERENCE NUMBER', scopeType: res.scopeType, showMore: false };
        }
        if (res.scopeType === 'ORDER_END_CLIENT_REFERENCE_NUMBER') {
          return { label: 'ORDER END CLIENT REFERENCE NUMBER', scopeType: res.scopeType, showMore: false };
        }
        if (res.scopeType === 'PATENT_NUMBER') {
          return { label: 'IP IDENTIFIER', scopeType: res.scopeType, showMore: false };
        }
        if (res.scopeType === 'ANNUITY_BILL') {
          return { label: 'BILL ID', scopeType: res.scopeType, showMore: false };
        }
        if (res.scopeType === 'ANNUITY_INVOICE') {
          return { label: 'INVOICE NUMBER', scopeType: res.scopeType, showMore: false };
        }
      });

    const sortOrder = ['PROJECT', 'ESTIMATE', 'ANNUITY', 'PATENT_NUMBER', 'VENDOR', 'COMPANY', 'REFERENCE_NUMBER',
      'COUNTRY_REFERENCE_NUMBER', 'VENDOR_REFERENCE_NUMBER', 'ORDER_END_CLIENT_REFERENCE_NUMBER', 'ANNUITY_BILL',
      'ANNUITY_INVOICE'];

    this.scopeTypeDisplays.sort((scopeTypeA, scopeTypeB) => {
      return sortOrder.indexOf(scopeTypeA.scopeType)
        - sortOrder.indexOf(scopeTypeB.scopeType);
    });
  }

  public async routeToResult(result: SearchResult): Promise<void> {
    if (result.scopeType === 'ESTIMATE' || result.parentScopeType === 'ESTIMATE') {
      const url = this.estimatesOverviewService.gotoEstimate(result, false, true);
      this.navigateToRedirect(url);
      return;
    }
    if (result.scopeType === 'PROJECT' || result.parentScopeType === 'PROJECT') {
      this.navigateToRedirect('projects/' + result.id);
      return;
    }
    if (result.scopeType === 'VENDOR') {
      this.navigateToRedirect('settings-v2/internal-management/vendor/' + result.id + '/vendor-profile');
      return;
    }
    if (result.scopeType === 'COMPANY') {
      this.navigateToRedirect('settings-v2/internal-management/company/' + result.id + '/company-profile');
      return;
    }
    if (result.scopeType === 'ANNUITY' || result.parentScopeType === 'ANNUITY') {
      const params: Params = { scope: 'ANNUITY', scopeId: result.id };
      this.router.navigate(['/redirect'], { queryParams: params });
      return;
    }
    if (result.scopeType === 'ANNUITY_PATENT' || result.parentScopeType === 'ANNUITY_PATENT') {
      const patentDetails = await this.patentService
        .get(result.id)
        .toPromise();

      const params: Params = {
        scope: 'ANNUITY_PATENT',
        scopeId: patentDetails.id,
        parentScopeId: patentDetails.associatedCompany.id
      };

      this.router.navigate(['/redirect'], { queryParams: params });
      return;
    }
    if (result.scopeType === 'ANNUITY_BILL') {
      this.navigateToRedirect('annuities/internal/view-bill/' + result.id);
      return;
    }
    if (result.scopeType === 'ANNUITY_INVOICE') {
      if (this.userService.isPM()) {
        this.navigateToRedirect('annuities/internal/invoice/view/' + result.id);
        return;
      } else if (this.userService.isSalesOrAccountManager()) {
        this.navigateToRedirect('annuities/sm-am/invoice/' + result.id);
        return;
      }
    }
  }

  private navigateToRedirect(route: string, params?: string): void {
    this.router.navigate(['/redirect'], { queryParams: { route, query: params } });
  }

  public clearInput(): void {
    this.searchOverlay.hide();
    this.loaded = false;
    this.searchValue = '';
  }
}
