import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Injectable()
export class PageTitleService {

  private pageTitle: string;

  constructor(private titleService: Title) {
    this.pageTitle = titleService.getTitle();
  }

  public setTitle(newTitle: string): void {
    this.titleService.setTitle(newTitle + ' | ' + this.pageTitle);
  }

  public resetTitle(): void {
    this.titleService.setTitle(this.pageTitle);
  }

}
