import { EventEmitter, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../shared/services/user.service';
import { LoggingService } from '../shared/logging.service';
import { MessageService } from 'primeng/api';
import firebase from 'firebase/app';
import { environment } from '../../environments/environment';
import { GeneralResponseMessage } from '../shared/dto/general-response-message';
import { AppContextService } from '../shared/services/app-context.service';
import { HttpErrorResponse } from '@angular/common/http';
import { UserDisabledStatus } from './user-disabled-status';
import { FirebaseService } from '../firebase/firebase.service';
import { from, Observable, Subscription, throwError as observableThrowError } from 'rxjs';
import { tap } from 'rxjs/operators';
import { PatentPortfolioService } from '../patent-portfolio/patent-portfolio.service';
import { ManagedAnnuityService } from '../annuities/managed-annuity.service';
import { HttpCacheService } from '../shared/services/http-cache.service';
import { isNullOrUndefined } from '../shared/utils/is-null-or-undefined';

@Injectable()
export class AuthService {
  private userSub: Subscription;
  private token: any;
  private tempPassword: string;
  private connected: boolean;
  private oobCode: string;

  // This variable handles the logout process of other tabs that are open and still be
  // compatible with minimal changes to legacy session handling
  private logoutSequenceStarted = false;

  private userInactiveStatus: UserDisabledStatus;

  public connectedEmitter: EventEmitter<boolean>;
  public tokenEmitter: EventEmitter<string>;

  constructor(private firebaseService: FirebaseService,
              private loggingService: LoggingService,
              private userService: UserService,
              private growlService: MessageService,
              private appContext: AppContextService,
              private router: Router,
              private route: ActivatedRoute,
              private httpCacheService: HttpCacheService,
  ) {
    this.connectedEmitter = new EventEmitter<boolean>();

    this.tokenEmitter = new EventEmitter<string>();

    // We don't authenticate automatically when signing in with a token.
    // This will be called after the token login.
    if (this.signingInWithToken()) {
      // Do not auth automatically, but render the body
      // this.authenticate()
      this.renderBody();
    } else {
      // Not using token, auth automatically
      // Body will be rendered once we figure out if the user has session or not
      this.authenticate();
    }
  }

  public isConnected(): boolean {
    return this.connected;
  }

  public setOobCode(oobCode: string): void {
    this.oobCode = oobCode;
  }

  public authenticate(): void {
    // Subscribe to Session change event. NOT token change. Token can change and we could still have the same session
    this.firebaseService.auth.onAuthStateChanged(user => {
      if (user) {
        this.logoutSequenceStarted = false;
        user.getIdToken()
          .then(token => {
            this.setToken(token);
            this.userService
              .retrieveUser()
              .then((portalUser) => {
                this.connected = true;
                this.connectedEmitter.emit(true);

                // Check if we are in the root page or we are in an account portal instance, do not do any redirection
                // If we are in the root page, redirect them to dashboard.
                // Otherwise do not redirect them so that they stay on the page.
                if (this.isInRoot() && !this.appContext.isAccountPortal()) {
                  this.router.navigate(['/dashboard/landing']);
                }
                // Make sure to render the body or else you won't be able to see anything.
                this.renderBody();
              })
              .catch((error) => {
                // Session was created in firebase, but we couldn't get the user from the system api
                this.handleApiUserError(error);
                this.renderBody();
              });
          });
      } else {
        // NO SESSION ENTIRELY
        // This block triggers when:
        // User logs out
        // User first visits or navigates with no session
        this.setIsConnected(false);


        // We have to call this so that the other tabs(when you have multiple open) will also clear data
        // The logout sequence will only be true if the user deliberately logged out from this window.
        if (!this.logoutSequenceStarted) {
          this.logout();
        }

        // Bug 2321 - Redirect inactive users to 403 error
        // We check it in this block because we are only able to figure out the user's disabled status after
        // a successful login to firebase and after retrieving the user information.
        if (this.userInactiveStatus) {
          this.router.navigate(['/error-403'], { queryParams: { disabled: this.userInactiveStatus } })
            .then(() => {
              // Clear the status
              this.userInactiveStatus = undefined;
              this.renderBody();
            });

          // Exit early if we have a pending active status. Do not continue below
          return;
        }

        // Are we in auth pages?
        // If we are in auth pages, do not redirect and just render. (in case we are using ooB/reset/)
        if (this.isInAuth() || this.isInErrorPages()) {
          this.renderBody();
        } else {
          let returnUrl;
          let queryParams = {};

          if (this.router.url.startsWith('/redirect')) {
            returnUrl = JSON.stringify(this.route.snapshot.queryParams);
            queryParams = { returnUrl };
          }

          // If we are NOT inside the auth set of pages, redirect them to login as they have NO SESSION
          this.router.navigate(['/auth/login'], { queryParams })
            .then(() => {
              this.renderBody();
            });
        }

        // Reset the app context
        this.appContext.resetContext();
      }
    });

    // Update token whenever it changes.
    this.firebaseService.auth.onIdTokenChanged((user) => {
      if (user) {
        user
          .getIdToken()
          .then((token) => {
            const time = new Date().toString();
            this.loggingService.info(`Token updated at ${time}`);
            this.setToken(token);
          });
      }
    });
  }

  public getIdToken(): string {
    return this.token;
  }

  public setIsConnected(connected: boolean): void {
    this.connected = connected;
  }

  public getAuthorizationHeader(): string {
    return isNullOrUndefined(this.token) ? null : 'Bearer ' + this.token;
  }

  /**
   * This is usually used for testing the auth headers
   * Do not remove.
   * @param token
   */
  public setAuthorizationHeader(token: string): void {
    this.token = token;
  }

  public refreshToken(): Observable<string> {
    if (isNullOrUndefined(this.firebaseService.auth.currentUser)) {
      return observableThrowError('Could not refresh token - No active session yet');
    }

    // Purge all cache when refreshing token.
    // This makes sure all requests after a token refresh will not be cached.
    this.httpCacheService.purge();

    return from(this.firebaseService.auth.currentUser.getIdToken(true))
      .pipe(
        tap(token => {
          // Debug stuff here -
          this.token = token;
        })
      );
  }

  public login(email: string, password: string, isInvite?: boolean): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.firebaseService.auth.signInWithEmailAndPassword(email, password)
        .then(value => {
          value.user.getIdToken()
            .then(token => {
              this.setToken(token);
              if (!!this.userSub) {
                this.userSub.unsubscribe();
              }
              // NOTE: Do not do any redirect to dashboard from here.
              // Any routing to dashboard is handled in authenticate()
              if (isInvite) {
                this.tempPassword = password;
                this.userService.retrieveUser();
                this.router.navigate(['/auth/password-update']);
              }
              resolve(true);
            });
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  public loginWithToken(customToken: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.firebaseService.auth.signInWithCustomToken(customToken)
        .then(value => value.user.getIdToken())
        .then((userToken) => {
          this.setToken(userToken);
          if (this.userSub) {
            this.userSub.unsubscribe();
          }
          resolve(null);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public updatePasswordOnly(password: string): Observable<void> {
    const user = this.firebaseService.auth.currentUser;
    return from(user.updatePassword(password))
      .pipe(
        tap(token => {
          // Debug here
          // this.growlService.add({ severity: 'success', summary: 'Successfully reset password.', detail: ' ' });
        })
      );
  }

  public updatePassword(currentPassword: string, password: string): Promise<boolean> {
    const user = this.firebaseService.auth.currentUser;

    return new Promise((resolve) => {
      this.firebaseService.auth.currentUser
        .reauthenticateWithCredential(firebase.auth.EmailAuthProvider.credential(user.email, currentPassword))
        .then(response => {
          user.updatePassword(password)
            .then(() => {
              this.growlService.add({ severity: 'success', summary: 'Successfully reset password.', detail: ' ' });
              resolve(true);
            })
            .catch(error => {
              this.loggingService.info(error);
              this.growlService.add({
                severity: 'error',
                summary: `Failed to reset your password. ${error.message}`,
                detail: ' '
              });
              resolve(false);
            });
        })
        .catch(currentPasswordError => {
          this.loggingService.info(currentPasswordError);
          this.growlService.add({
            severity: 'error',
            summary: `Your current password is incorrect. ${currentPasswordError.message}`,
            detail: ' '
          });
          resolve(false);
        });
    });
  }

  public updatePasswordReset(email: string, password: string): Promise<string> {

    return new Promise((resolve, reject) => {
      setTimeout(() => {
        this.loggingService.info(' Trying to reset ', this.oobCode, password);
        this.firebaseService.auth.confirmPasswordReset(this.oobCode, password)
          .then(confirmResponse => {
            this.userService.isEmailForecastOnly(email)
              .then((isForecastResponse: GeneralResponseMessage) => {
                if (isForecastResponse.response === 'true') {
                  resolve(environment.forecasterUrl);
                } else {
                  resolve(environment.mainSystemUrl);
                }
              })
              .catch(retrieveUserError => {
                this.loggingService.info('failed to retrieve user info!', retrieveUserError);
                reject(retrieveUserError);
              });
          })
          .catch(confirmError => {
            this.loggingService.info('failed to confirm!', confirmError);
            reject(confirmError);
          });
      });
    });
  }

  /**
   * verify the oob code from firebase.
   * @returns {Promise<string>} email
   */
  public verifyResetCode(): Promise<string> {
    return new Promise((resolve, reject) => {
      this.firebaseService.auth.verifyPasswordResetCode(this.oobCode)
        .then(email => {
          this.loggingService.info('verified password reset code', email);
          resolve(email);
        })
        .catch(error => {
          this.loggingService.info('Failed to verify reset code', this.oobCode);
          reject(error);
        });
    });
  }

  public getTempPassword(): string {
    return this.tempPassword;
  }

  public logout(emitEvents: boolean = true): Promise<void> {
    this.logoutSequenceStarted = true;
    return new Promise((resolve, reject) => {
      let userId: number = null;

      if (this.userService.getUser()) {
        userId = this.userService.getUser().userId;
      }

      this.firebaseService.auth.signOut()
        .then(() => {
          this.tempPassword = null;
          this.connected = false;
          this.userService.setUser(null);
          window.localStorage.removeItem('color_cache');
          window.localStorage.removeItem('townip_display_payment_instruction');
          window.localStorage.removeItem('townip_display_vendor_manager_welcome_message');
          window.localStorage.removeItem('townip_display_vendor_user_welcome_message');

          if (userId) {
            window.localStorage.removeItem('townip_mfa_' + userId);
          }

          // patent portfolio
          window.localStorage.removeItem(PatentPortfolioService.orgIdKey);
          window.localStorage.removeItem(PatentPortfolioService.orgNameKey);
          // annuities
          window.localStorage.removeItem(ManagedAnnuityService.orgIdKey);
          window.localStorage.removeItem(ManagedAnnuityService.orgNameKey);

          if (emitEvents) {
            this.connectedEmitter.emit(false);
          }
          resolve();
        })
        .catch(e => reject(e));
    });
  }

  public signingInWithToken(): boolean {
    // Checks if we are signing into the app with a custom sign in token and `verify` token from PDF server.
    // When don't have access to the angular router at this level yet, so we check it using the global url
    return RegExp('cst=')
      .test(window.location.href) || RegExp('verify=')
      .test(window.location.href);
  }

  public poisonToken(): void {
    this.token = 'invalid'; // This could really be anything.
  }

  /**
   * Checks whether we are in the root page or not.
   * We use this to check if we need to redirect to our default landing pages.
   */
  private isInRoot(): boolean {
    // We won't have access to the actual route from here, so we use the global one.
    // We check by getting the total number of / in the url
    // By default they should split into 4 parts if in root pages
    const current = window.location.href;
    const paths = current.split('/');

    // If the url ends with /, we need to to remove it or the count will be invalid.
    if (current.endsWith('/')) {
      paths.pop();
    }

    return paths.length <= 3;
  }

  /**
   * Checks if we are in the auth pages already
   * Uses the global location because we need the full route regardless of router status
   */
  private isInAuth(): boolean {
    return RegExp('/auth/')
      .test(window.location.href);
  }

  private isInErrorPages(): boolean {
    return RegExp('/error-')
      .test(window.location.href);
  }

  private renderBody(): void {
    document.body.style.opacity = '1';
  }

  private handleApiUserError(error: HttpErrorResponse): void {
    // Client Errors
    if (error.status >= 400 && error.status < 500) {
      // wrap in try catch just to make sure this never errors out. (In'case api changes the statuses)
      try {
        switch (error.error.message) {
        case 'Company User is OFFLINE.':
          this.userInactiveStatus = UserDisabledStatus.CompanyOffline;
          break;
        case 'User has been deactivated.':
          this.userInactiveStatus = UserDisabledStatus.UserDisabled;
          break;
        case 'Company has been deactivated.':
          this.userInactiveStatus = UserDisabledStatus.CompanyDisabled;
          break;
        case 'Vendor is suspended.':
          this.userInactiveStatus = UserDisabledStatus.CompanyDisabled;
          break;
        default:
          // Depending on the backend, this could result into a firebase error (backend unable to verify to firebase)
          this.userInactiveStatus = UserDisabledStatus.Other;

        }
      } catch (e) {
        this.userInactiveStatus = undefined;
      }

      // This block is a permission error or data not existing yet on our system.
      // We have to log them out or risk a redirect loop
      this.logout();
      return;
    }

    // Server errors (system might be offline, rebooting, rebuilding etc)
    // We couldn't get the user, redirect immediately, but do not logout. (so they can retry)
    if (error.status >= 500) {
      this.router.navigate(['/error-503-maintenance']);
      return;
    }
  }

  private setToken(newToken: string): void {
    if (isNullOrUndefined(this.token) || this.token !== newToken) {
      this.token = newToken;
      this.tokenEmitter.emit(this.token);
    }
  }
}
