import { TaskNavigationService } from '../../../../tasks/services/task-navigation.service';
import { NavService } from '../../../../main/navigation/nav.service';
import { Router } from '@angular/router';
import { UserService } from '../../../services/user.service';
import { Message } from '../../../dto/messaging/message';
import { PortalUser } from '../../../domain/user';

export class ScopeNavigation {

  /**
   * This will identify if the scope is good to navigate
   * @param message
   */
  public static canNavigateToScope(message: Message): boolean {
    if (message.hideScope) {
      return false;
    }

    // Return false if the estimate scope does not have project category
    if (message.scope === 'ESTIMATE' && !message.projectCategory) {
      return false;
    }

    return true;
  }

  constructor(private taskNavService: TaskNavigationService,
              private userService: UserService,
              private navService: NavService,
              private router: Router)   {
  }

  public navigateToScope(message: Message): void {
    const user = this.userService.getUser();

    if (message.hideScope) {
      return;
    }
    if (message.scope === 'TASK') {
      this.taskNavService.navigateToTaskByIds(message.scopeId, message.parentScopeId, message.projectCategory);
    }
    if (message.scope === 'PROJECT') {
      this.processProjectNavigation(user, message);
    }

    if (message.scope === 'ESTIMATE') {
      this.navService.navigateToEstimateMessage(message);
    }

    // Invoices
    if (message.scope === 'INVOICE') {
      this.processInvoiceNavigation(user, message);
    }
  }

  private processProjectNavigation(user: PortalUser, message: Message): void {
    // Used the scopeName to identify if the scope is billing
    // since we don't have any other identifier if the scope is billing
    // Ex: "19-1002-BILL-141-1"
    const isBilling = message.scopeName
      .toLowerCase()
      .includes('bill');

    if (user.userType === 'INTERNAL') {
      if (isBilling) {
        this.router.navigate(['projects', message.scopeId, 'finances', 'vendor-bills']);
        return;
      }

      this.navService.goToProject(user, message.scopeId);
    } else if (user.userType === 'VENDOR' && isBilling) {
      // We will only navigate it to on vendor's billing page
      // We cannot open the modal of the billing on vendor billing page since the message scopeId is for project
      this.router.navigate(['billing']);
    } else {
      this.navService.goToProject(user, message.scopeId);
    }
  }

  private processInvoiceNavigation(user: PortalUser, message: Message): void {
    if (user.userType === 'COMPANY') {
      // client needs to route to project invoices page
      this.router.navigate(['invoices', message.scopeId]);
    } else if (user.userType === 'INTERNAL' &&
      !this.userService.authorizations.ROLE_INTERNAL_SALES_MGR &&
      !this.userService.authorizations.ROLE_INTERNAL_ACCOUNT_MGR) {
      // current user is an internal PM or InternalAdmin -- reroute to invoice s
      // sales managers cannot view projects, only estimates
      this.router.navigate(['projects', message.parentScopeId, 'finances', 'invoices', message.scopeId]);
    }
  }
}
