import { Pipe, PipeTransform } from '@angular/core';
import * as get from 'lodash/get';
import { sortBy } from '../utils/sort-by';

/**
 * If path is defined, it will get using the path via lodash's GET.
 * If path is not defined, it will try to look for args for a function
 * If both is not defined, will return a blank array
 * @param value
 * @param path The object path (Object.property.property)
 * @param asc If ascending or descending
 */
export function sortByString<T>(value: T[], path: string = null, asc: boolean = true): T[] {
  const sorted = sortBy(value, (item) => {
    try {
      // If no path - we'll assume it is a plain string array
      if (!path) {
        return item.toUpperCase();
      }

      return get(item, path)
        .toUpperCase();
    } catch (e) {
      console.warn('Target value is not a string');
      return '';
    }
  });

  if (!asc) {
    return sorted.reverse();
  }

  return sorted;
}

@Pipe({
  name: 'sortByString'
})
export class SortByStringPipe implements PipeTransform {

  public transform<T>(value: T[], path: string = null, asc: boolean = true): T[] {
    return sortByString(value, path, asc);
  }

}
