import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverviewAssigneeDisplayComponent } from './overview-assignee-display.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [OverviewAssigneeDisplayComponent],
  exports: [OverviewAssigneeDisplayComponent],
})
export class OverviewAssigneeDisplayModule { }
