import { Injectable } from '@angular/core';
import { Task } from '../../shared/dto/projects/task';
import { Router } from '@angular/router';
import { UserService } from '../../shared/services/user.service';
import { MessageProjectCategory } from '../../shared/dto/messaging/message-project-category';
import { AlertMessage, ScopeRouteType } from '../../shared/dto/messaging/alert-message';

@Injectable()
export class TaskNavigationService {
  private currentTaskId: number;

  constructor(private router: Router,
              private userService: UserService) {
  }

  public navigateToTask(task: Task, returnUrlString: boolean = false): string {
    return this.navigateToTaskByIds(
      task.taskAssignmentId,
      task.projectOverview.projectId,
      task.projectOverview.projectCategory.projectCategory,
      returnUrlString);
  }

  public navigateToTaskByIds(taskId: number,
    projectId: number,
    projectCategory: string = MessageProjectCategory.PatentProsecution,
    returnUrlString: boolean = false,
    alertMessage?: AlertMessage): string {
    // Routing logic when provided a scopeRouteType
    if (alertMessage && alertMessage.scopeRouteType) {
      this.navigateByScopeRouteType(alertMessage);
      return;
    }

    const user = this.userService.getUser();
    // DEPRECATED
    // We now handling default project landing in project-landing.component.ts

    let url = '';

    if (user.userType === 'INTERNAL' && projectCategory === MessageProjectCategory.PatentProsecution) {
      // This one is only for the special case of the PM for patent prosecution (ex: from notification)
      url = `projects/${projectId}/workspace/task-view`;
    } else if (user.userType === 'INTERNAL' && projectCategory === MessageProjectCategory.LegalTranslation) {
      // Special case also for general translation project
      url = `projects/${projectId}/general-translation-workspace/tasks`;
    } else {
      // Let the landing page handle the redirects
      url = `projects/${projectId}`;
    }

    if (!returnUrlString) {
      this.router.navigate([url]);
      return;
    }

    return url;
  }

  public getTaskId(): number {
    return this.currentTaskId;
  }

  public setTaskId(taskId: number): void {
    this.currentTaskId = taskId;
  }

  public clear(): void {
    this.currentTaskId = null;
  }

  private navigateByScopeRouteType(alert: AlertMessage): void {
    if (!alert.scopeRouteType) {
      this.router.navigate(['projects', alert.parentScopeId]);
      return;
    }

    if (alert.scopeRouteType === ScopeRouteType.CompleteOrder) {
      this.router.navigate(['projects', alert.parentScopeId, 'complete-project', alert.scopeId]);
      return;
    }

    if (alert.scopeRouteType === ScopeRouteType.FilingDelivery) {
      this.router.navigate(
        ['projects', alert.parentScopeId, 'filing-delivery'],
        { queryParams: { taskId: alert.scopeId } });
      return;
    }

    if (alert.scopeRouteType === ScopeRouteType.TranslationDelivery && alert.projectCategory === 'PATENT_PROSECUTION') {
      this.router.navigate(
        ['projects', alert.parentScopeId, 'translation-delivery'],
        { queryParams: { taskId: alert.scopeId } });
      return;
    }

    if (alert.scopeRouteType === ScopeRouteType.TranslationDelivery && alert.projectCategory === 'LEGAL_TRANSLATION') {
      this.router.navigate(['projects', alert.parentScopeId, 'general-translation-workspace', 'tasks']);
      return;
    }
  }
}
