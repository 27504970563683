import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SessionGuardService } from '../shared/guards/session-guard.service';
import { DebugComponent } from './debug.component';

const routes: Routes = [
  {
    path: 'debug', component: DebugComponent,
    canActivate: [SessionGuardService],
    children: [],
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class DebugRoutingModule { }
