import { EventEmitter, Injectable } from '@angular/core';
import { Company } from '../../shared/dto/company';
import { Vendor } from '../../shared/dto/vendor-management/vendor';
import { CompanyUser } from '../../shared/dto/customer-management/company-user';
import { PortalUser } from '../../shared/domain/user';
import { CompanyGroupLite } from '../../shared/dto/company-group-lite';
import { VendorUser } from '../../shared/dto/vendor-management/vendor-user';

@Injectable()
export class OrganizationManagementService {
  private company: Company;
  private team: CompanyGroupLite;
  private vendor: Vendor;
  private companyUser: CompanyUser;
  private vendorUser: VendorUser;
  private portalUser: PortalUser;
  private pricingScope: string;
  public companyUserEmitter: EventEmitter<CompanyUser>;

  constructor() {
    this.companyUserEmitter = new EventEmitter();
  }

  /**
   * This method is called to clear all data when components are destroyed.
   */
  public clear(): void {
    this.company = null;
    this.vendor = null;
    this.companyUser = null;
    this.portalUser = null;
  }

  public clearUser(): void {
    this.companyUser = null;
  }

  public clearVendorUser(): void {
    this.vendorUser = null;
  }

  public clearCompany(): void {
    this.company = null;
  }

  public setPricingScope(scope: string): void {
    this.pricingScope = scope;
  }

  public getPricingScope(): string {
    return this.pricingScope;
  }

  public setCompany(managedCompany: Company): void {
    this.company = managedCompany;
    this.vendor = null;
  }

  public getCompany(): Company {
    return this.company;
  }

  public setTeam(managedTeam: CompanyGroupLite): void {
    this.team = managedTeam;
  }

  public getTeam(): CompanyGroupLite {
    return this.team;
  }

  public setVendor(managedVendor: Vendor): void {
    this.vendor = managedVendor;
    this.company = null;
  }

  public getVendor(): Vendor {
    return this.vendor;
  }

  public setCompanyUser(managedCompanyUser: CompanyUser): void {
    this.companyUser = managedCompanyUser;
    this.vendor = null;
    this.companyUserEmitter.emit(this.companyUser);
  }

  public getCompanyUser(): CompanyUser {
    return this.companyUser;
  }

  public setVendorUser(managedVendorUser: VendorUser): void {
    this.vendorUser = managedVendorUser;
  }

  public getVendorUser(): VendorUser {
    return this.vendorUser;
  }

  public setPortalUser(managedPortalUser: PortalUser): void {
    this.portalUser = managedPortalUser;
  }

  public getPortalUser(): PortalUser {
    return this.portalUser;
  }

  public isPortalUser(): boolean {
    let portalUser = false;
    if (this.getCompanyUser() && this.getCompanyUser().user && this.getCompanyUser().user.authorities) {
      portalUser = this.getCompanyUser()
        .user
        .authorities
        .includes('PORTAL_USER');
    }
    return portalUser;
  }

  public isUserCompanySalesOrAccountManager(user: PortalUser): boolean {
    return this.company &&
      ((this.company.salesManager && this.company.salesManager.user.id === user.id) ||
        (this.company.accountManager && this.company.accountManager.user.id === user.id));
  }
}
