import { Component, Output, OnInit, EventEmitter, ViewChild, Input } from '@angular/core';
import { FileMetaData } from '../../../dto/file-meta-data';
import { FileUpload } from 'primeng/fileupload';
import { ConfigService } from '../../../../data-services/config.service';
import { AuthService } from '../../../../security/auth.service';
import { FileKeyPair } from '../../../dto/file-key';
import { getFileSizeString } from '../../../pipes/file-size.pipe';
import { isNullOrUndefined } from '../../../utils/is-null-or-undefined';

@Component({
  selector: 'townip-message-file-upload',
  templateUrl: './message-file-upload.component.html',
  styleUrls: ['./message-file-upload.component.scss']
})
export class MessageFileUploadComponent implements OnInit {

  @ViewChild(FileUpload) public pFileUpload: FileUpload;

  @Input()
  public displayUploadContainer = false;

  @Output()
  // NOTE: Only suppressing because legacy.
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  public onUpdateFiles: EventEmitter<FileKeyPair[]> = new EventEmitter();

  @Output()
  // NOTE: Only suppressing because legacy.
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  public onRemoveFile: EventEmitter<string[]> = new EventEmitter();

  @Output()
  // NOTE: Only suppressing because legacy.
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  public onSelectRemoveFile: EventEmitter<boolean> = new EventEmitter<boolean>();

  public uploadErrors: string[];

  public uploadedFiles: FileKeyPair[] = [];

  public emailFileThreshold: number;

  // This is a list of filekeys that should be removed from the backend.
  // This is used so that we don't have files on the backend that are created but
  // are not referenced to anything.
  private uploadedFilesToDelete: string[] = [];

  constructor(private configService: ConfigService,
              private authService: AuthService) {
  }

  public ngOnInit(): void {
    this.emailFileThreshold =
      Number(this.configService.retrieveParamConfigValueByType('EMAIL_FILE_SIZE_THRESHOLD')) * 1024 * 1024;

    // make sure file category retrieved for file attachment.
    this.configService.retrieveFileCategoryTypes();
  }

  public reset(): void {
    this.uploadedFilesToDelete = [];
    this.uploadedFiles = [];
    this.uploadErrors = [];
  }

  public onUpload(event: any): void {
    event.originalEvent.body.forEach(file => {
      this.uploadedFiles.push(file);
    });

    this.onUpdateFiles.emit(this.uploadedFiles);
  }

  public remove(file: FileKeyPair): void {
    const filteredUploadedFiles = this.uploadedFiles.filter(uploadedFile => uploadedFile.filekey !== file.filekey);
    this.uploadedFilesToDelete.push(file.filekey);
    this.uploadedFiles = filteredUploadedFiles;

    this.onRemoveFile.emit(this.uploadedFilesToDelete);
    this.onUpdateFiles.emit(this.uploadedFiles);
  }

  public removeFromUploader(event: any, file: any): void {
    // Remove the file from the prime uploader
    const fileIndex = this.pFileUpload.files.findIndex(f => f.name === file.name);
    this.pFileUpload.remove(event, fileIndex);
    this.onSelectRemoveFile.emit(true);
  }

  public onSelect(event: any): void {
    let index = 0;
    this.uploadErrors = [];
    const removeFiles: File[] = [];

    while (!isNullOrUndefined(event.files[index])) {
      const fileSize = event.files[index].size;

      if (this.uploadedFiles.find(file => file.filename === event.files[index].name)) {
        removeFiles.push(event.files[index]);
        this.uploadErrors.push(`${event.files[index].name} is already in the Uploaded File list.`);
        index++;
        continue;
      }

      if (fileSize === 0) {
        this.uploadErrors.push(`${event.files[index].name} is empty`);
        removeFiles.push(event.files[index]);
        index++;
        continue;
      }

      if (fileSize > this.pFileUpload.maxFileSize) {
        this.uploadErrors.push(`${event.files[index].name}: Invalid file size, maximum upload size is 1 GB `);
      } else if (fileSize > this.emailFileThreshold) {
        // show warning message for email file size threshold.
        this.uploadErrors.push(`${event.files[index].name}: : Warning, email file size threshold
        ${getFileSizeString(this.emailFileThreshold)}, email might not be delivered.`);
      } else {
        index++;
        continue;
      }
      index++;
    }

    setTimeout(() => {
      if (removeFiles.length > 0) {
        for (const duplicate of removeFiles) {
          const fileIndex = this.pFileUpload.files.findIndex(f => f.name === duplicate.name);
          this.pFileUpload.remove(event, fileIndex);
        }

        // Emit once all duplicates are removed
        this.onSelectRemoveFile.emit(true);
      }

      this.pFileUpload.upload();
    });
  }

  public onBeforeUpload(event: any): void {
    if (this.pFileUpload.files && this.pFileUpload.files.length > 0) {
      const selectedFileMetaData: FileMetaData[] = [];
      this.pFileUpload.files.forEach(file => {
        const metaData: FileMetaData = {
          fileName: file.name,
          fileCategory: this.configService.fileCategories.find(category => category.fileCategory === 'FILE_ATTACHMENT'),
        };
        selectedFileMetaData.push(metaData);
      });
      event.formData.append('filemetadata', JSON.stringify(selectedFileMetaData));
    }
  }

}
