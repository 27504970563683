import { SelectItem } from 'primeng/api';

export enum DashboardPreference {
  TranslationAndFiling = 'TRANSLATION_AND_FILING',
  Annuities = 'ANNUITIES',
  Forecaster = 'FORECASTER',
  Reports = 'REPORTS',
}

export const DASHBOARD_PREFERENCE: SelectItem[] = [
  { label: 'Translation & Filing', value: DashboardPreference.TranslationAndFiling },
  { label: 'Renewal Services', value: DashboardPreference.Annuities },
  { label: 'Forecaster', value: DashboardPreference.Forecaster },
  { label: 'Reports', value: DashboardPreference.Reports },
];
