import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaintenanceNoticeComponent } from './maintenance-notice.component';
import { ModalModule } from '../../controls/modal/modal.module';

@NgModule({
  imports: [
    CommonModule,
    ModalModule
  ],
  declarations: [MaintenanceNoticeComponent],
  exports: [MaintenanceNoticeComponent]
})
export class MaintenanceNoticeModule {
}
