import * as _ from 'underscore';
import { Injectable } from '@angular/core';
import { isNullOrUndefined } from '../utils/is-null-or-undefined';

export interface RequestErrorInterceptorMessage {
  url: string;
  title?: string;
  body?: string;
  bypass?: boolean;
  permanent?: boolean;
  forceNoOrigin?: boolean;
}

@Injectable()
export class HttpRequestErrorInterceptorMessages  {

  public messages: RequestErrorInterceptorMessage[] = [];

  /**
   * Adds a new override message, if you want to override the default one that the request interceptor
   * generates based off the actual request response
   *
   * Set { url: '/api/whatever', bypass: true } if you want to disable the default message for the given URL
   * @param {RequestErrorInterceptorMessage} message
   * @param {boolean} externalUrl
   */
  public add(message: RequestErrorInterceptorMessage, externalUrl: boolean = false): void {
    let completeUrl = this.generateCompleteUrl(message.url);

    // If we are requesting through an external url, use that one.
    if (externalUrl === true || message.forceNoOrigin) {
      completeUrl = message.url;
    }

    const existingMessage = _.findWhere(this.messages, { url: completeUrl });

    if (existingMessage) {
      existingMessage.title = message.title;
      existingMessage.body = message.body;
      existingMessage.bypass = !isNullOrUndefined(message.bypass);
    } else {
      const pushMessage = _.clone(message);
      pushMessage.url = completeUrl;
      this.messages.push(pushMessage);
    }
  }

  /**
   * Removed a message with the given url
   * @param url
   */
  public remove(interceptMessage: RequestErrorInterceptorMessage): void {
    if (isNullOrUndefined(interceptMessage)) {
      return;
    }

    const messageIndex = this.messages.findIndex(message => message.url === interceptMessage.url);

    if (messageIndex !== -1) {
      this.messages.splice(messageIndex, 1);
    }
  }

  /**
   * Finds a message with the given url
   * Url must be the full url including domain and protocol
   * @param {string} url
   * @returns {RequestErrorInterceptorMessage}
   */
  public find(url: string): RequestErrorInterceptorMessage  {
    return  _.findWhere(this.messages, { url: url });
  }

  private generateCompleteUrl(url: string): string {
    return window.location.origin + url;
  }
}
