import { Component, ElementRef, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import Mark from 'mark.js';

@Component({
  selector: 'townip-text-highlighter',
  templateUrl: './text-highlighter.component.html',
  styleUrls: ['./text-highlighter.component.scss']
})
export class TextHighlighterComponent implements OnInit, OnChanges {

  @Input()
  public word = '';

  @Input()
  public color: string;

  @ViewChild('container', { static: true })
  public container: ElementRef;

  private markInstance: Mark;

  constructor() {
  }

  public ngOnInit(): void {
    this.markInstance = new Mark(this.container.nativeElement);
  }

  public ngOnChanges(): void {
    if (!this.word) {
      setTimeout(() => this.markInstance.unmark());
      return;
    }
    const options = {
      each: (element: HTMLElement) => {
        element.style.backgroundColor = this.color;
      }
    };
    setTimeout(() => this.markInstance.mark(this.word, options));
  }

}
