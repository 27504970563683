export function generateFullname(user: any): string {
  let name = '';
  try {
    name = `${user.firstName} ${user.lastName}`;
  } catch (e) {
    console.warn('firstName and lastName properties must exist');
  }

  return name;
}
