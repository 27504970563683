import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'townip-icon-svgs',
  templateUrl: './icon-svgs.component.html',
  styleUrls: ['./icon-svgs.component.scss']
})
export class IconSvgsComponent implements OnInit {

  @Input()
  public iconType: string;

  constructor() {
  }

  public ngOnInit(): void {
  }
}
