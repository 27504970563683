import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChange, ViewChild } from '@angular/core';
import { Message } from '../../../dto/messaging/message';
import { MessagingService } from '../../../services/messaging.service';
import { PortalUser } from '../../../domain/user';
import { UserService } from '../../../services/user.service';
import { Subscription } from 'rxjs';
import * as sortBy from 'lodash/sortBy';
import { MessageAssociateFileComponent } from '../message-associate-file/message-associate-file.component';
import { FileKeyPair } from '../../../dto/file-key';
import { SelectItem } from 'primeng/api';
import { isNullOrUndefined } from '../../../utils/is-null-or-undefined';

interface MessageThread extends Message {
  showFullDetails?: boolean;
}

@Component({
  selector: 'townip-message-thread',
  templateUrl: './message-thread.component.html',
  styleUrls: ['./message-thread.component.scss']
})
export class MessageThreadComponent implements OnInit, OnChanges, OnDestroy {

  @ViewChild(MessageAssociateFileComponent, { static: true })
  public messageAssociateComponent: MessageAssociateFileComponent;

  @Input() public threadMessageId: number;

  @Input() public externalRecipients: string[];

  @Input() public allowCreate: boolean;

  @Input() public hideFooter: boolean;

  @Input() public threadType: string;

  @Input()
  // Needed in order to keep the messages organization names
  public contacts: SelectItem[];

  @Input()
  public showProjectScope = true;

  @Output() public sendMessage: EventEmitter<Message>;

  @Output() public replyToMessage: EventEmitter<Message>;

  @Output() public navigateToScope = new EventEmitter<void>();

  @Output() public triggerReplyAll = new EventEmitter<void>();

  @Output()
  // NOTE: Only suppressing because legacy.
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  public onFileAssociated = new EventEmitter<void>();

  public showForm: boolean;

  public threadMessages: MessageThread[];

  public currentUser: PortalUser;

  public parentMessage: Message;

  public isGeneralMessage = true;

  private messageSubscription: Subscription;

  constructor(private messagingService: MessagingService,
              private userService: UserService) {
    this.showForm = false;
    this.allowCreate = true;
    this.sendMessage = new EventEmitter();
    this.replyToMessage = new EventEmitter();
    this.threadMessages = [];
    this.currentUser = this.userService.getUser();
  }

  public ngOnInit(): void {
  }

  public ngOnChanges(changes: { [propertyName: string]: SimpleChange }): void {
    if (changes.threadMessageId) {
      this.threadMessages = [];
      this.cleanupSubscriptions();
      if (this.threadType === 'user') {
        this.messageSubscription = this.messagingService.messages.subscribe(messages => {
          this.parentMessage = messages.find(message => message.id === this.threadMessageId);
          this.processThread(this.parentMessage);
        });
      } else if (this.threadType === 'project') {
        this.messageSubscription = this.messagingService.projectMessages.subscribe(messages => {
          this.parentMessage = messages.find(message => message.id === this.threadMessageId);
          this.processThread(this.parentMessage);
        });
      }
    }

    // Needed since contacts may not be built yet by the time we process on initial load.
    if (changes.contacts) {
      if (this.parentMessage) {
        this.syncOrganizationNames(this.parentMessage);
      }
    }
  }

  public ngOnDestroy(): void {
    this.cleanupSubscriptions();
  }

  private cleanupSubscriptions(): void {
    if (this.messageSubscription) {
      this.messageSubscription.unsubscribe();
    }
  }

  public show(): void {
  }

  private processThread(threadMessage: MessageThread): void {
    this.threadMessages = [];
    if (threadMessage) {
      if (threadMessage.childMessages) {
        threadMessage.childMessages.forEach(message => {
          if (this.threadMessages.indexOf(message) < 0) {
            message.isReply = true;
            this.threadMessages.push(message);
          }
        });
      }
      this.threadMessages.push(threadMessage);

      this.syncOrganizationNames(threadMessage);
    }

    // Sort Date
    this.threadMessages = sortBy(this.threadMessages, 'creationTime');
    // Reverse the Order
    this.threadMessages = this.threadMessages.reverse();

    if (this.threadMessages && this.threadMessages.length > 0) {
      // Automatically show the details of the latest message
      this.threadMessages[0].showFullDetails = true;
    }

    // Keep track if this thread has a scope or not
    this.isGeneralMessage = !(threadMessage && threadMessage.scope);
  }

  public showAssociateFile( file: FileKeyPair, message: Message): void {
    let parentMessage = message;
    if (message.parentMessageId) {
      parentMessage = this.threadMessages.find(theMessage => theMessage.id === message.parentMessageId);
    }

    this.messageAssociateComponent.show(file, parentMessage);
  }

  private syncOrganizationNames(message: Message): void {
    if (!isNullOrUndefined(this.contacts) && !isNullOrUndefined(message.messageRecipients)) {
      message.messageRecipients.forEach(messageRecipient => {
        const matchedContact = this.contacts.find(contact => contact.value.id === messageRecipient.user.id);
        if (!isNullOrUndefined(matchedContact)) {
          messageRecipient.user.organizationName = matchedContact.value.organization;
        }
      });
    }
  }

  public toggleForm(): void {
    if (this.showForm) {
      this.showForm = false;
      return;
    }

    this.showForm = true;
  }

  public saveMessage(message: Message): void {
    this.sendMessage.emit(message);
    this.toggleForm();
  }
}
