import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ServerBannerComponent } from './server-banner.component';

@NgModule({
  imports: [
    CommonModule
  ],
  exports: [ServerBannerComponent],
  declarations: [ServerBannerComponent],
})
export class ServerBannerModule { }
