import * as _ from 'underscore';
import { Pipe, PipeTransform } from '@angular/core';
import { FilterGroup } from '../filter';

@Pipe({
  name: 'isFilterActive'
})
export class IsFilterActivePipe implements PipeTransform {

  public transform(filters: FilterGroup[], name: string): boolean {
    const activeFilter = _.findWhere(filters, { name: name });
    if (activeFilter) {
      return true;
    }
    return false;
  }

}
