import { Injectable } from '@angular/core';
import { WorkflowActions } from '../shared/dto/projects/workflow-actions';
import { HttpClient } from '@angular/common/http';
import { RequestService } from '../shared/services/request-service';
import { Observable, of } from 'rxjs';
import { cloneDeep } from '../shared/clone-deep';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class WorkflowService {

  public workflowActionsPromise: Promise<WorkflowActions[]>;

  public workflowActions: WorkflowActions[];

  constructor(private httpClient: HttpClient,
              private requestService: RequestService) {
  }

  /**
   * Legacy version of retrieving workflow actions
   * Should not be used anymore for NEW components.
   * Use getWorkflowActions instead
   */
  public retrieveWorkflowActions(): Promise<WorkflowActions[]> {
    if (!!this.workflowActionsPromise) {
      return this.workflowActionsPromise;
    }

    const url = '/api/tasks/workflow-actions';

    this.workflowActionsPromise = this.httpClient
      .get<WorkflowActions[]>(url, { headers: this.requestService.buildHttpHeaders() })
      .toPromise();

    this.workflowActionsPromise.then(actions => {
      this.workflowActions = actions;
    }
    );
  }

  /**
   * Modern version of retrieveWorkflowActions for use with new components.
   * Caching is handled at the interceptor level or in the cache of this service.
   * Depending on where you are in the system, should work out of the box.
   *
   * @param ids - optional set of id's to filter out only the ones we need.
   * If no id's are privided, it will return all the system workflow actions
   */
  public getWorkflowActions(ids?: number[]): Observable<WorkflowActions[]> {
    const url = '/api/tasks/workflow-actions';

    // Do we have workflow actions already?
    let workflowActions: Observable<WorkflowActions[]>;

    if (this.workflowActions) {
      // We should have it set already of the app user has visited some route with guards that set this.
      // Make it an observable so we can pipe and transform
      workflowActions = of(cloneDeep(this.workflowActions));
    } else {
      workflowActions = this.httpClient
        .get<WorkflowActions[]>(url, { headers: this.requestService.buildHttpHeaders() });
    }

    // If id's are given filter the wafs to the specified ones.
    // This is often used for updating or modifying tasks
    if (ids) {
      return workflowActions
        .pipe(
          switchMap(wafs => {
            const filtered = wafs.filter(waf => ids.some(id => id === waf.id));
            return of(filtered);
          })
        );
    }

    return workflowActions;
  }

  public setRequestValue(waf: WorkflowActions, requestAction: string, value: any): void {
    const request = waf
      .workflowActionRequests
      .find((actionReq) => actionReq.type === requestAction);

    if (!request) {
      return;
    }

    request.requestResponse = value;
  }

  public setRequestValueByTitle(waf: WorkflowActions, title: string, value: any): void {
    const request = waf
      .workflowActionRequests
      .find((actionReq) => actionReq.title === title);

    if (!request) {
      return;
    }

    request.requestResponse = value;
  }
}
