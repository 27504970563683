import { PatentParticipant } from './patent/patent-participant';

export class EstimateFooter {
  public applicants: PatentParticipant[];
  public patentTitle: string;
  public patentId: string;
  public sourceFileInd = false;
  public totalCost: number;
  public sourceLanguage: string;
  public earliestPriority: string;
  public isEstimateSubmit: boolean;

  // These values are only set when data is selected
  public patentType: string;
  public patentSelected: boolean;
  public filingSelected: boolean;
  public countryCount: number;
  public claimsAnswered = true;
  public dateAnswered = true;
  public numberOfClaims: number;
  public dateRequired: boolean;
  public patentVerified: boolean;
  public unknownDocument: boolean;
  public turnaroundDate: Date;
  public countriesConfirmed: boolean;
  public directFilesUploaded: boolean;

  constructor() {
    this.clearPatent();
  }

  public settingTurnaround(isDateRequired: boolean): void {
    this.dateAnswered = false;
    this.dateRequired = isDateRequired;
  }

  public modifyingClaims(): void {
    this.claimsAnswered = true;
  }

  /**
   * Helper method to add the claims step question when available.
   */
  public updateNumberOfClaims(numberOfClaims: number): void {
    this.numberOfClaims = numberOfClaims;
  }

  public clearPatent(): void {
    this.sourceFileInd = false;
    this.applicants = [{ name: '- -' }];
    this.patentTitle = '- -';
    this.patentId = '- -';
    this.patentVerified = false;
    this.totalCost = null;
    this.sourceLanguage = '- -';
    this.earliestPriority = null;
    this.patentSelected = false;
    this.directFilesUploaded = false;
    this.unknownDocument = true;
  }

  public isEstimateReady(): boolean {
    let isReady = true;

    // For DIRECT filing type
    if (this.patentType === 'DIRECT') {
      if (!this.filingSelected) {
        isReady = false;
      }

      if (this.countryCount < 1) {
        isReady = false;
      }

      if (!this.directFilesUploaded) {
        isReady = false;
      }

      if (!this.dateAnswered) {
        isReady = false;
      }

      return isReady;
    }

    // For PCT AND EP
    if (!this.patentType) {
      isReady = false;
    } else if (!this.filingSelected) {
      isReady = false;
    } else if (!this.patentSelected) {
      isReady = false;
    } else if (!this.patentVerified) {
      isReady = false;
    } else if (!this.sourceFileInd && (this.unknownDocument || this.patentType === 'PCT')) {
      isReady = false;
    } else if (this.countryCount < 1) {
      isReady = false;
    } else if (!this.dateAnswered) {
      isReady = false;
    } else if (!this.claimsAnswered) {
      isReady = false;
    } else if (!this.countriesConfirmed) {
      isReady = false;
    }
    return isReady;
  }
}
