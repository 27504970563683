import { Directive } from '@angular/core';

@Directive({
  selector: 'townip-user-type'
})
export class UserTypeDirective { }

@Directive({
  selector: 'townip-user-name'
})
export class UserNameDirective { }

@Directive({
  selector: 'townip-user-image'
})
export class UserImageDirective { }

@Directive({
  selector: 'townip-user-content'
})
export class UserContentDirective { }


@Directive({
  selector: 'townip-user-meta'
})
export class UserMetaDirective { }
