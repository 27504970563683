<div class="row no-gutters">
  <div class="col-auto status-filters" *ngIf="(filters | filterCategory:'status').length > 0">
    <div class="category-label" >{{filterHeaderLabel}}</div>
    <div class="d-flex">
      <div class="filter-buttons">
        <button class="btn btn-secondary btn-filter"
                [ngClass]="{ active: activeFilters | isFilterActive:filter.name }"
                (click)="select(filter)"
                [disabled]="(filter.name | isFilterDisabled:disabledFilters) || disabled"
                *ngFor="let filter of (filters | filterCategory:'status')">
          <span class="active-icon" *ngIf="isActive(filter)">
            <i class="fa fa-check" style="margin-left: 3px;"></i>
          </span>
          {{filter.name}}
        </button>
      </div>
      <div class="category-divider"></div>
    </div>
  </div>
  <div class="col-auto extra-filters" *ngIf="(filters | filterCategory:'additional').length > 0">
    <div class="category-label">Additional Filters</div>
    <div class="d-flex">
      <div class="filter-buttons">
        <button class="btn btn-secondary btn-filter"
                [ngClass]="{ active: activeFilters | isFilterActive:filter.name }"
                (click)="select(filter)"
                [disabled]="disabled"
                *ngFor="let filter of (filters | filterCategory:'additional')">
          <span class="active-icon" *ngIf="isActive(filter)"><i class="fa fa-check"></i></span>
          {{filter.name}}
        </button>
      </div>
      <div class="category-divider"></div>
    </div>
  </div>
  <div class="col-auto clearing-filters d-flex">
    <div class="filter-buttons align-self-end">
      <button class="clear-filter" [disabled]="disabled" (click)="clear()">Clear All</button>
    </div>
  </div>
</div>

<!-- Filter form - it has a modal inside -->
<townip-filter-form
  #filterModal
  [fields]="customFilterOptions"
  (onSaved)="addCustomFilter($event)">
</townip-filter-form>


