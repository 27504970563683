import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { Company } from '../../../../shared/dto/company';
import { SelectItem } from 'primeng/api';
import { CompanyUser } from '../../../../shared/dto/customer-management/company-user';
import { NavigationStart, Router, RouterEvent } from '@angular/router';
import { CustomerManagementService } from '../../../../data-services/customer-management.service';
import * as _ from 'underscore';
import { OrganizationManagementService } from '../../../services/organization-management.service';
import { CompanyLite } from '../../../../shared/dto/company-lite';
import PreferencesUtil from '../../../company-profile/preferences-util';
import { AutoComplete } from 'primeng/autocomplete';
import { isNullOrUndefined } from '../../../../shared/utils/is-null-or-undefined';
import { ModalComponent } from '../../../../controls/modal/modal.component';

@Component({
  selector: 'townip-new-client-estimate',
  templateUrl: './new-client-estimate.component.html',
  styleUrls: ['./new-client-estimate.component.scss']
})
export class NewClientEstimateComponent implements OnInit {
  @Input()
  public clientCompanies: CompanyLite[];

  @ViewChild(ModalComponent, { static: true })
  public modal: ModalComponent;

  @ViewChild(AutoComplete, { static: true })
  public autoComplete: AutoComplete;

  public filteredCompanies: CompanyLite[];
  public selectedCompany: Company;
  public companyUsers: SelectItem[];
  public selectedCompanyUser: CompanyUser;
  public estimateType: string;

  // Alert Flags
  public showCompanyAlert = false;
  public showCompanyUserAlert = false;

  private modalSubscription: Subscription;

  constructor(private router: Router,
              private customerManagementService: CustomerManagementService,
              private orgService: OrganizationManagementService) {
    // this.companies = [];
    this.companyUsers = [];
  }

  public ngOnInit(): void {
  }

  private clear(): void {
    this.showCompanyAlert = false;
    this.showCompanyUserAlert = false;
    this.selectedCompany = null;
    this.selectedCompanyUser = null;
    this.filteredCompanies = null;
    this.companyUsers = [];

    if (this.autoComplete) {
      // clear input from autoComplete
      this.autoComplete.inputEL.nativeElement.value = '';
    }
  }

  /**
   * Show this modal for either a Project or Estimate.
   * @param {string} estimateType
   */
  public show(estimateType: string): void {
    this.clear();
    this.estimateType = estimateType;
    this.customerManagementService
      .getCompanyLites()
      .subscribe(companies => {
        this.clientCompanies = companies;
        this.setupCompanies();
      });

    this.modalSubscription = this.router
      .events
      .subscribe((event) => {
        const isOpen = this.modal && this.modal.modal.isShown;
        // TODO: Angular Upgrade, need to look at what this did originally.
        // const redirectToDashboard = event.url ? event.url.startsWith('/dashboard') : false;
        const redirectToDashboard = false;
        if (event instanceof NavigationStart && isOpen && !redirectToDashboard) {
          this.hide();
        }
      });
  }

  private setupCompanies(): void {
    this.clientCompanies.sort((a, b) => {
      const companyNameA = a.companyName.toLocaleLowerCase();
      const companyNameB = b.companyName.toLocaleLowerCase();
      if (companyNameA > companyNameB) {
        return 1;
      }
      if (companyNameA < companyNameB) {
        return -1;
      }
      return 0;
    });
    this.modal.show();
  }

  public hide(): void {
    this.clear();
    this.modal.hide();
    this.modalSubscription.unsubscribe();
  }

  public filterClientCompanies(event: any): void {
    const query = event.query;
    this.filteredCompanies = _.filter(this.clientCompanies,
      (company) =>
        company.companyName
          .toLowerCase()
          .indexOf(query.toLowerCase()) >= 0);
  }

  public companyChange(): void {
    this.companyUsers = [];
    this.selectedCompanyUser = null;
    this.selectedCompany = null;
  }

  public selectCompany(event: CompanyLite): void {
    this.showCompanyAlert = false;
    this.selectedCompanyUser = null;

    this.customerManagementService
      .getCompanyById(event.id)
      .then((retrievedCompany) => {
        this.selectedCompany = retrievedCompany;
        this.companyUsers = [];
        this.selectedCompany.companyUsers.forEach(companyUser => {
          const restrictedUser = companyUser.user.authorities.indexOf('RESTRICTED_COMPANY_USER') >= 0;
          if (restrictedUser && this.estimateType !== 'Project') {
            return;
          }

          if (companyUser.user.active) {
            this.companyUsers
              .push({ label: companyUser.user.firstName + ' ' + companyUser.user.lastName, value: companyUser });
          }
        });

        this.companyUsers.sort((a, b) => {
          const userNameA = a.label.toLocaleLowerCase();
          const userNameB = b.label.toLocaleLowerCase();
          if (userNameA > userNameB) {
            return 1;
          }
          if (userNameA < userNameB) {
            return -1;
          }
          return 0;
        });

        if (this.companyUsers.length === 1) {
          this.selectedCompanyUser = this.companyUsers[0].value;
          this.selectCompanyUser();
        } else {
          this.companyUsers.unshift({ label: 'Select User', value: null });
        }
      });
  }

  public selectCompanyUser(): void {
    if (isNullOrUndefined(this.selectedCompanyUser)) {
      this.showCompanyUserAlert = true;
      // Do Nothing
      return;
    }
    this.showCompanyUserAlert = false;

    PreferencesUtil.copyCompanyPreferencesToUser(this.selectedCompany, this.selectedCompanyUser);
  }

  public submit(): void {
    if (isNullOrUndefined(this.selectedCompany)) {
      this.showCompanyAlert = true;
    }

    if (isNullOrUndefined(this.selectedCompanyUser)) {
      this.showCompanyUserAlert = true;
    }

    if (this.showCompanyAlert || this.showCompanyUserAlert) {
      return;
    }


    this.orgService.setCompany(this.selectedCompany);
    this.orgService.setCompanyUser(this.selectedCompanyUser);
    if (this.estimateType === 'Project') {
      // OLD estimate creation page:
      // this.router.navigate(['/estimate/new-project/patent']);
      // NEW estimate creation page:
      this.router.navigate(['/estimate/new-estimate-2'], { queryParams: { project: true } });
    } else {
      // OLD estimate creation page:
      // this.router.navigate(['/estimate/new-estimate/patent']);
      // NEW estimate creation page:
      this.router.navigate(['/estimate/new-estimate-2']);
    }
    this.hide();
  }
}
