import { Directive, ElementRef } from '@angular/core';

/**
 * Content placeholders to organize content in a more semantic way.
 * For readability and maintenance
 */
@Directive({ selector: 'townip-message-content' })
export class MessageContentDirective {
}

@Directive({ selector: 'townip-message-date' })
export class MessageDateDirective {
}

@Directive({ selector: 'townip-message-author' })
export class MessageAuthorDirective {
}

@Directive({ selector: 'townip-message-attachments' })
export class MessageAttachmentsDirective {
}

@Directive({ selector: 'townip-message-author-image' })
export class MessageAuthorImageDirective {
}

@Directive({ selector: 'townip-message-footer' })
export class MessageFooterDirective {
}

@Directive({ selector: '[townipThreadContainer]' })
export class ThreadContainerDirective {

  private nativeElement: HTMLElement;

  constructor(private element: ElementRef) {
    this.nativeElement = element.nativeElement;
  }

  public scrollToBottom(): void {
    this.nativeElement.scrollTop = this.nativeElement.scrollHeight;
  }

  public scrollToTop(): void {
    jQuery(this.nativeElement)
      .animate({
        scrollTop: 0
      }, 500);
  }
}
