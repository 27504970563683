import { Component, ElementRef, EventEmitter, forwardRef, Input, OnChanges, OnDestroy, OnInit, Output, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { UserService } from '../../../services/user.service';
import * as _ from 'underscore';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { clone } from '../../../../settings-v2/shared/utils/clone';
import { RecipientFilter } from '../utils/recipient-filter';

export interface IndexedSelectItem extends SelectItem {
  searchIndex?: string;
}

@Component({
  selector: 'townip-message-find-recipient',
  templateUrl: './message-find-recipient.component.html',
  styleUrls: ['./message-find-recipient.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => MessageFindRecipientComponent),
    multi: true
  }]
})
export class MessageFindRecipientComponent implements OnInit, OnChanges, ControlValueAccessor, OnDestroy {

  @ViewChild('inputSelection', { static: true }) public inputSelection: ElementRef;

  @Input()
  public users: IndexedSelectItem[];

  @Input()
  public selected: IndexedSelectItem[];

  @Input()
  public description = 'You are able to search for Clients, Vendors, and Internal Users.';

  @Input()
  public userListHeight = 300;

  @Output()
  public cancel: EventEmitter<boolean> = new EventEmitter<boolean>();

  public filteredUsers: IndexedSelectItem[];

  public tempSelection = '';

  public newlySelected: IndexedSelectItem[];

  public recipientTypes: string[];

  public isInternal: boolean;

  public onChange;

  public onTouched;

  public isOpen = false;

  public inputTimer: any;

  public indexedUserSort = (user: IndexedSelectItem): string => user && user.label.toLowerCase();

  constructor(private renderer: Renderer2,
              private eRef: ElementRef,
              private userService: UserService) {
    this.newlySelected = [];
    this.users = [];
    this.recipientTypes = [];
  }

  public ngOnInit(): void {
    this.isInternal = this.userService
      .getUser()
      .userType === 'INTERNAL';

    this.isOpen = true;
    this.filteredUsers = clone(this.users);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.selected) {
      this.newlySelected = this.selected;
    }

    if (this.selected === undefined || this.selected === null) {
      this.newlySelected = [];
    }
  }

  public ngOnDestroy(): void {
    this.onAddNewRecipients();
  }

  public writeValue(value: IndexedSelectItem[]): void {
    this.newlySelected = clone(value);
  }

  public registerOnChange(func: any): void {
    this.onChange = func;
  }

  public registerOnTouched(func: any): void {
    this.onTouched = func;
  }

  public select(selectedUser: IndexedSelectItem): void {
    if (this.tempSelection !== '') {
      this.tempSelection = '';
      this.onSearchKeyChange(this.tempSelection);
    }

    const isExisting = this.newlySelected.find(user => {
      return user.value.id === selectedUser.value.id && user.value.email === selectedUser.value.email;
    });

    if (isExisting) {
      return;
    }

    this.newlySelected.push(selectedUser);
  }

  /**
   * Add all the users of the selected organization
   * @param organization
   */
  public selectOrganization(organization: string): void {
    if (this.tempSelection !== '') {
      this.tempSelection = '';
      this.onSearchKeyChange(this.tempSelection);
    }

    const organizationUsers = this.filteredUsers.filter(user => user.value.organization === organization);

    for (const user of organizationUsers) {
      if (!_.find(this.newlySelected, selected => {
        return selected.value.id === user.value.id && selected.value.email === user.value.email;
      })) {
        this.newlySelected.push(user);
      }
    }
  }

  public remove(item: SelectItem): void {
    this.newlySelected = _.without(this.newlySelected, item);
  }

  public onSearchKeyChange(key: string): void {
    clearTimeout(this.inputTimer);

    this.inputTimer = setTimeout(() => {
      this.filteredUsers = this.users.filter(user => {
        return RecipientFilter.isUserExisting(user, key);
      });
    });
  }

  public isUserSelected(selectedUser: IndexedSelectItem): IndexedSelectItem {
    return (_.find(this.newlySelected, (user) => {
      return user.value.id === selectedUser.value.id && user.value.email === selectedUser.value.email;
    }));
  }

  public setFocus(): void {
    if (this.inputSelection) {
      const nElement = this.inputSelection.nativeElement as HTMLInputElement;
      nElement.focus();
    }
  }

  public onAddNewRecipients(): void {
    if (!this.newlySelected) {
      return;
    }

    if (this.onChange) {
      this.onChange(this.newlySelected);
    }
  }

  public onClearAll(): void {
    this.tempSelection = '';
    this.newlySelected = [];
    // Reset the list
    this.filteredUsers = clone(this.users);
  }
}
