export enum PatentFamilyPaginatedFields {
  id = 'id',
  familyName = 'familyName',
  familyNumber = 'familyNumber',
  patentCount = 'patentCount',
  countryCount = 'countryCount',
  lastUpdated = 'lastUpdated',
  lastUpdatedBy = 'lastUpdatedBy',
  companyId = 'companyId',
  companyName = 'companyName',
  description = 'description',
  companyUserId = 'companyUserId',
  clientContactName = 'clientContactName',
  realCompanyId = 'realCompanyId',
}

export interface PatentFamilyPaginated {
  id?: number;
  familyName?: string;
  familyNumber?: string;
  patentCount?: number;
  countryCount?: number;
  lastUpdated?: number;
  lastUpdatedBy?: string;
  companyId?: number;
  companyName?: string;
  description?: string;
  companyUserId?: number;
  clientContactName?: string;
  realCompanyId?: number;
}
