import { Injectable } from '@angular/core';

@Injectable()
export class PatentPortfolioService {

  public static orgIdKey = 'townip_managed_org_id';

  public static orgUserIdKey = 'townip_managed_org_user_id';

  public static orgUserNameKey = 'townip_managed_org_user_name';

  public static orgNameKey = 'townip_managed_org_name';

  private _organizationId: number;

  private _organizationName: string;

  private _organizationUserId: number;

  private _organizationUserName: string;

  constructor() {
    this._organizationId = +window.localStorage.getItem(PatentPortfolioService.orgIdKey);
    this._organizationUserId = +window.localStorage.getItem(PatentPortfolioService.orgUserIdKey);
    this._organizationName = window.localStorage.getItem(PatentPortfolioService.orgNameKey);
    this._organizationUserName = window.localStorage.getItem(PatentPortfolioService.orgUserNameKey);
  }

  public get organizationId(): number {
    return this._organizationId;
  }

  public set organizationId(value: number) {
    if (!this._organizationId) {
      this._organizationId = value;
      window.localStorage.setItem(PatentPortfolioService.orgIdKey, value.toString());
      return;
    }

    throw new Error('When the organization value is set, you cannot modify it again.');
  }

  public get organizationName(): string {
    return this._organizationName;
  }

  public set organizationName(value: string) {
    if (!this._organizationName) {
      this._organizationName = value;
      window.localStorage.setItem(PatentPortfolioService.orgNameKey, value);
      return;
    }

    throw new Error('When the organization value is set, you cannot modify it again.');
  }

  public get organizationUserId(): number {
    return this._organizationUserId;
  }

  public set organizationUserId(value: number) {
    if (!this._organizationUserId) {
      this._organizationUserId = value;
      window.localStorage.setItem(PatentPortfolioService.orgUserIdKey, value.toString());
      return;
    }

    throw new Error('When the organization value is set, you cannot modify it again.');
  }

  public get organizationUserName(): string {
    return this._organizationUserName;
  }

  public set organizationUserName(value: string) {
    if (!this._organizationUserName) {
      this._organizationUserName = value;
      window.localStorage.setItem(PatentPortfolioService.orgUserNameKey, value.toString());
      return;
    }

    throw new Error('When the organization value is set, you cannot modify it again.');
  }

  public clear(): void {
    this._organizationId = 0;
    this._organizationUserId = 0;
    this._organizationName = '';
    this._organizationUserName = '';

    window.localStorage.removeItem(PatentPortfolioService.orgIdKey);
    window.localStorage.removeItem(PatentPortfolioService.orgUserIdKey);
    window.localStorage.removeItem(PatentPortfolioService.orgNameKey);
    window.localStorage.removeItem(PatentPortfolioService.orgUserNameKey);
  }

}
