<townip-modal #maintenanceNoticeModal heading="New Updates Available" size="lg" (onHide)="clearTimer()">
  <townip-modal-body>
    <div class="d-flex align-items-center py-5">
      <div class="w-50 message">
        <p>
          We’re always working to improve the performance of Sun IP.
          If you do nothing, the system will update automatically in
          <span class="text-primary font-weight-bold">{{countdown}}</span> seconds.
        </p>
        <p>
          If you run into any troubles, please let us know at
          <a href="mailto: support@sunip.com" class="font-weight-bold">support&#64;sunip.com</a>
        </p>
      </div>
      <div class="w-50 text-center">
        <img src="/assets/images/svgAssets/widescreen-computer.svg" class="w-75" alt="maintenance-notice-image">
      </div>
    </div>
  </townip-modal-body>
  <townip-modal-footer>
    <button class="btn btn-primary btn-lg" (click)="refreshPage()">Refresh to Update Now</button>
  </townip-modal-footer>
</townip-modal>
