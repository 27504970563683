<div class="modal fade" bsModal (onHidden)="onCloseButton()" #staticModal="bs-modal" [config]="{backdrop: 'static'}"
     tabindex="-1" role="dialog" aria-labelledby="dialog-static-name" style="{{zIndex ? 'z-index: ' + zIndex : ''}}">
  <div class="modal-dialog modal-{{size}}">
    <div class="modal-content">
      <div class="modal-header">
        <h4
	        id="dialog-static-name"
	        [ngClass]="{'pull-left': showCloseButton, 'mr-auto': !showCloseButton}"
	        class="modal-title pull-left">{{heading}}</h4>
        <button
	        *ngIf="showCloseButton"
	        type="button"
	        class="close pull-right"
	        aria-label="Close"
	        (click)="staticModal.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <ng-content select="townip-modal-body"></ng-content>
      </div>
      <div class="modal-footer" *ngIf="modalFooter">
        <ng-content select="townip-modal-footer"></ng-content>
      </div>
    </div>
  </div>
</div>
