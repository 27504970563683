import { Pipe, PipeTransform } from '@angular/core';
import { MessageInfo } from '../messages/messages.component';

/**
 * This pipe will count the attachment of the parent and child messages
 */
@Pipe({
  name: 'messageAttachmentCount'
})
export class MessageAttachmentCountPipe implements PipeTransform {

  public transform(message: MessageInfo): number {
    let attachmentCount = 0;

    if (message.fileAttachments) {
      attachmentCount = attachmentCount + message.fileAttachments.length;
    }

    if (message.childMessages) {
      for (const childMessage of message.childMessages) {
        if (!childMessage.fileAttachments) {
          continue;
        }

        attachmentCount = attachmentCount + childMessage.fileAttachments.length;
      }
    }

    return attachmentCount;
  }

}
