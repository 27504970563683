import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LoggingService } from '../logging.service';
import { UserService } from '../services/user.service';
import { OrganizationManagementService } from '../../settings-v2/services/organization-management.service';
import { CustomerManagementService } from '../../data-services/customer-management.service';
import { isNullOrUndefined } from '../utils/is-null-or-undefined';

@Injectable()
export class CreateEstimateGuardService  {

  constructor(private loggingService: LoggingService,
              private orgService: OrganizationManagementService,
              private customerManagementService: CustomerManagementService,
              private router: Router,
              private userService: UserService) {
  }

  /**
   * Guard to prevent a PM from opening the create-estimate page in a bad state.
   * @returns {Promise<T>}
   */
  public canActivate(): Promise<boolean> {
    return new Promise((resolve) => {
      const user = this.userService.getUser();
      if (isNullOrUndefined(user)) {
        this.router.navigate(['/auth/login']);
        resolve(false);
        return;
      }

      if (user.userType === 'INTERNAL') {
        if (isNullOrUndefined(this.orgService.getCompanyUser()) || isNullOrUndefined(this.orgService.getCompany())) {
          this.router.navigate(['/dashboard']);
          resolve(false);
        } else {
          resolve(true);
        }
        return;
      } else if (user.userType === 'COMPANY') {
        if (isNullOrUndefined(this.customerManagementService.getCompanyUser()) ||
          isNullOrUndefined(this.customerManagementService.getCompany())) {
          this.router.navigate(['/dashboard']);
          resolve(false);
        } else {
          resolve(true);
        }
        return;
      }
      resolve(false);
    });
  }
}
