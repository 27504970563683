import { FileCategoryType } from '../../dto/system-config/file-category-type';
import { FileKeyPair } from '../../dto/file-key';

/**
 * Remove the file category subtype of each FileKeyPair
 * @param {FileKeyPair} file
 * @returns {FileKeyPair}
 */
export function omitFileCategorySubtype(file: FileKeyPair): FileKeyPair {
  if (file.fileCategory.text.startsWith('Translation-')) {
    const texts = file.fileCategory.text.split('Translation-');
    file.fileCategory.text = texts[1];

    return file;
  }

  if (file.fileCategory.text.startsWith('Proofreading-')) {
    const texts = file.fileCategory.text.split('Proofreading-');
    file.fileCategory.text = texts[1];

    return file;
  }

  if (file.fileCategory.text.startsWith('DTP-')) {
    const texts = file.fileCategory.text.split('DTP-');
    file.fileCategory.text = texts[1];

    return file;
  }


  if (file.fileCategory.text.startsWith('QA-')) {
    const texts = file.fileCategory.text.split('QA-');
    file.fileCategory.text = texts[1];

    return file;
  }
}

/**
 * Being used to remove the subtype of the file categories
 * @param {FileCategoryType[]} fileCategories
 * @returns {FileCategoryType[]}
 */
export function omitCategoriesSubType(fileCategories: FileCategoryType[]): FileCategoryType[] {
  fileCategories.forEach(fc => {
    if (fc.text.startsWith('Translation-')) {
      const texts = fc.text.split('Translation-');
      fc.text = texts[1];

      return;
    }

    if (fc.text.startsWith('Proofreading-')) {
      const texts = fc.text.split('Proofreading-');
      fc.text = texts[1];

      return;
    }

    if (fc.text.startsWith('DTP-')) {
      const texts = fc.text.split('DTP-');
      fc.text = texts[1];

      return;
    }


    if (fc.text.startsWith('QA-')) {
      const texts = fc.text.split('QA-');
      fc.text = texts[1];

      return;
    }
  });

  return fileCategories;
}
