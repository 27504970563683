import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorLog, ErrorLogService } from '../error-log.service';
import { AppContextService } from '../../shared/services/app-context.service';

@Component({
  selector: 'townip-error-dark',
  templateUrl: './error-dark.component.html',
  styleUrls: ['./error-dark.component.scss']
})
export class ErrorDarkComponent implements OnInit {

  public supportContact: string;

  public lastError: ErrorLog;

  public showError = false;

  constructor(private router: Router,
              private errorService: ErrorLogService,
              private appContext: AppContextService) { }

  public ngOnInit(): void {
    this.lastError = this.errorService.getError();
    this.supportContact = this.appContext.supportContact;
  }

  public close(): void {
    this.router.navigate(['/dashboard']);
  }

  public toggleError(): void {
    this.showError = !this.showError;
  }

}
