<!-- Use this component to debug other components -->
<div class="alert alert-warning text-center">You are using the debug component. Do not commit the changes here.</div>

<div class="d-flex">
  <div class="color-box green-1"></div>
  <div class="color-box green-2"></div>
  <div class="color-box green-accent"></div>
  <div class="color-box green-light"></div>
</div>

<h3 class="first">Basic</h3>

<p-paginator rows="10" totalRecords="100" pageLinkSize="3"></p-paginator>

<div class="container">
  <h2>The V2 Editor</h2>
  <div [innerHTML]="editorText"></div>
  <townip-editor-v2 [(ngModel)]="editorText"></townip-editor-v2>
</div>

