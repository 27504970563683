import { Injectable } from '@angular/core';
import { ConfigService } from '../../data-services/config.service';
import { Router } from '@angular/router';
import { LoggingService } from '../logging.service';

@Injectable()
export class PricingGuardService  {

  constructor(private loggingService: LoggingService,
              private configService: ConfigService,
              private router: Router) {
  }

  /**
   * Temporary fix so that a page refresh does not break the whole system
   * @returns {Promise<T>}
   */
  public canActivate(): Promise<boolean> {
    return new Promise((resolve) => {
      setTimeout(() => {
        const dataFetch: Promise<any>[] = [];
        dataFetch.push(this.configService.retrievePatentTypes());
        dataFetch.push(this.configService.retrieveLanguagePairs());
        dataFetch.push(this.configService.retrieveCurrencies());
        dataFetch.push(this.configService.retrieveProficiencyTypes());
        dataFetch.push(this.configService.retrieveUnitCostTypes());
        dataFetch.push(this.configService.retrieveServices());
        dataFetch.push(this.configService.retrieveProjectCategories());
        dataFetch.push(this.configService.retrieveAdditionalServices());
        dataFetch.push(this.configService.retrieveTranslationTypes());
        dataFetch.push(this.configService.retrieveTechnicalSpecialtyTypes());

        // When all the data finishes loading async
        Promise.all(dataFetch)
          .then(() => {
            resolve(true);
          })
          .catch(() => {
            console.error('Failed to load session instance (pricing)');
            resolve(false);
            // Navigate to the error page to give chance for recovery
            // (note: make sure  this is always after the resolve)
            this.router.navigate(['/error-404']);
          });
        return;
      });
    });
  }
}
