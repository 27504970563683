import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'townip-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {

  @Input()
  public text = 'Loading';

  @Input()
  public styleClass: string;

  constructor() { }

  public ngOnInit(): void {
  }

}
