import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderFiltersV2Component } from './header-filters-v2.component';
import { FormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';
import { DropdownModule } from 'primeng/dropdown';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PipesModule } from '../../pipes/pipes.module';
import { HeaderFiltersService } from './header-filters.service';
import { CalendarModule } from '../../../controls/calendar/calendar.module';

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    CalendarModule,
    MultiSelectModule,
    DropdownModule,
    TooltipModule,
    PipesModule,
    InputTextModule,
  ],
  declarations: [HeaderFiltersV2Component],
  exports: [HeaderFiltersV2Component],
  providers: [HeaderFiltersService]
})
export class HeaderFiltersV2Module { }
