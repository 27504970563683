import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TermsOfServiceModalComponent } from './terms-of-service-modal.component';
import { CheckboxModule } from 'primeng/checkbox';
import { FormsModule } from '@angular/forms';
import { TermsOfUseContentModule } from '../../terms-of-use-content/terms-of-use-content.module';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { StandardAlertModule } from '../../../annuities/shared/standard-alert/standard-alert.module';
import { PipesModule } from '../../pipes/pipes.module';
import { ModalModule } from '../../../controls/modal/modal.module';

@NgModule({
  imports: [
    CommonModule,
    ModalModule,
    ScrollPanelModule,
    CheckboxModule,
    FormsModule,
    TermsOfUseContentModule,
    StandardAlertModule,
    PipesModule,
  ],
  declarations: [TermsOfServiceModalComponent],
  exports: [TermsOfServiceModalComponent]
})
export class TermsOfServiceModalModule { }
