import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../services/user.service';

// If the user ONLY has the forecaster permission, they will be redirected to 403 page.
// This Guard MUST ALWAYS come AFTER the SessionGuard

@Injectable()
export class NoForecasterGuard  {

  constructor(private userService: UserService, private router: Router) {

  }

  public canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    if (this.userService.isForecasterOnly()) {
      this.router.navigate(['/error-403']);
      return false;
    }

    return true;
  }
}
