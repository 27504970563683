import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StartupPageComponent } from './startup-page.component';
import { LoadingModule } from '../../shared/components/loading/loading.module';

@NgModule({
  imports: [
    CommonModule,
    LoadingModule,
  ],
  declarations: [StartupPageComponent]
})
export class StartupPageModule { }
