import * as _ from 'underscore';
import { FieldType } from './field-type';
import { FilterFormOptions, FilterValueMap, InputType } from '../components/filter-form/filter-form';
import { SelectItem } from 'primeng/api';
import { FilterOperator, OPERATORS } from './filter-operators';

export interface TableHeaderFilter {
  colName: string;
  filters: FilterGroup[];
}

export interface FilterGroup {
  name: string;
  custom?: boolean;
  desc?: string;
  prevName?: string; // Used only for checking after filter edit, since we don't store unique id
  filters?: FilterParameters[];
  category?: string; // Used to categorize the filters, in-case we want them displayed on different sections of the page
  isOr?: boolean; // If or will be used on this operator
  override?: boolean;
  metadata?: any;
}

export interface FilterGroupSet {
  name: string;
  filters: FilterGroupSetEntry[];
  default?: boolean;
}

export interface FilterGroupSetEntry {
  name: string;
  autoSelect?: boolean;
}

export interface FilterParameters {
  name: string;
  field: string;
  collectionField?: string; // Used for array collections, 1 level only, no deep support yet
  type: FieldType;
  value: any;
  operator: string;
  fieldValueOverride?: any; // Use this value when comparing, instead of the value from parsing the field
}

export class Filter {

  public name: string;
  public field: string;
  public type: FieldType;
  public input: InputType;
  public operator: string;
  public selectionValues: any[];
  public active: boolean;
  public collectionField: string;
  public operatorsOverride: FilterOperator[];
  public value: string | any[];
  public minValue: number;
  public maxValue: number;

  constructor(options: FilterFormOptions) {
    this.name = options.name;
    this.field = options.field;
    this.type = options.fieldType;
    this.active = false;
    this.input = options.inputType;
    this.selectionValues = this.buildSelectionValues(options.values, options.valueMapping);
    this.collectionField = options.collectionField;
    this.minValue = 0;
    this.maxValue = 100000;

    // When we are overriding operators
    if (options.operators) {
      this.operatorsOverride = options.operators;
    } else {
      this.operatorsOverride = [];
    }

    this.operator = this.getDefaultOperator();
    this.resetValue();
  }

  private buildSelectionValues(selectionValues: any[], selectionMapping: FilterValueMap): any[] {
    if (selectionMapping === undefined) {
      return selectionValues;
    }

    // If it is a collection, return a mapping
    if (selectionMapping.isCollection) {
      return _.map(selectionValues, (item): SelectItem => {
        return {
          label: item[selectionMapping.label],
          value: item[selectionMapping.value]
        };
      });
    }

    // If it's just an array, return the item as the same mapping on label and v
    return _.map(selectionValues, (item): SelectItem => {
      return {
        label: item,
        value: item
      };
    });
  }

  private getDefaultOperator(): string {
    if (this.operatorsOverride.length > 0) {
      return this.operatorsOverride[0].value;
    }

    let operator;

    switch (this.type) {
    case FieldType.Collection:
      operator = 'contains';
      break;
    case FieldType.Array:
      operator = 'contains';
      break;
    case FieldType.Date:
      operator = 'between';
      break;
    default:
      operator = '=';
    }

    return operator;
  }

  public resetValue(): void {
    let value = null;
    // Handle date fields, they should be an array of date range date[0], date[1]
    if (this.type === FieldType.Date) {
      value = [null, null];
    }

    if (this.type === FieldType.Boolean) {
      value = 'false';
    }

    if (this.type === FieldType.Numeric && this.operator === OPERATORS.numeric.$between.value) {
      value = [0, 50000];
    }

    // All other values
    this.value = value;
  }

  public toParams(): FilterParameters {
    return {
      name: this.name,
      field: this.field,
      collectionField: this.collectionField,
      type: this.type,
      operator: this.operator,
      value: this.handleParamValue()
    };
  }

  /**
   * Convert the values into proper types based on the fieldtype
   * @returns {number}
   */
  private handleParamValue(): any {

    if (this.type === FieldType.Numeric && !_.isArray(this.value)) {
      return +this.value;
    }

    if (this.type === FieldType.Boolean) {
      return (this.value === 'true');
    }

    // If none satisfy
    return this.value;
  }

}
