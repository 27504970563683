/**
 * A promise version of angular's set timeout tick change detection;
 * Can be used on async/await operations
 */
export function waitForTick(): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    });
  });
}
