import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { SystemTermsService } from '../../data-services/system-terms.service';
import { TermsOfUse } from '../dto/terms-of-use';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'townip-terms-of-use-content',
  templateUrl: './terms-of-use-content.component.html',
  styleUrls: ['./terms-of-use-content.component.scss']
})
export class TermsOfUseContentComponent implements OnInit, OnChanges {

  @Input()
  public tosId: number;

  public tos: TermsOfUse;

  public tosContent: SafeHtml;

  constructor(
    private sysTermService: SystemTermsService,
    private sanitizer: DomSanitizer,
  ) { }

  public ngOnInit(): void {
  }

  public ngOnChanges(): void {
    this.tos = null;

    if (!this.tosId) {
      return;
    }

    this.sysTermService
      .get(this.tosId)
      .subscribe((data) => {
        this.tos = data;
        // Can bypass security here since the content will come internally
        this.tosContent = this.sanitizer.bypassSecurityTrustHtml(data.terms);
      });
  }

}
