import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { PortalUser } from '../../domain/user';
import { UserService } from '../../services/user.service';
import { Vendor } from '../../dto/vendor-management/vendor';
import { Company } from '../../dto/company';
import { VendorManagementService } from '../../../data-services/vendor-management.service';
import { CustomerManagementService } from '../../../data-services/customer-management.service';
import { SystemTerm } from '../../dto/system-term';
import { SystemTermsService } from '../../../data-services/system-terms.service';
import { TermsOfUse } from '../../dto/terms-of-use';
import { waitForTick } from '../../wait-for-tick';
import { ModalComponent } from '../../../controls/modal/modal.component';

@Component({
  selector: 'townip-terms-of-service-modal',
  templateUrl: './terms-of-service-modal.component.html',
  styleUrls: ['./terms-of-service-modal.component.scss']
})
export class TermsOfServiceModalComponent implements OnInit {

  @ViewChild(ModalComponent)
  public modal: ModalComponent;

  @Input()
  public term: SystemTerm;

  @Output()
  // NOTE: Only suppressing because legacy.
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  public onReject = new EventEmitter<boolean>();

  @Output()
  // NOTE: Only suppressing because legacy.
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  public onAccept = new EventEmitter<boolean>();

  public user: PortalUser;

  public vendor: Vendor;

  public company: Company;

  public accepted = false;

  public termsOfUse: TermsOfUse;

  constructor(private userService: UserService,
              private customerService: CustomerManagementService,
              private vendorService: VendorManagementService,
              private systemTermService: SystemTermsService,
  ) {
  }

  public ngOnInit(): void {

  }

  public async show(): Promise<void> {
    this.setData();
    this.modal.show();

    // We modify the z-index HARD. Because by default, all modal backdrops will be under the nav and toolbar.
    // In this particular case though, we need it to be above everything else.
    // The backdrop will be destroyed on close, so we don't need to restore the previous values.
    const backdrop = $('bs-modal-backdrop');
    backdrop.css('z-index', 999999);
    // Also the opacity.
    backdrop.css('opacity', 1);
    // Also the bg image
    backdrop.css('background-image', 'url(\'assets/images/nyc-bg.jpg\')');
    backdrop.css('background-size', 'cover');
    backdrop.css('background-position', 'center');
  }

  public hide(): void {
    this.modal.hide();
  }

  public accept(): void {
    if (this.user.userType === 'COMPANY') {
      // Check the status first before accepting. If it's already accepted, hide.
      this.customerService
        .getTermsOfServiceStatus(this.company.idfr)
        .subscribe((term) => {
          if (term.acknowledge || term.bypass) {
            this.hide();
          } else {
            this.acceptCompany();
          }
        });
    }

    if (this.user.userType === 'VENDOR') {
      // Check the status first before accepting. If it's already accepted, hide.
      this.vendorService
        .getTermsOfServiceStatus(this.vendor.id)
        .subscribe((term) => {
          if (term.acknowledge || term.bypass) {
            this.hide();
          } else {
            this.acceptVendor();
          }
        });
    }
  }

  private acceptCompany(): void {
    this.customerService
      .acceptTermsOfService(this.term.type, this.company.idfr)
      .subscribe(() => {
        this.hide();
        this.onAccept.emit(true);
      }, (error) => {
        // We can't hide if it errors out. Because they must accept it
        // this.hide();
      });
  }

  private acceptVendor(): void {
    this.vendorService
      .acceptTermsOfService(this.term.type, this.vendor.id)
      .subscribe(() => {
        this.hide();
      }, (error) => {
        // We can't hide if it errors out. Because they must accept it
        // this.hide();
      });
  }

  public decline(): void {
    // TOS has been declined
    this.hide();
    this.onReject.emit(true);
  }

  public save(): void {
    if (this.accepted) {
      this.accept();
      return;
    }

    this.decline();
  }

  private async setData(): Promise<void> {
    this.user = this.userService.getUser();

    if (this.user.userType === 'COMPANY') {
      this.company = this.customerService.getCompany();
    }

    if (this.user.userType === 'VENDOR') {
      this.vendor = this.vendorService.getVendor();
    }

    await waitForTick();

    this.systemTermService
      .get(this.term.termsOfUseId)
      .subscribe((terms) => {
        this.termsOfUse = terms;
      });
  }
}
