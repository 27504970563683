import { AnnuityActionHelper } from '../../../annuities/lib/annuity-action-helper';

enum Action {
  Cancel = 'cancel',
  Acknowledge = 'acknowledge',
  Fulfill = 'fulfill',
  VerifyReceipt = 'verifyReceipt',
  RequestMoreInformation = 'requestMoreInformation',
  RequestFromClient = 'requestFromClient',
  Respond = 'respond',
  AcceptResponse = 'acceptResponse',
  Reassign = 'reassign',
  AddReferenceNumber = 'addReferenceNumber',
  EditReferenceNumber = 'editReferenceNumber',
  Hold = 'hold',
  ReportIssue = 'reportIssue',
  ResolveIssue = 'resolveIssue',
  SendBackToVendor = 'sendBackToVendor',
  Resume = 'resume',
  CreateAdditionalInvoice = 'createAdditionalInvoice',
  SendPaymentInstructions = 'sendPaymentInstructions',
  ReleaseReceipt = 'releaseReceipt',
  BypassPaymentRequired = 'bypassPaymentRequired',
  EditPartialInstruction = 'editPartialInstruction',
  StartQualityControl = 'startQualityControl',
  AssignQualityControl = 'assignQualityControl',
  ReleaseQualityControl = 'releaseQualityControl',
  CompleteRework = 'completeRework',
  UploadFurtherFiles = 'uploadFurtherFiles',
  CompleteQualityControl = 'completeQualityControl',
  ReAssignQualityControl = 'reAssignQualityControl',
  PriceFreeze = 'priceFreeze',
  PriceUnfreeze = 'unfreezePrice',
}

enum AssignmentCategory {
  Client = 'CLIENT',
  Internal = 'INTERNAL',
  Vendor = 'VENDOR',
}

interface WorkflowAction {
  action: Action;
  assignmentCategory: AssignmentCategory;
  creationDate: number;
  id: number;
  lastModified: number;
  readableAction: string;
}

interface CategorizedWorkflowActions {
  internal?: WorkflowAction[];
  vendor?: WorkflowAction[];
  company?: WorkflowAction[];
  all?: WorkflowAction[];
  helper?: AnnuityActionHelper;
}

export { WorkflowAction, AssignmentCategory, Action, CategorizedWorkflowActions };
