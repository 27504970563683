import { Component, EventEmitter, Input, OnChanges, OnInit, OnDestroy, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { PortalUser } from '../../../domain/user';
import { BaseMessage } from '../../../dto/messaging/base-message';
import { MessageUser } from '../../../dto/messaging/message-user';
import { MessageService } from 'primeng/api';
import { removeImageTag } from '../../../prime-editor.util';
import { Subscription } from 'rxjs';
import { Message } from '../../../dto/messaging/message';
import { FileKeyPair } from '../../../dto/file-key';
import { DocumentService } from '../../../../data-services/document.service';
import { MessageFileUploadComponent } from '../message-file-upload/message-file-upload.component';
import { ModalComponent } from '../../../../controls/modal/modal.component';

@Component({
  selector: 'townip-message-form',
  templateUrl: './message-form.component.html',
  styleUrls: ['./message-form.component.scss']
})
export class MessageFormComponent implements OnInit, OnChanges, OnDestroy {

  @ViewChild(MessageFileUploadComponent, { static: true })
  public msgFileUpload: MessageFileUploadComponent;

  @ViewChild(ModalComponent, { static: true })
  public attachmentModal: ModalComponent;

  @Input() public user: PortalUser;
  @Input() public contacts: MessageUser[];
  @Input() public messageSubject: string;
  @Input() public showMessageSubject: boolean;

  // If will be used at modal
  @Input() public popup = false;

  @Output()
  // NOTE: Only suppressing because legacy.
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  public onSendMessage: EventEmitter<BaseMessage>;

  @Output()
  public attachments: EventEmitter<any[]> = new EventEmitter<any[]>();

  @Output()
  // NOTE: Only suppressing because legacy.
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  public onCancel: EventEmitter<any>;
  public messageForm: UntypedFormGroup;
  public isInit: boolean;
  public disabledSubject: boolean;

  public messageBodySub: Subscription;
  public removeImageTag = removeImageTag;

  public uploadedFiles: FileKeyPair[];

  // This is a list of filekeys that should be removed from the backend.
  // This is used so that we don't have files on the backend that are created but
  // are not referenced to anything.
  public uploadedFilesToDelete: string[];

  constructor(private growlService: MessageService,
              private documentService: DocumentService) {
    this.onSendMessage = new EventEmitter<BaseMessage>();
    this.onCancel = new EventEmitter<any>();
    this.showMessageSubject = true;
    this.uploadedFiles = [];
    this.uploadedFilesToDelete = [];
  }

  public ngOnInit(): void {
    this.formGroupInit();
    this.isInit = true;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    // console.log('Changes! ', this.contacts);
    if (changes.messageSubject && this.messageForm && this.messageForm.controls) {
      this.messageForm.controls.messageSubject.setValue(this.messageSubject);
    }
  }

  public ngOnDestroy(): void {
    this.updateFiles(true);

    if (this.messageBodySub) {
      this.messageBodySub.unsubscribe();
    }
  }

  public sendReply(): void {
    const messageForm = this.messageForm.getRawValue();
    const today = new Date();
    const message: Message = {
      creationTime: today.getTime(),
      creator: {
        id: this.user.id,
        firstName: this.user.firstName,
        lastName: this.user.lastName,
        organization: this.user.organizationName
      },
      messageBody: messageForm.messageBody,
      messageSubject: messageForm.messageSubject,
      messageType: 'GENERAL_MESSAGE',
      fileAttachments: this.uploadedFiles
    };

    this.updateFiles(false);
    this.uploadedFiles = [];
    this.onSendMessage.emit(message);
    this.formGroupInit();
  }

  public cancel(): void {
    this.logMessageState();
    this.messageForm.reset();
    this.updateFiles(true);
    this.uploadedFiles = [];
    this.onCancel.emit(null);
    this.msgFileUpload.reset();
  }

  public logMessageState(): void {
  }

  public onConfirmAttachments(): void {
    this.attachments.emit(this.uploadedFiles);
    this.uploadedFiles = [];
    this.attachmentModal.hide();
  }

  public discardAttachments(): void {
    this.updateFiles(true);
    this.uploadedFiles = [];
    this.msgFileUpload.reset();
    this.attachments.emit(null);
    this.attachmentModal.hide();
  }

  public showAttachmentModal(): void {
    this.msgFileUpload.reset();
    this.attachmentModal.show();
  }

  private formGroupInit(): void {
    this.disabledSubject = this.messageSubject != null;
    this.messageForm = new UntypedFormGroup({
      messageBody: new UntypedFormControl(null, Validators.required),
      messageSubject: new UntypedFormControl({ value: this.messageSubject, disabled: this.disabledSubject }, Validators.required)
    });

    this.msgBodyValueChangesSetup();
  }

  public updateFiles(allFiles: boolean): void {
    if (allFiles) {
      this.uploadedFiles.forEach(file => this.uploadedFilesToDelete.push(file.filekey));
    }
    if (this.uploadedFilesToDelete.length > 0) {
      this.documentService
        .deleteFiles(this.uploadedFilesToDelete)
        .then();
    }
    this.uploadedFilesToDelete = [];
  }

  private msgBodyValueChangesSetup(): void {
    this.messageBodySub = this.messageForm.controls.messageBody.valueChanges.subscribe((data) => {
      const newValue = this.removeImageTag(data);
      if (newValue === data) {
        this.messageBodySub.unsubscribe();
        this.msgBodyValueChangesSetup();
        return;
      }
      this.messageForm.controls.messageBody.patchValue(newValue);
    });
  }
}
