import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { DeliveryEmail } from '../shared/dto/filing-and-translation-delivery/delivery-email';
import { DeliveryEmailRecipient } from '../shared/dto/filing-and-translation-delivery/delivery-email-recipient';
import { EmailPreviewResponse } from '../shared/dto/filing-and-translation-delivery/email-preview-response';
import { NationalPhaseFilingInfo } from '../shared/dto/filing-and-translation-delivery/national-phase-filing-info';
import { VendorAssignmentStatus } from '../shared/dto/filing-and-translation-delivery/vendor-assignment-status';
import { Task } from '../shared/dto/projects/task';
import { WorkflowActions } from '../shared/dto/projects/workflow-actions';
import { Observable, Subject, Subscription, throwError } from 'rxjs';
import { RequestService } from '../shared/services/request-service';
import { UserService } from '../shared/services/user.service';
import { UpdateTaskAction } from '../shared/dto/projects/update-task-action';
import { TasksOverviewV2Response } from '../tasks/tasks-overview-v2/tasks-overview-v2-response';
import { ProjectsOverviewService } from './projects-overview.service';
import { OrderService } from './order.service';
import { MessagingService } from '../shared/services/messaging.service';
import { LoggingService } from '../shared/logging.service';
import { AcceptTask } from '../shared/components/service-request/domain/accept-task';
import { WorkflowService } from './workflow-service';
import { TaskFiles } from '../shared/dto/projects/task-files';
import { AuthService } from '../security/auth.service';
import { Message } from '../shared/dto/messaging/message';
import { WorkflowActionRequest } from '../shared/dto/projects/workflow-action-request';
import { StatusUpdateEvent } from '../shared/dto/status-update-event';
import { WorkflowTaskCost } from '../shared/dto/projects/workflow-task-cost';
import { DeliveryContacts } from '../shared/dto/delivery-contacts';
import { ApiQueryOptions } from '../shared/api-query-params';
import { DocumentService } from './document.service';
import { AlertMessage } from '../shared/dto/messaging/alert-message';
import { MessageUser } from '../shared/dto/messaging/message-user';
import { PaginatedColumnValueIndex, PaginatedData, PaginateObject } from '../shared/dto/paginated-data';
import { catchError, finalize, map, tap } from 'rxjs/operators';
import * as _ from 'underscore';
import { FileKeyPair } from '../shared/dto/file-key';
import { ViewTaskResponse } from '../shared/components/task-action/view-task-response';
import { FilingInformation } from '../shared/dto/projects/filing-information';
import { HttpRequestErrorInterceptorMessages } from '../shared/interceptors/http-request-error-interceptor-messages';
import { ValidationDeadlines } from '../shared/dto/projects/validation-deadlines';
import { QualityResults } from '../shared/dto/quality-results/quality-results';
import { ImpactedTaskResponse } from '../shared/dto/projects/impacted-task-response';
import { ImpactedTaskRequest } from '../shared/dto/projects/impacted-task-request';
import { CompanyServiceItems } from '../settings-v2/company-activity/company-vendor-usage/vendor-usage-filter/company-service-items';
import { Service } from '../shared/dto/system-config/service';
import { NoteMessage } from '../shared/dto/messaging/note-message';
import { isNullOrUndefined } from '../shared/utils/is-null-or-undefined';
import { TableColumn } from '../shared/table-column';
import { isNullOrUndefinedOrBlank } from '../shared/utils/is-null-or-undefined-or-blank';
import { OrderOrganizationReference } from '../shared/dto/projects/order-organization-reference';
import { VerifyOfficialFees } from '../shared/dto/projects/verify-official-fees';
import { OfficialFeeVerificationType } from '../shared/enums/official-fee-verification-type';
import { OfficialFeeTotalType } from '../shared/dto/projects/official-fee-total-type';
import { OfficialFeeCalculateTotalResponse } from '../shared/dto/projects/official-fee-calculate-total-response';
import { OfficialFeeVerificationLineItem } from '../shared/dto/projects/official-fee-verification-line-item';
import { ApproveOfficialFeesModel } from '../shared/dto/projects/approve-official-fees-model';

@Injectable()
export class TasksOverviewService {

  // push out task's project id(s)
  public taskAlert = new Subject<number[]>();

  public taskUpdate = new EventEmitter<StatusUpdateEvent>(); // Subscribe for update notification

  private taskSubscription: Subscription;

  public static isPartial(task: Task): boolean {
    if (!task.assignee.parentTask && task.assignee.splitTask) {
      return true;
    }

    if (task.assignee.startPage >= 0 && task.assignee.endPage > 0) {
      return true;
    }

    return false;
  }

  constructor(private httpClient: HttpClient,
              private authService: AuthService,
              private loggingService: LoggingService,
              private requestService: RequestService,
              private projectService: ProjectsOverviewService,
              private orderService: OrderService,
              private userService: UserService,
              private messagingService: MessagingService,
              private workflowService: WorkflowService,
              private documentService: DocumentService,
              private httpRequestErrorMsg: HttpRequestErrorInterceptorMessages,
  ) {
    this.authService.connectedEmitter.subscribe(connected => {
      if (!connected) {
        this.logout();
      }
    });

    // Subscribe to the alerts so that we can update the data when a new alert comes in (only task)
    this.messagingService.alertEmitter.subscribe((alert) => {
      if (alert.scope === 'TASK') {
        this.alertTaskChange(isNullOrUndefined(alert.parentScopeId) ? null : [alert.parentScopeId]);
      } else if (alert.scope === 'PROJECT') {
        this.alertTaskChange(isNullOrUndefined(alert.scopeId) ? null : [alert.scopeId]);
      }
    });
  }

  public alertTaskChange(projectIds: number[]): void {

    this.taskAlert.next(projectIds);

    this.projectService.taskChanges.next(projectIds);

  }

  private logout(): void {
    this.cleanupTaskSubscription();
  }

  private cleanupTaskSubscription(): void {
    if (this.taskSubscription) {
      this.loggingService.info('Cleaning up task subscription.');
      this.taskSubscription.unsubscribe();
    }
  }

  public downloadVendorFiles(groupTasks: Task[]): void {
    this.getTaskFilesForTasks(groupTasks)
      .then(workflowTaskFiles => {
        const fileKeys: string[] = [];
        workflowTaskFiles.forEach(workflowTaskFile => {
          workflowTaskFile.taskFiles.forEach(taskFile => {
            // 25925 - Vendor can now see ALL files associated with it when accepting a task
            fileKeys.push(taskFile.filekey);
          });
        });
        let fileName = '';
        if (groupTasks[0].projectOverview && groupTasks[0].projectOverview.projectNumber) {
          fileName = 'Project-' + groupTasks[0].projectOverview.projectNumber;
        }
        const queryOptions: ApiQueryOptions = {
          queryParams: {
            theFileName: fileName,
            organizeFiles: true
          }
        };
        this.documentService.getFiles(fileKeys, queryOptions);
      });
  }

  public getTasksV2(request: PaginatedData<TasksOverviewV2Response>):
    Observable<PaginatedData<TasksOverviewV2Response>> {
    const url = '/api/v2/tasks';
    const headers = this.requestService.buildHttpHeaders();

    // const requestSample = {
    //   columnValues: [
    //     { field: 'customerLite.firstName' },
    //     { field: 'customerLite.lastName' },
    //   ],
    //   filter: '',
    //   scope: 'MINE',
    //   page: {
    //     number: 1,
    //     size: 20,
    //     sort: [],
    //   }
    // };

    return this.httpClient
      .post<any>(url, request.toObject(), { headers: headers })
      .pipe(
        // Update the given pagination object with values from the response.
        map((response: PaginateObject<TasksOverviewV2Response>) => request.update(response))
      );
  }

  public getTaskById(taskId: number): Observable<Task> {
    return this.getUpdatedTaskById(taskId);
  }

  public getUpdatedTaskById(taskId: number): Observable<Task> {
    const url = '/api/tasks/' + taskId;
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient.get<Task>(url, { headers: headers });
  }

  public getDeliveryContacts(taskId: number): Observable<DeliveryContacts> {
    const url = '/api/tasks/' + taskId + '/deliveryContacts';
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.get<DeliveryContacts>(url, { headers: headers });
  }

  public updateTask(task: Task, workflowAction: WorkflowActions): Observable<boolean> {
    const taskId = task.taskAssignmentId;
    const url = '/api/tasks/' + taskId;

    // A quick hack for us to actually monitor the request without affecting all other consumers.
    // This will still trigger the rest of the stuff inside client.post
    const returnStatus = new Subject<boolean>();
    this.httpClient.post<any>(url, workflowAction, { headers: this.requestService.buildHttpHeaders() })
      .subscribe(() => {
      },
      (err: any) => {
        this.alertTaskChange(isNullOrUndefined(task.projectOverview) ? null : [task.projectOverview.projectId]);
        this.taskUpdate.emit({
          updateId: taskId,
          status: false,
          projectId: isNullOrUndefined(task.projectOverview) ? null : task.projectOverview.projectId
        });

        returnStatus.error(err);
      }, () => {
        this.alertTaskChange(isNullOrUndefined(task.projectOverview) ? null : [task.projectOverview.projectId]);
        this.taskUpdate.emit({
          updateId: taskId,
          status: true,
          projectId: isNullOrUndefined(task.projectOverview) ? null : task.projectOverview.projectId
        });

        returnStatus.next(true);
        // Need to complete so we don't need to unsubscribe
        returnStatus.complete();
      });

    // Return the quick hack subject
    return returnStatus;
  }

  public updateInternalDateTask(taskId: number, workflowAction: WorkflowActions): Promise<boolean> {
    const url = '/api/tasks/' + taskId;

    return this.httpClient.post<any>(url, workflowAction, { headers: this.requestService.buildHttpHeaders() })
      .toPromise();
  }

  public isDocumentVerified(task: Task, workflowAction: WorkflowActions): Message {
    let result: Message = null;
    const docsVerified = workflowAction.workflowActionRequests.find((workflow) =>
      workflow.type === 'boolean' && workflow.title === 'docsVerified');
    const additionalNotes = workflowAction.workflowActionRequests.find((workflow) =>
      workflow.type === 'note' && workflow.title === 'additionalNotes');

    if ((docsVerified && !docsVerified.requestResponse) && (additionalNotes && additionalNotes.requestResponse)) {
      result = this.mapMessageForInvalidDocument(task, additionalNotes);
    }

    return result;
  }

  public invalidDocumentNotification(message: Message): void {
    const response = this.messagingService.postMessage(message.creator.id, message);
    response.toPromise()
      .then(r => {

      });
  }

  private mapMessageForInvalidDocument(task: Task, request: WorkflowActionRequest): Message {
    const today: Date = new Date();
    const user = this.userService.getUser();

    return {
      creator: {
        id: user.id,
        firstName: user.firstName,
        lastName: user.lastName,
        organization: user.organizationName
      },
      messageRecipients: [{
        user: {
          id: task.projectOverview.assignedPM.user.id,
          firstName: task.projectOverview.assignedPM.user.firstName,
          lastName: task.projectOverview.assignedPM.user.lastName
        }
      }],
      messageSubject: `${task.assignee.countries[0].name} Documents have been declined by the filing vendor.`,
      messageBody: request.requestResponse.messageBody,
      creationTime: today.getTime(),
      messageType: 'GENERAL_MESSAGE',
      scope: 'TASK',
      parentScopeId: task.projectOverview.projectId,
      scopeId: task.taskAssignmentId,
      scopeName: task.assignee.taskId,
    };
  }

  public getTaskForEstimate(estimateId: number, bypassError: boolean = false): Promise<Task> {
    const url = '/api/tasks/estimate/' + estimateId;

    if (bypassError) {
      this.httpRequestErrorMsg.add({ url, bypass: true });
    }

    return this.httpClient.get<Task>(url, { headers: this.requestService.buildHttpHeaders() })
      .toPromise();
  }

  public updateTasksObservable(actions: UpdateTaskAction[]): Observable<void> {
    const url = '/api/tasks/update';

    return this.httpClient.post<void>(url, actions, { headers: this.requestService.buildHttpHeaders() });
  }

  public updateTasks(actions: UpdateTaskAction[]): Promise<void> {
    let success = true;
    const projectIds =
      _.uniq(
        _.map(
          actions
            .filter(action => !isNullOrUndefined(action.projectId)),
          (action) => action.projectId),
        'projectId');
    return this.updateTasksObservable(actions)
      .pipe(
        catchError(e => {
          success = false;
          return throwError(e);
        }),
        // Execute on both error and success, based on previous logic.
        finalize(() => {
          this.alertTaskChange(projectIds);
          if (projectIds.length === 1) {
            this.taskUpdate.emit({
              updateId: null,
              status: success,
              projectId: projectIds[0],
            });
          } else {
            this.taskUpdate.emit();
          }
        })
      )
      .toPromise();
  }

  public updateProjectTask(projectId: number, workflowAction: WorkflowActions): void {
    const url = '/api/tasks/project/' + projectId;

    this.projectService.projectLoadingEmitter.emit(true);
    this.httpClient.post(url, workflowAction, { headers: this.requestService.buildHttpHeaders() })
      .subscribe(() => {
      },
      (err: any) => {
      }, () => {
        if (workflowAction.actionType === 'cancel' || workflowAction.actionType === 'hold' ||
            workflowAction.actionType === 'resume') {
          this.projectService.getProjectById(projectId)
            .toPromise()
            .then(project => {
              this.projectService.projectLoadingEmitter.emit(false);
              this.projectService.projectChangedEmitter.emit(project);
            }, (err: any) => {
              this.loggingService.info('Error attempting to update the project on task change', err);
            });
        }
      });
  }

  public updateProjectComponentTask(projectId: number,
    documentId: number,
    componentId: number,
    workflowAction: WorkflowActions): void {
    const url = '/api/tasks/project/' + projectId + '/document/' + documentId + '/component/' + componentId;
    this.httpClient.post(url, workflowAction, { headers: this.requestService.buildHttpHeaders() })
      .subscribe(() => {
      },
      (err: any) => {
        this.alertTaskChange([projectId]);
        this.taskUpdate.emit();
      }, () => {
        this.orderService.getOrderSummary(projectId)
          .toPromise()
          .then(
            summary => this.orderService.emitOrderSummary(summary));
        this.alertTaskChange([projectId]);
        this.taskUpdate.emit();
      });
  }

  public updateTasksResponse(updateTasks: AcceptTask[], actionTitle: string, jobResponse: any): void {
    if (updateTasks) {
      // The normal single-task accept from task actions.
      updateTasks.forEach(at => {
        this.updateTaskResponse(at, actionTitle, jobResponse);
      });
    }
  }

  public updateTaskResponse(updateTask: AcceptTask, actionTitle: string, jobResponse: any): void {
    if (updateTask) {
      const acceptAction = updateTask.action.workflowActionRequests.find(war => war.title === actionTitle);
      if (acceptAction) {
        acceptAction.requestResponse = jobResponse;
      }
    }
  }

  public retrieveTaskRejectNote(taskId: number): Observable<NoteMessage[]> {
    return this.messagingService.retrieveRejectNoteByTaskId(taskId);
  }

  public resolveWorkflowActions(tasks: Task[]): void {
    if (tasks) {
      tasks.forEach(t => {
        if (t.workflowActions) {
          t.resolvedWorkflowActions = [];

          t.workflowActions.forEach(waId => {
            if (isNullOrUndefined(this.workflowService.workflowActions)) {
              return;
            }
            const foundWorkflowAction = this.workflowService.workflowActions.find(wa => wa.id === waId);
            if (foundWorkflowAction) {
              t.resolvedWorkflowActions.push(foundWorkflowAction);
            }
          });
        }
      });
    }
  }

  public getTaskFilesForTasks(tasks: Task[]): Promise<TaskFiles[]> {
    if (!tasks) {
      return Promise.resolve([]);
    }

    const taskIds: number[] = [];
    tasks.forEach(t => taskIds.push(t.taskAssignmentId));

    const url = '/api/tasks/files';

    return this.httpClient.post<TaskFiles[]>(url, taskIds, { headers: this.requestService.buildHttpHeaders() })
      .toPromise();
  }

  public getFilingConfirmationFilesForTask(taskId: number): Promise<TaskFiles> {
    if (!taskId) {
      return Promise.resolve(null);
    }

    const url = `/api/tasks/${taskId}/associated-filing-confirmation-files`;

    return this.httpClient.get<TaskFiles>(url, { headers: this.requestService.buildHttpHeaders() })
      .toPromise();
  }

  public addTaskFiles(taskId: number, supplementalDocs: FileKeyPair[]): Promise<FileKeyPair[]> {
    const url = `/api/tasks/${taskId}/files`;

    return this.httpClient.post<FileKeyPair[]>(url, supplementalDocs, { headers: this.requestService.buildHttpHeaders() })
      .toPromise();
  }

  public getUserAssociatedTaskFiles(taskId: number): Promise<TaskFiles> {
    const url = `/api/tasks/${taskId}/associate/files`;

    return this.httpClient.get<TaskFiles>(url, { headers: this.requestService.buildHttpHeaders() })
      .toPromise();
  }

  public getTaskCostsByTaskId(taskId: number): Promise<WorkflowTaskCost> {
    const url = '/api/tasks/' + taskId + '/costs';

    return this.httpClient.get<WorkflowTaskCost>(url, { headers: this.requestService.buildHttpHeaders() })
      .toPromise();
  }

  public getServiceManagersForTask(taskAssignmentId: number): Promise<MessageUser[]> {
    const url = '/api/tasks/' + taskAssignmentId + '/vendorServiceManagers';

    return this.httpClient.get<MessageUser[]>(url, { headers: this.requestService.buildHttpHeaders() })
      .toPromise();
  }

  public getTaskCostsByProjectId(projectId: number): Promise<WorkflowTaskCost[]> {
    const url = '/api/tasks/project/' + projectId + '/costs';

    return this.httpClient.get<WorkflowTaskCost[]>(url, { headers: this.requestService.buildHttpHeaders() })
      .toPromise();
  }

  public getViewTaskResponse(taskId: number): Observable<ViewTaskResponse> {
    const url = `/api/task/${taskId}/completed/view/response`;
    return this.httpClient.get<ViewTaskResponse>(url, { headers: this.requestService.buildHttpHeaders() });
  }

  public getQualityResults(taskId: number): Observable<QualityResults> {
    const url = `/api/task/${taskId}/quality-results`;
    return this.httpClient.get<QualityResults>(url, { headers: this.requestService.buildHttpHeaders() });
  }

  public getTaskFilingDelivery(taskId: number): Observable<FilingInformation> {
    const url = `/api/task/${taskId}/filing-delivery-contact`;
    return this.httpClient.get<FilingInformation>(url, { headers: this.requestService.buildHttpHeaders() });
  }

  public generateDeliveryEmail(taskId: number, hasFile?: boolean): Promise<DeliveryEmail[]> {
    const headers = this.requestService.buildHttpHeaders();

    const requestBody = {
      taskAssignmentId: taskId,
      hasFile: hasFile
    };

    return this.httpClient
      .post<DeliveryEmail[]>('/api/tasks/generate-delivery-email', requestBody, { headers })
      .toPromise();
  }

  public getDeliveryRecipients(taskId: number): Promise<DeliveryEmailRecipient[]> {
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient
      .get<DeliveryEmailRecipient[]>(`/api/tasks/${taskId}/delivery-contacts`, { headers })
      .toPromise();
  }

  public getEmailPreview(taskId: number, email: DeliveryEmail): Promise<EmailPreviewResponse> {
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient
      .post<EmailPreviewResponse>(`/api/tasks/${taskId}/email-preview`, email, { headers })
      .toPromise();
  }

  public getNationalPhaseFilingInfo(taskId: number): Promise<NationalPhaseFilingInfo> {
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient
      .get<NationalPhaseFilingInfo>(`/api/tasks/${taskId}/national-phase-filing-info`, { headers })
      .toPromise();
  }

  public validateVendorAssignments(taskId: number): Promise<VendorAssignmentStatus> {
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient
      .get<VendorAssignmentStatus>(`/api/tasks/${taskId}/validate-vendor-assignments`, { headers })
      .toPromise();
  }

  public putFilingDeliveryContact(taskId: number, contact: FilingInformation): Promise<FilingInformation> {
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient
      .put<FilingInformation>(`/api/tasks/${taskId}/filing-delivery-contact`, contact, { headers })
      .toPromise();
  }

  private isBulkAssignment(alert: AlertMessage): boolean {
    return (alert.action === 'bulkRemoveAssignment' ||
      (alert.action === 'bulkAssign' && alert.taskDefinition === 'reassignJob') ||
      (alert.action === 'bulkAssignment' && alert.taskDefinition === 'userAccept'));
  }

  public getRequestedTasks(): Promise<Task[]> {
    const url = '/api/tasks/requested-vendor-tasks';

    return this.httpClient.get<Task[]>(url, { headers: this.requestService.buildHttpHeaders() })
      .toPromise();
  }

  public getCalculatedValidationDate(orderId: number,
    grantPublicationDate: Date): Promise<ValidationDeadlines> {
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient
      .get<ValidationDeadlines>(`/api/order/${orderId}/calculate-validation-dates/${grantPublicationDate}`,
        { headers })
      .toPromise();
  }

  public getImpactedTasks(request: ImpactedTaskRequest): Observable<ImpactedTaskResponse> {
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient
      .post<ImpactedTaskResponse>('/api/task/impacted-tasks', request, { headers });
  }

  public getServicesUsedByCompany(companyId: number): Promise<Service[]> {
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient
      .get<Service[]>(`/api/task/service/company/${companyId}`, { headers })
      .toPromise();
  }

  public getServiceItemsByCompanyAndService(serviceIds: number[],
    companyId: number): Promise<CompanyServiceItems> {
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient
      .post<CompanyServiceItems>(`/api/task/service/items/company/${companyId}`, serviceIds, { headers })
      .toPromise();
  }

  public retrieveEmailsForTask(task: Task): Promise<DeliveryEmail[]> {
    const taskId = task.taskAssignmentId;
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient
      .get<DeliveryEmail[]>(`/api/task/${taskId}/emails`,
        { headers })
      .toPromise();
  }

  public exportTasks(request: PaginatedData<TasksOverviewV2Response>, columns: TableColumn[]): Observable<HttpResponse<Blob>> {
    const url = '/api/task/paginated/export';
    const headers = this.requestService.buildHttpHeaders();

    // Copy the request so we don't mutate the original
    const data = request.toObject();
    data.page.number = 0; // Prevents issue where incorrect import items when on page 2.
    data.page.size = data.page.totalElements;

    data.columnValues = columns.map((col) => {
      // Special FE fields that needs to be mapped backend
      if (col.field === 'countriesParsed') {
        return { field: 'countryIds', readableField: col.header } as PaginatedColumnValueIndex;
      }

      if (col.field === 'assigneeParsed') {
        return { field: 'assigneeAndOrganization', readableField: col.header } as PaginatedColumnValueIndex;
      }

      if (col.field === 'startDateParsed') {
        return { field: 'startDate', readableField: col.header } as PaginatedColumnValueIndex;
      }

      if (col.field === 'dueDateParsed') {
        return { field: 'dueDate', readableField: col.header } as PaginatedColumnValueIndex;
      }

      if (col.field === 'deliveryDeadlineParsed') {
        return { field: 'deliveryDeadline', readableField: col.header } as PaginatedColumnValueIndex;
      }

      if (col.field === 'client') {
        return { field: 'clientNameAndCompanyName', readableField: col.header } as PaginatedColumnValueIndex;
      }

      if (col.field === 'taskTypeParsed') {
        return { field: 'taskType', readableField: col.header } as PaginatedColumnValueIndex;
      }

      if (col.field === 'filingDeadlineParsed') {
        return { field: 'filingDeadline', readableField: col.header } as PaginatedColumnValueIndex;
      }

      if (col.field === 'completionDateParsed') {
        return { field: 'completionDate', readableField: col.header } as PaginatedColumnValueIndex;
      }

      if (col.field === 'assignmentDateParsed') {
        return { field: 'assignmentDate', readableField: col.header } as PaginatedColumnValueIndex;
      }

      return { field: col.field, readableField: col.header } as PaginatedColumnValueIndex;
    });

    // Cleanup because of FE_specific columns?
    data.columnValues = data.columnValues.filter((col) => {
      if (isNullOrUndefinedOrBlank(col.readableField)) {
        return false;
      }

      // Other FE-speific handling here
      return true;
    });

    return this.httpClient
      .post(url, data, { headers: headers, observe: 'response', responseType: 'blob' })
      .pipe(
        // Update the given pagination object with values from the response.
        tap((resp) => {
          this.documentService.saveFile(resp);
        })
      );
  }

  public getVendorReferenceNumbers(taskId: number): Observable<OrderOrganizationReference[]> {
    const url = `/api/tasks/${taskId}/vendor-reference-numbers`;
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.get<OrderOrganizationReference[]>(url, { headers });
  }

  public getOfficialFeeVerification(taskId: number): Observable<VerifyOfficialFees> {
    const url = `/api/task/${taskId}/verify-official-fees`;
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.get<VerifyOfficialFees>(url, { headers });
  }

  public getOfficialFeeApproval(taskId: number): Observable<ApproveOfficialFeesModel> {
    const url = `/api/task/${taskId}/approve-official-fee-updates`;
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.get<ApproveOfficialFeesModel>(url, { headers });
  }

  public calculateVendorFeeTotal(
    data: OfficialFeeVerificationLineItem[],
    type: OfficialFeeTotalType,
  ): Observable<OfficialFeeCalculateTotalResponse> {
    const url = `/api/task/calculate-official-fee-verification-total/${type}`;
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.post<OfficialFeeCalculateTotalResponse>(url, data, { headers });
  }

}
