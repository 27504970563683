import { Component, TemplateRef, ViewChild } from '@angular/core';
import { GlobalModalService } from './global-modal.service';
import { ModalComponent } from '../modal/modal.component';
import { firstValueFrom, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { GlobalModalConfirmOptions } from './global-modal-options';
import { cloneDeep } from '../../shared/clone-deep';
import { isNullOrUndefined } from '../../shared/utils/is-null-or-undefined';

@Component({
  selector: 'townip-global-modal',
  templateUrl: './global-modal.component.html',
  styleUrl: './global-modal.component.scss'
})
export class GlobalModalComponent {

  @ViewChild(ModalComponent)
  public modal: ModalComponent;

  @ViewChild('confirmBody')
  public confirmBody: TemplateRef<any>;

  @ViewChild('confirmAction')
  public confirmAction: TemplateRef<any>;

  public activeBody: TemplateRef<any>;

  public activeAction: TemplateRef<any>;

  public confirmResponse = new Subject<boolean>();

  public confirmData: GlobalModalConfirmOptions;

  public heading: string;

  constructor(private globalModal: GlobalModalService) {
    this.globalModal.setInstance(this);
  }

  public async showConfirm(opts: GlobalModalConfirmOptions): Promise<boolean> {
    this.heading = 'Confirmation';
    this.confirmData = cloneDeep(opts);
    this.activeBody = this.confirmBody;
    this.activeAction = this.confirmAction;
    this.modal.show();

    if (opts.heading) {
      this.heading = opts.heading;
    }

    // Note: in the future if we need default params,
    // might probably need to just copy->override object from defaults
    if (isNullOrUndefined(opts.noVisible)) {
      this.confirmData.noVisible = true;
    }

    return firstValueFrom(
      this.confirmResponse
        .pipe(
          tap(() => {
            this.modal.hide();
          })
        )
    );
  }

  public cleanup(): void {
    // Possibly prevent memory leak if the user just hit the "x" on upper right
    // while the implementing component waits for response. Closing is automatically NO.
    this.confirmResponse.next(false);

    this.activeBody = null;
    this.activeAction = null;
    this.confirmData = null;
  }
}
