<townip-modal #modal (onHide)="clear()" size="lg" heading="Warning - Unsaved Changes">
  <townip-modal-body>
    <townip-standard-alert type="danger" mode="lite" iconAlignment="top">
      <b>Navigating away from this page will lose any changes.</b><br>Are you sure you wish to continue?
    </townip-standard-alert>
  </townip-modal-body>
  <townip-modal-footer>
    <button (click)="modal.hide()" class="btn btn-secondary" type="button">Cancel</button>
    <button (click)="confirmActivate()" class="btn btn-primary" type="button">Continue</button>
  </townip-modal-footer>
</townip-modal>
