import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { isNullOrUndefined } from '../utils/is-null-or-undefined';

@Pipe({
  name: 'standardDate',
})
export class StandardDatePipe implements PipeTransform {
  public transform(value: any): string {
    const date = moment(value);
    if (isNullOrUndefined(value) || !date.isValid()) {
      return '-';
    }
    return date.format('MMM DD, YYYY');
  }
}
