import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { EventEmitter, Injectable } from '@angular/core';
import { Message } from '../dto/messaging/message';
import { NoteMessage } from '../dto/messaging/note-message';
import { TodoMessage } from '../dto/messaging/todo-message';
import { AlertMessage } from '../dto/messaging/alert-message';
import { WebsocketService } from './websocket.service';
import { RequestService } from './request-service';
import { UserService } from './user.service';
import { FieldType } from '../data-filter/field-type';
import { MessageRequest } from '../request/message-request';
import { HttpClient, HttpParams } from '@angular/common/http';
import * as moment from 'moment';
import { AuthService } from '../../security/auth.service';
import { TechSupportRequest } from '../dto/tech-support-request';
import { GeneralResponseMessage } from '../dto/general-response-message';
import { STATIC_FILTERS } from '../../data-services/filter.service';
import { MessageContact } from '../dto/messaging/message-contact';
import { BulkOrganizationMessage } from '../dto/messaging/bulk-organization-message';
import { EntityScope } from '../dto/scopes';
import { GlobalLoaderService } from './global-loader.service';
import { DeliveryEmail } from '../dto/filing-and-translation-delivery/delivery-email';
import { ContactScope } from '../enums/contact-scope';
import { DeliveryEmailRecipient } from '../dto/filing-and-translation-delivery/delivery-email-recipient';
import { isNullOrUndefined } from '../utils/is-null-or-undefined';
import { map } from 'rxjs/operators';
import { ProjectResponseDocument } from '../response/project-response-document';
import { DeliveryEmailTypeEnum } from '../dto/filing-and-translation-delivery/delivery-email-type.enum';

const overdueAlertKey = 'townip_pm_dashboard_overdue_projects_alert';

@Injectable()
export class MessagingService {
  public messages: BehaviorSubject<Message[]> = new BehaviorSubject([]);
  public todos: BehaviorSubject<TodoMessage[]> = new BehaviorSubject([]);
  public alerts: BehaviorSubject<AlertMessage[]> = new BehaviorSubject([]);
  public alertEmitter: EventEmitter<AlertMessage> = new EventEmitter();
  public messageEmitter: EventEmitter<Message> = new EventEmitter();
  public projectMessages: BehaviorSubject<Message[]> = new BehaviorSubject([]);
  public currentProjectId: number;

  // Subscriptions
  private isSubscribed: boolean;
  private messageSubscription: Subscription;
  private todoSubscription: Subscription;
  private alertSubscription: Subscription;
  private noteSubscription: Subscription;

  constructor(private httpClient: HttpClient,
              private requestService: RequestService,
              private websocketService: WebsocketService,
              private authService: AuthService,
              private userService: UserService,
              private globalLoaderService: GlobalLoaderService) {
    this.authService.connectedEmitter.subscribe(connected => {
      if (connected) {
        const user = this.userService.getUser();
        if (this.isSubscribed) {
          return;
        } else if (isNullOrUndefined(user)) {
          this.clear();
          return;
        }
        this.getMessagesForUser(user.id);
        this.getTodos(user.id);
        this.getAlertsForUser(user.id);

        this.isSubscribed = true;
      } else {
        this.clear();
      }
    });

    this.websocketService.websocketUpdateEmitter.subscribe(websocketMessage => {
      if (websocketMessage.message.type === 'MESSAGE') {
        this.retrieveIndividualMessage(websocketMessage.message.id)
          .toPromise()
          .then(retrievedMessage => {
            retrievedMessage.hidePopup = websocketMessage.message.hidePopup;
            this.processNewMessage(retrievedMessage);
          });
      } else if (websocketMessage.message.type === 'TODO') {
        this.getTodoMessage(websocketMessage.message.id);
      } else if (!isNullOrUndefined(websocketMessage.message.messageType) &&
        websocketMessage.message.messageType === 'ALERT') {
        this.processNewAlert(websocketMessage.message);
        if (websocketMessage.message.scope === 'TODO') {
          this.getTodoMessage(websocketMessage.message.scopeId);
        }
      }
    });
  }

  public getTodoMessage(id: number): void {
    this.retrieveTodoMessage(id)
      .toPromise()
      .then(todo =>
        this.processNewTodo(todo)
      );
  }

  public postNote(userId: number, note: NoteMessage): Observable<NoteMessage> {
    const url = `/api/messaging/note/user/${userId}`;
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient.post<NoteMessage>(url, note, { headers: headers });
  }

  public deleteNote(noteId: number): Observable<NoteMessage> {
    const url = `/api/messaging/delete/note/${noteId}`;
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.delete<NoteMessage>(url, { headers: headers });
  }

  public updateNote(note: NoteMessage): Observable<NoteMessage> {
    const url = '/api/messaging/note';
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.put<NoteMessage>(url, note, { headers: headers });
  }

  public retrieveNoteByScopeAndId(scope: string, scopeId: number): Observable<NoteMessage[]> {
    const url = '/api/messaging/note/retrieve';
    const headers = this.requestService.buildHttpHeaders();
    const messageRequest: MessageRequest = { scopeType: scope, messageScopeId: scopeId };

    return this.httpClient.post<NoteMessage[]>(url, messageRequest, { headers: headers });
  }

  public retrieveNoteByScopeAndIdAndCategory(scope: string,
    scopeId: number,
    category: string): Observable<NoteMessage[]> {
    const url = '/api/messaging/note/retrieve';
    const headers = this.requestService.buildHttpHeaders();

    const messageRequest: MessageRequest = { scopeType: scope, messageScopeId: scopeId, categoryType: category };

    return this.httpClient.post<NoteMessage[]>(url, messageRequest, { headers: headers });
  }

  public retrieveNoteByProjectId(projectId: number): Observable<NoteMessage[]> {
    const url = `/api/messaging/note/project/${projectId}`;
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient.get<NoteMessage[]>(url, { headers: headers });
  }

  public retrieveAllNotesByProjectId(
    projectId: number,
    documents: ProjectResponseDocument[] = null
  ): Observable<NoteMessage[]> {
    const url = `/api/messaging/note/project/${projectId}/all`;
    const headers = this.requestService.buildHttpHeaders();
    const request = this.httpClient.get<NoteMessage[]>(url, { headers: headers });

    if (!documents) {
      return request;
    }

    return request
      .pipe(
        map(value => value.concat(this.mergeDocumentNotes(documents)))
      );
  }

  private mergeDocumentNotes(documents: ProjectResponseDocument[]): NoteMessage[] {
    return documents
      .filter(document => !isNullOrUndefined(document.notes))
      .map(document => {
        return {
          messageBody: document.notes,
          creationTime: document.fileSystemKey.lastModified.valueOf(),
          scope: 'ESTIMATE',
          categorySubtype: null,
          messageSubject: document.fileSystemKey.filename,
          creator: null,
          messageType: 'DOCUMENT_NOTE',
        } as NoteMessage;
      });
  }

  public retrieveAllNotesByTaskId(taskId: number): Observable<NoteMessage[]> {
    const url = `/api/messaging/note/task/${taskId}/all`;
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient.get<NoteMessage[]>(url, { headers: headers });
  }

  public retrieveNoteByTaskId(taskId: number): Observable<NoteMessage[]> {
    const url = `/api/messaging/note/task/${taskId}`;
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient.get<NoteMessage[]>(url, { headers: headers });
  }

  public retrieveRejectNoteByTaskId(taskId: number): Observable<NoteMessage[]> {
    const url = `/api/messaging/note/reject/task/${taskId}`;
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient.get<NoteMessage[]>(url, { headers: headers });
  }

  public postMessage(userId: number, message: Message): Observable<Message> {
    const url = `/api/messaging/message/user/${userId}`;
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient.post<Message>(url, message, { headers: headers });
  }

  private retrieveMessages(userId: number, subscribe: boolean = true): Observable<Message[]> {
    const url = `/api/messaging/message/user/${userId}`;
    this.globalLoaderService.ignorePermanently(url);

    // TODO need to add date range after new messages mockup for task 1089, currently hardcoded for past 365 days
    const from = moment()
      .subtract(365, 'days')
      .startOf('day')
      .valueOf(); // unix timestamp
    let params = new HttpParams();
    params = params.append('from', from.toString());

    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient.get<Message[]>(url, { headers: headers, params: params });
  }

  private retrieveIndividualMessage(messageId: number): Observable<Message> {
    const url = `/api/messaging/message/${messageId}`;
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.get<Message>(url, { headers: headers });
  }

  private retrieveMessage(messageId: number): Observable<Message> {
    const url = `/api/messaging/message/parent/${messageId}`;
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.get<Message>(url, { headers: headers });
  }

  private retrieveProjectMessages(orderId: number): Observable<Message[]> {
    this.currentProjectId = orderId;
    const url = `/api/messaging/message/project/${orderId}`;
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.get<Message[]>(url, { headers: headers });
  }

  private retrieveProjectMessagesByRecipient(orderId: number): Observable<Message[]> {
    // this endpoint return parent project messages that has logged in user as the recipient.
    const url = `/api/messaging/recipient/message/project/${orderId}`;
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.get<Message[]>(url, { headers: headers });
  }

  public processNewMessage(message: Message): void {
    // message has parent message but the parent message is not cached, need to retrieve the parent message.
    if (message.parentMessageId) {
      const parentMessage = this.messages.getValue()
        .find(m => m.id === message.parentMessageId);
      if (!parentMessage) {
        this.retrieveMessage(message.parentMessageId)
          .toPromise()
          .then(retrievedParentMessage => {
            if (retrievedParentMessage) {
              this.processNewMessage(retrievedParentMessage);
            }
          });
        return;
      }
    }
    // the following method call updates main messaging component but will not handle project/task level updates
    this.processMessageIntoList(message, this.messages);

    // allows for web socket updates for project / task level replies that do not have a scope ID or task ID set
    if (!message.scope && !message.scopeId && message.parentMessageId) {
      const parent = this.messages.getValue()
        .find(m => m.id === message.parentMessageId);
      if (parent) {
        // project view web socket updates
        if (parent.scope === 'PROJECT' && +this.currentProjectId === parent.scopeId ||
          ((parent.scope === 'TASK' || parent.scope === 'INVOICE') &&
            +this.currentProjectId === parent.parentScopeId)) {
          this.processMessageIntoList(message, this.projectMessages);
        }
      }
    } else {
      if ((message.scope === 'PROJECT' && +this.currentProjectId === message.scopeId) ||
        ((message.scope === 'TASK' || message.scope === 'INVOICE') &&
          +this.currentProjectId === message.parentScopeId)) {
        // update project message list for new thread
        this.processMessageIntoList(message, this.projectMessages);
      }
    }

    this.messageEmitter.emit(message);
  }

  public processNewTodo(todo: TodoMessage): void {
    const todos = this.todos.getValue();
    const index = todos.findIndex(t => t.id === todo.id);
    if (index >= 0) {
      todos[index] = todo;
    } else {
      todos.push(todo);
    }
    this.todos.next(todos);
  }

  public sortAlertMessages(alerts: AlertMessage[]): void {
    alerts = alerts.sort((a, b) => {
      return b.sentTime - a.sentTime;
    });
  }

  public processNewAlert(alert: AlertMessage): void {
    if (isNullOrUndefined(alert.sentTime)) {
      alert.sentTime = new Date().getTime();
    }
    const alerts = this.alerts.getValue();
    // Check if this already is being emitted because it was read.
    if (alert.messageRecipients[0].read) {
      const index = alerts.findIndex(a => a.id === alert.id);
      alerts[index] = alert;
    } else {
      if (alerts.findIndex(a => a.id === alert.id) >= 0) {
        // This alert already exists.
        return;
      }
      // This is a new alert, make sure it is displayed.
      if (!alert.hideScope) { // only add routing if not hidden
        if (alert.scope === 'TASK') {
          alert.alertRoute = '/tasks';
        } else if (alert.scope === 'PROJECT') {
          alert.alertRoute = '/projects';
        } else if (alert.scope === 'ESTIMATE') {
          if (this.userService.user.userType === 'INTERNAL') {
            alert.alertRoute = '/estimate/estimate-review';
          } else {
            alert.alertRoute = '/estimate/project-review';
          }
        }
      }
      alerts.push(alert);
      this.sortAlertMessages(alerts);
      this.alertEmitter.emit(alert);
    }
    this.alerts.next(alerts);
  }

  public snoozeAlert(toastId: number): Observable<AlertMessage> {
    const alerts = this.alerts.getValue();
    const alertIndex = alerts.findIndex(alrt => alrt.toastId === toastId);
    const alert = alerts[alertIndex];
    alerts.splice(alertIndex, 1);
    this.alerts.next(alerts);
    const url = `/api/messaging/alert/${alert.id}/snooze`;
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.put<AlertMessage>(url, null, { headers: headers });
  }

  public getAlertForToast(toastId: number): AlertMessage {
    return this.alerts.getValue()
      .find(alrt => alrt.toastId === toastId);
  }

  private processMessageIntoList(message: Message, messagesSubject: BehaviorSubject<Message[]>): void {
    let messages = messagesSubject.getValue();
    // If the message has a parent, add it to the parent's children
    if (message.parentMessageId) {
      const parentMessage = messages.find(m => m.id === message.parentMessageId);
      if (parentMessage) {
        if (!parentMessage.childMessages) {
          parentMessage.childMessages = [];
        }
        parentMessage.childMessages.push(message);

        // Also set the recepients of the parent message to unread (except the author)
        for (const recipient of parentMessage.messageRecipients) {
          if (recipient.user.id === message.creator.id) {
            continue;
          }
          recipient.read = false;
        }
      }
    } else {
      const index = messages.findIndex(m => m.id === message.id);
      if (index >= 0) {
        messages[index] = message;
      } else {
        // This is a new message, push it to the list.
        messages.push(message);
      }

    }
    // Create a new instance of the messages array (usually for change detection)
    messages = [...messages];
    messagesSubject.next(messages.sort((a, b) => b.creationTime as any - a.creationTime as any));

  }

  public getMessagesForUser(userId: number, subscribe: boolean = true): void {
    const response = this.retrieveMessages(userId, subscribe);
    if (response instanceof Observable) {
      response.toPromise()
        .then(messages => {
          messages = messages.sort((a, b) => {
            return b.creationTime - a.creationTime;
          });
          messages.forEach(message => {
            if (message.childMessages) {
              message.childMessages = message.childMessages.sort((a, b) => {
                return b.creationTime - a.creationTime;
              });
            }
          });
          this.messages.next(messages);
        });
    } else {
      console.error('An error occurred ', response, ' getting the message subscription for user ' + userId);
    }
  }

  public getTodos(userId: number): void {
    const response = this.retrieveTodoMessages(userId);
    if (response instanceof Observable) {
      response.toPromise()
        .then(todos => {
          if (!todos) {
            return;
          }

          todos = todos.sort((a, b) => {
            return b.creationTime - a.creationTime;
          });
          this.todos.next(todos);
        });
    } else {
      console.error('An error occurred ', response, ' getting the todo subscription for user ' + userId);
    }
  }


  public async getMessagesForProject(projectId: number): Promise<Message[]> {
    const messages: Message[] = await this.retrieveProjectMessages(projectId)
      .toPromise();

    messages.sort((a, b) => b.creationTime - a.creationTime);

    messages.forEach(message => {
      if (message.childMessages) {
        message.childMessages.sort((a, b) => b.creationTime - a.creationTime);
      }
    });

    this.projectMessages.next(messages);
    return messages;
  }

  public postTodoMessage(userId: number, todoMessage: TodoMessage): Observable<TodoMessage> {
    const url = `/api/messaging/todo/user/${userId}`;
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.post<TodoMessage>(url, todoMessage, { headers: headers });
  }

  public updateTodoMessage(todoMessage: TodoMessage): Observable<TodoMessage> {
    const url = '/api/messaging/todo';
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.put<TodoMessage>(url, todoMessage, { headers: headers });
  }

  public toggleTodoComplete(todoId: number): Observable<GeneralResponseMessage> {
    const url = `/api/messaging/todo/${todoId}/toggleComplete`;
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.put<GeneralResponseMessage>(url, null, { headers: headers });
  }

  public retrieveTodoMessage(todoId: number): Observable<TodoMessage> {
    const url = `/api/messaging/todo/${todoId}`;
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.get<TodoMessage>(url, { headers: headers });
  }

  public retrieveTodoMessages(userId: number): Observable<TodoMessage[]> {
    const url = `/api/messaging/todo/user/${userId}`;
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.get<TodoMessage[]>(url, { headers: headers });
  }

  public postManualAlert(userId: number, alertMessage: AlertMessage): Observable<GeneralResponseMessage> {
    const url = `/api/messaging/alert/user/${userId}`;
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.post<GeneralResponseMessage>(url, alertMessage, { headers: headers });
  }

  public getAlertsForUser(userId: number): void {
    const response = this.retrieveAlerts(userId);
    if (response instanceof Observable) {
      response.toPromise()
        .then(alerts => {
          // This fixes race issues on logout where we finish getting alerts after the user has logged out.
          // Ex: (login then quickly log out
          if (!this.userService.getUser()) {
            return;
          }

          this.sortAlertMessages(alerts);
          alerts.forEach(alert => {
            if (alert.scope === 'TASK') {
              alert.alertRoute = '/tasks';
            } else if (alert.scope === 'PROJECT') {
              alert.alertRoute = '/projects';
            } else if (alert.scope === 'ESTIMATE') {
              if (this.userService.user.userType === 'INTERNAL') {
                alert.alertRoute = '/estimate/estimate-review';
              } else {
                alert.alertRoute = '/estimate/project-review';
              }
            }
          });
          this.alerts.next(alerts);
        });
    } else {
      console.error('An error occurred ', response, ' getting the alerts subscription for user ' + userId);
    }
  }

  private retrieveAlerts(userId: number): Observable<AlertMessage[]> {
    const url = `/api/messaging/alert/user/${userId}`;
    this.globalLoaderService.ignorePermanently(url);
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.get<AlertMessage[]>(url, { headers: headers });
  }

  public toggleRead(userId: number, baseMessageId: number): Observable<any> {
    const url = `/api/messaging/message/${baseMessageId}/user/${userId}/toggleRead`;
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient.put(url, { headers: headers });
  }

  public markMessageRead(userId: number, baseMessageId: number, messageType: string): void {
    const url = `/api/messaging/message/${baseMessageId}/user/${userId}/markRead`;
    const headers = this.requestService.buildHttpHeaders();
    const response = this.httpClient.put(url, { headers: headers });

    response.toPromise()
      .then(() => {
        if (messageType === 'ALERT') {
          const alerts = this.alerts.getValue();
          const index = alerts.findIndex(updateAlert => updateAlert.baseMessageId === baseMessageId);
          alerts[index].messageRecipients.find(mr => mr.user.id === userId).read = true;
          this.alerts.next(alerts);
        } else if (messageType === 'MESSAGE') {
          const messages = this.messages.getValue();
          const index = messages.findIndex(updateMessage => updateMessage.baseMessageId === baseMessageId);
          messages[index].messageRecipients.find(mr => mr.user.id === userId).read = true;
          this.messages.next(messages);
        }
      });
  }

  public markAllMessagesRead(userId: number, messageType: string): Observable<GeneralResponseMessage> {
    const url = `/api/messaging/message/user/${userId}/markAllRead/${messageType}`;
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.put<GeneralResponseMessage>(url, null, { headers: headers });
  }

  public retrieveMessageRecipients(scope: string, scopeId: number): Observable<MessageContact[]> {
    let url;
    if (scope && scopeId) {
      url = `/api/messaging/scope-recipients?scope=${scope}&scope-id=${scopeId}`;
    } else {
      url = '/api/messaging/recipients';
    }
    const headers = this.requestService.buildHttpHeaders();
    // TODO: Add type
    return this.httpClient.get<MessageContact[]>(url, { headers: headers });
  }

  private clear(): void {
    this.messages.next([]);

    if (this.messageSubscription) {
      this.messageSubscription.unsubscribe();
    }

    if (this.alertSubscription) {
      this.alertSubscription.unsubscribe();
    }

    if (this.todoSubscription) {
      this.todoSubscription.unsubscribe();
    }

    if (this.noteSubscription) {
      this.noteSubscription.unsubscribe();
    }

    if (this.projectMessages) {
      this.projectMessages.next([]);
    }

    this.isSubscribed = false;
    window.localStorage.removeItem(overdueAlertKey);
  }

  public sendMockAlert(): void {
    const id = Math.ceil(Math.random() * 65535); // Generate a random ID.,
    const testAlert: AlertMessage = {
      id: id,
      toastId: id,
      service: null,
      serviceSubtype: null,
      taskDefinition: null,
      action: 'task',
      status: null,
      messageSubject: 'New Mock Alert',
      messageBody: 'You have a new mock alert this is a very long message idk what to say <ul><li>Some list</li></ul>',
      messageRecipients: [],
      fireTime: new Date().getTime(),
      creator: null,
      creationTime: new Date().getTime(),
      alertRoute: null,
      // For mocking out different alerts:
      scope: 'TASK', // PROJECT OR TASK
      scopeId: 32,
      messageType: 'ALERT',
      // alertRoute: '/tasks',
      hideScope: false
    };

    const alerts = this.alerts.getValue();
    alerts.push(testAlert);
    this.alerts.next(alerts);
    this.alertEmitter.emit(testAlert);
  }

  public supportEmail(techSupportRequest: TechSupportRequest): Promise<GeneralResponseMessage> {
    const url = '/api/messaging/support/email';
    /* simple message
    let files: string[] = [];
    files.push('d34933d3-a511-47fc-9775-a81d56123985');
    let techSupportRequestSimple: TechSupportRequest = {
      systemType: 'FORECAST',
      subject: 'test subject',
      messageBody: 'test body',
      fileSystemKeys: files
    };
    */

    const headers = this.requestService.buildHttpHeaders();
    // return Success other wise failed message.
    return this.httpClient.post<GeneralResponseMessage>(url, techSupportRequest, { headers: headers })
      .toPromise();
  }

  public syncProjectMessages(projectId: number): void {
    // sync project messages in this.message
    this.retrieveProjectMessagesByRecipient(projectId)
      .toPromise()
      .then(response => {

        let messages = this.messages.getValue();
        messages = messages.filter(message =>
          !(message.scope === 'PROJECT' && message.scopeId === projectId) &&
          !(message.scope === 'TASK' && message.parentScopeId === projectId)
        );

        response.forEach(message => {
          if (message.childMessages) {
            message.childMessages = message.childMessages.sort((a, b) => {
              return b.creationTime - a.creationTime;
            });
          }

          const index = messages.findIndex(m => m.id === message.id);
          if (index >= 0) {
            messages[index] = message;
          } else {
            // This is a new message, push it to the list.
            messages.push(message);
          }
        });

        // Create a new instance of the messages array (usually for change detection)
        messages = [...messages];
        this.messages.next(messages.sort((a, b) => b.creationTime as any - a.creationTime as any));

      });

  }

  public processEstimateNoteMessage(userId: number, note: NoteMessage): Observable<NoteMessage> {
    const url = `/api/project/estimate/messaging/note/user/${userId}`;
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.post<NoteMessage>(url, note, { headers: headers });
  }

  public deleteInternalNote(noteId: number): Observable<NoteMessage> {
    const url = `/api/project/estimate/messaging/delete/note/${noteId}`;
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.delete<NoteMessage>(url, { headers: headers });
  }

  public sendBulkOrganizationMessage(message: BulkOrganizationMessage, scopeId: number, scopeType: EntityScope): Observable<GeneralResponseMessage> {
    const url = `/api/messaging/bulk-message?&scopeId=${scopeId}&scopeType=${scopeType}`;
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.post<{ response: string }>(url, message, { headers: headers });
  }

  public sendBulkOrganizationOutreachMessage(message: BulkOrganizationMessage): Observable<GeneralResponseMessage> {
    const url = '/api/messaging/vendor-outreach';
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.post<{ response: string }>(url, message, { headers: headers });
  }

  private retrieveExternalRecipientsForMessage(messageId: number): Observable<string[]> {
    const url = `/api/messaging/${messageId}/external-recipients`;
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.get<string[]>(url, { headers: headers });
  }

  public async getExternalRecipientsForMessage(messageId: number): Promise<string[]> {
    return this.retrieveExternalRecipientsForMessage(messageId)
      .toPromise();
  }

  public getOpsEmailContact(): Observable<string> {
    const url = '/api/messaging/ops-email-contact';
    const headers = this.requestService.buildHttpHeaders('text/plain');
    return this.httpClient.get(url, { headers: headers, observe: 'body', responseType: 'text' });
  }

  public sendCustomEmail(email: DeliveryEmail): Observable<DeliveryEmail> {
    const url = '/api/messaging/send-custom-email';
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.post<DeliveryEmail>(url, email, { headers: headers });
  }

  public getEmailContact(scope: ContactScope): Observable<DeliveryEmailRecipient> {
    const url = `/api/messaging/email-contact?ScopeType=${scope}`;
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.get<DeliveryEmailRecipient>(url, { headers: headers });
  }

  public getEmailContactByDeliveryType(
    scopeId: number,
    scope: ContactScope,
    deliveryType: DeliveryEmailTypeEnum,
  ): Observable<DeliveryEmailRecipient> {
    const url = `/api/messaging/email-contact?ScopeType=${scope}&ScopeId=${scopeId}&EmailType=${deliveryType}`;
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.get<DeliveryEmailRecipient>(url, { headers: headers });
  }
}
