import { Component, OnInit } from '@angular/core';
import { UserService } from '../../shared/services/user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'townip-error-auth-module-only',
  templateUrl: './error-auth-module-only.component.html',
  styleUrls: ['./error-auth-module-only.component.scss']
})
export class ErrorAuthModuleOnlyComponent implements OnInit {

  constructor(private userService: UserService, private router: Router) { }

  public ngOnInit(): void {
  }

  public logout(): void {
    this.userService.logout();

    setTimeout(() => {
      this.router.navigate(['/auth/login']);
    }, 1000);
  }

}
