import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'townip-messages-sub-header',
  templateUrl: './messages-sub-header.component.html',
  styleUrls: ['./messages-sub-header.component.scss']
})
export class MessagesSubHeaderComponent implements OnInit {

  constructor() { }

  public ngOnInit(): void {
  }

}
