import { Pipe, PipeTransform } from '@angular/core';
import { IndexedSelectItem } from '../message-find-recipient/message-find-recipient.component';
import { UserService } from '../../../services/user.service';
import { PortalUser } from '../../../domain/user';

export interface TypeUsers {
  recipientType: string;
  users: IndexedSelectItem[];
}

export enum RecipientTypes {
  Client = 'Client',
  Vendor = 'Vendor',
  ProjectManager = 'Project Manager',
  SalesManager = 'Sales Manager',
  AccountManager = 'Account Manager'
}

@Pipe({
  name: 'groupByRecipientType'
})
export class GroupByRecipientTypePipe implements PipeTransform {

  constructor(private userService: UserService) {
  }

  public transform(filteredUsers: IndexedSelectItem[]): TypeUsers[] {
    const currentUser = this.userService.getUser();
    if (!filteredUsers) {
      return null;
    }

    const recipientTypes = this.setupRecipientTypes(currentUser);

    return this.groupUserByType(filteredUsers, recipientTypes);
  }

  /**
   * Get the available recipient types
   */
  private setupRecipientTypes(currentUser: PortalUser): string[] {
    if (currentUser.userType === 'INTERNAL') {
      // we need the undefined because in some cases message recipients are used
      // where we have a recipient type, and in other cases email contacts are used
      // which do not have a recipient type. in the latter, the company grouping will
      // place these users in the correct area.
      return [
        RecipientTypes.Client,
        RecipientTypes.Vendor,
        RecipientTypes.ProjectManager,
        RecipientTypes.SalesManager,
        RecipientTypes.AccountManager,
        undefined
      ];
    }

    if (currentUser.userType === 'COMPANY') {
      return [
        RecipientTypes.SalesManager,
        RecipientTypes.AccountManager,
        RecipientTypes.ProjectManager,
        RecipientTypes.Client
      ];
    }

    if (currentUser.userType === 'VENDOR') {
      return [
        RecipientTypes.ProjectManager,
        RecipientTypes.SalesManager,
        RecipientTypes.AccountManager,
        RecipientTypes.Vendor
      ];
    }

    return [];
  }

  /**
   * Group each user by type
   */
  private groupUserByType(filteredUsers: IndexedSelectItem[], recipientTypes: string[]): TypeUsers[] {
    const users: TypeUsers[] = [];

    for (const recipientType of recipientTypes) {
      // NOTE:
      // The recipient type is converted to uppercase value to make it compatible with other
      // similar recipient type values.
      // Problem arises for example when using the component for Delivery VS Messaging.
      // We really only want to use 1 implementation ideally, so making the types uppercase would make the x-compat.
      const typeUsers: TypeUsers = {
        recipientType: this.typeUpperCase(recipientType),
        users: filteredUsers
          .filter(user => this.typeUpperCase(user.value.recipientType) === this.typeUpperCase(recipientType))
      };

      if (typeUsers.users.length === 0) {
        continue;
      }

      users.push(typeUsers);
    }

    return users;
  }

  private typeUpperCase(type: string): string {
    try {
      return type.toUpperCase();
    } catch (e) {
      return type;
    }
  }

}
