import { PlatformModule } from '@angular/cdk/platform';
import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, Injector, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi, withJsonpSupport } from '@angular/common/http';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { UserService } from './shared/services/user.service';
import { EstimateService } from './estimate/estimate.service';
import { ProjectService } from './data-services/project.service';
import { OrderService } from './data-services/order.service';
import { CountryService } from './data-services/country.service';
import { DocumentService } from './data-services/document.service';
import { WindowRefService } from './shared/services/window-ref.service';
import { CustomerManagementService } from './data-services/customer-management.service';
import { SharedModule } from './shared/shared.module';
import { VendorManagementService } from './data-services/vendor-management.service';
import { ConfigService } from './data-services/config.service';
import { WebsocketService } from './shared/services/websocket.service';
import { SessionGuardService } from './shared/guards/session-guard.service';
import { InternalManagementService } from './data-services/internal-management.service';
import { VendorOnboardingService } from './onboarding/components/create-vendor/create-vendor-company/vendor-onboarding.service';
import { MessagingModule } from './shared/components/messaging/messaging.module';
import { MessagingService } from './shared/services/messaging.service';
import { RequestService } from './shared/services/request-service';
import { ToastrModule } from 'ngx-toastr';
import { DebugModule } from './debug/debug.module';
import { ToastGroupingService } from './shared/services/toast-grouping.service';
import { RandomColorService } from './shared/services/random-color.service';
import { ContainerMarginsService } from './shared/services/container-margins.service';
import { CertificationDataStoreService } from './data-services/config/data-store/certification-data-store.service';
import { LanguageSpecializationDataStoreService } from './data-services/config/data-store/language-specialization-data-store.service';
import { PatentTypeDataStoreService } from './data-services/config/data-store/patent-type-data-store.service';
import { CountryDataStoreService } from './data-services/config/data-store/country-data-store.service';
import { IntSearchAuthorityDataStoreService } from './data-services/config/data-store/int-search-authority-data-store.service';
import { SystemPricingDataStoreService } from './data-services/config/data-store/system-pricing-data-store.service';
import { ProjectCategoryDataStoreService } from './data-services/config/data-store/project-category-data-store.service';
import { ProjectTypeDataStoreService } from './data-services/config/data-store/project-type-data-store.service';
import { DefaultProductivityDataStoreService } from './data-services/config/data-store/default-productivity-data-store.service';
import { MessagesModule } from './messages/messages.module';
import { InvoiceService } from './data-services/invoice.service';
import { ForecastService } from './forecast/forecast.service';
import { ReceivingOfficeDataStoreService } from './data-services/config/data-store/receiving-office-data-store.service';
import { TasksOverviewService } from './data-services/tasks-overview.service';
import { ProjectsOverviewService } from './data-services/projects-overview.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { LanguageSpecializationSubtypeDataStoreService } from './data-services/config/data-store/language-specialization-subtype-data-store.service';
import { CanDeactivateGuard } from './shared/services/can-deactivate-guard.service';
import { ConfirmModalService } from './shared/services/confirm-modal.service';
import { HttpRequestErrorInterceptor } from './shared/interceptors/http-request-error-interceptor';
import { HttpRequestErrorInterceptorMessages } from './shared/interceptors/http-request-error-interceptor-messages';
import { OrganizationManagementService } from './settings-v2/services/organization-management.service';
import { InternalManagementGuard } from './shared/services/internal-management-guard.service';
import { FilterSelectionMemoryService } from './shared/data-filter/filter-selection/filter-selection-memory.service';
import { MenuComponent } from './main/navigation/menu/menu.component';
import { ToolbarComponent } from './main/navigation/toolbar/toolbar.component';
import { NavService } from './main/navigation/nav.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoggingService } from './shared/logging.service';
import { ConsoleLoggingService } from './shared/console-logging.service';
import { GlobalErrorHandler } from './main/error-handlers/global-error-handler';
import { GlobalErrorHandlerService } from './main/error-handlers/global-error-handler.service';
import { ErrorGuardService } from './shared/guards/error-guard.service';
import { PrintPreviewGuard } from './print-preview/print-preview.guard';
import { TablePreferencesService } from './shared/services/table-preferences.service';
import { GrowlModule } from 'primeng/growl';
import { ProgressBarModule } from 'primeng/progressbar';
import { MultiSelectModule } from 'primeng/multiselect';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { PanelMenuModule } from 'primeng/panelmenu';
import { TaskGuardService } from './data-services/task-guard-service';
import { WorkflowService } from './data-services/workflow-service';
import { BillingService } from './billing/services/billing.service';
import { TaskNavigationService } from './tasks/services/task-navigation.service';
import { AuthInterceptor } from './security/auth-interceptor';
import { AuthService } from './security/auth.service';
import { ErrorsModule } from './errors/errors.module';
import { RedirectModule } from './shared/redirect/redirect.module';
import { LoadingModule } from './shared/components/loading/loading.module';
import { LoadingService } from './shared/components/loading/loading.service';
import { FileService } from './data-services/file.service';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { OnboardingGuardService } from './shared/guards/onboarding-guard.service';
import { LanguageGuardService } from './shared/guards/language-guard.service';
import { MiscConfigGuardService } from './shared/guards/misc-config-guard.service';
import { PricingGuardService } from './shared/guards/pricing-guard.service';
import { ProjectGuardService } from './shared/guards/project-guard.service';
import { VendorOnboardingGuardService } from './shared/guards/vendor-onboarding-guard.service';
import { EmailValidatorsService } from './validators/email-validators.service';
import { NewClientEstimateModule } from './settings-v2/internal-management/components/new-client-estimate/new-client-estimate.module';
import { CreateEstimateGuardService } from './shared/guards/create-estimate-guard.service';
import { InternalUserGuardService } from './shared/guards/internal-user-guard.service';
import { VendorUserGuardService } from './shared/guards/vendor-user-guard.service';
import { CompanyUserGuardService } from './shared/guards/company-user-guard.service';
import { LoginGuard } from './shared/guards/login.guard';
import { VendorManagerGuardService } from './shared/guards/vendor-manager-guard.service';
import { NonVendorGuardService } from './shared/guards/non-vendor-guard.service';
import { ProjectDeactivateGuard } from './shared/services/project-deactivate-guard';
import { IconSvgsModule } from './icon-svgs/icon-svgs.module';
import { TermsOfServiceModalModule } from './shared/components/terms-of-service-modal/terms-of-service-modal.module';
import { InitialsModule } from './shared/components/initials/initials.module';
import { BroadcastService } from './shared/services/broadcast.service';
import { AppContextService } from './shared/services/app-context.service';
import { NoForecasterGuard } from './shared/guards/no-forecaster.guard';
import { StartupPageModule } from './main/startup-page/startup-page.module';
import { WebFontService } from './shared/services/web-font.service';
import { ProjectsGuardService } from './data-services/projects-guard.service';
import { CsvParserService } from './shared/services/csv-parser.service';
import { PrintPreviewService } from './print-preview/print-preview.service';
import { PrintSessionGuard } from './print-preview/print-session.guard';
import { EstimatesOverviewService } from './estimate/estimates-overview/estimates-overview.service';
import { DisallowLegalsightGuard } from './shared/guards/disallow-legalsight.guard';
import { ConnectionDaemonService } from './shared/services/connection-daemon.service';
import { OfflineScreenModule } from './main/offline-screen/offline-screen.module';
import { TasksActionService } from './data-services/tasks-action.service';
import { AdditionalServicesDataStoreService } from './data-services/config/data-store/additional-services-data-store.service';
import { FirebaseService } from './firebase/firebase.service';
import { FileUploadInterceptorService, FileUploadServiceSingletonInstance } from './shared/interceptors/file-upload-interceptor.service';
import { ReportService } from './data-services/report.service';
import { AssignmentService } from './data-services/assignment.service';
import { FilterService } from './data-services/filter.service';
import { SearchSystemModule } from './shared/components/search-system/search-system.module';
import { CalendarTasksService } from './data-services/calendar-tasks.service';
import { PageTitleService } from './shared/services/page-title.service';
import { ServerBannerModule } from './shared/components/server-banner/server-banner.module';
import { DisallowSalesAccountManagerGuard } from './shared/guards/disallow-sales-account-manager-guard.service';
import { RestrictedCompanyUserGuardService } from './shared/guards/restricted-company-user-guard.service';
import { MaintenanceNoticeModule } from './main/maintenance-notice/maintenance-notice.module';
import { HttpCacheService } from './shared/services/http-cache.service';
import { HttpCacheInterceptorService } from './shared/interceptors/http-cache.interceptor.service';
import { GlobalLoaderInterceptor } from './security/global-loader.interceptor';
import { GlobalLoaderModule } from './security/components/global-loader/global-loader.module';
import { GlobalLoaderService } from './shared/services/global-loader.service';
import { AnnuityPatentService } from './data-services/annuity-patent.service';
import { PatentFamilyService } from './data-services/patent-family.service';
import { AnnuityService } from './data-services/annuity.service';
import { AnnuityWorkflowService } from './data-services/annuity-workflow.service';
import { AnnuityInvoiceService } from './data-services/annuity-invoice.service';
import { GrowlService } from './shared/services/growl.service';
import { DirectivesModule } from './shared/directives/directives.module';
import { AnnuityBillingService } from './data-services/annuity-billing.service';
import { AnnuitiesGuard } from './shared/guards/annuities.guard';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule as BsModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { ApplicantService } from './data-services/applicant.service';
import { VendorOnlyGuard } from './shared/guards/user-authorization/vendor-only.guard';
import { CompanyOnlyGuard } from './shared/guards/user-authorization/company-only.guard';
import { SmAmOnlyGuard } from './shared/guards/user-authorization/sm-am-only.guard';
import { PmOnlyGuard } from './shared/guards/user-authorization/pm-only.guard';
import { SystemTermsService } from './data-services/system-terms.service';
import { ForecasterGuard } from './shared/guards/forecaster-guard';
import { InternalAdminOnlyGuard } from './shared/guards/user-authorization/internal-admin-only.guard';
import { CreatePortfolioForecastService } from './forecast/create-portfolio-forecast-v2/services/create-portfolio-forecast.service';
import { GlobalModalModule } from './controls/global-modal/global-modal.module';
import { GlobalModalService } from './controls/global-modal/global-modal.service';
import { ModalModule } from './controls/modal/modal.module';
import { LeavePageWarningModalModule } from './annuities/shared/leave-page-warning-modal/leave-page-warning-modal.module';
import { StandardAlertModule } from './annuities/shared/standard-alert/standard-alert.module';
import { DefaultInvoicePreferenceGuardService } from './shared/guards/default-invoice-preference-guard.service';

@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    ToolbarComponent,
  ],
  bootstrap: [AppComponent], imports: [
    GrowlModule,
    ToastrModule.forRoot(),
    DebugModule,
    ErrorsModule,
    AppRoutingModule,
    BrowserModule,
    FormsModule,
    ProgressBarModule,
    ReactiveFormsModule,
    SharedModule,
    AccordionModule.forRoot(),
    ButtonsModule.forRoot(),
    BsDropdownModule.forRoot(),
    BsModalModule.forRoot(),
    PaginationModule.forRoot(),
    PopoverModule.forRoot(),
    TabsModule.forRoot(),
    TooltipModule.forRoot(),
    TypeaheadModule.forRoot(),
    BrowserAnimationsModule,
    MessagingModule,
    MultiSelectModule,
    NewClientEstimateModule,
    DropdownModule,
    LoadingModule,
    MessagesModule,
    InputTextModule,
    ConfirmDialogModule,
    PanelMenuModule,
    RedirectModule,
    ScrollPanelModule,
    IconSvgsModule,
    TermsOfServiceModalModule,
    InitialsModule,
    StartupPageModule,
    OfflineScreenModule,
    SearchSystemModule,
    PlatformModule,
    ServerBannerModule,
    GlobalLoaderModule,
    MaintenanceNoticeModule,
    DirectivesModule,
    GlobalModalModule,
    ModalModule,
    LeavePageWarningModalModule,
    StandardAlertModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    AppContextService,
    AuthService,
    // Prime message service provider
    MessageService,
    HttpRequestErrorInterceptorMessages,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestErrorInterceptor,
      multi: true,
    },
    FileUploadServiceSingletonInstance,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: FileUploadInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpCacheInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: GlobalLoaderInterceptor,
      multi: true,
    },
    FirebaseService,
    BillingService,
    ConfigService,
    CountryService,
    CustomerManagementService,
    DocumentService,
    EstimateService,
    EstimatesOverviewService,
    EmailValidatorsService,
    ForecastService,
    InternalManagementService,
    LoadingService,
    MessagingService,
    OrderService,
    OrganizationManagementService,
    ProjectService,
    RequestService,
    UserService,
    CreateEstimateGuardService,
    InternalUserGuardService,
    VendorUserGuardService,
    VendorManagerGuardService,
    CompanyUserGuardService,
    RestrictedCompanyUserGuardService,
    LoginGuard,
    NonVendorGuardService,
    NoForecasterGuard,
    SessionGuardService,
    LanguageGuardService,
    MiscConfigGuardService,
    PricingGuardService,
    ProjectGuardService,
    OnboardingGuardService,
    VendorOnboardingGuardService,
    ErrorGuardService,
    PrintPreviewGuard,
    PrintPreviewService,
    PrintSessionGuard,
    ProjectDeactivateGuard,
    VendorManagementService,
    VendorOnboardingService,
    WebsocketService,
    WindowRefService,
    ToastGroupingService,
    RandomColorService,
    ContainerMarginsService,
    InvoiceService,
    TasksOverviewService,
    ProjectsOverviewService,
    CanDeactivateGuard,
    InternalManagementGuard,
    ConfirmModalService,
    ConfirmationService,
    GlobalErrorHandlerService,
    NavService,
    TaskNavigationService,
    { provide: LoggingService, useClass: ConsoleLoggingService },
    // Config Data Stores
    CertificationDataStoreService,
    CountryDataStoreService,
    DefaultProductivityDataStoreService,
    LanguageSpecializationDataStoreService,
    LanguageSpecializationSubtypeDataStoreService,
    PatentTypeDataStoreService,
    ProjectCategoryDataStoreService,
    AdditionalServicesDataStoreService,
    ProjectTypeDataStoreService,
    IntSearchAuthorityDataStoreService,
    SystemPricingDataStoreService,
    ReceivingOfficeDataStoreService,
    FilterSelectionMemoryService,
    TablePreferencesService,
    WorkflowService,
    TaskGuardService,
    ProjectsGuardService,
    FileService,
    BroadcastService,
    { provide: ErrorHandler, useFactory: getErrorHandler, deps: [Injector] },
    WebFontService,
    CsvParserService,
    DisallowSalesAccountManagerGuard,
    DisallowLegalsightGuard,
    ConnectionDaemonService,
    TasksActionService,
    ReportService,
    AssignmentService,
    FilterService,
    CalendarTasksService,
    PageTitleService,
    HttpCacheService,
    GlobalLoaderService,
    AnnuityPatentService,
    PatentFamilyService,
    AnnuityService,
    AnnuityWorkflowService,
    AnnuityInvoiceService,
    GrowlService,
    AnnuityBillingService,
    AnnuitiesGuard,
    ApplicantService,
    VendorOnlyGuard,
    CompanyOnlyGuard,
    SmAmOnlyGuard,
    PmOnlyGuard,
    SystemTermsService,
    ForecasterGuard,
    InternalAdminOnlyGuard,
    CreatePortfolioForecastService,
    GlobalModalService,
    DefaultInvoicePreferenceGuardService,
    provideHttpClient(withInterceptorsFromDi(), withJsonpSupport()),
  ]
})
export class AppModule {
}

export function getErrorHandler(injector: Injector): ErrorHandler {
  return new GlobalErrorHandler(injector.get(GlobalErrorHandlerService));
}
