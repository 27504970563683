import { HttpClient } from '@angular/common/http';
import { RequestService } from '../shared/services/request-service';
import { PaginatedData, PaginateObject } from '../shared/dto/paginated-data';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AnnuityBillPaginated } from '../shared/dto/annuities/annuity-bill-paginated';
import { AnnuityBillInfo } from '../shared/dto/annuities/annuity-bill-info';
import { AnnuityBill } from '../shared/dto/annuities/annuity-bill';
import { FilterScope, PaginatedRequest } from '../shared/query/paginated-request.component';
import { RequestFilterNumberResponse } from '../shared/query/request-filter.component';
import { UserService } from '../shared/services/user.service';

@Injectable()
export class AnnuityBillingService {

  constructor(
    private httpClient: HttpClient,
    private requestService: RequestService,
    private userService: UserService,
  ) {
  }

  public getAllPaginated(request: PaginatedData<AnnuityBillPaginated>): Observable<PaginatedData<AnnuityBillPaginated>> {
    const url = '/api/annuity/bill/paginated';
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient
      .post<any>(url, request.toObject(), { headers: headers })
      .pipe(
        // Update the given pagination object with values from the response.
        map((response: PaginateObject<AnnuityBillPaginated>) => request.update(response))
      );
  }

  public getFilingBillInfo(id: number): Observable<AnnuityBillInfo> {
    const url = `/api/annuity/${id}/filing-bill-info`;
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient.get<AnnuityBillInfo>(url, { headers: headers });
  }

  public get(id: number): Observable<AnnuityBill> {
    const url = `/api/annuity/bill/${id}`;
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient.get<AnnuityBill>(url, { headers });
  }

  public submit(id: number, invoiceNumber: string): Observable<AnnuityBill> {
    const url = `/api/annuity/bill/${id}/submit`;
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient.put<AnnuityBill>(url, invoiceNumber, { headers });
  }

  public update(bill: AnnuityBill): Observable<AnnuityBill> {
    const url = '/api/annuity/bill';
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient.put<AnnuityBill>(url, bill, { headers });
  }

  public calculate(bill: AnnuityBill): Observable<AnnuityBill> {
    const url = '/api/annuity/bill/calculate';
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient.post<AnnuityBill>(url, bill, { headers });
  }

  public getCount(query: string, scope: FilterScope = this.userService.paginatedScope): Observable<RequestFilterNumberResponse> {
    const url = '/api/annuity/bill/counts';

    const request: PaginatedRequest = new PaginatedRequest();
    request.filter = query;
    request.scope = scope;

    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient.post<RequestFilterNumberResponse>(url, request, { headers });
  }

  public reject(id: number): Observable<AnnuityBill> {
    const url = `/api/annuity/bill/${id}/reject`;
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient.put<AnnuityBill>(url, id, { headers });
  }

  public updateInvoiceNumber(id: number, invoiceNumber: string): Observable<AnnuityBill> {
    const url = `/api/annuity/bill/${id}/invoice-number`;
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient.put<AnnuityBill>(url, invoiceNumber, { headers });
  }
}
