import { Injectable } from '@angular/core';

@Injectable()
export class ManagedAnnuityService {

  public static orgIdKey = 'townip_managed_org_annuity_id';

  public static orgNameKey = 'townip_managed_annuity_org_name';

  private _organizationId: number;

  private _organizationName: string;

  constructor() {
    this._organizationId = +window.localStorage.getItem(ManagedAnnuityService.orgIdKey);
    this._organizationName = window.localStorage.getItem(ManagedAnnuityService.orgNameKey);
  }

  public get organizationId(): number {
    return this._organizationId;
  }

  public set organizationId(value: number) {
    if (!this._organizationId) {
      this._organizationId = value;
      window.localStorage.setItem(ManagedAnnuityService.orgIdKey, value.toString());
      return;
    }

    throw new Error('When the organization value is set, you cannot modify it again.');
  }

  public get organizationName(): string {
    return this._organizationName;
  }

  public set organizationName(value: string) {
    if (!this._organizationName) {
      this._organizationName = value;
      window.localStorage.setItem(ManagedAnnuityService.orgNameKey, value);
      return;
    }

    throw new Error('When the organization value is set, you cannot modify it again.');
  }

  public clear(): void {
    this._organizationId = 0;
    this._organizationName = '';

    window.localStorage.removeItem(ManagedAnnuityService.orgIdKey);
    window.localStorage.removeItem(ManagedAnnuityService.orgNameKey);
  }
}
