<div fillHeight class="d-flex align-items-center justify-content-center">
  <div class="error-container">
    <span class="error-code">404</span>
    <div class="error-content text-center">
      <div class="error-title">Something went wrong</div>
      <div class="error-text">
        <a href="#" routerLink="/dashboard" (click)="hardNavigate($event, '/dashboard')">
          <i class="fa fa-arrow-left"></i> Back to dashboard
        </a>
        or contact <a href="mailto:{{supportContact}}" target="_top">customer service</a>
      </div>
      <div style="width: 600px;" class="ml-auto mr-auto">
        <button class="btn btn-link" (click)="toggleError()">Error Information</button>
        <textarea class="form-control mt-3" [readonly]="true" *ngIf="showError">
          {{lastError.timestamp}}
          {{lastError.error}}
          {{lastError.location}}
        </textarea>
      </div>
    </div>
  </div>
</div>
