<div class="header-filter" *ngIf="options" [ngSwitch]="options.input">
  <ng-container *ngSwitchCase="'multiselect'">
    <p-multiSelect
      maxSelectedLabels="1"
      [options]="options.selectValues"
      [(ngModel)]="currentValue"
      (ngModelChange)="signalUpdate()"
      defaultLabel=""
      [resetFilterOnHide]="true">
    </p-multiSelect>
  </ng-container>
  <ng-container *ngSwitchCase="'date'">
    <div
      class="control d-flex align-items-center white-bg"
      [tooltip]="currentValue && currentValue[1] && currentValue[0] ? dateDesc : null">
      <ng-template #dateDesc>
        {{currentValue[0] | date : 'mediumDate' : options.utc ? '+0000' : '' }}
        -
        {{currentValue[1] | date : 'mediumDate' : options.utc ? '+0000' : '' }}
      </ng-template>
      <townip-calendar
        selectionMode="range"
        class="w-100"
        inputStyleClass="text-center"
        dateType="timestamp"
        [showButtonBar]="true"
        [(ngModel)]="currentValue"
        [utc]="options.utc"
        (ngModelChange)="signalUpdate()">
      </townip-calendar>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'range'">
    <p-dropdown [(ngModel)]="currentValue" (ngModelChange)="signalUpdate()" [options]="rangeOptions">
    </p-dropdown>
  </ng-container>
  <ng-container *ngSwitchCase="'boolean'">
    <p-dropdown [(ngModel)]="currentValue[0]" (ngModelChange)="signalUpdate()" [options]="booleanOptions">

    </p-dropdown>
    <!--<select class="control flex-grow" [(ngModel)]="currentValue[0]" (ngModelChange)="signalUpdate()">-->
    <!--<option [value]="null">All</option>-->
    <!--<option [value]="true">Yes</option>-->
    <!--<option [value]="false">No</option>-->
    <!--</select>-->
  </ng-container>
  <ng-container *ngSwitchDefault>-</ng-container>
</div>




