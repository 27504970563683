import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TextHighlighterModule } from '../text-highlighter/text-highlighter.module';
import { OverviewUtilitiesModule } from '../../overview-utilities/overview-utilities.module';
import { MessageThreadComponent } from './message-thread/message-thread.component';
import { MessageComponent } from './message/message.component';
import { MessageFormComponent } from './message-form/message-form.component';
import { MessagePaneComponent } from './message-pane/message-pane.component';
import { MessageQuickviewComponent } from './message-quickview/message-quickview.component';
import { UserCardModule } from '../user-card/user-card.module';
import { MessageAttachmentsDirective, MessageAuthorDirective, MessageAuthorImageDirective, MessageContentDirective, MessageDateDirective, MessageFooterDirective, ThreadContainerDirective } from './messaging.directives';
import { MessagesComponent } from './messages/messages.component';
import { TodoComponent } from './Todos/todo/todo.component';
import { TodoFormComponent } from './Todos/todo-form/todo-form.component';
import { CustomToast } from './custom-toast';
import { AlertsQuickviewComponent } from './alerts-quickview/alerts-quickview.component';
import { PipeModule } from '../../../pipe/pipe.module';
import { TrimmedTextModule } from '../trimmed-text/trimmed-text.module';
import { MessageConnectionStatusComponent } from './message-connection-status/message-connection-status.component';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { MessagesHeaderComponent } from './messages-header/messages-header.component';
import { InitialsModule } from '../initials/initials.module';
import { DirectivesModule } from '../../directives/directives.module';
import { PipesModule } from '../../pipes/pipes.module';
import { MessageRecipientsComponent } from './message-recipients/message-recipients.component';
import { MessageReadBadgeComponent } from './message-read-badge/message-read-badge.component';
import { MessageListComponent } from './message-list/message-list.component';
import { EditorModule } from 'primeng/editor';
import { MultiSelectModule } from 'primeng/multiselect';
import { CheckboxModule } from 'primeng/checkbox';
import { CalendarModule } from 'primeng/calendar';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { DropdownModule } from 'primeng/dropdown';
import { TooltipModule as TooltipModulePrime } from 'primeng/tooltip';
import { SharedModule as PrimeSharedModule } from 'primeng/shared';
import { MessagesSubHeaderComponent } from './messages-sub-header/messages-sub-header.component';
import { RouterModule } from '@angular/router';
import { MessageFileUploadComponent } from './message-file-upload/message-file-upload.component';
import { FileUploadModule } from 'primeng/fileupload';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { MessageAttachmentComponent } from './message-attachment/message-attachment.component';
import { ModalModule } from '../../../controls/modal/modal.module';
import { FileUploaderModule } from '../../../estimate/shared/file-uploader/file-uploader.module';
import { MessageFindRecipientComponent } from './message-find-recipient/message-find-recipient.component';
import { UserChipsComponent } from './user-chips/user-chips.component';
import { MessagingPipeModule } from './pipes/messaging-pipe.module';
import { ValidatorsModule } from 'app/shared/validators/validators.module';
import { MessageAssociateFileComponent } from './message-associate-file/message-associate-file.component';
import { FilesAssociateTasksModule } from '../files-associate-tasks/files-associate-tasks.module';
import { AdvancedDatepickerModule } from '../../../controls/advanced-datepicker/advanced-datepicker.module';

@NgModule({
  imports: [
    CommonModule,
    CheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    UserCardModule,
    MultiSelectModule,
    EditorModule,
    PrimeSharedModule,
    PipeModule,
    PipesModule,
    CalendarModule,
    AutoCompleteModule,
    TrimmedTextModule,
    TooltipModule,
    DropdownModule,
    TooltipModulePrime,
    InitialsModule,
    DirectivesModule,
    ButtonsModule,
    RouterModule,
    FileUploadModule,
    ModalModule,
    FileUploaderModule,
    ScrollPanelModule,
    OverlayPanelModule,
    MessagingPipeModule,
    ValidatorsModule,
    FilesAssociateTasksModule,
    TextHighlighterModule,
    OverviewUtilitiesModule,
    AdvancedDatepickerModule,
    PopoverModule,
  ],
  declarations: [
    MessageThreadComponent,
    MessageComponent,
    MessageFormComponent,
    MessagePaneComponent,
    MessageQuickviewComponent,
    MessageContentDirective,
    MessageAuthorDirective,
    MessageAuthorImageDirective,
    MessageAttachmentsDirective,
    MessageDateDirective,
    MessageFooterDirective,
    MessagesComponent,
    TodoComponent,
    TodoFormComponent,
    CustomToast,
    AlertsQuickviewComponent,
    MessageConnectionStatusComponent,
    MessagesHeaderComponent,
    MessageRecipientsComponent,
    MessageReadBadgeComponent,
    ThreadContainerDirective,
    MessageListComponent,
    MessagesSubHeaderComponent,
    MessageFileUploadComponent,
    MessageAttachmentComponent,
    MessageFindRecipientComponent,
    UserChipsComponent,
    MessageAssociateFileComponent,
  ],
  exports: [
    MessageThreadComponent,
    MessageQuickviewComponent,
    AlertsQuickviewComponent,
    MessagesComponent,
    MessageFormComponent,
    TodoFormComponent,
    MessageListComponent,
    MessageFindRecipientComponent,
    UserChipsComponent,
    MessageFileUploadComponent,
    MessageAttachmentComponent
  ],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class MessagingModule {
}
