<townip-modal heading="{{heading}}" (onHide)="cleanup()">
  <townip-modal-body>
    <ng-container [ngTemplateOutlet]="activeBody"></ng-container>
  </townip-modal-body>
  <townip-modal-footer>
    <ng-container [ngTemplateOutlet]="activeAction"></ng-container>
  </townip-modal-footer>
</townip-modal>

<ng-template #confirmBody>
  {{confirmData.message}}
</ng-template>
<ng-template #confirmAction>
  <button *ngIf="confirmData.noVisible" class="btn btn-secondary" (click)="confirmResponse.next(false)">
    {{confirmData.noLabel ? confirmData.noLabel : 'No'}}
  </button>
  <button class="btn btn-primary" (click)="confirmResponse.next(true)">
    {{confirmData.yesLabel ? confirmData.yesLabel : 'Yes'}}
  </button>
</ng-template>
