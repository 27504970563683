import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ConfigService } from '../data-services/config.service';
import { ForecastRequest } from '../shared/dto/forecast/forecast-request';
import { RequestService } from '../shared/services/request-service';
import { ForecastResponse } from '../shared/dto/forecast/forecast-response';
import { PatentDocumentDetails } from '../shared/response/patent-document-details';
import { ForecastOverview } from '../shared/dto/forecast/forecast-overview';
import { UserService } from '../shared/services/user.service';
import { ForecastUser } from '../shared/dto/forecast/forecast-user';
import { PatentCountryAbandonDate } from './report-forecast/report-forecast-summary-details/report-forecast-summary-details.component';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../security/auth.service';
import { MessageService } from 'primeng/api';
import { ForecastDownloadDetails, ForecastDownloadRequest } from './report-forecast/report-download-modal/report-download-modal.model';
import { ApiQueryOptions, ApiQueryParams } from '../shared/api-query-params';
import { ForecastStatusAction, ForecastUserStats } from '../shared/dto/forecast/forecast-user-stats';
import { GeneralResponseMessage } from '../shared/dto/general-response-message';
import { DocumentService } from '../data-services/document.service';
import { timeout } from 'rxjs/operators';
import { FeeAdjustmentRequest } from '../shared/request/fee-adjustment-request';

@Injectable()
export class ForecastService {
  public userPatentPromise: Promise<PatentDocumentDetails[]>;
  public patentAbandonDateChanged: Subject<PatentCountryAbandonDate> = new Subject<PatentCountryAbandonDate>();
  public forecastDataChanged: Subject<ForecastResponse> = new Subject<ForecastResponse>();

  public forecastDownloadTriggered: Subject<ForecastDownloadDetails> = new Subject<ForecastDownloadDetails>();
  public forecastUsers: ForecastUser[];

  constructor(private configService: ConfigService,
              private httpClient: HttpClient,
              private authService: AuthService,
              private userService: UserService,
              private growlService: MessageService,
              private requestService: RequestService,
              private docService: DocumentService) {
    this.authService.connectedEmitter.subscribe(connected => {
      if (!connected) {
        this.clear();
      }
    });
  }

  public downloadForecast(downloadRequirements?: ForecastDownloadDetails): void {
    this.forecastDownloadTriggered.next(downloadRequirements);
  }

  public clear(): void {
    this.patentAbandonDateChanged = new Subject<PatentCountryAbandonDate>();
  }

  public getForecastReportById(forecastId: number): Promise<ForecastResponse> {
    const url = `/api/forecast/${forecastId}`;
    const headers = this.requestService.buildHttpHeaders();
    // TODO: Response type dto for getForecastReportById
    const forecastPromise = this.httpClient.get<ForecastResponse>(url, { headers: headers })
      .toPromise();

    return forecastPromise;
  }

  public createForecast(data: ForecastRequest): Promise<ForecastResponse> {
    const timeoutDuration = 60000; // 60 seconds if possible
    const url = '/api/forecast';
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient
      .post<ForecastResponse>(url, data, { headers: headers })
      .pipe(
        timeout(timeoutDuration)
      )
      .toPromise();
  }

  public adjustFees(feeAdjustmentRequest: FeeAdjustmentRequest): Observable<FeeAdjustmentRequest> {
    const url = '/api/forecast/fee-adjustment';
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.post<FeeAdjustmentRequest>(url, feeAdjustmentRequest, { headers: headers });
  }

  public getForecasts(opts?: ApiQueryOptions): Promise<ForecastOverview[]> {
    let url = '/api/forecasts';
    if (opts) {
      const queryParams = new ApiQueryParams(opts).generateQueryParams();
      // Append the generated query params
      url += queryParams;
    }

    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient.get<ForecastOverview[]>(url, { headers: headers })
      .toPromise();
  }

  public getForecastsByUserId(companyUserId: number,
    startDate?: number,
    endDate?: number): Promise<ForecastOverview[]> {
    const url = `/api/forecasts/user/${companyUserId}`;
    const headers = this.requestService.buildHttpHeaders();

    if (startDate && endDate) {
      return this.httpClient.get<ForecastOverview[]>(url, {
        headers: headers,
        params: { start: startDate.toString(), end: endDate.toString() }
      })
        .toPromise();
    }

    return this.httpClient.get<ForecastOverview[]>(url, { headers: headers })
      .toPromise();
  }

  public getForecastByCompanyId(companyId: number): Promise<ForecastOverview[]> {
    const url = `/api/forecasts/company/${companyId}`;
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.get<ForecastOverview[]>(url, { headers: headers })
      .toPromise();
  }

  public getUserForecastStats(companyUserId: number,
    startDate?: number,
    endDate?: number): Promise<ForecastUserStats[]> {
    const url = `/api/forecast/stats/${companyUserId}`;
    const headers = this.requestService.buildHttpHeaders();

    if (startDate && endDate) {
      return this.httpClient.get<ForecastUserStats[]>(url, {
        headers: headers,
        params: { start: startDate.toString(), end: endDate.toString() }
      })
        .toPromise();
    }

    return this.httpClient.get<ForecastUserStats[]>(url, { headers: headers })
      .toPromise();
  }

  public updateUserForecastStats(companyUserId: number,
    forecastId: number,
    statsAction: ForecastStatusAction): Promise<any> {
    const url = `/api/forecast/stats/${companyUserId}`;
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient.post(url,
      { forecastOverviewId: forecastId, statsAction: statsAction }, { headers: headers })
      .toPromise();
  }

  public getForecastUsers(): Promise<ForecastUser[]> {
    const url = '/api/forecast/users';
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.get<ForecastUser[]>(url, { headers: headers })

      .toPromise();
  }

  public getForecastUser(customerId: number): ForecastUser {
    if (this.forecastUsers) {
      return this.forecastUsers.find(user => user.customer.id === customerId);
    }
    return null;
  }

  public downloadForecastExcel(downloadRequest: ForecastDownloadRequest): void {
    const url = '/api/forecast/generate/excel';
    const headers = this.requestService.buildHttpHeaders();

    this.httpClient.post(url, downloadRequest, {
      headers: headers,
      observe: 'response',
      responseType: 'blob'
    })
      .toPromise()
      .then(resp => {
        this.docService.saveFile(resp);
      }
      );
  }

  public sendDownloadedForecastPdf(downloadRequest: ForecastDownloadRequest): Promise<GeneralResponseMessage> {

    const url = '/api/forecast/email/file';
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.post<GeneralResponseMessage>(url, downloadRequest, { headers: headers })
      .toPromise();
  }

  public linkForecastEstimate(forecastId: number, estimateId: number): Promise<any> {
    const url = `/api/forecast/${forecastId}/link/estimate/${estimateId}`;
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.put<any>(url, { headers: headers })
      .toPromise();
  }

  public unlinkForecastEstimate(forecastId: number, estimateId: number): Promise<any> {
    const url = `/api/forecast/${forecastId}/unlink/estimate/${estimateId}`;
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.put<any>(url, { headers: headers })
      .toPromise();
  }

  /**
   * Updates the forecast without doing a re-run.
   * If you need to update patents or documents, use createForecast
   *
   * @param data
   */
  public updateForecast(data: ForecastRequest): Promise<any> {
    const url = `/api/forecast/${data.forecastOverviewId}`;
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.put<any>(url, data, { headers: headers })
      .toPromise();
  }

}
