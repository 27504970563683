<div class="d-flex flex-wrap message-recipients-container" (click)="setFocus()">
  <div class="chip-container" *ngFor="let user of selectedUsers">
    <div class="chip">{{user.label}}
      <button type="button" class="fa-button close-button" (click)="remove(user)" *ngIf="!disableAdding">
        <i class="fa fa-times"></i>
      </button>
    </div>
  </div>
  <input
    class="message-recipients form-control form-control-sm flex-fill w-auto no-border p-0"
    #inputKey
    *ngIf="disableAdding === false"
    [(ngModel)]="tempSelection"
    (keyup)="onSearchKeyChange(inputKey.value, $event);">
</div>

<p-overlayPanel appendTo="body" styleClass="recipient-search" *ngIf="filteredUsers || tempSelection && allowCreateNew">
  <townip-user-card
    class="user-details-container"
    *ngIf="tempSelection && allowCreateNew"
    (click)="$event.stopPropagation(); onCreateNew()">
    <townip-user-name class="select-item">
      <span class="text-muted">Create new contact <strong>{{tempSelection}}</strong></span>
    </townip-user-name>
  </townip-user-card>

  <ng-container *ngFor="let groupedUser of filteredUsers | groupByRecipientType; let i = index">
    <!-- Display of User Type  -->
    <div class="user-type-container">
      <hr *ngIf="i !== 0" class="separator">
      <span class="user-type">
          {{ groupedUser.recipientType | uppercase }}
        </span>
    </div>

    <ng-container *ngFor="let userOrganization of groupedUser | generateCompany:filteredUsers">
      <ng-container *ngIf="userOrganization; else displayInternalUsers">
        <!-- Display of User Organization -->
        <div class="py-2">
            <span (click)="$event.stopPropagation(); selectOrganization(userOrganization, groupedUser.recipientType)"
                  class="org-name">
              {{ userOrganization | uppercase }}
            </span>
        </div>

        <!-- Display of User -->
        <div class="organization-users">
          <ng-container *ngFor="let companyUser of filteredUsers | companyUsers:userOrganization:groupedUser.recipientType">
            <ng-container *ngTemplateOutlet="userOption; context: {user:companyUser}"></ng-container>
          </ng-container>
        </div>
      </ng-container>

      <ng-template #displayInternalUsers>
        <ng-container *ngFor="let companyUser of groupedUser.users">
          <ng-container *ngTemplateOutlet="userOption; context: {user:companyUser}"></ng-container>
        </ng-container>
      </ng-template>
    </ng-container>

    <!-- Display of User Details -->
    <ng-template #userOption let-user="user">
      <townip-user-card
        (click)="$event.stopPropagation(); select(user)"
        *ngIf="!user.value.isOrganization"
        class="user-details-container">
        <townip-user-name class="select-item">
          <townip-text-highlighter [word]="tempSelection">
            <span>{{ user.label }}</span>
            <span class="pull-right" *ngIf="isUserSelected(user)">
              <i class="fa fa-check check-user"></i>
            </span>
          </townip-text-highlighter>
        </townip-user-name>
      </townip-user-card>
    </ng-template>
  </ng-container>
</p-overlayPanel>
