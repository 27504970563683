import { DeliveryContact } from './dto/preferences/delivery-contact';
import { DeliveryContactTypeEnum } from './dto/filing-and-translation-delivery/delivery-contact-type.enum';
import { SelectItem } from 'primeng/api';
import { cloneDeep } from './clone-deep';
import { DeliveryContactCardModel } from '../settings-v2/components/pref-editor/delivery-contact-card/delivery-contact-card.component';
import PreferencesUtil from '../settings-v2/company-profile/preferences-util';
import { OrderDetailsResponse } from './response/order-details-response';

export default class DeliveryContactsUtil {
  public static filterOutSubmitterAndDeactivatedUser(deliveryContacts: DeliveryContact[],
    submitterId: number,
    includeInactive: boolean = false): DeliveryContact[] {
    let result: DeliveryContact[];
    if (deliveryContacts) {
      result = deliveryContacts.filter((deliveryContact) => {
        if (deliveryContact.active || includeInactive) {
          if (deliveryContact.companyUser) {
            return deliveryContact.companyUser.id !== submitterId;
          } else if (deliveryContact.externalContact) {
            return deliveryContact.externalContact !== submitterId;
          }
        }

        return false;
      });
    }

    return result;
  }

  public static isInternalOrSupplemental(type: DeliveryContactTypeEnum): boolean {
    return type === DeliveryContactTypeEnum.Internal || type === DeliveryContactTypeEnum.SupplementalEmail;
  }

  public static buildDeliveryContactOptions(customerId: number,
    contacts: DeliveryContact[],
    includeInactive: boolean = false): SelectItem[] {
    const deliveryContacts =
      cloneDeep(DeliveryContactsUtil.filterOutSubmitterAndDeactivatedUser(contacts, customerId, includeInactive));

    if (!Array.isArray(deliveryContacts)) {
      return [];
    }

    const deliveryContactOptions = [];
    for (const contact of deliveryContacts) {
      let deliveryContact: DeliveryContactCardModel;
      if (contact.externalContact && (contact.externalContact.active || includeInactive)) {
        // Do not include inactive contacts.
        deliveryContact = PreferencesUtil.copyExternalContactToContactModel(contact);
      } else if (contact.companyUser) {
        deliveryContact = PreferencesUtil.copyCompanyUserToContactModel(contact.companyUser, contact.defaultInd);
        deliveryContact.id = contact.id;
      } else if (contact.externalContactGroup && (contact.externalContactGroup.active || includeInactive)) {
        // Do not include inactive contact groups.
        deliveryContact = PreferencesUtil.copyExternalContactGroupToContactModel(contact);
      }
      deliveryContactOptions.push({ label: deliveryContact.name, value: deliveryContact });
    }

    return deliveryContactOptions;
  }

  /**
   * Method to build the project delivery contacts.
   */
  public static buildProjectDeliveryContacts(orderDetails: OrderDetailsResponse,
    deliveryContactOptions: SelectItem[]): DeliveryContactCardModel[] {
    if (!orderDetails.deliveryInfo
      || !Array.isArray(orderDetails.deliveryInfo.orderDeliveryContacts)
      || !Array.isArray(deliveryContactOptions)) {
      return [];
    }

    const projectDeliveryContacts = [];
    orderDetails.deliveryInfo.orderDeliveryContacts.forEach(orderDeliveryContact => {
      const deliveryContact = orderDeliveryContact.deliveryContact;
      const match = deliveryContactOptions.find(option => option.value.id === deliveryContact.id);
      if (match) {
        projectDeliveryContacts.push(match.value);
      }
    });

    return projectDeliveryContacts;
  }
}
