import { EventEmitter, Injectable } from '@angular/core';
import { ProjectRequestDocument } from '../shared/request/project-request-document';
import { ProjectJurisdiction } from '../shared/request/project-jurisdiction';
import { ProjectRequest } from '../shared/request/project-request';
import { PatentDocument } from '../shared/dto/patent/patent-document';
import { EstimateFooter } from '../shared/dto/estimate-footer';
import { ProjectFilingResponse } from '../shared/response/project-filing-response';
import { ConfigService } from '../data-services/config.service';
import { ProjectType } from '../shared/dto/system-config/project-type';
import { UntypedFormGroup } from '@angular/forms';
import { NavService } from '../main/navigation/nav.service';
import { LoggingService } from '../shared/logging.service';
import { ProjectResponse } from '../shared/response/project-response';
import { PortalUser } from '../shared/domain/user';
import { ProjectResponseDocument } from '../shared/response/project-response-document';
import { CompanyGroupLite } from '../shared/dto/company-group-lite';
import { Country } from '../shared/dto/system-config/country';
import { FileKeySupplementalData } from '../shared/dto/file-key-supplemental-data';
import { FileKeyPair } from '../shared/dto/file-key';
import { Language } from '../shared/dto/system-config/language';
import { Subject } from 'rxjs';
import { FileTranslateDetails } from './shared/general-translation-uploaders/file-translate-uploader/file-translate-uploader.model';
import { EstimateRequestModel } from './create-estimate-v2/shared/estimate-request-model';
import { isNullOrUndefined } from '../shared/utils/is-null-or-undefined';


/**
 * This is the enum of all available questions during the estimate process
 */
export enum EstimateStep {
  SELECT_TEAM = 'selectTeam',
  PATENT_TYPE = 'patentType',
  EP_SUBTYPE = 'epSubtype',
  FILING = 'estimateFiling',
  PATENT_SEARCH = 'patentSearch',
  SOURCE_FILE_UPLOAD = 'sourceFileUpload',
  PATENT_UPLOAD = 'patentUpload',
  COUNTRY_SELECT = 'countrySelect',
  TURNAROUND_TIME = 'turnaroundTime',
  SET_TURNAROUND_TIME = 'setTurnaroundTime',
  // MODIFYING_CLAIMS = 'modifyingClaims',
  ESTIMATE_TABLE = 'estimateTable',
  MATTER_DOCKET_NUMBER = 'matterDocketNumber',
  NOTES = 'estimateNotes',
  DIRECT_FILING_TYPE = 'directFilingType',
  DIRECT_TEAM_SELECT = 'directSelectTeam',
  DIRECT_COUNTRY = 'directCountry',
  DIRECT_APPLICATION_TYPE = 'directApplicationType',
  DIRECT_UPLOAD = 'directUpload',
}

@Injectable()
export class EstimateService {
  public patent: PatentDocument;
  public sourceFile: FileKeyPair;
  public estimateTeam: CompanyGroupLite;
  public estimateTeamEmitter: EventEmitter<CompanyGroupLite> = new EventEmitter();
  public estimateRequest: ProjectRequest;
  public patentType: string;
  public patentPhase: string;
  public estimateFooter: EstimateFooter;
  public estimateFooterEmitter: EventEmitter<EstimateFooter> = new EventEmitter();
  public clearCountriesEmitter: EventEmitter<boolean> = new EventEmitter();
  public submitEstimateEmitter: EventEmitter<boolean> = new EventEmitter();
  public submitOrderEmitter: EventEmitter<boolean> = new EventEmitter();
  public currentStepEmitter: EventEmitter<string> = new EventEmitter();
  public finishedStepEmitter: EventEmitter<string> = new EventEmitter();
  public translationAndFilingEmitter: EventEmitter<boolean> = new EventEmitter();
  public translationAndFilingProjectType: ProjectType;
  public translationOnlyProjectType: ProjectType;

  public patentEmitter = new EventEmitter<PatentDocument>();
  public patentTypeEmitter = new EventEmitter<string>();

  public translationAndFiling = false;
  public translationInd = false;
  public modifyingTurnaroundInd = false;
  public filingInd = false;
  public translationIndEmitter: EventEmitter<boolean>;
  public filingIndEmitter: EventEmitter<boolean>;
  public adjustedClaimsMap: Map<number, number>;
  public estimateForm: UntypedFormGroup;
  public directFileDocs: FileKeyPair[]; // Later, must be merged with the estimate supplemental docs

  public activeStep: string;

  public estimateRequestModel: EstimateRequestModel;

  // Unsave Modal
  public callUnsavedChanges: Subject<FileTranslateDetails> = new Subject<FileTranslateDetails>();
  public proceedUnsavedChanges: Subject<FileTranslateDetails> = new Subject<FileTranslateDetails>();
  public continueEdit: Subject<FileTranslateDetails> = new Subject<FileTranslateDetails>();

  constructor(private configService: ConfigService,
              private loggingService: LoggingService,
              private navService: NavService) {
    this.refresh();
    this.adjustedClaimsMap = new Map<number, number>();
    this.translationIndEmitter = new EventEmitter();
    this.filingIndEmitter = new EventEmitter();
  }

  public static shouldTranslate(country: Country,
    patentType: string,
    translateInd: boolean,
    patentLanguage: string): boolean {
    let returnValue: boolean;
    // If this country only supports one language in the claims section, and that language is the same language as
    // the patent document, remove translation ind.
    if (translateInd) {
      let translate = true;
      const service = country.patentServices.find(ps => ps.patentTypeConfig.name === patentType);
      if (!!service && !!service.sectionLanguages) {
        const claimsSection = service.sectionLanguages.find(sl => sl.patentSection === 'CLAIMS');
        if (!!claimsSection && !!claimsSection.languages && claimsSection.languages.length === 1) {
          if (claimsSection.languages[0].language === patentLanguage) {
            translate = false;
          }
        }
      }
      returnValue = translate;
    } else {
      returnValue = false;
    }
    return returnValue;
  }

  public static copyGeneralTranslationEstimateData(oldResponse: ProjectResponse, user: PortalUser): ProjectRequest {
    const estimateRequest: ProjectRequest = {};

    estimateRequest.projectCategory = oldResponse.projectCategory;
    estimateRequest.directOrder = oldResponse.directOrder;
    estimateRequest.model = 'ESTIMATE';
    estimateRequest.groupId = oldResponse.group ? oldResponse.group.id : null;
    estimateRequest.customerId = oldResponse.customerLite.id;
    estimateRequest.submitterId = user.id;
    estimateRequest.referenceNumbers = oldResponse.referenceNumbers;
    estimateRequest.projectName = oldResponse.projectName;
    estimateRequest.supplementalDocs = oldResponse.supplementalDocuments;
    estimateRequest.projectDescription = oldResponse.projectDescription;
    estimateRequest.customerPreferencesId = oldResponse.customerPreferencesId;
    estimateRequest.billingInformationId = oldResponse.billingInformation ? oldResponse.billingInformation.id : null;

    if (!isNullOrUndefined(oldResponse.additionalServices)) {
      const configAdditionalServices = oldResponse.additionalServices.filter(
        additionalService => !isNullOrUndefined(additionalService.additionalService));
      estimateRequest.additionalServicesRequested = configAdditionalServices.map(additionalService =>
        additionalService.additionalService);
    }

    estimateRequest.projectType = oldResponse.projectType;
    estimateRequest.documents = oldResponse.documents.map(document => {
      const projectDocs: ProjectRequestDocument = {};

      projectDocs.documentId = document.id;
      projectDocs.userDeadline = new Date(document.userDeadline);
      projectDocs.documentName = document.documentName;
      projectDocs.ranges = document.ranges;

      const source: Language = document.translations[0].fromLanguage ? document.translations[0].fromLanguage : null;
      const target: Language[] = document.translations.map(translation => {
        return translation.toLanguage;
      });

      projectDocs.translationRequest = {
        fromLanguage: source,
        toLanguages: target
      };

      return projectDocs;
    });

    return estimateRequest;
  }

  /**
   * This method will take the data from a previous response and set it in a new Request.
   * @param {ProjectResponse} oldResponse
   * @param {PortalUser} user
   * @returns {ProjectRequest}
   */
  public static copyEstimateData(oldResponse: ProjectResponse, user: PortalUser): ProjectRequest {
    const estimateRequest: ProjectRequest = {};
    if (user.userType === 'INTERNAL') {
      estimateRequest.customerId = oldResponse.customerLite.id;
    } else {
      estimateRequest.customerId = user.id;
    }
    estimateRequest.submitterId = user.id;
    estimateRequest.documents = [];
    estimateRequest.model = 'ESTIMATE';
    estimateRequest.documents = [];
    estimateRequest.patentPhase = oldResponse.patentPhase;
    estimateRequest.referenceNumbers = oldResponse.referenceNumbers;
    estimateRequest.projectDeadline = oldResponse.userEstimateDeadline;
    estimateRequest.projectType = oldResponse.projectType;
    estimateRequest.customerId = oldResponse.customerLite.id;
    estimateRequest.groupId = oldResponse.group ? oldResponse.group.id : null;
    estimateRequest.customerPreferencesId = oldResponse.customerPreferencesId;
    estimateRequest.billingInformationId = oldResponse.billingInformation ? oldResponse.billingInformation.id : null;

    const oldDoc: ProjectResponseDocument = oldResponse.documents[0];
    const document: ProjectRequestDocument = {};
    document.documentName = oldDoc.documentName;
    document.jurisdictions = [];
    document.documentId = oldDoc.sourceDocumentId;
    document.documentDetails = oldDoc.documentDetails;
    document.id = oldDoc.id;

    if (oldDoc.documentDetails.patentTypeConfig.name === 'UNITARY') {
      document.translationRequest = {
        fromLanguage: oldDoc.translations[0].fromLanguage,
        toLanguages: [oldDoc.translations[0].toLanguage],
        translationQualityType: oldDoc.translations[0].translationQualityType,
      };
    }

    for (const filing of oldDoc.filings) {
      const jurisdiction: ProjectJurisdiction = {
        country: filing.jurisdiction,
        jurisdictionId: filing.jurisdiction.id,
        translate: filing.translateInd,
        extensionType: filing.extensionType,
        file: filing.filing,
        userDeadline: filing.userDeadline,
        userExtensionDeadline: filing.userExtensionDeadline,
        adjustedClaimCount: filing.adjustedClaimCount,
        calculatedDeadline: filing.calculatedDeadline,
        filingDeadline: filing.filingDeadline,
      };
      document.jurisdictions.push(jurisdiction);
    }

    estimateRequest.documents.push(document);
    return estimateRequest;
  }

  public setEstimateForm(estimateForm: UntypedFormGroup): void {
    this.estimateForm = estimateForm;
  }

  public refresh(): void {
    this.estimateTeam = null;
    this.estimateRequest = {};
    this.estimateRequest.documents = [];
    this.patentType = 'PCT';
    this.estimateFooter = new EstimateFooter();
    this.estimateFooterEmitter.emit(this.estimateFooter);
    this.clearCountries();
  }

  public clear(): void {
    this.clearCountries();
    this.estimateTeam = null;
    this.estimateFooter = new EstimateFooter();
    this.estimateFooter.clearPatent();
    this.estimateFooterEmitter.emit(this.estimateFooter);
  }

  public saveEstimateRequestModal(estimateModal: EstimateRequestModel): void {
    this.estimateRequestModel = estimateModal;
  }

  public getEstimateRequestModal(): EstimateRequestModel {
    return this.estimateRequestModel;
  }

  public updateAdjustedClaims(filing: ProjectFilingResponse): void {
    this.adjustedClaimsMap.set(filing.jurisdiction.id, filing.adjustedClaimCount);
  }

  public setTranslationAndFiling(isTandF: boolean): void {
    this.translationAndFiling = isTandF;
    this.translationAndFilingEmitter.emit(this.translationAndFiling);
    this.estimateFooter.filingSelected = true;

    if (isTandF) {
      if (!this.translationAndFilingProjectType) {
        this.translationAndFilingProjectType = this.configService.translationAndFilingProjectType;
      }
      this.estimateRequest.projectType = this.translationAndFilingProjectType;
      this.filingInd = true;
      this.translationInd = true;
      this.filingIndEmitter.emit(true);
      this.translationIndEmitter.emit(true);
    } else {
      if (!this.translationAndFiling) {
        this.translationOnlyProjectType = this.configService.translationProjectType;
      }
      this.estimateRequest.projectType = this.translationOnlyProjectType;
      this.filingInd = false;
      this.translationInd = true;
      this.translationIndEmitter.emit(true);
      this.filingIndEmitter.emit(false);
    }
    this.estimateFooterEmitter.emit(this.estimateFooter);
  }

  public directFilingPatentSelected(): void {
    this.estimateFooter.filingSelected = true;
    this.estimateFooter.patentSelected = true;
    this.estimateFooterEmitter.emit(this.estimateFooter);
  }

  public setTurnaroundInd(isDateRequired: boolean): void {
    this.modifyingTurnaroundInd = isDateRequired;
    this.estimateFooter.settingTurnaround(isDateRequired);
    this.estimateFooterEmitter.emit(this.estimateFooter);
  }

  public setTurnaroundDate(turnaroundDate: Date): void {
    this.estimateFooter.turnaroundDate = turnaroundDate;
    this.estimateFooter.dateAnswered = true;
    this.estimateFooterEmitter.emit(this.estimateFooter);
  }

  public modifyingClaims(): void {
    this.estimateFooter.modifyingClaims();
    this.estimateFooterEmitter.emit(this.estimateFooter);
  }

  public countriesConfirmed(confirmed: boolean): void {
    this.estimateFooter.countriesConfirmed = confirmed;
    this.estimateFooterEmitter.emit(this.estimateFooter);
  }

  public inputtingNotes(): void {
    this.estimateFooterEmitter.emit(this.estimateFooter);
  }

  public setTotalCost(totalCost: number): void {
    this.estimateFooter.totalCost = totalCost;
    this.estimateFooterEmitter.emit(this.estimateFooter);
  }

  public updateFooterForSelectedCountries(countryCount: number): void {
    this.estimateFooter.countryCount = countryCount;
    this.estimateFooterEmitter.emit(this.estimateFooter);
  }

  public setPatent(patentDoc: PatentDocument): void {
    this.patent = patentDoc;
    if (patentDoc) {
      this.estimateFooter.updateNumberOfClaims(patentDoc.numberOfClaims);
      this.estimateFooter.patentSelected = true;
      if (patentDoc.applicants) {
        this.estimateFooter.applicants = patentDoc.applicants;
      }
      if (patentDoc.applicationNumber) {
        this.estimateFooter.patentId = patentDoc.applicationNumber;
      }
      if (patentDoc.documentName) {
        this.estimateFooter.patentTitle = patentDoc.documentName;
      }
      if (patentDoc.sourceLanguage) {
        this.estimateFooter.sourceLanguage = patentDoc.sourceLanguage;
      }
      if (patentDoc.sourceLanguage) {
        this.estimateFooter.earliestPriority = patentDoc.priorityDate;
      }
    } else {
      this.estimateFooter.updateNumberOfClaims(0);
      this.estimateFooter.patentSelected = false;
      this.estimateFooter.patentVerified = false;
    }
    this.updatePatent();
  }

  public setSourceFile(file: FileKeyPair): void {
    this.sourceFile = file;
    this.estimateFooter.sourceFileInd = !!file;
    this.estimateFooterEmitter.emit(this.estimateFooter);
  }

  public clearCountries(): void {
    this.clearCountriesEmitter.emit(true);
  }

  public setPatentType(patentType: string): void {
    this.clear();
    this.patentType = patentType;
    this.patentPhase = '';
    this.estimateFooter.patentType = patentType;
    this.estimateFooterEmitter.emit(this.estimateFooter);
    this.updatePatentType();
  }

  public setPatentPhase(phase: string): void {
    this.patentPhase = phase;
    this.estimateRequest.patentPhase = phase;
  }

  private updatePatent(): void {
    this.patentEmitter.emit(this.patent);
    this.estimateFooterEmitter.emit(this.estimateFooter);
  }

  public verifyPatent(isVerified: boolean, isUnknownDocument: boolean): void {
    this.estimateFooter.patentVerified = isVerified;
    this.estimateFooter.unknownDocument = isUnknownDocument;

    if (!isUnknownDocument) {
      this.sourceFile = null;
    }
    this.estimateFooterEmitter.emit(this.estimateFooter);
  }

  private updatePatentType(): void {
    this.patentTypeEmitter.emit(this.patentType);
  }

  public addDocumentToEstimate(document: ProjectRequestDocument): void {
    this.estimateRequest.documents = [];
    this.estimateRequest.documents.push(document);

    // Only used when the filing is DIRECT
    this.estimateFooter.directFilesUploaded = true;
  }

  public addDirectFileSupplementalDocsToEstimate(docs: FileKeySupplementalData[]): void {
    this.directFileDocs = docs;
  }

  /**
   * Clears only the uploaded docs.
   */
  public clearDirectFileDocs(): void {
    this.estimateRequest.documents = [];
    this.directFileDocs = [];

    this.estimateFooter.directFilesUploaded = false;
  }

  /**
   * Clears the all the docs and the patent
   */
  public clearDocument(): void {
    this.estimateRequest.documents = [];
    this.estimateRequest.supplementalDocs = [];
    this.estimateFooter.clearPatent();
    this.estimateFooterEmitter.emit(this.estimateFooter);

    // Only used when the filing is DIRECT
    this.directFileDocs = [];
    this.estimateFooter.directFilesUploaded = false;
    this.setPatent(null);
  }

  public addJurisdictionsToDocument(jurisdictions: ProjectJurisdiction[]): void {
    // TODO this will change if multiple documents are allowed.
    jurisdictions.forEach(j => {
      const claims = this.adjustedClaimsMap.get(j.jurisdictionId);
      if (claims) {
        j.adjustedClaimCount = claims;
      }
    });
    this.estimateRequest.documents[0].jurisdictions = jurisdictions;
  }

  public submitEstimate(): void {
    this.submitEstimateEmitter.emit(true);
  }

  /**
   * This method will trigger order submission
   * @param isQuickOrder - indicates if this is a quick order.
   */
  public submitOrder(isQuickOrder: boolean): void {
    this.submitOrderEmitter.emit(isQuickOrder);
  }

  /**
   * This method takes the step you are completing as an argument, calls nav service
   * to scroll the next step into view and the step you should proceed to will be emitted.
   * @param {EstimateStep} step - The step that was just completed.
   * @param {number} timeout - Length of the timeout defaulted to 1s
   */
  public finishStep(step: EstimateStep, timeout: number = 500): void {
    this.finishedStepEmitter.emit(step.toString());
    let nextStep: string = null;
    let scrollToTop = false;
    let offset = 0;   // How many pixels of offset we want to scroll from the element

    switch (step) {
    case EstimateStep.PATENT_TYPE:
      if (this.patentType === 'EP') {
        nextStep = EstimateStep.EP_SUBTYPE;
      } else if (this.patentType === 'PCT') {
        nextStep = EstimateStep.FILING;
      } else {
        nextStep = EstimateStep.DIRECT_FILING_TYPE;
      }
      break;
    case EstimateStep.EP_SUBTYPE:
      nextStep = EstimateStep.FILING;
      break;
    case EstimateStep.FILING:
      nextStep = EstimateStep.PATENT_SEARCH;
      break;
    case EstimateStep.PATENT_SEARCH:
      if (this.navService.hasStep(EstimateStep.SOURCE_FILE_UPLOAD)) {
        nextStep = EstimateStep.SOURCE_FILE_UPLOAD;
      } else if (this.navService.hasStep(EstimateStep.SELECT_TEAM)) {
        nextStep = EstimateStep.SELECT_TEAM;
      } else {
        nextStep = EstimateStep.COUNTRY_SELECT;
      }
      break;
    case EstimateStep.SOURCE_FILE_UPLOAD:
      // NOTE: When uploading a file for PCT/EP, we don't navigate to the next step. (Task 2072)
      // if (this.navService.hasStep(EstimateStep.SELECT_TEAM)) {
      //   nextStep = EstimateStep.SELECT_TEAM;
      // } else {
      //   nextStep = EstimateStep.COUNTRY_SELECT;
      // }
      break;
    case EstimateStep.SELECT_TEAM:
      // There is no country selection for EP Grant projects.
      if (this.patentType === 'EP' && this.patentPhase === 'EP_GRANT') {
        nextStep = EstimateStep.TURNAROUND_TIME;
      } else {
        // Note: Need the offset to align it properly.
        offset = -150;
        nextStep = EstimateStep.COUNTRY_SELECT;
      }
      break;
    case EstimateStep.COUNTRY_SELECT:
      nextStep = EstimateStep.TURNAROUND_TIME;
      scrollToTop = true;
      break;
    case EstimateStep.TURNAROUND_TIME:
      if (this.modifyingTurnaroundInd) {
        nextStep = EstimateStep.SET_TURNAROUND_TIME;
      } else {
        nextStep = EstimateStep.ESTIMATE_TABLE;
      }
      break;
    case EstimateStep.SET_TURNAROUND_TIME:
      nextStep = EstimateStep.MATTER_DOCKET_NUMBER;
      break;
    case EstimateStep.ESTIMATE_TABLE:
      nextStep = EstimateStep.MATTER_DOCKET_NUMBER;
      break;
    case EstimateStep.MATTER_DOCKET_NUMBER:
      nextStep = EstimateStep.NOTES;
      break;
    case EstimateStep.DIRECT_FILING_TYPE:
      nextStep = EstimateStep.DIRECT_TEAM_SELECT;
      break;
    case EstimateStep.DIRECT_TEAM_SELECT:
      // Note: Need the offset to align it properly.
      offset = -150;
      nextStep = EstimateStep.DIRECT_COUNTRY;
      break;
    case EstimateStep.DIRECT_COUNTRY:
      nextStep = EstimateStep.DIRECT_APPLICATION_TYPE;
      break;
    case EstimateStep.DIRECT_APPLICATION_TYPE:
      nextStep = EstimateStep.DIRECT_UPLOAD;
      break;
    case EstimateStep.DIRECT_UPLOAD:
      nextStep = EstimateStep.TURNAROUND_TIME;
      scrollToTop = true;
      break;
    default:
      this.loggingService.info('Something went wrong with step ', step);
    }
    if (!!nextStep) {
      this.activeStep = nextStep;
      setTimeout(() => this.currentStepEmitter.emit(this.activeStep), timeout);
      nextStep = '#' + nextStep;
      this.loggingService.info('Going from step ', step, ' to ', nextStep);
      if (scrollToTop) {
        this.loggingService.info('Going to the top of the step');
        setTimeout(() => this.navService.navigateTo(nextStep, 'start'), timeout);
      } else {
        setTimeout(() => this.navService.navigateTo(nextStep, 'center', offset), timeout);
      }
    }
  }

  public goToStep(step: string): void {
    if (step !== this.activeStep) {
      this.activeStep = step;
      this.loggingService.info('Going to step ', step);
      this.currentStepEmitter.emit(step);
      this.navService.navigateTo('#' + step);
    }
  }

  public setEstimateTeam(team: CompanyGroupLite): void {
    this.estimateTeam = team;
    this.estimateTeamEmitter.emit(this.estimateTeam);
    if (!!team) {
      this.estimateRequest.groupId = team.id;
    }
  }

  public getPatentTypeConfigId(): number {

    const patentTypeConfig = this.configService.patentTypes.find(patentType => patentType.name === this.patentType);
    if (patentTypeConfig) {
      return patentTypeConfig.id;
    }
    return null;
  }

  public determineEstimateCountryDisplayDeadline(projectResponse: ProjectResponse): void {
    if (isNullOrUndefined(projectResponse) || isNullOrUndefined(projectResponse.documents)) {
      return;
    }

    projectResponse.documents.forEach(document => {
      if (!isNullOrUndefined(document.filings)) {
        document.filings.forEach(filing => {

          const displayDeadline = [];
          const displayDeadlineText = [];
          const displayDeadlineSections: string[][] = [];
          let showDisplayDeadlineTime = true; // only not show time is when country filing deadline is display.
          let displayDays: number;
          let displayDaysSections = [];
          let hasGoodDeadline = true;

          // 1. project user deadline with no country user deadline!
          if (!!projectResponse.userEstimateDeadline && !filing.userDeadline &&
            this.compareDates(projectResponse.userEstimateDeadline, filing.calculatedDeadline)) {
            displayDeadline.push(filing.calculatedDeadline);

            // if we have a project deadline and extension, make sure to add text stating filing extension
            if (filing.filing && !!filing.extensionDeadline) {
              displayDeadlineText.push('Extended Filing Deadline');
            } else if (filing.extensionSections && filing.extensionSections.length > 0) {
              // if a project deadline and taking an extension (I guess it could happen)
              // if we have non-extension & extension deliverables add them together so we see the
              // extension icon
              if (filing.nonExtensionSections) {
                displayDeadlineSections.push(filing.nonExtensionSections);
                displayDeadlineSections[0] = displayDeadlineSections[0].concat(filing.extensionSections);
              } else {
                // otherwise just display the extension sections
                displayDeadlineSections.push(filing.extensionSections);
              }
            }
          } else if (filing.userDeadline || filing.userExtensionDeadline) {
            // 2. is country user deadline
            if (filing.multipleDeliveriesRequired) {
              // has non extension and extension has different country user deadline!
              if (filing.userDeadline) {
                displayDeadline.push(filing.userDeadline);
              } else {
                displayDeadline.push(filing.internalFilingDeadline);
              }

              if (filing.userExtensionDeadline) {
                displayDeadline.push(filing.userExtensionDeadline);
              } else {
                if (filing.filing) {
                  displayDeadline.push(filing.extensionDeadline);
                } else {
                  displayDays = filing.estimatedBusinessDaysToCalculatedDeadline;
                  displayDaysSections = filing.extensionSections;
                }
              }

              if (filing.filing) {
                displayDeadlineText.push('Filing Deadline');
                displayDeadlineText.push('Extended Translation');
              } else {
                displayDeadlineSections.push(filing.nonExtensionSections);
                displayDeadlineSections.push(filing.extensionSections);
              }
            } else {
              // one country user deadline
              displayDeadline.push(filing.calculatedDeadline);

              if (filing.filing && !!filing.extensionDeadline) {
                displayDeadlineText.push('Extended Filing Deadline');
              } else if (filing.extensionSections && filing.extensionSections.length > 0) {
                displayDeadlineSections.push(filing.extensionSections);
              }
            }
          } else if (filing.filing) {
            // 3. not user deadline and country require filing
            showDisplayDeadlineTime = false;
            if (!!filing.extensionDeadline && !!filing.multipleDeliveriesRequired) {
              // two filing deadline
              displayDeadline.push(filing.filingDeadline);
              displayDeadlineText.push('Filing Deadline');
              displayDeadline.push(filing.extensionDeadline);
              displayDeadlineText.push('Extended Translation');
            } else {
              // one filing deadline
              if (!!filing.extensionDeadline) {
                displayDeadline.push(filing.extensionDeadline);
                displayDeadlineText.push('Extended Filing Deadline');
              } else if (!!filing.filingDeadline) {
                displayDeadlineText.push('Filing Deadline');
                displayDeadline.push(filing.filingDeadline);
              }
            }
          } else if (!filing.filing && !!filing.multipleDeliveriesRequired && !!filing.internalFilingDeadline &&
            !!filing.estimatedBusinessDaysToCalculatedDeadline) {
            // translation only with both extension and non extension,
            // show both the internal filing deadline and number of days

            displayDeadlineSections.push(filing.nonExtensionSections);
            displayDeadline.push(filing.internalFilingDeadline);
            showDisplayDeadlineTime = false;
            displayDays = filing.estimatedBusinessDaysToCalculatedDeadline;
            displayDaysSections = filing.extensionSections;
          } else if (!!filing.rush || !!filing.multipleDeliveriesRequired) {
            if (isNullOrUndefined(filing.calculatedDeadline)) {
              hasGoodDeadline = false;
            } else {
              displayDeadline.push(filing.calculatedDeadline);
            }
          } else {
            displayDays = filing.estimatedBusinessDaysToCalculatedDeadline;
            displayDaysSections = filing.extensionSections;
          }

          filing.displayDays = displayDays;
          filing.displayDaysSections = displayDaysSections;
          filing.displayDeadlines = displayDeadline;
          filing.displayDeadlineText = displayDeadlineText;
          filing.displayDeadlineSections = displayDeadlineSections;
          filing.showDisplayDeadlineTime = showDisplayDeadlineTime;
          filing.hasGoodDeadline = hasGoodDeadline;
        });
      }
    });
  }

  private compareDates(dateOne: Date, dateTwo: Date): boolean {
    if (isNullOrUndefined(dateOne) || isNullOrUndefined(dateTwo)) {
      return false;
    }

    return dateOne.valueOf() === dateTwo.valueOf();
  }
}
