import * as _ from 'underscore';
import { Injectable } from '@angular/core';
import { WorkflowService } from '../../data-services/workflow-service';
import { AuthService } from '../../security/auth.service';
import { FilterSelectionMemoryService } from '../../shared/data-filter/filter-selection/filter-selection-memory.service';
import { PortalUser } from '../../shared/domain/user';
import { ProjectResponse } from '../../shared/response/project-response';
import { PaginatedData, PaginateObject } from '../../shared/dto/paginated-data';
import { ProjectService } from '../../data-services/project.service';
import { Router } from '@angular/router';
import { UserService } from '../../shared/services/user.service';
import { PROJECT_CATEGORY_VALUE } from '../../shared/dto/system-config/project-category-value';
import { EstimatesOverviewV2Response } from '../estimates-overview-v2/estimates-overview-v2-response';
import { OrganizationManagementService } from '../../settings-v2/services/organization-management.service';
import { isNullOrUndefined } from '../../shared/utils/is-null-or-undefined';

@Injectable()
export class EstimatesOverviewService {

  private navigateInProgress = false;

  private paginationData: PaginateObject<EstimatesOverviewV2Response>;

  private dataStoreId = 'townip_estimates_pagination';

  constructor(private userService: UserService,
              private filterMemory: FilterSelectionMemoryService,
              private projectService: ProjectService,
              private workflowService: WorkflowService,
              private router: Router,
              private authService: AuthService,
              private orgService: OrganizationManagementService) {
    this.initPaginationData();
  }

  public savePaginationData(data: PaginatedData<EstimatesOverviewV2Response>): void {
    const content = data.content;

    const paginationData = data.toObject();
    paginationData.columnValues = [];

    paginationData.page.content = content.map(estimate => {
      return {
        id: +estimate.id,
        projectCategory: {
          projectCategory: estimate.projectCategoryString
        },
        projectEstimateStatus: estimate.projectEstimateStatus,
        patentPhase: estimate.patentPhase
      };
    });
    this.paginationData = paginationData;
    window
      .localStorage
      .setItem(this.dataStoreId, JSON.stringify(paginationData));
  }

  public resolveWorkflowActions(estimates: EstimatesOverviewV2Response[]): void {
    if (!estimates || isNullOrUndefined(this.workflowService.workflowActions)) {
      return;
    }
    const workflowActions = this.workflowService.workflowActions;
    estimates
      .filter(estimate => estimate.workflowActions)
      .forEach(estimate => {
        estimate.resolvedWorkflowActions = estimate
          .workflowActions
          .map(id => workflowActions.find(workflowAction => workflowAction.id === id))
          .filter(workflowAction => !isNullOrUndefined(workflowAction));
      });
  }

  public isFirstEstimate(id: number): boolean {
    if (!id || !this.paginationData || !this.paginationData.page.content.length) {
      return true;
    }
    const content = this.paginationData.page.content;
    const index = content.findIndex(estimate => {
      return estimate.id === id;
    });
    return this.paginationData.page.first && index === 0 || index === -1;
  }

  public isLastEstimate(id: number): boolean {
    if (!id || !this.paginationData || !this.paginationData.page.content.length) {
      return true;
    }
    const lastIndex = this.paginationData.page.content.length - 1;
    const content = this.paginationData.page.content;
    const index = content.findIndex(estimate => {
      return estimate.id === id;
    });
    return this.paginationData.page.last && index === lastIndex || index === -1;
  }

  public traverseEstimates(id: number, isNext: boolean): void {
    if (this.navigateInProgress) {
      return;
    }
    this.navigateInProgress = true;

    this.getPrevNextEstimate(id, isNext)
      .then(estimate => {
        this.navigateInProgress = false;
        if (estimate) {
          this.gotoEstimate(estimate);
        }
      });
  }

  public gotoEstimate(estimate: any, openNewTab: boolean = false, returnUrl: boolean = false): string {
    const user = this.userService.getUser();
    let url = '';

    // NO available identifier for project category other than the ID.
    if ((estimate.projectCategory && estimate.projectCategory.projectCategory === 'LEGAL_TRANSLATION') ||
      estimate.projectType === 'LEGAL_TRANSLATION') {
      url = this.navigateLegalTranslationProject(estimate, user);
    } else {
      url = this.navigateProsecutionProject(estimate, user);
    }

    if (openNewTab) {
      window.open(url, '_blank');
      return;
    }

    if (returnUrl) {
      return url;
    }

    this.router.navigate([url]);
  }

  public viewAsClient(estimate: ProjectResponse): void {
    if (estimate.projectCategory.projectCategory === PROJECT_CATEGORY_VALUE.LegalTranslation) {
      this.viewAsClientGeneralTranslation(estimate);
    }

    if (estimate.projectCategory.projectCategory === PROJECT_CATEGORY_VALUE.PatentProsecution) {
      this.viewAsClientPatentProsecution(estimate);
    }
  }

  private viewAsClientPatentProsecution(estimate: ProjectResponse): void {
    if (!!estimate.patentPhase && (estimate.patentPhase === 'EP_GRANT' || estimate.patentPhase === 'EP_VALIDATION')) {
      this.router.navigate(['estimate/ep-review/', estimate.id]);
      return;
    } else {
      this.router.navigate(['estimate/project-review/', estimate.id]);
      return;
    }
  }

  private viewAsClientGeneralTranslation(estimate: ProjectResponse): void {
    this.router.navigate(['estimate/general-translation/', estimate.id]);
  }

  private initPaginationData(): void {
    const paginationStr = window
      .localStorage
      .getItem(this.dataStoreId);
    if (paginationStr) {
      this.paginationData = JSON.parse(paginationStr);
    }

    this.authService
      .connectedEmitter
      .subscribe(loggedIn => {
        if (!loggedIn) {
          window
            .localStorage
            .removeItem(this.dataStoreId);
          this.paginationData = null;
        }
      });
  }

  private getPrevNextEstimate(id: number, isNext: boolean): Promise<EstimatesOverviewV2Response> {
    const content = this.paginationData.page.content;

    if (!content.length || this.isFirstEstimate(id) && !isNext || this.isLastEstimate(id) && isNext) {
      return Promise.resolve(null);
    }

    const index = content.findIndex(estimate => {
      return +estimate.id === id;
    });

    if (index < 0) {
      return Promise.resolve(null);
    }

    const newIndex = index + (isNext ? 1 : -1);

    // if newIndex is within the list - return estimate at newIndex
    if (newIndex >= 0 && newIndex < content.length) {
      return Promise.resolve(content[newIndex]);
    }

    // if newIndex is outside of list - navigate to the next/previous page
    this.paginationData.page.number += (isNext ? 1 : -1);
    return this.fetchData()
      .then(newContent => {
        return content.length ? newContent[isNext ? 0 : this.paginationData.page.size - 1] : null;
      });
  }

  private fetchData(): Promise<EstimatesOverviewV2Response[]> {
    const paginationTemp = new PaginatedData<EstimatesOverviewV2Response>(this.paginationData);
    return this.projectService
      .getProjectEstimatesV2(paginationTemp)
      .toPromise()
      .then(() => {
        this.savePaginationData(paginationTemp);
        return this.paginationData.page.content;
      })
      .catch(() => {
        return [];
      });
  }

  private navigateProsecutionProject(estimate: any, user: PortalUser): string {
    if (user.userType === 'INTERNAL' &&
      !(this.userService.authorizations.ROLE_INTERNAL_SALES_MGR ||
        this.userService.authorizations.ROLE_INTERNAL_ACCOUNT_MGR)) {
      return `estimate/estimate-review/${estimate.id}`;
    } else if (estimate.projectEstimateStatus === 'IN_PROGRESS' && (user.userType === 'COMPANY' ||
      this.userService.authorizations.ROLE_INTERNAL_SALES_MGR ||
      this.userService.authorizations.ROLE_INTERNAL_ACCOUNT_MGR)) {
      return `estimate/approved/${estimate.id}`;
    } else if (!!estimate.patentPhase && _.contains(['EP_GRANT', 'EP_VALIDATION'], estimate.patentPhase)) {
      return `estimate/ep-review/${estimate.id}`;
    } else {
      this.orgService.clear();
      return `estimate/project-review/${estimate.id}`;
    }
  }

  private navigateLegalTranslationProject(estimate: any, user: PortalUser): string {
    // Internal users go to review page
    if (user.userType === 'INTERNAL' &&
      !(this.userService.authorizations.ROLE_INTERNAL_SALES_MGR ||
        this.userService.authorizations.ROLE_INTERNAL_ACCOUNT_MGR)) {
      return `estimate/general-translation-review/${estimate.id}`;
    }

    if (this.userService.authorizations.ROLE_INTERNAL_SALES_MGR ||
      this.userService.authorizations.ROLE_INTERNAL_ACCOUNT_MGR &&
      estimate.projectEstimateStatus === 'APPROVED') {
      return `estimate/general-translation/${estimate.id}`;
    }

    // Other user types go to client review page
    if (_.contains(['READY_FOR_APPROVAL', 'REJECTED', 'EXPIRED'], estimate.projectEstimateStatus)) {
      return `estimate/general-translation/${estimate.id}`;
    }

    // Everything else go to pending.
    return `estimate/general-translation/${estimate.id}/pending`;
  }
}
