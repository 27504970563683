// Add global variables here.

export const DATE_INTERCHANGE_FORMAT = 'YYYY-MM-DD';
export const ANNUITY_FULFILLMENT_FILETYPES = [
  'OFFICIAL_RECEIPT',
  'PROOF_OF_PAYMENT',
  'REGISTER_EXTRACT',
  'RENEWAL_CERTIFICATE',
  'OTHER',
];
