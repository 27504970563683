import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DebugComponent } from './debug.component';
import { DebugRoutingModule } from './debug-routing.module';
import { SharedModule } from '../shared/shared.module';
import { PaginatorModule } from 'primeng/paginator';
import { EditorV2Module } from '../controls/editor-v2/editor-v2.module';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    DebugRoutingModule,
    SharedModule,
    PaginatorModule,
    EditorV2Module,
    FormsModule
  ],
  declarations: [DebugComponent]
})
export class DebugModule { }
