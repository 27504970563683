import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GlobalModalComponent } from './global-modal.component';
import { ModalModule } from '../modal/modal.module';



@NgModule({
  declarations: [GlobalModalComponent],
  exports: [GlobalModalComponent],
  imports: [
    CommonModule,
    ModalModule
  ]
})
export class GlobalModalModule { }
