import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilesAssociateTasksComponent } from './files-associate-tasks.component';
import { DataFilterModule } from '../../data-filter/data-filter.module';
import { TableModule } from 'primeng/table';
import { PipesModule } from '../../pipes/pipes.module';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    DataFilterModule,
    TableModule,
    PipesModule,
    FormsModule
  ],
  exports: [FilesAssociateTasksComponent],
  declarations: [FilesAssociateTasksComponent]
})
export class FilesAssociateTasksModule { }
