import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataFilterPipe } from './data-filter.pipe';
import { FilterCategoryPipe } from './filter-category.pipe';
import { FilterGroupSetPipe } from './filter-group-set.pipe';
import { IsFilterActivePipe } from './is-filter-active.pipe';
import { IsFilterDisabledPipe } from './is-filter-disabled.pipe';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    DataFilterPipe,
    FilterCategoryPipe,
    IsFilterActivePipe,
    IsFilterDisabledPipe,
    FilterGroupSetPipe,
  ],
  exports: [
    DataFilterPipe,
    FilterCategoryPipe,
    IsFilterActivePipe,
    IsFilterDisabledPipe,
    FilterGroupSetPipe,
  ]
})
export class DataFilterPipesModule { }
