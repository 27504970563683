import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterSelectionV2Component } from './filter-selection-v2.component';
import { DataFilterPipesModule } from '../data-filter-pipes/data-filter-pipes.module';

@NgModule({
  imports: [
    CommonModule,
    DataFilterPipesModule,
  ],
  declarations: [FilterSelectionV2Component],
  exports: [FilterSelectionV2Component]
})
export class FilterSelectionV2Module { }
