import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { isNullOrUndefined } from '../../utils/is-null-or-undefined';

@Component({
  selector: 'townip-initials',
  templateUrl: './initials.component.html',
  styleUrls: ['./initials.component.scss']
})
export class InitialsComponent implements OnInit, OnChanges {

  @Input()
  public data: string;

  @Input()
  public size: number; // in pixels

  @Input()
  public fontSize: string; // e.g 12rem

  @Input()
  public color: string;

  public initials: string;

  public lastLetter: string;

  public style: any;

  constructor() {
    this.size = 40; // default size
    this.fontSize = '1.0rem';
    this.buildStyle();
  }

  public ngOnInit(): void {
    this.buildStyle();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    // remove any character that is not a letter or number or whitespace
    const sanitized = this.data.replace(/[^a-zA-Z0-9\s]/g, '');

    const ref = sanitized.split(' ');
    this.initials = ref[0][0];

    if (!isNullOrUndefined(ref[1]) && ref[1] !== 'undefined') {
      const second = ref[1][0];
      if (!isNullOrUndefined(second)) {
        this.initials += second;
      }
    }

    if (!this.color) {
      this.lastLetter = sanitized
        .trim()
        .slice(-1)
        .toLowerCase();
    }
    this.buildStyle();
  }

  private buildStyle(): void {
    this.style = {
      'width.px': this.size,
      'height.px': this.size,
      'borderRadius.px': this.size,
      backgroundColor: this.color
    };
  }
}
