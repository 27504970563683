import { SelectItem } from 'primeng/api';

export enum IpRightType {
  Patent = 'PATENT',
  Spc = 'SPC',
  UtilityModel = 'UTILITY',
  Design = 'DESIGN',
  Trademark = 'TRADEMARK',
}

export const IP_RIGHT_TYPES: SelectItem[] = [
  { label: 'Patent', value: IpRightType.Patent },
  { label: 'SPC', value: IpRightType.Spc },
  { label: 'Utility Model', value: IpRightType.UtilityModel },
  { label: 'Design', value: IpRightType.Design },
  { label: 'Trademark', value: IpRightType.Trademark },
];
