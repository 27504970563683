import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'scopeFilter'
})
export class ScopeFilterPipe implements PipeTransform {

  public transform(value: any, args?: any): any {
    return value.filter(obj => obj.scopeType === args.scopeFilter);
  }

}
