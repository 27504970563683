import { Component, forwardRef, Input, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { EditorComponent } from '@tinymce/tinymce-angular';
import { EDITOR_ICONS } from './editor-v2-icons';

export interface EditorV2GetContentParams {
  format?: string;
}

export interface EditorV2Button {
  name: string;
  icon?: string;
  tooltip?: string;
  text?: string;
  onAction?: any;
}

@Component({
  selector: 'townip-editor-v2',
  templateUrl: './editor-v2.component.html',
  styleUrls: ['./editor-v2.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => EditorV2Component),
    multi: true,
  }]
})
export class EditorV2Component implements OnInit, ControlValueAccessor {

  @Input()
  public height = 500;

  @Input()
  public styleClass = '';

  @Input()
  public customButtons: EditorV2Button[];

  @Input()
  public placeholder = '';

  @Output()
  public init = new EventEmitter<void>();

  @ViewChild(EditorComponent, { static: true })
  public editor: EditorComponent;

  public content: string;

  public onChange: (value: any) => void;

  public onTouched: () => void;

  public isTouched = false;

  public isDisabled = false;

  public initParams: any;

  constructor() {
  }

  public ngOnInit(): void {
    this.initParams = {
      height: this.height,
      menubar: false,
      statusbar: false,
      plugins: [
        'advlist autolink lists table paste'
      ],
      toolbar: 'bold italic underline | alignleft aligncenter alignright | bullist numlist | attachments',
      placeholder: this.placeholder,
      setup: editor => this.setupEditor(editor)
    };
  }

  public writeValue(obj: string): void {
    this.content = obj;
  }

  public registerOnChange(func: any): void {
    this.onChange = func;
  }

  public registerOnTouched(func: any): void {
    this.onTouched = func;
  }

  public updateModel(value: string): void {
    this.onChange(value);
  }

  public touch(): void {
    if (!this.isTouched) {
      this.isTouched = true;
    }
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  public getContent(params?: EditorV2GetContentParams): string {
    if (!this.editor.editor) {
      return this.content;
    }
    const tinyMceEditor = this.editor.editor;
    // @ts-expect-error unknown params
    return tinyMceEditor.getContent(params);
  }

  private setupEditor(editor: any): void {
    // add custom icons
    EDITOR_ICONS.forEach(icon => editor.ui.registry.addIcon(icon.name, icon.src));

    // add custom buttons from input
    if (Array.isArray(this.customButtons)) {
      this.customButtons.forEach(button => {
        editor.ui.registry.addButton(button.name, {
          icon: button.icon,
          tooltip: button.tooltip,
          text: button.text,
          onAction: button.onAction
        });
      });
    }
  }

}
