import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { UserService } from '../services/user.service';
import { DashboardPreference } from '../enums/dashboard-preference';

@Injectable()
export class DefaultInvoicePreferenceGuardService {

  constructor(
    private router: Router,
    private userService: UserService,
  ) {
  }

  public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    if (state.url !== '/invoices') {
      return true;
    }

    if (this.userService.user.defaultDashboardPreference !== DashboardPreference.Annuities) {
      this.router.navigate(['/invoices/translation-filing']);
      return false;
    }
    this.router.navigate(['/annuities/company/invoices']);
    return false;
  }
}
