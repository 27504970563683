<div class="quickview-container" [hidden]="renderMenu === false">
  <div class="quickview">
    <townip-message-connection-status
      warning="You might not receive messages while disconnected">
    </townip-message-connection-status>
    <div class="quickview-caret"><i class="fa fa-caret-up"></i></div>
    <div class="messages-header d-flex align-items-center font-weight-normal">
      <div>{{messages?.length}} New Messages</div>
      <div class="ml-auto font-weight-normal">
        <a href="#" routerLink="/messages">View All</a>
      </div>
    </div>
    <!-- Do not render the the list when dropdown not shown. -->
    <!-- it will incur major performance loss if there are more than 100+ alerts/messages -->
    <div class="messages-container" *ngIf="renderMenu">
      <div *ngIf="unreadMessageCount===0" class="p-2 text-center">Currently no new messages</div>
      <cdk-virtual-scroll-viewport itemSize="47" class="scroll-container">
        <div
          class="card-container row align-items-center padding-5"
          (click)="navigate(message)"
          *cdkVirtualFor="let message of messages">
          <div class="col-1">
            <townip-initials
              *ngIf="!message.childMessages || (message.childMessages && message.childMessages.length === 0)"
              class="margin-top-5"
              fontSize="12px"
              [size]="30"
              [data]="message.creator.firstName + ' ' + message.creator.lastName">
            </townip-initials>
            <townip-initials
              *ngIf="message.childMessages && message.childMessages.length > 0"
              class="margin-top-5"
              fontSize="12px"
              [size]="30"
              [data]="message.childMessages[0].creator.firstName + ' ' + message.childMessages[0].creator.lastName">
            </townip-initials>
          </div>
          <div class="col-6 content-container">
            <div class="font-weight-normal">
              <ng-container *ngFor="let user of message.messageRecipients; let i = index">
                <ng-container *ngIf="i < 2">
                  {{user.user.firstName}}
                  <span *ngIf="i === 0 && message.messageRecipients && message.messageRecipients.length > 1">,</span><span
                  *ngIf="i === 1 && message.messageRecipients.length > 2">...</span>
                </ng-container>
              </ng-container>
              <span *ngIf="message.messageRecipients && message.messageRecipients.length > 2">
                +{{message.messageRecipients.length - 2}}
              </span>
            </div>
            <div class="content">
              <ng-container *ngIf="message.childMessages?.length > 0">
                <!-- Always select the last message -->
                <div class="message-content text-truncate">{{message.childMessages[0].messageBody | htmlToText}}</div>
              </ng-container>
              <ng-container *ngIf="message.childMessages === undefined || message.childMessages?.length === 0">
                <div class="message-content text-truncate">{{message.messageBody | htmlToText}}</div>
              </ng-container>
            </div>
          </div>
          <div class="col-5 text-right">
            <div
              *ngIf="!message.childMessages || (message.childMessages && message.childMessages.length === 0)"
              class="timestamp small font-weight-light">
              {{message.creationTime | date:'MMM d \'\'\'yy'}}
            </div>
            <div
              *ngIf="message.childMessages && message.childMessages.length > 0"
              class="timestamp small font-weight-light">
              {{message.childMessages[0].creationTime | date:'MMM d \'\'\'yy'}}
            </div>
            <div class="ml-auto" *ngIf="message.scope && message.scopeName">
              <button
                (click)="$event.stopPropagation(); navigateScope(message)"
                *ngIf="message.scope"
                title="{{message.scope + ' ' + message.scopeName}}"
                class="btn btn-reply-scope text-truncate {{message.scope}} pull-right">
                {{message.scope + ' ' + message.scopeName}}
              </button>
            </div>
          </div>
        </div>
      </cdk-virtual-scroll-viewport>
    </div>
  </div>
</div>
