import { Observable } from 'rxjs';
import { EventEmitter, Injectable } from '@angular/core';
import { EstimatesOverviewV2Response } from '../estimate/estimates-overview-v2/estimates-overview-v2-response';
import { ProjectRequest } from '../shared/request/project-request';
import { OrderRequest } from '../shared/request/order-request';
import { FileKeyPair } from '../shared/dto/file-key';
import { RequestService } from '../shared/services/request-service';
import { ProjectResponse } from '../shared/response/project-response';
import { ProjectRevisionResponse } from '../shared/response/project-revision-response';
import { PatentDocumentDetails } from '../shared/response/patent-document-details';
import { UpdateProjectRequest } from '../shared/request/update-project-request';
import { BillingInfo } from '../shared/dto/billing-info';
import { Company } from '../shared/dto/company';
import { UserService } from '../shared/services/user.service';
import { EstimateService } from '../estimate/estimate.service';
import { UpdateProjectStatusRequest } from '../shared/request/update-project-status-request';
import { UpdateProjectGeneralInfoRequest } from '../shared/request/update-project-general-info-request';
import { UpdateServiceRequest } from '../shared/request/update-service-request';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { GeneralResponseMessage } from '../shared/dto/general-response-message';
import { ApiQueryOptions, ApiQueryParams } from '../shared/api-query-params';
import { FileKeySupplementalData } from '../shared/dto/file-key-supplemental-data';
import { WebsocketService } from '../shared/services/websocket.service';
import { EstimateWebsocketMessage } from '../shared/dto/messaging/estimate-websocket-message';
import { DocumentService } from './document.service';
import { UpdateAdditionalServiceRequest } from '../shared/request/update-additional-service-request';
import { UpdateGeneralTransDocumentsRequest } from '../shared/request/update-general-trans-documents-request';
import { HttpServiceRequestOptions } from '../shared/request/http-service-request-options';
import { HttpRequestErrorInterceptorMessages } from '../shared/interceptors/http-request-error-interceptor-messages';
import { ProjectMetaData } from '../shared/response/project-meta-data';
import { PaginatedColumnValueIndex, PaginatedData, PaginateObject } from '../shared/dto/paginated-data';
import { map, tap } from 'rxjs/operators';
import { RequestFilterNumberResponse } from '../shared/query/request-filter.component';
import { ColumnValues, FilterScope, PaginatedRequest } from '../shared/query/paginated-request.component';
import { PaginatedResponse } from '../shared/query/paginated-response.component';
import { LoggingService } from '../shared/logging.service';
import { BillableComponentTaskSummary } from '../shared/dto/projects/billable-component-task-summary';
import { OrderUpdateRequest } from '../shared/dto/order/order-update-request';
import { EstimateSummary } from '../shared/dto/estimate-summary';
import { isNullOrUndefined } from '../shared/utils/is-null-or-undefined';
import { TableColumn } from '../shared/table-column';

export enum TableRequestType {
  ESTIMATE = 'ESTIMATE',
  PROJECT = 'PROJECT',
  TASK = 'TASK'
}

@Injectable()
export class ProjectService {
  public submitted = false;

  public estimateEventEmitter = new EventEmitter<ProjectResponse[]>();

  constructor(private httpClient: HttpClient,
              private requestService: RequestService,
              private userService: UserService,
              private estimateService: EstimateService,
              private websocketService: WebsocketService,
              private httpRequestErrorInterceptor: HttpRequestErrorInterceptorMessages,
              private docService: DocumentService,
              private loggingService: LoggingService) {
    this.websocketService.websocketUpdateEmitter.subscribe(data => {
      if (data.message.type === 'ESTIMATE') {
        const estimateData = this.getEstimatesFromWebSocketMessage(data.message);
        Promise.all(estimateData)
          .then(projectEstimates => {
            this.estimateEventEmitter.emit(projectEstimates);
          });
      }
    });
  }

  public getProjectEstimate(estimateId: number): Observable<ProjectResponse> {
    const url = `/api/project/estimate/${estimateId}`;
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient.get<ProjectResponse>(url, { headers: headers });
  }

  public getTransformedProjectEstimate(estimateId: number): Observable<ProjectResponse> {
    const url = `/api/project/estimate/${estimateId}/transform`;
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient.get<ProjectResponse>(url, { headers: headers });
  }

  public getUnlinkedEstimatesForUser(companyUserId: number): Observable<ProjectMetaData[]> {
    const url = `/api/project/estimate/unlinked/customer/${companyUserId}`;
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient.get<ProjectMetaData[]>(url, { headers: headers });
  }

  public getProjectEstimatesV2(request: PaginatedData<EstimatesOverviewV2Response>): Observable<PaginatedData<EstimatesOverviewV2Response>> {
    const url = '/api/v2/project/estimate';
    const headers = this.requestService.buildHttpHeaders();

    // const requestSample = {
    //   columnValues: [
    //     { field: 'customerLite.firstName' },
    //     { field: 'customerLite.lastName' },
    //   ],
    //   filter: '',
    //   scope: 'MINE',
    //   page: {
    //     number: 1,
    //     size: 20,
    //     sort: [],
    //   }
    // };

    return this.httpClient
      .post<any>(url, request.toObject(), { headers: headers })
      .pipe(
        // Update the given pagination object with values from the response.
        map((response: PaginateObject<EstimatesOverviewV2Response>) => request.update(response))
      );
  }

  public getProjects(query: string, scope: FilterScope): Promise<PaginatedResponse> {
    const url = '/api/v2/projects';

    const request: PaginatedRequest = new PaginatedRequest();
    request.filter = query;
    request.scope = scope;

    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient.post<PaginatedResponse>(url, request, { headers: headers })
      .toPromise();
  }

  public getProjectEstimatesQueryCountPromiseWithScope(query: string,
    requestType: TableRequestType,
    scope: FilterScope): Promise<RequestFilterNumberResponse> {
    let url = '';

    if (requestType === TableRequestType.PROJECT) {
      url = '/api/v2/projects/counts';
    } else if (requestType === TableRequestType.ESTIMATE) {
      url = '/api/v2/project/estimate/counts';
    } else if (requestType === TableRequestType.TASK) {
      url = '/api/v2/tasks/counts';
    }

    const request: PaginatedRequest = new PaginatedRequest();
    request.filter = query;
    request.scope = scope;

    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient.post<RequestFilterNumberResponse>(url, request, { headers: headers })
      .toPromise();
  }

  public getProjectEstimatesQueryCountPromise(query: string, requestType: TableRequestType, scope: FilterScope):
  Promise<RequestFilterNumberResponse> {
    let url = '';

    if (requestType === TableRequestType.PROJECT) {
      url = '/api/v2/projects/counts';
    } else if (requestType === TableRequestType.ESTIMATE) {
      url = '/api/v2/project/estimate/counts';
    } else if (requestType === TableRequestType.TASK) {
      url = '/api/v2/tasks/counts';
    }

    const request: PaginatedRequest = new PaginatedRequest();
    request.filter = query;
    request.scope = scope;

    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient.post<RequestFilterNumberResponse>(url, request, { headers: headers })
      .toPromise();
  }

  public getProjectEstimatesQuerySumPromise(query: string,
    colName: string,
    requestType: TableRequestType,
    scope: FilterScope): Promise<RequestFilterNumberResponse> {
    let url = '';

    if (requestType === TableRequestType.PROJECT) {
      url = '/api/v2/projects/sum';
    } else if (requestType === TableRequestType.ESTIMATE) {
      url = '/api/v2/project/estimate/sum';
    } else if (requestType === TableRequestType.TASK) {
      url = '/api/v2/tasks/sum';
    }

    const request: PaginatedRequest = new PaginatedRequest();
    request.filter = query;

    const colVals: ColumnValues = new ColumnValues();
    colVals.field = colName;

    const colValsArray: ColumnValues[] = [];
    colValsArray.push(colVals);

    request.columnValues = colValsArray;
    request.scope = scope;

    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient.post<RequestFilterNumberResponse>(url, request, { headers: headers })
      .toPromise();
  }

  public updateEstimateStatus(estimateUpdate: UpdateProjectStatusRequest): Observable<ProjectResponse> {
    const url = `/api/project/estimate/${estimateUpdate.estimateId}/status`;
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient.put<ProjectResponse>(url, estimateUpdate, { headers: headers });
  }

  public updateGeneralInfo(generalInfoUpdate: UpdateProjectGeneralInfoRequest): Observable<ProjectRevisionResponse[]> {
    const url = `/api/project/estimate/general/${generalInfoUpdate.estimateId}`;
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient.put<ProjectRevisionResponse[]>(url, generalInfoUpdate, { headers: headers });
  }

  public updateOverallEstimate(estimateUpdate: UpdateProjectRequest): Observable<ProjectRevisionResponse[]> {
    const url = `/api/project/estimate/${estimateUpdate.estimateId}`;
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient.put<ProjectRevisionResponse[]>(url, estimateUpdate, { headers: headers });
  }

  public changeEstimateType(estimateId: number): Observable<ProjectRevisionResponse[]> {
    const url = `/api/project/estimate/${estimateId}/change-type`;
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient.post<ProjectRevisionResponse[]>(url, null, { headers: headers });
  }

  public rerunEstimate(estimateId: number): Observable<ProjectRevisionResponse[]> {
    const url = `/api/project/estimate/${estimateId}/rerun`;
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient.post<ProjectRevisionResponse[]>(url, null, { headers: headers });
  }

  public hideEstimateFromClient(estimateId: number): Observable<ProjectResponse> {
    const url = `/api/project/estimate/${estimateId}/hide`;
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient.put<ProjectResponse>(url, { headers: headers });
  }

  public getHistoricalProjectEstimates(estimateId: number): Observable<ProjectRevisionResponse[]> {
    const url = `/api/project/estimate/historical/${estimateId}`;
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient.get<ProjectRevisionResponse[]>(url, { headers: headers });
  }

  public getHistoricalProjectEstimateByRevisionId(estimateId: number,
    revisionId: number): Observable<ProjectRevisionResponse> {
    const url = `/api/project/estimate/historical/${estimateId}/revision/${revisionId}`;
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient.get<ProjectRevisionResponse>(url, { headers: headers });
  }

  public getHistoricalProjectEstimatesForProject(projectId: number): Observable<ProjectRevisionResponse[]> {
    const url = `/api/orders/estimate/historical/${projectId}`;
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient.get<ProjectRevisionResponse[]>(url, { headers: headers });
  }

  public getEstimateSummaryRevision(estimateId: number,
    revisionId?: number,
    clientView?: boolean): Observable<EstimateSummary> {
    let url = `/api/project/estimate/${estimateId}/summary`;
    const headers = this.requestService.buildHttpHeaders();

    try {
      const params: ApiQueryOptions = {
        queryParams: {
          revisionId: revisionId ? revisionId.toString() : null,
          clientView: clientView === true ? true : null
        }
      };
      // Append the generated query params
      url += new ApiQueryParams(params).generateQueryParams();
    } catch (e) {
      console.error('Failed to create query parameters for estimate summary');
    }

    return this.httpClient.get<EstimateSummary>(url, { headers: headers });
  }

  public getLatestProjectEstimate(projectId: number): Observable<ProjectResponse> {
    const url = `/api/orders/estimate/${projectId}`;
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient.get<ProjectResponse>(url, { headers: headers });
  }

  public updateDocumentDetails(estimateId: number, documentId: number,
    documentDetails: PatentDocumentDetails): Observable<ProjectRevisionResponse[]> {
    const url = `/api/project/estimate/${estimateId}/document/${documentId}`;
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient.put<ProjectRevisionResponse[]>(url, documentDetails, { headers: headers });
  }

  public submitProjectEstimate(estimateRequest: ProjectRequest[]): Promise<GeneralResponseMessage> {
    this.loggingService.info('Regular Order request, estimate: ', estimateRequest);
    estimateRequest.forEach(request => request.model = 'ESTIMATE');
    const url = '/api/project/estimate';
    const headers = this.requestService.buildHttpHeaders();
    this.submitted = true;

    return this.httpClient.post<GeneralResponseMessage>(url, estimateRequest, { headers: headers })
      .toPromise();
  }

  public submitQuickOrder(estimateRequest: ProjectRequest[]): Promise<GeneralResponseMessage> {
    this.loggingService.info('Quick Order request, estimate: ', estimateRequest);
    const url = '/api/project/estimate';
    const headers = this.requestService.buildHttpHeaders();
    this.submitted = true;

    return this.httpClient.post<GeneralResponseMessage>(url, estimateRequest, { headers: headers })
      .toPromise();
  }

  public updateEstimate(estimateRequest: ProjectRequest, estimateId: number): Promise<GeneralResponseMessage> {
    const url = `/api/project/estimate/update/${estimateId}`;
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.put<GeneralResponseMessage>(url, estimateRequest, { headers: headers })
      .toPromise();
  }

  public placeAndSubmitOrder(customerId: number,
    company: Company,
    projectResponse: ProjectResponse,
    directOrder?: boolean,
    httpServiceRequestOptions?: HttpServiceRequestOptions): Promise<GeneralResponseMessage> {
    const submitterId = this.userService.user.id;
    const estimateId = projectResponse.id;
    const estimate: ProjectRequest = this.estimateService.estimateRequest;
    const billingInfo: BillingInfo = null; // default billing info logic moved to backend.
    const orderRequest: OrderRequest =
      new OrderRequest(customerId, submitterId, estimateId, estimate, billingInfo, directOrder);
    return this.submitOrder(orderRequest, httpServiceRequestOptions);
  }

  public submitOrder(orderRequests: OrderRequest, options?: HttpServiceRequestOptions): Promise<GeneralResponseMessage> {
    const url = '/api/orders';
    const headers = this.requestService.buildHttpHeaders();

    if (options && options.bypassErrorNotification) {
      this.httpRequestErrorInterceptor.add({ url: url, permanent: false, bypass: true });
    }

    return this.httpClient.post<GeneralResponseMessage>(url, orderRequests, { headers: headers })
      .toPromise();
  }

  public removeJurisdiction(estimateId: number, documentId: number, jurisdictionId: number): Promise<ProjectResponse> {
    const url =
      `/api/project/estimate/${estimateId}/document/${documentId}/jurisdiction/${jurisdictionId}`;
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.delete<ProjectResponse>(url, { headers: headers })
      .toPromise();
  }

  public addSupplementalDocs(estimateId: number,
    supplementalDocs: FileKeyPair[]): Observable<ProjectRevisionResponse[]> {
    const url = `/api/project/estimate/${estimateId}/supplementalDocs`;
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.post<ProjectRevisionResponse[]>(url, supplementalDocs, { headers: headers });
  }

  public updateServiceRequest(estimateId: number,
    serviceRequest: UpdateServiceRequest): Observable<ProjectRevisionResponse[]> {
    const url = `/api/project/estimate/${estimateId}/service`;
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.put<ProjectRevisionResponse[]>(url, serviceRequest, { headers: headers });
  }

  public getEstimateAvailableFileCategoryId(estimateId: number): Promise<number[]> {
    const url = `/api/project/estimate/${estimateId}/available/file/categories`;
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.get<any>(url, { headers: headers })
      .toPromise();
  }

  public getOrderCompletionDateByEstimateId(estimateId: number): Promise<Date> {
    const url = `/api/order/completion/estimate/${estimateId}`;
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.get<any>(url, { headers: headers })
      .toPromise();
  }

  public getOrderCompletionDateByProjectId(projectId: number): Promise<Date> {
    const url = `/api/order/completion/project/${projectId}`;
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.get<any>(url, { headers: headers })
      .toPromise();
  }

  public updatePriorityFiles(estimateId: number,
    files: FileKeySupplementalData[]): Observable<FileKeySupplementalData[]> {
    const url = `/api/project/estimate/${estimateId}/priority/files`;
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.put<any>(url, files, { headers: headers });
  }

  public getEstimatesFromWebSocketMessage(estimateWebsocketMessage: EstimateWebsocketMessage):
    Promise<ProjectResponse>[] {
    const estimateData: Promise<ProjectResponse>[] = [];
    estimateData.push(this.getProjectEstimate(estimateWebsocketMessage.id)
      .toPromise());
    if (!isNullOrUndefined(estimateWebsocketMessage.associatedEstimateId)) {
      estimateData.push(this.getProjectEstimate(estimateWebsocketMessage.associatedEstimateId)
        .toPromise());
    }
    return estimateData;
  }

  public getExcelFiles(estimateId: number, opts?: ApiQueryOptions): Promise<HttpResponse<Blob>> {
    let url = `/api/project/estimate/${estimateId}/excel/file`;

    if (opts) {
      // Append the generated query params
      url += new ApiQueryParams(opts).generateQueryParams();
    }

    const headers = this.requestService.buildHttpHeaders();

    const promise = this.httpClient
      .post(url, null, { headers: headers, observe: 'response', responseType: 'blob' })
      .toPromise();

    promise.then(resp => {
      this.docService.saveFile(resp);
    });

    return promise;
  }

  public updateAdditionalServices(estimateId: number, data: UpdateAdditionalServiceRequest): Observable<ProjectRevisionResponse[]> {
    const url = `/api/project/estimate/${estimateId}/addons`;
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient.put<ProjectRevisionResponse[]>(url, data, { headers: headers });
  }

  public updateFileRequest(fileUpdate: UpdateGeneralTransDocumentsRequest): Observable<ProjectRevisionResponse[]> {
    const id: number = fileUpdate.estimateId;
    const url = `/api/project/estimate/${id}/documents`;
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient
      .post<ProjectRevisionResponse[]>(url, fileUpdate, { headers: headers });
  }

  public getBillableOrderCountrySummary(orderId: number): Observable<BillableComponentTaskSummary[]> {
    const url = `/api/project/${orderId}/billable/component/summary`;
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient
      .post<BillableComponentTaskSummary[]>(url, null, { headers: headers });
  }

  public syncToSalesforce(estimateId: number): void {
    const url = `/api/salesforce/${estimateId}`;
    const headers = this.requestService.buildHttpHeaders();
    this.httpClient.post(url, null, { headers: headers })
      .toPromise();
  }

  public sendUpdateMetadataRequest(estimateId: number, updateRequest: OrderUpdateRequest):
    Observable<ProjectRevisionResponse[]> {
    const url = `/api/project/estimate/${estimateId}/metadata`;
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.put<ProjectRevisionResponse[]>(url, updateRequest, { headers: headers });
  }

  public downloadExcel(estimateId: number,
    includeRelated: boolean = false): void {
    const url = `/api/project/estimate/${estimateId}/${includeRelated}/download/excel`;
    const headers = this.requestService.buildHttpHeaders();

    this.httpClient.get(url, {
      headers: headers,
      observe: 'response',
      responseType: 'blob'
    })
      .toPromise()
      .then(resp => {
        this.docService.saveFile(resp);
      }
      );
  }

  public exportEstimates(request: PaginatedData<EstimatesOverviewV2Response>, columns: TableColumn[]): Observable<HttpResponse<Blob>> {
    const url = '/api/project/estimate/paginated/export';
    const headers = this.requestService.buildHttpHeaders();

    // Copy the request so we don't mutate the original
    const data = request.toObject();
    data.page.number = 0; // Prevents issue where incorrect import items when on page 2.
    data.page.size = data.page.totalElements;

    data.columnValues = columns.map((col) => {
      // Special FE fields that needs to be mapped backend
      if (col.field === 'requestedBy') {
        return { field: 'requestedByCompany', readableField: col.header } as PaginatedColumnValueIndex;
      }

      return { field: col.field, readableField: col.header } as PaginatedColumnValueIndex;
    });

    // Cleanup because of FE_specific columns?
    data.columnValues = data.columnValues.filter((col) => {
      return JSON.stringify(col) !== '{}';
    });

    return this.httpClient
      .post(url, data, { headers: headers, observe: 'response', responseType: 'blob' })
      .pipe(
        // Update the given pagination object with values from the response.
        tap((resp) => {
          this.docService.saveFile(resp);
        })
      );
  }
}
