import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { PrintPreviewService } from './print-preview.service';

@Injectable()
export class PrintPreviewGuard  {

  constructor(private router: Router,
              private printService: PrintPreviewService) {
  }

  public canActivate(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.printService.printingEmitter.emit(true);
      resolve(true);
    });
  }

  public canDeactivate(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.printService.printingEmitter.emit(false);
      resolve(true);
    });
  }
}
