import { Message } from './dto/messaging/message';
import * as _ from 'underscore';
import { isNullOrUndefined } from './utils/is-null-or-undefined';

export default class MessageUtil {
  public static filterMessagesByAll(messages: Message[], searchQuery: string): Message[] {
    return _.filter(messages, (message) => {
      const rgx = new RegExp(searchQuery, 'ig');

      return this.findByAuthor(rgx, message) ||
        this.findBySubject(rgx, message) ||
        this.findByTag(rgx, message) ||
        this.findByMessageBody(rgx, message) ||
        this.findByRecipients(rgx, message);
    });
  }

  public static showFileAttachments(message: Message): boolean {
    if (!isNullOrUndefined(message.childMessages) && message.childMessages.length > 0) {
      // Component always uses latest message.
      if (!isNullOrUndefined(message.childMessages[0].fileAttachments) &&
        message.childMessages[0].fileAttachments.length > 0) {
        return true;
      }
    } else {
      if (!isNullOrUndefined(message.fileAttachments) && message.fileAttachments.length > 0) {
        return true;
      }
    }

    return false;
  }

  public static filterMessagesByAuthor(messages: Message[], searchQuery: string): Message[] {
    return _.filter(messages, (message) => {
      const rgx = new RegExp(searchQuery, 'ig');
      return this.findByAuthor(rgx, message);
    });
  }

  public static filterMessagesBySubject(messages: Message[], searchQuery: string): Message[] {
    return _.filter(messages, (message) => {
      const rgx = new RegExp(searchQuery, 'ig');
      return this.findBySubject(rgx, message);
    });
  }

  public static filterMessagesByCountry(messages: Message[], countryId: number ): Message[] {
    const result = _.filter(messages, (message) => {

      if (isNullOrUndefined(countryId) && !message.relatedCountries) {
        return true;
      } else if (message.relatedCountries && message.relatedCountries.find((country) => country.id === countryId)) {
        return true;
      }

      return false;
    });

    return result;
  }

  private static findByAuthor(rgx: RegExp, message: Message): boolean {
    return rgx.test(message.creator.firstName + ' ' + message.creator.lastName);
  }

  private static findByRecipients(rgx: RegExp, message: Message): boolean {
    return (message.messageRecipients &&
      message.messageRecipients
        .findIndex(recipient => rgx.test(recipient.user.firstName + ' ' + recipient.user.lastName)) >= 0);
  }

  private static findBySubject(rgx: RegExp, message: Message): boolean {
    return rgx.test(message.messageSubject);
  }

  private static findByMessageBody(rgx: RegExp, message: Message): boolean {
    const childMessages = message.childMessages || [];
    return rgx.test(message.messageBody) || (childMessages.findIndex(child => rgx.test(child.messageBody)) >= 0);
  }

  private static findByTag(rgx: RegExp, message: Message): boolean {
    return rgx.test(message.scope + ' ' + message.scopeName);
  }
}
