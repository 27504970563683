import { Injectable } from '@angular/core';
import { ConfigService } from '../../config.service';
import { ConfigDataStoreService } from './config-data-store.service';
import { UserService } from '../../../shared/services/user.service';
import { SystemConfigDTO } from '../../../shared/dto/system-config/system-config-dto';
import { DefaultProductivity } from '../../../shared/dto/system-config/default-productivity';
import { SystemConfigResponseDTO } from '../../../shared/dto/system-config/system-config-response-dto';
import { DefaultProductivityLanguagePair } from '../../../shared/dto/system-config/default-productivity-language-pair';

@Injectable()
export class DefaultProductivityDataStoreService extends ConfigDataStoreService<DefaultProductivity> {

  private defaultProdLanguagePairType = 'DEFAULT_PRODUCTIVITY_LANGUAGE_PAIR';

  constructor(configService: ConfigService, userService: UserService) {
    super(configService, userService, 'DEFAULT_PRODUCTIVITY');
  }

  public create(data: DefaultProductivity): Promise<SystemConfigResponseDTO> {
    const configObject: SystemConfigDTO = { userId: this.user.id, type: this.type, defaultProductivityDTO: data };
    const response = this.configService.addSystemConfig(configObject);
    response.then(res => {
      this.dataSetPromise.then(dataSet => {
        dataSet.push(res.configValueDTO);
        this.dataSet.next(dataSet);
        this.dataSetPromise = Promise.resolve(dataSet);
      });
    });
    return response;
  }

  public update(data: DefaultProductivity): Promise<SystemConfigResponseDTO> {
    const configObject: SystemConfigDTO = { userId: this.user.id, type: this.type, defaultProductivityDTO: data };
    const response = this.configService.updateSystemConfig(configObject);
    response.then(res => {
      this.dataSetPromise.then(dataSet => {
        const index = dataSet.findIndex(dataPoint => dataPoint.id === res.configValueDTO.id);
        dataSet[index] = res.configValueDTO;
        this.dataSet.next(dataSet);
        this.dataSetPromise = Promise.resolve(dataSet);
      });
    });

    return response;
  }

  public enable(data: DefaultProductivity): Promise<SystemConfigResponseDTO> {
    return this.configService.enableSystemConfig({ userId: this.user.id, type: this.type, defaultProductivityDTO: data });
  }

  public disable(data: DefaultProductivity): Promise<SystemConfigResponseDTO> {
    return this.configService.disableSystemConfig({
      userId: this.user.id,
      type: this.type,
      defaultProductivityDTO: data
    });
  }

  public find(id: number): Promise<DefaultProductivity> {
    return Promise.resolve(this.dataSetPromise.then(ds => {
      return ds.find(d => d.id === id);
    }));
  }

  public createLanguagePair(data: DefaultProductivityLanguagePair): Promise<SystemConfigResponseDTO> {
    const configObject: SystemConfigDTO = {
      userId: this.user.id,
      type: this.defaultProdLanguagePairType,
      defaultProductivityLanguagePairDTO: data
    };
    const response = this.configService.addSystemConfig(configObject);
    response.then(res => {
      this.dataSetPromise.then(dataSet => {
        const index = dataSet.findIndex(dataPoint => dataPoint.id === res.configValueDTO.id);
        dataSet[index] = res.configValueDTO;
        this.dataSet.next(dataSet);
        this.dataSetPromise = Promise.resolve(dataSet);
      });
    });
    return response;
  }

  public updateLanguagePair(data: DefaultProductivityLanguagePair): Promise<SystemConfigResponseDTO> {
    const configObject: SystemConfigDTO = {
      userId: this.user.id,
      type: this.defaultProdLanguagePairType,
      defaultProductivityLanguagePairDTO: data
    };
    const response = this.configService.updateSystemConfig(configObject);
    response.then(res => {
      this.dataSetPromise.then(dataSet => {
        const index = dataSet.findIndex(dataPoint => dataPoint.id === res.configValueDTO.id);
        dataSet[index] = res.configValueDTO;
        this.dataSet.next(dataSet);
        this.dataSetPromise = Promise.resolve(dataSet);
      });
    });

    return response;
  }

  public deleteLanguagePair(data: DefaultProductivityLanguagePair): Promise<SystemConfigResponseDTO> {
    const response = this.configService.disableSystemConfig({
      userId: this.user.id,
      type: this.defaultProdLanguagePairType,
      defaultProductivityLanguagePairDTO: data
    });

    response.then(res => {
      this.dataSetPromise.then(dataSet => {
        const index = dataSet.findIndex(dataPoint => dataPoint.id === res.configValueDTO.id);
        dataSet[index] = res.configValueDTO;
        this.dataSet.next(dataSet);
        this.dataSetPromise = Promise.resolve(dataSet);
      });
    });

    return response;
  }
}
