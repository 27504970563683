import { Pipe, PipeTransform } from '@angular/core';
import { generateFullname } from '../generate-fullname';

@Pipe({
  name: 'generateFullname'
})
export class GenerateFullnamePipe implements PipeTransform {

  public transform(value: any, args?: any): any {
    return generateFullname(value);
  }

}
