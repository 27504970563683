import { Injectable } from '@angular/core';
import { ForecastDocumentDetails } from '../../create-multiple-forecast/forecast-document-details';

@Injectable({ providedIn: 'root' })
export class CreatePortfolioForecastService {

  public patents: ForecastDocumentDetails[] = [];

  public title: string;

  public team: number;

  public formTouched: boolean;

  constructor() {}

  public remove(selectedPatent: ForecastDocumentDetails): void {
    this.patents = this.patents.filter(patent => patent !== selectedPatent);
  }

  public clear(): void {
    this.patents = [];
    this.title = undefined;
    this.team = undefined;
    this.formTouched = undefined;
  }
}
