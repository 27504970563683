import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconSvgsComponent } from './icon-svgs.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [IconSvgsComponent],
  exports: [IconSvgsComponent]
})
export class IconSvgsModule { }
