import { Injectable } from '@angular/core';
import { VendorUser } from '../../../../shared/dto/vendor-management/vendor-user';
import { Vendor } from '../../../../shared/dto/vendor-management/vendor';
import { Address } from '../../../../shared/dto/address';
import { LoggingService } from '../../../../shared/logging.service';

@Injectable()
export class VendorOnboardingService {
  private vendorUser: VendorUser;
  private vendor: Vendor;
  private vendorAddress: Address;

  constructor(private loggingService: LoggingService) {
  }

  public clear(): void {
    this.vendorUser = null;
    this.vendor = null;
  }

  public setVendor(vendor: Vendor): void {
    this.vendor = vendor;
  }

  public getVendor(): Vendor {
    return this.vendor;
  }

  public setAddress(address: Address): void {
    this.vendorAddress = address;
  }

  public getAddress(): Address {
    return this.vendorAddress;
  }

  public setVendorUser(vendorUser: VendorUser): void {
    this.vendorUser = vendorUser;
  }

  public getVendorUser(): VendorUser {
    return this.vendorUser;
  }
}
