import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckboxModule } from 'primeng/checkbox';
import { FilterButtonDirective } from './filter-button.directive';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { FilterSelectionComponent } from './filter-selection/filter-selection.component';
import { FilterFormModule } from '../components/filter-form/filter-form.module';
import { HeaderFiltersModule } from './header-filters/header-filters.module';
import { FileFilterSelectionComponent } from './file-filter-selection/file-filter-selection.component';
import { CheckListModule } from './check-list/check-list.module';
import { FormsModule } from '@angular/forms';
import { KeepMenuOpenOnItemClickDirective } from '../directives/keep-menu-item-open.directive';
import { HeaderFiltersV2Module } from './header-filters-v2/header-filters-v2.module';
import { FilterSelectionV2Module } from './filter-selection-v2/filter-selection-v2.module';
import { DataFilterPipesModule } from './data-filter-pipes/data-filter-pipes.module';

@NgModule({
  imports: [
    CommonModule,
    BsDropdownModule,
    TooltipModule,
    FilterFormModule,
    HeaderFiltersModule,
    CheckboxModule,
    AccordionModule,
    CheckListModule,
    FormsModule,
    HeaderFiltersV2Module,
    FilterSelectionV2Module,
    DataFilterPipesModule,
  ],
  declarations: [
    FilterButtonDirective,
    FilterSelectionComponent,
    FileFilterSelectionComponent,
    KeepMenuOpenOnItemClickDirective
  ],
  exports: [
    FilterButtonDirective,
    FilterSelectionComponent,
    HeaderFiltersModule,
    FileFilterSelectionComponent,
    HeaderFiltersV2Module,
    FilterSelectionComponent,
    DataFilterPipesModule,
    FilterSelectionV2Module,
  ]
})
export class DataFilterModule { }
