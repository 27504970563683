import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RequestService } from '../shared/services/request-service';

@Injectable({
  providedIn: 'root'
})
export class ApplicantService {

  constructor(
    private httpClient: HttpClient,
    private requestService: RequestService,
  ) {
    // TODO: Move applicant endpoints to this service
  }
}
