<div class="layout-sidebar" [ngClass]="{'expanded-nav' : isExpanded}">
  <div id="#townip-side-menu" class="side-menu sidebar-inverse">
    <nav class="navbar" role="navigation">
      <div class="side-menu-container">
        <div class="navbar-header">
          <a class="navbar-brand" href="#" (click)="$event.preventDefault()"
              [routerLink]="['/dashboard']" routerLinkActive="active">
            <div class="icon">
              <!--<img class="brand-img" src="../assets/images/logos/full-logo-white.svg">-->
              <img class="brand-logo sunip" [attr.src]="appContext.brandLogo">
            </div>
          </a>
        </div>
        <p-scrollPanel styleClass="clear-scrollpanel" [style]="{ width: '100%', height: '100%' }">
          <ng-container *ngIf="user">
            <ul class="nav navbar-nav" id="main-nav">
              <!-- Translation & Filing Category -->
              <ng-container *townipIfUserHasTransFilingAccess>
                <li class="nav-item collapse-all menu-category">
                  <a class="nav-link side-nav-link" href="#" (click)="$event.preventDefault()">
                    <townip-icon-svgs [iconType]="'FILING_TRANSLATION_CATEGORY'"></townip-icon-svgs>
                  </a>
                </li>
                <li *townipIfUserHasTransFilingAccess class="nav-item collapse-all">
                  <a class="nav-link side-nav-link" href="#" (click)="$event.preventDefault();"
                     [routerLink]="['/dashboard']" routerLinkActive="active">
                    <townip-icon-svgs [iconType]="'DASHBOARD'"></townip-icon-svgs>
                  </a>
                </li>
                <li *townipIfUserHasTransFilingAccess class="nav-item collapse-all">
                  <a class="nav-link side-nav-link"
                     [routerLink]="['/redirect']"
                     [queryParams]="{route: 'projects'}"
                     [skipLocationChange]="true"
                     [ngClass]="{'active': currentModule === 'projects'}">
                    <townip-icon-svgs [iconType]="'PROJECTS'"></townip-icon-svgs>
                  </a>
                </li>
                <li *ngIf="(userType === 'CUSTOMER' && !userPermissions.RESTRICTED_COMPANY_USER) || userType === 'PM' || userType === 'SALES_MANAGER' || userType === 'ACCOUNT_MANAGER'" class="nav-item collapse-all">
                  <a class="nav-link side-nav-link"
                     [routerLink]="['/redirect']"
                     [queryParams]="{route: 'estimate/estimates'}"
                     [skipLocationChange]="true"
                     [ngClass]="{'active': currentModule === 'estimates'}">
                    <townip-icon-svgs [iconType]="'ESTIMATES'"></townip-icon-svgs>
                  </a>
                </li>
                <li *ngIf="!(userType === 'SALES_MANAGER' || userType === 'ACCOUNT_MANAGER')" class="nav-item collapse-all">
                  <a class="nav-link side-nav-link"
                     [routerLink]="['/redirect']"
                     [queryParams]="{route: 'tasks'}"
                     [skipLocationChange]="true"
                     [ngClass]="{'active': currentModule === 'tasks'}">
                    <townip-icon-svgs [iconType]="'TASKS'"></townip-icon-svgs>
                  </a>
                </li>
              </ng-container>

              <ng-container *townipIfUserHasTransFilingAccess>
                <li class="division" *townipIfUserHasAnnuitiesAccess></li>
              </ng-container>

              <!-- Annuities Category -->
              <ng-container *townipIfUserHasAnnuitiesAccess>
                <li class="nav-item collapse-all menu-category">
                  <a class="nav-link side-nav-link" href="#" (click)="$event.preventDefault()">
                    <townip-icon-svgs [iconType]="'ANNUITIES_CATEGORY'"></townip-icon-svgs>
                  </a>
                </li>
                <li class="nav-item collapse-all" *ngIf="userType === 'CUSTOMER' && !userPermissions.RESTRICTED_COMPANY_USER">
                  <a
                    class="nav-link side-nav-link"
                    [routerLink]="['/annuities']"
                    [skipLocationChange]="true"
                    [ngClass]="{'active': currentModule === 'annuities-overview'}">
                    <townip-icon-svgs [iconType]="'CLIENT ANNUITIES'"></townip-icon-svgs>
                  </a>
                </li>
                <li class="nav-item collapse-all" *ngIf="userType === userTypes.SALES_MANAGER  || userType === userTypes.ACCOUNT_MANAGER">
                  <a
                    class="nav-link side-nav-link"
                    [routerLink]="['/annuities']"
                    [skipLocationChange]="true"
                    [ngClass]="{'active': currentModule === 'annuities-overview'}">
                    <townip-icon-svgs [iconType]="'CLIENT ANNUITIES'"></townip-icon-svgs>
                  </a>
                </li>
                <li class="nav-item collapse-all" *ngIf="userType === userTypes.VENDOR_MANAGER || userType === userTypes.VENDOR">
                  <a
                    class="nav-link side-nav-link"
                    [routerLink]="['/annuities']"
                    [skipLocationChange]="true"
                    [ngClass]="{'active': currentModule === 'annuities-overview'}">
                    <townip-icon-svgs [iconType]="'VENDOR ANNUITIES'"></townip-icon-svgs>
                  </a>
                </li>
                <li class="nav-item collapse-all" *ngIf="userType === 'PM'">
                  <a
                    class="nav-link side-nav-link"
                    [routerLink]="['/annuities']"
                    [skipLocationChange]="true"
                    [ngClass]="{'active': currentModule === 'annuities-overview'}">
                    <townip-icon-svgs [iconType]="'INTERNAL ANNUITIES'"></townip-icon-svgs>
                  </a>
                </li>
                <li class="nav-item collapse-all" *ngIf="userType === 'CUSTOMER'">
                  <a
                    class="nav-link side-nav-link"
                    [routerLink]="['/annuities/company/all']"
                    [skipLocationChange]="true"
                    [ngClass]="{'active': currentModule === 'annuities-table'}">
                    <townip-icon-svgs [iconType]="'ANNUITIES_TABLE'"></townip-icon-svgs>
                  </a>
                </li>
                <li class="nav-item collapse-all" *ngIf="userType === 'PM'">
                  <a
                    class="nav-link side-nav-link"
                    [routerLink]="['/annuities/internal/project-manager/all']"
                    [skipLocationChange]="true"
                    [ngClass]="{'active': currentModule === 'annuities-table'}">
                    <townip-icon-svgs [iconType]="'ANNUITIES_TABLE'"></townip-icon-svgs>
                  </a>
                </li>
                <li class="nav-item collapse-all" *ngIf="(userType === userTypes.VENDOR_MANAGER || userType === userTypes.VENDOR)">
                  <a
                    class="nav-link side-nav-link"
                    [routerLink]="['/annuities/vendor/annuities-table']"
                    [skipLocationChange]="true"
                    [ngClass]="{'active': currentModule === 'annuities-table'}">
                    <townip-icon-svgs [iconType]="'ANNUITIES_TABLE'"></townip-icon-svgs>
                  </a>
                </li>
                <li
                  class="nav-item collapse-all"
                  *ngIf="userType === 'SALES_MANAGER' || userType === 'ACCOUNT_MANAGER'">
                  <a
                    class="nav-link side-nav-link"
                    [routerLink]="['/annuities/sm-am/all']"
                    [skipLocationChange]="true"
                    [ngClass]="{'active': currentModule === 'annuities-table'}">
                    <townip-icon-svgs [iconType]="'ANNUITIES_TABLE'"></townip-icon-svgs>
                  </a>
                </li>
                <li class="nav-item collapse-all" *ngIf="userType === 'CUSTOMER' && !userPermissions.RESTRICTED_COMPANY_USER">
                  <a class="nav-link side-nav-link" [routerLink]="['/patent-portfolio']" routerLinkActive="active">
                    <townip-icon-svgs [iconType]="'IP PORTFOLIO'"></townip-icon-svgs>
                  </a>
                </li>
                <li class="nav-item collapse-all" *ngIf="userAuthorities?.ROLE_INTERNAL_USER">
                  <a class="nav-link side-nav-link" *ngIf="isSalesOrAccountMgr" [routerLink]="['/patent-portfolio']" routerLinkActive="active">
                    <townip-icon-svgs [iconType]="'IP PORTFOLIO'"></townip-icon-svgs>
                  </a>
                  <a class="nav-link side-nav-link" *ngIf="!isSalesOrAccountMgr" [routerLink]="['/patent-portfolio/landing']" routerLinkActive="active">
                    <townip-icon-svgs [iconType]="'IP PORTFOLIO'"></townip-icon-svgs>
                  </a>
                </li>
              </ng-container>
            </ul>

            <!-- Others Category -->
            <ul  class="nav navbar-nav uncategorized">
              <li *ngIf="hasAnnuitiesAccess || hasTranslationFilingAccess" class="division"></li>

              <ng-container *ngIf="isForecaster && !userPermissions.RESTRICTED_COMPANY_USER">
                <li class="nav-item collapse-all">
                  <a class="nav-link side-nav-link" [routerLink]="['/forecast/dashboard']" routerLinkActive="active">
                    <townip-icon-svgs [iconType]="'FORECAST'"></townip-icon-svgs>
                  </a>
                </li>
              </ng-container>

              <li class="nav-item collapse-all" *ngIf="hasViewReportsPermission">
                <a class="nav-link side-nav-link" [routerLink]="['/reporting']" routerLinkActive="active">
                  <townip-icon-svgs [iconType]="'REPORTS'"></townip-icon-svgs>
                </a>
              </li>
              <li class="nav-item collapse-all" *ngIf="userType === 'VENDOR_MANAGER'">
                <a
                  class="nav-link side-nav-link"
                  [routerLink]="['/redirect']"
                  [queryParams]="{route: 'billing'}"
                  [skipLocationChange]="true"
                  [ngClass]="{'active': currentModule === 'billing'}">
                  <townip-icon-svgs [iconType]="'BILLING'"></townip-icon-svgs>
                </a>
              </li>
              <ng-container *ngIf="userType === 'CUSTOMER' && !userPermissions.RESTRICTED_COMPANY_USER">
                <li *ngIf="hasTranslationFilingAccess || hasAnnuitiesAccess" class="nav-item collapse-all">
                  <a class="nav-link side-nav-link"
                     [routerLink]="['/redirect']"
                     [queryParams]="{route: 'invoices'}"
                     [skipLocationChange]="true"
                     [ngClass]="{'active': currentModule === 'invoices'}">
                    <townip-icon-svgs [iconType]="'INVOICES'"></townip-icon-svgs>
                  </a>
                </li>
              </ng-container>
              <li class="nav-item collapse-all" *ngIf="!!user && userType === 'PM'; else nonPmSettings">
                <a class="nav-link side-nav-link" [routerLink]="['/settings-v2/internal']" routerLinkActive="active">
                  <townip-icon-svgs [iconType]="'SETTINGS'"></townip-icon-svgs>
                </a>
              </li>
              <ng-template #nonPmSettings>
                <li
                  class="nav-item collapse-all"
                  *ngIf="user && (userType === userTypes.VENDOR_MANAGER || userType === userTypes.VENDOR); else genericSettings">
                  <a class="nav-link side-nav-link" [routerLink]="['/settings-v2/vendor/user-profile']" routerLinkActive="active">
                    <townip-icon-svgs [iconType]="'SETTINGS'"></townip-icon-svgs>
                  </a>
                </li>
                <ng-template #genericSettings>
                  <li class="nav-item collapse-all" *ngIf="!!user">
                    <a class="nav-link side-nav-link" [routerLink]="['/settings-v2/user-profile']" routerLinkActive="active">
                      <townip-icon-svgs [iconType]="'SETTINGS'"></townip-icon-svgs>
                    </a>
                  </li>
                </ng-template>
              </ng-template>
              <li class="nav-item" *ngIf="!user">
                <a class="nav-link side-nav-link" [routerLink]="['/auth/login']" routerLinkActive="active">
                  <townip-icon-svgs [iconType]="'LOGIN'"></townip-icon-svgs>
                </a>
              </li>

              <ng-container *townipIfInternalUser>
                <li *ngIf="hasAnnuitiesAccess || hasTranslationFilingAccess" class="division"></li>

                <li class="nav-item collapse-all" *ngIf="userType === 'PM'">
                  <a class="nav-link side-nav-link" [routerLink]="['/system-configuration']" routerLinkActive="active">
                    <townip-icon-svgs [iconType]="'CONFIGURATION'"></townip-icon-svgs>
                  </a>
                </li>
                <li class="nav-item collapse-all" *ngIf="userType === 'PM' || userType === 'ACCOUNT_MANAGER' || userType === 'SALES_MANAGER'">
                  <a class="nav-link side-nav-link"
                     [routerLink]="['/settings-v2/internal-management/companies']" routerLinkActive="active">
                    <townip-icon-svgs [iconType]="'MANAGE_USERS'"></townip-icon-svgs>
                  </a>
                </li>
                <li class="nav-item collapse-all" *ngIf="userType === 'PM'">
                  <a class="nav-link side-nav-link"
                     [routerLink]="['/settings-v2/internal-management/vendors']" routerLinkActive="active">
                    <townip-icon-svgs [iconType]="'MANAGE_VENDORS'"></townip-icon-svgs>
                  </a>
                </li>
              </ng-container>

              <ng-container *ngIf="accountManager">
                <!-- Contact Account Manager -->
                <li class="division"></li>
                <li class="nav-item collapse-all">
                  <div class="d-flex contact-manager mt-2" (click)="showMessageModal()">
                    <div class="d-flex flex-column" id="managerColumn">
                      <span>Contact Account Manager</span>
                      <div class="manager-name">
                        {{ accountManager?.firstName }} {{ accountManager?.lastName }}
                      </div>
                    </div>
                    <div class="message-icon">
                      <i class="fa fa-envelope-o" aria-hidden="true" style="font-size: 1.8rem"></i>
                    </div>
                  </div>
                </li>
              </ng-container>
            </ul>
          </ng-container>
        </p-scrollPanel>
      </div>
      <!-- /.navbar-collapse -->
    </nav>
  </div>
</div>

<!-- Modal for Messaging Account Manager -->
<townip-modal size="lg" heading="Message" (onHide)="messageModalVisible = false">
  <townip-modal-body>
    <div>
      <townip-messages
        *ngIf="accountManager && messageModalVisible"
        [selectedUserId]="accountManager?.id"
        [disableContacts]="true"
        [hideMessageList]="true"
        [newMessage]="true"
        (messageSent)="messageSent()"
        (messageCancelled)="hideMessageModal()">
      </townip-messages>
    </div>
  </townip-modal-body>
</townip-modal>
