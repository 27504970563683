import { Pipe, PipeTransform } from '@angular/core';
import { FieldType } from '../../../data-filter/field-type';
import { FilterOperator, OPERATOR_COLLECTION } from '../../../data-filter/filter-operators';

@Pipe({
  name: 'filterOperator'
})
export class FilterOperatorPipe implements PipeTransform {

  public transform(value: FilterOperator[], args?: FieldType): FilterOperator[] {

    const operators = OPERATOR_COLLECTION.filter((op) => {
      if (op.type === args) {
        return op;
      }
    });

    return operators;
  }

}
