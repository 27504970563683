import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StatusBadgeModule } from './status-badge/status-badge.module';
import { GenerateFullnamePipe } from './pipes/generate-fullname.pipe';
import { OverviewTabsComponent } from './overview-tabs/overview-tabs.component';
import { OverviewDateDisplayModule } from './overview-date-display/overview-date-display.module';
import { OverviewAssigneeDisplayModule } from './overview-assignee-display/overview-assignee-display.module';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TooltipModule } from 'primeng/tooltip';

@NgModule({
  imports: [
    CommonModule,
    StatusBadgeModule,
    OverviewDateDisplayModule,
    OverviewAssigneeDisplayModule,
    BsDropdownModule,
    TooltipModule
  ],
  declarations: [
    GenerateFullnamePipe,
    OverviewTabsComponent
  ],
  exports: [
    GenerateFullnamePipe,
    StatusBadgeModule,
    OverviewTabsComponent,
    OverviewDateDisplayModule,
    OverviewAssigneeDisplayModule,
  ],
})
export class OverviewUtilitiesModule { }
