import * as _ from 'underscore';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, } from '@angular/core';
import { Router } from '@angular/router';
import { Message } from '../../../dto/messaging/message';
import { MessagingService } from '../../../services/messaging.service';
import { PortalUser } from '../../../domain/user';
import { Subscription } from 'rxjs';
import { WebsocketService } from '../../../services/websocket.service';
import MessageUtil from '../../../message-util';
import { ScopeNavigation } from '../utils/scope-navigation';
import { TaskNavigationService } from '../../../../tasks/services/task-navigation.service';
import { NavService } from '../../../../main/navigation/nav.service';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'townip-message-quickview',
  templateUrl: './message-quickview.component.html',
  styleUrls: ['./message-quickview.component.scss'],
})
export class MessageQuickviewComponent implements OnInit, OnDestroy {

  @Input()
  public user: PortalUser;

  @Output()
  public unreadMessageCountChange: EventEmitter<number>;

  public unreadMessageCount = 0;

  public messages: Message[]; // Contains only the filtered 'unread' messages

  public renderMenu: boolean;

  private messageSubscription: Subscription;

  constructor(private messageService: MessagingService,
              private websocketService: WebsocketService,
              private taskNavService: TaskNavigationService,
              private navService: NavService,
              private userService: UserService,
              private router: Router) {
    this.renderMenu = false;
    this.messages = [];
    this.unreadMessageCountChange = new EventEmitter();
  }

  public ngOnInit(): void {
    this.messageSubscription = this.messageService.messages.subscribe(userMessages => {
      this.getMessages(userMessages);
    });
  }

  private getMessages(userMessages: Message[]): void {
    this.messages = this.getUnreadOnly(userMessages);
    this.unreadMessageCount = 0;
    this.unreadMessageCount = this.messages.length;
    this.unreadMessageCountChange.emit(this.messages.length);
  }

  /**
   * Filters the messages to contain only unread message that belongs
   * to the currently logged in user.
   * @param {Message[]} messages
   * @returns {Message[]}
   */
  private getUnreadOnly(messages: Message[]): Message[] {
    const userMessages: Message[] = []; // Contains unread messages that belong  to the current user
    for (const message of messages) {
      const recipient = message.messageRecipients.find(mr => mr.user.id === this.user.id);
      if (recipient && !recipient.read) {
        userMessages.push(message);
      }
      // Also sort the child messages by id descending ALWAYS
      message.childMessages = _.sortBy(message.childMessages, 'id')
        .reverse();
    }

    return userMessages;
  }

  public ngOnDestroy(): void {
    if (this.messageSubscription) {
      this.messageSubscription.unsubscribe();
    }
  }

  public hideMenu(): void {
    if (!this.renderMenu) {
      return;
    }
    // Set a very minimal delay so that angular has time to process
    // the click event before hiding the rendered menu
    setTimeout(() => {
      this.renderMenu = false;
    }, 200);
  }

  public showMenu(): void {
    this.renderMenu = true;
  }

  public toggleMenu(): void {
    if (this.renderMenu) {
      this.hideMenu();
    }

    this.renderMenu = true;
  }

  public navigate(message?: Message): void {
    let  url = `${location.origin}/messages`;

    // Navigate to messages and pre-select one.
    if (message) {
      url = `${location.origin}/messages;mid=${message.id}`;
    }

    // Navigate to all message
    window.open(url);
  }

  public navigateScope(message: Message): void {
    if (!ScopeNavigation.canNavigateToScope(message)) {
      return;
    }

    const scopeNavigation = new ScopeNavigation(this.taskNavService, this.userService, this.navService, this.router);
    scopeNavigation.navigateToScope(message);
  }

  public showFileAttachments(message: Message): boolean {
    return MessageUtil.showFileAttachments(message);
  }
}
