<div class="search-bar d-flex overflow-hidden" #searchBar>
  <input class="search-input flex-grow-1 px-2" [pKeyFilter]="keyFilterRegEx" (keyup.enter)="searchSystem($event)" [(ngModel)]="searchValue"/>
  <button class="btn-search" (click)="searchSystem($event)">
    <i class="fa fa-search" aria-hidden="true"></i></button>
</div>

<p-overlayPanel #searchOverlay *ngIf="searchValue?.length > 0" [styleClass]="'navbar-search-overlay'" [appendTo]="searchBar">
  <section *ngIf="loaded; else loading" class="overlay-display">
    <ng-container>
      <div class="row header">
        <div class="col-9 search-value">
          <span *ngIf="scopeTypeDisplays?.length > 0">Search results for '</span>
          <span *ngIf="!scopeTypeDisplays || scopeTypeDisplays?.length === 0">
            Could not find results for '</span>{{displaySearchValue}}'
        </div>
        <div class="col-3 text-right">
          <span class="clear-results" (click)="clearInput()">
          Clear Results</span>
        </div>
      </div>
    </ng-container>
    <ng-container *ngFor="let scopeTypeDisplay of scopeTypeDisplays">
      <hr/>
      <div class="result-scope-display">
        {{scopeTypeDisplay.label}}
      </div>
      <div *ngFor="let result of searchResults | scopeFilter : {scopeFilter: scopeTypeDisplay.scopeType}; let i = index; let last = last">
        <div *ngIf="i < 3 || scopeTypeDisplay?.showMore" class="results-display"
             (click)="routeToResult(result); searchOverlay.hide(); clearInput()">
          <townip-text-highlighter [title]="result.name" class="text-truncate w-75" [word]="searchValue">{{result.name}}</townip-text-highlighter>
          <ng-template *ngTemplateOutlet="parentScopeTypeBadge; context: {$implicit: result}"></ng-template>
        </div>
        <div class="show-more">
          <div
            *ngIf="!scopeTypeDisplay?.showMore && i=== 3"
            (click)="$event.stopPropagation(); scopeTypeDisplay.showMore = true">
            more...
          </div>
          <div
            *ngIf="scopeTypeDisplay?.showMore && last"
            (click)="$event.stopPropagation(); scopeTypeDisplay.showMore = false">
            show less...
          </div>
        </div>
      </div>
    </ng-container>
  </section>
</p-overlayPanel>

<ng-template #loading>
  <div class="text-center">
    <i class="fa fa-refresh fa-spin fa-fw"></i>
  </div>
</ng-template>

<ng-template let-result #parentScopeTypeBadge>
  <span *ngIf="result.parentScopeType === 'PROJECT'" class="btn btn-reply-scope {{result.parentScopeType}} text-truncate pull-right">
    Project {{result.secondaryDisplayName}}
  </span>
  <span *ngIf="result.parentScopeType === 'ESTIMATE'" class="btn btn-reply-scope {{result.parentScopeType}} text-truncate pull-right">
    Estimate {{result.secondaryDisplayName}}
  </span>
  <span *ngIf="result.parentScopeType === 'ANNUITY'" class="btn btn-reply-scope {{result.parentScopeType}} text-truncate pull-right">
    Renewal Service {{result.secondaryDisplayName}}
  </span>
  <span *ngIf="result.parentScopeType === 'ANNUITY_PATENT'" class="btn btn-reply-scope {{result.parentScopeType}} text-truncate pull-right">
    Portfolio {{result.secondaryDisplayName}}
  </span>
  <span *ngIf="result.scopeType === 'COMPANY' && result.secondaryDisplayName" class="btn btn-reply-scope {{result.scopeType}} text-truncate pull-right">
    {{result.secondaryDisplayName}}
  </span>
</ng-template>
