import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FillHeightDirective } from './fill-height.directive';
import { ResponsiveContainerDirective } from './responsive-container.directive';
import { ElementIsEllipsisDirective } from './element-is-ellipsis.directive';
import { VariableViewDirective } from './variable-view.directive';
import { AnchorOffsetDirective } from './anchor-offset.directive';
import { TrimInputDirective } from './trim-input.directive';
import { DragDropListenerDirective } from './drag-drop-listener.directive';
import { IfInternalUserDirective } from './if-internal-user.directive';
import { IfUserHasAnnuitiesAccessDirective } from './if-user-has-annuities-access.directive';
import { IfCompanyUserDirective } from './if-company-user.directive';
import { IfPmUserDirective } from './if-pm-user.directive';
import { IfUserHasTransFilingAccessDirective } from './if-user-has-trans-filing-access.directive';
import { IfVendorUserDirective } from './if-vendor-user.directive';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    FillHeightDirective,
    ResponsiveContainerDirective,
    ElementIsEllipsisDirective,
    VariableViewDirective,
    AnchorOffsetDirective,
    TrimInputDirective,
    DragDropListenerDirective,
    IfInternalUserDirective,
    IfCompanyUserDirective,
    IfUserHasAnnuitiesAccessDirective,
    IfPmUserDirective,
    IfUserHasTransFilingAccessDirective,
    IfVendorUserDirective,
  ],
  exports: [
    FillHeightDirective,
    ResponsiveContainerDirective,
    ElementIsEllipsisDirective,
    VariableViewDirective,
    AnchorOffsetDirective,
    TrimInputDirective,
    DragDropListenerDirective,
    IfInternalUserDirective,
    IfCompanyUserDirective,
    IfUserHasAnnuitiesAccessDirective,
    IfPmUserDirective,
    IfUserHasTransFilingAccessDirective,
    IfVendorUserDirective,
  ],
})
export class DirectivesModule {
}
