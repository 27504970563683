import { EventEmitter, Injectable } from '@angular/core';
import * as clone from 'lodash/cloneDeep';
import { FilterSet } from '../filter-set';
import { RsqlEncoder } from '../rsql-encoder';
import { Subject } from 'rxjs';
import { isNullOrUndefined } from '../../utils/is-null-or-undefined';

export interface HeaderFiltersQueryOverrides {
  [key: string]: (filter: FilterSet) => string;
}

@Injectable()
export class HeaderFiltersService {

  public updates = new EventEmitter<FilterSet[]>();

  public valueUpdates = new EventEmitter<FilterSet[]>();

  /** Hack to reset the filter within the active header filter whenever the options are changed */
  public reapplyActiveFilterOverlayInput = new Subject<void>();

  /** List of query overrides that are invoked when generating RSQL queries */
  public filterOverrides: HeaderFiltersQueryOverrides = {};

  private filters: FilterSet[] = [];

  public static generateRSQL(filters: FilterSet[], filterOverrides: HeaderFiltersQueryOverrides = {}): string {
    const activeFilters = this.postFilter(filters);

    const rsqlEncoder = new RsqlEncoder();
    const queries: string[] = activeFilters.map((filter: FilterSet) => {
      if (filterOverrides[filter.field]) {
        return filterOverrides[filter.field](filter);
      }
      return rsqlEncoder.encode(filter);
    }, []);

    return RsqlEncoder.combine(queries);
  }

  /**
   * postFilter clears out all the filters sets that has null, undefined or blank values
   */
  public static postFilter(filters: FilterSet[]): FilterSet[] {
    return filters.filter((filter) => {
      return !(isNullOrUndefined(filter.value) || filter.value === '');
    });
  }

  constructor() { }

  public add(filterSet: FilterSet): void {
    const existingSet = this.filters.find((item) => item.field === filterSet.field);

    if (existingSet) {
      existingSet.value = filterSet.value;
      existingSet.operator = filterSet.operator;
    } else {
      this.filters.push(filterSet);
    }

    this.updates.emit(clone(HeaderFiltersService.postFilter(this.filters)));
  }

  public getFilters(): FilterSet[] {
    return clone(HeaderFiltersService.postFilter(this.filters));
  }

  public clearById(id: string): void {
    this.filters = this.filters.filter(filter => filter.id !== id);
  }

  public clearAll(): void {
    this.filters = [];
  }

  public clearFilters(): void {
    const filters = this.filters.map(filter => {
      filter.value = null;
      return filter;
    });
    this.setValues(filters);
  }

  public toRSQL(): string {
    return HeaderFiltersService.generateRSQL(this.filters, this.filterOverrides);
  }

  public setValues(filters: FilterSet[]): void {
    if (isNullOrUndefined(filters)) {
      return;
    }

    this.filters = clone(filters);

    // Do any post-value mutation here.
    this.valueUpdates.emit(clone(filters));
  }
}
