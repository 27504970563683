import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewClientEstimateComponent } from './new-client-estimate.component';
import { FormsModule } from '@angular/forms';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { DropdownModule } from 'primeng/dropdown';
import { ModalModule } from '../../../../controls/modal/modal.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    DropdownModule,
    ModalModule,
    AutoCompleteModule
  ],
  declarations: [NewClientEstimateComponent],
  exports: [NewClientEstimateComponent]
})
export class NewClientEstimateModule { }
