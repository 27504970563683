<div fillHeight class="d-flex align-items-center justify-content-center">
  <div class="error-container">
    <!-- Centering will break if we remove .error-code -->
    <span class="error-code">&nbsp;</span>
    <div class="error-content text-center">
      <div class="error-title">
        Screen size not supported
      </div>
      <div class="error-text">
        This system is optimized for use on a computer. Mobile viewing of this application is currently not supported.
      </div>
    </div>
  </div>
</div>
