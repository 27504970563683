import { Component, OnDestroy, OnInit } from '@angular/core';
import { ContainerMarginsService } from '../shared/services/container-margins.service';

@Component({
  selector: 'townip-messages-page',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss']
})
export class MessagesComponent implements OnInit, OnDestroy {

  constructor(private containerMargins: ContainerMarginsService) { }

  public ngOnInit(): void {
    this.containerMargins.disable();
  }

  public ngOnDestroy(): void {
    this.containerMargins.enable();
  }

}
