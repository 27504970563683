import { Action, AssignmentCategory, CategorizedWorkflowActions, WorkflowAction } from '../../shared/dto/annuities/annuity-workflow-action';

interface ActionDictionary {
  [key: string]: boolean;
}

/**
 * Helper for various annuity actions.
 * Possibly convert/implement to a structural directive
 *
 * Methods can be directly accessed in the template as they were optimized for performance
 */
export class AnnuityActionHelper {

  private _actions: CategorizedWorkflowActions;

  private _availableInternal: ActionDictionary = {};

  private _availableCompany: ActionDictionary = {};

  private _availableVendor: ActionDictionary = {};

  constructor(annuityActions: CategorizedWorkflowActions) {
    this._actions = annuityActions;
    this.parse();
  }

  public hasVendorAction(action: Action): boolean {
    return this._availableVendor[action];
  }

  public hasInternalAction(action: Action): boolean {
    return this._availableInternal[action];
  }

  public hasCompanyAction(action: Action): boolean {
    return this._availableCompany[action];
  }

  public getVendorAction(action: Action): WorkflowAction {
    return this._actions.vendor.find(existingAction => existingAction.action === action);
  }

  public getSourceAction(category: AssignmentCategory, action: Action): WorkflowAction {
    return null;
  }

  /**
   * Parses the actions for categorization
   * @param annuityActions
   */
  public parse(annuityActions?: CategorizedWorkflowActions): void {
    this._availableInternal = {};
    this._availableCompany = {};
    this._availableVendor = {};

    this._actions
      .company
      .forEach(action => {
        this._availableCompany[action.action] = true;
      });

    this._actions
      .internal
      .forEach(action => {
        this._availableInternal[action.action] = true;
      });

    this._actions
      .company
      .forEach(action => {
        this._availableCompany[action.action] = true;
      });
  }

}
