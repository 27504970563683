<!-- TODO: Temp Basic layout, will be replaced by an updated design from mike -->
<div class="offline-screen" *ngIf="currentStatus !== statusCodes.Online">
  <div class="d-flex align-items-center" style="height: 100%">
    <div class="m-auto offline-dialog d-flex flex-column">
      <!-- Dialog Body: No Internet -->
      <div class="p-5 mb-4 text-center" *ngIf="currentStatus === statusCodes.Offline">
        <i class="fa fa-warning dialog-icon text-warning mt-3"></i>
        <h3 class="mt-3">System currently offline.</h3>
        <p>
          <strong style="font-size: 1.1rem">
            You might be offline or the system is currently undergoing maintenance.
          </strong>
          <br />
          To preserve the integrity of your data, system access will be disabled.
        </p>
      </div>

      <!-- Dialog Body: Backend Offline -->
      <!--<div class="p-4 text-center mb-5" *ngIf="currentStatus === statusCodes.Backend">-->
        <!--<i class="fa fa-warning dialog-icon text-warning mt-3"></i>-->
        <!--<h3 class="mt-3 mb-3">System Offline for Maintenance</h3>-->
        <!--<p>Sorry, we are currently enhancing our system.<br />We should be up and running in just a few minutes.</p>-->
        <!--<p class="text-bold-500">To preserve the integrity of your data, system access will be disabled.</p>-->
      <!--</div>-->

      <!-- Action Footer -->
      <!--<div class="text-right mt-auto full-width">-->
        <!--<hr />-->
        <!--<div class="px-4 pb-3">-->
          <!--<button class="btn btn-primary"></button>-->
        <!--</div>-->
      <!--</div>-->
    </div>
  </div>
</div>
