import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CompanyUsersPipe } from './company-users.pipe';
import { FilterUserPipe } from './filter-user.pipe';
import { GenerateCompanyPipe } from './generate-company.pipe';
import { GroupByRecipientTypePipe } from './group-by-recipient-type.pipe';
import { MessageNamePipe, MessagesOnlyPipe, RepliesToPipe, UnreadMessageOnlyPipe } from './messaging.pipes';
import { MessageAttachmentCountPipe } from './message-attachment-count.pipe';

@NgModule({
  declarations: [
    CompanyUsersPipe,
    FilterUserPipe,
    GenerateCompanyPipe,
    GroupByRecipientTypePipe,
    RepliesToPipe,
    MessagesOnlyPipe,
    MessageNamePipe,
    UnreadMessageOnlyPipe,
    MessageAttachmentCountPipe
  ],
  exports: [
    CompanyUsersPipe,
    FilterUserPipe,
    GenerateCompanyPipe,
    GroupByRecipientTypePipe,
    RepliesToPipe,
    MessagesOnlyPipe,
    MessageNamePipe,
    UnreadMessageOnlyPipe,
    MessageAttachmentCountPipe
  ],
  imports: [
    CommonModule
  ]
})
export class MessagingPipeModule {
}
