import { Injectable } from '@angular/core';
import { PaginatedData, PaginateObject } from '../shared/dto/paginated-data';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { RequestService } from '../shared/services/request-service';
import { FilterScope, PAGINATE_NO_LIMIT } from '../shared/query/paginated-request.component';
import { AnnuityInvoicePaginated } from '../shared/dto/annuities/annuity-invoice-paginated';
import { AnnuityInvoice } from '../shared/dto/annuities/annuity-invoice';
import { AnnuityInvoiceLineItemInfo } from '../shared/dto/annuities/annuity-invoice-line-item-info';
import { AnnuityInvoiceLineItem } from '../shared/dto/annuities/annuity-invoice-line-item';
import { isNullOrUndefined } from '../shared/utils/is-null-or-undefined';

@Injectable({
  providedIn: 'root'
})
export class AnnuityInvoiceService {

  constructor(private httpClient: HttpClient, private requestService: RequestService) { }

  public getAllPaginated(request?: PaginatedData<AnnuityInvoicePaginated>): Observable<PaginatedData<AnnuityInvoicePaginated>> {
    const url = '/api/annuity/invoice/paginated';
    const headers = this.requestService.buildHttpHeaders();

    let defaultRequest = request;

    // Request is optional, and if we just want to see if the endpoint returns something.
    if (!defaultRequest) {
      defaultRequest = new PaginatedData<AnnuityInvoicePaginated>();
      defaultRequest.scope = FilterScope.MY_COMPANY;
      defaultRequest.size = PAGINATE_NO_LIMIT;
    }

    return this.httpClient
      .post<any>(url, defaultRequest.toObject(), { headers: headers })
      .pipe(
        // Update the given pagination object with values from the response.
        map((response: PaginateObject<AnnuityInvoicePaginated>) => defaultRequest.update(response))
      );
  }

  public get(id: number): Observable<AnnuityInvoice> {
    const url = `/api/annuity/invoice/${id}`;
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient.get<AnnuityInvoice>(url, { headers });
  }

  public getLineItemInfo(annuityId: number): Observable<AnnuityInvoiceLineItemInfo[]> {
    const url = `/api/annuity/${annuityId}/invoice-line-info`;
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient.get<AnnuityInvoiceLineItemInfo[]>(url, { headers });
  }

  public calculateLineItem(id: number, item: AnnuityInvoiceLineItem): Observable<AnnuityInvoiceLineItem> {
    const url = `/api/annuity/invoice/${id}/line/calculate`;
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient.post<AnnuityInvoiceLineItem>(url, item, { headers });
  }

  public calculateInvoice(id: number, invoice: AnnuityInvoice): Observable<AnnuityInvoice> {
    const url = `/api/annuity/invoice/${id}/calculate`;
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient.post<AnnuityInvoice>(url, invoice, { headers });
  }

  public updateInvoice(invoice: AnnuityInvoice): Observable<AnnuityInvoice> {
    const url = '/api/annuity/invoice';
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient.put<AnnuityInvoice>(url, invoice, { headers });
  }

  public void(id: number): Observable<AnnuityInvoice> {
    const url = `/api/annuity/invoice/${id}`;
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient.delete<AnnuityInvoice>(url, { headers });
  }

  public hasValidInvoice(annuityId: number): Observable<boolean> {
    return this.getLineItemInfo(annuityId)
      .pipe(
        map(lineItems => {
          // Debug:
          // return false;
          return lineItems
            .some(lineItem => !isNullOrUndefined(lineItem.annuityInvoiceId));
        })
      );
  }

}
