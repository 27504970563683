import { EventEmitter, Injectable } from '@angular/core';
import { ProjectsOverviewV2Response } from '../projects/projects-overview-v2/projects-overview-v2-response';
import { AuthService } from '../security/auth.service';
import { ProjectsOverview } from '../shared/dto/projects/projects-overview';
import { Observable, Subject } from 'rxjs';
import { RequestService } from '../shared/services/request-service';
import { Router } from '@angular/router';
import { Company } from '../shared/dto/company';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { FilterSelectionMemoryService } from '../shared/data-filter/filter-selection/filter-selection-memory.service';
import { ApiQueryOptions, ApiQueryParams } from '../shared/api-query-params';
import { UserService } from '../shared/services/user.service';
import { WorkflowService } from './workflow-service';
import { ProjectComponentOverview } from '../shared/dto/projects/project-component-overview';
import { PaginatedColumnValueIndex, PaginatedData, PaginateObject } from '../shared/dto/paginated-data';
import { map, tap } from 'rxjs/operators';
import { MessagingService } from '../shared/services/messaging.service';
import { ReferenceNumber } from '../estimate/create-estimate-v2/shared/reference-number';
import { ProjectResponse } from '../shared/response/project-response';
import { DeliveryContact } from '../shared/dto/preferences/delivery-contact';
import { ProjectHeaderData } from '../shared/dto/projects/project-header-data';
import { FilterScope, PAGINATE_NO_LIMIT } from '../shared/query/paginated-request.component';
import { isNullOrUndefined } from '../shared/utils/is-null-or-undefined';
import { TableColumn } from '../shared/table-column';
import { DocumentService } from './document.service';
import { VendorOrderReferences } from '../shared/dto/projects/vendor-order-references';
import { OrderOrganizationReference } from '../shared/dto/projects/order-organization-reference';
import { sortBy } from '../shared/utils/sort-by';

@Injectable()
export class ProjectsOverviewService {

  public project: ProjectsOverview;

  // push out task change project id(s)
  public taskChanges: Subject<number[]> = new Subject<number[]>();

  private projectCompany: Company;

  public projectCompanyEmitter: EventEmitter<Company>;

  public projectChangedEmitter: EventEmitter<ProjectsOverview>;

  public projectLoadingEmitter: EventEmitter<boolean>;
  public overviewParamState: any;

  private navigateInProgress = false;

  private paginationData: PaginateObject<ProjectsOverviewV2Response>;

  private dataStoreId = 'townip_projects_pagination';

  constructor(private userService: UserService,
              private httpClient: HttpClient,
              private router: Router,
              private requestService: RequestService,
              private filterSelectionMemory: FilterSelectionMemoryService,
              private workflowService: WorkflowService,
              private authService: AuthService,
              private messagingService: MessagingService,
              private docService: DocumentService,
  ) {
    this.projectCompanyEmitter = new EventEmitter();
    this.projectChangedEmitter = new EventEmitter();
    this.projectLoadingEmitter = new EventEmitter<boolean>();

    this.initPaginationData();
  }

  public getProjectsV2(request: PaginatedData<ProjectsOverviewV2Response>):
    Observable<PaginatedData<ProjectsOverviewV2Response>> {
    const url = '/api/v2/projects';
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient
      .post<any>(url, request.toObject(), { headers: headers })
      .pipe(
        // Update the given pagination object with values from the response.
        map((response: PaginateObject<ProjectsOverviewV2Response>) => request.update(response))
      );
  }

  public exportProjects(request: PaginatedData<ProjectsOverviewV2Response>, columns: TableColumn[]): Observable<HttpResponse<Blob>> {
    const url = '/api/projects/paginated/export';
    const headers = this.requestService.buildHttpHeaders();

    // Copy the request so we don't mutate the original
    const data = request.toObject();
    data.page.number = 0; // Prevents issue where incorrect import items when on page 2.
    data.page.size = data.page.totalElements;

    data.columnValues = columns.map((col) => {
      // Special FE fields that needs to be mapped backed
      if (col.field === 'clientParsed') {
        return { field: 'requestedByCompany', readableField: col.header } as PaginatedColumnValueIndex;
      }

      return { field: col.field, readableField: col.header } as PaginatedColumnValueIndex;
    });

    return this.httpClient
      .post(url, data, { headers: headers, observe: 'response', responseType: 'blob' })
      .pipe(
        // Update the given pagination object with values from the response.
        tap((resp) => {
          this.docService.saveFile(resp);
        })
      );
  }

  public getProjectsForSelection(scope: FilterScope, query: string = ''): Observable<ProjectsOverviewV2Response[]> {
    const paginationData = new PaginatedData<ProjectsOverviewV2Response>();
    paginationData.size = PAGINATE_NO_LIMIT;
    paginationData.page = 0;
    paginationData.query = query;
    paginationData.scope = scope;

    return this
      .getProjectsV2(paginationData)
      .pipe(
        map(data => data.content ? data.content : [])
      );
  }

  public getPinnedProjects(): Observable<ProjectsOverview[]> {
    // this return very lite version of project overview.
    const url = '/api/projects/pinned';

    return this.httpClient.get<ProjectsOverview[]>(url, { headers: this.requestService.buildHttpHeaders() });
  }

  public getProjectById(projectId: number): Observable<ProjectsOverview> {
    const url = '/api/projects/' + projectId;
    return this.httpClient.get<ProjectsOverview>(url, { headers: this.requestService.buildHttpHeaders() });
  }

  public togglePinProject(orderId: number, userId: number): Observable<any> {
    const url = `/api/orders/${orderId}/pinned-user/${userId}/togglePinned`;
    return this.httpClient.post<any>(url, { headers: this.requestService.buildHttpHeaders() });
  }

  public toggleFollowProject(orderId: number, userId: number, follow: boolean): void {
    // change this endpoint to pass in follow for auto correct with backend when it is already followed/unfollowed.
    const url = `/api/orders/${orderId}/followers/${userId}/toggleFollow/${follow}`;

    this.httpClient.post<any>(url, { headers: this.requestService.buildHttpHeaders() })
      .subscribe(() => {
      },
      (err: any) => {
        console.error(err);
      }, () => {
        this.messagingService.syncProjectMessages(orderId);
      });
  }

  public savePaginationData(data: PaginatedData<ProjectsOverviewV2Response>): void {
    const content = data.content;

    const paginationData = data.toObject();
    paginationData.columnValues = [];

    paginationData.page.content = content.map(project => {
      return {
        id: +project.id,
        projectCategoryString: project.projectCategoryString
      };
    });
    this.paginationData = paginationData;
    window
      .localStorage
      .setItem(this.dataStoreId, JSON.stringify(paginationData));
  }

  public clearPaginationData(): void {
    this.paginationData = null;
    window
      .localStorage
      .removeItem(this.dataStoreId);
  }

  public isFirstProject(id: number): boolean {
    if (!id || !this.paginationData || !this.paginationData.page.content.length) {
      return true;
    }
    const content = this.paginationData.page.content;
    const index = content.findIndex(project => {
      return project.id === id;
    });
    return this.paginationData.page.first && index === 0 || index === -1;
  }

  public isLastProject(id: number): boolean {
    if (!id || !this.paginationData || !this.paginationData.page.content.length) {
      return true;
    }
    const lastIndex = this.paginationData.page.content.length - 1;
    const content = this.paginationData.page.content;
    const index = content.findIndex(project => {
      return project.id === id;
    });
    return this.paginationData.page.last && index === lastIndex || index === -1;
  }

  public traverseProjects(id: number, isNext: boolean): void {
    if (this.navigateInProgress) {
      return;
    }
    this.navigateInProgress = true;
    this.getPrevNextProject(id, isNext)
      .then(project => {
        if (project) {
          this.gotoProject(project);
        }
        this.navigateInProgress = false;
      });
  }

  private initPaginationData(): void {
    const paginationStr = window
      .localStorage
      .getItem(this.dataStoreId);
    if (paginationStr) {
      this.paginationData = JSON.parse(paginationStr);
    }

    this.authService
      .connectedEmitter
      .subscribe(loggedIn => {
        if (!loggedIn) {
          window
            .localStorage
            .removeItem(this.dataStoreId);
          this.paginationData = null;
        }
      });
  }

  private getPrevNextProject(id: number, isNext: boolean): Promise<ProjectsOverviewV2Response> {
    const content = this.paginationData.page.content;

    if (!content.length || this.isFirstProject(id) && !isNext || this.isLastProject(id) && isNext) {
      return Promise.resolve(null);
    }

    const index = content.findIndex(project => {
      return +project.id === id;
    });

    if (index < 0) {
      return Promise.resolve(null);
    }

    const newIndex = index + (isNext ? 1 : -1);

    // if newIndex is within the list - return project at newIndex
    if (newIndex >= 0 && newIndex < content.length) {
      return Promise.resolve(content[newIndex]);
    }

    // if newIndex is outside of list - navigate to the next/previous page
    this.paginationData.page.number += (isNext ? 1 : -1);
    return this.fetchData()
      .then(newContent => {
        return content.length ? newContent[isNext ? 0 : this.paginationData.page.size - 1] : null;
      });
  }

  private fetchData(): Promise<ProjectsOverviewV2Response[]> {
    const paginationTemp = new PaginatedData<ProjectsOverviewV2Response>(this.paginationData);
    return this.getProjectsV2(paginationTemp)
      .toPromise()
      .then(() => {
        this.savePaginationData(paginationTemp);
        return this.paginationData.page.content;
      })
      .catch(() => {
        return [];
      });
  }

  private gotoProject(project: ProjectsOverviewV2Response): void {
    const user = this.userService.getUser();
    if (project.projectCategoryString === 'PATENT_PROSECUTION') {
      this.patentProsecutionProjectNavigation(project, user.userType);
    } else if (project.projectCategoryString === 'LEGAL_TRANSLATION') {
      this.legalTranslationProjectNavigation(project, user.userType);
    }
  }

  /**
   * Project Navigation for Patent Prosecution project category
   * @param project
   * @param userType
   */
  private patentProsecutionProjectNavigation(project: ProjectsOverviewV2Response, userType: string): void {
    const detailRoute = '/projects/';
    let subRoute: string;
    if (userType === 'INTERNAL' &&
      !this.userService.authorizations.ROLE_INTERNAL_SALES_MGR &&
      !this.userService.authorizations.ROLE_INTERNAL_ACCOUNT_MGR) {
      subRoute = 'workspace';
    } else if (userType === 'VENDOR') {
      subRoute = 'task-view';
    } else {
      subRoute = 'summary-view';
    }

    this.router.navigate([detailRoute, project.id, subRoute]);
  }

  /**
   * Project navigation for Legal Translation project category
   * @param project
   * @param userType
   */
  private legalTranslationProjectNavigation(project: ProjectsOverviewV2Response, userType: string): void {
    const detailRoute = '/projects/';
    let subRoute: string;
    if (userType === 'INTERNAL' &&
      !this.userService.authorizations.ROLE_INTERNAL_SALES_MGR &&
      !this.userService.authorizations.ROLE_INTERNAL_ACCOUNT_MGR) {
      subRoute = 'general-translation-workspace';
    } else if (userType === 'VENDOR') {
      subRoute = 'general-translation-vendor-workspace';
    } else {
      subRoute = 'general-translation-summary-view';
    }

    this.router.navigate([detailRoute, project.id, subRoute]);
  }

  public setProjectCompany(company: Company): void {
    this.projectCompany = company;
    this.projectCompanyEmitter.emit(company);
  }

  public getProjectCompany(): Company {
    return this.projectCompany;
  }

  public cleanup(): void {
    this.projectCompany = null;
    this.project = null;
  }

  public emitTaskChanges(projectId: number): void {
    if (this.taskChanges) {
      this.taskChanges.next(isNullOrUndefined(projectId) ? null : [projectId]);
    }
  }

  public resolveWorkflowActions(projects: ProjectsOverview[]): void {
    if (projects) {
      projects.forEach(t => {
        if (t.projectActions) {
          t.resolvedWorkflowActions = [];

          t.projectActions.forEach(waId => {
            if (isNullOrUndefined(this.workflowService.workflowActions)) {
              return;
            }
            const foundWorkflowAction = this.workflowService.workflowActions.find(wa => wa.id === waId);
            if (foundWorkflowAction) {
              t.resolvedWorkflowActions.push(foundWorkflowAction);
            }
          });
        }
      });
    }
  }

  public resolveWorkflowComponentActions(componentOverviews: ProjectComponentOverview[]): void {
    if (componentOverviews) {
      componentOverviews.forEach(t => {
        if (t.componentActions) {
          t.resolvedComponentActions = [];

          t.componentActions.forEach(waId => {
            if (isNullOrUndefined(this.workflowService.workflowActions)) {
              return;
            }
            const foundWorkflowAction = this.workflowService.workflowActions.find(wa => wa.id === waId);
            if (foundWorkflowAction) {
              t.resolvedComponentActions.push(foundWorkflowAction);
            }
          });
        }
      });
    }
  }

  public updateReferenceNumber(estimateId: number, referenceNumbers: ReferenceNumber[]): Promise<ProjectResponse> {
    const url = `/api/project/estimate/${estimateId}/reference/number`;
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.put<ProjectResponse>(url, referenceNumbers, { headers: headers })
      .toPromise();
  }

  public getCompanyDeliveryContactsForEstimate(estimateId: number, teamId?: number): Promise<DeliveryContact[]> {
    let url = `/api/project/estimate/${estimateId}/delivery-contacts`;
    if (!isNullOrUndefined(teamId)) {
      const queryOptions: ApiQueryOptions = {
        queryParams: {
          teamId: teamId,
        }
      };
      url += new ApiQueryParams(queryOptions).generateQueryParams();
    }
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.httpClient.get<DeliveryContact[]>(url, { headers: headers })
      .toPromise();
  }

  public getProjectHeaderData(projectId: number): Observable<ProjectHeaderData> {
    const url = `/api/projects/${projectId}/header`;
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.get<ProjectHeaderData>(url, { headers: headers });
  }

  public getVendorReferenceNumbers(projectId: number, vendorId: number, includeRequested: boolean = false):
    Observable<VendorOrderReferences> {
    let url = `/api/projects/${projectId}/vendor/${vendorId}/reference-numbers`;

    if (includeRequested) {
      const queryOptions: ApiQueryOptions = {
        queryParams: {
          isIncludeRequested: true,
        }
      };
      url += new ApiQueryParams(queryOptions).generateQueryParams();
    }

    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient
      .get<VendorOrderReferences>(url, { headers: headers })
      .pipe(
        tap((response: VendorOrderReferences) => {
          response.countryReferenceNumbers = sortBy(response.countryReferenceNumbers, 'country.name');
        })
      );
  }

  public getVendorCountryFilingReferenceNumbers(orderId: number): Observable<OrderOrganizationReference[]> {
    const url = `/api/projects/${orderId}/vendor-reference-numbers`;
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.get<OrderOrganizationReference[]>(url, { headers: headers });
  }
}
