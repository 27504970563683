import { CreateInvoiceDepositRequest, CustomerInvoice, InvoiceJurisdictionLineItem, InvoiceTranslationLineItem, UpdateInvoiceCostRequest } from '../shared/dto/invoice';
import { Observable } from 'rxjs';
import { RequestService } from '../shared/services/request-service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PaginatedData, PaginateObject } from '../shared/dto/paginated-data';
import { InvoicePaginated, InvoicePaginatedFields } from '../shared/dto/invoice-paginated';
import { map } from 'rxjs/operators';
import { RsqlEncoder } from '../shared/data-filter/rsql-encoder';
import { FilterScope, PAGINATE_NO_LIMIT } from '../shared/query/paginated-request.component';
import { FieldType } from '../shared/data-filter/field-type';
import { OPERATORS } from '../shared/data-filter/filter-operators';
import { UserService } from '../shared/services/user.service';

@Injectable()
export class InvoiceService {

  constructor(private httpClient: HttpClient,
              private requestService: RequestService,
              private userService: UserService,
  ) {
  }

  public getAllPaginated(request: PaginatedData<InvoicePaginated>): Observable<PaginatedData<InvoicePaginated>> {
    const url = '/api/invoice/paginated';
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient
      .post<any>(url, request.toObject(), { headers })
      .pipe(
        // Update the given pagination object with values from the response.
        map((response: PaginateObject<InvoicePaginated>) => request.update(response)),
      );
  }

  public getProjectSummaryInvoices(projectId: number): Observable<InvoicePaginated[]> {
    const url = '/api/invoice/paginated';
    const headers = this.requestService.buildHttpHeaders();

    const rsqlEncoder = new RsqlEncoder();
    const request = new PaginatedData<InvoicePaginated>();
    request.size = PAGINATE_NO_LIMIT;
    request.scope = this.userService.isPM() ? FilterScope.MINE : this.userService.paginatedScope;
    request.query = rsqlEncoder.encode({
      field: InvoicePaginatedFields.projectId,
      type: FieldType.Numeric,
      operator: OPERATORS.common.$eq,
      value: projectId,
    });

    request.addColumnValue(InvoicePaginatedFields.country, null, true);
    request.addColumnValue(InvoicePaginatedFields.referenceNumbers);

    return this.httpClient
      .post<PaginateObject<InvoicePaginated>>(url, request.toObject(), { headers })
      .pipe(
        // Update the given pagination object with values from the response.
        map(response => response.page.content ? response.page.content : []),
      );
  }

  public getInvoice(id: number): Observable<CustomerInvoice> {
    const url = `/api/invoice/${id}`;
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient.get<CustomerInvoice>(url, { headers: headers });
  }

  public updateInvoiceCost(request: UpdateInvoiceCostRequest): Observable<CustomerInvoice> {
    const url = `/api/invoice/${request.id}/cost`;
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient.put<CustomerInvoice>(url, request, { headers: headers });
  }

  public createAdditional(orderId: number, dueDate: number): Observable<CustomerInvoice> {
    const url = '/api/invoice/additional';
    const headers = this.requestService.buildHttpHeaders();

    const request = { orderId: orderId, dueDate: dueDate };
    return this.httpClient.post<CustomerInvoice>(url, request, { headers: headers });
  }

  public createDeposit(request: CreateInvoiceDepositRequest): Observable<CustomerInvoice> {
    const url = '/api/invoice/deposit';

    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.post<CustomerInvoice>(url, request, { headers: headers });
  }

  public voidInvoice(id: number): Observable<CustomerInvoice> {
    const url = `/api/invoice/void/${id}`;

    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.put<CustomerInvoice>(url, null, { headers: headers });
  }

  public sendDraftInvoice(id: number): Observable<CustomerInvoice> {
    const url = `/api/invoice/${id}/send`;

    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.put<CustomerInvoice>(url, null, { headers: headers });
  }

  public getRegInvoiceByProjectAndType(projectId: number): Observable<CustomerInvoice> {
    const url = `/api/invoice/regular/project/${projectId}`;
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient.get<CustomerInvoice>(url, { headers: headers });
  }

  public updateInvoiceToCountryInvoices(invoiceId: number): Observable<CustomerInvoice> {
    const url = `/api/invoice/${invoiceId}/country-invoices`;
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient.put<CustomerInvoice>(url, null, { headers: headers });
  }

  public getUpdatedJurisdictionLineItem(invoiceId: number,
    item: InvoiceJurisdictionLineItem): Observable<InvoiceJurisdictionLineItem> {
    const url = `/api/invoice/${invoiceId}/jurisdiction/calculate`;
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient.post<InvoiceJurisdictionLineItem>(url, item, { headers: headers });
  }

  public getUpdatedTranslationLineItem(invoiceId: number,
    item: InvoiceTranslationLineItem): Observable<InvoiceTranslationLineItem> {
    const url = `/api/invoice/${invoiceId}/translation/calculate`;
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient.post<InvoiceTranslationLineItem>(url, item, { headers: headers });
  }

  public getUpdatedInvoiceCost(invoiceId: number, invoice: UpdateInvoiceCostRequest): Observable<CustomerInvoice> {
    const url = `/api/invoice/${invoiceId}/calculate-cost`;
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient.put<CustomerInvoice>(url, invoice, { headers: headers });
  }

  public getSendStatus(projectId: number): Observable<{ response: string, status: string }> {
    const url = `/api/invoice/send-status/project/${projectId}`;
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient.post<{ response: string, status: string }>(url, null, { headers: headers });
  }

}
