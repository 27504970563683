import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalComponent } from './modal.component';
import { ModalModule as BsModal } from 'ngx-bootstrap/modal';
import { ModalBodyComponent } from './modal-body/modal-body.component';
import { ModalFooterComponent } from './modal-footer/modal-footer.component';

@NgModule({
  imports: [
    CommonModule,
    BsModal,
  ],
  declarations: [ModalComponent, ModalBodyComponent, ModalFooterComponent],
  exports: [ModalComponent, ModalBodyComponent, ModalFooterComponent]
})
export class ModalModule { }
