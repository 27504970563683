import { ErrorHandler } from '@angular/core';
import { GlobalErrorHandlerService } from './global-error-handler.service';
import { environment } from '../../../environments/environment';

export class GlobalErrorHandler extends ErrorHandler {

  constructor(private globalErrorHandlerService: GlobalErrorHandlerService) {
    super();
  }

  public handleError(e: any): void {
    if (!environment.production) {
      super.handleError(e);
    }

    this.globalErrorHandlerService.handleError(e);
  }
}
