import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'underscore';

@Pipe({
  name: 'checklistGroupParentFilter'
})
export class ChecklistGroupParentFilterPipe implements PipeTransform {

  public transform(value: any[], parentGroup?: string): any {
    return _.filter(value, (item) => {
      return item.parent === parentGroup;
    });
  }
}
