import { EventEmitter, Injectable } from '@angular/core';
import { LoggingService } from '../../shared/logging.service';
import { PortalUser } from '../../shared/domain/user';
import { NavigationEnd, NavigationExtras, Router } from '@angular/router';
import { TasksOverviewService } from '../../data-services/tasks-overview.service';
import { UserService } from '../../shared/services/user.service';
import { MessageProjectCategory } from '../../shared/dto/messaging/message-project-category';
import { Message } from '../../shared/dto/messaging/message';
import { AlertMessage, ScopeRouteType } from '../../shared/dto/messaging/alert-message';
import { MessagingService } from '../../shared/services/messaging.service';
import { TaskNavigationService } from '../../tasks/services/task-navigation.service';
import { AuthService } from '../../security/auth.service';
import { filter, take } from 'rxjs/operators';
import { DashboardPreference } from '../../shared/enums/dashboard-preference';

@Injectable()
export class NavService {
  private expanded = false;
  public collapseEmitter = new EventEmitter<boolean>();

  private navigationHistory: string[] = [];

  constructor(private loggingService: LoggingService,
              private router: Router,
              private userService: UserService,
              private taskOverviewService: TasksOverviewService,
              private messagingService: MessagingService,
              private taskNavService: TaskNavigationService,
              private authService: AuthService) {
    this.expanded = false;
    this.collapseEmitter = new EventEmitter();

    this.router
      .events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => this.navigationHistory.push(event.url));
  }

  /**
   * This method will handle emitting to the app if the page is expanded;
   */
  public toggle(): boolean {
    this.expanded = !this.expanded;
    this.collapseEmitter.emit(this.expanded);
    return this.expanded;
  }

  public navigateTo(tagId: string, placement: string = 'center', offset?: number): void {
    if (!tagId.startsWith('#')) {
      tagId = '#' + tagId;
    }
    const element = document.querySelector(tagId) as HTMLElement;
    const target = jQuery(tagId);
    const scrollOffset = 150; // This is the size of the header + navbar + additional spacing

    if (!!element) {
      setTimeout(() => {
        // Scrolling logic when an offset has been given
        jQuery('html, body')
          .animate({
            scrollTop: target.offset().top - scrollOffset
          }, 800);
      });

    } else {
      this.loggingService.info('could not find element for ', tagId);
    }
  }

  public hasStep(tagId: string): boolean {
    if (!tagId.startsWith('#')) {
      tagId = '#' + tagId;
    }
    const element = document.querySelector(tagId);
    return !!element;
  }

  public goToDashboard(user: PortalUser, returnUrl: boolean = false): string {
    let url = '/settings-v2'; // Set settings as default route since no permission is needed to access it

    if (user.defaultDashboardPreference === DashboardPreference.Reports) {
      // Annuities module has its own redirection logic
      url = '/reporting';
    }

    if (user.defaultDashboardPreference === DashboardPreference.Annuities) {
      // Annuities module has its own redirection logic
      url = '/annuities';
    }

    if (user.defaultDashboardPreference === DashboardPreference.Forecaster) {
      // Forecaster module its own redirection logic
      url = '/forecast/dashboard';
    }

    if (user.defaultDashboardPreference === DashboardPreference.TranslationAndFiling) {
      url = this.getTranslationAndFilingDashboardUrl(user);
    }

    if (returnUrl) {
      return url;
    } else {
      this.router.navigate([url]);
    }
  }

  public goToTranslationFilingDashboard(user: PortalUser, returnUrl: boolean = false): string {
    const url = this.getTranslationAndFilingDashboardUrl(user);

    if (returnUrl) {
      return url;
    }

    this.router.navigate([url]);
  }

  public goToProject(user: PortalUser, projectId: number, alert?: AlertMessage): void {
    // If no alert provided, redirect to the main projects page.
    if (!alert) {
      this.router.navigate(['projects', projectId]);
      return;
    }

    if (alert.scopeRouteType === ScopeRouteType.Files || alert.action === 'filesAvailable') {
      this.navigateToProjectFiles(projectId, alert.projectCategory);
      return;
    }

    // If it does not suffice any from the conditions, redirect to the main page of project.
    this.router.navigate(['projects', projectId]);
  }

  public isExpanded(): boolean {
    return this.expanded;
  }

  public navigateToEstimateMessage(message: Message): void {
    if (message.hideScope) {
      return;
    }

    // Customers
    if (this.userService.user.userType === 'COMPANY') {
      this.navigateToEstimateClientView(message);
      return;
    }

    // Sales/Account Managers. Basically they see the same thing as the customers
    if (this.userService.user.userType === 'INTERNAL'
      && (this.userService.authorizations.ROLE_INTERNAL_ACCOUNT_MGR
        || this.userService.authorizations.ROLE_INTERNAL_SALES_MGR)) {

      this.navigateToEstimateClientView(message);
      return;
    }

    // PM's and admins, internal users (aside from sales/account)
    if (this.userService.user.userType === 'INTERNAL'
      && message.projectCategory === MessageProjectCategory.PatentProsecution) {
      this.router.navigate(['estimate/estimate-review', message.scopeId]);
      return;
    }

    if (this.userService.user.userType  === 'INTERNAL'
      && message.projectCategory === MessageProjectCategory.LegalTranslation) {
      this.router.navigate(['estimate/general-translation-review', message.scopeId]);
      return;
    }
  }

  public navigateAlert(alert: AlertMessage, metadata?: Message | AlertMessage): void {
    // This is the routing if scope is provided and there is no metadata
    // metadata will only be provided when request came from toast alert
    // We will enter to this logic if the request is like this
    // localhost:4200/redirect?scope=PROJECT&scopeId=7195&projectCategory=LEGAL_TRANSLATION&scopeRouteType=FILES
    if (alert.scope && !metadata) {
      this.redirectionRouting(alert);
      return;
    }

    // Default routing of alert
    if (alert && alert.alertRoute) {
      const user = this.userService.user;
      this.messagingService.markMessageRead(user.id, alert.baseMessageId, 'ALERT');

      if (metadata.scope === 'PROJECT') {
        this.goToProject(user, alert.scopeId, alert);
      } else if (metadata.scope === 'TASK') {
        this.taskNavService.navigateToTaskByIds(metadata.scopeId, metadata.parentScopeId, metadata.projectCategory);
      } else {
        this.navigateToEstimateMessage(alert);
      }
    }
  }

  /**
   * By specifying a path, if there is no history in the browser, it will navigate to the given path
   * using the Angular router. Otherwise, it will use window history (similar to back button);
   *
   * @param path
   * @param extras
   * @param useLegacyHandling Use the old way of doing navigations. Use for pages that previously needed it
   */
  public goBackOrPath(path: string, extras?: NavigationExtras, useLegacyHandling?: boolean): Promise<boolean> {
    if (useLegacyHandling) {
      // OLD HANDLING that uses history for pages that need it.
      // Need to OPT-IN TO IT.
      if (this.navigationHistory.length < 2) {
        return this.router.navigate([path], extras);
      }

      return this.router.navigateByUrl(this.navigationHistory[this.navigationHistory.length - 2]);
    }

    // MOVING FORWARD Use default handling ALWAYS. USE. BROWSER. HISTORY. PERIOD.
    // IF QA SAYS = New tab opened, navigated to a hard link and EXIT/BACK redirects to new tab again
    // - This is actually the correct behavior
    // NO WE WILL NOT HAVE A "DEFAULT" page to navigate if the user EXITS/BACK without a browser history.
    // - @path parameter will be deprecated in the future.
    // If it is an INTERACTION ISSUE (modal x modal x canDeactivate) WE WILL CHANGE THE IMPLEMENTATION of the PAGE.
    // - Yes we will change the Implementation rather than work around it and find "hacks" to make it work
    // IF ALL ELSE FAILS
    // - discuss with @dpili first.
    // ---------------------------------------------------------------------------------------------------------------
    // AGAIN TO EMPHASIZE: WE WILL USE NATIVE BROWSER HISTORY FUNCTION ON BACK/EXIT BUTTONS FOR FULL PAGES. PERIOD.
    // ---------------------------------------------------------------------------------------------------------------
    // This back/exit buttons already created more problems that it solves.
    // Using any other method than browser history is UNRELIABLE. PERIOD.
    // ---------------------------------------------------------------------------------------------------------------
    if (window.history.length >= 2) {
      // Why is this >= 2 you ask?
      // Because opening a new tab - the tab(1) in history is the new tab page.
      // The problem comes when the user configured "new tabs" to be a blank page, making tab(1) the URL you entered.
      // -- and this is exactly why we want to ged rid of this "default" page if no history function.
      // But maybe that is an edge case but we will definitely move to natural back navigations for browsers
      window.history.back();
      return;
    }

    return this.router.navigate([path], extras);
  }

  private navigateToEstimateClientView(message: Message): void {
    if (message.projectCategory === MessageProjectCategory.LegalTranslation) {
      this.router.navigate(['estimate/general-translation', message.scopeId]);
    } else {
      this.router.navigate(['estimate/project-review', message.scopeId]);
    }
  }

  private redirectionRouting(alert: AlertMessage): void {
    // We need to check first if the user is already connected
    // When connected, we can identify where we will route the user based on the User Type
    if (this.authService.isConnected()) {
      this.redirectionRoutes(alert);
      return;
    }

    // Subscribe to the connectedEmitter of auth service to redirect once connected
    this.authService
      .connectedEmitter
      .pipe(take(1))
      .subscribe((connected: boolean) => {
        if (connected) {
          this.redirectionRoutes(alert);
        }
      });
  }

  private redirectionRoutes(alert: AlertMessage): void {
    if (alert.scope === 'PROJECT') {
      this.goToProject(this.userService.user, alert.scopeId, alert);
    } else if (alert.scope === 'TASK') {
      this.taskNavService.navigateToTaskByIds(
        alert.scopeId,
        alert.parentScopeId,
        alert.projectCategory,
        false,
        alert);
    } else {
      this.navigateToEstimateMessage(alert);
    }
  }

  private navigateToProjectFiles(projectId: number, projectCategory: string): void {
    if (projectCategory === 'PATENT_PROSECUTION') {
      this.router.navigate(['projects', projectId, 'files']);
      return;
    }

    this.router.navigate(['projects', projectId, 'general-translation-files']);
  }

  private getTranslationAndFilingDashboardUrl(user: PortalUser): string {
    // -------------------------------------------------------------
    // PORTED FROM OLD LOGIC, removed annuities/forecaster specific
    // -------------------------------------------------------------

    if (!user) {
      return '/auth/login';
    }

    let url: string;

    if (user.userType === 'COMPANY') { // Only for Main system users.
      url = '/dashboard/customer-v2';
    }

    if (user.userType === 'VENDOR') {
      if (this.userService.authorizations.ROLE_VENDOR_ADMIN) {
        url = '/dashboard/vendor-manager';
      } else {
        url = '/dashboard/vendor-user';
      }
    }

    if (user.userType === 'INTERNAL') {
      if (this.userService.authorizations.ROLE_INTERNAL_ACCOUNT_MGR
        || this.userService.authorizations.ROLE_INTERNAL_SALES_MGR) {
        url = '/dashboard/sales-account-manager-v2';
      } else {
        url = '/dashboard/project-manager';
      }
    }

    return url;
  }
}
