import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RequestService } from '../shared/services/request-service';
import { InternalOrganizationDTO, InternalOrganizationLiteDTO, InternalUserDTO, InternalUserMetrics } from '../shared/dto/internal-management';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CompanyUser } from '../shared/dto/customer-management/company-user';
import { SystemTerm } from '../shared/dto/system-term';
import { InternalUserValidationMessage } from '../shared/dto/InternalUserValidationMessage';
import { map } from 'rxjs/operators';
import * as sortBy from 'lodash/sortBy';
import { generateFullname } from '../shared/overview-utilities/generate-fullname';

@Injectable()
export class InternalManagementService {
  private internalOrg: InternalOrganizationDTO;
  private internalUser: InternalUserDTO;

  public internalUserTypes: string[];

  constructor(private http: HttpClient, private requestService: RequestService) {
    this.internalUserTypes = [
      'Project Manager',
      'Account Manager',
      'Sales Manager',
      'Admin',
      'Account Admin',
      'Sales Admin',
      'View Only'
    ];
  }

  public setInternalOrg(internalOrg: InternalOrganizationDTO): void {
    this.internalOrg = internalOrg;
  }

  public getInternalOrg(): InternalOrganizationDTO {
    return this.internalOrg;
  }

  public setInternalUser(internalUser: InternalUserDTO): void {
    this.internalUser = internalUser;
  }

  public getInternalUser(): InternalUserDTO {
    return this.internalUser;
  }

  public updateInternalUserPermissions(userId: number,
    permissions: string[]): Observable<InternalUserValidationMessage> {
    const url = `api/internal/user/${userId}/update/permissions?permissions=${permissions}`;
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.http.post<InternalUserValidationMessage>(url, null, { headers: headers });
  }

  public createInternalUser(user: InternalUserDTO): Observable<InternalUserDTO> {
    const url = `/api/internal/organization/${user.organizationId}/user`;
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.http.post<InternalUserDTO>(url, user, { headers: headers });
  }

  public saveInternalUser(user: InternalUserDTO): Observable<InternalUserDTO> {
    const url = `/api/internal/user/${user.id}`;
    const headers = this.requestService.buildHttpHeaders();
    return this.http.put<InternalUserDTO>(url, user, { headers: headers });
  }

  public updateInternalUserRole(userId: number, role: string): Observable<InternalUserValidationMessage> {
    const url = `/api/internal/user/${userId}/role/${role}`;
    const headers = this.requestService.buildHttpHeaders();
    return this.http.put<InternalUserValidationMessage>(url, null, { headers: headers });
  }

  public toggleInternalUserActivationStatus(userId: number): Observable<InternalUserValidationMessage> {
    const url = `/api/internal/user/${userId}/toggle/active/status`;
    const headers = this.requestService.buildHttpHeaders();
    return this.http.put<InternalUserValidationMessage>(url, null, { headers: headers });
  }

  public getAllInternalUsers(): Observable<InternalUserDTO[]> {
    const url = '/api/internal/assignable-users';
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.http.get<InternalUserDTO[]>(url, { headers: headers })
      .pipe(
        map(users => {
          return sortBy(users, (internalUser: InternalUserDTO) => {
            return generateFullname(internalUser.user)
              .toUpperCase();
          });
        })
      );
  }

  public getAssignableUsers(userType: string, scopeType?: string, projectId?: number): Observable<InternalUserDTO[]> {
    let url = `/api/internal/assignable-users?userType=${userType}`;

    if (scopeType) {
      url += `&scopeType=${scopeType}`;
    }

    if (projectId) {
      url += `&projectId=${projectId}`;
    }

    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.http.get<InternalUserDTO[]>(url, { headers: headers })
      .pipe(
        map(data => {
          return sortBy(data, (internalUser) => {
            return internalUser.user.firstName.toLowerCase() + internalUser.user.lastName.toLowerCase();
          });
        })
      );
  }

  public getOrganization(organizationId: number): Observable<InternalOrganizationDTO> {
    const url = `/api/internal/organization/${organizationId}`;
    const headers = this.requestService.buildHttpHeaders();
    const orgObservable: Observable<InternalOrganizationDTO> =
      this.http.get<InternalOrganizationDTO>(url, { headers: headers });
    orgObservable.toPromise()
      .then(org => this.setInternalOrg(org));
    return orgObservable;
  }

  public getLiteOrganization(organizationId: number): Observable<InternalOrganizationLiteDTO> {
    const url = `/api/internal/organization/lite/${organizationId}`;
    const headers = this.requestService.buildHttpHeaders();
    return this.http.get<InternalOrganizationLiteDTO>(url, { headers: headers });
  }

  public retrieveInternalUser(internalUserId: number): Promise<InternalUserDTO> {
    return new Promise((resolve, reject) => {
      const url = `/api/internal/user/${internalUserId}`;
      const headers: HttpHeaders = this.requestService.buildHttpHeaders();
      const companyUserObservable = this.http.get<CompanyUser>(url, { headers: headers });
      companyUserObservable.subscribe(cu => {
        this.setInternalUser(cu);
        resolve(cu);
      });
    });
  }

  public getInternalUserMetrics(internalUserId: number): Observable<InternalUserMetrics> {
    const url = `/api/internal/user/${internalUserId}/metrics`;
    const headers = this.requestService.buildHttpHeaders();
    return this.http.get<InternalUserMetrics>(url, { headers: headers });
  }

  public bypassTermsOfService(termId: number, status: boolean, reason?: string): Observable<SystemTerm> {
    const url = `api/term/${termId}/bypass/${status}`;
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.http.post<SystemTerm>(url, reason, { headers: headers });
  }
}
