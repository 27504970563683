import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RequestService } from '../shared/services/request-service';
import { TermsOfUse } from '../shared/dto/terms-of-use';

@Injectable({
  providedIn: 'root'
})
export class SystemTermsService {

  constructor(
    private http: HttpClient,
    private requestService: RequestService,
  ) { }

  public get(id: number): Observable<TermsOfUse> {
    const url = `/api/terms-of-use/${id}`;
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.http.get<any>(url, { headers: headers });
  }
}
