import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'relativeTime',
  pure: false // So that the relative value updates as time passes by
})
export class RelativeTimePipe implements PipeTransform {

  public transform(value: any, args?: any): any {
    return moment(value)
      .fromNow();
  }

}
