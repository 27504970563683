import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { HttpRequestErrorInterceptorMessages } from '../interceptors/http-request-error-interceptor-messages';

export enum ConnectionDaemonStatusCode {
  Offline = 'OFFLINE',
  Backend = 'BACKEND',
  Unknown = 'UNKNOWN',
  Online = 'ONLINE',
}

@Injectable()
export class ConnectionDaemonService {

  public status = new BehaviorSubject<ConnectionDaemonStatusCode>(ConnectionDaemonStatusCode.Online);

  // The url path to check if an internet connection is active.
  public connectionCheckPath = '/assets/alive.txt';

  public versionStatus = new Subject<string>();

  // The url path to check if the backend api is available
  // private backendCheckPath = 'assets/alive.txt';
  private backendCheckPath = '/api/';

  // Number of milliseconds before each each
  private interval = 15000; //  15 secs

  private isChecking = false;

  private lastCheck: 'CLIENT' | 'BACKEND';

  private checkEnabled = true;

  constructor(private http: HttpClient,
              private interceptorMessaging: HttpRequestErrorInterceptorMessages) { }

  public start(): void {
    // timer
    setInterval(() => {
      if (!this.checkEnabled) {
        return;
      }

      // Don't do another one if previous one is still in progress
      if (this.isChecking) {
        return;
      }

      this.isChecking = true;

      // Sequence for both backend/frontend checking
      // Check them sequentially
      // this.checkConnection().toPromise()
      //   .then(() => {
      //     return this.checkApi().toPromise();
      //   })
      //   .then(() => {
      //     // ONLINE status must be sent here because first one must pass!
      //     this.status.next(ConnectionDaemonStatusCode.Online);
      //     this.isChecking = false;
      //   })
      //   .catch((err) => {
      //     this.isChecking = false;
      //     this.handlerError(err)
      //   });

      // Sequence for frontend connectivity only
      // Check them sequentially

      // Bypass growl for this endpoint. It can get annoying when disconnected for too long.
      this.interceptorMessaging.add({ url: this.connectionCheckPath, bypass: true, forceNoOrigin: true });

      this.checkConnection()
        .toPromise()
        .then((content) => {
          this.versionStatus.next(content);
          this.status.next(ConnectionDaemonStatusCode.Online);
          this.isChecking = false;
        })
        .catch((err) => {
          this.isChecking = false;
          this.handlerError(err);
        });

    }, this.interval);
  }

  public disable(): void {
    this.checkEnabled = false;
  }

  public enable(): void {
    this.checkEnabled = true;
  }

  private checkConnection(): Observable<string> {
    // Set the last checked endpoint so we know where the problem came from
    this.lastCheck = 'CLIENT';

    // Make sure we're not caching. Tell the browser we don't want to cache
    const headers = {
      'Cache-Control': 'no-cache'
    };
    return this.http.get(this.connectionCheckPath, { responseType: 'text', headers });
  }

  private checkApi(): Observable<string> {
    // Set the last checked endpoint so we know where the problem came from
    this.lastCheck = 'BACKEND';
    return this.http.get(this.backendCheckPath, { responseType: 'text' });
  }

  private handlerError(err: HttpErrorResponse): void {
    if (this.lastCheck === 'CLIENT') {
      this.status.next(ConnectionDaemonStatusCode.Offline);
    } else {
      this.status.next(ConnectionDaemonStatusCode.Backend);
    }
  }

}
