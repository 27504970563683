import { Injectable } from '@angular/core';
import { GlobalModalComponent } from './global-modal.component';
import { GlobalModalConfirmOptions } from './global-modal-options';
import { LeavePageWarningModalComponent } from '../../annuities/shared/leave-page-warning-modal/leave-page-warning-modal.component';

@Injectable({
  providedIn: 'root'
})
export class GlobalModalService {

  public globalModal: GlobalModalComponent;

  public globalDiscard: LeavePageWarningModalComponent;

  constructor() { }

  public confirm(opts: GlobalModalConfirmOptions): Promise<boolean> {
    return this.globalModal.showConfirm(opts);
  }

  public confirmDiscard(): Promise<boolean> {
    // No method params provided here because all discard instances must be consistent.
    // No special handling at any circumstance.
    return this.globalDiscard.show();
  }

  public setInstance(comp: GlobalModalComponent): void {
    this.globalModal = comp;
  }

  public setDiscardInstance(comp: LeavePageWarningModalComponent): void {
    this.globalDiscard = comp;
  }

}
