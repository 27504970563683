import { Pipe, PipeTransform } from '@angular/core';
import { Task } from '../dto/projects/task';
import { isNullOrUndefined } from '../utils/is-null-or-undefined';

export function getTaskAssignee(task: Task, asArray: boolean = false): string | string[] {
  let assignee = '';
  let user = '';

  // Handling the first part of the name
  if (task.assignee.assignmentCategory === 'INTERNAL') {
    if (task.projectOverview && task.projectOverview.assignedPM) {
      assignee = task.projectOverview.assignedPM.organizationName;
    }
  } else if (task.assignee.assignmentCategory === 'VENDOR') {
    if (task.assignee.vendorLiteDTO) {
      // If there is an assigned vendor, use that
      assignee = task.assignee.vendorLiteDTO.vendorName;
    }
  } else if (isNullOrUndefined(task.assignee.vendorLiteDTO)) {
    // If there is no assigned vendor, use the company name
    assignee = task.projectOverview.customerLite.companyName;
  }

  // Handling the second part
  if (!isNullOrUndefined(task.assignee.assignedUser)) {
    user = `${task.assignee.assignedUser.firstName} ${task.assignee.assignedUser.lastName}`;
  }

  // Start return routine
  if (asArray) {
    return [assignee, user];
  }

  if (user !== '') {
    return `${assignee} (${user})`;
  }
  return assignee;
}

export function getTaskAssignedUser(task: Task): string {
  if (!isNullOrUndefined(task.assignee.assignedUser)) {
    return `${task.assignee.assignedUser.firstName} ${task.assignee.assignedUser.lastName}`;
  } else {
    return null;
  }
}

@Pipe({
  name: 'taskAssignee'
})
export class TaskAssigneePipe implements PipeTransform {

  public transform(task: Task, asArray: boolean = false): any {
    return getTaskAssignee(task, asArray);
  }
}
