import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Vendor } from '../shared/dto/vendor-management/vendor';
import { VendorUser } from '../shared/dto/vendor-management/vendor-user';
import { ContactInformation } from '../shared/dto/vendor-management/contact-information';
import { VendorDayClosed } from '../shared/dto/vendor-management/vendor-day-closed';
import { VendorService } from '../shared/dto/vendor-management/vendor-service';
import { Country } from '../shared/dto/system-config/country';
import { VendorLanguagePairSettings } from '../shared/dto/vendor-management/vendor-language-pair-settings';
import { ConfigService } from './config.service';
import { VendorGroup } from '../shared/dto/vendor-management/vendor-group';
import { RequestService } from 'app/shared/services/request-service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GeneralResponseMessage } from '../shared/dto/general-response-message';
import { LoggingService } from '../shared/logging.service';
import { SystemTerm } from '../shared/dto/system-term';
import { ValidationResponseMessage } from '../shared/dto/validation-response-message';
import { VendorBillingInformation } from '../shared/dto/vendor-management/vendor-billing-information';
import { VendorPaginated } from '../shared/dto/vendor-management/vendor-paginated';
import { VendorSearchOptions } from '../shared/dto/vendor-management/vendor-search-options';
import { VendorActivityHistoryRequest } from '../shared/request/vendor-activity-history-request';
import { VendorActivityHistory } from '../shared/dto/vendor-management/vendor-activity-history';
import { CompanyVendorUsage } from '../shared/dto/vendor-management/company-vendor-usage';
import { isNullOrUndefinedOrBlank } from '../shared/utils/is-null-or-undefined-or-blank';
import { OrganizationEmailContacts } from '../shared/dto/messaging/organization-email-contacts';
import { DeliveryEmailRecipient } from '../shared/dto/filing-and-translation-delivery/delivery-email-recipient';
import { VendorAnnuityPreferences } from '../shared/dto/vendor-management/vendor-annuity-preferences';
import { map } from 'rxjs/operators';
import { VendorSystemAccess } from '../shared/dto/vendor-management/vendor-system-access';
import { FileKeyPair } from '../shared/dto/file-key';
import { IpRightType } from '../shared/dto/annuities/ip-right-type';
import { VendorUsageFilter } from '../settings-v2/company-activity/company-vendor-usage/vendor-usage-filter/vendor-usage-filter.component';
import { TmWeights } from '../shared/dto/system-config/tm-weights';
import { VendorLanguagePairWithTm } from '../shared/dto/vendor-management/vendor-language-pair';
import { IprServiceType } from '../shared/dto/annuities/ipr-service-type';
@Injectable()
export class VendorManagementService {

  public vendorObservable: Observable<Vendor>;
  public vendorUser: VendorUser;
  public vendorUserObservable: Observable<VendorUser>;

  private vendor: Vendor;

  // This variable holds the key for storing vendor billing dismissed status
  private billingNoticeKey = 'townip_display_payment_instruction';

  constructor(private httpClient: HttpClient,
              private loggingService: LoggingService,
              private configService: ConfigService,
              private requestService: RequestService) {
  }

  public retrieveVendor(vendorId: number): Promise<Vendor> {
    return new Promise((resolve, reject) => {
      const url = `/api/vendor/${vendorId}`;
      const headers = this.requestService.buildHttpHeaders();
      this.vendorObservable = this.httpClient.get(url, { headers: headers });
      this.vendorObservable.subscribe(v => {
        this.vendor = v;
        resolve(v);
      });
    });
  }

  /**
   * Gets vendor by id.
   *
   * THIS vs retrieveVendor:
   * Use this if you only need to get the vendor without needing to update the service local copy of it.
   * retrieveVendor updates the local copy.
   */
  public getVendorById(id: number): Observable<Vendor> {
    const url = `/api/vendor/${id}`;
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.get<Vendor>(url, { headers: headers });
  }

  public getPreferredVendorsByJurisdictionAndService(jurisdictionId: number,
    serviceTypeId: number): Observable<Vendor[]> {
    const url = `/api/vendor/country/${jurisdictionId}/service/${serviceTypeId}`;
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.get<Vendor[]>(url, { headers: headers });
  }

  public getPreferredVendorsByLanguagePairAndService(languagePairId: number, serviceId: number): Observable<Vendor[]> {
    const url = `/api/vendor/languagePair/${languagePairId}/service/${serviceId}`;
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.get<Vendor[]>(url, { headers: headers });
  }

  public retrieveVendorUser(vendorId: number, vendorUserId: number): Promise<VendorUser> {
    return new Promise((resolve, reject) => {
      const url = `/api/vendor/${vendorId}/user/${vendorUserId}`;
      const headers = this.requestService.buildHttpHeaders();
      this.vendorUserObservable = this.httpClient.get<VendorUser>(url, { headers: headers });
      this.vendorUserObservable.subscribe(vu => {
        this.vendorUser = vu;
        resolve(vu);
      });
    });
  }

  public retrieveVendorAdmins(vendorId: number): Observable<VendorUser[]> {
    const url = `/api/vendor/${vendorId}/admins`;
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.get<VendorUser[]>(url, { headers: headers });
  }

  public getAssignableUsers(vendorId: number, taskId?: number): Observable<VendorUser[]> {
    const url = `/api/vendor/${vendorId}/assignable-users`;
    const headers = this.requestService.buildHttpHeaders();
    const params = taskId ? { taskAssignmentId: `${taskId}` } : {};

    return this.httpClient.get<VendorUser[]>(url, { headers, params });
  }

  public makeUserAdmin(vendorId: number, vendorUserId: number): Observable<VendorUser> {
    const url = `/api/vendor/${vendorId}/userId/${vendorUserId}/makeAdmin`;
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.put<VendorUser>(url, { headers: headers });
  }

  public makeVendorAdmins(vendorId: number, vendorUserIds: number[]): Observable<VendorUser[]> {
    const url = `/api/vendor/${vendorId}/makeAdmins`;
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.put<VendorUser[]>(url, vendorUserIds, { headers: headers });
  }

  public revokeUserAdmin(vendorId: number, vendorUserId: number): Observable<VendorUser> {
    const url = `/api/vendor/${vendorId}/userId/${vendorUserId}/revokeAdmin`;
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.put<VendorUser>(url, { headers: headers });
  }

  public getVendor(): Vendor {
    return this.vendor;
  }

  public setVendor(vendor: Vendor): void {
    this.vendor = vendor;
  }

  public getVendorUser(): VendorUser {
    return this.vendorUser;
  }

  public setVendorUser(vendorUser: VendorUser): void {
    this.vendorUser = vendorUser;
  }

  public createVendor(vendor: Vendor): Observable<Vendor> {
    const url = 'api/vendor';
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.post<Vendor>(url, vendor, { headers: headers });
  }

  public addVendorUser(vendorId: number, vendorUser: VendorUser): Promise<VendorUser> {
    const url = `api/vendor/${vendorId}/user`;
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.post<VendorUser>(url, vendorUser, { headers: headers })
      .toPromise();
  }

  public addVendorUserToGroups(vendorId: number, userId: number, groupIds: number[]): Promise<VendorUser> {
    const url = `/api/vendor/${vendorId}/user/${userId}/groups`;
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.httpClient.post<VendorUser>(url, groupIds, { headers: headers })
      .toPromise();
  }

  public addExistingVendorUsersToGroup(vendorId: number,
    teamId: number,
    vendorUserIds: number[]): Promise<VendorUser[]> {
    const url = `api/vendor/${vendorId}/group/${teamId}/users`;
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.put<VendorUser[]>(url, vendorUserIds, { headers: headers })
      .toPromise();
  }

  public removeVendorUserFromGroup(vendorId: number,
    teamId: number,
    vendorUserId: number): Promise<GeneralResponseMessage> {
    const url = `api/vendor/${vendorId}/group/${teamId}/user/${vendorUserId}`;
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.delete<GeneralResponseMessage>(url, { headers: headers })
      .toPromise();
  }

  public addVendorTeam(vendorId: number, vendorTeam: VendorGroup): Promise<VendorGroup> {
    const url = `api/vendor/${vendorId}/group`;
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.post<VendorGroup>(url, vendorTeam, { headers: headers })
      .toPromise();
  }

  public updateVendorTeam(vendorId: number, vendorTeam: VendorGroup): Promise<VendorGroup> {
    const url = `api/vendor/${vendorId}/group/${vendorTeam.id}`;
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.put<VendorGroup>(url, vendorTeam, { headers: headers })
      .toPromise();
  }

  public deleteVendorTeam(vendorId: number, groupId: number): Promise<Vendor> {
    const url = `api/vendor/${vendorId}/group/${groupId}`;
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.delete<Vendor>(url, { headers: headers })
      .toPromise();
  }

  public updateVendorContact(vendorId: number, vendorContact: ContactInformation[]): Promise<ContactInformation[]> {
    const url = `api/vendor/${vendorId}/contacts`;
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.put<ContactInformation[]>(url, vendorContact, { headers: headers })
      .toPromise();
  }

  public addDaysOff(vendorId: number, daysOff: VendorDayClosed[]): Promise<VendorDayClosed[]> {
    const url = `api/vendor/${vendorId}/daysClosed`;
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.put<VendorDayClosed[]>(url, daysOff, { headers: headers })
      .toPromise();
  }

  public updateVendorWorkingDays(vendorId: number, workingDays: string): Promise<GeneralResponseMessage> {
    const url = `api/vendor/${vendorId}/workingDays`;
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.put<GeneralResponseMessage>(url, workingDays, { headers: headers })
      .toPromise();
  }

  public updateVendorServices(vendorId: number, vendorServices: VendorService[]): Promise<VendorService[]> {
    const url = `api/vendor/${vendorId}/service`;
    this.loggingService.info(url, vendorServices);
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.put<VendorService[]>(url, vendorServices, { headers: headers })
      .toPromise();
  }

  public updateVendor(vendorId: number, vendor: Vendor): Promise<VendorUser> {
    const url = `api/vendor/${vendorId}`;
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.put<Vendor>(url, vendor, { headers: headers })
      .toPromise();
  }

  public updateVendorUser(vendorId: number, vendorUserId: number, vendorUser: VendorUser): Promise<VendorUser> {
    const url = `api/vendor/${vendorId}/user/${vendorUserId}`;
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.put<VendorUser>(url, vendorUser, { headers: headers })
      .toPromise();
  }

  public updateVendorUserProbation(vendorUserId: number, isOnProbation: boolean): Promise<VendorUser> {
    const url = `api/vendor/userId/${vendorUserId}/probation/${isOnProbation}`;
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.put<VendorUser>(url, null, { headers: headers })
      .toPromise();
  }

  public clear(): void {
    this.vendorObservable = null;
    this.vendor = null;
    this.vendorUser = null;
    this.vendorUserObservable = null;
  }

  public acceptTermsOfService(termType: string, vendorId: number): Observable<SystemTerm> {
    const url = `api/term/${termType}/accept/vendor/${vendorId}`;
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.httpClient.post<SystemTerm>(url, null, { headers: headers });
  }

  public getTermsOfServiceStatus(vendorId: number): Observable<SystemTerm> {
    const url = `api/term/vendor/${vendorId}`;
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.httpClient.get<SystemTerm>(url, { headers: headers });

    // Caching mechanism. Needs more work.
    // if (isNullOrUndefined(this.termsOfServiceStatus)) {
    //   const url = `api/term/vendor/${vendorId}`;
    //   const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    //   return this.http.get<SystemTerm>(url,{headers: headers});
    // }

    // return new Observable<SystemTerm>((observer) => {
    //   observer.next(this.termsOfServiceStatus);
    //   observer.complete();
    // })
  }

  public validateUpdateVendorStatus(vendorId: number, vendorStatus: string): Promise<ValidationResponseMessage> {
    const url = `/api/vendor/${vendorId}/validate/status/${vendorStatus}`;
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.httpClient.get<ValidationResponseMessage>(url, { headers: headers })
      .toPromise();
  }

  public updateVendorStatus(vendorId: number, vendorStatus: string): Promise<Vendor> {
    const url = `/api/vendor/${vendorId}/status/${vendorStatus}`;
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.httpClient.put<Vendor>(url, null, { headers: headers })
      .toPromise();
  }

  public deactivateVendorUser(vendorId: number, vendorUserId: number): Promise<VendorUser> {
    const url = `/api/vendor/${vendorId}/user/activation/${vendorUserId}`;
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.httpClient.delete<VendorUser>(url, { headers: headers })
      .toPromise();
  }

  public validateDeactivateVendorUser(vendorId: number, vendorUserId: number): Promise<ValidationResponseMessage> {
    const url = `/api/vendor/${vendorId}/validate/user/activation/${vendorUserId}`;
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.httpClient.delete<ValidationResponseMessage>(url, { headers: headers })
      .toPromise();
  }

  public activateVendorUser(vendorId: number, vendorUserId: number): Promise<VendorUser> {
    const url = `/api/vendor/${vendorId}/user/activation/${vendorUserId}`;
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.httpClient.put<VendorUser>(url, null, { headers: headers })
      .toPromise();
  }

  public validateDeactivateVendorsJurisdictionService(jurisdictionServiceId: number):
    Promise<ValidationResponseMessage> {
    const url = `/api/vendor/jurisdiction/service/${jurisdictionServiceId}/deactivate/validation`;
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.get<ValidationResponseMessage>(url, { headers: headers })
      .toPromise();
  }

  public validateDeactivateVendorsLanguagePairService(languagePairServiceId: number):
    Promise<ValidationResponseMessage> {
    const url = `/api/vendor/language/pair/service/${languagePairServiceId}/deactivate/validation`;
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.get<ValidationResponseMessage>(url, { headers: headers })
      .toPromise();
  }

  public getVendorWithDefaultCountryFilingSystemService(countryId: number, patentType: string): Promise<Vendor> {
    const url = `/api/vendor/default/filing/country/${countryId}/patent/${patentType}`;
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.get<Vendor>(url, { headers: headers })
      .toPromise();
  }

  public getVendorWithDefaultLanguagePairSystemService(languagePairId: number, serviceId: number):
    Promise<VendorLanguagePairSettings> {
    const url = `/api/vendor/default/language/pair/${languagePairId}/service/${serviceId}`;
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.get<VendorLanguagePairSettings>(url, { headers: headers })
      .toPromise();
  }

  public getVendorWithDefaultSystemService(serviceId: number): Promise<VendorService> {
    const url = `/api/vendor/default/service/${serviceId}`;
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.get<VendorService>(url, { headers: headers })
      .toPromise();
  }

  public putVendorBillingInfo(vendorId: number, vendorBillingInfo: VendorBillingInformation): Promise<VendorUser> {
    const url = `/api/vendor/${vendorId}/vendor-billing-information`;
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.put<VendorUser>(url, vendorBillingInfo, { headers: headers })
      .toPromise();
  }

  public searchVendors(opts: VendorSearchOptions): Observable<VendorPaginated> {
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.post<VendorPaginated>('/api/vendor/search', opts, { headers });
  }

  public saveVendorNote(id: number, note: string): Observable<Vendor> {
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.put<Vendor>(`/api/vendor/${id}/note`, note, { headers });
  }

  public saveVendorMinimumFee(id: number, fee: number): Observable<Vendor> {
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.put<Vendor>(`/api/vendor/${id}/minimum-fee`, fee, { headers });
  }

  public getActivityHistory(request: VendorActivityHistoryRequest): Observable<VendorActivityHistory> {
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.post<VendorActivityHistory>('/api/activity/history', request, { headers });
  }

  public getActivityHistoryBulk(requests: VendorActivityHistoryRequest[]): Observable<VendorActivityHistory[]> {
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.post<VendorActivityHistory[]>('/api/activity/history/bulk', requests, { headers });
  }

  public getTermsOfServiceHistory(id: number): Observable<SystemTerm[]> {
    const url = `api/term/vendor/${id}/history`;
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.httpClient.get<SystemTerm[]>(url, { headers: headers });
  }

  public getVendorUsageForCompany(companyId: number, params: VendorUsageFilter): Observable<CompanyVendorUsage[]> {
    const headers = this.requestService.buildHttpHeaders();

    const body = {
      serviceIds: params.services.map(service => service.id),
      countryIds: params.countries.map(country => country.id),
      languagePairIds: params.languagePairs.map(languagePair => languagePair.id),
    };

    const url = `/api/vendor/usage/company/${companyId}/service`;
    return this.httpClient.post<CompanyVendorUsage[]>(url, body, { headers });
  }

  public showBillingNotice(): boolean {
    // Checks if there are any stored data that the Vendor's Billing Alert has been dismissed/closed.
    return isNullOrUndefinedOrBlank(window.localStorage.getItem(this.billingNoticeKey));
  }

  public dismissBillingNotice(): void {
    // Declare and store value whenever Vendor's Billing Alert has been dismissed/close.
    window.localStorage.setItem(this.billingNoticeKey, 'false');
  }

  public getVendorContacts(vendorId: number): Observable<DeliveryEmailRecipient[]> {
    const url = `/api/vendor/${vendorId}/vendor-contacts`;

    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.get<OrganizationEmailContacts[]>(url, { headers });
  }

  public getDefaultVendorContacts(vendorIds: number[], orderId?: number): Observable<OrganizationEmailContacts[]> {
    let url = '/api/vendor-contacts?vendorIds=' + vendorIds.toString();

    if (orderId) {
      url += `&orderId=${orderId}`;
    }

    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.get<OrganizationEmailContacts[]>(url, { headers });
  }

  public getVendorAnnuityPreferences(vendorId: number): Observable<VendorAnnuityPreferences> {
    const url = `/api/vendor/${vendorId}/annuity/preferences`;
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.get<VendorAnnuityPreferences>(url, { headers });
  }

  public updateVendorAnnuityPreferences(vendorId: number, annuityPreferences: VendorAnnuityPreferences): Observable<VendorAnnuityPreferences> {
    const url = `/api/vendor/${vendorId}/annuity/preferences`;
    const headers = this.requestService.buildHttpHeaders();
    return this.httpClient.post<VendorAnnuityPreferences>(url, annuityPreferences, { headers });
  }

  public getVendorsByAnnuityCountryIpRightTypeAndServiceType(
    vendorJurisdiction: Country,
    ipRightType: IpRightType,
    iprServiceType?: IprServiceType,
  ): Observable<Vendor[]> {
    const vendorSearchOptions: VendorSearchOptions = {
      page: 0,
      paginationElementSize: 99999,
      annuityVendorSearch: {
        vendorJurisdiction,
        ipRightType,
        iprServiceType
      },
    };

    return this.searchVendors(vendorSearchOptions)
      .pipe(
        map(result => {
          const content = result.content;
          if (!Array.isArray(content)) {
            return [];
          }
          return content.map(paginatedContent => paginatedContent.vendor);
        })
      );
  }

  public getVendorsByAnnuityCountry(vendorJurisdiction: Country): Observable<Vendor[]> {
    const vendorSearchOptions: VendorSearchOptions = {
      page: 0,
      paginationElementSize: 99999,
      annuityVendorSearch: {
        vendorJurisdiction,
      },
    };

    return this.searchVendors(vendorSearchOptions)
      .pipe(
        map(result => {
          const content = result.content;
          if (!Array.isArray(content)) {
            return [];
          }
          return content.map(paginatedContent => paginatedContent.vendor);
        })
      );
  }

  public getUserSystemAccess(vendorId: number): Observable<VendorSystemAccess> {
    const url = `api/vendor/${vendorId}/users/system-access`;
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.httpClient.get<VendorSystemAccess>(url, { headers: headers });
  }

  public updateUsersSystemAccess(companyId: number, vendorAccess: VendorSystemAccess): Observable<VendorSystemAccess> {
    const url = `api/vendor/${companyId}/users/system-access`;
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();

    // Remove vendor admin since we can't modify their permissions
    // Including them in the request, the backend will return an error
    vendorAccess.vendorUsers = vendorAccess
      .vendorUsers
      .filter(user => !user.admin);

    return this.httpClient.put<VendorSystemAccess>(url, vendorAccess, { headers: headers });
  }

  public getFiles(vendorId: number): Observable<FileKeyPair[]> {
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();

    return this.httpClient
      .get<FileKeyPair[]>(`api/vendor/${vendorId}/files`, { headers });
  }

  public addFiles(vendorId: number, files: FileKeyPair[]): Observable<FileKeyPair[]> {
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();

    return this.httpClient
      .post<FileKeyPair[]>(`api/vendor/${vendorId}/files/add`, files, { headers });
  }

  public deleteFile(vendorId: number, fileKey: string): Observable<FileKeyPair> {
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();

    return this.httpClient
      .delete<FileKeyPair>(`api/vendor/${vendorId}/files`, {
        headers,
        params: { fileKey },
      });
  }

  public getVendorTmWeights(vendorId: number): Observable<TmWeights> {
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.httpClient.get<TmWeights>(`api/vendor/${vendorId}/tm-weights`, { headers });
  }

  public updateVendorTmWeights(vendorId: number, tmWeights: TmWeights): Observable<TmWeights> {
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.httpClient.put<TmWeights>(`api/vendor/${vendorId}/tm-weights`, tmWeights, { headers });
  }

  public getVendorLanguagePairTmWeights(vendorId: number): Observable<VendorLanguagePairWithTm[]> {
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.httpClient
      .get<VendorLanguagePairWithTm[]>(`api/vendor/${vendorId}/vendor-language-services/tm-weights`, { headers });
  }

  public updateVendorLanguagePairTmWeights(
    data: { vendorId: number, languagePairId: number, weights: TmWeights }
  ): Observable<VendorLanguagePairWithTm> {
    const headers: HttpHeaders = this.requestService.buildHttpHeaders();
    return this.httpClient
      .put<VendorLanguagePairWithTm>(
        `api/vendor/${data.vendorId}/vendor-language-service/${data.languagePairId}/tm-weights`,
        data.weights,
        { headers }
      );
  }

}
