<ng-container *ngIf="form" [formGroup]="form">
  <p-calendar
    class="bs-input {{class}}"
    styleClass="bs-input"
    formControlName="value"
    [appendTo]="appendTo"
    [disabled]="disabled"
    [showIcon]="showIcon"
    [showTime]="showTime"
    [maxDate]="maxDate"
    [minDate]="minDate"
    [readonlyInput]="readonlyInput"
    [defaultDate]="defaultDate"
    [dateFormat]="dateFormat"
    [placeholder]="placeholder"
    [monthNavigator]="monthNavigator"
    [yearNavigator]="yearNavigator"
    [yearRange]="yearRange"
    [selectionMode]="selectionMode"
    [inputStyleClass]="inputStyleClass"
    [selectOtherMonths]="true"
    [inline]="inline"
    [showButtonBar]="showButtonBar"
    (onBlur)="onTouched ? onTouched(true) : null">
  </p-calendar>
</ng-container>
