import { Injectable } from '@angular/core';
import { UserService } from '../services/user.service';
import { CustomerManagementService } from '../../data-services/customer-management.service';
import { VendorManagementService } from '../../data-services/vendor-management.service';
import { ConfigService } from '../../data-services/config.service';
import { Router } from '@angular/router';
import { LoggingService } from '../logging.service';
import { AuthService } from '../../security/auth.service';
import { Subscription } from 'rxjs';
import { isNullOrUndefined } from '../utils/is-null-or-undefined';

@Injectable()
export class SessionGuardService  {

  constructor(private authService: AuthService,
              private userService: UserService,
              private loggingService: LoggingService,
              private vendorService: VendorManagementService,
              private customerManagementService: CustomerManagementService,
              private configService: ConfigService,
              private _router: Router) {
  }

  /**
   * Temporary fix so that a page refresh does not break the whole system
   * @returns {Promise<T>}
   */
  public canActivate(): Promise<boolean> {
    // NOTE: Use resolve to false if invalid session instead of reject.
    // Using reject will result in a 404 redirect (because reject is considered uncaught exception in a promise guard)
    return new Promise((resolve, reject) => {
      // Check if session is active
      if (this.authService.isConnected()) {
        this.fetchData()
          .then(() => resolve(true))
          .catch(() => resolve(false));
        return;
      }

      let connectionWait = true;
      // We're not connected yet, wait for the event.
      const connectionSub: Subscription = this.authService.connectedEmitter.subscribe((status) => {
        connectionWait = false;
        connectionSub.unsubscribe();

        if (!status) {
          console.warn('Session Error');
          resolve(false);
          return;
        }
        this.fetchData()
          .then(() => resolve(true))
          .catch(() => resolve(false));
      });

      // Set a timeout to wait for the session to be setup
      setTimeout(() => {
        if (!connectionWait) {
          connectionSub.unsubscribe();
          // We previously connected successfully, no need to do anything
          return;
        }

        // Resolve with false after 10 seconds
        console.warn('Session wait timeout: 10 seconds');
        resolve(false);
      }, 10000);
    });
  }

  private fetchData(): Promise<void> {
    return new Promise((resolve, reject) => {
      const user = this.userService.getUser();
      if (!user) {
        reject('Session was there, but user fetch failed.');
        return;
      }

      // Making sure we are also loading the required data together with the user
      const dataFetch: Promise<any>[] = [];
      if (user.userType === 'COMPANY') {
        // Retrieve only when company is not set or undefined
        if (isNullOrUndefined(this.customerManagementService.getCompany())) {
          dataFetch.push(this.customerManagementService.retrieveCompany(user.organizationId));
        }
        if (isNullOrUndefined(this.customerManagementService.getCompanyUser())) {
          dataFetch.push(this.customerManagementService.retrieveCompanyUser(user.organizationId, user.userId));
        }
      } else if (user.userType === 'VENDOR') {
        // Retrieve only when vendor is not set or undefined
        if (isNullOrUndefined(this.vendorService.getVendor())) {
          dataFetch.push(this.vendorService.retrieveVendor(user.organizationId));
        }
        if (isNullOrUndefined(this.vendorService.getVendorUser())) {
          dataFetch.push(this.vendorService.retrieveVendorUser(user.organizationId, user.userId));
        }
      }

      // Making sure the configurations are loaded before loading the path
      // TODO: Probably move this to a different guard service and add the other config service
      dataFetch.push(this.configService.countriesPromise);
      dataFetch.push(this.configService.languagesPromise);
      dataFetch.push(this.configService.languagePairsPromise);
      dataFetch.push(this.configService.timeZonesPromise);

      // When all the data finishes loading async
      return Promise.all(dataFetch)
        .then(() => {
          // NOTE:
          // A refactoring is needed in the main App.Component as well to not fetch the data AGAIN
          // when the vendor and company has already been fetched for the user
          // this.userService.userEmitter.emit(user);
          this.loggingService.info('All user/config data is present for the session');
          resolve();
        })
        .catch(() => {
          reject();
          this.loggingService.error('Failed to load session instance (company and vendors)');
          // Navigate to the error page to give chance for recovery
          this._router.navigate(['error-404']);
        });
    });
  }
}

