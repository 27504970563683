import { Injectable } from '@angular/core';
import { DataStore } from '../../data-store';
import { ConfigService } from '../../config.service';
import { PortalUser } from '../../../shared/domain/user';
import { UserService } from '../../../shared/services/user.service';
import { BehaviorSubject } from 'rxjs';
import { SystemConfigResponseDTO } from '../../../shared/dto/system-config/system-config-response-dto';

@Injectable()
export class ConfigDataStoreService<T> implements DataStore<T> {
  protected dataSetPromise: Promise<T[]>;
  protected user: PortalUser;
  protected dataSet: BehaviorSubject<T[]>;

  constructor(protected configService: ConfigService,
              protected userService: UserService,
              protected type: string) {
    this.dataSet = new BehaviorSubject([]);
    this.init();
  }

  public init(): void {
    this.dataSetPromise = this.configService.retrieveSystemConfig(this.type);
    this.dataSetPromise.then(dataSet => this.dataSet.next(dataSet));
    this.user = this.userService.user;
  }

  public getDataSet(): BehaviorSubject<T[]> {
    return this.dataSet;
  }

  public create(data: T): Promise<SystemConfigResponseDTO> {
    this.user = this.userService.user;
    return this.configService.addSystemConfig({ userId: this.user.id, type: this.type, valueDTO: data });
  }

  public update(data: T): Promise<SystemConfigResponseDTO> {
    this.user = this.userService.user;
    return this.configService.updateSystemConfig({ userId: this.user.id, type: this.type, valueDTO: data });
  }

  public enable(data: T): Promise<SystemConfigResponseDTO> {
    this.user = this.userService.user;
    return this.configService.enableSystemConfig({ userId: this.user.id, type: this.type, valueDTO: data });
  }

  public disable(data: T): Promise<SystemConfigResponseDTO> {
    this.user = this.userService.user;
    return this.configService.disableSystemConfig({ userId: this.user.id, type: this.type, valueDTO: data });
  }

  public get(id: number): Promise<T> {
    this.user = this.userService.user;
    return this.dataSetPromise.then(dataset => {
      return Promise.resolve(dataset.find((value: T | any) => value.id === id));
    });
  }

  public remove(data: T | any): Promise<SystemConfigResponseDTO> {
    this.user = this.userService.user;
    return this.configService.disableSystemConfig(data);
  }

}
