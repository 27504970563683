<div class="d-flex height-inherit">
  <div
    class="message-navigation height-inherit flex-shrink-0"
    *ngIf="!hideMessageList"
    style="width: 382px">
    <townip-message-list
      class="d-flex flex-column message-list height-inherit"
      [activeMessage]="selectedMessage?.id"
      [countrySelectItems]="countrySelectItems"
      [showProjectScope]="showProjectScope"
      [messages]="messages"
      (onSelect)="navigateToMessage($event)"
      (onNavigateScope)="navigate($event)"
      (onCreate)="showNewMessage()">
    </townip-message-list>
  </div>

  <div
    class="message-select ml-2 flex-grow"
    *ngIf="(!showNewMessageInd && !sendCommunication) && !hideMessageList && !selectedMessage">
    <div class="blank-header"></div>
    <div class="blank-thread"></div>
  </div>
  <div
    class="pl-2 height-inherit flex-grow w-100"
    *ngIf="(!showNewMessageInd && !sendCommunication) && !hideMessageList && selectedMessage">
    <div class="message-thread d-flex flex-column height-inherit">
      <p-scrollPanel
          #threadScrollPanel
          styleClass="thread-panel"
          class="height-inherit d-block"
          [style]="{width: '100%', height: '100%', 'border': 'none', 'background-color': '#f8fbfb'}">
        <div class="thread-subject font-weight-normal py-2 pr-4">{{selectedMessage.messageSubject}}</div>
        <div townipThreadContainer id="right-panel" class="message-entries">
          <!-- Reply All Form -->
          <div *ngIf="showReplyAll" class="message-thread reply-all">
            <div *ngIf="excludedRecipients?.length" class="alert alert-danger d-inline-flex m-auto w-100">
              <i class="fa fa-exclamation-triangle my-auto mr-3" aria-hidden="true"></i>
              <p style="font-size:14px">The following e-mails will <strong>NOT</strong> receive this message:
                <ng-container *ngFor="let recipient of excludedRecipients; let last = last">
                  {{recipient}}<span *ngIf="!last">, </span>
                </ng-container>
              </p>
            </div>

            <div class="reply-all-details pb-2">
              <div class="d-flex reply-recipients-container align-items-center w-100">
                <div class="d-flex w-75 reply-recipients font-weight-normal">
                  <div class="message-form-label align-self-center text-muted padding-left-15">To:</div>
                  <div class="align-self-stretch">
                    <ng-container *ngFor="let user of selectedMessageRecipients; let last = last">
                    <span [tooltip]="user.value.organization" placement="bottom">
                      {{user.value.firstName}} {{user.value.lastName}}<span *ngIf="!last">, </span>
                    </span>
                    </ng-container>
                  </div>
                </div>
                <div class="w-25">
                  <button
                    (click)="navigate(selectedMessage)"
                    class="btn btn-reply-scope {{selectedMessage.scope}} float-right"
                    *ngIf="selectedMessage.scope && (showProjectScope || (!showProjectScope && selectedMessage.scope !== 'PROJECT'))">
                    {{selectedMessage.scope}} {{selectedMessage.scopeName}}
                  </button>
                </div>
              </div>
              <div class="message-new reply-all">
                <div class="d-flex margin-bottom-5 padding-left-15 header-subject-container font-weight-normal w-75">
                  <div class="message-form-label align-self-center">RE:</div>
                  <div class="align-self-stretch flex-grow font-weight-normal">{{replySubject}}</div>
                </div>
                <div class="d-flex flex-wrap" *ngIf="newMessageAttachments">
                  <townip-message-attachment
                    class="d-block pr-1 mt-1"
                    *ngFor="let attachment of newMessageAttachments"
                    [file]="attachment"
                    (removeFile)="removeAttachment($event)">
                  </townip-message-attachment>
                </div>
              </div>
            </div>
            <!-- Message Form -->
            <div class="message-form full-height">
              <townip-message-form
                [user]="user"
                [popup]="popup"
                [showMessageSubject]="false"
                [messageSubject]="newMessageSubject"
                (attachments)="onAddAttachments($event)"
                (onSendMessage)="replyToThread(selectedMessage, $event.messageBody)"
                [contacts]="selectedMessageRecipients"
                (onCancel)="showReplyAll = false">
              </townip-message-form>
            </div>
          </div>
          <townip-message-thread
            *ngIf="!showNewMessageInd && !!selectedMessage"
            [threadMessageId]="selectedMessage.id"
            [externalRecipients]="initialExternalRecipients"
            [showProjectScope]="showProjectScope"
            [contacts]="contacts"
            [hideFooter]="true"
            [allowCreate]="false"
            [threadType]="threadType"
            (triggerReplyAll)="onReplyAll(); threadScrollPanel.scrollTop(0)"
            (navigateToScope)="navigate(selectedMessage)"
            (onFileAssociated)="onFileAssociated.emit()">
          </townip-message-thread>
        </div>
      </p-scrollPanel>
    </div>
  </div>
  <div
    class="message-new height-inherit pl-2 flex-grow overflow-auto"
    *ngIf="hasRecipients && (showNewMessageInd || sendCommunication); else noRecipeints">
    <div class="d-flex flex-column new-communication">
      <townip-messages-header class="recipient-container">
        <div class="d-flex margin-bottom-15 align-items-center position-relative">
          <ng-template #findRecipients>
            <townip-message-find-recipient
              [userListHeight]="findRecipientHeight"
              [users]="contacts"
              [(ngModel)]="selectedContacts">
            </townip-message-find-recipient>
          </ng-template>

          <div
            #popoverFindRecipient="bs-popover"
            [popover]="findRecipients"
            [outsideClick]="true"
            triggers=""
            placement="bottom left"
            containerClass="find-recipient"
            class="d-flex header-recipients-container w-75">
            <div class="message-form-label align-self-center">
              To:
              <i class="fa fa-refresh fa-spin fa-fw" *ngIf="isLoadingContacts"></i>
            </div>
            <div class="w-100">
              <townip-user-chips
                class="align-content-center"
                [users]="contacts"
                [disableAdding]="disableContacts"
                [(selected)]="selectedContacts">
              </townip-user-chips>
            </div>
          </div>
          <div class="margin-left-10" *ngIf="contacts?.length > 0">
            <button type="button"
                    class="btn btn-link btn-find-recipient"
                    (click)="showFindRecipientPanel(); popoverFindRecipient.toggle()">
              Find Recipients
            </button>
          </div>
        </div>
        <div class="d-flex margin-bottom-5 header-subject-container w-75">
          <div class="message-form-label align-self-center">
            Subject:
          </div>
          <div class="align-self-stretch flex-grow">
            <input type="text"
                   class="message-subject form-control form-control-sm full-width no-border"
                   maxlength="250"
                   [(ngModel)]="newMessageSubject"/>
          </div>
        </div>
        <div class="d-flex flex-wrap" *ngIf="newMessageAttachments">
          <townip-message-attachment
            class="d-block pr-1 mt-1"
            *ngFor="let attachment of newMessageAttachments"
            [file]="attachment"
            (removeFile)="removeAttachment($event)">
          </townip-message-attachment>
        </div>
        <button
          [disabled]="true"
          class="btn btn-reply-scope {{scope}} mt-2"
          *ngIf="scope && (showProjectScope || (!showProjectScope && scope !== 'PROJECT'))">
          {{scope + ' ' + scopeName}}
        </button>
      </townip-messages-header>
      <!-- Message Form -->
      <div class="message-form">
        <townip-message-form
          class="new-message-form"
          [user]="user"
          [popup]="popup"
          [showMessageSubject]="false"
          [messageSubject]="newMessageSubject"
          (onSendMessage)="sendNewMessage($event)"
          (attachments)="onAddAttachments($event)"
          [contacts]="selectedContacts"
          (onCancel)="cancelNewMessage()">
        </townip-message-form>
      </div>
    </div>
  </div>
  <ng-template #noRecipeints>
    <div *ngIf="(showNewMessageInd || sendCommunication) && !isLoadingContacts"
         class="alert alert-warning text-center flex-grow" style="height: fit-content">You can not send the message to
      anyone.
    </div>
    <div *ngIf="isLoadingContacts && (showNewMessageInd || sendCommunication)" class="text-center flex-grow">
      <div class="margin-top-30 margin-bottom-10">Loading Recipients</div>
      <i class="fa fa-circle-o-notch fa-2x fa-spin fa-fw m-0 p-0"></i>
    </div>
  </ng-template>
</div>
