import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { isNullOrUndefined } from '../../utils/is-null-or-undefined';

@Component({
  selector: 'townip-trimmed-text',
  templateUrl: './trimmed-text.component.html',
  styleUrls: ['./trimmed-text.component.scss']
})
export class TrimmedTextComponent implements OnInit, OnChanges {

  @Input()
  public fullText: string;

  @Input()
  public expandable: boolean;

  @Input()
  public expandLabel: string;

  @Input()
  public collapseLabel: string;

  @Input()
  public tooltipExpand: boolean;

  @Input()
  public characters: number;

  @Input()
  public lines: number;

  @Input()
  public isToast = false;

  @Input()
  public useTooltip: boolean;

  @Input()
  public placement = 'top';

  @Input()
  public maxHeight = 100;

  @Output()
  public actionEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  public summary: string;

  public isTrimmed: boolean;

  public isExpanded: boolean;

  constructor() {
    this.summary = '';
    this.fullText = '';
    this.expandable = true;
    this.expandLabel = 'Show more';
    this.collapseLabel = 'Less';
    this.tooltipExpand = true;
    this.lines = 5;
    this.characters = 50;
    this.isTrimmed = false;
    this.isExpanded = false;
  }

  get customStyle(): any {
    if (this.isExpanded) {
      return null;
    }

    return { 'max-height.px': this.maxHeight.toString() };
  }

  public ngOnInit(): void {

  }

  public ngOnChanges(): void {
    if (isNullOrUndefined(this.fullText)) {
      this.summary = null;
      return;
    }

    let pCount = 0;
    pCount = this.fullText.split('<p>').length;

    if (this.fullText.length <= this.characters && pCount < this.lines) {
      this.summary = this.fullText;
    } else {
      const trimmed = this.fullText.substr(0, this.characters - 1);
      this.summary = trimmed;
      this.isTrimmed = true;
      this.summary = this.summary + '...';
    }
  }

  public expand(): void {
    this.actionEmitter.emit(true);

    if (!this.isToast) {
      this.isExpanded = true;
    }
  }

  public collapse(): void {
    this.isExpanded = false;
  }
}
