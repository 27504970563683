import { Component, OnInit } from '@angular/core';
import { GlobalLoaderService } from '../../../shared/services/global-loader.service';
import { GuardsCheckEnd, GuardsCheckStart, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterEvent } from '@angular/router';
import { LoadingService } from '../../../shared/components/loading/loading.service';

@Component({
  selector: 'townip-global-loader',
  templateUrl: './global-loader.component.html',
  styleUrls: ['./global-loader.component.scss']
})
export class GlobalLoaderComponent implements OnInit {

  public showLoader = false;

  constructor(private globalLoaderService: GlobalLoaderService,
              private loadingService: LoadingService,
              private router: Router) {

  }

  public ngOnInit(): void {
    this.globalLoaderService
      .loaderStatus
      .subscribe(loadCount => {
        this.toggleLoader(loadCount);
      });

    this.setupRouteListener();
  }

  public get bigLoaderActive(): boolean {
    // console.log(this.globalLoaderService.loaderStatus.value);
    return this.loadingService.loadingEmitter.value !== null;
  }

  private toggleLoader(current: number): void {
    this.showLoader = current > 0;
  }

  /**
   * Sets up a route listener so that when async routes are loaded,
   * the loader also comes up until the async route has finished loading.
   */
  private setupRouteListener(): void {
    this.router
      .events
      .subscribe((ev) => {
        // This triggers the loader for lazy loaded chunk and helps with big chunks as they load quite a while
        if (ev instanceof NavigationStart) {
          this.globalLoaderService.triggerAuto();
        }

        // We need to complete when the guards start verification
        // This will make sure the loader does not COVER the canDeactivate guards that render modals.
        // The loader will still show up for guards that require API calls since those will trigger their own loader.
        if (ev instanceof GuardsCheckStart) {
          this.globalLoaderService.completeAuto();
        }

        // Then trigger it again after to resume any module loading
        if (ev instanceof GuardsCheckEnd) {
          this.globalLoaderService.triggerAuto();
        }

        if (ev instanceof NavigationEnd) {
          this.globalLoaderService.completeAuto();
        }

        // Making sure errors are handled too.
        if (ev instanceof NavigationError) {
          this.globalLoaderService.completeAuto();
        }

        // When cancelled, also handle.
        if (ev instanceof NavigationCancel) {
          this.globalLoaderService.completeAuto();
        }
      });
  }

}
