import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { isNullOrUndefined } from '../utils/is-null-or-undefined';

// Did not include the Daylight here, since the label for that is unknown
const timezones = [
  { label: 'Eastern', value: -5 },
  { label: 'Central', value: -6 },
  { label: 'Mountain', value: -7 },
  { label: 'Pacific', value: -8 }
];

@Pipe({
  name: 'timezone'
})
export class TimezonePipe implements PipeTransform {

  constructor(private datePipe: DatePipe) {

  }

  public transform(value: any, timezone: any): any {
    const tmzn = timezones.find(tz => tz.label === timezone);
    if (isNullOrUndefined(tmzn)) {
      return '';
    }

    const offset: number = tmzn.value;

    const clientDate = new Date();
    const utc = clientDate.getTime() + (clientDate.getTimezoneOffset() * 60000);
    const serverDate = new Date(utc + (3600000 * offset));

    return this.datePipe.transform(serverDate, 'shortTime');
  }

}
