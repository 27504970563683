import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ProjectCategory } from '../../../dto/system-config/project-category';
import { FileKeyPair } from '../../../dto/file-key';
import { FileService } from '../../../../data-services/file.service';
import { OrderService } from '../../../../data-services/order.service';
import { PROJECT_CATEGORY_VALUE } from '../../../dto/system-config/project-category-value';
import { Message } from '../../../dto/messaging/message';
import { FilesAssociateTasksComponent, FilesAssociateTasksFilters } from '../../files-associate-tasks/files-associate-tasks.component';
import { ConfigService } from '../../../../data-services/config.service';
import { SelectItem } from 'primeng/api';
import { FileKeySupplementalData } from '../../../dto/file-key-supplemental-data';
import { MessageService } from 'primeng/api';
import { ProjectService } from '../../../../data-services/project.service';
import { ModalComponent } from '../../../../controls/modal/modal.component';

@Component({
  selector: 'townip-message-associate-file',
  templateUrl: './message-associate-file.component.html',
  styleUrls: ['./message-associate-file.component.scss']
})
export class MessageAssociateFileComponent implements OnInit {
  @ViewChild('filesAssociateTasks', { static: true })
  public filesAssociateTasksComponent: FilesAssociateTasksComponent;

  @ViewChild('associateFiles', { static: true })
  public associateFiles: ModalComponent;

  @Output()
  // NOTE: Only suppressing because legacy.
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  public onAssociated: EventEmitter<void> = new EventEmitter<void>();

  public file: FileKeySupplementalData;

  public message: Message;

  public orderId: number;

  public alreadyAssociated: boolean;

  public associateWithOrder: boolean;

  public showTaskAssociation: boolean;

  public associateWithTasks: boolean;

  public associatedTasksChanged: boolean;

  public updateCategory: boolean;

  public label: string;

  public projectCategory: ProjectCategory;

  public fileCategoryItems: SelectItem[];

  public loaded = false;

  public isLegalTranslation: boolean;

  public fileAssociateTaskFilters: FilesAssociateTasksFilters;

  constructor(private fileService: FileService,
              private orderService: OrderService,
              private projectService: ProjectService,
              private configService: ConfigService,
              private messageSvc: MessageService) {
  }

  public ngOnInit(): void {
    this.fileCategoryItems = [];
    this.configService.fileCategories.forEach(cat => {
      this.fileCategoryItems.push({ label: cat.text, value: cat });
    });
  }

  public show(file: FileKeyPair, message: Message): void {
    this.file = file;
    this.message = message;

    this.getData();

    this.associateFiles.show();
  }

  public hide(): void {
    this.associateFiles.hide();

    this.alreadyAssociated = false;
    this.associateWithOrder = false;
    this.associateWithTasks = false;
    this.showTaskAssociation = false;
    this.associatedTasksChanged = false;
    this.isLegalTranslation = false;
    this.loaded = false;
  }

  public getData(): void {
    if (this.message.scope === 'TASK' || this.message.scope === 'INVOICE') {
      this.orderId = this.message.parentScopeId;
    } else {
      this.orderId = this.message.scopeId;
    }

    this.getOrderData();
  }

  public getOrderData(): void {
    if (this.message.scope === 'ESTIMATE') {
      this.projectService.getProjectEstimate(this.orderId)
        .toPromise()
        .then((estimate) => {
          this.setAssociationDetails(estimate.estimateId, estimate.supplementalDocuments);
          this.loaded = true;
        });
    } else {
      this.orderService.getOrderDetails(this.orderId)
        .toPromise()
        .then((details) => {
          this.isLegalTranslation =
            details.orderProjectEstimate.projectCategory.projectCategory === PROJECT_CATEGORY_VALUE.LegalTranslation;
          this.setAssociationDetails(details.orderId, details.supplementalDocuments);
          if (this.showTaskAssociation) {
            this.filesAssociateTasksComponent.buildTaskData(this.file, details.taskAssignments, this.orderId)
              .then(() => {
                this.loaded = true;
              });
          } else {
            this.loaded = true;
          }
        });
    }
  }

  public setAssociateWithTasks(): void {
    // Set associate order to true, if we associate with tasks
    this.associateWithOrder = this.associateWithTasks ? true : this.associateWithOrder;
  }

  public setAssociationDetails(orderDisplayId: string, supplementalDocuments: FileKeySupplementalData[]): void {
    if (this.message.scope === 'ESTIMATE') {
      this.label = 'Estimate: ' + orderDisplayId;
    } else {
      this.label = 'Project: ' + orderDisplayId;
    }

    this.showTaskAssociation = this.message.scope === 'PROJECT' || this.message.scope === 'TASK';

    const file = supplementalDocuments.find(doc => doc.filekey === this.file.filekey);
    if (file) {
      this.file = file;
      this.label = 'File is already Associated with ' + this.label;
      this.alreadyAssociated = true;
      this.associateWithTasks = this.showTaskAssociation;
      this.associateWithOrder = true;
    }
  }

  public updateFileCategory(fileCategory: any): void {
    this.fileService.updateFileCategory(this.file.filekey, fileCategory.value.id)
      .then((file) => {
        this.messageSvc.add({ severity: 'success', summary: 'File Category Changed', detail: ' ' });
        this.file.fileCategory = file.fileCategory;
        this.updateCategory = false;
      });
  }

  public associateFile(): void {
    this.associateFileToOrder()
      .then(clientDocId => {
        if (this.associateWithTasks && this.associatedTasksChanged && clientDocId) {
          this.filesAssociateTasksComponent.associateFilesAndTasks(clientDocId);
          this.messageSvc.add({ severity: 'success', summary: 'Associated Successfully', detail: ' ' });
          this.hide();
        } else {
          this.messageSvc.add({ severity: 'success', summary: 'Associated Successfully', detail: ' ' });
          this.hide();
        }
        this.onAssociated.emit();
      });
  }



  public associateFileToOrder(): Promise<number> {
    return new Promise<number>(resolve => {
      if (this.alreadyAssociated) {
        resolve(this.file.clientDocumentId);
        return;
      }

      const files: FileKeyPair[] = [];
      files.push(this.file);

      if (this.message.scope === 'ESTIMATE') {
        this.projectService.addSupplementalDocs(this.orderId, files)
          .toPromise()
          .then(() => {
            resolve(null);
          });
      } else {
        this.orderService.addNewOrderFiles(this.orderId, files)
          .then((result) => {
            resolve(result[0].clientDocumentId);
          });
      }
    });
  }
}
