import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[townipDragDropListener]'
})
export class DragDropListenerDirective {

  @HostListener('dragover', ['$event'])
  public dragOver(ev: any): void {
    if (ev && ev.dataTransfer && ev.dataTransfer.dropEffect !== 'copy') {
      ev.dataTransfer.dropEffect = 'copy';
    }
  }

}
