import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { InputMaskModule } from 'primeng/inputmask';
import { InputTextModule } from 'primeng/inputtext';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { SpinnerModule } from 'primeng/spinner';
import { CalendarModule } from 'primeng/calendar';
import { AdvancedDatepickerComponent } from './advanced-datepicker.component';

@NgModule({
  declarations: [AdvancedDatepickerComponent],
  exports: [AdvancedDatepickerComponent],
  imports: [
    CommonModule,
    FormsModule,
    InputMaskModule,
    CalendarModule,
    OverlayPanelModule,
    SpinnerModule,
    InputTextModule
  ]
})
export class AdvancedDatepickerModule { }
