import { Component, OnInit } from '@angular/core';
import { AppContextService } from '../../shared/services/app-context.service';
import { ErrorLog, ErrorLogService } from '../error-log.service';

@Component({
  selector: 'townip-error-404',
  templateUrl: './error-404.component.html',
  styleUrls: ['./error-404.component.scss']
})
export class Error404Component implements OnInit {

  public supportContact: string;

  public lastError: ErrorLog;

  public showError = false;

  constructor(private appContext: AppContextService, private errorLog: ErrorLogService) {
  }

  public ngOnInit(): void {
    this.lastError = this.errorLog.getError();
    this.supportContact = this.appContext.supportContact;
  }

  public hardNavigate(event: MouseEvent, path: string): void {
    event.preventDefault();

    // Hard navigate so that the whole angular app will refresh to the path.
    window.location.href = path;
  }

  public toggleError(): void {
    this.showError = !this.showError;
  }
}
