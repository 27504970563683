import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RequestService } from '../shared/services/request-service';
import { Observable } from 'rxjs';
import { SearchResult } from '../shared/response/search-result';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  constructor(private httpClient: HttpClient,
              private requestService: RequestService) { }

  public searchSystem(searchValue: string): Observable<SearchResult[]> {
    const url = `/api/search?searchValue=${searchValue}`;
    const headers = this.requestService.buildHttpHeaders();

    return this.httpClient.get<SearchResult[]>(url, { headers: headers });
  }
}
