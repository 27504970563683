import * as _ from 'underscore';
import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { UserService } from '../../../services/user.service';
import { OverlayPanel } from 'primeng/overlaypanel';
import { RecipientFilter } from '../utils/recipient-filter';
import { DeliveryContactTypeEnum } from '../../../dto/filing-and-translation-delivery/delivery-contact-type.enum';
import { isNullOrUndefined } from '../../../utils/is-null-or-undefined';

interface IndexedSelectItem extends SelectItem {
  searchIndex?: string;
}

@Component({
  selector: 'townip-user-chips',
  templateUrl: './user-chips.component.html',
  styleUrls: ['./user-chips.component.scss']
})
export class UserChipsComponent implements OnInit, OnChanges {

  @ViewChild('inputKey')
  public inputKey: ElementRef;

  @ViewChild(OverlayPanel)
  public searchOverlay: OverlayPanel;

  @Input()
  public users: IndexedSelectItem[];

  @Input()
  public selected: SelectItem[];

  @Input()
  public placeholder: string;

  @Input()
  public disableAdding = false;

  /** Allows users to create new contacts */
  @Input()
  public allowCreateNew = false;

  @Input()
  public limitResults = true;

  @Output()
  public selectedChange: EventEmitter<SelectItem[]> = new EventEmitter();

  @Output()
  public createNewContact = new EventEmitter<string>();

  public filteredUsers: IndexedSelectItem[];

  public tempSelection: string; // Used as a model for ngx typeahead.

  public selectedUsers: SelectItem[];

  public recipientTypes: string[];

  public isInternal: boolean;

  public inputTimer: any;

  constructor(private renderer: Renderer2,
              private eRef: ElementRef,
              private userService: UserService) {
    this.selectedUsers = [];
    this.users = [];
    this.recipientTypes = [];
  }

  public ngOnInit(): void {
    this.isInternal = this.userService.getUser().userType === 'INTERNAL';
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.selected) {
      this.selectedUsers = this.selected;
    }

    if (this.selected === undefined || this.selected === null) {
      this.selectedUsers = [];
    }
  }

  public onSearchKeyChange(key: string, event: any): void {
    clearTimeout(this.inputTimer);

    this.inputTimer = setTimeout(() => {
      this.filteredUsers = this.users.filter(user => {
        if (!user.label) {
          return false;
        }

        return RecipientFilter.isUserExisting(user, key);
      });

      if (this.limitResults) {
        this.filteredUsers = this.filteredUsers.slice(0, 12);
      }

      if (this.filteredUsers.length > 0 || (this.tempSelection && this.allowCreateNew)) {
        // Show the overlay if there's a user or allowCreateNew is set to true
        setTimeout(() => {
          this.searchOverlay.show(event);
        });
      } else {
        // Hide the overlay when no user
        setTimeout(() => {
          this.searchOverlay.hide();
        });
      }
    });
  }

  public select(selectedUser: IndexedSelectItem): void {
    this.tempSelection = '';

    const existingUser = this.selectedUsers.find(user => {
      return user.value.id === selectedUser.value.id && user.value.email === selectedUser.value.email;
    });

    if (existingUser) {
      this.selectedUsers = this.selectedUsers.filter(user => user !== existingUser);
    } else {
      this.checkSupplementalEmails(selectedUser);
      this.selectedUsers.push(selectedUser);
    }

    this.selectedChange.emit(this.selectedUsers);
    this.searchOverlay.hide();
  }

  private checkSupplementalEmails(selectedUser: IndexedSelectItem): void {
    if (isNullOrUndefined(selectedUser.value) || isNullOrUndefined(selectedUser.value.contactType)) {
      return;
    }

    const userTypes =
      [DeliveryContactTypeEnum.Internal, DeliveryContactTypeEnum.Vendor, DeliveryContactTypeEnum.Client];

    if (!userTypes.some(type => type === selectedUser.value.contactType)) {
      return;
    }

    const supplementalEmails =
      this.users.filter(contactOpt => {
        return contactOpt.value.contactType === DeliveryContactTypeEnum.SupplementalEmail &&
          contactOpt.value.id === selectedUser.value.id;
      });

    if (!isNullOrUndefined(supplementalEmails) && supplementalEmails.length > 0) {
      supplementalEmails.forEach(supplementalContact => {
        if (this.selectedUsers.every(user => user.value.email !== supplementalContact.value.email)) {
          this.selectedUsers.push(supplementalContact);
        }
      });
    }
  }

  public onCreateNew(): void {
    this.createNewContact.emit(this.tempSelection);
    this.tempSelection = '';
    this.searchOverlay.hide();
  }

  public selectOrganization(organization: string, userType: string): void {
    this.tempSelection = '';

    const organizationUsers =
      this.users.filter(user => user.value.organization === organization && user.value.recipientType === userType);

    for (const user of organizationUsers) {
      if (!_.find(this.selectedUsers, selected => {
        return selected.value.id === user.value.id && selected.value.email === user.value.email;
      })) {
        this.selectedUsers.push(user);
      }
    }

    this.searchOverlay.hide();
    this.selectedChange.emit(this.selectedUsers);
  }

  public remove(item: SelectItem): void {
    this.selectedUsers = _.without(this.selectedUsers, item);
    this.selectedChange.emit(this.selectedUsers);
  }

  public isUserSelected(model: IndexedSelectItem): SelectItem {
    return (_.find(this.selectedUsers, (user) => {
      return user.value.id === model.value.id && user.value.email === model.value.email;
    }));
  }

  public setFocus(): void {
    if (this.inputKey) {
      const nElement = this.inputKey.nativeElement as HTMLInputElement;
      nElement.focus();
    }
  }
}
