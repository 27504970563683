<div class="global-action-block" *ngIf="showLoader">
  <!-- Blocks the user from doing any action while any HTTP load is in progress -->
</div>

<!-- This should not conflict with the big loader -->
<!-- Meaning we should not be showing this when the big loader is being rendered -->
<div class="global-loader" *ngIf="showLoader && !bigLoaderActive">
  <i class="fa fa-refresh fa-spin"></i>
  <div class="d-inline-block ml-2">Loading</div>
</div>
