import { Router } from '@angular/router';
import { OrganizationManagementService } from '../../settings-v2/services/organization-management.service';
import { Injectable } from '@angular/core';

@Injectable()
export class InternalManagementGuard  {
  constructor(private orgManagementService: OrganizationManagementService,
              private router: Router) {}

  public canActivate(): boolean {
    if (!this.orgManagementService.getVendor()) {
      this.router.navigate(['/settings/companies-and-vendors']);
      return false;
    }
    return true;
  }
}
