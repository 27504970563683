import { Component, OnInit } from '@angular/core';
import { WebsocketService } from '../../shared/services/websocket.service';
import { ConnectionDaemonService, ConnectionDaemonStatusCode } from '../../shared/services/connection-daemon.service';

@Component({
  selector: 'townip-error-503-maintenance',
  templateUrl: './error-503-maintenance.component.html',
  styleUrls: ['./error-503-maintenance.component.scss']
})
export class Error503MaintenanceComponent implements OnInit {

  constructor(private ws: WebsocketService, private connectionDaemon: ConnectionDaemonService) {
  }

  public ngOnInit(): void {
    // IMPORTANT NOTE:
    // You can't navigate to another page when you reach this component.
    // You need to refresh the whole window with retry();

    // Disconnect from pubnub.
    this.ws.fullDisconnect();

    // DISABLE PERIODIC CHECKING
    // Emit "online" first because otherwise the modal will be left behind (race condition)
    // - This is when the check for alive.txt comes in first before the 503 (that seems to cause it)
    this.connectionDaemon.status.next(ConnectionDaemonStatusCode.Online);
    // If we reach this page = THE USER HAS TO FULL REFRESH (we have a button)
    this.connectionDaemon.disable();
  }

  public retry(): void {
    // Hard refresh
    window.location.href = '/';
  }

}
