/**
 * These statuses are different from the PM and Vendor ones.
 * It would be best not to merge them together.
 */
import { SelectItem } from 'primeng/api';

export enum AnnuityClientStatus {
    Upcoming = 'UPCOMING',
    PendingAutoPay = 'PENDING_AUTO_INSTRUCT',
    WaitingForInstruction = 'WAITING_FOR_INSTRUCTIONS',
    PaymentRequired = 'PAYMENT_REQUIRED',
    InProgress = 'IN_PROGRESS',
    ActionRequired = 'ACTION_REQUIRED',
    Fulfilled = 'FULFILLED',
    OnHold = 'ON_HOLD',
    OptNotToPay = 'OPT_NOT_TO_PAY',
    Cancelled = 'CANCELLED',
    PastWindow = 'PAST_WINDOW',
    Paid = 'PAID',
    // TODO: Add others
}

export const ANNUITY_CLIENT_STATUSES: SelectItem[] = [
  { label: 'Upcoming', value: AnnuityClientStatus.Upcoming },
  { label: 'Pending Auto Instruct', value: AnnuityClientStatus.PendingAutoPay },
  { label: 'Waiting for Instructions', value: AnnuityClientStatus.WaitingForInstruction },
  { label: 'Payment Required', value: AnnuityClientStatus.PaymentRequired },
  { label: 'In Progress', value: AnnuityClientStatus.InProgress },
  { label: 'Action Required', value: AnnuityClientStatus.ActionRequired },
  { label: 'Paid', value: AnnuityClientStatus.Paid },
  { label: 'Fulfilled', value: AnnuityClientStatus.Fulfilled },
  { label: 'On Hold', value: AnnuityClientStatus.OnHold },
  { label: 'Opt Not to Pay', value: AnnuityClientStatus.OptNotToPay },
  { label: 'Cancelled', value: AnnuityClientStatus.Cancelled },
  { label: 'Past Window', value: AnnuityClientStatus.PastWindow },
  // TODO: Add others.
];
