import { RouterModule, Routes } from '@angular/router';
import { TaskGuardService } from '../data-services/task-guard-service';
import { NgModule } from '@angular/core';
import { MessagesComponent } from './messages.component';

const routes: Routes = [
  {
    path: '', component: MessagesComponent,
    canActivate: [TaskGuardService]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MessagesRoutingModule {
}
