export enum AnnuityRequiredField {
  Applicant = 'APPLICANT',
  NationalFilingDate = 'NATIONAL_FILING_DATE',
  InternationalApplicationDate = 'INTERNATIONAL_FILING_DATE',
  EffectiveFilingDate = 'EFFECTIVE_FILING_DATE',
  PublicationDate = 'PUBLICATION_DATE',
  GrantDate = 'GRANT_DATE',
  ExaminationRequestDate = 'EXAMINATION_REQUEST_DATE',
  EntitySize = 'ENTITY_SIZE',
  DesignatedStates = 'DESIGNATED_STATES',
  NumberOfClaims = 'NUMBER_OF_CLAIMS',
  NumberOfIndependentClaims = 'NUMBER_OF_INDEPENDENT_CLAIMS',
  IntentToGrantDate = 'INTENT_TO_GRANT_DATE',
  FirstPaymentDate = 'FIRST_PAYMENT_DATE',
  NoticeOfAllowanceDate = 'NOTICE_OF_ALLOWANCE',
  ApplicantIsInventor = 'APPLICANT_IS_INVENTOR',
  WillingToLicense = 'WILLING_TO_LICENSE',
  FilingRoute = 'FILING_ROUTE',
  AgentOfRegistration = 'AGENT_OF_REGISTRATION',
  IsPatentExtended = 'IS_PATENT_EXTENDED',
  RegistrationDate = 'REGISTRATION_DATE',
  NumberOfSubDesigns = 'NUMBER_OF_SUB_DESIGNS',
  SpcExpiryDate = 'SPC_EXPIRY_DATE',
  ParentFilingDate = 'PARENT_FILING_DATE',
  GrantStatus = 'GRANT_STATUS',
  GrantStatusUnitary = 'GRANT_STATUS_UNITARY',
  NumberOfClasses = 'NUMBER_OF_CLASSES',
  RequestConvertRenewalSystemFiled = 'REQUEST_CONVERT_RENEWAL_SYSTEM_FILED',
  TypeOfMark = 'TYPE_OF_MARK',
  ApplicantLocatedInThisCountry = 'APPLICANT_LOCATED_IN_THIS_COUNTRY',
  PriorityDate = 'PRIORITY_DATE',
  SingleTrademarkClass = 'SINGLE_TRADEMARK_CLASS',
  ExcessDesignatedGoods = 'EXCESS_DESIGNATED_GOODS',
  PriorityClaimed = 'PRIORITY_CLAIMED',
  FigurativeMark = 'FIGURATIVE_MARK',
  RenewalCalculationDate = 'RENEWAL_CALCULATION_DATE',
  FrenchPolynesiaApplicable = 'FRENCH_POLYNESIA_APPLICABLE',
  NextRenewalDate  = 'NEXT_RENEWAL_DATE',
  DeclarationOfIncontestability = 'DECLARATION_OF_INCONTESTABILITY',
  LastRenewalDeadline = 'LAST_RENEWAL_DEADLINE',
  TrademarkClasses = 'TRADEMARK_CLASSES',
  IsClassified = 'IS_CLASSIFIED',
  ColorType = 'COLOR_TYPE',
  ItemizedClasses = 'ITEMIZED_CLASSES',
  OfficeOfOrigin = 'OFFICE_OF_ORIGIN',
}

export enum AnnuitySpecificRequiredField {
  ExtensionAvailable = 'EXTENSION_AVAILABLE',
  StatesPartialRenewalAvailable = 'STATES_PARTIAL_RENEWAL_AVAILABLE',
  DesignsPartialRenewalAvailable = 'DESIGNS_PARTIAL_RENEWAL_AVAILABLE',
  TrademarksPartialRenewalAvailable = 'TRADEMARKS_PARTIAL_RENEWAL_AVAILABLE',
  DeclarationOfIncontestability = 'DECLARATION_OF_INCONTESTABILITY'
}

export enum AnnuityRequiredFieldPartialType {
  StatesPartialRenewalAvailable = 'STATES_PARTIAL_RENEWAL_AVAILABLE',
  DesignsPartialRenewalAvailable = 'DESIGNS_PARTIAL_RENEWAL_AVAILABLE',
  TrademarksPartialRenewalAvailable = 'TRADEMARKS_PARTIAL_RENEWAL_AVAILABLE'
}

export interface AnnuityRequiredFieldsConfig {
  countryCode?: string;
  annuityPatentRequiredFields?: AnnuityPatentRequiredFields[];
  annuitySpecificRequiredFields?: AnnuitySpecificRequiredFields[];
  spreadsheetConfigured?: boolean;
  vendorConfigured?: boolean;
  partialRenewalType?: AnnuityRequiredFieldPartialType;
}

export interface AnnuitySpecificRequiredFields {
  extensionDeadlineShift: number;
  specificRequiredFields: AnnuitySpecificRequiredField[];
}

export interface AnnuityPatentRequiredFields {
  requiredFieldType?: AnnuityRequiredField;
  conditionalRequiredFields?: AnnuityConditionalRequiredField[];
  patentRequiredFields?: { [field: string]: AnnuityRequiredField[] };
  annuitySpecificRequiredFields: AnnuityRequiredField[];
  requiredFieldNote?: string;
}

export interface AnnuityConditionalRequiredField {
  comparisonType: AnnuityConditionalRequiredComparisonType;
  dateValue: string;
  stringValue: string;
  booleanValue: boolean;
  requiredFieldType: AnnuityRequiredField;
  conditionalRequiredFields?: AnnuityConditionalRequiredField[];
}

export enum AnnuityConditionalRequiredComparisonType {
  // Using $lt, etc pattern here to keep consistent with our system filters
  // Later we might change this to use those same enum
  $lt = 'LESS_THAN',
  $gt = 'GREATER_THAN',
  $eq = 'EQUALS',
}
