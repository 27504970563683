import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isFilterDisabled'
})
export class IsFilterDisabledPipe implements PipeTransform {

  public transform(name: string, disabledFilters?: any): boolean {
    if (disabledFilters[name]) {
      return true;
    }

    return false;
  }
}
