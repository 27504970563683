import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LoggingService } from '../logging.service';
import { UserService } from '../services/user.service';
import { isNullOrUndefined } from '../utils/is-null-or-undefined';

@Injectable()
export class RestrictedCompanyUserGuardService  {

  constructor(private loggingService: LoggingService,
              private router: Router,
              private userService: UserService) {
  }

  /**
   * Guard to prevent a restricted company user from accessing estimates, financial info, and other
   * company priviledged data.
   * @returns {Promise<T>}
   */
  public canActivate(): Promise<boolean> {
    return new Promise((resolve) => {
      const user = this.userService.getUser();

      if (isNullOrUndefined(user)) {
        this.router.navigate(['/auth/login']);
        resolve(false);
        return;
      }

      if (user.userType === 'COMPANY') {
        if (this.userService.getPermissions().RESTRICTED_COMPANY_USER) {
          this.router.navigate(['/error-403']);
          resolve(false);
        } else {
          resolve(true);
        }
      } else {
        resolve(true);
      }
    });
  }
}
